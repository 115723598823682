import { GoogleMapWrapper } from "./GoogleMapWrapper";
import React from "react";
import { FormattedMessage as T } from "react-intl";
import { UserConsumer } from "../Base";
import { hasPermission, Permission } from "../Permission";

export function Minimap({
  date = Date.now(),
  imei,
  latest_gps_info,
  portal,
  fetchAddress,
  timeShown = Date.now(),
}) {
  if (latest_gps_info.lat === null || latest_gps_info.lng === null) {
    return null;
  }

  const devices = latest_gps_info ? [latest_gps_info] : [];
  return (
    <UserConsumer>
      {user =>
        hasPermission(user, Permission.Location) && (
          <div className="wide-graph-frame" style={{ overflow: "hidden" }}>
            <div className="graph-headline">
              <h5 className="headline-for-icon">
                <i className="fa fa-globe" />
                <T
                  id="tracar.device-list.mapView"
                  defaultMessage="Kartenansicht"
                />
              </h5>
            </div>
            <GoogleMapWrapper
              containerElement={<div className="map-container" />}
              date={date}
              devices={devices}
              mapElement={<div className="minimap" />}
              portal={portal}
              timeShown={timeShown}
              preLoad
              fetchAddress={fetchAddress}
              mapType={"miniMap"}
            />
          </div>
        )
      }
    </UserConsumer>
  );
}
