import React from "react";
import Link from "react-router-dom/es/Link";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { DeviceType } from "../Wires/deviceTypes";
import { defineMessages, injectIntl } from "react-intl";
import { Portal } from "../Portal";

const terms = defineMessages({
  connectDevice: {
    id: "tracar.rfid-chip-list.connectDevice",
    defaultMessage: "Mit Gerät verknüpfen",
  },
  deleteChip: {
    id: "tracat.rfid-chip-list.deleteChip",
    defaultMessage: "Chip löschen",
  },
  settings: {
    id: "tracar.rfid-chip-list.settings",
    defaultMessage: "Einstellungen",
  },
});

export const RfidChipTableLine = withRouter(
  injectIntl(props => {
    const t = props.intl.formatMessage;

    const { chip_code, chip_name, fname, lname, rfid_chip_id } = props.rfidChip;

    //needed for multiple assigning
    const updateRfidChipToAssign = (event, rfidChip) => {
      props.updateRfidChipsToAssign(rfidChip, event.target.checked);
    };

    //needed for multiple assigning
    const updateCheckBox = rfidChip => {
      const checkBox = document.getElementById(
        "checkBox_" + rfidChip.rfid_chip_id,
      );
      checkBox.checked = !checkBox.checked;
      props.updateRfidChipsToAssign(rfidChip, checkBox.checked);
    };

    const assignDevice = rfidChip => {
      props.updateRfidChipToAssign(rfidChip, true);
    };

    return (
      <div
        className="table-line tracker grid-x"
        onClick={() => updateCheckBox(props.rfidChip)}
      >
        <div className="cell small-11 medium-1 large-1 table-line-left ">
          <input
            type="checkbox"
            id={"checkBox_" + rfid_chip_id}
            onClick={event => {
              event.stopPropagation();
              updateRfidChipToAssign(event, props.rfidChip);
            }}
            defaultChecked={
              typeof props.rfidChipsToAssign.find(
                chip => chip.rfid_chip_id === props.rfidChip.rfid_chip_id,
              ) !== "undefined"
            }
          />
          {/*{icon}*/}
          <div style={{ display: "block" }}>
            {chip_name}
            <div style={{ fontWeight: "normal" }}>
              <small>Code: {chip_code}</small>
            </div>
          </div>
        </div>

        <div className="cell small-12 medium-12 large-auto table-line-middle flexible">
          {fname} {lname}
        </div>

        <div className="cell small-12 medium-1 large-1 table-line-right fixed">
          <div
            className="button-container"
            data-for={`chip-table-line-${rfid_chip_id}`}
            data-tip={t(terms.settings)}
            onClick={event => {
              event.stopPropagation();
              props.toggleShowSettingsOverlay(rfid_chip_id);
            }}
          >
            <i className="button icon-cog" />
          </div>
          <div
            className="button-container"
            data-for={`chip-table-line-${rfid_chip_id}`}
            data-tip={t(terms.connectDevice)}
            onClick={event => {
              event.stopPropagation();
              assignDevice(props.rfidChip);
            }}
          >
            <i className="button icon-rfid-chip-device" />
          </div>
          <div
            className="button-container"
            data-for={`chip-table-line-${rfid_chip_id}`}
            data-tip={t(terms.deleteChip)}
            onClick={event => {
              event.stopPropagation();
              props.toggleDeleteConfirmationOverlay(rfid_chip_id);
            }}
          >
            <i className="button icon-trash" />
          </div>
          {/*<div*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.tags)}*/}
          {/*  onClick={() => props.toggleShowAssignTagsOverlay(props.device)}*/}
          {/*>*/}
          {/*  <i className="fa fa-tag " />*/}
          {/*</div>{" "}*/}
          {/*<Link*/}
          {/*  to={"/" + portal + "/messages/" + imei}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.messages)}*/}
          {/*>*/}
          {/*  <i className="fa fa-envelope" />*/}
          {/*  {unread_messages > 0 && unread_messages < 100 && (*/}
          {/*    <div className="indicator">{unread_messages}</div>*/}
          {/*  )}*/}
          {/*  {unread_messages > 100 && <div className="indicator">99+</div>}*/}
          {/*</Link>*/}
          {/*<Link*/}
          {/*  to={"/" + portal + "/map/" + imei}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.mapView)}*/}
          {/*>*/}
          {/*  <i className="fa fa-map-marker" />*/}
          {/*</Link>*/}
          {/*<Link*/}
          {/*  to={`/${portal}/device-safety-zone/${imei}`}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.geofences)}*/}
          {/*>*/}
          {/*  <i className="fa fa-globe" />*/}
          {/*</Link>*/}
          {/*<Link*/}
          {/*  to={`/${portal}/device/${imei}`}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.settings)}*/}
          {/*>*/}
          {/*  <i className="fa fa-cog" />*/}
          {/*</Link>*/}
        </div>
        {/* <ReactTooltip*/}
        {/*  delayShow={500}*/}
        {/*  class="tooltip"*/}
        {/*  border={true}*/}
        {/*  id={`tracker-table-line-${imei}`}*/}
        {/*/> */}
      </div>
    );
  }),
);
