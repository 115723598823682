import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { injectIntl } from "react-intl";
import { RoleList } from "./RoleList";
import { RoleCreateOverlay } from "./RoleCreateOverlay";
import { RoleEditOverlay } from "./RoleEditOverlay";
import { RoleDeleteOverlay } from "./RoleDeleteOverlay";
import { RoleAssignUserOverlay } from "./RoleAssignUserOverlay";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";

const roleListLimit = 10;

class AdminPanelRoleBaseClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      // total number of roles across all pagination pages
      numberOfRoles: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // display overlay to create a new role?
      showCreateOverlay: false,
      // display overlay to assign users to a role?
      showAssignUserOverlay: false,
      // display overlay to edit an existing role?
      showEditOverlay: false,
      // display overlay to delete an existing role?
      showDeleteOverlay: false,
      // used by pagination to fetch the roles displayed on the current pagination page
      roleListOffset: 0,
      // search term to filter role list
      roleListSearchKey: "",
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props
      .getRoleListFiltered(
        this.state.roleListSearchKey,
        this.state.roleListOffset,
        roleListLimit,
      )
      .then(recordListData => {
        this.setState({
          loading: false,
          // unwrap role records
          recordList: recordListData.role_data,
          numberOfRoles: recordListData.total_amount,
          numberOfPages: Math.ceil(recordListData.total_amount / roleListLimit),
        });
      });
  }

  render() {
    if (this.state.recordList != null) {
      /// loaded
      return (
        <UserConsumer>
          {user =>
            hasPortalAccess(user, Portal.AdminPanelRole) && (
              <div>
                <RoleList
                  toggleCreateOverlay={this.toggleCreateOverlay}
                  recordList={this.state.recordList}
                  numberOfRoles={this.state.numberOfRoles}
                  updateListOffset={this.updateListOffset}
                  updateListSearchKey={this.updateListSearchKey}
                  updatePagination={this.updatePagination}
                  currentPage={this.state.currentPage}
                  numberOfPages={this.state.numberOfPages}
                  inviteMessage={this.state.inviteMessage}
                  userLoginData={this.props.userLoginData}
                  toggleEditOverlay={this.toggleEditOverlay}
                  toggleDeleteOverlay={this.toggleDeleteOverlay}
                  toggleAssignUserOverlay={this.toggleAssignUserOverlay}
                />
                {this.state.showCreateOverlay && (
                  <RoleCreateOverlay
                    closeFunction={this.toggleCreateOverlay}
                    createRole={this.props.createRole}
                    getPermissionList={this.props.getPermissionList}
                  />
                )}
                {this.state.showEditOverlay && (
                  <RoleEditOverlay
                    closeFunction={this.toggleEditOverlay}
                    editRoleRecord={this.state.editRoleRecord}
                    editRole={this.props.editRole}
                    getPermissionList={this.props.getPermissionList}
                  />
                )}
                {this.state.showDeleteOverlay && (
                  <RoleDeleteOverlay
                    closeFunction={this.toggleDeleteOverlay}
                    deleteRoleRecord={this.state.deleteRoleRecord}
                    deleteRole={this.props.deleteRole}
                  />
                )}
                {this.state.showAssignUserOverlay && (
                  <RoleAssignUserOverlay
                    closeFunction={this.toggleAssignUserOverlay}
                    assignUserRoleRecord={this.state.assignUserRoleRecord}
                    assignUsersToRole={this.props.assignUsersToRole}
                    getUserList={this.props.getUserList}
                    getUserGroupList={this.props.getUserGroupList}
                    getUserListByRole={this.props.getUserListByRole}
                  />
                )}
              </div>
            )
          }
        </UserConsumer>
      );
    } else {
      /// loading
      return <LoadingCircle />;
    }
  }

  /**
   * Updates the pagination offset to facilitate page switching
   * @param offset
   */
  updateListOffset = offset => {
    this.setState({ roleListOffset: offset }, this.load);
  };

  /**
   * Updates the search term the list is filtered by
   * @param searchKey
   */
  updateListSearchKey = searchKey => {
    this.setState({ roleListSearchKey: searchKey }, this.load);
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * roleListLimit);
  };

  /**
   * Shows / hides the overlay containing the form to create roles
   */
  toggleCreateOverlay = () => {
    this.setState({ showCreateOverlay: !this.state.showCreateOverlay }, () => {
      if (!this.state.showCreateOverlay) {
        this.load();
      }
    });
  };

  /**
   * Shows / hides the overlay containing the form to edit roles
   */
  toggleEditOverlay = roleRecord => {
    this.setState(
      {
        editRoleRecord: roleRecord,
        showEditOverlay: !this.state.showEditOverlay,
      },
      () => {
        // reload list only when closing the edit overlay
        if (!this.state.showEditOverlay) {
          this.load();
        }
      },
    );
  };

  /**
   * Shows / hides the overlay containing the confirmation to delete a single role
   */
  toggleDeleteOverlay = roleRecord => {
    this.setState(
      {
        deleteRoleRecord: roleRecord,
        showDeleteOverlay: !this.state.showDeleteOverlay,
      },
      () => {
        // reload list only when closing the delete overlay
        if (!this.state.showDeleteOverlay) {
          this.load();
        }
      },
    );
  };

  /**
   * Shows / hides the overlay containing the form to assign a role to one or more users
   */
  toggleAssignUserOverlay = roleRecord => {
    this.setState(
      {
        assignUserRoleRecord: roleRecord,
        showAssignUserOverlay: !this.state.showAssignUserOverlay,
      },
      () => {
        // reload list only when closing the assign overlay
        if (!this.state.showAssignUserOverlay) {
          this.load();
        }
      },
    );
  };
}

export const AdminPanelRoleBase = injectIntl(AdminPanelRoleBaseClass);
