import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { InfoWindow } from "react-google-maps";
import moment from "moment/moment";
import Link from "react-router-dom/es/Link";
import { getPinUrl } from "./functions";
import { AccClientConfig } from "../ClientSpecificContent/acc-index";
import { Portal } from "../Portal";
import { UserConsumer } from "../Base";
import { hasPermission, Permission } from "../Permission";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  knots: {
    id: "base.knots",
    defaultMessage: "Knoten",
  },
});

class InfoOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressFetched: false,
    };
  }

  fetchAddress = () => {
    this.props
      .fetchAddress({
        lat: this.props.trackerPosition.lat,
        lon: this.props.trackerPosition.lng,
      })
      .then(res => {
        this.setState({ address: res.address, addressFetched: true });
      });
  };

  render() {
    const portal = this.props.portal;
    const t = this.props.intl.formatMessage;
    const { course, device, gps_datetime, speed } = this.props.trackerPosition;
    const speedInfo =
      AccClientConfig.vehicleIsBoat && portal === Portal.Vehicle
        ? `${parseInt((speed * 0.36) / 1.852, 10)} ${t(terms.knots)}`
        : `${parseInt(speed * 0.36, 10)} km / h`;

    const pinSrc = getPinUrl(this.props.trackerPosition);

    if (!this.state.addressFetched) {
      this.fetchAddress();
    }

    return (
      // TODO test as just this.props.closeCallback
      <UserConsumer>
        {user => (
          <InfoWindow onCloseClick={() => this.props.closeCallback()}>
            {this.props.trackerPosition.device ? (
              /* -- InfoOverlay for Devices ------------------------------------ */
              <div className="modal grid-margin-x info-window">
                <div className="grid-x">
                  <div className="small-12 cell">
                    <h3>
                      <img className="icon-badge" src={pinSrc} />
                      &nbsp;&nbsp; {device.name}
                    </h3>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="small-12 cell device-info">
                    <dl className="details">
                      <dt>
                        <T
                          id="tracar.map.overlay.date"
                          defaultMessage="Datum:"
                        />
                      </dt>
                      <dd>{moment(gps_datetime).format("DD.MM.YYYY")} </dd>
                      <dt>
                        <T
                          id="tracar.map.overlay.time"
                          defaultMessage="Zeit:"
                        />
                      </dt>
                      <dd>
                        <T
                          id="tracar.map.overlay.oclock"
                          defaultMessage="{hhmmss} Uhr"
                          values={{
                            hhmmss: moment(gps_datetime).format("HH:mm:ss"),
                          }}
                        />
                      </dd>
                      <dt>
                        <T
                          id="tracar.map.overlay.speed"
                          defaultMessage="Geschwindigkeit:"
                        />
                      </dt>
                      <dd>{speedInfo}</dd>
                      <dt>
                        <T
                          id="tracar.map.overlay.geoposition"
                          defaultMessage="Geoposition:"
                        />
                      </dt>
                      <dd>{this.state.address || <LoadingSmallCircle />}</dd>
                      <dt>
                        <T
                          id="tracar.map.overlay.direction"
                          defaultMessage="Fahrtrichtung:"
                        />
                      </dt>
                      <dd>{course / 10}°</dd>
                    </dl>
                  </div>
                </div>
                <div className="grid-x grid-margin-x">
                  {// hide statistic(button) if current portal is 'Person'
                  portal !== Portal.Person &&
                  hasPermission(user, Permission.Dashboard) ? (
                    <Link
                      to={`/${portal}/statistics/${device.imei}`}
                      className="button cell small-6 medium-6 large-6"
                    >
                      <i className="fa fa-tachometer" />
                      &nbsp;&nbsp;
                      <T
                        id="tracar.map.overlay.statistics"
                        defaultMessage="Statistiken"
                      />
                    </Link>
                  ) : (
                    <React.Fragment />
                  )}
                  {// hide routenliste(button) if current portal is 'Person'
                  portal !== Portal.Person &&
                  hasPermission(user, Permission.RouteList) ? (
                    <Link
                      to={`/${portal}/fahrtenbuch/${device.imei}`}
                      className="button cell small-6 medium-6 large-6"
                    >
                      <i className="fa fa-book" />
                      &nbsp;&nbsp;{" "}
                      <T
                        id="tracar.map.overlay.fahrtenbuch"
                        defaultMessage="Routenliste"
                      />
                    </Link>
                  ) : (
                    <React.Fragment />
                  )}
                  {hasPermission(user, Permission.SafetyZone) ? (
                    <Link
                      to={{
                        pathname: `/${portal}/device-list/settings/${
                          device.imei
                        }`,
                        state: {
                          selectedDeviceImei: device.imei,
                          selectedSettingsTab: "geofence_settings",
                        },
                      }}
                      className="button cell small-6 medium-6 large-6"
                    >
                      <i className="fa fa-globe" />
                      &nbsp;&nbsp;
                      <T
                        id="tracar.map.overlay.safetyZones"
                        defaultMessage="Sicherheitszonen"
                      />
                    </Link>
                  ) : (
                    <React.Fragment />
                  )}
                  {hasPermission(user, Permission.DeviceList) ? (
                    <Link
                      to={{
                        pathname: `/${portal}/device-list/settings/${
                          device.imei
                        }`,
                        state: { selectedDeviceImei: device.imei },
                      }}
                      className="button cell small-6 medium-6 large-6"
                    >
                      <i className="fa fa-cog" />
                      &nbsp;
                      <T
                        id="tracar.map.overlay.settings"
                        defaultMessage="Einstellungen"
                      />
                    </Link>
                  ) : (
                    <React.Fragment />
                  )}
                  {false && (
                    <Link
                      to={"/foo"}
                      className="button cell small-6 medium-6 large-6"
                    >
                      <T
                        id="tracar.map.overlay.messages"
                        defaultMessage="Meldungen"
                      />
                    </Link>
                  )}
                  {this.props.withSplit && (
                    <div
                      className="button cell auto"
                      onClick={this.props.toggleSplitConfirmation}
                    >
                      <i className="fa fa-scissors" />
                      &nbsp;
                      <T
                        id="tracar.map.overlay.split"
                        defaultMessage="Route an diesem Punkt teilen"
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              /* -- InfoOverlay for Customer Addresses ------------------------- */
              <div className="modal grid-margin-x info-window">
                <div className="grid-x">
                  <div className="small-12 cell">
                    <h3>
                      <img className="icon-badge" src={pinSrc} />
                      &nbsp;&nbsp;{" "}
                      {this.props.trackerPosition.customer_company_name}
                    </h3>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="small-12 cell device-info">
                    <dl className="details">
                      <dt>
                        <T
                          id="tracar.map.overlay.name"
                          defaultMessage="Name:"
                        />
                      </dt>
                      <dd>{this.props.trackerPosition.customer_name}</dd>
                      <dt>
                        <T
                          id="tracar.map.overlay.address"
                          defaultMessage="Adresse:"
                        />
                      </dt>
                      <dd>{this.props.trackerPosition.customer_address}</dd>
                    </dl>
                  </div>
                </div>
                <div className="grid-x grid-margin-x">
                  <Link
                    to={`/${portal}/customerAddresses`}
                    className="button cell small-6 medium-6 large-6"
                  >
                    <T
                      id="tracar.map.overlay.customerAddresses"
                      defaultMessage="Kundenadressen"
                    />
                  </Link>
                </div>
              </div>
            )}
          </InfoWindow>
        )}
      </UserConsumer>
    );
  }
}

export const InfoOverlay = injectIntl(InfoOverlayClass);
