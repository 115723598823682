import fetchUsers from "./fetchUsers";
import fetchRoles from "./fetchRoles";
import fetchUserGroups from "./fetchUserGroups";
import fetchDevices from "./fetchDevices";
import fetchServiceInterval from "./fetchServiceInterval";
import fetchPoolVehicleAdmin from "./fetchPoolVehicleAdmin";
import fetchDeviceCategories from "./fetchDeviceCategories";
import fetchExpenses from "./fetchExpensesAdmin";

export const fetchAdminPanelList = (jsonFetch,freeFetch) => ({
  ...fetchUsers(jsonFetch),
  ...fetchRoles(jsonFetch),
  ...fetchDevices(jsonFetch),
  ...fetchDeviceCategories(jsonFetch),
  ...fetchUserGroups(jsonFetch),
  ...fetchServiceInterval(jsonFetch),
  ...fetchPoolVehicleAdmin(jsonFetch, freeFetch),
  ...fetchExpenses(jsonFetch),
});
