import { postJson, putJson } from "./fetchService";

const getSos = jsonFetch => imei => {
  return jsonFetch(`/set_sos/${imei}`);
};

const setSos = jsonFetch => (imei, sosObj) => {
  return jsonFetch(`/set_sos/${imei}`, postJson(sosObj));
};

export const fetchPerson = jsonFetch => ({
  getSos: getSos(jsonFetch),
  setSos: setSos(jsonFetch),
});
