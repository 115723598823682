import React from "react";
import { Redirect } from "react-router";
import { GoogleMapWrapper } from "./GoogleMapWrapper";

export class RouteMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      routeData: [],
    };
  }

  render() {
    if (this.state.routeSplitted) {
      return this.redirectToRouteOverview();
    }

    return (
      <GoogleMapWrapper
        containerElement={<div className="map-container" />}
        mapElement={<div style={{ height: `70vh` }} />}
        portal={this.props.match.params.portal}
        splitRoute={this.handleRouteSplit}
        getRouteData={this.props.getRouteData}
        fetchAddress={this.props.fetchAddress}
        fahrtenbuchId={this.props.match.params.route}
      />
    );
  }

  /**
   * Split the route at the selected tracklog
   * @return void
   */
  handleRouteSplit = (imei, tracklogId) => {
    const fahrtenbuchId = this.props.match.params.route;

    this.props.splitRoute(imei, fahrtenbuchId, tracklogId).then(response => {
      this.setState({
        routeSplitted: true,
        splitData: response,
      });
    });
  };

  /**
   * Redirect to Fahrtenbuch overview after routes were splitted
   * @return JSX
   */
  redirectToRouteOverview = () => {
    return (
      <Redirect
        to={{
          pathname: `/${this.props.match.params.portal}/fahrtenbuch/${
            this.props.match.params.imei
          }`,
          state: { splitRoutes: this.state.splitData },
        }}
      />
    );
  };
}
