import React from "react";
import ReactTooltip from "react-tooltip";
import Link from "react-router-dom/es/Link";
import { DeviceType } from "../Wires/deviceTypes";

import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  del: {
    id: "customer.addresses.tableLine.delete",
    defaultMessage: "Löschen",
  },
  edit: {
    id: "customer.addresses.tableLine.edit",
    defaultMessage: "Bearbeiten",
  },
});

class CustomerAddressTableLineClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDelete = () => {
    this.props.showDeleteConfirmation(
      this.props.customerAddress.customer_address_id,
    );
  };

  render() {
    const t = this.props.intl.formatMessage;
    const addressId = this.props.customerAddress.customer_address_id;

    return (
      <div className="table-line customer-address grid-x">
        <div className="cell small-12 medium-3 large-2">
          {this.props.customerAddress.customer_company_name}
          <div>
            <small>{this.props.customerAddress.customer_name}</small>
          </div>
        </div>

        <div className="cell small-12 medium-6 large-5">
          {this.props.customerAddress.customer_address}
        </div>

        <div className="cell auto table-line-right">
          <div className="button-container">
            <Link
              to={{
                pathname: `/admin-panel/customerAddresses/${addressId}`,
                state: {
                  address: this.props.customerAddress,
                },
              }}
              className="button"
              data-for={`customer-address-table-line-${addressId}`}
              data-tip={t(terms.edit)}
            >
              <i className="fa fa-pencil" />
            </Link>

            <div
              className="button"
              data-for={`customer-address-table-line-${addressId}`}
              data-tip={t(terms.del)}
              onClick={this.handleDelete}
            >
              <i className="fa fa-trash" />
            </div>
          </div>
        </div>
        <ReactTooltip
          delayShow={500}
          class="tooltip"
          border={true}
          id={`customer-address-table-line-${addressId}`}
        />
      </div>
    );
  }
}

export const CustomerAddressTableLine = injectIntl(
  CustomerAddressTableLineClass,
);
