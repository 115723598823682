import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { DeviceAssignmentType } from "../../ApiContract";
import { UserConsumer } from "../../Base";

export class DeviceUserLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log("user", this.props.user);
  }

  //needed for multiple assigning
  updateCheckBox = imei => {
    const checkBox = document.getElementById("checkBox_" + imei);
    this.props.updateSelectedDevicesImeiList(imei, !checkBox.checked);
  };

  // all portals have the map marker
  getIcon = color => {
    return (
      <div className={`message-pin`}>
        <i className="fa fa-map-marker" style={{ color }} />
      </div>
    );
  };

  checkIfLocked = assignment => {
    return this.props.user.locked_assignments.some(
      some_assignment => some_assignment === assignment,
    );
  };

  render() {
    return (
      <div className="table-line grid-x grid-padding-x">
        <div className="cell small-12 large-auto first-cell">
          <div className="grid-x">
            <div className="shrink cell">
              {!this.props.user.possible_assignments.includes(
                DeviceAssignmentType.NotAssigned,
              ) ? (
                <span className="checkbox">
                  <i className="fa fa-lock" />
                </span>
              ) : (
                <span
                  className="checkbox"
                  id={"checkBox_" + this.props.user.id}
                  checked={this.props.selected}
                  onClick={() =>
                    this.props.togglePermissionForUser(
                      this.props.user.id,
                      this.props.selected &&
                        !this.props.user.possible_assignments.includes(
                          this.props.defaultAssignmentType,
                        )
                        ? DeviceAssignmentType.NotAssigned
                        : this.props.defaultAssignmentType,
                    )
                  }
                >
                  {this.props.selected ? (
                    <i className="icon-check" />
                  ) : (
                    <i className="icon-check_empty" />
                  )}
                </span>
              )}
            </div>
            <div className="shrink cell">
              <span className="name">{this.props.user.email}</span>{" "}
              <span className="grey-text">
                {" "}
                {this.props.user.first_name} {this.props.user.last_name}
              </span>
            </div>
          </div>
          <div className="grey-text">
            <span className="grey-text flex-container">
              <div className="user-status border-right">Active User</div>
              <div className="registration-date border-right">
                <T
                  id="adminPanel.devices.user-assignment.line.labels.registrationDate"
                  defaultMessage="Registriert am"
                />
                {": "}
                {this.props.user.registered_on
                  ? this.props.user.registered_on
                  : "-"}{" "}
              </div>
              {/*| Employee ID: {this.props.user.id}{" "}*/}
              {this.props.availableAssignments.length > 0 && (
                <div className="chips flex-container">
                  {this.props.availableAssignments.includes(
                    DeviceAssignmentType.Read,
                  ) && (
                    <div
                      className={`chip show ${
                        this.props.current_assignments.includes(
                          DeviceAssignmentType.Read,
                        )
                          ? "green"
                          : !this.props.user.possible_assignments.includes(
                              DeviceAssignmentType.Read,
                            )
                          ? "inactive"
                          : ""
                      }`}
                      onClick={() => {
                        this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Read,
                        ) &&
                          !this.checkIfLocked(DeviceAssignmentType.Read) &&
                          this.props.togglePermissionForUser(
                            this.props.user.id,
                            DeviceAssignmentType.Read,
                          );
                      }}
                    >
                      {this.checkIfLocked(DeviceAssignmentType.Read) && (
                        <React.Fragment>
                          <i className="fa fa-lock" />
                        </React.Fragment>
                      )}
                      <T
                        id="adminPanel.devices.user-assignment.labels.assignmentTypeRead"
                        defaultMessage="Anzeigen"
                      />
                    </div>
                  )}
                  {this.props.availableAssignments.includes(
                    DeviceAssignmentType.Write,
                  ) && (
                    <div
                      className={`chip edit ${
                        this.props.current_assignments.includes(
                          DeviceAssignmentType.Write,
                        )
                          ? "green"
                          : !this.props.user.possible_assignments.includes(
                              DeviceAssignmentType.Write,
                            )
                          ? "inactive"
                          : ""
                      }`}
                      onClick={() => {
                        this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Write,
                        ) &&
                          !this.checkIfLocked(DeviceAssignmentType.Write) &&
                          this.props.togglePermissionForUser(
                            this.props.user.id,
                            DeviceAssignmentType.Write,
                          );
                      }}
                    >
                      {this.checkIfLocked(DeviceAssignmentType.Write) && (
                        <React.Fragment>
                          <i className="fa fa-lock" />
                        </React.Fragment>
                      )}
                      <T
                        id="adminPanel.devices.user-assignment.labels.assignmentTypeWrite"
                        defaultMessage="Bearbeiten"
                      />
                    </div>
                  )}
                  {this.props.availableAssignments.includes(
                    DeviceAssignmentType.Manage,
                  ) && (
                    <div
                      className={`chip assign ${
                        this.props.current_assignments.includes(
                          DeviceAssignmentType.Manage,
                        )
                          ? "green"
                          : !this.props.user.possible_assignments.includes(
                              DeviceAssignmentType.Manage,
                            )
                          ? "inactive"
                          : ""
                      }`}
                      onClick={() => {
                        this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Manage,
                        ) &&
                          !this.checkIfLocked(DeviceAssignmentType.Manage) &&
                          this.props.togglePermissionForUser(
                            this.props.user.id,
                            DeviceAssignmentType.Manage,
                          );
                      }}
                    >
                      {this.checkIfLocked(DeviceAssignmentType.Manage) && (
                        <React.Fragment>
                          <i className="fa fa-lock" />
                        </React.Fragment>
                      )}
                      <T
                        id="adminPanel.devices.user-assignment.labels.assignmentTypeManage"
                        defaultMessage="Zuordnen"
                      />
                    </div>
                  )}
                  {this.props.availableAssignments.includes(
                    DeviceAssignmentType.Fahrtenbuch,
                  ) && (
                    <div
                      className={`chip fahrtenbuch ${
                        this.props.current_assignments.includes(
                          DeviceAssignmentType.Fahrtenbuch,
                        )
                          ? "green"
                          : !this.props.user.possible_assignments.includes(
                              DeviceAssignmentType.Fahrtenbuch,
                            )
                          ? "inactive"
                          : ""
                      }`}
                      onClick={() => {
                        this.props.user.possible_assignments.includes(
                          DeviceAssignmentType.Fahrtenbuch,
                        ) &&
                          !this.checkIfLocked(
                            DeviceAssignmentType.Fahrtenbuch,
                          ) &&
                          this.props.togglePermissionForUser(
                            this.props.user.id,
                            DeviceAssignmentType.Fahrtenbuch,
                          );
                      }}
                    >
                      {this.checkIfLocked(DeviceAssignmentType.Fahrtenbuch) && (
                        <React.Fragment>
                          <i className="fa fa-lock" />
                        </React.Fragment>
                      )}
                      <T
                        id="adminPanel.devices.user-assignment.labels.assignmentTypeFahrtenbuch"
                        defaultMessage="Fahrtenbuch"
                      />
                    </div>
                  )}
                </div>
              )}
            </span>
          </div>
        </div>
        <div className="cell small-12 medium-12 large-2">
          <strong>{this.props.user.role.role_name}</strong>
          <br />
          <span className="grey-text line-pad">
            <T
              id="adminPanel.devices.user-assignment.labels.userRole"
              defaultMessage="Nutzerrolle"
            />
          </span>
        </div>
        {/*
        <div className="cell small-12 medium-12 large-auto">
          <strong>Entwickler</strong>
          <br />
          <span className="grey-text line-pad">Nutzergruppe</span>
        </div>
        */}
        <div className="cell small-12 medium-1 large-1 table-line-right fixed">
          <div className="button-container" />
        </div>
      </div>
    );
  }
}
