import React from "react";
import { PoolVehicleTableLine } from "./PoolVehicleTableLine";
import {
  defineMessages,
  FormattedMessage,
  FormattedMessage as T,
  injectIntl,
} from "react-intl";
import { PaginationBar } from "../AdminPanel/PaginationBar";
import ReactTooltip from "react-tooltip";

const terms = defineMessages({
  registeredDevices: {
    id: "tracar.device-list.registeredDevices",
    defaultMessage: "Registrierte Geräte:",
  },
});

class PoolVehicleTableClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceToAssignTagsTo: null,
      showAssignTagsOverlay: false,
    };
  }

  render() {
    return (
      <div>
        <div className="table-frame filter-table admin-list overflow-x-clip">
          <div className="table-header">
            <i className="icon-device" />
            <T
              id="portal.poolVehicles.list.header"
              defaultMessage="Zugewiesene Poolfahrzeuge"
            />
            <span className="item-count">{this.props.numberOfDevices}</span>
          </div>
          {this.props.currentPoolVehicle.imei && (
            <fieldset className="current-vehicle flex-just-between margin-horizontal-1 margin-vertical-1 font-bold">
              <legend>
                <T
                  id="portal.poolVehicles.list.currentPoolVehicle.headline"
                  defaultMessage="Mein Fahrzeug"
                />
                :
              </legend>
              <div className="vehicle-name">
                {this.props.currentPoolVehicle.name}
              </div>
              <a
                className="color-red"
                href="#"
                onClick={() =>
                  this.props.checkOutOfDevice(
                    this.props.currentPoolVehicle.imei,
                  )
                }
              >
                <i className="icon icon-steering_wheel"></i>
                Fahrzeug abmelden
              </a>
            </fieldset>
          )}
          <div className="grid-x grid-margin-x table-line filter">
            <div className="cell small-12 medium-6 large-2">
              <a className="" href="#">
                <T
                  id="portal.poolVehicles.list.column.header.vehicle"
                  defaultMessage="Fahrzeug"
                />
                <i className="fa fa-sort relative" />
              </a>
            </div>
            <div className="cell small-12 medium-6 large-2">
              <a className="" href="#">
                <T
                  id="portal.poolVehicles.list.column.header.routeType"
                  defaultMessage="Erlaubte Fahrten"
                />
                <i className="fa fa-sort relative" />
              </a>
            </div>
            <div className="cell small-12 medium-6 large-2">
              <a className="" href="#">
                <T
                  id="portal.poolVehicles.list.column.header.status"
                  defaultMessage="Status"
                />
                <i className="fa fa-sort relative" />
              </a>
            </div>
            <div className="cell small-12 medium-6 large-2">
              <a className="no-filter" href="#" data-tip data-for="warning">
                <T
                  id="portal.poolVehicles.list.column.header.lastPosition"
                  defaultMessage="Letzte Position"
                />
                &nbsp;&nbsp;
                <i
                  style={{ color: "gray" }}
                  className="icon-_279 margin-horizontal-0"
                />
              </a>

              <ReactTooltip id="warning" type="error" effect="solid">
                <span>
                  <T
                    id="portal.poolVehicles.list.column.header.warning"
                    defaultMessage="Dies ist die letzte bekannte Position. Sie muss
                  nicht aktuell sein."
                  />
                </span>
              </ReactTooltip>
              {/*<i className="fa fa-sort relative" />*/}
            </div>
            <div className="cell small-12 medium-6 large-2">
              {/* <a className="no-filter" href="#">
                <T
                  id="portal.poolVehicles.list.column.header.deviceMessage"
                  defaultMessage="Gerätemitteilung"
                /> */}
              {/*<i className="fa fa-sort relative" />*/}
              {/* </a> */}
            </div>
            <div className="cell small-12 medium-1 large-auto" />
          </div>
          {this.props.devices.map(device => (
            <PoolVehicleTableLine
              key={device.imei}
              device={device}
              user={this.props.user}
              checkInToDevice={this.props.checkInToDevice}
              disableCheckIn={this.props.currentPoolVehicle.hasOwnProperty(
                "imei",
              )}
              toggleExpenseOverlay={this.props.toggleExpenseOverlay}
              messageListQuery={this.props.messageListQuery}
            />
          ))}
        </div>

        <PaginationBar
          currentPage={this.props.currentPage}
          numberOfPages={this.props.numberOfPages}
          updateCurrentPage={this.props.updatePagination}
        />
      </div>
    );
  }
}

export const PoolVehicleTable = injectIntl(PoolVehicleTableClass);
