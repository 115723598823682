import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../../TextInput";
import DatePicker from "react-datepicker";
import moment from "moment";
import { defaultLocale } from "../../Language";
import { CustomDatePickerInput } from "../../Map/Map";
import {
  ServiceInterval,
  ThresholdTypes,
} from "../../ServiceInterval";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";
import * as Device from "../../Device";
import { Category } from "../../Device";

// Translations
const terms = defineMessages({
  header_new_title: {
    id: "service.interval.new.header",
    defaultMessage: "Serviceintervall anlegen",
  },
  header_edit_title: {
    id: "service.interval.edit.header",
    defaultMessage: "Serviceintervall bearbeiten",
  },
  device_label: {
    id: "service.interval.new.device.label",
    defaultMessage: "Gerät",
  },
  type_name_label: {
    id: "service.interval.new.type.label",
    defaultMessage: "Service Typ angeben",
  },
  type_description_label: {
    id: "service.interval.new.description.label",
    defaultMessage: "Service Beschreibung",
  },
  interval_date: {
    id: "service.interval.new.date.label",
    defaultMessage: "Datum",
  },
  interval_range: {
    id: "service.interval.new.range.label",
    defaultMessage: "Kilometer",
  },
  interval_threshold: {
    id: "service.interval.new.distance.label",
    defaultMessage: " ",
  },
  interval_runningtime: {
    id: "service.interval.new.operatingtime.label",
    defaultMessage: "Betriebsstunden",
  },
  errors_upload_failed: {
    id: "service.interval.new",
    defaultMessage:
      "Fehler beim Erstellen des Intervalls. Bitte versuchen Sie es erneut.",
  },
  turnus_type_label: {
    id: "service.interval.turnus",
    defaultMessage: "Periode",
  },
  start_date: {
    id: "service.interval.startDate",
    defaultMessage: "Startdatum",
  },
  start_value: {
    id: "service.interval.startValue",
    defaultMessage: "Startwert",
  },
  set_service_interval: {
    id: "service.interval.label.set",
    defaultMessage: "Serviceintervall konfigurieren",
  },
  cancel: {
    id: "service.interval.label.cancel",
    defaultMessage: "Abbrechen",
  },
  save: {
    id: "service.interval.label.save",
    defaultMessage: "Speichern",
  },
  in: {
    id: "service.interval.value.in",
    defaultMessage: "in",
  },
  days: {
    id: "service.interval.value.days",
    defaultMessage: "Tagen",
  },
  timePeriodType: {
    day: {
      id: "service.interval.label.timePeriodType.day",
      defaultMessage: "Tage",
    },
    week: {
      id: "service.interval.label.timePeriodType.week",
      defaultMessage: "Wochen",
    },
    month: {
      id: "service.interval.label.timePeriodType.month",
      defaultMessage: "Monate",
    },
    year: {
      id: "service.interval.label.timePeriodType.year",
      defaultMessage: "Jahre",
    },
  },
  dateFormMode: {
    fixedDate: {
      id: "service.interval.label.dateFormMode.fixedDate",
      defaultMessage: "nach festem Datum",
    },
    timePeriod: {
      id: "service.interval.label.dateFormMode.timePeriod",
      defaultMessage: "nach Zeitspanne",
    },
  },
  thresholdFormMode: {
    distance: {
      id: "service.interval.label.thresholdFormMode.distance",
      defaultMessage: "Kilometer",
    },
    time: {
      id: "service.interval.label.thresholdFormMode.time",
      defaultMessage: "Laufzeit",
    },
  },
  thresholdDistanceFormMode: {
    fixedDistance: {
      id: "service.interval.label.thresholdDistanceFormMode.fixedDistance",
      defaultMessage: "Nach Kilometerstand",
    },
    runningDistance: {
      id: "service.interval.label.thresholdDistanceFormMode.runningDistance",
      defaultMessage: "Nach Laufleistung",
    },
  },
  thresholdOperatingTimeFormMode: {
    fixedOperatingTime: {
      id:
        "service.interval.label.thresholdOperatingTimeFormMode.fixedOperatingTime",
      defaultMessage: "Nach Endstundenstand",
    },
    runningOperatingTime: {
      id:
        "service.interval.label.thresholdOperatingTimeFormMode.runningOperatingTime",
      defaultMessage: "Nach Betriebsstunden",
    },
  },
});

const InternalDateFormat = "YYYY-MM-DD";

const TimePeriodType = {
  Day: "Tage",
  Week: "Wochen",
  Month: "Monate",
  Year: "Jahre",
};

export const FormMode = {
  Create: "create",
  Edit: "edit",
  Clone: "clone",
};

const DateFormMode = {
  fixedDate: "fixedDate",
  timePeriod: "timePeriod",
};

const ThresholdFormMode = {
  distance: "distance",
  operatingTime: "operatingTime",
};

const ThresholdDistanceFormMode = {
  fixedDistance: "fixedDistance",
  runningDistance: "runningDistance",
};

const ThresholdOperatingTimeFormMode = {
  fixedOperatingTime: "fixedOperatingTime",
  runningOperatingTime: "runningOperatingTime",
};

class ServiceIntervalFormClass extends React.Component {
  handleChange;

  constructor(props) {
    super(props);

    // if we are editing or cloning an existing service interval we need to initialise the values correctly here
    this.state = {
      error: null,
      loading: true,
      devices: [],
      enumTypes: [],
      deviceImei: this.getInitialDeviceImei(),
      serviceIntervalId: this.getInitialServiceIntervalId(),
      serviceType: null,
      typeDescription: this.getInitialTypeDescription(),
      thresholdType: this.props.serviceInterval
        ? this.props.serviceInterval.threshold_type_choices
          ? this.props.serviceInterval.threshold_type_choices
          : ""
        : "",
      showDateOption: this.props.serviceInterval
        ? this.props.serviceInterval.planned_service_date
          ? true
          : false
        : false,
      showThresholdOption: this.props.serviceInterval
        ? this.props.serviceInterval.threshold
          ? true
          : false
        : false,
      startDate: this.getInitialStartDate(),
      fixedDate: this.getInitialFixedDate(),
      timePeriodType: this.getInitialTimePeriodType(),
      timePeriodCount: this.getInitialTimePeriodCount(),
      fixedOperatingTime: this.getInitialFixedOperatingTime(),
      fixedDistance: this.getInitialFixedDistance(),
      currentOdometerValue: null,
      currentRunningTimeValue: null,
      dateFormMode: DateFormMode.fixedDate,
      thresholdFormMode: this.getInitialThresholdFormMode(),
      thresholdDistanceFormMode: ThresholdDistanceFormMode.fixedDistance,
      thresholdOperatingTimeFormMode:
        ThresholdOperatingTimeFormMode.fixedOperatingTime,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  getInitialThresholdFormMode = () => {
    if (this.props.formMode === FormMode.Create) {
      return null;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      return this.props.serviceInterval.device_category === Category.Auto
        ? ThresholdFormMode.distance
        : ThresholdFormMode.operatingTime;
    }

    return null;
  };

  async componentDidMount() {
    Promise.all([this.props.fetchDevices(), this.props.getEnumTypes()]).then(
      ([devices, enumTypes]) => {
        let preselectedDevice = devices.devices[0];
        this.setState({
          devices,
          deviceImei:
            this.props.formMode === FormMode.Create
              ? preselectedDevice.imei
              : this.state.deviceImei,
          serviceType:
            this.props.formMode === FormMode.Create
              ? this.state.serviceType === null
                ? enumTypes.service_types[0]
                : this.state.serviceType
              : this.props.serviceInterval
              ? this.props.serviceInterval.service_type_choices
                ? this.props.serviceInterval.service_type_choices
                : null
              : null,
          enumTypes,
          timePeriodType:
            this.props.formMode === FormMode.Create
              ? this.state.timePeriodType === null
                ? enumTypes.date_types[0]
                : this.state.timePeriodType
              : this.state.timePeriodType,
          thresholdType:
            this.state.thresholdType === ""
              ? preselectedDevice.device_category.description === Category.Auto
                ? ThresholdTypes.Distance
                : ThresholdTypes.OperatingTime
              : this.state.thresholdType,
          loading: false,
          thresholdFormMode:
            this.state.thresholdFormMode === null
              ? preselectedDevice.device_category.description === Category.Auto
                ? ThresholdFormMode.distance
                : ThresholdFormMode.operatingTime
              : this.state.thresholdFormMode,
          currentOdometerValue: this.getInitialCurrentOdometerValue(
            preselectedDevice,
          ),
          currentRunningTimeValue: this.getInitialCurrentRunningTimeValue(
            preselectedDevice,
          ),
        });
      },
    );
  }

  getInitialServiceIntervalId = () => {
    if (this.props.formMode === FormMode.Create) {
      return null;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      return this.props.serviceInterval.service_interval_id;
    }

    return null;
  };

  getInitialDeviceImei = () => {
    if (this.props.formMode === FormMode.Create) {
      return null;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      return this.props.serviceInterval.imei;
    }

    return null;
  };

  getInitialTypeDescription = () => {
    if (this.props.formMode === FormMode.Create) {
      return null;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      return this.props.serviceInterval.service;
    }

    return null;
  };

  getInitialTimePeriodCount = () => {
    if (this.props.formMode === FormMode.Create) {
      return 0;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      if (this.props.serviceInterval.planned_service_date === null) {
        // return 0 if interval does not use a service date
        return 0;
      }
      return this.props.serviceInterval.delta_date_value;
    }
  };

  getInitialTimePeriodType = () => {
    if (this.props.formMode === FormMode.Create) {
      return null;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      if (this.props.serviceInterval.planned_service_date === null) {
        // return null if interval does not use a service date
        return null;
      }
      return this.props.serviceInterval.delta_date_type;
    }
  };

  getInitialStartDate = () => {
    if (this.props.formMode === FormMode.Create) {
      return moment().format(InternalDateFormat);
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      if (this.props.serviceInterval.planned_service_date === null) {
        // return $now if interval does not use a service date
        return moment().format(InternalDateFormat);
      }

      // calculate start type using the planned date and the delta values
      let startDate = moment(
        this.props.serviceInterval.planned_service_date,
      ).subtract(
        this.props.serviceInterval.delta_date_value,
        this.getMomentPeriodType(this.props.serviceInterval.delta_date_type),
      );
      return startDate.format(InternalDateFormat);
    }
  };

  getInitialFixedDate = () => {
    if (this.props.formMode === FormMode.Create) {
      return moment().format(InternalDateFormat);
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      if (this.props.serviceInterval.planned_service_date === null) {
        // return $now if interval does not use a service date
        return moment().format(InternalDateFormat);
      }

      return moment(this.props.serviceInterval.planned_service_date).format(
        InternalDateFormat,
      );
    }
  };

  getInitialFixedOperatingTime = () => {
    if (this.props.formMode === FormMode.Create) {
      return null;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      return this.props.serviceInterval.threshold;
    }
  };

  getInitialFixedDistance = () => {
    if (this.props.formMode === FormMode.Create) {
      return null;
    }

    if (
      this.props.formMode === FormMode.Edit ||
      this.props.formMode === FormMode.Clone
    ) {
      return this.props.serviceInterval.threshold;
    }
  };

  getInitialCurrentOdometerValue = device => {
    if (this.props.formMode === FormMode.Create) {
      if (device.device_category.description === Category.Auto) {
        return device.car_details.device_odom_curr;
      }
      return 0;
    }

    return this.props.serviceInterval.device_odom_or_ignition_curr;
  };

  getInitialCurrentRunningTimeValue = device => {
    if (this.props.formMode === FormMode.Create) {
      if (device.device_category.description !== Category.Auto) {
        return device.car_details.device_ignition_time_curr;
      }
      return null;
    }

    return this.props.serviceInterval.device_odom_or_ignition_curr
      ? this.props.serviceInterval.device_odom_or_ignition_curr
      : this.props.serviceInterval.odom_or_ignition_at_creation_time;
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  handleChangeDevice = event => {
    let deviceImei = event.target.value;
    let selectedDevice = this.state.devices.devices.find(
      device => device.imei === deviceImei,
    );
    let newThresholdFormMode =
      selectedDevice.device_category.description === Device.Category.Auto
        ? ThresholdFormMode.distance
        : ThresholdFormMode.operatingTime;
    this.setState({
      deviceImei: deviceImei,
      thresholdType:
        selectedDevice.device_category.description === Device.Category.Auto
          ? ThresholdTypes.Distance
          : ThresholdTypes.OperatingTime,
      thresholdFormMode: newThresholdFormMode,
      currentOdometerValue:
        selectedDevice.device_category.description === Device.Category.Auto
          ? selectedDevice.car_details.device_odom_curr
          : 0,
      currentRunningTimeValue:
        selectedDevice.device_category.description !== Device.Category.Auto
          ? selectedDevice.car_details.device_ignition_time_curr
          : 0,
    });
  };

  handleChangeDateFormMode = dateFormMode => {
    this.setState({ dateFormMode: dateFormMode });
  };

  handleChangeThresholdDistanceFormMode = thresholdDistanceFormMode => {
    this.setState({ thresholdDistanceFormMode: thresholdDistanceFormMode });
  };

  handleChangeThresholdOperatingTimeFormMode = thresholdOperatingTimeFormMode => {
    this.setState({
      thresholdOperatingTimeFormMode: thresholdOperatingTimeFormMode,
    });
  };

  handleChangeStartDate = date => {
    this.setState({ startDate: date.format(InternalDateFormat) });
  };

  handleChangeFixedDate = date => {
    this.setState({ fixedDate: date.format(InternalDateFormat) });
  };

  handleChangeTimePeriodCount = count => {
    this.setState(
      {
        timePeriodCount: count,
      },
      this.setState({ fixedDate: this.getFixedDateValue() }),
    );
  };

  toggleShowDateOption = () => {
    this.setState({ showDateOption: !this.state.showDateOption });
  };

  toggleShowThresholdOption = () => {
    this.setState({ showThresholdOption: !this.state.showThresholdOption });
  };

  handleSubmit = user => {
    let intervalData = new ServiceInterval(
      this.getServiceIntervalId(),
      this.state.serviceType,
      this.getThresholdTypeValue(),
      this.state.deviceImei,
      parseInt(this.getThresholdValue()),
      this.state.typeDescription ? this.state.typeDescription : "", // service can not be NULL
      this.getFixedDateValue(),
      this.getDeltaDateValue(),
      this.getDeltaDateTypeValue(),
      parseInt(this.getDeltaThresholdValue()),
      user,
      false,
    );

    this.props.onFormSubmit(user, intervalData);
  };

  getServiceIntervalId = () => {
    if (this.props.formMode === FormMode.Edit) {
      return this.state.serviceIntervalId;
    }

    return null;
  };

  getThresholdValue = () => {
    if (!this.state.showThresholdOption) {
      return null;
    }

    if (this.state.thresholdFormMode === ThresholdFormMode.distance) {
      if (
        this.state.thresholdDistanceFormMode ===
        ThresholdDistanceFormMode.fixedDistance
      ) {
        return parseInt(this.state.fixedDistance);
      }

      if (
        this.state.thresholdDistanceFormMode ===
        ThresholdDistanceFormMode.runningDistance
      ) {
        return (
          parseInt(this.state.currentOdometerValue) +
          parseInt(this.state.runningDistance)
        );
      }
    }

    if (this.state.thresholdFormMode === ThresholdFormMode.operatingTime) {
      if (
        this.state.thresholdOperatingTimeFormMode ===
        ThresholdOperatingTimeFormMode.fixedOperatingTime
      ) {
        return parseInt(this.state.fixedOperatingTime);
      }

      if (
        this.state.thresholdOperatingTimeFormMode ===
        ThresholdOperatingTimeFormMode.runningOperatingTime
      ) {
        return (
          parseInt(this.state.currentRunningTimeValue) +
          parseInt(this.state.runningOperatingTime)
        );
      }

      return this.state.fixedOperatingTime;
    }

    return null;
  };

  getThresholdTypeValue = () => {
    if (!this.state.showThresholdOption) {
      return null;
    }

    return this.state.thresholdType;
  };

  getDeltaThresholdValue = () => {
    if (!this.state.showThresholdOption) {
      return null;
    }

    if (this.state.thresholdFormMode === ThresholdFormMode.distance) {
      if (
        this.state.thresholdDistanceFormMode ===
        ThresholdDistanceFormMode.fixedDistance
      ) {
        return Math.round(
          this.state.fixedDistance - this.state.currentOdometerValue,
        );
      }
      if (
        this.state.thresholdDistanceFormMode ===
        ThresholdDistanceFormMode.runningDistance
      ) {
        return this.state.runningDistance;
      }
    }

    if (this.state.thresholdFormMode === ThresholdFormMode.operatingTime) {
      if (
        this.state.thresholdOperatingTimeFormMode ===
        ThresholdOperatingTimeFormMode.fixedOperatingTime
      ) {
        return Math.round(
          this.state.fixedOperatingTime - this.state.currentRunningTimeValue,
        );
      }
      if (
        this.state.thresholdOperatingTimeFormMode ===
        ThresholdOperatingTimeFormMode.runningOperatingTime
      ) {
        return this.state.runningOperatingTime;
      }
    }

    return null;
  };

  getFixedDateValue = () => {
    if (!this.state.showDateOption) {
      return null;
    }

    if (this.state.dateFormMode === DateFormMode.fixedDate) {
      return this.state.fixedDate;
    }

    // calculate fixed date from delta values if those are selected
    if (this.state.dateFormMode === DateFormMode.timePeriod) {
      let currentStartDate = moment(this.state.startDate);
      let fixedDate = currentStartDate.add(
        this.state.timePeriodCount,
        this.getMomentPeriodType(),
      );
      return fixedDate.format(InternalDateFormat);
    }

    return null;
  };

  getDeltaDateValue = () => {
    if (!this.state.showDateOption) {
      return null;
    }

    // calculate delta if fixed date is selected, since the API requires this value
    if (this.state.dateFormMode === DateFormMode.fixedDate) {
      return parseInt(
        moment(this.state.fixedDate).diff(moment(this.state.startDate), "days"),
      );
    }

    if (this.state.dateFormMode === DateFormMode.timePeriod) {
      return parseInt(this.state.timePeriodCount);
    }

    return null;
  };

  getDeltaDateTypeValue = () => {
    if (!this.state.showDateOption) {
      return null;
    }

    // delta is calculated in days if fixedDate is selected (API requires this value)
    if (this.state.dateFormMode === DateFormMode.fixedDate) {
      return "Tage";
    }

    if (this.state.dateFormMode === DateFormMode.timePeriod) {
      return this.state.timePeriodType;
    }

    return null;
  };

  getMomentPeriodType = (timePeriodType = null) => {
    if (timePeriodType === null) {
      timePeriodType = this.state.timePeriodType;
    }

    switch (timePeriodType) {
      case "Jahre":
      case "Years":
        return "years";
      case "Monate":
      case "Months":
        return "months";
      case "Wochen":
      case "Weeks":
        return "weeks";
      case "Tage":
      case "Days":
        return "days";
    }
  };

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return (
      <div className="new-interval-modal">
        <RequestOverlay
          closeFunction={this.props.closeFunction}
          className="import-customer-addresses"
          enableCloseOnOverlayClicked={true}
        >
          {this.state.uploading
            ? this.renderLoadingCircle()
            : this.renderInterface()}

          {this.renderError()}
        </RequestOverlay>
      </div>
    );
  }

  /**
   * Renders the loading circle
   * @return LoadingCircle
   */
  renderLoadingCircle = () => {
    return <LoadingCircle />;
  };

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderInterface = () => {
    return (
      <UserConsumer>
        {user =>
          hasPortalAccess(user, Portal.AdminPanelServiceInterval) && (
            <div>
              {this.renderBody()}
              {this.renderFooter(user.email)}
            </div>
          )
        }
      </UserConsumer>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="headline">
        <i className="icon-wrench" />
        {this.props.formMode === FormMode.Create ||
        this.props.formMode === FormMode.Clone ? (
          <div>{t(terms.header_new_title)}</div>
        ) : (
          <div>
            {t(terms.header_edit_title)}
            <i className="icon-long_arrow_right" />
            <span className="device-name">
              {this.props.serviceInterval.vehicle_model}
            </span>
          </div>
        )}
      </div>
    );
  };

  /**
   * Render the body
   * @return JSX
   */
  renderBody = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <div className="grid-x grid-margin-x grid-margin-y margin-bottom-1">
          <div className="select-frame cell large-4">
            <i className="fa fa-sort-down angle" />
            <select
              className="select"
              id="deviceImei"
              value={this.state.deviceImei}
              onChange={this.handleChangeDevice}
            >
              {this.state.devices.devices &&
                this.state.devices.devices.map(device => {
                  return (
                    <option value={device.imei} key={device.imei}>
                      {device.name}
                    </option>
                  );
                })}
            </select>
            <label htmlFor="device_name_label">{t(terms.device_label)}</label>
          </div>
          <div className="select-frame cell large-4">
            <i className="fa fa-sort-down angle" />
            <select
              className="select"
              id="serviceType"
              value={this.state.serviceType}
              onChange={this.handleChange}
            >
              {this.state.enumTypes.service_types &&
                this.state.enumTypes.service_types.map(type => {
                  return (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  );
                })}
            </select>
            <label htmlFor="type_name_label">{t(terms.type_name_label)}</label>
          </div>
          <TextInput
            id="typeDescription"
            className="cell large-4"
            name={t(terms.type_description_label)}
            onChange={this.handleChange}
            value={this.state.typeDescription}
          />
        </div>
        <div className="grid-x">
          <div className="cell konfig-headline">
            {t(terms.set_service_interval)}
          </div>
          <form className="cell grid-x">
            <div className="cell large-6 konfig-form date">
              <div
                className="checkbox action"
                onClick={() => this.toggleShowDateOption()}
              >
                {this.state.showDateOption ? (
                  <i className="icon-check" />
                ) : (
                  <i className="icon-check_empty" />
                )}
                {t(terms.interval_date)}
              </div>

              <div
                className={`${
                  this.state.showDateOption ? "active" : "disabled"
                } form-content`}
              >
                {this.renderDateForm()}
              </div>
            </div>

            <div className="cell large-6 konfig-form range">
              <div
                className="checkbox action"
                onClick={() => this.toggleShowThresholdOption()}
              >
                {this.state.showThresholdOption ? (
                  <i className="icon-check" />
                ) : (
                  <i className="icon-check_empty" />
                )}
                {this.state.thresholdFormMode === ThresholdFormMode.distance
                  ? t(terms.interval_range)
                  : t(terms.interval_runningtime)}
              </div>

              <div
                className={`${
                  this.state.showThresholdOption ? "active" : "disabled"
                } form-content`}
              >
                {this.state.thresholdFormMode === ThresholdFormMode.distance
                  ? this.renderDistanceForm()
                  : this.renderOperatingTimeForm()}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  renderDateForm = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="grid-x grid-margin-x">
        <div className="cell text-left margin-bottom-1">
          <span>{t(terms.start_date)}</span>
        </div>
        <div className="cell large-6">
          <DatePicker
            customInput={<CustomDatePickerInput />}
            locale={moment().locale() || defaultLocale}
            onChange={date => this.handleChangeStartDate(date)}
            popperPlacement="top-end"
            selected={moment(this.state.startDate)}
            selectsStart
            showMonthDropdown
            startDate={moment(this.state.startDate)}
            withPortal
          />
        </div>
        <div className="cell large-6">
          <input
            className="read-only"
            type={"text"}
            value={
              this.state.showDateOption
                ? this.state.dateFormMode === DateFormMode.fixedDate
                  ? `${t(terms.in)} ${this.getDeltaDateValue()} ${t(
                      terms.days,
                    )}`
                  : this.state.fixedDate
                : "-"
            }
            disabled={true}
          />
        </div>
        <div
          className="cell large-6 radios action"
          onClick={() => this.handleChangeDateFormMode(DateFormMode.fixedDate)}
        >
          {this.state.dateFormMode === DateFormMode.fixedDate ? (
            <i className="fa fa-check-circle" />
          ) : (
            <i className="icon-circle_blank" />
          )}
          {t(terms.dateFormMode.fixedDate)}
        </div>
        <div
          className="cell large-6 radios action"
          onClick={() => this.handleChangeDateFormMode(DateFormMode.timePeriod)}
        >
          {this.state.dateFormMode === DateFormMode.timePeriod ? (
            <i className="fa fa-check-circle" />
          ) : (
            <i className="icon-circle_blank" />
          )}
          {t(terms.dateFormMode.timePeriod)}
        </div>
        <div className="cell large-6">
          <DatePicker
            customInput={
              <CustomDatePickerInput
                cssClassName={
                  this.state.dateFormMode !== DateFormMode.fixedDate
                    ? "read-only white-bg"
                    : ""
                }
              />
            }
            locale={moment().locale() || defaultLocale}
            onChange={date => this.handleChangeFixedDate(date)}
            popperPlacement="top-end"
            selected={moment(this.state.fixedDate)}
            selectsStart
            showMonthDropdown
            startDate={moment(this.state.fixedDate)}
            withPortal
            disabled={this.state.dateFormMode !== DateFormMode.fixedDate}
          />
        </div>
        <div className="cell large-6 grid-x">
          <input
            className={`cell large-2 ${
              this.state.dateFormMode !== DateFormMode.timePeriod
                ? "read-only white-bg"
                : ""
            }`}
            type={"text"}
            id={"timePeriodCount"}
            value={this.state.timePeriodCount}
            onChange={count =>
              this.handleChangeTimePeriodCount(count.target.value)
            }
          />
          <select
            className={`cell auto ${
              this.state.dateFormMode !== DateFormMode.timePeriod
                ? "read-only white-bg"
                : ""
            }`}
            id={"timePeriodType"}
            value={this.state.timePeriodType}
            onChange={this.handleChange}
          >
            {this.state.enumTypes &&
              this.state.enumTypes.date_types &&
              this.state.enumTypes.date_types.map(dateType => (
                <option value={dateType}>{dateType}</option>
              ))}
          </select>
        </div>
      </div>
    );
  };

  renderDistanceForm = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="grid-x grid-margin-x">
        <div className="cell text-left margin-bottom-1">
          <span>{t(terms.start_value)}</span>
        </div>
        <div className="cell large-6">
          <input
            className="read-only white-bg"
            type={"text"}
            value={`${Math.round(this.state.currentOdometerValue)} km`}
            disabled={true}
          />
        </div>
        <div className="cell large-6">
          <input
            className="read-only"
            type={"text"}
            value={
              this.state.showThresholdOption
                ? this.state.thresholdDistanceFormMode ===
                  ThresholdDistanceFormMode.fixedDistance
                  ? `${t(terms.in)} ${this.getDeltaThresholdValue()} km`
                  : `${t(terms.in)} ${this.state.runningDistance} km`
                : "-"
            }
            disabled={true}
          />
        </div>
        <div
          className="cell large-6 radios action"
          onClick={() =>
            this.handleChangeThresholdDistanceFormMode(
              ThresholdDistanceFormMode.fixedDistance,
            )
          }
        >
          {this.state.thresholdDistanceFormMode ===
          ThresholdDistanceFormMode.fixedDistance ? (
            <i className="fa fa-check-circle" />
          ) : (
            <i className="icon-circle_blank" />
          )}
          {t(terms.thresholdDistanceFormMode.fixedDistance)}
        </div>
        <div
          className="cell large-6 radios action"
          onClick={() =>
            this.handleChangeThresholdDistanceFormMode(
              ThresholdDistanceFormMode.runningDistance,
            )
          }
        >
          {this.state.thresholdDistanceFormMode ===
          ThresholdDistanceFormMode.runningDistance ? (
            <i className="fa fa-check-circle" />
          ) : (
            <i className="icon-circle_blank" />
          )}
          {t(terms.thresholdDistanceFormMode.runningDistance)}
        </div>
        <div className="cell large-6">
          <input
            className={`${
              this.state.thresholdDistanceFormMode !==
              ThresholdDistanceFormMode.fixedDistance
                ? "read-only white-bg"
                : ""
            }`}
            type={"text"}
            id={"fixedDistance"}
            value={this.state.fixedDistance}
            onChange={this.handleChange}
            disabled={
              this.state.thresholdDistanceFormMode !==
              ThresholdDistanceFormMode.fixedDistance
            }
          />
        </div>
        <div className="cell large-6">
          <input
            className={` ${
              this.state.thresholdDistanceFormMode !==
              ThresholdDistanceFormMode.runningDistance
                ? "read-only white-bg"
                : ""
            }`}
            type={"text"}
            id={"runningDistance"}
            value={this.state.runningDistance}
            onChange={this.handleChange}
            disabled={
              this.state.thresholdDistanceFormMode !==
              ThresholdDistanceFormMode.runningDistance
            }
          />
        </div>
      </div>
    );
  };

  renderOperatingTimeForm = () => {
    const t = this.props.intl.formatMessage;
    return (
      <div className="grid-x grid-margin-x">
        <div className="cell text-left margin-bottom-1">
          <span>{t(terms.start_value)}</span>
        </div>
        <div className="cell large-6">
          <input
            type={"text"}
            value={this.state.currentRunningTimeValue}
            disabled={true}
          />
        </div>
        <div className="cell large-6">
          <input
            className="read-only"
            type={"text"}
            value={
              this.state.showThresholdOption
                ? this.state.thresholdOperatingTimeFormMode ===
                  ThresholdOperatingTimeFormMode.fixedOperatingTime
                  ? `${t(terms.in)} ${this.getDeltaThresholdValue()} h`
                  : `${t(terms.in)} ${
                      this.state.runningOperatingTime
                        ? this.state.runningOperatingTime
                        : 0
                    } h`
                : "-"
            }
            disabled={true}
          />
        </div>
        <div className="cell large-6">
          <div
            className="radios action"
            onClick={() =>
              this.handleChangeThresholdOperatingTimeFormMode(
                ThresholdOperatingTimeFormMode.fixedOperatingTime,
              )
            }
          >
            {this.state.thresholdOperatingTimeFormMode ===
            ThresholdOperatingTimeFormMode.fixedOperatingTime ? (
              <i className="fa fa-check-circle" />
            ) : (
              <i className="icon-circle_blank" />
            )}
            {t(terms.thresholdOperatingTimeFormMode.fixedOperatingTime)}
          </div>
        </div>
        <div className="cell large-6">
          <div
            className="radios action"
            onClick={() =>
              this.handleChangeThresholdOperatingTimeFormMode(
                ThresholdOperatingTimeFormMode.runningOperatingTime,
              )
            }
          >
            {this.state.thresholdOperatingTimeFormMode ===
            ThresholdOperatingTimeFormMode.runningOperatingTime ? (
              <i className="fa fa-check-circle" />
            ) : (
              <i className="icon-circle_blank" />
            )}
            {t(terms.thresholdOperatingTimeFormMode.runningOperatingTime)}
          </div>
        </div>
        <div className="cell large-6">
          <input
            className={`${
              this.state.thresholdOperatingTimeFormMode !==
              ThresholdOperatingTimeFormMode.fixedOperatingTime
                ? "read-only white-bg"
                : ""
            }`}
            type={"text"}
            id={"fixedOperatingTime"}
            value={this.state.fixedOperatingTime}
            onChange={this.handleChange}
            disabled={
              this.state.thresholdOperatingTimeFormMode !==
              ThresholdOperatingTimeFormMode.fixedOperatingTime
            }
          />
        </div>
        <div className="cell large-6">
          <input
            className={`${
              this.state.thresholdOperatingTimeFormMode !==
              ThresholdOperatingTimeFormMode.runningOperatingTime
                ? "read-only white-bg"
                : ""
            }`}
            type={"text"}
            id={"runningOperatingTime"}
            value={this.state.runningOperatingTime}
            onChange={this.handleChange}
            disabled={
              this.state.thresholdOperatingTimeFormMode !==
              ThresholdOperatingTimeFormMode.runningOperatingTime
            }
          />
        </div>
      </div>
    );
  };

  /**
   * Render the footer
   * @return JSX
   */
  renderFooter = user => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="upload-container footer-container">
        <div className="button-container align-right align-middle">
          <div className="cancel" onClick={this.props.closeFunction}>
            {t(terms.cancel)}
          </div>
          <div
            className="button primary margin-0"
            onClick={() => this.handleSubmit(user)}
          >
            {t(terms.save)}
          </div>
        </div>
      </div>
    );
  };

  renderError = () => {
    if (!this.state.error) {
      return null;
    }

    return (
      <div className="error">
        <T
          id="customer.addresses.errors.uploadFailed"
          defaultMessage="Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut."
        />
      </div>
    );
  };
}

export const ServiceIntervalForm = injectIntl(ServiceIntervalFormClass);
