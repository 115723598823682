import { deleteJson, postJson, putJson } from "./fetchService";
import { apiBaseUrl } from "./middleware";

const serverbaseUrl = apiBaseUrl;

/// Collection of all fetch functions regarding the non-company-user management
export default function fetchDeviceCategories(wrappedFetch) {
  const getCategoryListForDevice = imei => {
    return wrappedFetch("/admin_user/device_categories/" + imei);
  };

  const assignCategoryToDevice = (imei, category) => {
    console.log("API endpoint missing");
  };

  return {
    getCategoryListForDevice,
    assignCategoryToDevice,
  };
}
