import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import ReactTooltip from "react-tooltip";

const terms = defineMessages({
  role_name_label: {
    id: "adminPanel.users.edit.inputLabel.role_name",
    defaultMessage: "Name",
  },
  text_search_placeholder: {
    id: "adminPanel.roles.create.placeholder.searchField",
    defaultMessage: "Funktionsname",
  },
});

class RoleEditOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      showErrorMessage: false,
      // name of the selected role. Managed by form field
      editRoleName: this.props.editRoleRecord.role_name,
      // list of functionalitites (= permissions) of the selected role. Managed by selectRecord() and deselectRecord()
      selectedPermissions: this.props.editRoleRecord.permissions,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    // fetch list of functionalities (= permissions) to populate list
    this.props.getPermissionList().then(recordList => {
      this.setState({
        loading: false,
        recordList,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    if (this.validate()) {
      this.props
        .editRole(
          this.props.editRoleRecord.role_id,
          this.state.editRoleName,
          this.state.selectedPermissions.map(
            permission => permission.permission_code,
          ),
        )
        .then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return !isEmpty(this.state.editRoleName);
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  getFilteredFunctions = () => {
    return this.state.recordList.reduce((acc, permissionRecord) => {
      // show only functions matching the search query
      const hasMatchingChildren =
        this.state.query.length === 0 ||
        permissionRecord.children.some(
          r => r.printable_name.toLowerCase().search(this.state.query) > -1,
        );
      const stringFiltering =
        this.state.query.length === 0 ||
        permissionRecord.printable_name.toLowerCase().search(this.state.query) >
          -1;
      // do not show permission if it does not match the query AND none of its children match the query
      if (!stringFiltering && !hasMatchingChildren) {
        return acc;
      }

      // if the permission OR any of its children matches the query include the parent permission
      // because children get displayed by their parents, ie. only if their parents are displayed
      return [...acc, permissionRecord];
    }, []);
  };

  /**
   * Selects a functionality (= permission)
   * @param record
   */
  selectRecord = record => {
    // only select unselected functionalities.
    // use some() to prevent false negatives due to object equality
    if (
      !this.state.selectedPermissions.some(
        selectedPermission =>
          record.permission_code === selectedPermission.permission_code,
      )
    ) {
      let selectedPermissions = [...this.state.selectedPermissions, record];
      this.setState({ selectedPermissions });
    }
  };

  /**
   * Selects a functionality (= permission) that is nested in another record
   * @param nestedRecord
   * @param record
   */
  selectRecordNested = (nestedRecord, record) => {
    // first set the parent record, then the nested record
    // only select unselected functionalities.
    // use some() to prevent false negatives due to object equality
    let selectedPermissions = [...this.state.selectedPermissions];
    if (
      !this.state.selectedPermissions.some(
        selectedPermission =>
          record.permission_code === selectedPermission.permission_code,
      )
    ) {
      selectedPermissions = [...selectedPermissions, record];
    }
    // only select unselected functionalities.
    // use some() to prevent false negatives due to object equality
    if (
      !this.state.selectedPermissions.some(
        selectedPermission =>
          nestedRecord.permission_code === selectedPermission.permission_code,
      )
    ) {
      selectedPermissions = [...selectedPermissions, nestedRecord];
    }
    this.setState({ selectedPermissions });
  };

  /**
   * Deselect a functionality
   * @param record
   */
  deselectRecord = record => {
    const removePermissions = [
      record.permission_code,
      ...record.children.map(c => c.permission_code),
    ];
    this.setState({
      selectedPermissions: this.state.selectedPermissions.filter(
        currentRecord =>
          !removePermissions.includes(currentRecord.permission_code),
      ),
    });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className={"header"}>
          <h3>
            <T
              id="adminPanel.roles.edit.headline"
              defaultMessage="Rolle bearbeiten"
            />{" "}
            ->{" "}
            <span className={"highlighted"}>
              {this.props.editRoleRecord.role_name}
            </span>
          </h3>
        </div>
        <div className={"small-spacer"} />
        <div className={"small-spacer"} />
        <div>
          <div className="small-spacer" />
          <TextInput
            id="editRoleName"
            name={t(terms.role_name_label)}
            className={this.state.showErrorMessage ? "error" : ""}
            onChange={this.handleChange}
            value={this.state.editRoleName}
          />
          {this.state.showErrorMessage && (
            <div className="error">
              <T
                id="adminPanel.roles.create.errorMessage.name_empty"
                defaultMessage="Bitte geben Sie einen Namen an."
              />
            </div>
          )}
          <div className="small-spacer" />
          <QueryLine
            changeCallback={this.setSearchFilter}
            placeholder={t(terms.text_search_placeholder)}
          />
          <div className="small-spacer" />
          <div className="admin-list table-scroll outer-table-frame text-left grid-container">
            <div className="table-frame edit-role text-center">
              {this.state.recordList &&
                this.getFilteredFunctions().map((record, index) => (
                  <React.Fragment key={index}>
                    <div className="table-line grid-x grid-padding-x align-middle">
                      <ReactTooltip
                        delayShow={500}
                        class="tooltip"
                        border={true}
                        id={"role-edit-list-" + record.permission_code}
                      />
                      <div className={"cell shrink auto flex-container"}>
                        <i
                          className={
                            "fa checkbox " +
                            (this.state.selectedPermissions.some(
                              selectedPermission =>
                                record.permission_code ===
                                selectedPermission.permission_code,
                            )
                              ? "fa-check-square-o"
                              : "fa-square-o")
                          }
                          onClick={
                            this.state.selectedPermissions.some(
                              selectedPermission =>
                                record.permission_code ===
                                selectedPermission.permission_code,
                            )
                              ? () => this.deselectRecord(record)
                              : () => this.selectRecord(record)
                          }
                        />
                      </div>
                      <div className="cell auto bold text-left flex-just-between">
                        {record.printable_name}
                        <i
                          className="fa fa-question-circle-o"
                          data-tip={record.permission_desc}
                          data-for={"role-edit-list-" + record.permission_code}
                        ></i>
                      </div>
                    </div>
                    {record.children.map((nestedRecord, index) => (
                      <div
                        className="table-line grid-x grid-padding-x align-middle child"
                        key={`${index}-${nestedRecord.permission_code}`}
                      >
                        <ReactTooltip
                          delayShow={500}
                          class="tooltip"
                          border={true}
                          id={"role-edit-list-" + nestedRecord.permission_code}
                        />
                        <div className={"cell shrink auto flex-container"}>
                          <i
                            className={
                              "fa checkbox " +
                              (this.state.selectedPermissions.some(
                                selectedPermission =>
                                  nestedRecord.permission_code ===
                                  selectedPermission.permission_code,
                              )
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            onClick={
                              this.state.selectedPermissions.some(
                                selectedPermission =>
                                  nestedRecord.permission_code ===
                                  selectedPermission.permission_code,
                              )
                                ? () => this.deselectRecord(nestedRecord)
                                : () =>
                                    this.selectRecordNested(
                                      nestedRecord,
                                      record,
                                    )
                            }
                          />
                        </div>
                        <div className="cell auto bold text-left flex-just-between">
                          {`${nestedRecord.printable_name}`}
                          <i
                            className="fa fa-question-circle-o"
                            data-tip={nestedRecord.permission_desc}
                            data-for={
                              "role-edit-list-" + nestedRecord.permission_code
                            }
                          ></i>
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
            </div>
          </div>
          <div className="small-spacer" />
        </div>
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.roles.edit.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.roles.edit.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const RoleEditOverlay = injectIntl(RoleEditOverlayClass);
