/// see for plotly control elements:
/// https://community.plot.ly/t/remove-options-from-the-hover-toolbar/130/11

import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { TrackerStatistics } from "./TrackerStatistics";
import { getAWholeDaysPeriod } from "../utility";
import moment from "moment";
import { DeviceDashboardAuto } from "../Dashboard/DeviceDashboardAuto";
import { DeviceDashboardVehicle } from "../Dashboard/DeviceDashboardVehicle";
import { DeviceDashboardAsset } from "../Dashboard/DeviceDashboardAsset";
import { Portal } from "../Portal";
import { UserConsumer } from "../Base";
import { hasPermission, Permission } from "../Permission";

export class TrackerStatisticsBase extends React.Component {
  imei;
  load;
  portal;

  constructor(props) {
    super(props);
    this.state = {
      loadingState: "loading",
      statistics: null,
      trackerDetail: null,
      deviceDashboard: null,
    };
    this.imei = this.props.match.params.imei || "";
    this.portal = this.props.match.params.portal || "";
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    Promise.all([
      this.props.requestStatistics(this.imei, getAWholeDaysPeriod(moment())),
      this.props.getTrackerDetail(this.imei),
      this.props.fetchDashboard.deviceDashboard(this.imei),
      this.props.getDeviceServiceIntervals(this.imei),
    ]).then(
      ([statistics, trackerDetail, deviceDashboard, serviceIntervals]) => {
        this.setState({
          statistics,
          trackerDetail,
          deviceDashboard,
          serviceIntervals,
          loadingState: "loaded",
        });
      },
    );
  }

  load(period) {
    return this.props.requestStatistics(this.imei, period).then(statistics => {
      this.setState({ statistics });
    });
  }

  sortByDate = items => {
    const sortParam = "planned_service_date";
    const direction = -1;

    if (!items) return;

    return items.length > 0
      ? []
          .concat(items)
          .sort((itemA, itemB) => {
            let paramFirst,
              paramSecond = "";
            paramFirst = new Date(itemA[sortParam]).getTime();
            paramSecond = new Date(itemB[sortParam]).getTime();
            if (paramFirst > paramSecond) {
              return -1 * direction;
            } else if (paramFirst < paramSecond) {
              return 1 * direction;
            } else {
              return 0;
            }
          })
          .splice(0, 4)
      : [];
  };

  setIntervalCompletion = async interval => {
    this.props
      .setIntervalCompletion(!interval.is_done, interval.service_interval_id)
      .then(() => {
        this.props
          .getDeviceServiceIntervals(this.imei)
          .then(serviceIntervals => {
            this.setState({ serviceIntervals });
          });
      });
  };

  render() {
    const { loadingState } = this.state;
    const serviceIntervals = this.sortByDate(this.state.serviceIntervals);

    if (loadingState === "loading") {
      return (
        <div className="outer-table-frame tracker">
          <div className="table-frame">
            <div className="spacer" />
            <LoadingCircle />
            <div className="spacer" />
          </div>
        </div>
      );
    }
    let dashboard;
    switch (this.portal) {
      case Portal.Auto:
        dashboard = (
          <DeviceDashboardAuto
            deviceDashboard={this.state.deviceDashboard}
            portal={this.portal}
            imei={this.imei}
            fetchAddress={this.props.fetchAddress}
            serviceIntervals={serviceIntervals}
            setIntervalCompletion={this.setIntervalCompletion}
          />
        );
        break;
      case Portal.Vehicle:
        dashboard = (
          <DeviceDashboardVehicle
            deviceDashboard={this.state.deviceDashboard}
            portal={this.portal}
            imei={this.imei}
            fetchAddress={this.props.fetchAddress}
            serviceIntervals={serviceIntervals}
            setIntervalCompletion={this.setIntervalCompletion}
          />
        );
        break;
      case Portal.Asset:
        dashboard = (
          <DeviceDashboardAsset
            deviceDashboard={this.state.deviceDashboard}
            portal={this.portal}
            imei={this.imei}
            fetchAddress={this.props.fetchAddress}
            serviceIntervals={serviceIntervals}
            setIntervalCompletion={this.setIntervalCompletion}
          />
        );
        break;
      default:
        dashboard = "";
    }

    if (this.state.statistics && this.state.trackerDetail) {
      return (
        <UserConsumer>
          {user => {
            return (
              hasPermission(user, Permission.Dashboard) && (
                <div>
                  {dashboard}
                  <TrackerStatistics
                    portal={this.portal}
                    statistics={this.state.statistics}
                    trackerDetail={this.state.trackerDetail}
                    load={this.load}
                  />
                </div>
              )
            );
          }}
        </UserConsumer>
      );
    }
    return null;
  }
}
