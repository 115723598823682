import React from "react";
import ReactDOM from "react-dom";
import { Intl } from "./Language";
import "./tools/polyfills/Object.prototype.find";
/// @see https://github.com/babel/babel/issues/4075#issuecomment-321467649
import "core-js/fn/symbol/iterator.js";
//import registerServiceWorker from "./registerServiceWorker";
require("dotenv").config();

/// deregister the service worker for now until the react router bug is fixed
/// @see  https://github.com/facebook/create-react-app/issues/3608
navigator &&
  navigator.serviceWorker &&
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });

ReactDOM.render(<Intl />, document.getElementById("root"));
//registerServiceWorker();
