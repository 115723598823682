import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TextInput } from "../TextInput";
import { LoadingSmallCircle } from "../LoadingCircle";
import { exchangeLetters } from "../utility";
import moment from "moment";
import { LoadingCircle } from "../LoadingCircle";
import { deviceSafetyZoneIsWholeDay } from "../DeviceSafetyZone/deviceSafetyZoneIsWholeDay";
import { GeofenceTableLine } from "./GeofenceTableLine";
import TimePicker from "rc-time-picker";

const terms = defineMessages({
  mon: {
    id: "weekdays.mon",
    defaultMessage: "Mo",
  },
  tue: {
    id: "weekdays.tue",
    defaultMessage: "Di",
  },
  wed: {
    id: "weekdays.wed",
    defaultMessage: "Mi",
  },
  thu: {
    id: "weekdays.thu",
    defaultMessage: "Do",
  },
  fri: {
    id: "weekdays.fri",
    defaultMessage: "Fr",
  },
  sat: {
    id: "weekdays.sat",
    defaultMessage: "Sa",
  },
  sun: {
    id: "weekdays.sun",
    defaultMessage: "So",
  },
});

export const getWeekdayList = t => [
  t(terms.mon),
  t(terms.tue),
  t(terms.wed),
  t(terms.thu),
  t(terms.fri),
  t(terms.sat),
  t(terms.sun),
];

class GeofenceTabClass extends React.Component {
  weekdayList;

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.geofenceSettings,
      isLoading: true,
      selectedGeofence: null,
      editGeofence: null,
      deletedGeofences: this.props.geofenceSettings.deletedGeofences || [],
    };

    this.weekdayList = getWeekdayList(props.intl.formatMessage);

    this.props.getGeofenceList().then(geofenceList => {
      this.setState({ isLoading: false, geofenceList });
    });
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    const settings = {
      ...this.props.geofenceSettings,
      exsisting_device_geofence: this.state.exsisting_device_geofence,
      deletedGeofences: this.state.deletedGeofences,
    };

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    if (this.state.resetSettings) {
      this.setState({
        exsisting_device_geofence: [],
        resetSettings: false,
        editGeofence: null,
      });
      this.props.resetDeviceSettings(["geofence_settings"]);
    } else {
      this.rememberSettings();
      if (this.props.isBulk) {
        this.state.exsisting_device_geofence.forEach(geofence => {
          let settingsToUpdate = {};

          Object.assign(settingsToUpdate, {
            new_geofence: geofence,
          });

          let settingsContainer = {};
          Object.assign(settingsContainer, {
            [this.props.tabType]: settingsToUpdate,
          });

          this.props.saveDeviceSettings(settingsContainer);
        });
      } else {
        this.props.setSettingsSaving("true");
        this.state.exsisting_device_geofence.forEach(geofence => {
          if (geofence.geofence_device_id === null) {
            this.props
              .getDeviceSafetyZones(this.props.imei, geofence)
              .then(() => {
                this.props.setSettingsSaving("done");
                this.props.loadTags();
              });
          } else {
            this.props
              .deviceSafetyZoneRequest(geofence.geofence_device_id, geofence)
              .then(() => {
                this.props.setSettingsSaving("done");
                this.props.loadTags();
              });
          }
        });

        this.state.exsisting_device_geofence.length === 0 &&
          this.state.deletedGeofences.length === 0 &&
          this.props.setSettingsSaving("done");

        this.state.deletedGeofences.forEach(geofence => {
          this.props.deleteDeviceSafetyZone(geofence).then(() => {
            this.props.setSettingsSaving("done");
            this.props.loadTags();
          });
        });
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const name = target.id;
    const value = target.value;
    let wire = this.state[name];
    wire.description = value;
    this.setState({ [name]: wire });

    this.props.setSettingsSaving("false");
  };

  updateState = updatedState => {
    this.props.setSettingsSaving("false");
    this.setState({ ...updatedState });
  };

  /**
   *
   * @param e
   * | { start_time: Moment }
   * | { end_time: Moment }
   * | "whole day"
   * | "time-dependent"
   */
  changeHours = e => {
    if (e === "whole day") {
      // noinspection JSUnusedLocalSymbols
      const editGeofence = {
        ...this.state.editGeofence,
        start_time: "00:00:00",
        end_time: "23:59:59",
      };
      this.setState({ editGeofence });
      return;
    }
    /// change only if it is not time-dependent already
    if (e === "time-dependent" && this.state.editGeofence) {
      const start_time = moment().set({
        hour: 8,
        minute: 0,
        second: 0,
      });
      const end_time = moment().set({
        hour: 18,
        minute: 0,
        second: 0,
      });

      const editGeofence = {
        ...this.state.editGeofence,
        start_time: start_time.format("HH:mm:ss"),
        end_time: end_time.format("HH:mm:ss"),
      };
      this.setState({ editGeofence });
      return;
    }

    if (typeof e === "object") {
      const editGeofence = { ...this.state.editGeofence, ...e };
      this.setState({ editGeofence }, () => {
        if (this.state.editGeofence != null) {
          let { start_time, end_time } = this.state.editGeofence;
          typeof start_time === "string" &&
            (start_time = moment(start_time, "HH:mm:ss"));
          typeof end_time === "string" &&
            (end_time = moment(end_time, "HH:mm:ss"));
          if (end_time && start_time && end_time.isBefore(start_time)) {
            const time = e.end_time || e.start_time;
            end_time = time.clone();
            start_time = time.clone();
            start_time = start_time.format("HH:mm:ss");
            end_time = end_time.format("HH:mm:ss");
            const editGeofence = {
              ...this.state.editGeofence,
              start_time,
              end_time,
            };
            this.setState({ editGeofence });
          }
        }
      });
    }
  };

  setAsLeaveAlarm = leave_alert => {
    const editGeofence = { ...this.state.editGeofence, leave_alert };
    this.setState({ editGeofence });
  };

  setWeekday = weekdayIndex => {
    const weekdayState =
      this.state.editGeofence &&
      this.state.editGeofence.day_frequency[weekdayIndex] === "1"
        ? "0"
        : "1";
    const day_frequency =
      (this.state.editGeofence &&
        exchangeLetters(
          this.state.editGeofence.day_frequency,
          weekdayState,
          weekdayIndex,
        )) ||
      "0000000";
    const editGeofence = { ...this.state.editGeofence, day_frequency };
    this.setState({ editGeofence });
  };

  handleSelect = e => {
    const geofence_id = parseInt(e.target.value, 10);
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const geofence_name = optionElement.getAttribute("geofence-name");
    const editGeofence = {
      ...this.state.editGeofence,
      geofence_id,
      geofence_name,
    };

    this.setState({ editGeofence });
  };

  selectGeofence = geofence => {
    this.setState({ selectedGeofence: geofence, editGeofence: geofence });
    this.props.setSettingsSaving("false");
  };

  addGeofence = () => {
    let updatedGeofenceList = this.state.exsisting_device_geofence || [];
    const newGeofence = this.getDefaultGeofence();
    updatedGeofenceList.push(newGeofence);
    this.setState({
      editGeofence: newGeofence,
      selectedGeofence: newGeofence,
      exsisting_device_geofence: updatedGeofenceList,
    });
    this.props.setSettingsSaving("false");
    this.scrollToBottom();
  };

  saveGeofence = () => {
    const updatedGeofence = this.state.editGeofence;
    let { start_time, end_time } = updatedGeofence;
    typeof start_time !== "string" &&
      (start_time = start_time.format("HH:mm:ss"));
    typeof end_time !== "string" && (end_time = end_time.format("HH:mm:ss"));
    const updatedGeofenceWithStringDate = {
      ...updatedGeofence,
      start_time,
      end_time,
    };
    let updatedGeofenceList = this.state.exsisting_device_geofence;
    const index = this.state.exsisting_device_geofence.findIndex(
      geofence => geofence === this.state.selectedGeofence,
    );
    updatedGeofenceList[index] = updatedGeofenceWithStringDate;
    this.setState({
      selectedGeofence: updatedGeofenceWithStringDate,
      exsisting_device_geofence: updatedGeofenceList,
    });
    this.props.setSettingsSaving("false");
  };

  deleteGeofence = () => {
    let updatedGeofenceList = this.state.exsisting_device_geofence;
    const index = this.state.exsisting_device_geofence.findIndex(
      geofence => geofence === this.state.selectedGeofence,
    );
    let deletedGeofences = this.state.deletedGeofences;
    updatedGeofenceList[index].geofence_device_id !== null &&
      deletedGeofences.push(updatedGeofenceList[index].geofence_device_id);
    updatedGeofenceList.splice(index, 1);
    this.setState({
      exsisting_device_geofence: updatedGeofenceList,
      editGeofence: null,
      deletedGeofences,
    });
  };

  getDefaultGeofence = () => {
    return {
      day_frequency: "0000000",
      geofence_id: this.state.geofenceList[0].geofence_id || "",
      geofence_name: this.state.geofenceList[0].name || "",
      leave_alert: false,
      geofence_device_id: null,
      start_time: "00:00:00",
      end_time: "23:59:59",
    };
  };

  toggleResetSettings = () => {
    this.setState({ resetSettings: !this.state.resetSettings });
    this.props.setSettingsSaving("false");
  };

  scrollToBottom = async () => {
    await this.Sleep(10);
    var objDiv = document.getElementById("geofence-table");
    objDiv && (objDiv.scrollTop = objDiv.scrollHeight);
  };

  Sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  render() {
    const t = this.props.intl.formatMessage;

    if (this.state.isLoading) {
      return <LoadingCircle />;
    } else {
      const { day_frequency } = this.state.editGeofence || {};

      return (
        <React.Fragment>
          <div className="content-container">
            <div className="content">
              <div className="grid-x grid-margin-x">
                <div
                  className="medium-6 large-6 cell geofence-table"
                  id="geofence-table"
                >
                  <div className="table-frame">
                    {!this.state.resetSettings &&
                      (this.props.isBulk ? (
                        <div dir="ltr">
                          <T
                            id="tracar.device-safety-zones.bulk"
                            defaultMessage="Bei den Einstellungen für mehrere Geräte kann die Ansicht der zugewiesenen Sicherheitszonen nicht erstellt werden. Es können nur die neuhinzugefügten Sicherheitszonen angezeigt werden."
                          />
                        </div>
                      ) : (
                        ((this.state.exsisting_device_geofence &&
                          this.state.exsisting_device_geofence.length === 0) ||
                          !this.state.exsisting_device_geofence) && (
                          <div className="table-line" dir="ltr">
                            <T
                              id="tracar.device-safety-zones.noZones"
                              defaultMessage="Sie haben noch keine Sicherheitszonen zugeordnet."
                            />
                          </div>
                        )
                      ))}
                    {this.state.resetSettings ? (
                      <div className="table-line" dir="ltr">
                        <T
                          id="tracar.device-safety-zones.resetZones"
                          defaultMessage="Sie haben alle Sicherheitszonen entfernt. Bitte bestätigen Sie die Änderungen mittels 'Sicherheitszonen aktualisieren'."
                        />
                      </div>
                    ) : (
                      this.state.exsisting_device_geofence &&
                      this.state.exsisting_device_geofence.map(
                        (geofence, index) => (
                          <GeofenceTableLine
                            key={index}
                            geofence={geofence}
                            deleteGeofence={this.deleteGeofence}
                            selectGeofence={this.selectGeofence}
                            isSelected={
                              this.state.selectedGeofence === geofence
                                ? true
                                : false
                            }
                            readOnly={this.props.readOnly}
                          />
                        ),
                      )
                    )}
                    {!this.props.readOnly && (
                      <div className="settings-bulk-buttons-container">
                        <div
                          className="button settings-bulk-button delete-button"
                          onClick={() => this.toggleResetSettings()}
                        >
                          {this.state.resetSettings ? (
                            <T
                              id="tracar.settings.undo"
                              defaultMessage="Rückgängig machen"
                            />
                          ) : (
                            <T
                              id="tracar.settings-geofence.reset"
                              defaultMessage="Alle Sicherheitszonen löschen"
                            />
                          )}
                          <i className="icon-uniF2D3" />
                        </div>
                        {!this.state.resetSettings && (
                          <div
                            className="button settings-bulk-button confirm-button"
                            onClick={() => this.addGeofence()}
                          >
                            <T
                              id="tracar.device-safety-zones.add"
                              defaultMessage="Sicherheitszone hinzufügen"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`medium-6 large-6 cell geofence-info-window ${(this
                    .state.editGeofence === null ||
                    this.state.resetSettings) &&
                    "no-zone-selected"}`}
                >
                  {this.state.editGeofence === null ||
                  this.state.resetSettings ? (
                    <div className="geofence-info-window-content">
                      {!this.props.readOnly && (
                        <T
                          id="tracar.device-safety-zones.noZoneSelected"
                          defaultMessage="Sie haben keine Sicherheitszone ausgewählt."
                        />
                      )}
                    </div>
                  ) : (
                    <div className="geofence-info-window-content">
                      <div className="grid-x grid-margin-x">
                        <div className="medium-12 large-12 cell">
                          <div className="select-frame">
                            <i className="fa fa-angle-down angle" />
                            <select
                              className="select"
                              id="safety-zone"
                              value={
                                this.state.editGeofence &&
                                this.state.editGeofence.geofence_id
                              }
                              geofence-name={
                                this.state.editGeofence &&
                                this.state.editGeofence.name
                              }
                              onChange={this.handleSelect}
                            >
                              {this.state.geofenceList.map(geofence => (
                                <option
                                  value={geofence.geofence_id}
                                  geofence-name={geofence.name}
                                  key={geofence.geofence_id}
                                >
                                  {geofence.name}
                                </option>
                              ))}
                            </select>
                            <label htmlFor="safety-zone">
                              <T
                                id="tracar.device-safety-zone-detail.safetyZone"
                                defaultMessage="Sicherheitszone"
                              />
                            </label>
                          </div>
                        </div>
                        <div className="medium-6 large-6 cell">
                          <div className="weekday-input">
                            <div className="label">
                              <T
                                id="tracar.device-safety-zone-detail.type"
                                defaultMessage="Alarmtyp"
                              />
                              &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="weekday-buttons">
                              <div
                                className={`button ${
                                  this.state.editGeofence &&
                                  this.state.editGeofence.leave_alert === false
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.setAsLeaveAlarm(false)}
                              >
                                <i className="fa fa-sign-in" /> &nbsp;{" "}
                                <T
                                  id="tracar.device-safety-zone-detail.alarmIn"
                                  defaultMessage="Einfahrt"
                                />
                              </div>
                              <div
                                className={`button ${
                                  this.state.editGeofence &&
                                  this.state.editGeofence.leave_alert === true
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.setAsLeaveAlarm(true)}
                              >
                                <i className="fa fa-sign-out" /> &nbsp;{" "}
                                <T
                                  id="tracar.device-safety-zone-detail.alarmOut"
                                  defaultMessage="Verlassen"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="medium-6 large-6 cell">
                          <div className="weekday-input">
                            <div className="label">
                              <T
                                id="tracar.device-safety-zone-detail.activeDays"
                                defaultMessage="Zu folgenden Tagen aktiv"
                              />
                              &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="weekday-buttons">
                              {this.weekdayList.map((weekday, index) => (
                                <div
                                  key={index}
                                  className={`button ${
                                    day_frequency[index] === "1" ? "active" : ""
                                  }`}
                                  onClick={() => this.setWeekday(index)}
                                >
                                  {weekday}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="medium-6 large-6 cell">
                          <div className="weekday-input">
                            <div className="label">
                              <T
                                id="tracar.device-safety-zone-detail.time"
                                defaultMessage="Zeit"
                              />
                              &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className="weekday-buttons">
                              <div
                                className={`button ${
                                  /* TODO have a look at union types to prevent such void-checks*/
                                  deviceSafetyZoneIsWholeDay(
                                    this.state.editGeofence,
                                  )
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => this.changeHours("whole day")}
                              >
                                <T
                                  id="tracar.device-safety-zone-detail.wholeDay"
                                  defaultMessage="ganztägig"
                                />
                              </div>
                              <div
                                className={`button ${
                                  !deviceSafetyZoneIsWholeDay(
                                    this.state.editGeofence,
                                  )
                                    ? " active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.changeHours("time-dependent")
                                }
                              >
                                <T
                                  id="tracar.device-safety-zone-detail.timeDependent"
                                  defaultMessage="Uhrzeitabhängig"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {!deviceSafetyZoneIsWholeDay(
                          this.state.editGeofence,
                        ) && (
                          <div className="medium-6 large-6 cell">
                            <div className="hour-select">
                              <div className="hour-button">
                                <div className="label">
                                  <T
                                    id="tracar.device-safety-zone-detail.start"
                                    defaultMessage="Start"
                                  />
                                </div>
                                <TimePicker
                                  id="timepicker-start"
                                  defaultValue={moment()}
                                  value={moment(
                                    this.state.editGeofence.start_time,
                                    "hh:mm:ss",
                                  )}
                                  showSecond={true}
                                  disabled={false}
                                  allowEmpty={false}
                                  onChange={start_time =>
                                    this.changeHours({ start_time })
                                  }
                                  minuteStep={1}
                                />
                              </div>
                              <div className="hour-button">
                                <div className="label">
                                  <T
                                    id="tracar.device-safety-zone-detail.end"
                                    defaultMessage="Ende"
                                  />
                                </div>
                                <TimePicker
                                  id="timepicker-end"
                                  defaultValue={moment()}
                                  value={moment(
                                    this.state.editGeofence.end_time,
                                    "hh:mm:ss",
                                  )}
                                  showSecond={true}
                                  disabled={false}
                                  allowEmpty={false}
                                  onChange={end_time =>
                                    this.changeHours({ end_time })
                                  }
                                  minuteStep={1}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="grid-x grid-margin-x settings-bulk-buttons-container">
                        <div
                          className="medium-6 large-6 cell button settings-bulk-button delete-button"
                          onClick={() => this.deleteGeofence()}
                        >
                          <T
                            id="tracar.device-safety-zone-detail.delete"
                            defaultMessage="Sicherheitszone entfernen"
                          />
                          <i className="icon-uniF2D3" />
                        </div>
                        <div
                          className="medium-6 large-6 cell button settings-bulk-button confirm-button"
                          onClick={() => this.saveGeofence()}
                        >
                          <T
                            id="tracar.device-safety-zone-detail.save"
                            defaultMessage="Daten speichern"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.renderFooter()}
        </React.Fragment>
      );
    }
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/${
              this.props.portal === "admin-panel"
                ? "device/list"
                : "device-list"
            }`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          {!this.props.readOnly && (
            <div
              className={`button save-button ${this.props.settingsSaving ===
                "true" && "saving"} ${this.props.settingsSaving === "done" &&
                "saved"}`}
              onClick={() =>
                this.props.settingsSaving === "false" &&
                this.saveDeviceSettings()
              }
            >
              {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
              <span>
                {this.props.settingsSaving === "true" && (
                  <T
                    id="tracar.settings.geofences.saving"
                    defaultMessage="Sicherheitszonen werden aktualisiert..."
                  />
                )}
                {this.props.settingsSaving === "false" && (
                  <T
                    id="tracar.settings.geofences.save"
                    defaultMessage="Sicherheitszonen aktualisieren"
                  />
                )}
                {this.props.settingsSaving === "done" && (
                  <T
                    id="tracar.settings.geofences.saved"
                    defaultMessage="Sicherheitszonen aktualisiert"
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const GeofenceTab = injectIntl(GeofenceTabClass);
