
export const ColorName = {
  "#E60000": "red",
  "#F0861B": "orange",
  "#F1C700": "banana",
  "#AC4321": "brown",
  "#2D862D": "green",
  "#9B568D": "old-pink",
  "#8E8716": "olive-green",
  "#E85B8E": "pink",
  "#853252": "plum",
  "#122372": "royal-blue",
  "#009BDC": "sky-blue",
  "#B6A99B": "stone",
};

export let trackerColors = [];
for (var hex in ColorName) {
  if (ColorName.hasOwnProperty(hex)) {
    trackerColors.push(hex);
  }
}
