import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { ExpenseCreateInline } from "./ExpenseCreateInline";
import moment from "moment/moment";
import { PaginationBar } from "../PaginationBar";
import { ExpenseEditAdminExpenseInline } from "./ExpenseEditAdminExpenseInline";
import { ExpenseEditUserExpenseInline } from "./ExpenseEditUserExpenseInline";
import { QueryLine } from "../../tools/QueryLine";
import { CostCategoryType } from "../../ApiContract";
import { SelectInput } from "../../SelectInput";

const terms = defineMessages({
  label_new_expense_button: {
    id: "adminPanel.expense.detailOverlay.buttonLabel.createExpense",
    defaultMessage: "Neue Kosten erfassen",
  },
  categoryFilterAll: {
    id: "adminPanel.expense.detailOverlay.filter.category.all",
    defaultMessage: "Alle Kategorien",
  },
  acknowledgeFilterAll: {
    id: "adminPanel.expense.detailOverlay.filter.acknowledge.all",
    defaultMessage: "Bestätigt und unbestätigt",
  },
  acknowledgeFilterTrue: {
    id: "adminPanel.expense.detailOverlay.filter.isAcknowledged",
    defaultMessage: "Bestätigt",
  },
  acknowledgeFilterFalse: {
    id: "adminPanel.expense.detailOverlay.filter.isNotAcknowledged",
    defaultMessage: "Unbestätigt",
  },
});
const listLimit = 10;

class ExpenseDetailOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showMore: false,
      showInnerCreateOverlay: false,
      showInnerAdminExpenseEditOverlay: false,
      showInnerUserExpenseEditOverlay: false,
      requestOverlayClassName: "",
      recordList: [],
      expenseCategoryList: [],
      selectedCategory: null,
      selectedExpense: null,
      sortField: "start_date",
      sortDirection: "desc",
      expenseListSearchKey: "",
      expenseListAcknowledgeFilter: "",
      totalCost: 0,
      // total number of devices across all pagination pages
      numberOfDevices: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // used by pagination to fetch the devices displayed on the current pagination page
      listOffset: 0,
    };
  }

  async componentDidMount() {
    if (this.props.expense) {
      if (this.props.expense.admin_cost) {
        this.toggleInnerAdminExpenseEditOverlay(this.props.expense);
      } else {
        this.toggleInnerUserExpenseEditOverlay(this.props.expense);
      }
    } else {
      this.load();
    }
  }

  async load() {
    const t = this.props.intl.formatMessage;
    this.props
      .getExpenseListForDevice(
        this.props.device.imei,
        this.state.listOffset,
        listLimit,
        this.state.sortField,
        this.state.sortDirection,
        this.state.expenseListSearchKey,
        this.state.selectedCategory,
        this.state.expenseListAcknowledgeFilter,
      )
      .then(recordListData =>
        this.setState({
          loading: false,
          recordList: recordListData.receipts,
          totalCost: recordListData.total_cost,
          numberOfDevices: recordListData.total_amount,
          numberOfPages: Math.ceil(recordListData.total_amount / listLimit),
        }),
      );
    this.props
      .getExpenseCategoryList(CostCategoryType.All)
      .then(recordListData => {
        recordListData.map(
          record =>
            (record.translatedName = t({
              id: `expense.category.${record.category_name}`,
              defaultMessage: record.category_name,
            })),
        );
        this.setState({
          loading: false,
          expenseCategoryList: [
            {
              translatedName: t(terms.categoryFilterAll),
              category_name: "ALL",
            },
            ...recordListData,
          ],
        });
      });
  }

  async acknowledgeExpense(expenseId) {
    this.props.acknowledgeExpense(expenseId).then(() => this.load());
  }

  async deleteExpense(expenseId) {
    this.props.deleteExpense(expenseId).then(() => this.load());
  }

  async sortList(sortField, sortDirection) {
    this.setState({ sortField, sortDirection }, () => this.load());
  }

  setSearchFilter = expenseListSearchKey => {
    this.setState({ expenseListSearchKey }, this.load);
  };

  setAcknowledgeFilter = event => {
    this.setState(
      { expenseListAcknowledgeFilter: event.target.value },
      this.load,
    );
  };

  setCategoryFilter = event => {
    this.setState({ [event.target.id]: event.target.value }, this.load);
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        className={this.state.requestOverlayClassName}
        closeFunction={this.props.closeFunction}
      >
        <div className="header margin-bottom-1">
          <h3>
            <T
              id="adminPanel.expense.expenseDetail.headline"
              defaultMessage="Poolfahrzeug"
            />{" "}
            <span className="margin-horizontal-1">></span>
            <span className="highlighted">{this.props.device.imei}</span>
            {(this.state.showInnerAdminExpenseEditOverlay ||
              this.state.showInnerUserExpenseEditOverlay) && (
              <span className="margin-horizontal-1">></span>
            )}
            <span className="modal-lvl2">
              {this.state.showInnerAdminExpenseEditOverlay ? (
                <T
                  id="adminPanel.expense.expenseDetail.headlineAdminCost"
                  defaultMessage="Fixkosten"
                />
              ) : (
                this.state.showInnerUserExpenseEditOverlay && (
                  <T
                    id="adminPanel.expense.expenseDetail.headlineUserCost"
                    defaultMessage="Einzelkosten"
                  />
                )
              )}
            </span>
          </h3>
        </div>
        {!this.state.showInnerCreateOverlay &&
          !this.state.showInnerUserExpenseEditOverlay &&
          !this.state.showInnerAdminExpenseEditOverlay && (
            <React.Fragment>
              <div className="grid-container">
                <div className="grid-x grid-margin-x">
                  <div className="cell">
                    <QueryLine
                      changeCallback={this.setSearchFilter}
                      changeDelay={500}
                    />
                  </div>
                  <select
                    className="cell small-3 margin-0"
                    onChange={this.setAcknowledgeFilter}
                  >
                    <option value="all">
                      <T
                        id="adminPanel.expense.detailOverlay.filter.acknowledge.all"
                        defaultMessage="Bestätigt und unbestätigt"
                      />
                    </option>
                    <option value="isAcknowledged">
                      <T
                        id="adminPanel.expense.detailOverlay.filter.isAcknowledged"
                        defaultMessage="Bestätigt"
                      />
                    </option>
                    <option value="isNotAcknowledged">
                      <T
                        id="adminPanel.expense.detailOverlay.filter.isNotAcknowledged"
                        defaultMessage="Unbestätigt"
                      />
                    </option>
                  </select>
                  <div className="cell small-3">
                    <SelectInput
                      id="selectedCategory"
                      // name={t(terms.user_role_label)}
                      value={this.state.selectedCategory}
                      nameProp={"translatedName"}
                      valueProp={"category_name"}
                      options={this.state.expenseCategoryList}
                      onChange={this.setCategoryFilter}
                    />
                  </div>
                  <div className="cell auto text-right">
                    <div
                      className="button primary"
                      onClick={this.toggleInnerCreateOverlay}
                    >
                      {t(terms.label_new_expense_button)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-frame filter-table admin-list">
                <div className="grid-x table-line filter text-left">
                  <div className="cell small-12 medium-6 large-2">
                    <a className="" href="#">
                      <T
                        id="adminPanel.expense.list.line.label.category"
                        defaultMessage="Kostenart"
                      />
                    </a>
                  </div>
                  <div className="cell small-12 medium-6 large-1">
                    <a
                      className=""
                      href="#"
                      onClick={() =>
                        this.sortList(
                          "start_date",
                          `${
                            this.state.sortField === "start_date" &&
                            this.state.sortDirection === "asc"
                              ? "desc"
                              : "asc"
                          }`,
                        )
                      }
                    >
                      <T
                        id="adminPanel.expense.list.line.label.date"
                        defaultMessage="Datum"
                      />
                      <i
                        className={`fa relative fa-sort${
                          this.state.sortField === "start_date" &&
                          this.state.sortDirection === "asc"
                            ? `-up`
                            : this.state.sortField === "start_date" &&
                              this.state.sortDirection === "desc"
                            ? "-down"
                            : ""
                        }`}
                      />
                    </a>
                  </div>
                  <div className="cell small-12 medium-6 large-4">
                    <T
                      id="adminPanel.expense.list.line.label.description"
                      defaultMessage="Beschreibung"
                    />
                  </div>
                  <div className="cell small-12 medium-6 large-3 text-right">
                    <a
                      className=""
                      href="#"
                      onClick={() =>
                        this.sortList(
                          "amount",
                          `${
                            this.state.sortField === "amount" &&
                            this.state.sortDirection === "asc"
                              ? "desc"
                              : "asc"
                          }`,
                        )
                      }
                    >
                      <strong>
                        <T
                          id="adminPanel.expense.list.line.label.amount"
                          defaultMessage="Kosten"
                        />{" "}
                        (
                        <T
                          id="adminPanel.expense.list.line.label.total"
                          defaultMessage="Bestätigte Belege"
                        />
                        : {this.state.totalCost}€)
                      </strong>
                      <i
                        className={`fa relative fa-sort${
                          this.state.sortField === "amount" &&
                          this.state.sortDirection === "asc"
                            ? `-up`
                            : this.state.sortField === "amount" &&
                              this.state.sortDirection === "desc"
                            ? "-down"
                            : ""
                        }`}
                      />
                    </a>
                  </div>
                  <div className="cell small-12 medium-1 large-2" />
                </div>

                {this.state.recordList.map(expense => (
                  <div
                    key={expense.id}
                    className="table-line grid-x gird-padding-x align-center-middle pool-vehicle detail-price-list text-left"
                  >
                    <div className="cell small-12 medium-6 large-2 first-cell">
                      <strong>
                        <T
                          id={`expense.category.${expense.category_name}`}
                          defaultMessage={expense.category_name}
                        />
                      </strong>
                    </div>
                    <div className="cell small-12 medium-6 large-1">
                      {moment(expense.start_date).format("DD.MM.YYYY")}
                    </div>
                    <div className="cell small-12 medium-12 large-4">
                      {expense.description}
                    </div>
                    <div className="cell small-12 medium-12 large-3 text-right">
                      <strong>{expense.amount}€</strong>
                    </div>
                    <div className="cell small-12 large-2 flex align-justify padding-left-3 icon-wrap">
                      {expense.admin_cost && (
                        <a href="#">
                          <i className={`fa fa-check-circle disabled green`} />
                        </a>
                      )}
                      {!expense.admin_cost && (
                        <a
                          href="#"
                          onClick={() => this.acknowledgeExpense(expense.id)}
                        >
                          <i
                            className={`fa fa-check-circle ${
                              expense.is_acknowledged ? "green" : ""
                            }`}
                          />
                        </a>
                      )}
                      {expense.image && (
                        <a
                          href="#"
                          onClick={() => this.props.getExpenseImage(expense.id)}
                        >
                          <i className="fa fa-arrow-circle-o-down" />
                        </a>
                      )}
                      {!expense.image && (
                        <a href="#">
                          <i className="fa fa-arrow-circle-o-down disabled" />
                        </a>
                      )}
                      <a
                        href="#"
                        onClick={() =>
                          expense.admin_cost
                            ? this.toggleInnerAdminExpenseEditOverlay(expense)
                            : this.toggleInnerUserExpenseEditOverlay(expense)
                        }
                      >
                        <i className="fa fa-pencil" />
                      </a>
                      <a
                        href="#"
                        onClick={() => this.deleteExpense(expense.id)}
                      >
                        <i className="fa fa-trash" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <PaginationBar
                currentPage={this.state.currentPage}
                numberOfPages={this.state.numberOfPages}
                updateCurrentPage={this.updatePagination}
              />
            </React.Fragment>
          )}

        {this.state.showInnerCreateOverlay && (
          <ExpenseCreateInline
            closeFunction={this.toggleInnerCreateOverlay}
            createExpense={this.props.createExpense}
            showInnerSaveButtons={true}
            getExpenseCategoryList={this.props.getExpenseCategoryList}
            showInnerCreateOverlay={this.state.showInnerCreateOverlay}
            imei={this.props.device.imei}
          />
        )}

        {this.state.showInnerAdminExpenseEditOverlay && (
          <ExpenseEditAdminExpenseInline
            closeFunction={this.toggleInnerAdminExpenseEditOverlay}
            expense={this.state.selectedExpense}
            editExpense={this.props.editAdminExpense}
            showInnerSaveButtons={true}
            getExpenseCategoryList={this.props.getExpenseCategoryList}
            showInnerEditOverlay={this.state.showInnerEditOverlay}
          />
        )}

        {this.state.showInnerUserExpenseEditOverlay && (
          <ExpenseEditUserExpenseInline
            closeFunction={this.toggleInnerUserExpenseEditOverlay}
            expense={this.state.selectedExpense}
            editExpense={this.props.editAdminExpense}
            showInnerSaveButtons={true}
            getExpenseCategoryList={this.props.getExpenseCategoryList}
            showInnerEditOverlay={this.state.showInnerEditOverlay}
          />
        )}

        {!this.state.showInnerCreateOverlay &&
          !this.state.showInnerUserExpenseEditOverlay &&
          !this.state.showInnerAdminExpenseEditOverlay && (
            <div>
              <React.Fragment>
                <div className="small-spacer" />
                <div className="footer text-right">
                  <div
                    className="button transparent"
                    onClick={this.props.closeFunction}
                  >
                    <T
                      id="adminPanel.expense.detailOverlay.buttonLabel.cancel"
                      defaultMessage="Schließen"
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
      </RequestOverlay>
    );
  }

  toggleInnerCreateOverlay = () => {
    this.setState(
      {
        showInnerCreateOverlay: !this.state.showInnerCreateOverlay,
        requestOverlayClassName:
          this.state.requestOverlayClassName === "" ? "" : "",
      },
      () => {
        this.load();
      },
    );
  };

  toggleInnerAdminExpenseEditOverlay = expense => {
    this.setState(
      {
        showInnerAdminExpenseEditOverlay: !this.state
          .showInnerAdminExpenseEditOverlay,
        selectedExpense: expense,
        requestOverlayClassName:
          this.state.requestOverlayClassName === "" ? "" : "",
      },
      () => {
        this.load();
      },
    );
  };

  toggleInnerUserExpenseEditOverlay = expense => {
    this.setState(
      {
        showInnerUserExpenseEditOverlay: !this.state
          .showInnerUserExpenseEditOverlay,
        selectedExpense: expense,
        requestOverlayClassName:
          this.state.requestOverlayClassName === "" ? "" : "",
      },
      () => {
        this.load();
      },
    );
  };

  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * listLimit);
  };

  updateListOffset = offset => {
    this.setState({ listOffset: offset }, this.load);
  };
}

export const ExpenseDetailOverlay = injectIntl(ExpenseDetailOverlayClass);
