import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import Link from "react-router-dom/es/Link";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

export function NewUpdatesCheckOverlay({ closeFunction }) {
  return (
    <RequestOverlay
      closeFunction={closeFunction}
      className="drivers-license-warning"
    >
      <h3>
        <i className="fa fa-info error" />
        &nbsp;&nbsp;&nbsp;{" "}
        <T id="tracar.updatePopup.header" defaultMessage="Neue Updates" />
      </h3>
      <div>
        <div>
          <T
            id="tracar.updatePopup.text"
            defaultMessage="Es gibt neue Updates. Klicken Sie den untenstehenden Link, um mehr darüber in unserer FAQ zu erfahren."
          />
          <div className="small-spacer" />
          <Link to={`faq`} className="button" onClick={closeFunction}>
            <T id="tracar.updatePopup.link" defaultMessage="FAQ" />
          </Link>
        </div>
        <div className="small-spacer" />
      </div>
      <div className="spacer" />
      <div className="button save-button" onClick={closeFunction}>
        <T id="tracar.updatePopup.close" defaultMessage="Schliessen" />
      </div>
    </RequestOverlay>
  );
}
