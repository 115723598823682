import React from "react";
import Plot from "react-plotly.js";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import numeral from "numeral";
import { sToh } from "../tools/functions";
import { Redirect } from "react-router";
import { FormattedMessage as T } from "react-intl";
import { Portal } from "../Portal";
import { AccClientConfig } from "../ClientSpecificContent/acc-index";

export class VehicleUsageLast30D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  setRedirect = plotlyClickEvent => {
    const { imei } = this.props.usage_per_device[
      plotlyClickEvent.points[0].pointIndex
    ];
    this.setState({ redirect: `/vehicle/statistics/${imei}` });
  };

  render() {
    if (this.state.redirect != null) {
      return <Redirect push to={this.state.redirect} />;
    }

    const {
      config,
      noAxisValues,
      usage_per_device: usage_data,
      widePlotLayoutCommons,
    } = this.props;

    /// same code is found in DeviceRangeGraph
    const x = usage_data.map(({ device_name }) => device_name);

    // extract max set of keys
    let graphKeys = [];
    usage_data.forEach(element =>
      Object.keys(element).forEach(
        key => !graphKeys.includes(key) && graphKeys.push(key),
      ),
    );
    let graphData = [];
    graphKeys.forEach(graphKey => {
      switch (graphKey) {
        // skip imei and device_name
        case "device_name":
        case "imei":
          break;
        case "usage_ignition":
          graphData[graphKey] = usage_data.map(({ usage_ignition }) =>
            sToh(parseFloat(usage_ignition)),
          );
          break;
        case "usage_engine":
          graphData[graphKey] = usage_data.map(({ usage_engine }) =>
            sToh(parseFloat(usage_engine)),
          );
          break;
        // parse varying amount of cables
        default:
          graphData[graphKey] = usage_data.map(({ [graphKey]: element }) => {
            if (element && element.data) {
              return sToh(parseFloat(element.data));
            }
            return 0;
          });
      }
    });
    const nf = (n: number) => numeral(n).format("0,0.0");

    const textLabel = x.map((device_name, index) => {
      let returnValue = "";
      graphKeys.forEach(value => {
        switch (value) {
          // skip device_name and imei
          case "device_name":
          case "imei":
            break;
          case "usage_ignition":
            returnValue += `Zündung:  ${nf(
              graphData["usage_ignition"][index],
            )} h<br />`;
            break;
          case "usage_engine":
            returnValue += `Motor:  ${nf(
              graphData["usage_engine"][index],
            )} h<br />`;
            break;
          // parse varying amount of cables
          default:
            if (graphData[value]) {
              if (usage_data[index][value] && usage_data[index][value].name) {
                returnValue += `${usage_data[index][value].name}:  ${nf(
                  graphData[value][index],
                )} h<br />`;
              }
            }
        }
      });

      return returnValue;
    });

    // a device can have a maximum of four cables, so we need up to four colors
    const wireColors = [
      Color.OuterSpace,
      Color.Silver,
      Color.SilverChalice,
      Color.SilverChalice1,
    ];

    // clone wire colors since we need to restore them later
    let wireColorsTemp = [...wireColors];

    let groupedBarChart = [];

    graphKeys.forEach(value => {
      switch (value) {
        // skip device_name and imei
        case "device_name":
        case "imei":
          break;
        case "usage_ignition":
          groupedBarChart.push({
            x,
            y: graphData[value],
            marker: { color: Color.Red },
            type: "bar",
            hoverinfo: "x+text",
            textformat: "x+y+text",
            text: textLabel,
          });
          break;
        case "usage_engine":
          groupedBarChart.push({
            x,
            y: graphData[value],
            marker: { color: Color.DoveGrey },
            type: "bar",
            hoverinfo: "none",
          });
          break;
        // parse varying amount of cables
        default:
          groupedBarChart.push({
            x,
            y: graphData[value],
            marker: { color: wireColorsTemp.pop() },
            type: "bar",
            hoverinfo: "none",
          });
      }
    });
    // restore wire colors
    wireColorsTemp = [...wireColors];

    return (
      <div className="wide-graph-frame">
        <div className="graph-headline">
          <h5 className="headline-for-icon">
            {this.props.portal === Portal.Vehicle &&
            AccClientConfig.vehicleIsBoat ? (
              <i className="fa fa-ship" />
            ) : (
              <i className="fa fa-truck" />
            )}
            <T
              id="tracar.vehicleUsage.usage30d"
              defaultMessage="Gerätenutzung, letzte 30 Tage"
            />
          </h5>
        </div>
        <div className="tiny-spacer" />
        <Plot
          onClick={this.setRedirect}
          config={config}
          data={groupedBarChart}
          layout={{
            ...widePlotLayoutCommons,
            margin: widePlotLayoutCommons.margin,
            yaxis: { title: "h", tickformat: ",.0f," },
            xaxis: {
              ...noAxisValues,
              type: "category",
            },
          }}
        />
        <ul style={{ marginLeft: "16px", marginTop: "-28px" }}>
          <li>
            <i className="fa fa-square" style={{ color: Color.Red }} />
            &nbsp;&nbsp;
            <T id="tracar.vehicleUsage.ignition" defaultMessage="Zündung" />
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i className="fa fa-square" style={{ color: Color.DoveGrey }} />
            &nbsp;&nbsp;
            <T id="tracar.vehicleUsage.engine" defaultMessage="Motor" />{" "}
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i
              className="fa fa-square"
              style={{ color: wireColorsTemp.pop() }}
            />
            &nbsp;&nbsp;
            <T id="tracar.wires.digital1" defaultMessage="Digitaler Input 1" />
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i
              className="fa fa-square"
              style={{ color: wireColorsTemp.pop() }}
            />
            &nbsp;&nbsp;
            <T id="tracar.wires.digital2" defaultMessage="Digitaler Input 2" />
            &nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <i
              className="fa fa-square"
              style={{ color: wireColorsTemp.pop() }}
            />{" "}
            <T id="tracar.wires.digital3" defaultMessage="Digitaler Input 3" />
          </li>
        </ul>
      </div>
    );
  }
}
