import React from "react";
import { DashboardMessageList } from "./DashboardMessageList";
import { DashboardServiceIntervalList } from "./DashboardServiceIntervalList";
import { BatteryGraph } from "./BatteryGraph";
import { LastSeenPie } from "./LastSeenPie";
import { FormattedMessage as T } from "react-intl";
import { Portal } from "../Portal";

export function DashboardCommonAsset({
  messages,
  battery,
  last_seen,
  portal,
  serviceIntervals,
  setIntervalCompletion,
  history,
}) {
  const config = { displayModeBar: false };
  const plotLayoutCommons = {
    separators: ",.",
    title: "",
    paper_bgcolor: "rgba(255,255,255,0)",
    plot_bgcolor: "rgba(255,255,255,0)",
    showlegend: false,
    width: 320,
    height: 210,
    margin: { t: 0, b: 25, l: 45, r: 15 },
  };

  const widePlotLayoutCommons = {
    ...plotLayoutCommons,
    width: 662,
  };

  const noAxisValues = {
    autorange: true,
    showgrid: false,
    zeroline: false,
    showline: false,
    autotick: true,
    ticks: "",
    showticklabels: false,
  };

  return (
    <div className="outer-table-frame dashboard">
      <div className="table-frame">
        <div className="table-header">
          <i className="fa fa-tachometer" />
          <T id="base.dashboard" defaultMessage="Dashboard" />
        </div>
        <div className="all-graphs-outer-frame">
          <div className="all-graphs-frame">
            <DashboardMessageList messages={messages} portal={Portal.Asset} />

            <DashboardServiceIntervalList
              serviceIntervals={serviceIntervals}
              setIntervalCompletion={setIntervalCompletion}
              portal={Portal.Asset}
            />

            <LastSeenPie
              last_seen={last_seen}
              config={config}
              plotLayoutCommons={plotLayoutCommons}
              portal={Portal.Asset}
              history={history}
            />

            <BatteryGraph
              battery_yesterday={battery}
              config={config}
              noAxisValues={noAxisValues}
              widePlotLayoutCommons={widePlotLayoutCommons}
              history={history}
              portal={portal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
