import React from "react";
import { TextInput } from "../TextInput";
import { LoadingCircle } from "../LoadingCircle";
import { RequestOverlay } from "../Request/RequestOverlay";
import { handleInputChange } from "../tools/handleInputChange";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  chip_code: {
    id: "tracar.rfid-chip-add.chip_code",
    defaultMessage: "Chip-Code:",
    label: "Chip-Code",
  },
  chip_name: {
    id: "tracar.rfid-chip-add.chip_name",
    defaultMessage: "Chip-Name:",
    label: "Chip-Name",
  },
  fname: {
    id: "tracar.rfid-chip-add.fname",
    defaultMessage: "Vorname:",
    label: "Vorname",
  },
  lname: {
    id: "tracar.rfid-chip-add.lname",
    defaultMessage: "Nachname:",
    label: "Nachname",
  },
});

class RfidChippAddClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      chip_code: "",
      chip_name: "",
      fname: "",
      lname: "",
      hasApiError: false,
      validationErrors: [],
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    const label = terms[name].label;

    //removes the field from the ValidationErrors
    this.setState({
      validationErrors: this.state.validationErrors.filter(error => {
        return error !== label;
      }),
    });

    this.setState({ [name]: value });
  };

  save = async () => {
    await this.validateInput(); //checks if every field has an input

    if (this.state.validationErrors.length === 0) {
      this.setState({ saving: true });

      this.props
        .createNewChip({
          chip_code: this.state.chip_code,
          chip_name: this.state.chip_name,
          fname: this.state.fname,
          lname: this.state.lname,
        })
        .then(res => {
          this.props.loadRfidChips();
          this.props.closeFunction();
        })
        .catch(err => {
          console.error(err);
          this.setState({ saving: false, hasApiError: true });
        });
    }
  };

  validateInput = async () => {
    await this.hasValue(this.state.chip_code, terms.chip_code.label);
    await this.hasValue(this.state.chip_name, terms.chip_name.label);
    await this.hasValue(this.state.fname, terms.fname.label);
    await this.hasValue(this.state.lname, terms.lname.label);
  };

  hasValue = (variable, name) => {
    if (!variable && this.state.validationErrors.indexOf(name) === -1) {
      this.setState({
        validationErrors: this.state.validationErrors.concat(name),
      });
    }
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="create-new-chip"
      >
        {this.state.saving ? (
          <LoadingCircle />
        ) : this.state.hasApiError ? (
          <p className="error">
            <T
              id="tracar.rfid-chip-add.api-error"
              defaultMessage=" Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
            />
          </p>
        ) : (
          <div>
            <h5>
              <T
                id="tracar.rfid-chip-add.headline"
                defaultMessage="Neuen Chip hinzufügen"
              />
            </h5>
            {this.state.validationErrors.length > 0 && (
              <div className="input-error">
                {this.state.validationErrors.map(error => {
                  return (
                    <span className="error" key={error}>
                      Das Feld {error} muss ausgefüllt sein.
                      <br />{" "}
                    </span>
                  );
                })}
              </div>
            )}
            <div className="grid-x grid-margin-x">
              <TextInput
                className="medium-6 large-6 cell"
                type="text"
                id="chip_code"
                name={t(terms.chip_code)}
                onChange={this.handleChange}
              />
              <TextInput
                className="medium-6 large-6 cell"
                type="text"
                id="chip_name"
                name={t(terms.chip_name)}
                onChange={this.handleChange}
              />
              <TextInput
                className="medium-6 large-6 cell"
                type="text"
                id="fname"
                name={t(terms.fname)}
                onChange={this.handleChange}
              />
              <TextInput
                className="medium-6 large-6 cell"
                type="text"
                id="lname"
                name={t(terms.lname)}
                onChange={this.handleChange}
              />
            </div>
            <div className="button save-button" onClick={this.save}>
              <T
                id="tracar.rfid-chip-add.save"
                defaultMessage="Chip speichern"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const RfidChipAdd = injectIntl(RfidChippAddClass);
