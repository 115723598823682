import React from "react";
import { LoadingCircle } from "../../../LoadingCircle";
import { RequestOverlay } from "../../../Request/RequestOverlay";
import { FormattedMessage as T, injectIntl } from "react-intl";

class ConfirmCategoryChangeOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Renders the headline an dinfo text.
   * @return RequestOverlay
   */
  render() {
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="confirm-route-split"
        enableCloseOnOverlayClicked={true}
      >
        {this.renderConfirmation()}
      </RequestOverlay>
    );
  }

  /**
   * Confirm route splitting
   * @return JSX
   */
  renderConfirmation = () => {
    if (this.state.changingCategories) {
      return <LoadingCircle />;
    }

    return (
      <div>
        {this.renderHeader()}
        {this.renderButtons()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="adminPanel.devices.settings.confirmCategoryChange.headline"
            defaultMessage="Möchten Sie die Kategorie wirklich ändern?"
          />
        </h5>
        <T
          id="adminPanel.devices.settings.confirmCategoryChange.body"
          defaultMessage="Warnung: Wenn Sie den Kategoriewechsel bestätigen, werden alle Daten in Bezug zu dem Gerät gelöscht. Es werden insbesondere alle Routen, Fahrtenbücher, Positionsdaten und Geräteeinstellungen für alle Nutzer dieses Gerätes gelöscht. Das Löschen ist nicht umkehrbar. Stellen Sie daher sicher, dass die Nutzer ihre Fahrtenbücher klassifizieren und runterladen. Möchten Sie fortfahren?"
        />
      </div>
    );
  };

  /**
   * Renders the buttons
   * @return JSX
   */
  renderButtons = () => {
    return (
      <div className="confirm-button-container">
        <div className="spacer" />

        <div className="button" onClick={this.props.closeFunction}>
          <span>
            <T
              id="adminPanel.devices.settings.confirmCategoryChange.button.cancel"
              defaultMessage="Abbrechen"
            />
          </span>
        </div>

        <div
          className="button save-button"
          onClick={this.processCategoryChangeConfirmation}
          style={{ marginLeft: "15px" }}
        >
          <span>
            <T
              id="adminPanel.devices.settings.confirmCategoryChange.button.confirm"
              defaultMessage="Bestätigen"
            />
          </span>
        </div>
      </div>
    );
  };

  /**
   * Show Loading Circle
   * @return void
   */
  processCategoryChangeConfirmation = () => {
    this.setState(
      {
        changingCategories: true,
      },
      this.props.confirmCategoryChange,
    );
  };
}

export const ConfirmCategoryChangeOverlay = injectIntl(
  ConfirmCategoryChangeOverlayClass,
);
