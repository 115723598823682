import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingCircle } from "../LoadingCircle";
import { RfidableTableLine } from "./RfidableTableLine";
import { TrackerTableHead } from "../TrackerList/TrackerTableHead";

const terms = defineMessages({
  registeredDevices: {
    id: "tracar.rfidable-list.rfidables",
    defaultMessage: "RFID-fähige Geräte:",
  },
});

class RfidableListClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { rfidableList } = this.props;
    const t = this.props.intl.formatMessage;
    return (
      <div className="table-frame">
        <TrackerTableHead
          headline={t(terms.registeredDevices)}
          iconClasses="icon-rfid-chip-device"
        />
        {this.props.rfidableLoadingState !== "ready" &&
          this.props.rfidChipLoadingState !== "ready" && <LoadingCircle />}
        {rfidableList.length === 0 &&
          this.props.rfidableLoadingState === "ready" && (
            <div className="table-line">
              <T
                id="tracar.rfid-chip-list.noDevices"
                defaultMessage="Sie haben noch keine Geräte registriert."
              />
            </div>
          )}
        {this.props.rfidableLoadingState === "ready" &&
          rfidableList.reduce((acc, rfidable) => {
            const stringFiltering =
              this.props.searchFilter.length === 0 ||
              rfidable.device_name
                .toLowerCase()
                .search(this.props.searchFilter.toLowerCase()) > -1 ||
              String(rfidable.device_id).search(this.props.searchFilter) > -1 ||
              rfidable.assigned_user
                .toLowerCase()
                .search(this.props.searchFilter.toLowerCase()) > -1;

            if (!stringFiltering) {
              return acc;
            }
            return [
              ...acc,
              <RfidableTableLine
                key={rfidable.device_id}
                rfidable={rfidable}
                updateDevicesToAssign={this.props.updateDevicesToAssign}
                updateDeviceToAssign={this.props.updateDeviceToAssign}
                devicesToAssign={this.props.devicesToAssign}
                toggleShowRfidableAccessHistory={
                  this.props.toggleShowRfidableAccessHistory
                }
                updateDeviceWithAccessHistory={
                  this.props.updateDeviceWithAccessHistory
                }
              />,
            ];
          }, [])}
      </div>
    );
  }
}

export const RfidableList = injectIntl(RfidableListClass);
