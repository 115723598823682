import { postJson, deleteJson } from "./fetchService";
import { saveAs } from "file-saver";

const poolVehicleRoute = "/user/poolfahrzeuge";

export default function fetchPoolVehicle(jsonFetch, freeFetch) {
  const getPoolVehiclesApi = async (limit, offset, search_key, status) => {
    return jsonFetch(
      poolVehicleRoute,
      postJson({ limit, offset, search_key, poolfahrzeug_status: status }),
    );
  };

  const getPoolVehiclesLocal = (limit, offset, search_key, status) =>
    new Promise((resolve, reject) =>
      resolve({
        poolvehicles: [
          {
            tags: [1],
            current_use: {
              driver: { first_name: "Benjamin", last_name: "Hollinger" },
            },
            imei: "1011",
            name: "Test11",
            description: "Nice",
            status: "Occupied",
            alowed_route_type: 1,
          },
        ],
        tags: {
          tag_id_list: [2, 1],
          tag_objects: {
            "1": {
              tag_name: "BMW",
              tag_additional_info: "so much info",
              tag_color: "#E60000",
            },
            "2": {
              tag_name: "Audi",
              tag_additional_info: "",
              tag_color: "#E60000",
            },
          },
        },
        pagination: {
          total_amount: 1,
          offset: 0,
          limit: 5,
          new_startpoint: null,
          sent_amount: 1,
        },
      }),
    );

  const getPoolVehicles = getPoolVehiclesApi;

  const checkInToDevice = async (imei, route_is_private) => {
    return jsonFetch(
      `${poolVehicleRoute}/check_in/${imei}`,
      postJson({ private: route_is_private }),
    );
  };

  const checkOutOfDevice = async imei => {
    return jsonFetch(`${poolVehicleRoute}/check_out/${imei}`, postJson({}));
  };

  const getCurrentPoolVehicle = async () => {
    return jsonFetch(`${poolVehicleRoute}/current`);
  };

  const getPoolVehiclesForPortalMap = async () => {
    return jsonFetch(`/user/location/live/poolvehicle `);
  };

  const getPoolVehiclesPortalMapHistory = async (type, date) => {
    if (type === 3) {
      return jsonFetch(
        `/user/location/live/poolvehicle`,
        postJson({ type, from: date.from, to: date.to }),
      );
    }

    return jsonFetch(`/user/location/live/poolvehicle`, postJson({ type }));
  };

  const getPoolVehiclesFahrtenbookAcrossVehicles = async (from, to) => {
    return jsonFetch(`/user/poolfahrzeuge/fahrtenbuch`,postJson({from: from, to: to}))
  }

  const generatePoolVehicleFahrtenbuchPdf = async (from, to) => {
    let filename = "fahrtenbuch-" + from.toString() + "-" + to.toString();

    return freeFetch(`/user/poolfahrzeuge/fahrtenbuch/pdf`,{
      ...postJson({from: from, to: to}),
      responseType: "blob",
    }).then(response => {
      for (let [header, content] of response.headers.entries()) {
        if (header === "content-disposition") {
          filename = content.match(/"(.*?)"/)[1];
        }
      }

      return response.blob();
    })
    .then(blob => saveAs(blob, filename));
  }

  return {
    getPoolVehiclesForPortalMap,
    getPoolVehiclesPortalMapHistory,
    getPoolVehicles,
    getCurrentPoolVehicle,
    checkInToDevice,
    checkOutOfDevice,
    getPoolVehiclesFahrtenbookAcrossVehicles,
    generatePoolVehicleFahrtenbuchPdf,
  };
}
