import React from "react";
import { AccLogo } from "./ClientSpecificContent/acc-index";
import Link from "react-router-dom/es/Link";
import { LoadingCircle } from "./LoadingCircle";
import { Redirect } from "react-router-dom";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  email: {
    id: "resetPassword.email",
    defaultMessage: "E-Mail-Adresse",
  },
});

class ResetPasswordClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loadingState: "none",
      validationError: false,
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  resetPassword = () => {
    this.setState({ loadingState: "saving" });
    this.props
      .resetPassword(this.state.email)
      .then(() => this.setState({ loadingState: "finished" }))
      .catch(err => {
        if (err.status === 400 || err.status === 422) {
          this.setState({ validationError: true, loadingState: "none" });
        }
      });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div className="login-overlay reset-password">
        <div className="login-form" onSubmit={this.resetPassword}>
          <div className="logo">
            <Link to="">
              <img className="login-logo" src={AccLogo} alt="Logo" />
            </Link>
          </div>
          <div className="spacer" />
          <div className="margin-bottom-1">
            <T
              id="resetPassword.enterMail"
              defaultMessage="Bitte geben Sie Ihre E-Mailadresse an, Ihnen wird umgehend ein ein Link zugesandt, der es Ihnen erlaubt Ihr Passwort zurückzusetzen."
            />
          </div>
          <div className="text-input-component">
            <input
              type="text"
              value={this.state.email}
              name="email"
              id="email"
              onChange={this.handleInputChange}
              placeholder={t(terms.email)}
            />
            <label htmlFor="email">{t(terms.email)}</label>
          </div>
          {this.state.validationError && (
            <div className="error">
              <T
                id="resetPassword.emailError"
                defaultMessage="Bitte geben Sie eine gültige E-Mailadresse ein"
              />
              <div className="small-spacer" />
            </div>
          )}
          <div className="flex align-justify">
            <Link className="back-link align-self-middle" to="">
              <T id="resetPassword.back" defaultMessage="zurück" />
            </Link>
            <div className="button primary" onClick={this.resetPassword}>
              <T
                id="resetPassword.resetPassword"
                defaultMessage="Passwort zurücksetzen"
              />
            </div>
          </div>
          {this.state.loadingState === "saving" && (
            <div>
              <LoadingCircle />
            </div>
          )}
          {this.state.loadingState === "finished" && (
            <div>
              <Redirect
                to={{
                  pathname: "/",
                  state: { passwordResetHasBeenRequested: true },
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const ResetPassword = injectIntl(ResetPasswordClass);
