import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { FormattedMessage as T, injectIntl } from "react-intl";

class ConfirmAssignDriverDahrtenbuchOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Renders the headline an dinfo text.
   * @return RequestOverlay
   */
  render() {
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="confirm-route-split"
        enableCloseOnOverlayClicked={true}
      >
        {this.renderConfirmation()}
      </RequestOverlay>
    );
  }

  /**
   * Confirm route splitting
   * @return JSX
   */
  renderConfirmation = () => {
    if (this.state.processingConfirmation) {
      return <LoadingCircle />;
    }

    return (
      <div>
        {this.renderHeader()}
        {this.renderButtons()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="adminPanel.poolVehicles.fahrtenbuch.confirmAssignDriver.headline"
            defaultMessage="Bitte bestätigen Sie die Zuweisung dieses Fahrers."
          />
        </h5>
        <T
          id="adminPanel.poolVehicles.fahrtenbuch.confirmAssignDriver.body"
          defaultMessage="Warnung: Die Zuweisung des Fahrers kann nicht rückgängig gemacht werden."
        />
      </div>
    );
  };

  /**
   * Renders the buttons
   * @return JSX
   */
  renderButtons = () => {
    return (
      <div className="confirm-button-container">
        <div className="spacer" />

        <div className="button" onClick={this.props.closeFunction}>
          <span>
            <T
              id="adminPanel.poolVehicles.fahrtenbuch.confirmAssignDriver.button.cancel"
              defaultMessage="Abbrechen"
            />
          </span>
        </div>

        <div
          className="button save-button"
          onClick={this.processConfirmation}
          style={{ marginLeft: "15px" }}
        >
          <span>
            <T
              id="adminPanel.poolVehicles.fahrtenbuch.confirmAssignDriver.button.confirm"
              defaultMessage="Bestätigen"
            />
          </span>
        </div>
      </div>
    );
  };

  /**
   * Show Loading Circle
   * @return void
   */
  processConfirmation = () => {
    this.setState(
      {
        processingConfirmation: true,
      },
      this.props.confirm,
    );
  };
}

export const ConfirmAssignDriverFahrtenbuchOverlay = injectIntl(
  ConfirmAssignDriverDahrtenbuchOverlayClass,
);
