import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { GeneralTab } from "./GeneralTab";
import { NotificationsTab } from "./NotificationsTab";
import { FahrtenbuchTab } from "./FahrtenbuchTab";
import { UserTab } from "./UserTab";
import { TagTab } from "./TagTab";
import { TrackingIntervalTab } from "./TrackingIntervalTab";
import { SosTab } from "./SosTab";
import { WiresTab } from "./WiresTab";
import { GeofenceTab } from "./GeofenceTab";
import { hasPermission, Permission } from "../Permission";
import { UserConsumer } from "../Base";
import { DeviceAssignmentType } from "../ApiContract";
import { UtilityTab } from "./UtilityTab";
import { ServiceIntervalTab } from "./ServiceIntervalTab";

const terms = defineMessages({
  default_settings: {
    id: "tracar.settings-tab.default_device_settings",
    defaultMessage: "Allgemein",
    label: "Allgemein",
  },
  fahrtenbuch_settings: {
    id: "tracar.seetings-tab.fahrtenbuch_settings",
    defaultMessage: "Fahrtenbuch",
    label: "Fahrtenbuch",
  },
  user_settings: {
    id: "tracar.seetings-tab.user_settings",
    defaultMessage: "Benutzer",
    label: "Benutzer",
  },
  notification_settings: {
    id: "tracar.seetings-tab.notification_settings",
    defaultMessage: "Benachrichtigungen",
    label: "Benachrichtigungen",
  },
  sos_numbers_settings: {
    id: "tracar.seetings-tab.sos_numbers_settings",
    defaultMessage: "SOS",
    label: "SOS",
  },
  geofence_settings: {
    id: "tracar.seetings-tab.geofence_settings",
    defaultMessage: "Sicherheitszonen",
    label: "Sicherheitszonen",
  },
  tracking_interval_settings: {
    id: "tracar.seetings-tab.tracking_interval_settings",
    defaultMessage: "Abfragefrequenz",
    label: "Abfragefrequenz",
  },
  rfid_setting: {
    id: "tracar.seetings-tab.rfid_setting",
    defaultMessage: "RFID",
    label: "RFID",
  },
  tagging_settings: {
    id: "tracar.seetings-tab.tags_setting",
    defaultMessage: "Tags",
    label: "Tags",
  },
  tracar_wired_settings: {
    id: "tracar.seetings-tab.tracar_wired_setting",
    defaultMessage: "Kabel",
    label: "Kabel",
  },
  utility_settings: {
    id: "tracar.seetings-tab.utility_settings",
    defaultMessage: "Betriebsparameter",
    label: "Utility",
  },
  service_interval_settings: {
    id: "tracar.seetings-tab.service_interval_setting",
    defaultMessage: "Wartungsintervalle",
    label: "Wartungsintervalle",
  },
  selected_devices: {
    id: "tracar.settings.selected_devices.title",
    defaultMessage: "Ausgewählte Geräte",
    label: "Ausgewählte Geräte",
  },
  show_more: {
    id: "tracar.settings.show_more",
    defaultMessage: "Alle anzeigen",
    label: "Alle anzeigen",
  },
  show_less: {
    id: "tracar.settings.show_less",
    defaultMessage: "Weniger anzeigen",
    label: "Weniger anzeigen",
  },
});

const numberOfDevicesToShow = 10;

class SettingsClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsTabs: this.props.tabs.sort(),
      activeTab:
        this.props.selectedSettingsTab.length > 0
          ? this.props.selectedSettingsTab
          : this.props.tabs[0],
      hoveredTab: null,
      showMore: false,
    };
  }

  switchTab = tab => {
    this.setState({ activeTab: tab });
  };

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  enterTabHeader = tab => {
    this.setState({ hoveredTab: tab });
  };

  leaveTabHeader = () => {
    this.setState({ hoveredTab: null });
  };

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  getIcon = tab => {
    switch (tab) {
      case "default_settings": {
        return "icon-th_list";
      }
      case "fahrtenbuch_settings": {
        return "icon-book";
      }
      case "geofence_settings": {
        return "icon-_548";
      }
      case "notification_settings": {
        return "icon-envelope_alt";
      }
      case "tagging_settings": {
        return "icon-tags";
      }
      case "user_settings": {
        return "icon-ban_circle";
      }
      case "tracking_interval_settings": {
        return "icon-time";
      }
      case "sos_numbers_settings": {
        return "icon-phone";
      }
      case "rfid_setting": {
        return "icon-rfid-chip";
      }
      case "tracar_wired_settings": {
        return "icon-_456";
      }
      default: {
        return "icon-cog";
      }
    }
  };

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return (
      <div className={"request-component"}>
        <div
          className={"settings"}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {this.renderConfirmation()}
          <Link to={`/${this.props.portal}/device-list`} className="tab-label">
            <i
              className="fa fa-times-circle close-x"
              onClick={this.props.clearSingleImei}
            />
          </Link>
        </div>
      </div>
    );
  }

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderConfirmation = () => {
    return (
      <div>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="header">
        <h5>
          <T id="tracar.settings.headline" defaultMessage="Einstellungen" />
        </h5>
      </div>
    );
  };

  /**
   * Renders the body with tabs
   * @return JSX
   */
  renderBody = () => {
    const t = this.props.intl.formatMessage;

    const deviceListToShow =
      this.props.deviceList.length > numberOfDevicesToShow
        ? this.state.showMore
          ? this.props.deviceList
          : this.props.deviceList.slice(0, numberOfDevicesToShow)
        : this.props.deviceList;

    return (
      <UserConsumer>
        {user => (
          <div className={`body${this.props.readOnly ? " read-only" : ""}`}>
            <div className="settings-tabs">
              <div className="tabs-inner-wrapper">
                <div style={{ display: "inline-block" }}>
                  {this.state.settingsTabs.map(tab => {
                    if (
                      tab === "geofence_settings" &&
                      !hasPermission(user, Permission.SafetyZone)
                    ) {
                      return;
                    }
                    const icon = this.getIcon(tab);
                    return (
                      <div
                        key={tab}
                        className={`tab-header ${this.state.activeTab === tab &&
                          "active"}`}
                        onMouseEnter={() => this.enterTabHeader(tab)}
                        onMouseLeave={() => this.leaveTabHeader()}
                        onClick={() => this.switchTab(tab)}
                      >
                        <div className="vertical-spacer" />
                        {this.state.activeTab === tab ? (
                          <div className="tab-label">
                            <div className="icon">
                              <span className={icon} />
                            </div>
                            <div className="text">{t(terms[tab])}</div>
                          </div>
                        ) : this.state.hoveredTab !== null &&
                          tab === this.state.hoveredTab ? (
                          <div className="tab-label">
                            <div className="icon">
                              <span className={icon} />
                            </div>
                            <div className="text">
                              {t(terms[this.state.hoveredTab])}
                            </div>
                          </div>
                        ) : (
                          <span className={icon} />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {this.props.isBulk && (
              <div className="list-title-container">
                <div className="list-title">{t(terms.selected_devices)}</div>
                <div className="list-title-number">
                  {this.props.deviceList.length}
                </div>
              </div>
            )}
            <div className="imeilist-container">
              <div className="imeilist" id="imeiListTable">
                {deviceListToShow.map(device => {
                  return (
                    <div key={device.imei} className="button imei relative">
                      {device.name}
                      {this.props.deviceList.length > 2 && (
                        <i
                          className="icon-remove_circle close-icon"
                          onClick={event => {
                            event.stopPropagation();
                            this.props.updateSelectedDevicesImeiList(
                              device.imei,
                              false,
                            );
                          }}
                        />
                      )}
                      {device.assignment_type === DeviceAssignmentType.Read && (
                        <i className="fa fa-lock absolute left-10" />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {this.props.deviceList.length > numberOfDevicesToShow && (
              <div
                className="button no-borders"
                onClick={() => this.toggleShowMore()}
              >
                {this.state.showMore ? t(terms.show_less) : t(terms.show_more)}
              </div>
            )}
            <div className="tab-content">
              {(() => {
                switch (this.state.activeTab) {
                  case "default_settings":
                    return (
                      <GeneralTab
                        generalSettings={
                          this.props.deviceSettings.default_settings
                        }
                        tabType="default_settings"
                        portal={this.props.portal}
                        handleChange={this.props.handleChange}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        isBulk={this.props.isBulk}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        readOnly={this.props.readOnly}
                      />
                    );
                  case "fahrtenbuch_settings":
                    return (
                      <FahrtenbuchTab
                        fahrtenbuchSettings={
                          this.props.deviceSettings.fahrtenbuch_settings
                        }
                        userSettings={this.props.deviceSettings.user_settings}
                        tabType="fahrtenbuch_settings"
                        portal={this.props.portal}
                        user={this.props.user}
                        imei={this.props.selectedDevicesImeiList[0]}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        carsRequest={this.props.carsRequest}
                        addNewCar={this.props.addNewCar}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        readOnly={this.props.readOnly}
                      />
                    );
                  case "utility_settings":
                    return (
                      <UtilityTab
                        utilitySettings={
                          this.props.deviceSettings.utility_settings
                        }
                        userSettings={this.props.deviceSettings.user_settings}
                        tabType="utility_settings"
                        portal={this.props.portal}
                        user={this.props.user}
                        imei={this.props.selectedDevicesImeiList[0]}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        carsRequest={this.props.carsRequest}
                        addNewCar={this.props.addNewCar}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        readOnly={this.props.readOnly}
                      />
                    );
                  case "service_interval_settings":
                    return (
                      <ServiceIntervalTab
                        fahrtenbuchSettings={
                          this.props.deviceSettings.fahrtenbuch_settings
                        }
                        userSettings={this.props.deviceSettings.user_settings}
                        tabType="fahrtenbuch_settings"
                        portal={this.props.portal}
                        user={this.props.user}
                        imei={this.props.selectedDevicesImeiList[0]}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        carsRequest={this.props.carsRequest}
                        addNewCar={this.props.addNewCar}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        readOnly={this.props.readOnly}
                        fetchService={this.props.fetchService}
                      />
                    );
                  /*case "user_settings":
                    return (
                      <UserTab
                        userSettings={this.props.deviceSettings.user_settings}
                        tabType="user_settings"
                        portal={this.props.portal}
                        user={this.props.user}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                      />
                    );*/
                  case "notification_settings":
                    return (
                      <NotificationsTab
                        notificationSettings={
                          this.props.deviceSettings.notification_settings
                        }
                        tabType="notification_settings"
                        portal={this.props.portal}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        isBulk={this.props.isBulk}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        resetDeviceSettings={this.props.resetDeviceSettings}
                        loadDeviceSettings={this.props.loadDeviceSettings}
                        readOnly={this.props.readOnly}
                      />
                    );
                  case "sos_numbers_settings":
                    return (
                      <SosTab
                        sosSettings={
                          this.props.deviceSettings.sos_numbers_settings
                        }
                        tabType="sos_numbers_settings"
                        portal={this.props.portal}
                        handleChange={this.props.handleChange}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        isBulk={this.props.isBulk}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        readOnly={this.props.readOnly}
                      />
                    );
                  case "geofence_settings":
                    return (
                      hasPermission(user, Permission.SafetyZone) && (
                        <GeofenceTab
                          geofenceSettings={
                            this.props.deviceSettings.geofence_settings
                          }
                          tabType="geofence_settings"
                          portal={this.props.portal}
                          user={this.props.user}
                          imei={this.props.selectedDevicesImeiList[0]}
                          updateDeviceSettings={this.props.updateDeviceSettings}
                          saveDeviceSettings={this.props.saveDeviceSettings}
                          clearSingleImei={this.props.clearSingleImei}
                          isBulk={this.props.isBulk}
                          settingsSaving={this.props.settingsSaving}
                          setSettingsSaving={this.props.setSettingsSaving}
                          getGeofenceList={this.props.getGeofenceList}
                          getDeviceSafetyZones={this.props.getDeviceSafetyZones}
                          deviceSafetyZoneRequest={
                            this.props.deviceSafetyZoneRequest
                          }
                          deleteDeviceSafetyZone={
                            this.props.deleteDeviceSafetyZone
                          }
                          resetDeviceSettings={this.props.resetDeviceSettings}
                          loadTags={this.props.loadTags}
                          readOnly={this.props.readOnly}
                        />
                      )
                    );
                  case "tracking_interval_settings":
                    return (
                      <TrackingIntervalTab
                        trackingIntervalSettings={
                          this.props.deviceSettings.tracking_interval_settings
                        }
                        tabType="tracking_interval_settings"
                        portal={this.props.portal}
                        user={this.props.user}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        readOnly={this.props.readOnly}
                      />
                    );
                  case "rfid_setting":
                    return <div> rfid_setting </div>;
                  case "tagging_settings":
                    return (
                      <TagTab
                        tagSettings={this.props.deviceSettings.tagging_settings}
                        tabType="tagging_settings"
                        portal={this.props.portal}
                        handleChange={this.props.handleChange}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        isBulk={this.props.isBulk}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        resetDeviceSettings={this.props.resetDeviceSettings}
                        readOnly={this.props.readOnly}
                      />
                    );
                  case "tracar_wired_settings":
                    return (
                      <WiresTab
                        wiresSettings={
                          this.props.deviceSettings.tracar_wired_settings
                        }
                        tabType="tracar_wired_settings"
                        portal={this.props.portal}
                        handleChange={this.props.handleChange}
                        updateDeviceSettings={this.props.updateDeviceSettings}
                        saveDeviceSettings={this.props.saveDeviceSettings}
                        clearSingleImei={this.props.clearSingleImei}
                        isBulk={this.props.isBulk}
                        settingsSaving={this.props.settingsSaving}
                        setSettingsSaving={this.props.setSettingsSaving}
                        selectedDevicesImeiList={
                          this.props.selectedDevicesImeiList
                        }
                        generateWiresCsv={this.props.generateWiresCsv}
                        readOnly={this.props.readOnly}
                      />
                    );
                  default:
                    return <div> settings </div>;
                }
              })()}
            </div>
          </div>
        )}
      </UserConsumer>
    );
  };
}

export const Settings = injectIntl(SettingsClass);
