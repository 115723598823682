import React from "react";
import Link from "react-router-dom/Link";
import moment from "moment/moment";
import { FormattedMessage as T } from "react-intl";

export class ExpenseLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecentExpenses: false,
    };
  }

  toggleRecentExpenses = () => {
    let showRecentExpenses = !this.state.showRecentExpenses;
    this.setState({ showRecentExpenses });
  };

  // all portals have the map marker
  getIcon = color => {
    return (
      <div className={`message-pin`}>
        <i className="fa fa-map-marker" style={{ color }} />
      </div>
    );
  };

  render() {
    return (
      <div className="table-line grid-x pool-vehicle price-info align-middle">
        <div
          className="cell small-12 medium-6 large-auto padding-vertical-1 vehicle"
          onClick={this.toggleRecentExpenses}
        >
          <i
            className={`fa ${
              this.state.showRecentExpenses ? "fa-angle-right" : "fa-angle-down"
            }`}
          />{" "}
          {this.props.device.vehicle_name}
        </div>
        <div className="cell small-12 medium-12 large-2 text-right">
          {this.props.device.total_cost
            ? `${this.props.device.total_cost}€`
            : "-"}
        </div>
        <div className="cell small-12 medium-1 large-2 flex align-right icon-wrap expense-line">
          <i
            className="icon-_279 margin-horizontal-0"
            onClick={() => {
              this.props.toggleExpenseDetailOverlay(this.props.device);
            }}
          />
        </div>
        {this.state.showRecentExpenses && (
          <React.Fragment>
            {this.props.device.costs.map(cost => (
              <div key={cost.id} className="cell grid-x  detail-price-info">
                <div className="cell small-12 large-2 type">
                  <i className={`fa fa-angle-down`} />{" "}
                  <T
                    id={`expense.category.${cost.category_name}`}
                    defaultMessage={cost.category_name}
                  />
                </div>
                <div className="cell small-12 large-2">
                  <T
                    id="adminPanel.expense.list.line.label.date"
                    defaultMessage="Datum"
                  />{" "}
                  {moment(cost.start_date).format("DD.MM.YYYY")}
                  <br />
                  <T
                    id="adminPanel.expense.list.line.label.cycle"
                    defaultMessage="Turnus"
                  />{" "}
                  Einmalig
                </div>
                <div className="cell small-12 large-4 padding-left-1 border-left">
                  <T
                    id="adminPanel.expense.list.line.label.description"
                    defaultMessage="Beschreibung"
                  />{" "}
                  {cost.description}
                </div>
                <div className="cell small-12 large-2 text-right border-left">
                  <T
                    id="adminPanel.expense.list.line.label.amount"
                    defaultMessage="Kosten"
                  />{" "}
                  {cost.amount}€
                </div>
                <div className="cell small-12 large-auto flex align-right icon-wrap expense-line">
                  {!cost.admin_cost && (
                    <i
                      className={`fa fa-check-circle ${
                        cost.is_acknowledged ? "green" : ""
                      }`}
                      onClick={() => this.props.acknowledgeExpense(cost.id)}
                    />
                  )}
                  {cost.admin_cost && (
                    <i className="fa fa-check-circle disabled green" />
                  )}

                  {cost.image && (
                    <i
                      className={`fa fa-arrow-circle-o-down`}
                      onClick={() => this.props.getExpenseImage(cost.id)}
                    />
                  )}
                  {!cost.image && (
                    <i className={`fa fa-arrow-circle-o-down disabled`} />
                  )}
                  <i
                    className={`fa fa-pencil`}
                    onClick={() => {
                      this.props.toggleExpenseDetailOverlay(
                        this.props.device,
                        cost,
                      );
                    }}
                  />
                  <i
                    className={`fa fa-trash`}
                    onClick={() => {
                      this.props.deleteExpense(cost.id);
                    }}
                  />
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
    );
  }
}
