import React from "react";
import { defineMessages, FormattedMessage as T } from "react-intl";
import { PaginationBar } from "../PaginationBar";
import { PoolVehicleLine } from "./PoolVehicleLine";
import ReactTooltip from "react-tooltip";

const filterType = ["Alle Geräte", "Geräte ohne Nutzer"];

const PoolVehicleOrderBy = {
  DeviceName: "device_name",
  AllowedRouteType: "allowed_route_type",
  CurrentUseLastName: "current_use_last_name",
  LastCheckInTime: "last_check_in_time",
};

export class PoolVehicleList extends React.Component {
  handleChange;

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      warnings: false,
      type_filter: "Alle Geräte",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({
      loading: false,
      // unwrap device records
      devices: this.props.recordList,
      warnings: !this.props.recordList.find(({ dlc }) => dlc && dlc.warned),
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    name === "filterType" && this.props.updateCurrentPage(1);
    this.setState({ [name]: value });
  }

  calcSortIcon(cat) {
    const catSorted = this.props.orderedBy.find(o => o.cat === cat);
    if (catSorted === undefined) {
      return "fa-sort";
    }
    return catSorted.ord === 0 ? "fa-sort-up" : "fa-sort-down";
  }

  compareCat(a, b) {
    switch (a) {
      case PoolVehicleOrderBy.AllowedRouteType:
        return b === PoolVehicleOrderBy.DeviceName ||
          b === PoolVehicleOrderBy.CurrentUseLastName
          ? 1
          : -1;
      case PoolVehicleOrderBy.CurrentUseLastName:
        return b === PoolVehicleOrderBy.DeviceName ? 1 : -1;
      case PoolVehicleOrderBy.DeviceName:
        return -1;
      case PoolVehicleOrderBy.LastCheckInTime:
        return 1;
      default:
        return 0;
    }
  }

  sortArrByCat(arr) {
    arr.sort((a, b) => this.compareCat(a.cat, b.cat));
    return arr;
  }

  sortBy(cat) {
    // item is part of PoolVehicleOrderBy
    const itemIndex = this.props.orderedBy.findIndex(i => i.cat === cat);
    if (itemIndex === -1) {
      // we do not already sort by this category
      this.props.setOrderBy(
        this.sortArrByCat([...this.props.orderedBy, { cat: cat, ord: 0 }]),
      );
    } else {
      const item = this.props.orderedBy[itemIndex];
      if (item.ord === 0) {
        // currently asc, now des
        const newOrder = [...this.props.orderedBy];
        newOrder.splice(itemIndex, 1);
        this.props.setOrderBy(
          this.sortArrByCat([...newOrder, { cat: cat, ord: 1 }]),
        );
      } else {
        // currently des, now do not sort by this cat
        const newOrder = [...this.props.orderedBy];
        newOrder.splice(itemIndex, 1);
        this.props.setOrderBy(this.sortArrByCat(newOrder));
      }
    }
  }

  render() {
    return (
      <div>
        <div className="table-frame filter-table admin-list overflow-x-clip">
          <div className="table-header">
            <i className="icon-device" />
            <T
              id="adminPanel.devices.list.headline"
              defaultMessage="Poolfahrzeuge"
            />
            <span className="item-count">{this.props.numberOfDevices}</span>
          </div>
          <div className="grid-x grid-margin-x table-line filter">
            <div
              className="cell small-12 medium-2"
              onClick={() => this.sortBy(PoolVehicleOrderBy.DeviceName)}
            >
              <a className="" href="#">
                <T
                  id="adminPanel.poolVehicles.list.column.header.vehicle"
                  defaultMessage="Fahrzeug"
                />
                <i
                  className={`fa ${this.calcSortIcon(
                    PoolVehicleOrderBy.DeviceName,
                  )} relative`}
                />
              </a>
            </div>
            <div
              className="cell small-12 medium-auto"
              onClick={() => this.sortBy(PoolVehicleOrderBy.CurrentUseLastName)}
            >
              <a className="" href="#">
                <T
                  id="adminPanel.poolVehicles.list.column.header.driver"
                  defaultMessage="Fahrer /-in"
                />
                <i
                  className={`fa ${this.calcSortIcon(
                    PoolVehicleOrderBy.CurrentUseLastName,
                  )} relative`}
                />
              </a>
            </div>
            <div
              className="cell small-12 medium-auto"
              onClick={() => this.sortBy(PoolVehicleOrderBy.LastCheckInTime)}
            >
              <a className="" href="#">
                <T
                  id="adminPanel.poolVehicles.list.column.header.lastCheckIn"
                  defaultMessage="Letzte Anmeldung"
                />
                <i
                  className={`fa ${this.calcSortIcon(
                    PoolVehicleOrderBy.LastCheckInTime,
                  )} relative`}
                />
              </a>
            </div>
            <div
              className="cell small-12 medium-auto"
              onClick={() => this.sortBy(PoolVehicleOrderBy.AllowedRouteType)}
            >
              <a className="" href="#">
                <T
                  id="adminPanel.poolVehicles.list.column.header.routeType"
                  defaultMessage="Erlaubte Fahrten"
                />
                <i
                  className={`fa ${this.calcSortIcon(
                    PoolVehicleOrderBy.AllowedRouteType,
                  )} relative`}
                />
              </a>
            </div>
            <div className="cell small-12 medium-auto">
              <a className="no-filter" href="#" data-tip data-for="warning">
                <T
                  id="portal.poolVehicles.list.column.header.lastPosition"
                  defaultMessage="Letzte Position"
                />
                &nbsp;&nbsp;
                <i
                  style={{ color: "gray" }}
                  className="icon-_279 margin-horizontal-0"
                />
              </a>

              <ReactTooltip id="warning" type="error" effect="solid">
                <span>
                  <T
                    id="portal.poolVehicles.list.column.header.warning"
                    defaultMessage="Dies ist die letzte bekannte Position. Sie muss
                  nicht aktuell sein."
                  />
                </span>
              </ReactTooltip>
            </div>
            <div className="cell small-12 medium-auto" />
          </div>
          {this.props.recordList.map(device => (
            <div className="relative" style={{ display: "flex" }}>
              <div
                className="status-pool-vehicle"
                style={{ backgroundColor: device.color }}
              ></div>
              <PoolVehicleLine
                key={device.imei}
                device={device}
                toggleDeviceAssignUserOverlay={
                  this.props.toggleDeviceAssignUserOverlay
                }
                toggleDeviceEditOverlay={this.props.toggleDeviceEditOverlay}
                togglePoolVehicleAssignDriverOverlay={
                  this.props.togglePoolVehicleAssignDriverOverlay
                }
                messageListQuery={this.props.messageListQuery}
              />
            </div>
          ))}
        </div>

        <PaginationBar
          currentPage={this.props.currentPage}
          numberOfPages={this.props.numberOfPages}
          updateCurrentPage={this.props.updatePagination}
        />
      </div>
    );
  }
}
