import React from "react";
import Cookies from "js-cookie";
import { Cookie } from "../Cookie";

import Login from "./Login";

export class LoginBase extends React.Component {
  constructor(props) {
    super(props);
    const passwordHasBeenReset =
      (this.props.location.state &&
        this.props.location.state.passwordHasBeenReset) ||
      false;
    const passwordResetHasBeenRequested =
      (this.props.location.state &&
        this.props.location.state.passwordResetHasBeenRequested) ||
      false;
    this.state = {
      userActivation: null,
      passwordResetHasBeenRequested,
      passwordHasBeenReset,
      loading: false,
    };

    const jwtSigned =
      Cookies.get(Cookie.jwtSigned) &&
      parseInt(Cookies.get(Cookie.jwtSigned), 10);

    // Check if the user has a cookie that is still valid and if so log in
    if (
      jwtSigned &&
      Cookies.get(Cookie.jwt) &&
      Cookies.get(Cookie.jwt) !== "undefined" &&
      parseInt(jwtSigned, 10) +
        parseInt(process.env.REACT_APP_JWT_EXPIRATION_S, 10) >
        Date.now() / 1000
    ) {
      this.state.loading = true;
      this.props.refreshCall().then(res => {
        this.setState({ loading: false });
        this.props.setLoginData(res.token, res.user);
      });
    }
  }

  componentWillMount() {
    if (
      this.props.match.params.activate === "activate" &&
      this.props.match.params.hash != null
    ) {
      this.setState({ userActivation: "pending" });
      this.props
        .activateUser(this.props.match.params.hash || "")
        .then(() => this.setState({ userActivation: "success" }))
        .catch(() => this.setState({ userActivation: "failure" }));
    }
  }

  render() {
    return (
      <div>
        <Login
          loading={this.state.loading}
          loginCall={this.props.loginCall}
          passwordHasBeenReset={this.state.passwordHasBeenReset}
          passwordResetHasBeenRequested={
            this.state.passwordResetHasBeenRequested
          }
          setLoginData={this.props.setLoginData}
          userActivation={this.state.userActivation}
          setLoggedIn={this.props.setLoggedIn}
        />
      </div>
    );
  }
}
