import {
  deleteJson,
  postJson,
  postMultipartFormdata,
  putMultipartFormdata,
  putJson,
} from "./fetchService";
import { apiBaseUrl } from "./middleware";
import { saveAs } from "file-saver";

const serverbaseUrl = apiBaseUrl;

/// Collection of all fetch functions regarding the non-company-user management
export default function fetchExpensesAdmin(wrappedFetch, freeFetch) {
  const getExpenseList = (offset, limit, search_key) => {
    return wrappedFetch(
      `/admin_user/v2/poolfahrzeuge/receipts/overview?offset=${offset}&limit=${limit}&search_key=${search_key}`,
    );
  };

  const getExpenseListForDevice = (
    imei,
    offset,
    limit,
    sortField,
    sortDirection,
    search_key,
    category_name,
    acknowledgeFilter,
  ) => {
    const order_by = [`${sortDirection === "desc" ? "-" : ""}${sortField}`];
    category_name = category_name === "ALL" ? null : category_name;
    let params = { offset, limit, order_by, search_key, category_name };

    switch (acknowledgeFilter) {
      case "isAcknowledged":
        params = { ...params, is_acknowledged: true };
        break;
      case "isNotAcknowledged":
        params = { ...params, is_acknowledged: false };
        break;
    }

    var queryString = Object.keys(params)
      .filter(function(filterKey) {
        return params[filterKey] !== null && params[filterKey] !== "";
      })
      .map(function(key) {
        return key + "=" + params[key];
      })
      .join("&");
    return wrappedFetch(
      `/admin_user/v2/poolfahrzeuge/${imei}/receipts?${queryString}`,
    );
  };

  const deleteExpense = receipt_id => {
    return wrappedFetch(
      `/admin_user/v2/poolfahrzeuge/receipts/${receipt_id}`,
      deleteJson(),
    );
  };

  const editAdminExpense = (
    expense_id,
    amount,
    tax,
    category,
    description,
    start_date,
    end_date,
    is_acknowledged,
    image,
  ) => {
    let body = {
      amount,
      tax,
      category,
      start_date,
      end_date,
      description,
      is_acknowledged,
    };
    if (image !== "nochange") {
      body = { ...body, image };
    }
    return wrappedFetch(
      `/admin_user/v2/poolfahrzeuge/receipts/${expense_id}`,
      putMultipartFormdata(body),
    );
  };

  const createExpense = (
    vehicle,
    frequency,
    amount,
    tax,
    category,
    description,
    start_date,
    end_date,
    is_acknowledged,
    image,
  ) => {
    return wrappedFetch(
      `/admin_user/v2/poolfahrzeuge/receipts`,
      postMultipartFormdata({
        vehicle,
        frequency,
        amount,
        tax,
        category,
        description,
        start_date,
        end_date,
        is_acknowledged,
        image,
      }),
    );
  };

  const getExpenseCategoryList = category_type => {
    return wrappedFetch(
      `/general/poolfahrzeuge/costs/categories`,
      postJson({ category_type }),
    );
  };

  const getExpenseImage = receipt_id => {
    let filename;
    return freeFetch(
      `/admin_user/v2/poolfahrzeuge/receipts/${receipt_id}/image`,
      {
        responseType: "blob",
      },
    )
      .then(response => {
        if (!response.ok) {
          return;
        }
        for (let [header, content] of response.headers.entries()) {
          if (header === "content-disposition") {
            filename = content.split("filename=")[1];
          }
        }
        return response.blob();
      })
      .then(blob => {
        if (!blob) {
          return;
        }
        return saveAs(blob, filename);
      });
  };

  const acknowledgeExpense = receipt_id => {
    return wrappedFetch(
      `/admin_user/v2/poolfahrzeuge/receipts/acknowledgement`,
      postJson({ receipt_ids: [receipt_id] }),
    );
  };

  return {
    getExpenseList,
    getExpenseListForDevice,
    editAdminExpense,
    createExpense,
    getExpenseCategoryList,
    getExpenseImage,
    acknowledgeExpense,
    deleteExpense,
  };
}
