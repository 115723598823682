import React from "react";
import { DashboardMessageList } from "./DashboardMessageList";
import { DashboardServiceIntervalList } from "./DashboardServiceIntervalList";
import { DeviceInfo } from "./DeviceInfo";
import { SafetyZonesList } from "./SafetyZonesList";
import { Minimap } from "../Map/Minimap";
import { FormattedMessage as T } from "react-intl";
import { Portal } from "../Portal";

export function DeviceDashboardAsset({
  deviceDashboard,
  portal,
  imei,
  fetchAddress,
  serviceIntervals,
  setIntervalCompletion,
}) {
  return (
    <div className="outer-table-frame dashboard">
      <div className="table-frame">
        <div className="table-header">
          <i className="fa fa-tachometer" />
          <T id="base.dashboard" defaultMessage="Dashboard" />
        </div>
        <div className="all-graphs-outer-frame">
          <div className="all-graphs-frame">
            <DeviceInfo {...deviceDashboard} />

            <DashboardMessageList
              messages={deviceDashboard.messages}
              portal={Portal.Asset}
            />

            <DashboardServiceIntervalList
              serviceIntervals={serviceIntervals}
              setIntervalCompletion={setIntervalCompletion}
              portal={Portal.Asset}
            />

            <SafetyZonesList
              safety_zones={deviceDashboard.device_info.device_geofences}
              portal={portal}
              imei={imei}
            />

            <Minimap
              portal={portal}
              imei={imei}
              latest_gps_info={deviceDashboard.latest_gps_info}
              fetchAddress={fetchAddress}
            />

            <div className="small-spacer" />
          </div>
        </div>
      </div>
    </div>
  );
}
