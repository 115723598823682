import React from "react";
import { RoleLine } from "./RoleLine";
import { FormattedMessage as T } from "react-intl";
import ReactTooltip from "react-tooltip";

import { UserConsumer } from "../../Base";
import { QueryLine } from "../../tools/QueryLine";
import { PaginationBar } from "../PaginationBar";

export class RoleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fleetIdFilter: "all",
      fleetIdFunctionality: false,
    };
  }

  render() {
    return (
      <UserConsumer>
        {user => (
          <div className="outer-table-frame admin-list">
            <div className="grid-x grid-margin-x align-middle search-area">
              <div className="auto cell">
                <QueryLine
                  changeCallback={this.props.updateListSearchKey}
                  changeDelay={500}
                />
              </div>
              <div className="shrink cell">
                <div className="button-frame">
                  <div
                    className="button green primary"
                    onClick={this.props.toggleCreateOverlay}
                  >
                    <T
                      id="adminPanel.roles.list.buttonLabel.createRoleButton"
                      defaultMessage="Neue Rolle anlegen"
                    />
                  </div>
                </div>
              </div>
            </div>

            {this.props.inviteMessage && (
              <div className={this.props.inviteMessage.className}>
                {this.props.inviteMessage.text}
                <div className="small-spacer" />
              </div>
            )}
            <div className="table-frame">
              <div className="table-header">
                <i className="fa icon-user-role" />
                <T
                  id="adminPanel.roles.list.headline"
                  defaultMessage="Rollen"
                />
                <span className="item-count">{this.props.numberOfRoles}</span>
              </div>
              {this.props.recordList.map(currentRecord => (
                <RoleLine
                  key={currentRecord.role_id}
                  currentRecord={currentRecord}
                  toggleEditOverlay={this.props.toggleEditOverlay}
                  toggleDeleteOverlay={this.props.toggleDeleteOverlay}
                  toggleAssignUserOverlay={this.props.toggleAssignUserOverlay}
                />
              ))}
            </div>
            <PaginationBar
              currentPage={this.props.currentPage}
              numberOfPages={this.props.numberOfPages}
              updateCurrentPage={this.props.updatePagination}
            />
            <ReactTooltip
              delayShow={500}
              class="tooltip"
              border={true}
              id={`non-company-user-line`}
            />
          </div>
        )}
      </UserConsumer>
    );
  }
}
