
export const AccColor = {
  RedBerry: "#990000",
  Red: "#E60000",
  Limeade: "#428600",
  Olive: "#a8b400",
  Supernova: "#fecb00",
  WebOrange: "#eb9700",
  White: "#ffffff",
  WildSand: "#f4f4f4",
  Gallery: "#ebebeb",
  Silver: "#cccccc",
  SilverChalice: "#afafaf",
  SilverChalice1: "#999999",
  DoveGrey: "#666666",
  TransparentDoveGrey: "rgba(102, 102, 102, 0.25)",
  OuterSpace: "#4a4d4e",
  MineShaft: "#333333",
};
