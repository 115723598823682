import React from "react";

import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import Link from "react-router-dom/es/Link";
import ReactTooltip from "react-tooltip";
import { isDev } from "../tools/Environment";
import { UserConsumer } from "../Base";
import { hasPermission, Permission } from "../Permission";

const terms = defineMessages({
  name: {
    id: "tracar.device-list.sorting.name",
    defaultMessage: "Name",
  },
  imei: {
    id: "tracar.device-list.sorting.imei",
    defaultMessage: "IMEI",
  },
  latest_tracklog_time: {
    id: "tracar.device-list.sorting.latest_tracklog_time",
    defaultMessage: "Letzter Zugriff",
  },
  registered_on: {
    id: "tracar.device-list.sorting.registered_on",
    defaultMessage: "Registriert am",
  },
  mapMultiple: {
    id: "tracar.device-list.map-selected.devices",
    defaultMessage: "Kartenansicht für ausgewählte Geräte",
  },
});

class SortingBarClass extends React.Component {
  constructor(props) {
    super(props);
  }

  changeDirection = direction => {
    switch (direction[1]) {
      case "": {
        direction[1] = "up";
        this.props.sortBy(direction);
        break;
      }
      case "up": {
        direction[1] = "down";
        this.props.sortBy(direction);
        break;
      }
      case "down": {
        direction[1] = "";
        this.props.sortBy(direction);
        break;
      }
      default: {
        console.log("no direction specified");
        direction[1] = "";
        this.props.sortBy(direction);
        break;
      }
    }
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <UserConsumer>
        {user => (
          <div className="button-frame">
            <div className="block">
              {Object.entries(this.props.directionList).map(direction => {
                return (
                  <div
                    key={direction[0]}
                    className={`button sorting ${this.props.directionList[
                      direction[0]
                    ] !== "" && "active"}`}
                    onClick={() => this.changeDirection(direction)}
                  >
                    {t(terms[direction[0]])}
                    {direction[1] !== "" && (
                      <i className={`i fa fa-arrow-${direction[1]}`} />
                    )}
                  </div>
                );
              })}
            </div>

            <div className="settings-button-container">
              <div className="settings">
                {hasPermission(user, Permission.Location) && (
                  <Link
                    to={{
                      pathname: "/" + this.props.portal + "/map/multiple",
                      state: {
                        devicesImeiList: this.props.selectedDevicesImeiList,
                        selectedTags: this.props.selectedTags,
                        selectedGeofences: this.props.selectedGeofences,
                      },
                    }}
                    className="button"
                    data-for={`map-multiple`}
                    data-tip={t(terms.mapMultiple)}
                    disabled={this.props.selectedDevicesImeiList.length === 0}
                  >
                    <i className="icon-_593" />
                  </Link>
                )}

                <Link
                  to={`/${this.props.portal}/device-list/settings`}
                  className="button"
                  disabled={!this.props.showBulkSettings}
                >
                  <i className="icon-cogs" />
                </Link>
              </div>
              <ReactTooltip
                delayShow={500}
                class="tooltip"
                border={true}
                id={`map-multiple`}
              />
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}

export const SortingBar = injectIntl(SortingBarClass);
