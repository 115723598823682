import { Map } from "immutable";
import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { MessageList } from "./MessageList";
import { removeSingleElementByCondition } from "../utility";
import { MessageHeadline } from "./MessageHeadline";
import ReactPaginate from "react-paginate";
import { RoleEditOverlay } from "../AdminPanel/Role/RoleEditOverlay";
import { MessageMapOverlay } from "./MessageMapOverlay";
import { GoogleMapWrapper } from "../Map/GoogleMapWrapper";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { withGoogleMap } from "react-google-maps";

const defaultPaginationLimit = 25;

// Translations
const terms = defineMessages({
  map_overlay_title: {
    id: "message.map.header.title",
    defaultMessage: "Kartenansicht",
  },
});

class MessagesBaseClass extends React.Component {
  imei;

  constructor(props) {
    super(props);
    this.imei = props.match.params.imei || "";
    this.state = {
      messageList: null,
      markedMessages: Map(),
      loading: true,
      offset: 0,
      paginationLimit: 25,
      selectedPage: 0,
      showMapOverlay: false,
      // message to display in map overlay
      mapMessageRecord: null,
    };
  }

  componentDidMount() {
    this.load({ offset: 0, limit: defaultPaginationLimit });
  }

  deleteEntry = id => {
    this.setState({ loading: true });
    if (this.state.messageList == null) {
      return;
    }
    const [messages] = removeSingleElementByCondition(
      this.state.messageList.messages,
      ({ message_id }) => id === message_id,
    );
    const messageList = { ...this.state.messageList, messages };
    this.setState({ messageList });
    this.props
      .deleteMessages(this.imei, [id])
      .then(() => this.load({ offset: 0, limit: this.state.paginationLimit }));
  };

  deleteMarkedEntries = () => {
    if (this.state.messageList == null) {
      return;
    }
    this.setState({ loading: true });
    const toDelete = [...this.state.markedMessages.keys()];
    this.props
      .deleteMessages(this.imei, toDelete)
      .then(() => this.load({ offset: 0, limit: this.state.paginationLimit }));
    /// nasty boilerplate...
    if (this.state.messageList == null) {
      return;
    }
    const messages = this.state.messageList.messages.filter(
      ({ message_id }) => !this.state.markedMessages.get(message_id),
    );
    const messageList = { ...this.state.messageList, messages };
    this.setState({ messageList });
  };

  load(pagination) {
    this.setState({ loading: true });
    this.props.messageListQuery(this.imei, pagination).then(messageList => {
      this.setState({ messageList, loading: false });
    });
  }

  markAll = () => {
    if (this.state.messageList == null) {
      return;
    }
    const markedMessages = Map(
      this.state.messageList.messages.map(({ message_id }) => [
        message_id,
        true,
      ]),
    );
    this.setState({ markedMessages });
  };

  markAsRead = id => () => {
    this.changeMessageItemsToRead([id]);
    this.props.markMessagesAsRead(this.imei, [id]);
  };

  changeMessageItemsToRead = ids => {
    if (this.state.messageList == null) {
      return;
    }

    const messages = this.state.messageList.messages.map(message => {
      if (ids.indexOf(message.message_id) === -1) {
        return message;
      }
      return { ...message, read: true };
    });

    const messageList = { ...this.state.messageList, messages };

    this.setState({ messageList });
  };

  handlePagination = data => {
    /// pixel offset from the windows upper edge
    const paginationYOffset = 155;
    const selected = data ? data.selected : this.state.selectedPage;
    const { paginationLimit } = this.state;
    let offset = Math.ceil(selected * paginationLimit);
    this.setState({
      offset,
      selectedPage: selected,
      markedMessages: Map(),
      loading: true,
      messageList: { ...this.state.messageList, messages: [] },
    });
    this.load({ offset, limit: paginationLimit });
    if (window.pageYOffset > paginationYOffset) {
      window.scrollTo(0, paginationYOffset);
    }
  };

  setPaginationLimit = paginationLimit => {
    this.setState({ paginationLimit }, this.handlePagination);
    this.setState({ selectedPage: 0 });
  };

  markSelectedAsRead = () => {
    const selected = [...this.state.markedMessages.keys()];
    this.changeMessageItemsToRead(selected);
    this.props.markMessagesAsRead(this.imei, selected);
  };

  toggleMessageMark = id => {
    const markedMessages = this.state.markedMessages.has(id)
      ? this.state.markedMessages.delete(id)
      : this.state.markedMessages.set(id, true);
    this.setState({ markedMessages });
  };

  toggleMapOverlay = messageRecord => {
    this.setState({
      mapMessageRecord: messageRecord,
      showMapOverlay: !this.state.showMapOverlay,
    });
  };

  render() {
    const t = this.props.intl.formatMessage;
    const { messageList, markedMessages } = this.state;
    const pageCount =
      messageList != null
        ? Math.ceil(messageList.total_amount / this.state.paginationLimit)
        : 0;

    const paginationLimitDefinition = setLimit => {
      const className =
        "link" + (setLimit === this.state.paginationLimit ? " active" : "");
      return {
        key: setLimit,
        onClick: () => this.setPaginationLimit(setLimit),
        className,
      };
    };

    const pagination = messageList != null && pageCount > 1 && (
      <div className="pagination-wrapper">
        <ReactPaginate
          activeClassName={"active"}
          breakClassName={"break-me"}
          breakLabel={<a>...</a>}
          containerClassName={"pagination"}
          forcePage={this.state.selectedPage}
          marginPagesDisplayed={2}
          nextLabel={<i className="fa fa-chevron-circle-right" />}
          onPageChange={this.handlePagination}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          previousLabel={<i className="fa fa-chevron-circle-left" />}
          subContainerClassName={"pages pagination"}
        />
        <ul className="pagination-limiter">
          {[25, 100, 500].map(limit => (
            <li {...paginationLimitDefinition(limit)}>{limit}</li>
          ))}
          &nbsp;&nbsp; Nachrichten anzeigen
        </ul>
      </div>
    );

    return (
      <div className="outer-table-frame tracker">
        {pagination}
        <div className="table-frame">
          {messageList != null && (
            <div>
              <MessageHeadline
                deleteMarkedEntries={this.deleteMarkedEntries}
                markAll={this.markAll}
                markedMessages={markedMessages}
                markSelectedAsRead={this.markSelectedAsRead}
                messageList={messageList}
              />
              <MessageList
                deleteEntry={this.deleteEntry}
                markedMessages={markedMessages}
                markAsRead={this.markAsRead}
                messageList={messageList}
                toggleMessageMark={this.toggleMessageMark}
                toggleMapOverlay={this.toggleMapOverlay}
              />
              {this.state.showMapOverlay && (
                <div className="new-interval-modal">
                  <RequestOverlay
                    closeFunction={() => this.toggleMapOverlay(null)}
                    className="message-map-container"
                    enableCloseOnOverlayClicked={true}
                  >
                    <div className="headline">
                      <div>{t(terms.map_overlay_title)}</div>
                    </div>
                    <MessageMapOverlay
                      portal={this.props.match.params.portal}
                      containerElement={<div className="map-container" />}
                      mapElement={
                        <div style={{ height: `70vh`, "margin-top": "25px" }} />
                      }
                      closeFunction={() => this.toggleMapOverlay(null)}
                      message={this.state.mapMessageRecord}
                    />
                  </RequestOverlay>
                </div>
              )}
            </div>
          )}
          {(messageList == null || this.state.loading) && (
            <div className="message-loading-circle">
              <LoadingCircle />
            </div>
          )}
        </div>
        {pagination}
      </div>
    );
  }
}
export const MessagesBase = injectIntl(MessagesBaseClass);
