import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import FormattedMessageFixed from "../../tools/FormattedMessageFixed";
import Link from "react-router-dom/Link";

const terms = defineMessages({
  privateCar: {
    id: "tracar.device-list.privateCar",
    defaultMessage: "privat genutztes Firmenfahrzeug",
  },
  companyCar: {
    id: "tracar.device-list.companyCar",
    defaultMessage: "Firmenfahrzeug",
  },
  tags: {
    id: "tracar.device-list.tags",
    defaultMessage: "Tags",
  },
  statistics: {
    id: "tracar.device-list.statistics",
    defaultMessage: "Statistiken",
  },
  mapView: {
    id: "tracar.device-list.mapView",
    defaultMessage: "Kartenansicht",
  },
  fahrtenbuch: {
    id: "tracar.device-list.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  routeList: {
    id: "tracar.device-list.routeList",
    defaultMessage: "Routenliste",
  },
  geofences: {
    id: "tracar.device-list.geofences",
    defaultMessage: "Sicherheitszonen",
  },
  settings: {
    id: "tracar.device-list.settings",
    defaultMessage: "Einstellungen",
  },
  contacts: {
    id: "tracar.device-list.contacts",
    defaultMessage: "Kontakte",
  },
  messages: {
    id: "tracar.device-list.messages",
    defaultMessage: "Nachrichten",
  },
  fence_down: {
    id: "tracar.device-list.fenceDown",
    defaultMessage: "Bauzaun ist umgestürzt!",
  },
  batteryStatus: {
    id: "tracar.device-list.batteryStatus",
    defaultMessage: "Batteriestatus",
  },
  ok: {
    id: "ok",
    defaultMessage: "O.K.",
  },
});

export class DeviceLineClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // all portals have the map marker
  getIcon = color => {
    return (
      <div className={`message-pin`}>
        <i className="fa fa-map-marker" style={{ color }} />
      </div>
    );
  };
  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div className="table-line grid-x gird-padding-x">
        <div className="cell small-12 medium-6 large-3 first-cell">
          <div className="name"> {this.props.device.name}</div>
        </div>
        <div className="cell small-12 medium-6 large-2">
          <FormattedMessageFixed
            id={`tracar.portal.${this.props.device.device_category.description}`}
            defaultMessage={`${this.props.device.device_category.description}`}
          />
        </div>
        <div className="cell small-12 medium-12 large-3">
          <div className="grey-text">IMEI: {this.props.device.imei}</div>
        </div>
        <div className="cell small-12 medium-12 large-3">
          <div className="button-frame tag-buttons">
            <div className="block">
              {this.props.device.tags &&
                this.props.device.tags.map(
                  tagId =>
                    this.props.tagList.tag_objects[tagId.toString()] && (
                      <span key={tagId} className="tag">
                        <i
                          className="fa fa-tag"
                          style={{
                            color: this.props.tagList.tag_objects[
                              tagId.toString()
                            ].tag_color,
                          }}
                        />
                        &nbsp;&nbsp;
                        {
                          this.props.tagList.tag_objects[tagId.toString()]
                            .tag_name
                        }{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    ),
                )}
            </div>
          </div>
        </div>
        <div className="cell small-12 medium-1 large-auto table-line-right fixed">
          <div className="button-container">
            <div
              className="button margin-0 padding-top-0 padding-bottom-1"
              onClick={() => {
                this.props.toggleDeviceAssignUserOverlay(this.props.device);
              }}
            >
              <i className="fa fa-user" />
              {this.props.device.users &&
                this.props.device.users.length != 0 && (
                  <span className="badge red">
                    {this.props.device.users.length}
                  </span>
                )}
            </div>
            <Link
              to={`/admin-panel/device/settings/${this.props.device.imei}`}
              className="button"
              data-for={`tracker-table-line-${this.props.device.imei}`}
              data-tip={t(terms.settings)}
              onClick={event => {
                event.stopPropagation();
                this.props.updateSelectedDeviceImei(this.props.device.imei);
              }}
            >
              <i className="icon-cogs" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export const DeviceLine = injectIntl(DeviceLineClass);
