import React from "react";
import DatePicker from "react-datepicker";
import { RequestOverlay } from "../Request/RequestOverlay";
import moment from "moment";
import { CustomDatePickerInput } from "../Map/Map";
import { FormattedMessage as T } from "react-intl";
import { defaultLocale } from "../Language";


export class WireExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: moment(),
      startDate: moment(),
    };
  }

  handleChangeEnd = (endDate) => this.setState({ endDate });

  handleChangeStart = (startDate) => this.setState({ startDate });

  generateCsvExport = () => {
    this.props.generateWiresCsv(this.props.imei, {
      from: this.state.startDate
        .clone()
        .utc()
        .format("YYYY-MM-DD"),
      to: this.state.endDate
        .clone()
        .utc()
        .format("YYYY-MM-DD"),
    });
    this.props.closeFunction();
  };

  render() {
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <h5>
          <strong>
            <T
              id="tracar.wires.export"
              defaultMessage="Daten der Tracar-Kontakte exportieren"
            />
          </strong>
        </h5>
        <div className="overlay-content">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "3rem" }}>
              <T id="tracar.wires.from" defaultMessage="von:" />
            </div>
            <DatePicker
              customInput={<CustomDatePickerInput />}
              endDate={this.state.endDate}
              locale={moment().locale() || defaultLocale}
              onChange={this.handleChangeStart}
              popperPlacement="top-end"
              selected={this.state.startDate}
              selectsStart
              showMonthDropdown
              startDate={this.state.startDate}
              withPortal
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "3rem" }}>
              <T id="tracar.wires.to" defaultMessage="bis:" />
            </div>
            <DatePicker
              customInput={<CustomDatePickerInput />}
              endDate={this.state.endDate}
              locale={moment().locale() || defaultLocale}
              onChange={this.handleChangeEnd}
              selected={this.state.endDate}
              selectsEnd
              showMonthDropdown
              startDate={this.state.startDate}
              withPortal
            />
          </div>
          <div className="button button-100" onClick={this.generateCsvExport}>
            <i className="fa fa-table" /> &nbsp;{" "}
            <T id="tracar.wires.exportCsv" defaultMessage="CSV-Export" />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}
