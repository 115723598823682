import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import { FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingCircle } from "../LoadingCircle";
import DatePicker from "react-datepicker";
import { CustomWeekPickerInput } from "../CustomWeekPickerInput";
import moment from "moment";
import { getWeekFromDate } from "../utility";
import { defaultLocale } from "../Language";

class RfidableAccessHistoryClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: true,
      hasApiError: false,
      selectedDate: getWeekFromDate(moment()),
      loadAccessListState: "loading",
      accessList: {},
    };
  }

  componentDidMount() {
    this.loadAccessList();
  }

  loadAccessList = () => {
    this.setState({ accessList: {}, loadAccessListState: "loading" });
    this.props.fetchRfid
      .getRfidableAccessList({
        imei: this.props.deviceWithAccessHistory.imei,
        from: this.state.selectedDate.from,
        to: this.state.selectedDate.to,
      })
      .then(accessList =>
        this.setState({ accessList, loadAccessListState: "ready" }),
      );
  };

  handleDateChange = date => {
    this.setState({ selectedDate: getWeekFromDate(date) }, () =>
      this.loadAccessList(),
    );
  };

  render() {
    const t = this.props.intl.formatMessage;
    const device = this.props.deviceWithAccessHistory;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="create-new-chip"
      >
        {this.state.loadAccessListState !== "ready" && <LoadingCircle />}
        {this.state.loadAccessListState === "ready" && (
          <div>
            <h5>
              <T
                id="tracar.rfidable-accesshistory.headline"
                defaultMessage="Zugriffshistorie für das Gerät:"
              />
              {
                <div key={device.device_id}>
                  {device.device_name} ({device.device_id}),
                </div>
              }
            </h5>

            <DatePicker
              calendarClassName="week-picker"
              customInput={
                <CustomWeekPickerInput selectedDate={this.state.selectedDate} />
              }
              dateFormat={this.props.dateFormat}
              dayClassName={date => {
                if (
                  date.isAfter(this.state.selectedDate.from) &&
                  date.isBefore(this.state.selectedDate.to)
                ) {
                  return "react-datepicker__day--selected";
                }
              }}
              locale={moment().locale() || defaultLocale}
              maxDate={moment().endOf("isoWeek")}
              onChange={this.handleDateChange}
              selected={this.state.selectedDate.from}
              showMonthDropdown
              withPortal
            />

            {this.state.accessList.unauthorized.length > 0 ||
            this.state.accessList.authorized.length > 0 ? (
              <div className="rfidable-access">
                {this.state.accessList.unauthorized.map(access => (
                  <div
                    className="unauthorized rfidable-access-wrap"
                    key={access.chip_code}
                  >
                    <div className="rfidable-access-date">
                      <strong>Datum</strong>
                      <span className="rfidable-access-content">
                        {access.date},{access.time}
                      </span>
                    </div>
                    <div className="rfidable-access-chip">
                      <strong>Chip-Code</strong>
                      <span className="rfidable-access-content">
                        {access.chip_code}
                      </span>
                    </div>
                    <div className="rfidable-access-address">
                      <strong>Adresse</strong>
                      <span className="rfidable-access-content">
                        {access.address}
                      </span>
                    </div>
                  </div>
                ))}

                {this.state.accessList.authorized.map(access => (
                  <div
                    className="authorized rfidable-access-wrap"
                    key={access.chip_code}
                  >
                    <div className="rfidable-access-date">
                      <strong>Datum</strong>
                      <span className="rfidable-access-content">
                        {access.date},{access.time}
                      </span>
                    </div>
                    <div className="rfidable-access-chip">
                      <strong>Chip-Code</strong>
                      <span className="rfidable-access-content">
                        {access.chip_code}
                      </span>
                    </div>
                    <div className="rfidable-access-address">
                      <strong>Adresse</strong>
                      <span className="rfidable-access-content">
                        {access.address}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="rfidable-access">
                <T
                  id="tracar.rfidable-accesshistory.noaccesses"
                  defaultMessage="Keine Zugriffe stattgefunden."
                />
              </div>
            )}

            <div
              className="button save-button"
              onClick={this.props.closeFunction}
            >
              <T
                id="tracar.rfidable-accesshistory.close"
                defaultMessage="Schliessen"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const RfidableAccessHistory = injectIntl(RfidableAccessHistoryClass);
