import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { RequestOverlay } from "../Request/RequestOverlay";
import { handleInputChange } from "../tools/handleInputChange";
import FileUploader from "../FileUploader";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  chip: {
    id: "tracar.rfid-chip-upload.chip",
    defaultMessage: "*.csv",
  },
  linkExampleFile: {
    id: "tracar.rfid-chip.linkExampleFile",
    defaultMessage: "/rfidChips/rfid_csv_beispiel_DE.csv",
  },
});

const MAX_FILE_SIZE = 2000000; //2mb
const MAX_FILE_SIZE_LABEL = "2 MB";

class RfidChipUploadClass extends React.Component {
  state = {
    saving: false,
    file: null,
    hasSizeError: false,
    hasApiError: false,
  };

  handleFileChange = file => {
    if (this.validateSize(file)) {
      this.setState({
        file: file,
        hasSizeError: false,
      });
    }
  };

  validateSize = file => {
    if (file.size > MAX_FILE_SIZE) {
      this.setState({ hasSizeError: true });
      return false;
    }
    return true;
  };

  save = () => {
    if (!this.state.hasSizeError) {
      this.setState({ saving: true });

      this.props
        .uploadChipList({
          file: this.state.file,
        })
        .then(res => {
          this.props.loadRfidChips();
          this.props.closeFunction();
        })
        .catch(err => {
          console.error(err);
          this.setState({ saving: false, hasApiError: true });
        });
    }
  };

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="create-new-chip"
      >
        {this.state.saving ? (
          <LoadingCircle />
        ) : this.state.hasApiError ? (
          <p className="error">
            <T
              id="tracar.rfid-chip-upload.api-error"
              defaultMessage=" Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
            />
          </p>
        ) : (
          <div>
            <h5>
              <T
                id="tracar.rfid-chip-upload.headline"
                defaultMessage="Chip-Liste hochladen"
              />
            </h5>

            <p className="description">
              <T
                id="tracar.rfid-chip-upload.description"
                defaultMessage={`Hier können Sie eine CSV-Datei hochladen.`}
              />
              <br />
              <a href={t(terms.linkExampleFile)} target="_blank">
                <strong>
                  <T
                    id="tracar.rfid-chip.downloadExampleFileLabel"
                    defaultMessage="Beispieldatei herunterladen"
                  />
                </strong>
              </a>
            </p>

            {this.state.hasSizeError && (
              <p className="error">
                <T
                  id="tracar.rfid-chip-upload.filesize-error"
                  defaultMessage={`Sie haben die maximale Dateigröße von ${MAX_FILE_SIZE_LABEL} überschritten.`}
                />
              </p>
            )}

            <FileUploader
              className="chip-csv-upload"
              handleSelectedFile={this.handleFileChange}
              validExtensions={[".csv"]}
            />

            <button
              className={
                "button save-button" +
                (this.state.hasSizeError || !this.state.file ? " disabled" : "")
              }
              onClick={
                this.state.hasSizeError || !this.state.file ? null : this.save
              }
            >
              <T id="tracar.rfid-chip-upload.save" defaultMessage="Hochladen" />
            </button>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const RfidChipUpload = injectIntl(RfidChipUploadClass);
