
import { postJson, putJson } from "./fetchService";
import { saveAs } from "file-saver";


/// Collection of all fetch functions regarding the non-company-user management
export const fetchWires = (jsonFetch, freeFetch) => ({
  wiresQuery(imei, wires) {
    const route = `/user/wires/${imei}`;
    return wires == null ? jsonFetch(route) : jsonFetch(route, putJson(wires));
  },
  /// for a problem description of the testing set up see
  /// https://stackoverflow.com/questions/43344819/reading-response-headers-with-fetch-api
  /// we will not get the name of the file unless it is live :/
  generateWiresCsv(imei, range) {
    return freeFetch(`/user/wirescsv/${imei}`, {
      ...postJson(range),
      responseType: "blob",
    })
      .then(response => {
        return response.blob();
      })
      .then(blob => saveAs(blob, "Daten-Kabel.csv"));
  },
});
