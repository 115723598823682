import React from "react";
import { TagBar } from "../Tags/TagBar";
import { GeofenceBar } from "../Geofence/GeofenceBar";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { hasPermission, Permission } from "../Permission";
import { UserConsumer } from "../Base";

const terms = defineMessages({
  tags: {
    id: "tracar.filter-bar.tags",
    defaultMessage: "Tags",
  },
  geofences: {
    id: "tracar.filter-bar.geofences",
    defaultMessage: "Sicherheitszonen",
  },
  withoutFilter: {
    id: "tracar.filter-bar.withoutFilter",
    defaultMessage: "ohne Filterung",
  },
  onlineFilter: {
    id: "tracar.filter-bar.onlineFilter",
    defaultMessage: "Online",
  },
  offlineFilter: {
    id: "tracar.filter-bar.offlineFilter",
    defaultMessage: "Offline",
  },
  noOnlineFilter: {
    id: "tracar.filter-bar.noOnlineFilter",
    defaultMessage: "Online/Offline",
  },
});

class FilterBarClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "",
      tabs: [],
    };
  }

  componentDidMount() {
    this.loadTabs();
  }

  loadTabs = () => {
    const tabs = ["tags", "geofences"];
    this.setState({ tabs: tabs, currentTab: tabs[0] });
  };

  switchCurrentTab = tab => {
    this.setState({ currentTab: tab });
  };

  render() {
    const t = this.props.intl.formatMessage;

    let onlineStatus = {};
    switch (this.props.onlineFilter) {
      case "": {
        onlineStatus = {
          title: t(terms.noOnlineFilter),
          style: { fontWeight: "normal" },
        };
        break;
      }
      case "true": {
        onlineStatus = {
          title: t(terms.onlineFilter),
          style: { fontWeight: "bold", color: "green" },
        };
        break;
      }
      case "false": {
        onlineStatus = {
          title: t(terms.offlineFilter),
          style: { fontWeight: "bold", color: "red" },
        };
        break;
      }
      default:
        break;
    }

    return (
      <UserConsumer>
        {user => (
          <React.Fragment>
            <div className="tag-tab-buttons">
              {this.state.tabs.map(tab => {
                // do not display geofence tab if user lacks the correct permission
                if (
                  tab === "geofences" &&
                  !hasPermission(user, Permission.SafetyZone)
                ) {
                  return "";
                }
                return (
                  <div className="tag-tab-button" key={`tag-tab-button-${tab}`}>
                    <div
                      className={`link ${tab === this.state.currentTab &&
                        "active"}`}
                      onClick={() => this.switchCurrentTab(tab)}
                    >
                      {t(terms[tab])}
                    </div>
                  </div>
                );
              })}
              <div className="tag-tab-button-right">
                <div
                  className="link"
                  onClick={() => this.props.toggleOnlineFilter()}
                  style={onlineStatus.style}
                >
                  {onlineStatus.title}
                </div>
              </div>
            </div>
            {this.props.tagList && this.renderTab(this.state.currentTab)}
            <div className="tag-tab-buttons">
              {this.state.tabs.map(tab => {
                return (
                  <div
                    className="tag-tab-button"
                    key={`geofence-tab-button-${tab}`}
                  >
                    {tab === "tags" && (
                      <div>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {t(terms.tags) + ": "}
                        </span>
                        {this.props.selectedTags &&
                        this.props.selectedTags.length ===
                          this.props.tagList.tag_id_list.length + 1
                          ? t(terms.withoutFilter)
                          : this.props.selectedTags.map(tag => {
                              return (
                                <span
                                  className="filter-overview-label"
                                  key={tag.id}
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {tag.name === "no_tags_placeholder" ? (
                                    <T
                                      id="tracar.tag.no-tags"
                                      defaultMessage="Ohne Tags"
                                    />
                                  ) : (
                                    tag.name
                                  )}
                                </span>
                              );
                            })}
                      </div>
                    )}
                    {tab === "geofences" &&
                      hasPermission(user, Permission.SafetyZone) && (
                        <div>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {t(terms.geofences) + ": "}
                          </span>
                          {this.props.selectedGeofences &&
                          this.props.selectedGeofences.length ===
                            this.props.geofencingList.length + 1
                            ? t(terms.withoutFilter)
                            : this.props.selectedGeofences.map(geofence => {
                                return (
                                  <span
                                    className="filter-overview-label"
                                    key={geofence.id}
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {geofence.name ===
                                    "no_geofences_placeholder" ? (
                                      <T
                                        id="tracar.tag.no-geofences"
                                        defaultMessage="Ohne Sicherheitszonen"
                                      />
                                    ) : (
                                      geofence.name
                                    )}
                                  </span>
                                );
                              })}
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </UserConsumer>
    );
  }

  renderTab(tab) {
    switch (tab) {
      case "tags": {
        return (
          <TagBar
            fetchTags={this.props.fetchTags}
            loadTags={this.props.loadTags}
            setTagsAsSaving={this.props.setTagsAsSaving}
            selectedTags={this.props.selectedTags}
            selectedAllTags={this.props.selectedAllTags}
            tagList={this.props.tagList}
            toggleSelectAll={this.props.toggleSelectAll}
            toggleSelectedTags={this.props.toggleSelectedTags}
            tagListLoadingState={this.props.tagListLoadingState}
          />
        );
      }
      case "geofences": {
        return (
          <UserConsumer>
            {user =>
              hasPermission(user, Permission.SafetyZone) && (
                <GeofenceBar
                  geofencingList={this.props.geofencingList}
                  selectedGeofences={this.props.selectedGeofences}
                  selectedAllGeofences={this.props.selectedAllGeofences}
                  toggleSelectAllGeofences={this.props.toggleSelectAllGeofences}
                  toggleSelectedGeofences={this.props.toggleSelectedGeofences}
                />
              )
            }
          </UserConsumer>
        );
      }
      default: {
        return;
      }
    }
  }
}

export const FilterBar = injectIntl(FilterBarClass);
