import React from "react";
import { noGeofencesPlaceholder } from "../TrackerList/TrackerListBase";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

export class GeofenceBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    this.props.selectedGeofences.length === 0 &&
      this.props.toggleSelectAllGeofences();
    return (
      <div>
        <div className="button-frame tag-buttons">
          <div className="block ">
            <div
              className={`button
              ${!this.props.selectedAllGeofences && "inactive"}`}
              onClick={() => this.props.toggleSelectAllGeofences()}
            >
              <i className="icon-globe" />
            </div>
            <div
              className={`button
              ${!this.props.selectedGeofences.find(
                geofence => geofence === noGeofencesPlaceholder,
              ) && "inactive"}`}
              onClick={() =>
                this.props.toggleSelectedGeofences(noGeofencesPlaceholder)
              }
            >
              <i className="icon-globe" />
              &nbsp;&nbsp;&nbsp;
              <T
                id="tracar.tag.no-geofences"
                defaultMessage="Ohne Sicherheitszonen"
              />
            </div>
            {this.props.geofencingList.reduce((acc, geofence) => {
              return [
                ...acc,
                <div
                  key={geofence.geofence_id}
                  className={`button
                    ${!this.props.selectedGeofences.find(
                      selectedGeofence =>
                        selectedGeofence.id === geofence.geofence_id,
                    ) && "inactive"}`}
                  onClick={() =>
                    this.props.toggleSelectedGeofences({
                      id: geofence.geofence_id,
                      name: geofence.name,
                    })
                  }
                >
                  <i className="icon-globe" />
                  &nbsp;&nbsp;&nbsp;{geofence.name}
                </div>,
              ];
            }, [])}
          </div>
        </div>
      </div>
    );
  }
}
