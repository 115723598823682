import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";
import { DeviceFilterType } from "../../ApiContract";
import { PoolVehicleList } from "./PoolVehicleList";
import { PoolVehicleAssignDriverOverlay } from "./PoolVehicleAssignDriverOverlay";

const deviceListLimit = 10;

class AdminPanelPoolVehicleBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      recordList: [],
      selectedTags: [],
      showImportOverlay: false,
      showDeleteConfirmationOverlay: false,
      tagListLoadingState: "loading",
      showPoolVehicleAssignDriverOverlay: false,
      query: "",
      tagList: { tag_id_list: [], tag_objects: {} },
      userList: [],
      // total number of devices across all pagination pages
      numberOfDevices: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // used by pagination to fetch the devices displayed on the current pagination page
      deviceListOffset: 0,
      // search term to filter role list
      deviceListSearchKey: "",
      // filter devices by assignment type
      deviceListFilterType: DeviceFilterType.All,
      orderBy: [],
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    const orderByArray = this.state.orderBy.map(
      item => `${item.ord === 1 ? "-" : ""}${item.cat}`,
    );
    this.props
      .getPoolVehicleList(
        deviceListLimit,
        this.state.deviceListOffset,
        this.state.deviceListSearchKey,
        "",
        orderByArray.length === 0 ? undefined : orderByArray,
      )
      .then(recordListData =>
        this.setState({
          loading: false,
          recordList: recordListData.poolvehicles,
          numberOfDevices: recordListData.pagination.total_amount,
          numberOfPages: Math.ceil(
            recordListData.pagination.total_amount / deviceListLimit,
          ),
        }),
      );
  }

  setSearchFilter = deviceListSearchKey => {
    this.setState({ deviceListSearchKey }, this.load);
  };

  updateCurrentPage = number => {
    this.setState({ currentPage: number });
  };

  getDeviceTypeList = async () => {
    return ["car", "utility", "asset", "person"];
  };

  setDeviceFilter = event => {
    this.setState({ deviceListFilterType: event.target.value }, this.load);
  };

  setOrderBy = arr => {
    this.setState({ orderBy: arr }, this.load);
  };

  render() {
    return (
      <UserConsumer>
        {user =>
          hasPortalAccess(user, Portal.AdminPanelDevice) && (
            <div className="outer-table-frame">
              <div className="grid-x grid-margin-x align-middle search-area">
                <div className="auto cell">
                  <QueryLine
                    changeCallback={this.setSearchFilter}
                    changeDelay={500}
                  />
                </div>
                <div className="medium-3 cell">
                  <select
                    className="margin-bottom-1"
                    onChange={this.setDeviceFilter}
                  >
                    <option value={DeviceFilterType.All}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.all"
                        defaultMessage="Alle"
                      />
                    </option>
                    <option value={DeviceFilterType.Unassigned}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.unassigned"
                        defaultMessage="Nicht zugewiesen"
                      />
                    </option>
                    <option value={DeviceFilterType.Assigned}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.assigned"
                        defaultMessage="Zugewiesen"
                      />
                    </option>
                    <option value={DeviceFilterType.AssignedFahrtenbuch}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.assignedFahrtenbuch"
                        defaultMessage="Fahrtenbuch zugewiesen"
                      />
                    </option>
                  </select>
                </div>
              </div>
              {this.renderDeviceList()}
            </div>
          )
        }
      </UserConsumer>
    );
  }

  /**
   * Returns the device-list-table
   * @return JSX
   */
  renderDeviceList = () => {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }

    return (
      <div>
        <PoolVehicleList
          fetchTags={this.props.fetchTags}
          recordList={this.state.recordList}
          toggleDeviceEditOverlay={this.toggleDeviceEditOverlay}
          toggleDeviceAssignUserOverlay={this.toggleDeviceAssignUserOverlay}
          togglePoolVehicleAssignDriverOverlay={
            this.togglePoolVehicleAssignDriverOverlay
          }
          numberOfDevices={this.state.numberOfDevices}
          updateListOffset={this.updateListOffset}
          updateListSearchKey={this.updateListSearchKey}
          updatePagination={this.updatePagination}
          currentPage={this.state.currentPage}
          numberOfPages={this.state.numberOfPages}
          setOrderBy={this.setOrderBy}
          orderedBy={this.state.orderBy}
          messageListQuery={this.props.messageListQuery}
        />
        {this.state.showPoolVehicleAssignDriverOverlay && (
          <PoolVehicleAssignDriverOverlay
            closeFunction={this.togglePoolVehicleAssignDriverOverlay}
            device={this.state.selectedDevice}
            getDriverOptionsForDevice={this.props.getDriverOptionsForDevice}
            checkUserInToDevice={this.props.checkUserInToDevice}
            checkUserOutOfDevice={this.props.checkUserOutOfDevice}
          />
        )}
      </div>
    );
  };

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };

  toggleDeviceEditOverlay = () => {
    // this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
    alert("Toggle edit overlay");
  };

  toggleDeviceAssignUserOverlay = device => {
    this.setState({
      showDeviceAssignUserOverlay: !this.state.showDeviceAssignUserOverlay,
      selectedDevice: device,
    });
  };

  togglePoolVehicleAssignDriverOverlay = device => {
    this.setState(
      {
        showPoolVehicleAssignDriverOverlay: !this.state
          .showPoolVehicleAssignDriverOverlay,
        selectedDevice: device,
      },
      this.load,
    );
  };

  toggleUserCreateOverlay = () => {
    //alert(`showUserCreateOverlay: ${!this.state.showDeviceUserCreateOverlay}`);
    this.setState({
      showDeviceUserCreateOverlay: !this.state.showDeviceUserCreateOverlay,
    });
  };

  /**
   * Updates the pagination offset to facilitate page switching
   * @param offset
   */
  updateListOffset = offset => {
    this.setState({ deviceListOffset: offset }, this.load);
  };

  /**
   * Updates the search term the list is filtered by
   * @param searchKey
   */
  updateListSearchKey = searchKey => {
    this.setState({ deviceListSearchKey: searchKey }, this.load);
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * deviceListLimit);
  };
}

export const AdminPanelPoolVehicleBase = injectIntl(
  AdminPanelPoolVehicleBaseClass,
);
