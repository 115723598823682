import React from "react";
import {FormattedMessage as T} from "react-intl";

export class SmartInventoryListFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="medium-2 cell">
                <select onChange={this.props.changeFunction}>
                    <option value="all">
                        <T id="adminPanel.smartInventory.optionLabel.showAll" defaultMessage="Alle">
                            {message => message}
                        </T>
                    </option>
                    <option value="available">
                        <T id="adminPanel.smartInventory.optionLabel.showAvailable" defaultMessage="Verfügbar">
                            {message => message}
                        </T>
                    </option>
                    <option value="unavailable">
                        <T id="adminPanel.smartInventory.optionLabel.showUnavailable" defaultMessage="Nicht verfügbar">
                            {message => message}
                        </T>
                    </option>
                </select>
            </div>
        );
    };
}
