
const wholeDayDefaultStartingTime = "00:00:00";
const wholeDayDefaultEndTime = "23:59:59";
const alternateWholeDayDefaultEndTime = "23:59:00";

/// TODO remove, replace by isWholeDay prop

export function deviceSafetyZoneIsWholeDay(deviceSafetyZone) {
  return (
    (deviceSafetyZone.start_time === wholeDayDefaultStartingTime &&
      (deviceSafetyZone.end_time === wholeDayDefaultEndTime ||
        deviceSafetyZone.end_time === alternateWholeDayDefaultEndTime)) ||
    (deviceSafetyZone.start_time == null && deviceSafetyZone.end_time == null)
  );
}
