import React from "react";
import { AccLogoSmall } from "./ClientSpecificContent/acc-index";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  linkImprint: {
    id: "footer.linkImprint",
    defaultMessage: " _ ",
  },
  languageSelectMessage: {
    id: "footer.languageSelectMessage",
    defaultMessage:
      " - Ihre Spracheinstellung für das Portal und Ihre E-Mails wurde auf deutsch gestellt.",
  },
});

class FooterClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageMessage: false,
    };
  }

  setLanguage = languageCode => {
    this.props.setLanguage(languageCode);
    this.props.localeQuery(languageCode);
    this.setState({ languageMessage: false }, () =>
      this.setState({
        languageMessage: true,
      }),
    );
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <footer>
        <div className="inner">
          <img src={AccLogoSmall} alt="Vodafone Logo" />
          <small>
            © {new Date().getFullYear()} <T id="footer.company" />
            {t(terms.linkImprint) !== " " ? (
              <span>
                &nbsp; | &nbsp;{" "}
                <a
                  href={t(terms.linkImprint)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <T id="footer.imprint" defaultMessage="Impressum" />
                </a>
              </span>
            ) : (
              ""
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className="link" onClick={() => this.setLanguage("en")}>
              en
            </span>{" "}
            |{" "}
            <span className="link" onClick={() => this.setLanguage("de")}>
              de
            </span>{" "}
          </small>
          {this.state.languageMessage && (
            <span className="language-changed-message">
              &nbsp;&nbsp;&nbsp;
              {t(terms.languageSelectMessage)}
            </span>
          )}
          <div className="version-number">
            {process.env.REACT_APP_VERSION_NUMBER}
          </div>
        </div>
      </footer>
    );
  }
}

export const Footer = injectIntl(FooterClass);
