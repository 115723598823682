import React from "react";
import { FormattedMessage as T, injectIntl } from "react-intl";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { SelectInput } from "../../SelectInput";

class DeviceAssignCategoryOverlayClass extends React.Component {
  handleChange;
  submit;

  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showMore: false,
      selectedCategory: this.props.device.device_category.description,
      categoryList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props
      .getCategoryListForDevice(this.props.device.imei)
      .then(categoryList => {
        // reformat category list to work with select input
        categoryList = categoryList.map(categoryName => {
          return {
            id: categoryName,
            name: this.props.intl.formatMessage({
              id: "tracar.portal." + categoryName,
              defaultMessage: categoryName,
            }),
          };
        });
        this.setState({
          categoryList,
        });
      });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  submit() {
    /*if (this.validate()) {
      this.props
        .assignCategoryToDevice(
          this.props.device.imei,
          this.state.selectedCategory,
        )
        .then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }*/
  }

  validate() {
    return true;
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        className={this.state.requestOverlayClassName}
        closeFunction={this.props.closeFunction}
      >
        <div className="header">
          <h3>
            <T
              id="adminPanel.devices.assignCategory.headline"
              defaultMessage="Kategorie zuweisen"
            />{" "}
            <span className="margin-horizontal-1">></span>
            <span className="highlighted">{this.props.device.imei}</span>
          </h3>
        </div>
        <div className="small-spacer" />
        <div>
          <React.Fragment>
            <h4 className="text-left">
              <T
                id="adminPanel.devices.assignCategory.label.selectCategory"
                defaultMessage="Kategorie"
              />{" "}
              <span className="item-count">
                {this.state.categoryRecordList
                  ? this.state.categoryRecordList.length
                  : 0}
              </span>
            </h4>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="medium-auto cell relative">
                  <SelectInput
                    id="selectedCategory"
                    // name={t(terms.user_role_label)}
                    value={this.state.selectedCategory}
                    nameProp={"name"}
                    valueProp={"id"}
                    options={this.state.categoryList}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="small-spacer" />
          </React.Fragment>

          <React.Fragment>
            <div className="small-spacer" />
            <div className="footer text-right">
              <div
                className="button transparent"
                onClick={this.props.closeFunction}
              >
                <T
                  id="adminPanel.users.assignRole.buttonLabel.cancel"
                  defaultMessage="Abbrechen"
                />
              </div>
              <div className="button green primary" onClick={this.submit}>
                <T
                  id="adminPanel.users.assignRole.buttonLabel.submit"
                  defaultMessage="Speichern"
                />
              </div>
            </div>
          </React.Fragment>
        </div>
      </RequestOverlay>
    );
  }
}

export const DeviceAssignCategoryOverlay = injectIntl(
  DeviceAssignCategoryOverlayClass,
);
