import React from "react";
import { FormattedMessage as T } from "react-intl";

export function SelectInput({
  className = "",
  id,
  name,
  selected = false,
  options = [],
  onChange,
  onEnter,
  value,
  disabled = false,
  onFocusValue = null,
  onBlurValue = null,
  nameProp = null,
  valueProp = null,
}) {
  // if (value === null) {
  //   value = "";
  // }
  // const handleKeyPress = e => {
  //   if (e.key === "Enter") {
  //     onEnter && onEnter();
  //   }
  // };
  let optionalProps = {};
  // onEnter && (optionalProps["onKeyPress"] = handleKeyPress);
  disabled && (optionalProps["disabled"] = disabled);
  onFocusValue !== null &&
    (optionalProps["onFocus"] = e => {
      e.target.value = onFocusValue;
    });
  onBlurValue !== null &&
    (optionalProps["onBlur"] = e => {
      e.target.value = onBlurValue;
    });

  nameProp = nameProp === null ? "name" : nameProp;
  valueProp = valueProp === null ? "id" : valueProp;
  let wrapperClass = typeof name === "undefined" ? " without-label" : "";
  return (
    <div className={"select-frame" + wrapperClass}>
      {/*<i className="fa fa-angle-down angle" />*/}
      <i className="arrow-down angle" />
      <select
        {...optionalProps}
        id={id}
        value={value}
        onChange={disabled ? () => {} : onChange}
      >
        {options.map(record => (
          <option value={record[valueProp]} key={record[valueProp]}>
            {record[nameProp]}
          </option>
        ))}
      </select>
      <label htmlFor={id}>{name}</label>
    </div>
  );
}
