import React from "react";
import {defineMessages, injectIntl, FormattedMessage as T} from "react-intl";
import {TextInput} from "../../../../TextInput";

const messages = defineMessages({
    name: {
        id: "adminPanel.smartInventoryTaskTemplate.createEditTemplate.name",
        defaultMessage: "Name der Liste",
    },
    delete: {
        id: "adminPanel.smartInventoryTaskTemplate.createEditTemplate.delete",
        defaultMessage: "Löschen",
    },
});

const formName = 'createEditForm';

class CreateAndEditTaskTemplatesForm extends React.Component {
    constructor(props) {
        super(props);

        const defaultState = {
            deletedTasks: [],
            tasks: this.props.tasks || [],
            name: this.props.template ? this.props.template.name : null,
        };

        this.state = Object.keys(this.props.data).length !== 0 ? this.props.data : defaultState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.tasks && this.props.tasks.length) {
            this.setState({tasks: this.props.tasks});
        }
    }

    render() {
        return (
            <div>
                <div className="small-spacer" />
                <div className="grid-container create-edit-template-form" style={{paddingTop: '1.5rem'}}>
                    <div className="grid-x grid-padding-x">
                        { this.renderNameInput() }
                        <div className="cell large-9 small-0"></div>
                        { this.renderTaskInputs() }
                        { this.renderAddTaskButton() }
                    </div>
                </div>
            </div>
        );
    }

    renderNameInput() {
        return (
            <div className="cell large-3 small-6">
                <TextInput
                    id="name"
                    onChange={this.updateTemplateName}
                    value={this.state.name}
                    name={this.props.intl.formatMessage(messages.name) + '*'}
                />
            </div>
        );
    }

    updateTemplateName = (event) => {
        this.setState({
            name: event.target.value
        }, () => this.props.updateFormData(formName, this.state));
    };

    renderTaskInputs() {
        if (this.state.tasks.length === 0) {
            return;
        }

        return (
            <div className={'cell small-6 large-3 text-left task-container'}>
                <T id="adminPanel.smartInventoryTaskTemplates.labels.tasks" defaultMessage="Einzelaufgabe"/>
                {
                    this.state.tasks.map((task, index) => {
                       return (
                           <div className="cell large-3 small-6 task-input" key={index}>
                                <TextInput
                                    id={'task-' + index}
                                    onChange={(event) => this.updateTaskName(event, index)}
                                    value={task.task_name}
                                    disabled={!!task.id}
                                />
                               <div
                                   className={'delete-task-button'}
                                   title={this.props.intl.formatMessage(messages['delete'])}
                                   onClick={() => { this.removeTask(index) }}
                               >
                                   <T
                                        id="adminPanel.smartInventoryTaskTemplates.labels.delete"
                                        defaultMessage="löschen"
                                    />
                               </div>
                           </div>
                       );
                    })
                }
            </div>
        );
    }

    renderAddTaskButton() {
        return (
            <div className="cell">
                <div className="button-frame" onClick={this.addNewTask}>
                    <div className="button transparent outline primary">
                        <T
                            id="adminPanel.smartInventoryTaskTemplates.labels.createTask"
                            defaultMessage="Listeneintrag hinzufügen"
                        />
                    </div>
                </div>
            </div>
        );
    }

    removeTask(index) {
        let newTasks = this.state.tasks;

        const deletedTask = newTasks.splice(index, 1).pop();

        this.setState({
            tasks: newTasks,
            deletedTasks: [
                ...this.state.deletedTasks,
                deletedTask
            ]
        }, () => this.props.updateFormData(formName, this.state))
    }

    addNewTask = () => {
        this.setState({
            tasks: [
                ...this.state.tasks,
                {
                    id: null,
                    task_name: null
                }
            ]
        }, () => this.props.updateFormData(formName, this.state))
    }

    updateTaskName = (event, index) => {
        let tasks = this.state.tasks;
        let task = tasks[index];
        task.task_name = event.target.value;

        this.setState({ tasks }, () => this.props.updateFormData(formName, this.state));
    };
}

export default injectIntl(CreateAndEditTaskTemplatesForm);
