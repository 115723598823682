import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import { UserLine } from "../User/UserLine";
import { SelectInput } from "../../SelectInput";

const terms = defineMessages({
  user_group_default_label: {
    id: "adminPanel.users.assignUser.filterPlaceholder.usergroup",
    defaultMessage: "Nutzergruppe",
  },
  text_search_placeholder: {
    id: "adminPanel.users.assignUser.placeholder.searchField",
    defaultMessage: "Benutzerdaten",
  },
});

class RoleAssignUserOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      recordListByRole: [],
      showErrorMessage: false,
      selectAllButtonState: "all",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    // fetch list of all users to populate the user list
    this.props.getUserList().then(recordList => {
      this.setState({
        loading: false,
        recordList: recordList.user_data,
      });
    });
    // fetch list of all users that already have the selected role to populate the bubbles
    this.props
      .getUserListByRole(this.props.assignUserRoleRecord.role_name)
      .then(recordListByRole => {
        this.setState({
          loading: false,
          recordListByRole: recordListByRole.user_data,
        });
      });
    // fetch list of all user groups to populate search field
    this.props.getUserGroupList().then(userGroupRecordList => {
      this.setState({
        loading: false,
        userGroupRecordList,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    this.props
      .assignUsersToRole(
        this.props.assignUserRoleRecord.role_id,
        this.state.recordListByRole.map(user => user.user.id),
      )
      .then(this.props.closeFunction);
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  filterRecords = () => {
    return this.state.recordList.filter(record => {
      if (record.user.role.role_id == this.props.assignUserRoleRecord.role_id) {
        return false;
      }

      let stringQueryResult = true;
      if (this.state.query.length >= 1) {
        stringQueryResult =
          record.user.email.search(this.state.query) > -1 ||
          record.user.first_name.search(this.state.query) > -1 ||
          record.user.last_name.search(this.state.query) > -1 ||
          (record.user.dlc &&
            record.user.dlc.dlc_barcode &&
            record.user.dlc.dlc_barcode.search(this.state.query) > -1);
      }

      return stringQueryResult;
    });
  };

  toggleCreateOverlay = new_user_id => {
    this.setState({
      showCreateOverlay: !this.state.showCreateOverlay,
      assignRoleUserRole:
        new_user_id !== undefined ? new_user_id : this.state.assignRoleUserRole,
    });
  };

  selectRecord = record => {
    if (
      !this.state.recordListByRole.some(
        userRecord => userRecord.user.id === record.user.id,
      )
    ) {
      let recordListByRole = [...this.state.recordListByRole, record];
      this.setState({ recordListByRole });
    }
  };

  selectAllRecords = () => {
    this.setState({
      selectAllButtonState: "none",
      recordListByRole: this.state.recordList,
    });
  };

  deselectAllRecords = () => {
    this.setState({
      selectAllButtonState: "all",
      recordListByRole: [],
    });
  };

  deselectRecord = record => {
    this.setState({
      recordListByRole: this.state.recordListByRole.filter(
        currentRecord => currentRecord.user.id !== record.user.id,
      ),
    });
  };

  render() {
    let show_all = false;
    let limited_class = "";
    if (this.state.recordListByRole.length > 10) {
      show_all = true;
      limited_class = "limited";
    }
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        className="role-assign-user"
        closeFunction={this.props.closeFunction}
      >
        <div className="header">
          <h3>
            <T
              id="adminPanel.roles.assignUser.headline"
              defaultMessage="Benutzer zuweisen"
            />{" "}
            ->
            <span className="location">
              {" "}
              {this.props.assignUserRoleRecord.role_name}
            </span>
          </h3>
        </div>
        <div className="small-spacer" />

        <h4 className="text-left">
          <T
            id="adminPanel.roles.assignUser.label.assignedUsers"
            defaultMessage="Zugewiesene Nutzer"
          />{" "}
          <span className="item-count">
            {this.state.recordListByRole.length}
          </span>
        </h4>
        <div
          className={
            "grid-x grid-padding-x grid-padding-y relative list-horizontal " +
            limited_class
          }
        >
          {show_all && <div className="radiant-overlay" />}
          {this.state.recordListByRole &&
            this.state.recordListByRole.map((record, index) => (
              <div className="cell shrink" key={index}>
                <div className="list-item flex-container align-justify">
                  <span>{record.user.email}</span>
                  <i
                    className="icon-remove_circle close-icon align-self-middle"
                    style={{ marginLeft: "8px" }}
                    onClick={() => this.deselectRecord(record)}
                  />
                </div>
              </div>
            ))}
        </div>
        {/* @todo auf click eine Klasse an .assigned-user-list schreiben. Dann kann mit css die höhe animiert werden und es werden alle angezeigt*/}
        {show_all && <a className="show-all">show all</a>}
        <div className="small-spacer" />
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="large-auto medium-6 cell">
            <QueryLine
              changeCallback={this.setSearchFilter}
              placeholder={t(terms.text_search_placeholder)}
            />
          </div>
          {
            // temporarily disabled
            /*<div className="large-3 medium-6 cell">
            <SelectInput
              id="userGroupFilter"
              // name={t(terms.user_role_label)}
              // nameProp={"role_name"}
              options={this.state.userGroupRecordList}
              onChange={this.setSearchFilter}
            />
          </div>*/
          }
        </div>
        <div className="small-spacer" />
        <div className="admin-list outer-table-frame text-left">
          {this.state.selectAllButtonState === "all" && (
            <div
              className="button transparent noPadding"
              onClick={this.selectAllRecords}
            >
              <T
                id="adminPanel.roles.assignUser.buttonLabel.selectAllUsersButton"
                defaultMessage="Alle auswählen"
              />
            </div>
          )}
          {this.state.selectAllButtonState === "none" && (
            <div
              className="button transparent noPadding"
              onClick={this.deselectAllRecords}
            >
              <T
                id="adminPanel.roles.assignUser.buttonLabel.deselectAllUsersButton"
                defaultMessage="Alle abwählen"
              />
            </div>
          )}
          <div className="table-frame table-scroll">
            {this.state.recordList &&
              this.state.recordListByRole &&
              this.filterRecords().map((record, index) => {
                return (
                  <UserLine
                    key={record.id}
                    currentUser={record}
                    is_selected={this.state.recordListByRole.some(
                      recordListByRoleEntry =>
                        recordListByRoleEntry.user.id === record.user.id,
                    )}
                    selectRecord={this.selectRecord}
                    deselectRecord={this.deselectRecord}
                  />
                );
              })}
          </div>
        </div>
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.roles.assignUser.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.roles.assignUser.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const RoleAssignUserOverlay = injectIntl(RoleAssignUserOverlayClass);
