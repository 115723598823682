/// transform form data to data that can be saved

export function generateSaveableDataTrackerDetailFromData(data) {
  let {
    car_number,
    classify_routes,
    deviceEmailList,
    driver,
    max_speed,
    model,
    user_id,
    ...saveableData
  } = data;
  deviceEmailList.length === 0 && (deviceEmailList = [""]);
  deviceEmailList = deviceEmailList.map(mail => ({ mail }));
  if (max_speed === "") {
    max_speed = -1;
  }
  return {
    deviceEmailList,
    max_speed,
    trackerDetail: {
      ...saveableData,
      cardetails: {
        car_number,
        classify_routes,
        driver,
        model,
      },
    },
    user_id,
  };
}
