import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";


const terms = defineMessages({
  noFile: {
    id: "fileUploader.noFile",
    defaultMessage: "Keine Datei ausgewählt"
  },
  selectedFileLabel: {
    id: "fileUploader.selectedFileLabel",
    defaultMessage: "Ausgewählte Datei:"
  },
  errorWrongExtension: {
    id: "fileUploader.wrongExtension",
    defaultMessage: "Es sind nur Dateien folgender Typen erlaubt:"
  }
});

/**
 * Renders a file upload
 */
class FileUploaderClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      selectedFile: null
    };

    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * Remove '.' from valid extensions if present
   * @return array
   */
  getUnifiedExtensions = () => {
    return this.props.validExtensions.map((extension) => {
      return extension.replace('.', '');
    });
  }

  /**
   * Hide file input to be able to style the button.
   * Trigger click on input on button click.
   * @param  Event event
   * @return void
   */
  handleClick(event) {
    this.refs.fileInput.click();
  }

  /**
   * Handle file select. Set the file to show its name
   * @param  event
   * @return void
   */
  handleSelect = (event) => {
    const [file] = event.target.files;
    const valid = this.validateFile(file);

    if (!valid) {
      return;
    }

    this.setState({
      selectedFile: file
    }, () => {
      this.props.handleSelectedFile(file)
    });
  }

  /**
   * Validates file by passed valid extensions. Sets errors.
   * @return boolean
   */
  validateFile = (file) => {
    this.setState({
      errors: []
    });

    if (!this.props.validExtensions) {
      return true;
    }

    const t = this.props.intl.formatMessage;
    const validExtensions = this.getUnifiedExtensions();
    const extension = file.name.substr(file.name.lastIndexOf('.') + 1);

    if (validExtensions.indexOf(extension) === -1) {
      this.setState({
        errors: [...this.state.errors, {
          type: 'wrongExtension',
          message: `${t(terms.errorWrongExtension)} ${this.props.validExtensions.join(', ')}`
        }]
      });

      return false;
    }

    return true;
  }

  render() {
    return (
      <div className={`file-upload ${this.props.className}`}>
        <input type="file" style={{display: 'none'}} ref="fileInput" onChange={this.handleSelect} accept={this.props.validExtensions || null} />

        <div className="grid-x">
          <div className="cell small-8 file-info-container">{this.renderSelectedFileInfo()}</div>
          <div className="button-container">{this.renderButton()}</div>
        </div>

        {this.renderErrors()}

      </div>
    );
  }

  /**
   * Renders the filename of the selected file - 'no file selected' otherwise
   * @return JSX
   */
  renderSelectedFileInfo = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="file-info">
        <label>
          <strong>
            {t(terms.selectedFileLabel)}
          </strong>
        </label>
        {
          this.state.selectedFile ?
            this.state.selectedFile.name :
            t(terms.noFile)
        }
      </div>
    );
  }

  renderButton = () => {
    return (
      <div className="file-upload-button button pull-right" onClick={this.handleClick}>
        <T
          id="customer.addresses.import.selectFile"
          defaultMessage="Datei auswählen"
        />
      </div>
    );
  }

  renderErrors = () => {
    if (this.state.errors.length === 0) {
      return null;
    }

    return (
      <div className="error-container">
        {this.state.errors.map((error) => {
          return <div className="error">{error.message}</div>
        })}
      </div>
    );
  }
}

export default injectIntl(FileUploaderClass);
