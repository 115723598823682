// https://tomchentw.github.io/react-google-maps/#usage--configuration
import {
  withGoogleMap,
  GoogleMap,
  Circle,
  Polygon,
  Marker,
} from "react-google-maps";
import { InfoOverlay } from "../Map/InfoOverlay";
import React from "react";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import { GeofenceType } from "./GeofenceType";
import { Redirect, withRouter } from "react-router-dom";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import {
  convertDeviceToPosition,
  convertCustomerAddressToPosition,
  getPinUrl,
} from "../Map/functions";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

const terms = defineMessages({
  name: {
    id: "tracar.geofencing.name",
    defaultMessage: "Name der Sicherheitszone",
  },
});

/// for more info on shapes
/// @see https://developers.google.com/maps/documentation/javascript/shapes

const google = window.google;

/// TODO in urgent need of refactoring
/// TODO - e.g. why is there polygon as class prop and state?
class GeofencingClass extends React.Component {
  changeName;
  createCircle;
  createPolygon;
  deleteGeofence;
  saveObject;

  geofenceId;
  /// Reference to the google maps of the type Circle or Polygon
  googleMapsRef;
  geofenceType;
  map;
  polygon;

  constructor(props) {
    super(props);

    if (this.props.match.params.geofenceId === "new") {
      this.geofenceId = "new";
    } else {
      this.geofenceId = parseInt(this.props.match.params.geofenceId, 10);
    }
    this.state = {
      bounds: null,
      circle: null,
      /// holds the rendered rect component i.e. Circle or Polygon
      currentlyRenderedObject: null,
      name: "",
      polygon: null,
      showErrorMessage: false,
      zoom: null,
      device: null,
      initialFit: false,
    };
    this.createPolygon = this.createPolygon.bind(this);
    this.createCircle = this.createCircle.bind(this);
    this.deleteGeofence = this.deleteGeofence.bind(this);
    this.saveObject = this.saveObject.bind(this);
    this.changeName = this.changeName.bind(this);

    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(function(position) {});
    }
  }

  componentDidMount() {
    this.setState();
    this.setMapLocation(this.parseIMEI(this.props.location.state));
  }

  componentDidUpdate() {
    if (!this.state.initialFit) {
      this.fitToBounds();
    }
    if (
      this.props.customerAddressList &&
      this.props.customerAddressList.fitToBounds
    ) {
      this.fitToBounds();
    }
  }

  fitToBounds = () => {
    const devices =
      this.props.customerAddressList &&
      this.props.customerAddressList.show &&
      this.props.customerAddressList.addresses
        ? this.props.customerAddressList.addresses
        : this.state.device
        ? [this.state.device]
        : null;

    if (devices === null) {
      return;
    }

    /// Fit the map for several markers
    const bounds = new window.google.maps.LatLngBounds();
    devices.map(device => bounds.extend(convertDeviceToPosition(device)));
    if (devices.length > 1) {
      this.map.fitBounds(bounds);
    } else if (devices.length === 1) {
      this.map.fitBounds(bounds);
      /// currently preload is only Minimap thus less zoom
      const zoom = this.props.preLoad ? 13 : 15;
      this.setState({ zoom });
    }

    this.props.customerAddressList &&
      this.props.customerAddressList.fitToBounds &&
      this.props.updateFitToBounds(false);
    this.setState({
      initialFit: true,
      label: null,
    });
  };

  handleZoomChanged() {
    const zoom = this.map.getZoom();
    if (zoom !== this.state.zoom) {
      this.setState({ zoom });
    }
  }

  parseIMEI = location => {
    if (location != null) {
      var match = location.from.match(/\d+/g);
      return match[match.length - 1];
    }
    return null;
  };

  setMapLocation = imei => {
    if (imei != null) {
      this.props.getLocationForSingleTracker(imei, false).then(res => {
        const devices = Array.isArray(res) ? [] : [res];
        if (devices.length > 0) {
          this.setState({
            device: devices[0],
            zoom: 12,
          });
        } else {
          this.setDefaultMapBounds();
        }
      });
    } else {
      this.setDefaultMapBounds();
    }
  };

  setDefaultMapBounds() {
    this.map.fitBounds({
      east: 13.916343,
      north: 53.930902,
      south: 48.166079,
      west: 6.012385,
    });
  }

  changeName(event) {
    this.setState({
      name: event.target.value,
    });
  }

  saveObject() {
    if (!this.state.name) {
      this.setState({ showErrorMessage: false }, () =>
        window.setTimeout(() => this.setState({ showErrorMessage: true })),
      );
      return;
    }
    const geofenceIdObj =
      this.geofenceId === "new" ? {} : { geofence_id: this.geofenceId };
    /// save circle
    if (this.geofenceType === GeofenceType.Circle) {
      const center = this.googleMapsRef.getCenter();
      this.props
        .saveGeofence({
          ...geofenceIdObj,
          name: this.state.name,
          fence_type: GeofenceType.Circle,
          data: this.googleMapsRef.getRadius(),
          center_lat: center
            .lat()
            .toString()
            .slice(0, 10),
          center_lon: center
            .lng()
            .toString()
            .slice(0, 10),
        })
        .then(() =>
          this.setState({ currentlyRenderedObject: this.getRedirect() }),
        );
      return;
    }
    /// save polygon
    if (this.geofenceType === GeofenceType.Polygon) {
      this.props
        .saveGeofence({
          ...geofenceIdObj,
          name: this.state.name,
          fence_type: GeofenceType.Polygon,
          data: JSON.stringify(this.googleMapsRef.getPath().getArray()),
        })
        .then(this.setState({ currentlyRenderedObject: this.getRedirect() }));
      return;
    }
    throw new Error("unknown object type");
  }

  getRedirect() {
    const pathname =
      this.props.location.state && this.props.location.state.from != null
        ? this.props.location.state.from
        : `/${this.props.match.params.portal || "tracar"}/geofencing`;

    return (
      <Redirect
        to={{
          /// TODO replace tracar with portal
          pathname,
          state: { savedGeofenceName: this.state.name },
        }}
      />
    );
  }

  componentWillMount() {
    if (this.geofenceId === "new") {
      return;
    }
    /// load and initialize the fence
    this.props.getGeofencingList().then(res => {
      const loadedFence = res.find(
        ({ geofence_id }) => geofence_id === this.geofenceId,
      );
      if (loadedFence && loadedFence.fence_type === GeofenceType.Circle) {
        this.setState(
          {
            name: loadedFence.name,
            currentlyRenderedObject: this.createCircle(
              {
                lat: parseFloat(loadedFence.center_lat),
                lng: parseFloat(loadedFence.center_lon),
              },
              parseFloat(loadedFence.data),
            ),
          },
          () => this.map.fitBounds(this.googleMapsRef.getBounds()),
        );
      }
      if (loadedFence && loadedFence.fence_type === GeofenceType.Polygon) {
        this.setState(
          {
            name: loadedFence.name,
            currentlyRenderedObject: this.createPolygon(loadedFence.data),
          },
          () => {
            const bounds = new window.google.maps.LatLngBounds();
            const path = this.googleMapsRef.getPath().getArray();
            path.forEach(p => bounds.extend(p));
            this.map.fitBounds(bounds);
          },
        );
      }
    });
  }

  deleteGeofence() {
    this.setState({
      circle: null,
      polygon: null,
      currentlyRenderedObject: null,
    });
  }

  createCircle(center, radius) {
    this.geofenceType = GeofenceType.Circle;
    const circle = (
      <Circle
        radius={radius}
        center={center}
        editable={true}
        defaultDraggable={true}
        ref={circle => {
          this.googleMapsRef = circle;
        }}
        onDragEnd={e => {}}
        onRadiusChanged={e => {}}
        defaultOptions={{
          strokeColor: Color.Red,
          strokeOpacity: 0.6,
          fillColor: Color.Red,
          fillOpacity: 0.15,
          strokeWeight: 5,
          // clickable: false,
          editable: true,
          zIndex: 1,
        }}
      />
    );

    return circle;
  }

  createPolygon(path) {
    if (path.length < 2) {
      this.polygon = null;
      this.setState({ currentlyRenderedObject: null });
      return null;
    }
    this.geofenceType = GeofenceType.Polygon;
    return (
      <Polygon
        ref={polygon => {
          this.googleMapsRef = polygon;
        }}
        defaultOptions={{
          strokeColor: Color.Red,
          strokeOpacity: 0.6,
          fillColor: Color.Red,
          fillOpacity: 0.15,
          strokeWeight: 5,
          // clickable: false,
          editable: true,
          zIndex: 1,
        }}
        onRightClick={e => {
          if (e.vertex == null) {
            return;
          }
          const path = [
            ...this.googleMapsRef
              .getPath()
              .getArray()
              .slice(0, e.vertex),
            ...this.googleMapsRef
              .getPath()
              .getArray()
              .slice(e.vertex + 1),
          ];
          this.setState({
            currentlyRenderedObject: this.createPolygon(path),
          });
        }}
        paths={path}
        editable={true}
        draggable={true}
        onMouseUp={e => {}}
      />
    );
  }

  markerClicked(marker) {
    if (marker.device) {
      this.setState({ label: marker });
    } else {
      this.setState({ customerData: marker });
    }
  }

  render() {
    const t = this.props.intl.formatMessage;

    const addressMarkers =
      this.props.customerAddressList.addresses &&
      this.props.customerAddressList.show
        ? this.props.customerAddressList.addresses.map(address =>
            convertCustomerAddressToPosition(address),
          )
        : [];

    // noinspection EqualityComparisonWithCoercionJS
    return (
      <div>
        <div className="geofence-input-frame">
          <div className="geofence-input">
            <input
              className={this.state.showErrorMessage ? "input-field-error" : ""}
              type="text"
              placeholder={t(terms.name)}
              value={this.state.name}
              onChange={this.changeName}
            />
            {this.state.currentlyRenderedObject != null && (
              <div className="button" onClick={this.deleteGeofence}>
                <T
                  id="tracar.geofencingDetail.delete"
                  defaultMessage="Sicherheitszone löschen"
                />
              </div>
            )}
            {this.state.currentlyRenderedObject != null && (
              <div className="button save-button" onClick={this.saveObject}>
                <T
                  id="tracar.geofencingDetail.save"
                  defaultMessage="Sicherheitszone speichern"
                />
              </div>
            )}
          </div>
        </div>
        <GoogleMap
          defaultOptions={{ scaleControl: true }}
          ref={map => (this.map = map)}
          zoom={this.state.zoom || 10}
          bounds={this.state.bounds}
          defaultCenter={{
            lat: 48.864716,
            lng: 2.349014,
          }}
          onClick={() => {}}
          onZoomChanged={this.handleZoomChanged.bind(this)}
        >
          {this.state.device ? (
            <MarkerWithLabel
              key={-1}
              position={convertDeviceToPosition(this.state.device)}
              labelAnchor={new window.google.maps.Point(0, -16)}
              icon={{
                url: getPinUrl(this.state.device),
                anchor: new window.google.maps.Point(8, 8),
              }}
              labelClass="pin-label"
            >
              <div>{this.state.device.device.name}</div>
            </MarkerWithLabel>
          ) : (
            <React.Fragment />
          )}
          {this.state.currentlyRenderedObject}
          {this.props.fence && (
            <Circle
              radius={parseFloat(this.props.fence.data) * 100}
              center={{
                lat: parseFloat(this.props.fence.center_lat),
                lng: parseFloat(this.props.fence.center_lon),
              }}
              editable={true}
              draggable={true}
              ref={circle => {
                this.setState({ circle, polygon: null });
              }}
              onDragEnd={e => {}}
              onRadiusChanged={e => {}}
            />
          )}

          {this.state.currentlyRenderedObject == null && (
            /// own paint controls:
            /// https://stackoverflow.com/questions/23828284/how-i-add-my-own-image-or-drawing-option-on-google-map-drawing
            <DrawingManager
              onCircleComplete={c => {
                this.setState({
                  currentlyRenderedObject: this.createCircle(
                    { lat: c.center.lat(), lng: c.center.lng() },
                    c.radius,
                  ),
                });
                c.setMap(null);
              }}
              onPolygonComplete={p => {
                this.setState({
                  currentlyRenderedObject: this.createPolygon(
                    p.getPath().getArray(),
                  ),
                });
                p.setMap(null);
              }}
              defaultDrawingMode={google.maps.drawing.OverlayType.CIRCLE}
              defaultOptions={{
                gestureHandling: "cooperative",
                drawingControl: true,
                drawingControlOptions: {
                  position: google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [
                    google.maps.drawing.OverlayType.CIRCLE,
                    google.maps.drawing.OverlayType.POLYGON,
                    //google.maps.drawing.OverlayType.POLYLINE,
                    //google.maps.drawing.OverlayType.RECTANGLE,
                  ],
                },
                polygonOptions: {
                  strokeColor: Color.Red,
                  strokeOpacity: 0.8,
                  fillColor: Color.Red,
                  fillOpacity: 0.3,
                  strokeWeight: 5,
                  // clickable: false,
                  editable: true,
                  zIndex: 1,
                },
                circleOptions: {
                  strokeColor: Color.Red,
                  strokeOpacity: 0.8,
                  fillColor: Color.Red,
                  fillOpacity: 0.3,
                  strokeWeight: 5,
                  // clickable: false,
                  editable: true,
                  zIndex: 1,
                },
              }}
            />
          )}
          {/* -- CUSTOMER ADDRESS MARKERS ---------------------------------- */}
          {addressMarkers
            ? addressMarkers.map(marker => {
                return (
                  <MarkerWithLabel
                    key={"address_" + marker.customer_address_id}
                    position={marker}
                    labelAnchor={new window.google.maps.Point(0, -16)}
                    onClick={() => this.markerClicked(marker)}
                    icon={{
                      url: getPinUrl(marker),
                      anchor: new window.google.maps.Point(8, 8),
                    }}
                    labelClass="pin-label"
                  >
                    <div>{marker.customer_name}</div>
                  </MarkerWithLabel>
                );
              })
            : ""}
          {/* -- SELECTED MARKER WITH INFO OVERLAY ------------------------- */}
          {this.state.label && (
            <Marker
              key={-1}
              position={convertDeviceToPosition(this.state.label)}
              icon={{
                url: getPinUrl(this.state.label),
                anchor: new window.google.maps.Point(8, 8),
              }}
            >
              <InfoOverlay
                closeCallback={() => this.setState({ label: null })}
                trackerPosition={this.state.label}
                portal={this.props.match.params.portal}
                withSplit={!!this.props.splitRoute}
                toggleSplitConfirmation={this.toggleSplitConfirmation}
                fetchAddress={this.props.fetchAddress}
              />
            </Marker>
          )}
          {this.state.customerData && (
            <Marker
              key={-1}
              position={convertDeviceToPosition(this.state.customerData)}
              icon={{
                url: getPinUrl(this.state.customerData),
                anchor: new window.google.maps.Point(8, 8),
              }}
            >
              <InfoOverlay
                closeCallback={() => this.setState({ customerData: null })}
                trackerPosition={this.state.customerData}
                portal={this.props.match.params.portal}
                withSplit={!!this.props.splitRoute}
                toggleSplitConfirmation={this.toggleSplitConfirmation}
                fetchAddress={this.props.fetchAddress}
              />
            </Marker>
          )}
        </GoogleMap>
      </div>
    );
  }
}

export const Geofencing = withRouter(
  injectIntl(withGoogleMap(GeofencingClass)),
);
