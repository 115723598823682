import React from "react";
import { RfidChipAdd } from "./RfidChipAdd";
import { RfidChipList } from "./RfidChipList";
import { RfidChipUpload } from "./RfidChipUpload";
import { ConfirmDeleteOverlay } from "../ConfirmDeleteOverlay";
import { SettingsOverlay } from "./SettingsOverlay";
import { RfidChipAssignDevices } from "./RfidChipAssignDevices";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { Link } from "react-router-dom";
import { QueryLine } from "../tools/QueryLine";

const terms = defineMessages({
  confirmChipDeleteLabel: {
    id: "tracar.rfid-chip-list.confirmDelete.label",
    defaultMessage: "den Chip",
  },
});

export class RfidChipListBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rfidChipList: [],
      rfidChipLoadingState: "loading",
      rfidableLoadingState: "loading",
      showAddChipOverlay: false,
      showUploadChipOverlay: false,
      showAssignDevicesOverlay: false,
      showDeleteConfirmationOverlay: false,
      showSettingsOverlay: false,
      chipIdForAssigningDevices: 0,
      chipIdToDelete: 0,
      chipIdToUpdate: 0,
      rfidChipsToAssign: [],
      rfidChipToAssign: [],
      rfidableList: [],
      searchFilter: "",
    };
  }

  componentDidMount() {
    this.loadRfidChips();
    this.loadRfidable();
  }

  loadRfidChips = () => {
    this.props.fetchRfid
      .getChipList()
      .then(rfidChipList =>
        this.setState({ rfidChipList, rfidChipLoadingState: "ready" }),
      );
  };

  loadRfidable = () => {
    this.props.fetchRfid.listRfidableDevices().then(rfidableList =>
      this.setState({
        rfidableList: rfidableList,
        rfidableLoadingState: "ready",
      }),
    );
  };

  deleteChip = () => {
    this.props.fetchRfid
      .deleteChips([this.state.chipIdToDelete])
      .then(res => {
        this.loadRfidChips();
        this.toggleDeleteConfirmationOverlay();
      })
      .catch(err => {
        console.error(err);
        //this.setState({ saving: false, hasApiError: true });
      });
  };

  updateChip = (fname, lname) => {
    let chipToUpdate = this.state.rfidChipList.find(
      chip => chip.rfid_chip_id === this.state.chipIdToUpdate,
    );

    chipToUpdate.fname = fname;
    chipToUpdate.lname = lname;

    this.props.fetchRfid
      .updateChip(chipToUpdate)
      .then(res => {
        this.loadRfidChips();
        this.loadRfidable();
        this.toggleShowSettingsOverlay();
      })
      .catch(err => {
        console.error(err);
      });
  };

  toggleAddChipOverlay = () => {
    this.setState({ showAddChipOverlay: !this.state.showAddChipOverlay });
  };

  toggleUploadChipOverlay = () => {
    this.setState({ showUploadChipOverlay: !this.state.showUploadChipOverlay });
  };

  toggleShowAssignDevicesOverlay = () => {
    if (!this.state.showAssignDevicesOverlay) {
      this.setState({
        showAssignDevicesOverlay: !this.state.showAssignDevicesOverlay,
      });
    } else {
      this.setState({
        showAssignDevicesOverlay: !this.state.showAssignDevicesOverlay,
        rfidChipToAssign: [],
      });
      this.loadRfidable();
      this.loadRfidChips();
    }
  };

  toggleDeleteConfirmationOverlay = (id = null) => {
    if (id) this.setState({ chipIdToDelete: id });

    this.setState({
      showDeleteConfirmationOverlay: !this.state.showDeleteConfirmationOverlay,
    });
  };

  toggleShowSettingsOverlay = (id = null) => {
    if (id) this.setState({ chipIdToUpdate: id });

    this.setState({
      showSettingsOverlay: !this.state.showSettingsOverlay,
    });
  };

  updateRfidChipsToAssign = (rfidChip, status) => {
    this.updateArray(this.state.rfidChipsToAssign, rfidChip, status);
  };

  updateRfidChipToAssign = (rfidChip, status) => {
    this.toggleShowAssignDevicesOverlay();
    this.updateArray(this.state.rfidChipToAssign, rfidChip, status);
  };

  updateArray(arr, obj, status) {
    if (status) {
      arr.push(obj);
    } else {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].rfid_chip_id === obj.rfid_chip_id) {
          arr.splice(i, 1);
        }
      }
    }
    this.setState({});
  }

  setSearchFilter = searchFilter => {
    this.setState({ searchFilter });
  };

  render() {
    const portal = (this.props.match && this.props.match.params.portal) || "";
    return (
      <div className="outer-table-frame tracker">
        <div className="button-frame">
          <div className="block">
            <div className="button icon-rfid-chip active" />
            <Link
              className="button icon-rfid-chip-device"
              to={`/admin-panel/rfid-chip-list/rfidable-list`}
            />
            <div className="button" onClick={this.toggleAddChipOverlay}>
              <T
                id="tracar.rfid-chip-add.headline"
                defaultMessage="Neuen RFID-Chip erstellen"
              />
            </div>
            <div className="button" onClick={this.toggleUploadChipOverlay}>
              <T
                id="tracar.rfid-chip-upload.headline"
                defaultMessage="Liste mit RFID-Chips hochladen"
              />
            </div>
            <div
              className="button"
              onClick={
                this.state.rfidChipsToAssign.length > 0
                  ? this.toggleShowAssignDevicesOverlay
                  : () => {}
              }
              disabled={this.state.rfidChipsToAssign.length === 0}
            >
              Geräte zuweisen
            </div>
          </div>
          <div className="small-full-btn" />
        </div>
        <QueryLine changeCallback={this.setSearchFilter} />
        {this.state.showAddChipOverlay && (
          <RfidChipAdd
            closeFunction={this.toggleAddChipOverlay}
            createNewChip={this.props.fetchRfid.createNewChip}
            loadRfidChips={this.loadRfidChips}
          />
        )}

        {this.state.showUploadChipOverlay && (
          <RfidChipUpload
            closeFunction={this.toggleUploadChipOverlay}
            uploadChipList={this.props.fetchRfid.uploadChipList}
            loadRfidChips={this.loadRfidChips}
          />
        )}

        {this.state.showAssignDevicesOverlay && (
          <RfidChipAssignDevices
            closeFunction={this.toggleShowAssignDevicesOverlay}
            fetchRfid={this.props.fetchRfid}
            rfidChipList={
              this.state.rfidChipToAssign.length > 0
                ? this.state.rfidChipToAssign
                : this.state.rfidChipsToAssign
            }
            updateRfidChipsToAssign={this.updateRfidChipsToAssign}
            rfidableList={this.state.rfidableList}
            loadRfidChips={this.loadRfidChips}
          />
        )}

        {this.state.showDeleteConfirmationOverlay && (
          <ConfirmDeleteOverlay
            closeFunction={this.toggleDeleteConfirmationOverlay}
            confirmDelete={this.deleteChip}
            label={terms.confirmChipDeleteLabel.defaultMessage}
          />
        )}

        {this.state.showSettingsOverlay && (
          <SettingsOverlay
            closeFunction={this.toggleShowSettingsOverlay}
            updateChip={this.updateChip}
            chipToUpdate={this.state.rfidChipList.find(
              chip => chip.rfid_chip_id === this.state.chipIdToUpdate,
            )}
          />
        )}

        <RfidChipList
          rfidChipList={this.state.rfidChipList}
          rfidChipsToAssign={this.state.rfidChipsToAssign}
          rfidChipLoadingState={this.state.rfidChipLoadingState}
          rfidableLoadingState={this.state.rfidableLoadingState}
          toggleShowAssignDevicesOverlay={this.toggleShowAssignDevicesOverlay}
          toggleDeleteConfirmationOverlay={this.toggleDeleteConfirmationOverlay}
          toggleShowSettingsOverlay={this.toggleShowSettingsOverlay}
          updateRfidChipsToAssign={this.updateRfidChipsToAssign}
          updateRfidChipToAssign={this.updateRfidChipToAssign}
          searchFilter={this.state.searchFilter}
        />
      </div>
    );
  }
}
