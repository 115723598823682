import React from "react";
import { injectIntl, FormattedMessage as T } from "react-intl";
import {RequestOverlay} from "../../../../Request/RequestOverlay";
import CreateAndEditTaskTemplatesForm from "../Forms/CreateAndEditTaskTemplatesForm";
import AssignTaskTemplateForm from "../Forms/AssignTaskTemplateForm";
import {
    assignTemplateToItem,
    createTemplate,
    createTemplateTasks,
    deleteTemplateTask,
    fetchTaskTemplateTasks,
    updateTemplateName as sendUpdateTemplateName
} from "../../../../fetch/fetchSmartInventory";

const afterTemplateType = 'afterTemplate';
const beforeTemplateType = 'beforeTemplate';

class CreateEditTaskTemplateOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tasks: [],
            showEdit: true,
            showAssignTasks: false,
            data: {
                createEditForm: {
                    tasks: [],
                    deletedTasks: [],
                    name: this.props.template ? this.props.template.name : null,
                },
                assignItemsForm: {
                    beforeTemplate: [],
                    afterTemplate: []
                }
            }
        };
    }

    componentDidMount() {
        if (!this.props.template) {
            return;
        }

        fetchTaskTemplateTasks(this.props.fetchService.jsonFetch, this.props.template.id)
            .then(response => this.setState({
                tasks: response.tasks_template,
                data: {
                    ...this.state.data,
                    createEditForm: {
                        ...this.state.data.createEditForm,
                        tasks: response.tasks_template,
                    }
                }
            }));
    }

    render() {
        return (
            <div className="new-task-template-modal">
                <RequestOverlay
                    closeFunction={() => { this.props.closeFunction(false); }}
                    className="create-edit-task-template"
                    enableCloseOnOverlayClicked={true}
                >
                    {this.renderInterface()}
                </RequestOverlay>
            </div>
        );
    }

    renderInterface = () => {
        return (
            <div>
                {this.renderHeader()}
                {this.renderTabs()}
                {this.renderBody()}
                <div className="small-spacer" />
                {this.renderFooter()}
            </div>
        );
    };

    renderHeader() {
        if (this.props.template) {
            return (
                <div className="header">
                    <h3>
                        <T
                            id="adminPanel.smartInventoryTaskTemplates.editTemplate.headline"
                            defaultMessage="Aufgabenliste"
                        />
                        <span> -> </span>
                        <span className={`highlighted`}>{this.props.template.name}</span>
                    </h3>
                </div>
            );
        }

        return (
            <div className="header">
                <h3>
                    <T id="adminPanel.smartInventoryTaskTemplates.createTemplate.headline" defaultMessage="Neue Liste erstellen"/>
                </h3>
            </div>
        );
    };

    renderTabs() {
        return (
            <div className="tabs">
                <div className="tabs-inner-wrapper">
                    <div className={`tab-element ${this.state.showEdit ? 'active' : ''}`}
                        onClick={() => this.setState({showEdit: true, showAssignTasks: false})}
                    >
                        <span className={'fa fa-pencil'}></span>
                        <T id="adminPanel.smartInventory.createItem.labels.edit" defaultMessage="Editieren"/>
                    </div>

                    <div className={`tab-element ${this.state.showAssignTasks ? 'active' : ''}`}
                        onClick={() => this.setState({showEdit: false, showAssignTasks: true})}
                    >
                        <span className={'fa fa-check-circle'}></span>
                        <T id="adminPanel.smartInventory.createItem.labels.assignTemplates" defaultMessage="Zuweisen"/>
                    </div>
                </div>
            </div>
        )
    }

    renderBody() {
        return (
            <div className={`tab-content`}>
                { this.renderCreateAndEditForm() }
                { this.renderAssignForm() }
            </div>
        );
    };

    renderCreateAndEditForm() {
        if (!this.state.showEdit) {
            return;
        }


        return <CreateAndEditTaskTemplatesForm
            key={this.state.tasks.length}
            fetchService={this.props.fetchService}
            template={this.props.template}
            tasks={this.state.tasks}
            data={this.state.data.createEditForm}
            deleteTask={this.deleteTask}
            updateFormData={(formName, data) => this.updateFormData(formName, data)}
        />;
    }

    renderAssignForm() {
        if (!this.state.showAssignTasks) {
            return;
        }

        return <AssignTaskTemplateForm
            key={this.state.tasks.length}
            fetchService={this.props.fetchService}
            template={this.props.template}
            tasks={this.state.tasks}
            data={this.state.data.assignItemsForm}
            updateFormData={(formName, data) => this.updateFormData(formName, data)}
            deleteTask={this.deleteTask}
        />
    }

    renderFooter() {
        return (
            <div className="footer text-right">
                <div className="button transparent" onClick={() => this.props.closeFunction(false)}>
                    <T id="adminPanel.smartInventory.createInventoryItem.buttonLabel.cancel" defaultMessage="Abbrechen"/>
                </div>
                <div className="button green primary" onClick={() => this.submit()}>
                    <T id="adminPanel.smartInventory.createInventoryItem.buttonLabel.submit" defaultMessage="Speichern"/>
                </div>
            </div>
        );
    }

    updateFormData(formName, data) {
        this.setState({
            data: {
                ...this.state.data,
                [formName]: data,
            }
        });
    }

    submit() {
        this.saveOrUpdateTemplate().then((response) => {
            this.saveAssignedItems(response.id).then(this.props.closeFunction(true));
        });
    }

    saveOrUpdateTemplate() {
        if (this.props.template) {
            return this.updateTemplate();
        }

        return this.createNewTemplate();
    }

    updateTemplate() {
        return this.sendUpdateTemplateNameRequest().then(this.updateTasks);
    }

    sendUpdateTemplateNameRequest() {
        return sendUpdateTemplateName(
            this.props.fetchService.jsonFetch,
            this.props.template.id,
            {name: this.state.data.createEditForm.name}
        );
    }

    updateTasks = (updateTemplateNameResponse) => {
        if (this.state.data.createEditForm.tasks.length === 0 && this.state.data.createEditForm.deletedTasks.length === 0) {
            return;
        }

        const tasksToCreate = this.state.data.createEditForm.tasks.map(task => {
            // No editing allowed
            if (task.id) {
                return;
            }

            return {task_name: task.task_name};
        }).filter(task => task && task.task_name);

        if (tasksToCreate.length) {
            this.createTasks(this.props.template.id, tasksToCreate);
        }

        this.state.data.createEditForm.deletedTasks.forEach(this.deleteTask);

        return updateTemplateNameResponse;
    }

    createTasks(templateId, payload) {
        return createTemplateTasks(this.props.fetchService.jsonFetch, templateId, payload);
    }

    deleteTask = (task) => {
        if (!task.id) {
            return;
        }

        return deleteTemplateTask(this.props.fetchService.jsonFetch, task.id);
    }

    createNewTemplate() {
        return this.createTemplate().then((response) => {
            this.createTemplateTasks(response);

            return response;
        });
    }

    createTemplate() {
        return createTemplate(this.props.fetchService.jsonFetch, this.state.data.createEditForm.name);
    }

    createTemplateTasks = (createTemplateResponse) => {
        const payload = this.state.data.createEditForm.tasks.map(task => {
            return {task_name: task.task_name};
        })

        return createTemplateTasks(this.props.fetchService.jsonFetch, createTemplateResponse.id, payload);
    }

    saveAssignedItems(templateId) {
        console.log('assigning items to template', templateId);
        // We need to make two requests. One to store the before template
        // on the items and one to store the after template.
        return this.assignBeforeTemplate(templateId)
            .then(() => this.assignAfterTemplate(templateId));

    }

    assignBeforeTemplate(templateId) {
        if (this.state.data.assignItemsForm.beforeTemplate.length === 0) {
            return new Promise((resolve) => resolve());
        }

        const data = this.compilePostData(beforeTemplateType, templateId);
        return assignTemplateToItem(this.props.fetchService.jsonFetch, data);
    }

    assignAfterTemplate(templateId) {
        if (this.state.data.assignItemsForm.afterTemplate.length === 0) {
            return new Promise((resolve) => resolve());
        }

        const data = this.compilePostData(afterTemplateType, templateId);
        return assignTemplateToItem(this.props.fetchService.jsonFetch, data);
    }

    compilePostData(type, templateId) {
        const data = {
            item_uuid: this.state.data.assignItemsForm[type].map(item => item.item_uuid)
        };

        if (type === beforeTemplateType) {
            data.template_before = templateId;
        }

        if (type === afterTemplateType) {
            data.template_after = templateId;
        }

        return data;
    }
}

export default injectIntl(CreateEditTaskTemplateOverlay);
