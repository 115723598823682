// https://tomchentw.github.io/react-google-maps/#usage--configuration
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  TrafficLayer,
} from "react-google-maps";
import React from "react";
import { InfoOverlay } from "./InfoOverlay";
import { LoadingCircle } from "../LoadingCircle";
import { ConfirmSplitOverlay } from "./ConfirmSplitOverlay";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import {
  convertDeviceToPosition,
  convertCustomerAddressToPosition,
  getPinUrl,
} from "../Map/functions";

function accessParam(match, paramName) {
  return match && match.params[paramName];
}

function getTimecode(time) {
  let timeCode = null;
  switch (time) {
    case "6":
      timeCode = 0;
      break;
    case "12":
      timeCode = 1;
      break;
    case "24":
      timeCode = 2;
      break;
    case "whole_day":
    case "period":
      timeCode = 3;
      break;
    default:
      throw new TypeError("Unknown case for possibleTimeShown: " + time);
  }
  return timeCode;
}

const reloadInterval = parseInt(process.env.REACT_APP_LIVE_REFRESH, 10) * 1000;

class GoogleMapWrapperClass extends React.Component {
  promiseNumber;
  componentUnmounted = false;
  constructor(props) {
    super(props);
    this.promiseNumber = +new Date();

    this.state = {
      zoom: null,
      devices: [],
      doFitToBounds: false,
      initialLoad: true,
      // the default "portal" does not load data, it just shows the map
      // preLoad means the data is loaded already before rendering
      loadPending: !this.props.isEmpty && !this.props.preLoad,
      showSplitConfirmation: false,
    };

    this.markerClicked = this.markerClicked.bind(this);
  }

  componentDidMount() {
    if (this.props.preLoad) {
      // integrate into existing minimap functionality
      this.setState({ devices: this.props.devices }, this.fitToBounds);
    } else if (this.props.fahrtenbuchId) {
      // integrate into existing route splitting functionality
      this.getRouteInfo(this.props.fahrtenbuchId);
    } else {
      this.selectDataToLoad();
      this.checkForLive();
      this.fitToBounds();
    }
    this.setState({ loadPending: false });
  }

  componentWillUnmount() {
    this.componentUnmounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.preLoad &&
      (prevProps.timeShown !== this.props.timeShown ||
        prevProps.date !== this.props.date)
    ) {
      this.selectDataToLoad();
    }

    if (this.state.doFitToBounds) {
      this.fitToBounds();
      this.setState({ doFitToBounds: false });
    }

    if (
      this.props.customerAddressList &&
      this.props.customerAddressList.fitToBounds
    ) {
      this.fitToBounds();
    }
  }

  checkForLive = (automated = false) => {
    if (this.componentUnmounted) {
      return;
    }

    this.props.timeShown === "live" && this.selectDataToLoad(automated);
    !this.componentUnmounted &&
      setTimeout(() => this.checkForLive(true), reloadInterval);
  };

  getRouteInfo = fahrtenbuchId => {
    this.props.getRouteData(fahrtenbuchId).then(response => {
      this.setState(
        {
          devices: response,
        },
        this.fitToBounds,
      );
    });
  };

  /**
   * Toggle split confirmation
   * @return void
   */
  toggleSplitConfirmation = () => {
    this.setState({
      showSplitConfirmation: !this.state.showSplitConfirmation,
    });
  };

  /**
   * Split the route at the selected tracklog
   * @return void
   */
  splitRoute = () => {
    this.props.splitRoute(
      this.state.label.device.imei,
      this.state.label.tracklog_id,
    );
  };

  fitToBounds = () => {
    const devices =
      this.props.customerAddressList &&
      this.props.customerAddressList.show &&
      this.props.customerAddressList.addresses
        ? this.props.customerAddressList.addresses
        : this.state.devices;

    /// Fit the map for several markers
    const bounds = new window.google.maps.LatLngBounds();
    devices.map(device => bounds.extend(convertDeviceToPosition(device)));
    if (devices.length > 1) {
      this.map.fitBounds(bounds);
    } else if (devices.length === 1) {
      this.map.fitBounds(bounds);
      /// currently preload is only Minimap thus less zoom
      const zoom = this.props.preLoad ? 13 : 15;
      this.setState({ zoom });
    }

    this.props.customerAddressList &&
      this.props.customerAddressList.fitToBounds &&
      this.props.updateFitToBounds(false);
    this.setState({
      label: null,
    });
  };

  markerClicked(marker) {
    if (marker.device) {
      this.setState({ label: marker });
    } else {
      this.setState({ customerData: marker });
    }
  }

  handleZoomChanged() {
    const zoom = this.map.getZoom();
    if (zoom !== this.state.zoom) {
      this.setState({ zoom });
    }
  }

  handleBoundsChanged() {
    if (this.props.mapType === "miniMap" && this.state.initialLoad === true) {
      this.setState({ zoom: 13, initialLoad: false });
    }
  }

  selectDataToLoad(automated = false) {
    if (this.componentUnmounted || this.props.isEmpty) {
      return;
    }

    this.props.timeShown === "live"
      ? this.loadList(automated)
      : this.loadHistory(this.props.timeShown);
  }

  loadList = (automated = false) => {
    this.promiseNumber += 1;
    const currentPromiseNumber = this.promiseNumber;
    this.props
      .getPoolVehiclesForMap()
      .then(res => {
        if (currentPromiseNumber !== this.promiseNumber) {
          return;
        }
        if (automated) {
          this.setState({ devices: res });
        } else {
          this.setState({ devices: res }, this.fitToBounds);
        }
      })
      .catch(function(err) {});
  };

  loadHistory(time) {
    this.promiseNumber += 1;
    const currentPromiseNumber = this.promiseNumber;
    const timeCode = getTimecode(time);
    this.props
      .getPoolVehiclesMapHistory(timeCode, this.props.date)
      .then(res => {
        if (currentPromiseNumber !== this.promiseNumber) {
          return;
        }
        this.setState({ devices: res }, this.fitToBounds);
      });
  }

  render() {
    // This may not be the perfect way to render no markers while loading
    // yet setting the devices to [] caused a setState on unmounted component
    // bug that would be very difficult to track
    const markers = this.state.loadPending
      ? []
      : this.state.devices.map(device => convertDeviceToPosition(device));
    let addressMarkers = [];
    if (this.props.mapType === "map") {
      addressMarkers =
        this.props.customerAddressList.addresses &&
        this.props.customerAddressList.show
          ? this.props.customerAddressList.addresses.map(address =>
              convertCustomerAddressToPosition(address),
            )
          : [];
    }

    /**
     * for more info on
     * labels:
     * https://developers.google.com/maps/documentation/javascript/examples/marker-labels
     * symbols:
     * https://developers.google.com/maps/documentation/javascript/symbols
     * markers and different designs thereof
     * https://developers.google.com/maps/documentation/javascript/markers
     *
     */

    const mapStyles = [];

    return (
      <div>
        {this.state.loadPending && <LoadingCircle className="map-loader" />}
        {this.props.mapType === "map" &&
          this.props.customerAddressList.isLoading && (
            <LoadingCircle className="map-loader" />
          )}
        <GoogleMap
          bounds={{
            east: 13.916343,
            north: 53.930902,
            south: 48.166079,
            west: 6.012385,
          }}
          ref={map => (this.map = map)}
          defaultZoom={19}
          zoom={this.state.zoom || 6}
          defaultOptions={{
            styles: mapStyles,
            scaleControl: true,
            disableDefaultUI: this.props.isEmpty,
            zoomControl: true,
          }}
          onZoomChanged={this.handleZoomChanged.bind(this)}
          onBoundsChanged={this.handleBoundsChanged.bind(this)}
          defaultCenter={{ lat: 51.225079, lng: 10.509258 }}
          onClick={() => {}}
        >
          {/* -- ALL MARKERS ----------------------------------------------- */}
          {this.props.timeShown === "live"
            ? markers.map((marker, index) => {
                return (
                  <MarkerWithLabel
                    key={index}
                    position={marker}
                    labelAnchor={new window.google.maps.Point(0, -16)}
                    onClick={() => this.markerClicked(marker.device)}
                    icon={{
                      url: getPinUrl(marker),
                      anchor: new window.google.maps.Point(8, 8),
                    }}
                    labelClass="pin-label"
                  >
                    <div>{marker.device.device.name}</div>
                  </MarkerWithLabel>
                );
              })
            : markers.map((marker, index) => {
                return (
                  <Marker
                    key={index}
                    position={marker}
                    onClick={() => this.markerClicked(marker.device)}
                    icon={{
                      url: getPinUrl(marker),
                      anchor: new window.google.maps.Point(8, 8),
                    }}
                  />
                );
              })}
          {/* -- CUSTOMER ADDRESS MARKERS ---------------------------------- */}
          {addressMarkers
            ? addressMarkers.map(marker => {
                return (
                  <MarkerWithLabel
                    key={"address_" + marker.customer_address_id}
                    position={marker}
                    labelAnchor={new window.google.maps.Point(0, -16)}
                    onClick={() => this.markerClicked(marker)}
                    icon={{
                      url: getPinUrl(marker),
                      anchor: new window.google.maps.Point(8, 8),
                    }}
                    labelClass="pin-label"
                  >
                    <div>{marker.customer_name}</div>
                  </MarkerWithLabel>
                );
              })
            : ""}
          {/* -- SELECTED MARKER WITH INFO OVERLAY ------------------------- */}
          {this.state.label && (
            <Marker
              key={-1}
              position={convertDeviceToPosition(this.state.label)}
              icon={{
                url: getPinUrl(this.state.label),
                anchor: new window.google.maps.Point(8, 8),
              }}
            >
              <InfoOverlay
                closeCallback={() => this.setState({ label: null })}
                trackerPosition={this.state.label}
                portal={this.state.label.device.device_category.description}
                withSplit={!!this.props.splitRoute}
                toggleSplitConfirmation={this.toggleSplitConfirmation}
                fetchAddress={this.props.fetchAddress}
              />
            </Marker>
          )}
          {this.state.customerData && (
            <Marker
              key={-1}
              position={convertDeviceToPosition(this.state.customerData)}
              icon={{
                url: getPinUrl(this.state.customerData),
                anchor: new window.google.maps.Point(8, 8),
              }}
            >
              <InfoOverlay
                closeCallback={() => this.setState({ customerData: null })}
                trackerPosition={this.state.customerData}
                portal={this.props.portal}
                withSplit={!!this.props.splitRoute}
                toggleSplitConfirmation={this.toggleSplitConfirmation}
                fetchAddress={this.props.fetchAddress}
              />
            </Marker>
          )}

          {/* -------------------------------------------------------------- */}

          {false && <TrafficLayer autoUpdate />}

          {this.renderSplitConfirmation()}
        </GoogleMap>
      </div>
    );
  }

  renderSplitConfirmation = () => {
    if (!this.state.showSplitConfirmation) {
      return null;
    }

    return (
      <ConfirmSplitOverlay
        closeFunction={this.toggleSplitConfirmation}
        confirmSplit={this.splitRoute}
      />
    );
  };
}

export const GoogleMapWrapper = withGoogleMap(GoogleMapWrapperClass);
