/// see for plotly control elements:
/// https://community.plot.ly/t/remove-options-from-the-hover-toolbar/130/11

import React from "react";

import { LoadingCircle } from "../LoadingCircle";
import { Portal } from "../Portal";
import { AdminPanelUserBase } from "./User/UserBase";
import { AdminPanelRoleBase } from "./Role/RoleBase";
import { AdminPanelDeviceBase } from "./Device/DeviceBase";
import { CustomerAddressesBase } from "../CustomerAddresses/CustomerAddressesBase";
import { RfidChipListBase } from "../RfidChipList/RfidChipListBase";
import { UserConsumer } from "../Base";
import { ServiceIntervalBase } from "./ServiceInterval/ServiceIntervalBase";
import { SmartInventory } from "./SmartInventory/List/SmartInventoryListBase";
import { AdminPanelPoolVehicleBase } from "./PoolVehicle/PoolVehicleBase";
import { AdminPanelExpenseBase } from "./Expense/ExpenseBase";

/**
 * Base component for the admin panel.
 * Includes portal list views (e.g. "Role Management") depending on route parameter "portal".
 * Props:
 * - match object from Router (used to determine current portal)
 * - fetchList contains fetchServices for all admin panel lists
 * - fetchTags contains fetchServices for device tags
 */
export class AdminPanelListBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: "loaded",
      dashboardData: null,
    };
  }

  render() {
    let list;
    const { portal } = this.props.match.params;
    switch (portal) {
      case Portal.AdminPanelUser:
        list = (
          <AdminPanelUserBase
            getUserList={this.props.fetchList.getUserList}
            getUserListAll={this.props.fetchList.getUserListAll}
            getUserListFiltered={this.props.fetchList.getUserListFiltered}
            getRoleListAll={this.props.fetchList.getRoleListAll}
            createUser={this.props.fetchList.inviteUser}
            uploadUsers={this.props.fetchList.uploadUsers}
            generateEmailAddress={this.props.fetchList.generateEmailAddress}
            editUser={this.props.fetchList.updateUser}
            deleteUser={this.props.fetchList.deleteUser}
            assignDeviceToUser={this.props.fetchList.getUserList}
            assignUsersToRole={this.props.fetchList.assignUsersToRole}
            assignUserGroupToUser={this.props.fetchList.getUserList}
            createRole={this.props.fetchList.createRole}
            getPermissionList={this.props.fetchList.getPermissionList}
            portal={portal}
          />
        );
        break;
      case Portal.AdminPanelRole:
        list = (
          <AdminPanelRoleBase
            getRoleListFiltered={this.props.fetchList.getRoleListFiltered}
            getPermissionList={this.props.fetchList.getPermissionList}
            getUserGroupList={this.props.fetchList.getUserGroupList}
            getUserList={this.props.fetchList.getUserList}
            getUserListByRole={this.props.fetchList.getUserListByRole}
            createRole={this.props.fetchList.createRole}
            editRole={this.props.fetchList.editRole}
            deleteRole={this.props.fetchList.deleteRole}
            assignUsersToRole={this.props.fetchList.assignUsersToRole}
            portal={portal}
          />
        );
        break;
      case Portal.AdminPanelDevice:
        list = (
          <AdminPanelDeviceBase
            getRoleList={this.props.fetchList.getRoleListAll}
            getCategoryListForDevice={
              this.props.fetchList.getCategoryListForDevice
            }
            getDeviceListFiltered={this.props.fetchList.getDeviceListFiltered}
            getDeviceList={this.props.fetchList.getDeviceList}
            getUserList={this.props.fetchList.getUserList}
            getUserListFiltered={this.props.fetchList.getUserListFiltered}
            fetchTags={this.props.fetchTags}
            assignUsersToDevice={this.props.fetchList.assignUsersToDevice}
            getAssignedUsersForDevice={
              this.props.fetchList.getAssignedUsersForDevice
            }
            portal={portal}
            assignCategoryToDevice={this.props.fetchList.assignCategoryToDevice}
          />
        );
        break;
      case Portal.AdminPanelRfidChipList:
        list = (
          <UserConsumer>
            {user => (
              <RfidChipListBase
                key={portal}
                match={this.props.match}
                fetchRfid={this.props.fetchService.fetchRfid}
                user={user}
              />
            )}
          </UserConsumer>
        );
        break;
      case Portal.AdminPanelCustomerAddress:
        list = (
          <CustomerAddressesBase
            getPaginatedCustomerAddresses={
              this.props.fetchService.getPaginatedCustomerAddresses
            }
            deleteAddress={this.props.fetchService.deleteCustomerAddress}
            uploadCustomerAddresses={
              this.props.fetchService.uploadCustomerAddresses
            }
            portal={portal}
          />
        );
        break;
      case Portal.AdminPanelServiceInterval:
        list = (
          <ServiceIntervalBase
            getPaginatedCustomerAddresses={
              this.props.fetchService.getPaginatedCustomerAddresses
            }
            deleteServiceInterval={
              this.props.fetchService.deleteServiceInterval
            }
            uploadCustomerAddresses={
              this.props.fetchService.uploadCustomerAddresses
            }
            getServiceIntervals={this.props.fetchService.getServiceIntervals}
            fetchDevices={this.props.fetchList.getDeviceList}
            createServiceInterval={
              this.props.fetchService.createServiceInterval
            }
            createMultipleServiceIntervals={
              this.props.fetchService.createMultipleServiceIntervals
            }
            setIntervalCompletion={
              this.props.fetchService.setIntervalCompletion
            }
            getEnumTypes={this.props.fetchService.getEnumTypes}
            portal={Portal.AdminPanel}
          />
        );
        break;
      case Portal.AdminPanelPoolVehicle:
        list = (
          <AdminPanelPoolVehicleBase
            getRoleList={this.props.fetchList.getRoleListAll}
            getDeviceListFiltered={this.props.fetchList.getDeviceListFiltered}
            getDeviceList={this.props.fetchList.getDeviceList}
            getPoolVehicleList={this.props.fetchList.getPoolVehicleList}
            getUserList={this.props.fetchList.getUserList}
            getUserListFiltered={this.props.fetchList.getUserListFiltered}
            getDriverOptionsForDevice={
              this.props.fetchList.getDriverOptionsForDevice
            }
            checkUserInToDevice={this.props.fetchList.checkUserInToDevice}
            checkUserOutOfDevice={this.props.fetchList.checkUserOutOfDevice}
            fetchTags={this.props.fetchTags}
            assignUsersToDevice={this.props.fetchList.assignUsersToDevice}
            getAssignedUsersForDevice={
              this.props.fetchList.getAssignedUsersForDevice
            }
            portal={portal}
            messageListQuery={this.props.fetchService.messageListQuery}
          />
        );
        break;
      case Portal.AdminPanelExpense:
        list = (
          <AdminPanelExpenseBase
            getExpenseList={this.props.fetchListExpenses.getExpenseList}
            getExpenseCategoryList={
              this.props.fetchListExpenses.getExpenseCategoryList
            }
            getExpenseListForDevice={
              this.props.fetchListExpenses.getExpenseListForDevice
            }
            editAdminExpense={this.props.fetchListExpenses.editAdminExpense}
            createExpense={this.props.fetchListExpenses.createExpense}
            getExpenseImage={this.props.fetchListExpenses.getExpenseImage}
            deleteExpense={this.props.fetchListExpenses.deleteExpense}
            acknowledgeExpense={this.props.fetchListExpenses.acknowledgeExpense}
            portal={portal}
          />
        );
        break;
      case Portal.AdminPanelSmartInventory:
        list = <SmartInventory fetchService={this.props.fetchService} />;
        break;
      default:
    }

    const { loadingState } = this.state;
    if (loadingState === "loading") {
      return (
        <div className="outer-table-frame tracker">
          <div className="table-frame">
            <div className="spacer" />
            <LoadingCircle />
            <div className="spacer" />
          </div>
        </div>
      );
    } else if (loadingState === "loaded") {
      return list;
    }
  }
}
