import React from "react";

export function TextInput({
  className = "",
  id,
  name,
  value,
  onChange,
  onEnter,
    onClick,
    onBlur,
  disabled = false,
  type = "text",
  onFocusValue = null,
  onBlurValue = null,
    hasError = false,
}) {

  if (value === null) {
    value = "";
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };

  let optionalProps = {};
  onEnter && (optionalProps["onKeyPress"] = handleKeyPress);
  disabled && (optionalProps["disabled"] = disabled);

  onFocusValue !== null &&
    (optionalProps["onFocus"] = e => {
      e.target.value = onFocusValue;
    });

  onBlurValue !== null &&
    (optionalProps["onBlur"] = e => {
      e.target.value = onBlurValue;
    });

  return (
    <div className={`text-input-component ${className} ${hasError ? 'error' : ''}` }>
      <input
        {...optionalProps}
        id={id}
        disabled={disabled}
        type={type}
        placeholder={name}
        value={value}
        onClick={onClick}
        onBlur={onBlur}
        onChange={disabled ? () => {} : onChange}
      />
      <label htmlFor={id}>{name}</label>
    </div>
  );
}
