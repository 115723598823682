import React from "react";
import Link from "react-router-dom/es/Link";
import { withRouter } from "react-router-dom";
import { getWeekdayList } from "./GeofenceTab";
import { deviceSafetyZoneIsWholeDay } from "../DeviceSafetyZone/deviceSafetyZoneIsWholeDay";
import { FormattedMessage as T, injectIntl } from "react-intl";

class GeofenceTableLineClass extends React.Component {
  deleteLine;
  cancelDelete;
  weekdayList;

  constructor(props) {
    super(props);

    this.weekdayList = getWeekdayList(props.intl.formatMessage);
    this.state = { pendingDelete: false };
    this.deleteLine = this.deleteLine.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }

  deleteLine(e) {
    this.state.pendingDelete
      ? this.props.deleteDeviceSafetyZone(
          this.props.deviceSafetyZone.geofence_device_id,
        )
      : this.setState({ pendingDelete: true });
  }

  cancelDelete() {
    this.setState({ pendingDelete: false });
  }

  render() {
    return (
      <div
        className={`geofence-table-line-container ${
          this.props.isSelected ? "selected" : ""
        }`}
        onClick={() =>
          !this.props.readOnly && this.props.selectGeofence(this.props.geofence)
        }
      >
        <div className="geofence-table-line-label">
          {this.props.geofence.geofence_name}
        </div>
        <div className="geofence-table-line">
          <div className="geofence-table-line-left">
            {this.weekdayList.map((weekday, index) => (
              <div
                key={index}
                className={`weekday-display ${
                  this.props.geofence.day_frequency[index] === "1"
                    ? "active"
                    : "inactive"
                }`}
              >
                {weekday}
              </div>
            ))}
          </div>
          <div className="geofence-table-line-middle">
            {!deviceSafetyZoneIsWholeDay(this.props.geofence) ? (
              <T
                id="tracar.device-safety-zone.fromTo"
                defaultMessage="{start} - {end}"
                values={{
                  start: this.props.geofence.start_time.slice(0, 8),
                  end: this.props.geofence.end_time.slice(0, 8),
                }}
              />
            ) : (
              <div>
                <T
                  id="tracar.device-safety-zone-detail.wholeDay"
                  defaultMessage="ganztägig"
                />
              </div>
            )}
          </div>
          <div className="geofence-table-line-right">
            {this.props.geofence.leave_alert ? (
              <div className="ib geofence-alarm-type">
                <i className="fa fa-sign-out" />
                <T
                  id="tracar.device-safety-zone.alarmOut"
                  defaultMessage="Ausfahrt"
                />
              </div>
            ) : (
              <div className="ib geofence-alarm-type">
                <i className="fa fa-sign-in" />
                <T
                  id="tracar.device-safety-zone.alarmIn"
                  defaultMessage="Einfahrt"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export let GeofenceTableLine = withRouter(injectIntl(GeofenceTableLineClass));
