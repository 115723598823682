import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import {
  FormattedMessage as T,
  injectIntl,
  defineMessages,
  FormattedMessage,
} from "react-intl";
import { DeviceList } from "./DeviceList";
import { noTagsPlaceholder }           from "../../TrackerList/TrackerListBase";
import { removeArrayElement }          from "../../utility";
import { DeviceCreateOverlay }         from "./DeviceCreateOverlay";
import { DeviceAssignUserOverlay }     from "./DeviceAssignUserOverlay";
import { QueryLine }                   from "../../tools/QueryLine";
import { TagBar }                      from "../../Tags/TagBar";
import { hasPortalAccess }             from "../../Permission";
import { UserConsumer }                from "../../Base";
import { Portal }                      from "../../Portal";
import { DeviceFilterType }            from "../../ApiContract";
import { DeviceAssignCategoryOverlay } from "./DeviceAssignCategoryOverlay";
import { TrackerTable }                from "../../TrackerList/TrackerTable";
import { SettingsBase }                from "../../Settings/SettingsBase";
import { Route }                       from "react-router";

const deviceListLimit = 10;

const terms = defineMessages({
  selectCategoryDefault: {
    id: "adminPanel.deviceBase.label.selectCategoryDefault",
    defaultMessage: "Kategorie wählen",
  },
});

class AdminPanelDeviceBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      recordList: [],
      deviceCategoryList: [],
      selectedTags: [],
      showImportOverlay: false,
      showDeleteConfirmationOverlay: false,
      tagListLoadingState: "loading",
      showCreateOverlay: false,
      query: "",
      tagList: { tag_id_list: [], tag_objects: {} },
      userList: [],
      // total number of devices across all pagination pages
      numberOfDevices: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // used by pagination to fetch the devices displayed on the current pagination page
      deviceListOffset: 0,
      // search term to filter role list
      deviceListSearchKey: "",
      // filter devices by assignment type
      deviceListFilterType: DeviceFilterType.All,
      // filter devices by device category
      deviceListCategoryFilter: "all",
      //list for single imei in case user want to edit one device direct (without bulkload)
      selectedDeviceImei: [],
      // list with one or more imei's for bulkload
      selectedDevicesImeiList: [],
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load(paginationInfo = {}) {
    // TODO: fetch paginated records @see CustomerAddresses
    this.props
      .getDeviceListFiltered(
        this.state.deviceListSearchKey,
        this.state.deviceListFilterType,
        this.state.deviceListCategoryFilter,
        this.state.deviceListOffset,
        deviceListLimit,
        this.state.selectedTags.map((t) => t.id).filter((i) => i !== noTagsPlaceholder.id)
      )
      .then(recordListData =>
        this.setState({
          loading: false,
          recordList: recordListData.devices,
          deviceCategoryList: recordListData.device_categories,
          numberOfDevices: recordListData.total_amount,
          numberOfPages: Math.ceil(
            recordListData.total_amount / deviceListLimit,
          ),
        }),
      );

    this.props.getUserList().then(userList =>
      this.setState({
        loading: false,
        userList: userList.user_data,
      }),
    );

    this.loadTags();
  }

  loadTags = () => {
    this.setState({ tagListLoadingState: "loading" });
    this.props.fetchTags.getTagList().then(tagList => {
      this.setState((old) => {return {
        tagList,
        tagListLoadingState: "ready",
        selectedTags: old.selectedTags.length === 0 ? [
          noTagsPlaceholder,
        ] : old.selectedTags,
        selectedAllTags: old.selectedAllTags === undefined ? true : old.selectedAllTags,
        initialTagLoad: false,
      };});
    });
  };

  toggleSelectAllTags = () => {
    if (this.state.selectedAllTags) {
      this.setState({
        selectedTags: this.state.lastSelectedTags,
        selectedAllTags: false,
        isFiltered: false,
        ...this.updatePaginationObj(0)
      }, this.load);
      return;
    }

    this.setState({
      selectedTags: [
        noTagsPlaceholder,
      ],
      lastSelectedTags: this.state.selectedTags,
      selectedAllTags: true,
      initialTagLoad: false,
      ...this.updatePaginationObj(0)
    }, this.load);
  };

  toggleSelectedTags = tagObject => {
    if (this.state.selectedAllTags) {
      this.setState({
        selectedTags: [tagObject],
        selectedAllTags: false,
        ...this.updatePaginationObj(0)
      }, this.load);
      return;
    }

    const index = this.state.selectedTags.findIndex(
      tag => tag.id === tagObject.id,
    );
    if (index === -1 && tagObject) {
      this.setState({
        selectedTags: [...this.state.selectedTags, tagObject],
        ...this.updatePaginationObj(0)
      }, this.load);
    } else {
      this.setState({
        selectedTags: removeArrayElement(this.state.selectedTags, index),
        ...this.updatePaginationObj(0)
      }, this.load);
    }
  };

  setTagsAsSaving = (asSaving = true) => {
    asSaving
      ? this.setState({ tagListLoadingState: "saving" })
      : this.setState({ tagListLoadingState: "ready" });
  };

  updateSelectedDeviceImei = imei => {
    imei === null
       ? this.setState({ selectedDeviceImei: [] })
       : this.setState({ selectedDeviceImei: [imei] });
  };

  setSearchFilter = deviceListSearchKey => {
    this.setState({ deviceListSearchKey, ...this.updatePaginationObj(0) }, this.load);
  };

  updateCurrentPage = number => {
    this.setState({ currentPage: number });
  };

  getDeviceTypeList = async () => {
    return ["car", "utility", "asset", "person"];
  };

  setDeviceFilter = event => {
    this.setState({ deviceListFilterType: event.target.value,...this.updatePaginationObj(0) }, this.load);
  };

  setCategoryFilter = event => {
    this.setState({ deviceListCategoryFilter: event.target.value, ...this.updatePaginationObj(0) }, this.load);
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <UserConsumer>
        {user =>
          hasPortalAccess(user, Portal.AdminPanelDevice) && (
            <div className="outer-table-frame">
              <div className="margin-bottom-1">
                {this.state.tagList && this.renderTagBar()}
              </div>
              <div className="grid-x grid-margin-x align-middle search-area">
                <div className="auto cell">
                  <QueryLine
                    changeCallback={this.setSearchFilter}
                    changeDelay={500}
                  />
                </div>
                <div className="cell medium-3">
                  <select
                    className="margin-bottom-1"
                    onChange={this.setCategoryFilter}
                  >
                    <option value="all">
                      {t(terms.selectCategoryDefault)}
                    </option>
                    {this.state.deviceCategoryList.map(categoryData => (
                      <option value={categoryData.name} key={categoryData.name}>
                        <FormattedMessage
                          id={`tracar.portal.${categoryData.name}`}
                          defaultMessage={`${categoryData.name}`}
                        >
                          {message => `${message} (${categoryData.count})`}
                        </FormattedMessage>
                      </option>
                    ))}
                  </select>
                </div>
                <div className="medium-3 cell">
                  <select
                    className="margin-bottom-1"
                    onChange={this.setDeviceFilter}
                  >
                    <option value={DeviceFilterType.All}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.all"
                        defaultMessage="Alle"
                      />
                    </option>
                    <option value={DeviceFilterType.Unassigned}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.unassigned"
                        defaultMessage="Nicht zugewiesen"
                      />
                    </option>
                    <option value={DeviceFilterType.Assigned}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.assigned"
                        defaultMessage="Zugewiesen"
                      />
                    </option>
                    <option value={DeviceFilterType.AssignedFahrtenbuch}>
                      <T
                        id="adminPanel.devices.list.deviceFilterType.optionLabel.assignedFahrtenbuch"
                        defaultMessage="Fahrtenbuch zugewiesen"
                      />
                    </option>
                  </select>
                </div>
              </div>
              {this.renderDeviceList()}
              {this.state.showDeviceAssignUserOverlay &&
                this.renderDeviceAssignUserOverlay(user)}
              {this.state.showDeviceAssignCategoryOverlay &&
                this.renderDeviceAssignCategoryOverlay(user)}
              {this.state.showCreateOverlay && this.renderDeviceCreateOverlay()}
              {/*<Route
                 path="/admin-panel/device/settings"
                 render={({ match }) => (
                    <SettingsBase
                       match={match}
                       fetchService={this.props.fetchService}
                       selectedDevicesImeiList={
                         this.state.selectedDeviceImei.length > 0
                            ? this.state.selectedDeviceImei
                            : selectedDevicesImeiList
                       }
                       selectedSettingsTab={selectedSettingsTab}
                       devices={this.state.devices}
                       user={this.props.user}
                       updateSelectedDevicesImeiList={
                         this.updateSelectedDevicesImeiList
                       }
                       fetchDeviceSettings={this.props.fetchDeviceSettings}
                       clearSingleImei={this.clearSingleImei}
                       loadTags={this.loadTags}
                       loadGeofencingList={this.loadGeofencingList}
                       carsRequest={this.props.carsRequest}
                       addNewCar={this.props.addNewCar}
                       generateWiresCsv={this.props.generateWiresCsv}
                       getGeofenceList={this.props.getGeofenceList}
                       getDeviceSafetyZones={this.props.getDeviceSafetyZones}
                       deviceSafetyZoneRequest={this.props.deviceSafetyZoneRequest}
                       deleteDeviceSafetyZone={this.props.deleteDeviceSafetyZone}
                       readOnly={this.checkIfReadOnlySettings()}
                    />
                 )}
              />*/}
            </div>
          )
        }
      </UserConsumer>
    );
  }

  /**
   * Returns the tag bar
   * @return JSX
   */
  renderTagBar = () => {
    return (
      this.state.tagList && (
        <TagBar
          fetchTags={this.props.fetchTags}
          loadTags={this.loadTags}
          setTagsAsSaving={this.setTagsAsSaving}
          selectedTags={this.state.selectedTags}
          selectedAllTags={this.state.selectedAllTags}
          tagList={this.state.tagList}
          toggleSelectAll={this.toggleSelectAllTags}
          toggleSelectedTags={this.toggleSelectedTags}
          tagListLoadingState={this.state.tagListLoadingState}
          setTagsAsSaving={this.setTagsAsSaving}
        />
      )
    );
  };

  /**
   * Returns the device-list-table
   * @return JSX
   */
  renderDeviceList = () => {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }

    return (
      <div>
        <DeviceList
          fetchTags={this.props.fetchTags}
          loadTags={this.loadTags}
          tagListLoadingState={this.state.tagListLoadingState}
          recordList={this.state.recordList}
          tagList={this.state.tagList}
          selectedTags={this.state.selectedTags}
          selectedAllTags={this.state.selectedAllTags}
          toggleSelectAllTags={this.toggleSelectAllTags}
          toggleSelectedTags={this.toggleSelectedTags}
          setTagsAsSaving={this.setTagsAsSaving}
          inviteMessage={this.state.inviteMessage}
          toggleDeviceEditOverlay={this.toggleDeviceEditOverlay}
          toggleDeviceAssignUserOverlay={this.toggleDeviceAssignUserOverlay}
          toggleDeviceAssignCategoryOverlay={
            this.toggleDeviceAssignCategoryOverlay
          }
          numberOfDevices={this.state.numberOfDevices}
          updateListOffset={this.updateListOffset}
          updateListSearchKey={this.updateListSearchKey}
          updatePagination={this.updatePagination}
          currentPage={this.state.currentPage}
          numberOfPages={this.state.numberOfPages}
          updateSelectedDeviceImei={this.updateSelectedDeviceImei}

        />
      </div>
    );
  };

  /**
   * Returns the user assingment overlay
   * @return JSX
   */
  renderDeviceAssignUserOverlay = user => {
    return (
      <DeviceAssignUserOverlay
        loggedInUser={user}
        device={this.state.selectedDevice}
        userList={this.state.userList}
        loadTags={this.loadTags}
        closeFunction={this.toggleDeviceAssignUserOverlay}
        settingsSaving={"false"}
        getRoleList={this.props.getRoleList}
        getUserListFiltered={this.props.getUserListFiltered}
        assignUsersToDevice={this.props.assignUsersToDevice}
        getAssignedUsersForDevice={this.props.getAssignedUsersForDevice}
      />
    );
  };

  /**
   * Returns the category assignment overlay
   * @return JSX
   */
  renderDeviceAssignCategoryOverlay = user => {
    return (
      <DeviceAssignCategoryOverlay
        loggedInUser={user}
        device={this.state.selectedDevice}
        closeFunction={this.toggleDeviceAssignCategoryOverlay}
        getCategoryListForDevice={this.props.getCategoryListForDevice}
        assignCategoryToDevice={this.props.assignCategoryToDevice}
      />
    );
  };

  /**
   * Returns th device creation overlay
   * @return JSX
   */
  renderDeviceCreateOverlay = () => {
    return (
      <DeviceCreateOverlay
        closeFunction={this.toggleCreateDeviceOverlay}
        getDeviceTypeList={this.getDeviceTypeList}
        tagList={this.state.tagList}
      />
    );
  };

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };

  toggleCreateDeviceOverlay = () => {
    this.setState({ showCreateOverlay: !this.state.showCreateOverlay });
  };

  toggleDeviceEditOverlay = () => {
    // this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
    alert("Toggle edit overlay");
  };

  toggleDeviceAssignUserOverlay = device => {
    this.setState({
      showDeviceAssignUserOverlay: !this.state.showDeviceAssignUserOverlay,
      selectedDevice: device,
    });
  };

  toggleDeviceAssignCategoryOverlay = device => {
    this.setState({
      showDeviceAssignCategoryOverlay: !this.state
        .showDeviceAssignCategoryOverlay,
      selectedDevice: device,
    });
  };

  toggleUserCreateOverlay = () => {
    //alert(`showUserCreateOverlay: ${!this.state.showDeviceUserCreateOverlay}`);
    this.setState({
      showDeviceUserCreateOverlay: !this.state.showDeviceUserCreateOverlay,
    });
  };

  /**
   * Updates the pagination offset to facilitate page switching
   * @param offset
   */
  updateListOffset = offset => {
    this.setState({ deviceListOffset: offset }, this.load);
  };

  /**
   * Updates the search term the list is filtered by
   * @param searchKey
   */
  updateListSearchKey = searchKey => {
    this.setState({ deviceListSearchKey: searchKey }, this.load);
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * deviceListLimit);
  };

  updatePaginationObj = pageNumber => {
    return { currentPage: pageNumber, deviceListOffset: pageNumber * deviceListLimit };
  };
}

export const AdminPanelDeviceBase = injectIntl(AdminPanelDeviceBaseClass);
