import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { Redirect } from "react-router-dom";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { FormattedMessage as T, injectIntl } from "react-intl";
import { deviceSafetyZoneIsWholeDay } from "./deviceSafetyZoneIsWholeDay";

class DeviceSafetyZoneDetailClass extends React.Component {
  render() {
    const {
      changeHours,
      deviceSafetyZone,
      geofencingList,
      handleSelect,
      imei,
      loadingState,
      portal,
      saveData,
      setAsLeaveAlarm,
      setWeekday,
      trackerDetail,
      weekdayList,
    } = this.props;

    if (loadingState === "loading" || loadingState === "saving") {
      return (
        <div className="form">
          <LoadingCircle />
        </div>
      );
    } else if (loadingState === "loaded") {
      const geofence = geofencingList.find(
        ({ geofence_id }) =>
          /// TODO check union types to avoid such null-checks
          geofence_id === (deviceSafetyZone && deviceSafetyZone.geofence_id),
      );
      /// TODO check union types to avoid such null-checks
      const { day_frequency } = deviceSafetyZone || {};
      return (
        <div className="form">
          <h5>
            <T
              id="tracar.device-safety-zone-detail.safetyzoneFor"
              defaultMessage="Sicherheitszone {geofence} für {trackerName}"
              values={{
                geofence: geofence && geofence.name,
                trackerName: trackerDetail && trackerDetail.name,
              }}
            />
          </h5>
          <div className="grid-x grid-margin-x">
            <div className="medium-6 large-6 cell">
              <div className="select-frame">
                <i className="fa fa-angle-down angle" />
                <select
                  className="select"
                  id="safety-zone"
                  value={deviceSafetyZone && deviceSafetyZone.geofence_id}
                  onChange={handleSelect}
                >
                  {geofencingList.map(geofence => (
                    <option
                      value={geofence.geofence_id}
                      key={geofence.geofence_id}
                    >
                      {geofence.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="safety-zone">
                  <T
                    id="tracar.device-safety-zone-detail.safetyZone"
                    defaultMessage="Sicherheitszone"
                  />
                </label>
              </div>
            </div>
            <div className="medium-6 large-6 cell">
              <div className="weekday-input">
                <div className="label">
                  <T
                    id="tracar.device-safety-zone-detail.activeDays"
                    defaultMessage="Zu folgenden Tagen aktiv:"
                  />
                  &nbsp;&nbsp;&nbsp;
                </div>
                <div className="weekday-buttons">
                  {weekdayList.map((weekday, index) => (
                    <div
                      key={index}
                      className={
                        "button" +
                        (day_frequency[index] === "1" ? " active" : "")
                      }
                      onClick={setWeekday(index)}
                    >
                      {weekday}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="medium-6 large-6 cell">
              <div className="weekday-input">
                <div className="label">
                  <T
                    id="tracar.device-safety-zone-detail.type"
                    defaultMessage="Alarmtyp:"
                  />
                  &nbsp;&nbsp;&nbsp;
                </div>
                <div className="weekday-buttons">
                  <div
                    className={
                      "button" +
                      (deviceSafetyZone &&
                      deviceSafetyZone.leave_alert === false
                        ? " active"
                        : "")
                    }
                    onClick={setAsLeaveAlarm(false)}
                  >
                    <i className="fa fa-sign-in" /> &nbsp;{" "}
                    <T
                      id="tracar.device-safety-zone-detail.alarmIn"
                      defaultMessage="Alarm bei der Einfahrt"
                    />
                  </div>
                  <div
                    className={
                      "button" +
                      (deviceSafetyZone && deviceSafetyZone.leave_alert === true
                        ? " active"
                        : "")
                    }
                    onClick={setAsLeaveAlarm(true)}
                  >
                    <i className="fa fa-sign-out" /> &nbsp;{" "}
                    <T
                      id="tracar.device-safety-zone-detail.alarmOut"
                      defaultMessage="Alarm beim Verlassen"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="medium-6 large-6 cell">
              <div className="weekday-input">
                <div className="label">
                  <T
                    id="tracar.device-safety-zone-detail.time"
                    defaultMessage="Zeit:"
                  />
                  &nbsp;&nbsp;&nbsp;
                </div>
                <div className="weekday-buttons">
                  <div
                    className={
                      "button" +
                      /// TODO have a look at union types to prevent such void-checks
                      (deviceSafetyZoneIsWholeDay(deviceSafetyZone)
                        ? " active"
                        : "")
                    }
                    onClick={() => changeHours("whole day")}
                  >
                    <T
                      id="tracar.device-safety-zone-detail.wholeDay"
                      defaultMessage="ganztägig"
                    />
                  </div>
                  <div
                    className={
                      "button" +
                      (!deviceSafetyZoneIsWholeDay(deviceSafetyZone)
                        ? " active"
                        : "")
                    }
                    onClick={() => changeHours("time-dependent")}
                  >
                    <T
                      id="tracar.device-safety-zone-detail.timeDependent"
                      defaultMessage="Uhrzeitabhängig"
                    />
                  </div>
                </div>
              </div>
            </div>
            {!deviceSafetyZoneIsWholeDay(deviceSafetyZone) && (
              <div className="medium-6 large-6 cell hour-select">
                <div className="hour-button">
                  <div className="label">
                    <T
                      id="tracar.device-safety-zone-detail.start"
                      defaultMessage="Start"
                    />
                  </div>
                  <TimePicker
                    defaultValue={moment()}
                    value={deviceSafetyZone.start_time}
                    showSecond={true}
                    disabled={false}
                    allowEmpty={false}
                    onChange={start_time => changeHours({ start_time })}
                    minuteStep={1}
                  />
                </div>
                <div className="hour-button">
                  <div className="label">
                    <T
                      id="tracar.device-safety-zone-detail.end"
                      defaultMessage="Ende"
                    />
                  </div>
                  <TimePicker
                    defaultValue={moment()}
                    value={deviceSafetyZone.end_time}
                    showSecond={true}
                    disabled={false}
                    allowEmpty={false}
                    onChange={end_time => changeHours({ end_time })}
                    minuteStep={1}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="spacer" />
          <div className="grid-x grid-margin-x">
            <div
              className="medium-6 large-6 cell button save-button"
              onClick={saveData}
            >
              <T
                id="tracar.device-safety-zone-detail.save"
                defaultMessage="Daten speichern"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to={`/${portal}/device-safety-zone/${imei}`} />;
    }
  }
}

export const DeviceSafetyZoneDetail = injectIntl(DeviceSafetyZoneDetailClass);
