import { deleteJson, postJson, putJson } from "./fetchService";

const userGroupRoute = "/admin_user/group_users";

/// Collection of all fetch functions regarding the non-company-user management
export default function fetchUserGroups(wrappedFetch) {
  const getUserGroupList = async () => {
    return dummyData;
  };

  const getUserGroupListFiltered = (search_key, offset, limit) => {
    return wrappedFetch(
      "/admin_user/list_user_groups",
      postJson({
        search_key: search_key,
        offset: offset,
        limit: limit,
        filter: false,
      }),
    );
  };

  const getUserListByUserGroup = (group_id, fetch_unassigned) => {
    return wrappedFetch(
      "/admin_user/group_users/list_user",
      postJson({
        group_id: group_id,
        unassigned_users: fetch_unassigned,
      }),
    );
  };

  const assignUsersToUserGroup = (user_group_record, users) => {
    return wrappedFetch(
      "/admin_user/group_users/" + user_group_record.group_id,
      putJson({
        group_name: user_group_record.group_name,
        users: users,
      }),
    );
  };

  const getUserGroupListBeta = () => {
    //TODO: API not ready
    return wrappedFetch(userGroupRoute, postJson());
  };
  const createUserGroup = (groupName, users) =>
    wrappedFetch(
      "/admin_user/group_users",
      postJson({ group_name: groupName, users: users }),
    );

  const editUserGroup = (group_id, group_name, group_users) =>
    wrappedFetch(
      "/admin_user/group_users/" + group_id,
      putJson({ group_name: group_name, users: group_users }),
    );

  const deleteUserGroup = group_id =>
    wrappedFetch("/admin_user/group_users/" + group_id, deleteJson({}));

  return {
    getUserGroupList,
    getUserGroupListBeta,
    createUserGroup,
    editUserGroup,
    deleteUserGroup,
    getUserListByUserGroup,
    assignUsersToUserGroup,
    getUserGroupListFiltered,
  };
}

const dummyData = [
  {
    id: 1,
    name: "Gruppe 01",
    users: [
      {
        email: "saniwa89@gmail.com",
        first_name: "",
        last_name: "",
        id: 89,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 3,
      },
    ],
  },
  {
    id: 2,
    name: "Gruppe 02",
    users: [
      {
        email: "saniwa89@gmail.com",
        first_name: "",
        last_name: "",
        id: 189,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 13,
        registered_on: "2018-01-18",
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 3,
      },
    ],
  },
  {
    id: 3,
    name: "Gruppe 03",
    users: [
      {
        email: "saniwa89@gmail.com",
        first_name: "",
        last_name: "",
        id: 89,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 3,
      },
    ],
  },
  {
    id: 4,
    name: "Gruppe 04",
    users: [
      {
        email: "test_subuser@leoworx.com",
        first_name: "SubUser",
        last_name: "Leoworx",
        id: 10,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "saniwa89@gmail.com",
        first_name: "",
        last_name: "",
        id: 189,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 13,
        registered_on: "2018-01-18",
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 3,
      },
    ],
  },
  {
    id: 5,
    name: "Gruppe 05",
    users: [
      {
        email: "saniwa89@gmail.com",
        first_name: "",
        last_name: "",
        id: 89,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 3,
      },
      {
        email: "test_subuser@leoworx.com",
        first_name: "SubUser",
        last_name: "Leoworx",
        id: 10,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "saniwa89@gmail.com",
        first_name: "",
        last_name: "",
        id: 189,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 13,
        registered_on: "2018-01-18",
      },
    ],
  },
  {
    id: 6,
    name: "Gruppe 06",
    users: [
      {
        email: "test_subuser@leoworx.com",
        first_name: "SubUser",
        last_name: "Leoworx",
        id: 10,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "saniwa89@gmail.com",
        first_name: "",
        last_name: "",
        id: 189,
        registered_on: "2018-01-18",
        latest_tracklog_time: new Date("2019-03-20T10:58:25Z"),
      },
      {
        email: "test_masteruser@leoworx.com",
        first_name: "Maximus",
        last_name: "Munstermann",
        id: 13,
        registered_on: "2018-01-18",
      },
    ],
  },
];
