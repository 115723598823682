import React from "react";
import { GeofencingList } from "./GeofencingList";
import { Request } from "../Request/Request";
import { FormattedMessage as T } from "react-intl";

export class GeofencingListBase extends React.Component {
  deleteGeofence;
  requestDelete;
  load;

  constructor(props) {
    super(props);

    this.state = {
      loadingState: null,
      geofences: null,
      requestedDeleteState: null,
      geofenceToBeDeleted: null,
    };
    this.requestDelete = this.requestDelete.bind(this);
    this.deleteGeofence = this.deleteGeofence.bind(this);
    this.load = this.load.bind(this);
  }

  deleteGeofence(geofenceId) {
    this.props.deleteGeofence(geofenceId).then(res => {
      this.setState({ requestedDeleteState: "done" });
      this.load();
    });
  }

  requestDelete(geofenceId) {
    const geofence =
      this.state.geofences &&
      this.state.geofences.find(
        geofence => geofenceId === geofence.geofence_id,
      );
    if (geofence == null) {
      return;
    }
    this.setState({
      requestedDeleteState: "pending",
      geofenceToBeDeleted: geofence,
    });
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loadingState: "loading" });
    this.props
      .getGeofencingList()
      .then(res => this.setState({ geofences: res, loadingState: "done" }));
  }

  render() {
    return (
      <div>
        <GeofencingList
          loadingState={this.state.loadingState}
          requestDelete={this.requestDelete}
          geofences={this.state.geofences}
          // //deleteGeofence={this.deleteGeofence}
          // // pendingDelete={this.state.requestedDeleteState}
          savedGeofenceName={
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.savedGeofenceName
          }
          deletedGeofence={
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.deletedGeofence
          }
          getLocationForSingleTracker={this.props.getLocationForSingleTracker}
        />
        {this.state.requestedDeleteState === "pending" &&
          this.state.geofenceToBeDeleted && (
            /// TODO CS bitte das Overlay für die Löschen-nachfrage bei den customer addresses übernehmen
            <Request
              text={
                <T
                  id="tracar.geofencing.deleteHeader"
                  defaultMessage="Möchten Sie die Sicherheitszone {name} löschen?"
                  values={{ name: this.state.geofenceToBeDeleted.name }}
                />
              }
              accept={{
                label: (
                  <T
                    id="tracar.geofencing.confirmDelete"
                    defaultMessage="Sicherheitszone löschen"
                  />
                ),
                callback: () => {
                  this.setState({
                    requestedDeleteState: null,
                    loadingState: "loading",
                  });
                  this.deleteGeofence(
                    this.state.geofenceToBeDeleted &&
                      this.state.geofenceToBeDeleted.geofence_id,
                  );
                },
              }}
              reject={{
                label: (
                  <T id="tracar.geofencing.cancel" defaultMessage="Abbrechen" />
                ),
                callback: () => this.setState({ requestedDeleteState: null }),
              }}
            />
          )}
      </div>
    );
  }
}
