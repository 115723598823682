import React from "react";
import { EditTagListOverlay } from "./EditTagListOverlay";
import { noTagsPlaceholder } from "../TrackerList/TrackerListBase";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

export class TagBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditTagsOverlay: false,
    };
  }

  toggleShowEditTagsOverlay = () => {
    this.setState({ showEditTagsOverlay: !this.state.showEditTagsOverlay });
  };

  render() {
    this.props.selectedTags.length === 0 && this.props.toggleSelectAll();

    return (
      <div>
        <div className="button-frame tag-buttons">
          <div className="block ">
            <div
              className={`button
                  ${!this.props.selectedAllTags && "inactive"}`}
              onClick={() => this.props.toggleSelectAll()}
            >
              <i className="fa fa-tags" />
            </div>
            <div
              className={`button
              ${!this.props.selectedTags.find(
                tag => tag === noTagsPlaceholder,
              ) && "inactive"}`}
              onClick={() => this.props.toggleSelectedTags(noTagsPlaceholder)}
            >
              <i className="fa fa-tag" />
              &nbsp;&nbsp;&nbsp;
              <T id="tracar.tag.no-tags" defaultMessage="Ohne Tags" />
            </div>
            {this.props.tagList.tag_id_list.reduce((acc, tagId) => {
              const tagObject = this.props.tagList.tag_objects[
                tagId.toString()
              ];
              return [
                ...acc,
                <div
                  key={tagId}
                  className={`button
                    ${!this.props.selectedTags.find(tag => tag.id === tagId) &&
                      "inactive"}`}
                  onClick={() =>
                    this.props.toggleSelectedTags({
                      id: tagId,
                      name: tagObject.tag_name,
                    })
                  }
                >
                  <i
                    className="fa fa-tag"
                    style={{ color: tagObject.tag_color }}
                  />
                  &nbsp;&nbsp;&nbsp;{tagObject.tag_name}
                </div>,
              ];
            }, [])}
          </div>
          <div className="small-full-btn">
            <div className="button" onClick={this.toggleShowEditTagsOverlay}>
              <i className="fa fa-pencil" />
            </div>
          </div>
        </div>
        {this.state.showEditTagsOverlay && (
          <EditTagListOverlay
            closeFunction={this.toggleShowEditTagsOverlay}
            fetchTags={this.props.fetchTags}
            loadTags={this.props.loadTags}
            setTagsAsSaving={this.props.setTagsAsSaving}
            tagList={this.props.tagList}
            tagListLoadingState={this.props.tagListLoadingState}
          />
        )}
      </div>
    );
  }
}
