//TODO: DEPRECATED(?)

import React from "react";
import { TrackerDetail } from "./TrackerDetail";
import { LoadingCircle } from "../LoadingCircle";
import { Redirect } from "react-router-dom";
import { Portal } from "../Portal";
import { Odometer } from "./Odometer";
import { generateTrackerDetailFormData } from "./generateTrackerDetailFormData";
import { generateSaveableDataTrackerDetailFromData } from "./generateSaveableDataTrackerDetailFromData";
import { NewCar } from "./NewCar";
import { SosDisplay } from "../Person/SosDisplay";

export class TrackerDetailBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceEmailList: null,
      formData: null,
      imei: this.props.match.params.imei || "",
      loadingState: "loading",
      nonCompanyUserList: null,
      odometer: null,
      showNewCarOverlay: false,
      cars: [],
      portal: this.props.match.params.portal || "",
      showOdometerOverlay: false,
      sosObj: null,
      trackingInterval: null,
    };

    this.load = this.load.bind(this);
    this.toggleOdometerOverlay = this.toggleOdometerOverlay.bind(this);
    this.toggleNewCarOverlay = this.toggleNewCarOverlay.bind(this);
  }

  load() {
    this.setState({ loadingState: "loading" });

    const maxSpeedPromise = this.props.maxSpeedRequest(this.state.imei);
    const deviceEmailListPromise = this.props.deviceEmailListRequest(
      this.state.imei,
    );
    const trackerDetailPromise = this.props.getTrackerDetail(this.state.imei);
    const nonCompanyUserListPromise = this.props.user.is_company
      ? this.props.getNonCompanyUserList()
      : [];

    const carsPromise = this.props.carsRequest(this.state.imei);

    const sosObj =
      this.state.portal === Portal.Person
        ? this.props.getSos(this.state.imei)
        : null;

    const trackingInterval =
      this.state.portal === Portal.Asset
        ? this.props.fetchTrackingInterval.getTrackingInterval(this.state.imei)
        : null;

    Promise.all([
      deviceEmailListPromise,
      maxSpeedPromise,
      nonCompanyUserListPromise,
      trackerDetailPromise,
      carsPromise,
      sosObj,
      trackingInterval,
    ]).then(
      ([
        deviceEmailList,
        max_speed,
        nonCompanyUserListArg,
        trackerDetail,
        cars,
        sosObj,
        trackingInterval,
      ]) => {
        const nonCompanyUserList = nonCompanyUserListArg.filter(
          ({ is_active }) => is_active,
        );
        this.setState({
          formData: generateTrackerDetailFormData(
            trackerDetail,
            deviceEmailList,
            max_speed,
          ),
          car_number: trackerDetail.cardetails.car_number,
          model: trackerDetail.cardetails.model,
          driver: trackerDetail.cardetails.driver,
          sosObj,
          trackingInterval: trackingInterval ? trackingInterval : null,
          nonCompanyUserList,
          loadingState: "loaded",
          odometer: trackerDetail.cardetails.device_odom_curr || 0,
          cars,
          odometer: trackerDetail.cardetails.device_odom_curr || 0,
        });
      },
    );
    this.props.maxSpeedRequest(this.state.imei).then(res => {});
  }

  componentDidMount() {
    this.load();
  }

  toggleOdometerOverlay() {
    this.setState({ showOdometerOverlay: !this.state.showOdometerOverlay });
  }

  toggleNewCarOverlay() {
    this.setState({ showNewCarOverlay: !this.state.showNewCarOverlay });
  }

  updateTrackingInterval = interval => {
    let updatedInterval = this.state.trackingInterval;
    updatedInterval.interval_setting_type = interval;
    this.setState({ trackingInterval: updatedInterval });
  };

  saveData = data => {
    const {
      deviceEmailList,
      max_speed,
      trackerDetail,
      user_id,
    } = generateSaveableDataTrackerDetailFromData(data);
    this.setState({ loadingState: "saving" });
    const speed = this.props.maxSpeedRequest(this.state.imei, max_speed);
    const mails = this.props.deviceEmailListRequest(
      this.state.imei,
      deviceEmailList,
    );
    const cars = this.props.carsRequest(this.state.imei);

    const detail = this.props.getTrackerDetail(this.state.imei, trackerDetail);

    const assignUserToDevice = this.props.assignUserToDevice(
      this.state.imei,
      parseInt(user_id, 10),
    );

    const trackingInterval = this.state.trackingInterval
      ? this.props.fetchTrackingInterval.setTrackingInterval(
          this.state.imei,
          this.state.trackingInterval.interval_setting_type,
        )
      : null;

    Promise.all([
      assignUserToDevice,
      detail,
      mails,
      speed,
      cars,
      trackingInterval,
    ])
      .then((userToDevice, detail, mails, speed, cars) =>
        this.setState({
          loadingState: "saved",
          cars: cars,
          trackingInterval: trackingInterval.actual_interval_in_hardware,
        }),
      )
      /// The catch is need to handle the 404 on delete :/
      .catch(() => this.setState({ loadingState: "saved" }));
  };

  render() {
    if (
      this.state.loadingState === "loading" ||
      this.state.loadingState === "saving"
    ) {
      return (
        <div className="form">
          <LoadingCircle />
        </div>
      );
    }
    if (
      this.state.loadingState === "loaded" &&
      /// TODO reinsert upon availability
      this.state.odometer != null &&
      this.state.formData != null &&
      this.state.nonCompanyUserList != null
    ) {
      return (
        <div>
          <div className="form">
            <TrackerDetail
              formData={this.state.formData}
              model={this.state.model}
              car_number={this.state.car_number}
              driver={this.state.driver}
              nonCompanyUserList={this.state.nonCompanyUserList}
              saveData={this.saveData}
              toggleOdometerOverlay={this.toggleOdometerOverlay}
              user={this.props.user}
              odometer={this.state.odometer}
              imei={this.state.imei}
              portal={this.state.portal}
              toggleNewCarOverlay={this.toggleNewCarOverlay}
              cars={this.state.cars}
              addNewCar={this.props.addNewCar}
              sosObj={this.state.sosObj}
              setSos={this.props.setSos}
              trackingInterval={this.state.trackingInterval}
              updateTrackingInterval={this.updateTrackingInterval}
              reloadDashboard={this.load}
              handleFormChange={event => {
                const target = event.target;
                let value =
                  target.type === "checkbox" ? target.checked : target.value;
                const name = target.id;
                if (name === "max_speed" && value !== "") {
                  value = parseInt(value, 10);
                  value > 400 && (value = 400);
                  value < 0 && (value = 0);
                }
                this.setState({ [name]: value });
              }}
            />
          </div>
          {this.state.showOdometerOverlay && (
            <Odometer
              changeOdometer={this.props.changeOdometer}
              closeFunction={this.toggleOdometerOverlay}
              odometer={this.state.odometer}
              imei={this.state.imei}
              setOdometer={odometer => this.setState({ odometer })}
            />
          )}
          {this.state.showNewCarOverlay && (
            <NewCar
              closeFunction={this.toggleNewCarOverlay}
              imei={this.state.imei}
              addNewCar={this.props.addNewCar}
              setCarFormData={(car_number, model, driver, odometer) => {
                this.props.carsRequest(this.state.imei).then(cars =>
                  this.setState({
                    cars: cars,
                    car_number: car_number,
                    model: model,
                    driver: driver,
                    odometer: odometer,
                  }),
                );
              }}
            />
          )}
        </div>
      );
    }
    if (this.state.loadingState === "saved") {
      const portal = this.props.match.params.portal || "";
      return <Redirect to={`/${portal}/device-list`} />;
    }
    return null;
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS,
  ): void {
    //console.log(this.state);
  }
}
