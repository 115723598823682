import {
  deleteJson,
  postJson,
  postMultipartFormdata,
  putMultipartFormdata,
} from "./fetchService";
import { apiBaseUrl } from "./middleware";
import { saveAs } from "file-saver";

const serverbaseUrl = apiBaseUrl;

/// Collection of all fetch functions regarding the expense management
export default function fetchExpensesPortal(wrappedFetch, freeFetch) {
  const getExpenseListForDevice = (imei, offset, limit) => {
    return wrappedFetch(
      `/user/v2/poolfahrzeuge/${imei}/receipts?offset=${offset}&limit=${limit}`,
    );
  };

  const deleteExpense = receipt_id => {
    return wrappedFetch(
      `/user/v2/poolfahrzeuge/receipts/${receipt_id}`,
      deleteJson(),
    );
  };

  const editExpense = (
    expense_id,
    amount,
    tax,
    category,
    description,
    is_acknowledged,
    image,
    quantity,
    price_per_unit,
  ) => {
    let body = {
      amount,
      tax,
      category,
      description,
      is_acknowledged,
    };
    if (image !== "nochange") {
      body = { ...body, image };
    }

    if (category === "REFUEL") {
      body = { ...body, quantity, price_per_unit };
    }

    return freeFetch(
      `/user/v2/poolfahrzeuge/receipts/${expense_id}`,
      putMultipartFormdata(body),
    );
  };

  const createExpense = (
    vehicle,
    amount,
    tax,
    category,
    description,
    start_date,
    image,
    quantity,
    price_per_unit,
  ) => {
    let body = {
      vehicle,
      amount,
      tax,
      category,
      description,
      start_date,
      image,
    };
    if (category === "REFUEL") {
      body = { ...body, quantity, price_per_unit };
    }
    return wrappedFetch(
      `/user/v2/poolfahrzeuge/receipts`,
      postMultipartFormdata(body),
    );
  };

  const getExpenseCategoryList = category_type => {
    return wrappedFetch(
      `/general/poolfahrzeuge/costs/categories`,
      postJson({ category_type }),
    );
  };

  const getExpenseImage = receipt_id => {
    let filename;
    return freeFetch(`/user/v2/poolfahrzeuge/receipts/${receipt_id}/image`, {
      responseType: "blob",
    })
      .then(response => {
        if (!response.ok) {
          return;
        }
        for (let [header, content] of response.headers.entries()) {
          if (header === "content-disposition") {
            filename = content.split("filename=")[1];
          }
        }
        return response.blob();
      })
      .then(blob => {
        if (!blob) {
          return;
        }
        return saveAs(blob, filename);
      });
  };

  return {
    getExpenseListForDevice,
    editExpense,
    createExpense,
    getExpenseCategoryList,
    getExpenseImage,
    deleteExpense,
  };
}
