import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

class ConfirmSplitOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Renders the headline an dinfo text.
   * @return RequestOverlay
   */
  render() {
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="confirm-route-split"
        enableCloseOnOverlayClicked={true}
      >
        {this.renderConfirmation()}
      </RequestOverlay>
    );
  }

  /**
   * Confirm route splitting
   * @return JSX
   */
  renderConfirmation = () => {
    if (this.state.splittingRoutes) {
      return <LoadingCircle />;
    }

    return (
      <div>
        {this.renderHeader()}
        {this.renderButtons()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="tracar.map.overlay.confirmSplitHeadline"
            defaultMessage="Möchten Sie die Route wirklich an diesem Punkt teilen?"
          />
        </h5>
        <T
          id="tracar.map.overlay.confirmSplitDescription"
          defaultMessage="Wenn Sie die Route an diesem Punkt teilen, wird die Route in zwei Teilrouten aufgeteilt. Die erste Teilroute endet an diesem Punkt und die zweite beginnt an dem nachfolgenden Punkt. So können Sie Routen die z.B. zuerst privat und danach Arbeitsweg sind ordentlich dokumentieren."
        />
      </div>
    );
  };

  /**
   * Renders the buttons
   * @return JSX
   */
  renderButtons = () => {
    return (
      <div className="confirm-button-container">
        <div className="spacer" />

        <div className="button" onClick={this.props.closeFunction}>
          <span>
            <T
              id="customer.addresses.confirmDelete.buttonCancel"
              defaultMessage="Abbrechen"
            />
          </span>
        </div>

        <div
          className="button save-button"
          onClick={this.processSplitConfirmation}
          style={{ marginLeft: "15px" }}
        >
          <span>
            <T
              id="customer.addresses.confirmDelete.buttonSplit"
              defaultMessage="Teilen"
            />
          </span>
        </div>
      </div>
    );
  };

  /**
   * Show Loading Circle
   * @return void
   */
  processSplitConfirmation = () => {
    this.setState(
      {
        splittingRoutes: true,
      },
      this.props.confirmSplit,
    );
  };
}

export const ConfirmSplitOverlay = injectIntl(ConfirmSplitOverlayClass);
