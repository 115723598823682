import React from "react";
import {injectIntl, FormattedMessage as T} from "react-intl";
import {UserConsumer} from "../../../Base";
import {LoadingCircle} from "../../../LoadingCircle";
import {hasPortalAccess} from "../../../Permission";
import {Portal} from "../../../Portal";
import {deleteTemplate, fetchTaskTemplates} from "../../../fetch/fetchSmartInventory";
import {SmartInventoryTaskTemplatesList} from "./SmartInventoryTaskTemplatesList";
import {SmartInventoryTaskTemplatesHeadline} from "./SmartInventoryTaskTemplatesHeadline";
import CreateEditTaskTemplateOverlay from "./Overlays/CreateEditTaskTemplateOverlay";
import {PaginationBar} from "../../PaginationBar";

class SmartInventoryTaskTemplates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            templates: [],
            loading: true,
            currentPage: 0,
            itemsPerPage: 10,
            templateToEdit: null,
            showCreateEditTemplateOverlay: false,
        };
    }

    async componentDidMount() {
        this.loadTaskTemplates();
    }

    loadTaskTemplates() {
        fetchTaskTemplates(this.props.fetchService.jsonFetch).then(templates => {
            this.setState({
                templates: templates,
                loading: false,
            });
        });
    }

    render() {
        if (this.state.loading) {
            return <LoadingCircle />;
        }

        return (
            <UserConsumer>
                {user => {
                    if (!hasPortalAccess(user, Portal.AdminPanelSmartInventory)) {
                        return;
                    }

                    return this.renderSmartInventoryTaskTemplatePage();
                }}
            </UserConsumer>
        );
    }

    renderSmartInventoryTaskTemplatePage() {
        return (
            <div className="admin-list outer-table-frame smart-inventory-task-templates">
                <div className="grid-x grid-margin-x align-middle margin-bottom-1">
                    {this.renderCreateButton()}
                </div>

                <div className="table-frame">
                    {this.renderTemplateList()}
                </div>

                {this.renderPagination()}

                {this.renderOverlays()}
            </div>
        );
    }

    renderCreateButton() {
        return (
            <div className="shrink cell">
                <div className="button-frame">
                    <div className="button green primary" onClick={() => this.setState({
                        templateToEdit: null,
                        showCreateEditTemplateOverlay: true,
                    })}>
                        <T
                            id="adminPanel.smartInventoryTaskTemplates.labels.createTemplate"
                            defaultMessage="Neue Liste anlegen"
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderTemplateList() {
         return (
            <div className="smart-inventory-task-templates-table">
                <SmartInventoryTaskTemplatesHeadline numberTasks={this.state.templates.length} />
                <SmartInventoryTaskTemplatesList
                    fetchService={this.props.fetchService}
                    templates={this.getPaginatedTemplates()}
                    editTemplate={this.editTemplate}
                    deleteTemplate={this.deleteTemplate}
                />
            </div>
        );
    }

    deleteTemplate = (template) => {
        deleteTemplate(this.props.fetchService.jsonFetch, template.id).then(() => {
            this.loadTaskTemplates();
        })
    }

    editTemplate = (template) => {
        this.setState({
           showCreateEditTemplateOverlay: true,
           templateToEdit: template,
        });
    }

    renderPagination() {
        const numberMaxPages = this.getMaxPages();

        if (numberMaxPages <= 1) {
            if (this.state.currentPage > 0) {
                this.setState({ currentPage: 0 });
            }

            return;
        }

        return (
            <PaginationBar
                currentPage={this.state.currentPage}
                numberOfPages={numberMaxPages}
                updateCurrentPage={this.updateCurrentPage}
            />
        );
    }

    getMaxPages() {
        return Math.ceil(this.state.templates.length / this.state.itemsPerPage);
    }

    updateCurrentPage = pageNumber => {
        if (pageNumber === this.state.currentPage) {
            return;
        }

        this.setState({
            currentPage: pageNumber,
            listOffset: pageNumber * this.state.itemsPerPage,
        });
    };

    getPaginatedTemplates() {
        return this.state.templates.slice(
            this.state.currentPage * this.state.itemsPerPage,
            this.state.currentPage * this.state.itemsPerPage + this.state.itemsPerPage,
        );
    }

    renderOverlays() {
        if (this.state.showCreateEditTemplateOverlay) {
            return this.renderCreateEditOverlay();
        }
    }

    renderCreateEditOverlay() {
        return <CreateEditTaskTemplateOverlay
            template={this.state.templateToEdit}
            fetchService={this.props.fetchService}
            closeFunction={(shouldReload = false) => {
                this.setState({showCreateEditTemplateOverlay: false}, () => {
                    if (shouldReload) {
                        this.loadTaskTemplates();
                    }
                });
            }}
        />
    }
}

export default injectIntl(SmartInventoryTaskTemplates);
