import React from "react";
import { removeArrayElement } from "../utility";
import { TrackerTable } from "./TrackerTable";
import { FilterBar } from "../Filter/FilterBar";
import { SortingBar } from "../Sorting/SortingBar";
import { QueryLine } from "../tools/QueryLine";
import { LoadingCircle } from "../LoadingCircle";
import { Route } from "react-router";
import { SettingsBase } from "../Settings/SettingsBase";
import { UserConsumer } from "../Base";
import { hasPermission, Permission } from "../Permission";
import { DeviceAssignmentType } from "../ApiContract";

export const noTagsPlaceholder = {
  id: Number.MAX_SAFE_INTEGER - 1,
  name: "no_tags_placeholder",
};
export const noGeofencesPlaceholder = {
  id: Number.MAX_SAFE_INTEGER - 2,
  name: "no_geofences_placeholder",
};

const getDirectionListDefault = () => ({
  name: "",
  imei: "",
  latest_tracklog_time: "",
  registered_on: "",
});

export class TrackerListBase extends React.Component {
  /// the promise number is used to enforce that only the latest request
  /// actually gets rendered
  promiseNumber;

  static contextType = UserConsumer;
  constructor(props) {
    super(props);
    let directionList;

    /** make sure that a misformed save does not ruin the app by causing a crash
     * in the constructor */
    try {
      if (localStorage.getItem("directionList")) {
        directionList = JSON.parse(localStorage.getItem("directionList"));
      } else {
        directionList = getDirectionListDefault();
      }
    } catch (e) {
      console.error(e);
      directionList = getDirectionListDefault();
    }

    this.state = {
      //list for single imei in case user want to edit one device direct (without bulkload)
      selectedDeviceImei: [],
      // list with one or more imei's for bulkload
      selectedDevicesImeiList: [],
      devices: null,
      selectedTags: [],
      selectedAllTags: true,
      lastSelectedTags: [],
      searchFilter: "",
      geofencingList: [],
      selectedGeofences: [],
      selectedAllGeofences: true,
      lastSelectedGeofences: [],
      isFiltered: false,
      initialTagLoad: true,
      initialGeofenceLoad: true,
      tagList: {
        tag_objects: {},
        tag_id_list: [],
      },
      tagListLoadingState: "loading",
      directionList,
      sortedDevices: [],
      isInitialSorted: false,
      onlineFilter:
        this.props.location.state && this.props.location.state.onlineFilter
          ? this.props.location.state.onlineFilter
          : "",
    };
    this.promiseNumber = 0;
  }

  componentDidMount() {
    this.loadTags();
  }

  loadDevices = () => {
    this.setState({ devices: null });
    this.props.getUserDevices(this.props.portal).then(({ devices }) => {
      this.setState({ devices: devices, sortedDevices: devices });
    });
  };

  loadTags = () => {
    this.setState({ tagListLoadingState: "loading" });
    //this.loadDevices();
    this.loadGeofencingList();
    this.props.fetchTags.getTagList().then(tagList => {
      this.setState({
        tagList,
        tagListLoadingState: "ready",
        selectedTags:
          this.props.location.state && this.props.location.state.selectedTags
            ? this.props.location.state.selectedTags
            : this.state.initialTagLoad
            ? [
                noTagsPlaceholder,
                ...Object.entries(tagList.tag_objects).map(tag => {
                  return { id: parseInt(tag[0]), name: tag[1].tag_name };
                }),
              ]
            : this.state.selectedTags,
        selectedAllTags: this.props.location.state ? false : true,
        initialTagLoad: false,
      });
    });
  };

  setSearchFilter = searchFilter => {
    this.setState({ searchFilter });
  };

  loadGeofencingList() {
    if (!hasPermission(this.props.user, Permission.SafetyZone)) {
      this.setState({
        selectedGeofences: [noGeofencesPlaceholder],
      });
      return;
    }
    this.props.fetchGeofences.getGeofencingList().then(res => {
      this.setState({
        geofencingList: res,
        selectedGeofences:
          this.props.location.state &&
          this.props.location.state.selectedGeofences
            ? this.props.location.state.selectedGeofences
            : this.state.initialGeofenceLoad
            ? [
                noGeofencesPlaceholder,
                ...res.map(geofence => {
                  return { id: geofence.geofence_id, name: geofence.name };
                }),
              ]
            : this.state.selectedGeofences,
        selectedAllGeofences: this.props.location.state ? false : true,
        isFiltered: false,
        initialGeofenceLoad: false,
      });
    });
  }

  setSearchFilter = searchFilter => {
    this.setState({ searchFilter });
  };

  toggleSelectAll = () => {
    if (this.state.selectedAllTags) {
      this.setState({
        selectedTags: this.state.lastSelectedTags,
        selectedAllTags: false,
        isFiltered: false,
      });
      return;
    }

    if (!this.state.selectedAllTags) {
      this.setState({
        selectedTags: [
          noTagsPlaceholder,
          ...Object.entries(this.state.tagList.tag_objects).map(tag => {
            return { id: parseInt(tag[0]), name: tag[1].tag_name };
          }),
        ],
        lastSelectedTags: this.state.selectedTags,
        selectedAllTags: true,
        isFiltered: false,
      });
    }
  };

  toggleSelectedTags = tagObject => {
    if (this.state.selectedAllTags) {
      this.setState({
        selectedTags: [tagObject],
        selectedAllTags: false,
      });
      this.filterGeofencingList();
      return;
    }

    const index = this.state.selectedTags.findIndex(
      tag => tag.id === tagObject.id,
    );
    if (index === -1 && tagObject) {
      this.setState({
        selectedTags: [...this.state.selectedTags, tagObject],
      });
    } else {
      this.setState({
        selectedTags: removeArrayElement(this.state.selectedTags, index),
      });
    }
    this.filterGeofencingList();
  };

  toggleSelectAllGeofences = () => {
    if (this.state.selectedAllGeofences) {
      this.setState({
        selectedGeofences: this.state.lastSelectedGeofences,
        selectedAllGeofences: false,
        isFiltered: false,
      });
      return;
    }

    if (!this.state.selectedAllGeofences) {
      this.setState({
        selectedGeofences: [
          noGeofencesPlaceholder,
          ...this.state.geofencingList.map(geofence => {
            return { id: geofence.geofence_id, name: geofence.name };
          }),
        ],
        lastSelectedGeofences: this.state.selectedGeofences,
        selectedAllGeofences: true,
        isFiltered: false,
      });
    }
  };

  toggleSelectedGeofences = geofenceObject => {
    if (this.state.selectedAllGeofences) {
      this.setState({
        selectedGeofences: [geofenceObject],
        selectedAllGeofences: false,
        isFiltered: false,
      });
      return;
    }

    const index = this.state.selectedGeofences.findIndex(
      geofence => geofence.id === geofenceObject.id,
    );
    if (index === -1 && geofenceObject) {
      this.setState({
        selectedGeofences: [...this.state.selectedGeofences, geofenceObject],
        isFiltered: false,
      });
    } else {
      this.setState({
        selectedGeofences: removeArrayElement(
          this.state.selectedGeofences,
          index,
        ),
        isFiltered: false,
      });
    }
  };

  toggleOnlineFilter = () => {
    switch (this.state.onlineFilter) {
      case "": {
        this.setState({ onlineFilter: "true" });
        break;
      }
      case "true": {
        this.setState({ onlineFilter: "false" });
        break;
      }
      case "false": {
        this.setState({ onlineFilter: "" });
        break;
      }
      default:
        break;
    }
  };

  filterGeofencingList() {
    let hasGeofence = true;
    let numberOfGeofences = null;
    let geofences = this.state.selectedGeofences.map(geofence => geofence.id);

    if (
      this.state.selectedGeofences.find(geofence => {
        return geofence.id === noGeofencesPlaceholder.id;
      })
    ) {
      hasGeofence = false;
    }

    /*if (geofences.length === 0) {
      return;
    }*/

    const withoutGeofencesPromise = !hasGeofence
      ? this.props.fetchGeofences.filterGeofencingList(
          false,
          numberOfGeofences,
          geofences,
          this.props.portal,
        )
      : {};

    const withGeofencesPromise =
      geofences.length > 0
        ? this.props.fetchGeofences.filterGeofencingList(
            true,
            numberOfGeofences,
            geofences,
            this.props.portal,
          )
        : {};

    Promise.all([withoutGeofencesPromise, withGeofencesPromise]).then(
      ([withoutGeofences, withGeofences]) => {
        const devices = [
          // temporary workaround for the problem of duplicate devices
          //...(withoutGeofences.devices || []),
          ...(withGeofences.devices || []),
        ];

        let sortedDevices = [];
        let sortingDefined = false;
        Object.entries(this.state.directionList).forEach(direction => {
          if (direction[1] !== "") {
            sortingDefined = true;
            sortedDevices = this.getDevicesSortedBy(direction, devices)
              .sortedDevices;
          }
        });

        !sortingDefined &&
          (sortedDevices = this.getDevicesSortedBy(["", ""], devices)
            .sortedDevices);
        this.setState({
          devices,
          sortedDevices,
          isFiltered: true,
        });
      },
    );
  }

  setTagsAsSaving = (asSaving = true) => {
    asSaving
      ? this.setState({ tagListLoadingState: "saving" })
      : this.setState({ tagListLoadingState: "ready" });
  };

  initialSorting = () => {
    const directionList =
      localStorage.getItem("directionList") &&
      JSON.parse(localStorage.getItem("directionList"));
    if (directionList) {
      Object.entries(directionList).forEach(direction => {
        direction[1] !== "" &&
          this.setState({
            ...this.getDevicesSortedBy(direction, this.state.devices),
          });
      });
    }

    this.setState({ isInitialSorted: true });
  };

  sortBy = direction => {
    this.setState({
      ...this.getDevicesSortedBy(direction, this.state.devices),
    });
  };

  getDevicesSortedBy = (direction, devices) => {
    switch (direction[1]) {
      case "": {
        let directionList = this.state.directionList;
        for (let key in directionList) {
          directionList[key] = "";
        }
        localStorage.setItem("directionList", JSON.stringify(directionList));
        return {
          directionList: directionList,
          sortedDevices: devices,
        };
      }
      case "up": {
        let directionList = this.state.directionList;
        for (let key in directionList) {
          key === direction[0]
            ? (directionList[key] = "up")
            : (directionList[key] = "");
        }
        localStorage.setItem("directionList", JSON.stringify(directionList));
        return {
          directionList: directionList,
          sortedDevices: this.sortDirection(direction[0], -1, devices),
        };
      }
      case "down": {
        let directionList = this.state.directionList;
        for (let key in directionList) {
          key === direction[0]
            ? (directionList[key] = "down")
            : (directionList[key] = "");
        }
        localStorage.setItem("directionList", JSON.stringify(directionList));
        return {
          directionList: directionList,
          sortedDevices: this.sortDirection(direction[0], 1, devices),
        };
      }
      default: {
        console.log("no direction specified");
      }
    }
  };

  sortDirection = (direction, directorVariable, devices) => {
    return [].concat(devices).sort((deviceA, deviceB) => {
      let directionA,
        directionB = "";
      if (
        direction === "latest_tracklog_time" ||
        direction === "registered_on"
      ) {
        directionA = new Date(deviceA[direction]).getTime();
        directionB = new Date(deviceB[direction]).getTime();
      } else {
        directionA = deviceA[direction].toLowerCase();
        directionB = deviceB[direction].toLowerCase();
      }
      if (directionA > directionB) {
        return -1 * directorVariable;
      } else if (directionA < directionB) {
        return 1 * directorVariable;
      } else {
        return 0;
      }
    });
  };

  updateSelectedDevicesImeiList = (imei, status) => {
    if (imei === null) {
      this.setState({ selectedDeviceImei: [] });
    } else {
      this.updateArray(this.state.selectedDevicesImeiList, imei, status);
    }
  };

  updateSelectedDeviceImei = imei => {
    imei === null
      ? this.setState({ selectedDeviceImei: [] })
      : this.setState({ selectedDeviceImei: [imei] });
  };

  clearSingleImei = () => {
    this.setState({ selectedDeviceImei: [] });
  };

  selectAllDevices = status => {
    this.getFilteredDevices(this.state.sortedDevices).map(device => {
      if (device.assignment_type === DeviceAssignmentType.Read) {
        return;
      }
      this.updateArray(this.state.selectedDevicesImeiList, device.imei, status);
    });
  };

  updateArray(arr, obj, status) {
    if (status) {
      !arr.find(arrObj => arrObj == obj) && arr.push(obj);
    } else {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === obj) {
          arr.splice(i, 1);
        }
      }
    }
    this.setState({});
  }

  checkIfReadOnlySettings = () => {
    let readOnlyDeviceSelectedSingle = this.state.selectedDeviceImei.some(
      imei => {
        return this.state.sortedDevices.some(
          device =>
            device.imei === imei &&
            device.assignment_type === DeviceAssignmentType.Read,
        );
      },
    );
    let readOnlyDeviceSelectedList = this.state.selectedDevicesImeiList.some(
      imei => {
        return this.state.sortedDevices.some(
          device =>
            device.imei === imei &&
            device.assignment_type === DeviceAssignmentType.Read,
        );
      },
    );

    return readOnlyDeviceSelectedSingle || readOnlyDeviceSelectedList;
  };

  getFilteredDevices = devices => {
    const shownTags = this.state.selectedTags.map(tag => {
      return tag.id;
    });

    if (this.state.tagListLoadingState === "ready") {
      return devices.reduce((acc, device) => {
        const tagFiltering =
          // show non tagged devices
          (device.tags.length === 0 &&
            shownTags.find(id => id === noTagsPlaceholder.id)) ||
          // show tagged devices
          shownTags.find(shownId => {
            return device.tags.find(id => {
              return shownId === id;
            });
          });

        if (!tagFiltering) {
          return acc;
        }

        const stringFiltering =
          this.state.searchFilter.length === 0 ||
          device.name
            .toLowerCase()
            .search(this.state.searchFilter.toLowerCase()) > -1 ||
          device.imei.search(this.state.searchFilter) > -1 ||
          device.description
            .toLowerCase()
            .search(this.state.searchFilter.toLowerCase()) > -1;

        if (!stringFiltering) {
          return acc;
        }

        const onlineFiltering =
          this.state.onlineFilter === "" ||
          device.is_online.toString() === this.state.onlineFilter;

        if (!onlineFiltering) {
          return acc;
        }

        return [...acc, device];
      }, []);
    } else {
      return [];
    }
  };

  removeSelectedDevicesFromFilteredDevices = devices => {
    return (
      devices !== null &&
      devices.reduce((acc, device) => {
        if (
          this.state.selectedDevicesImeiList.find(imei => {
            return imei === device.imei;
          })
        ) {
          return [...acc, device.imei];
        }
        return acc;
      }, [])
    );
  };

  render() {
    !this.state.isFiltered && this.filterGeofencingList();

    this.state.devices && !this.state.isInitialSorted && this.initialSorting();

    const selectedDevicesImeiList =
      this.props.location.state && this.props.location.state.selectedDeviceImei
        ? [this.props.location.state.selectedDeviceImei]
        : this.removeSelectedDevicesFromFilteredDevices(
            this.getFilteredDevices(this.state.sortedDevices),
          );

    const selectedSettingsTab =
      this.props.location.state && this.props.location.state.selectedSettingsTab
        ? this.props.location.state.selectedSettingsTab
        : "";

    return (
      <UserConsumer>
        {user =>
          hasPermission(user, Permission.DeviceList) && (
            <div className="outer-table-frame tracker">
              {!this.state.isFiltered && (
                <div className="loading-circle-container">
                  <LoadingCircle />
                </div>
              )}
              <FilterBar
                fetchTags={this.props.fetchTags}
                fetchGeofences={this.props.fetchGeofences}
                loadTags={this.loadTags}
                setTagsAsSaving={this.setTagsAsSaving}
                selectedTags={this.state.selectedTags}
                selectedAllTags={this.state.selectedAllTags}
                tagList={this.state.tagList}
                toggleSelectAll={this.toggleSelectAll}
                toggleSelectedTags={this.toggleSelectedTags}
                tagListLoadingState={this.state.tagListLoadingState}
                geofencingList={this.state.geofencingList}
                selectedGeofences={this.state.selectedGeofences}
                selectedAllGeofences={this.state.selectedAllGeofences}
                toggleSelectAllGeofences={this.toggleSelectAllGeofences}
                toggleSelectedGeofences={this.toggleSelectedGeofences}
                onlineFilter={this.state.onlineFilter}
                toggleOnlineFilter={this.toggleOnlineFilter}
              />
              <QueryLine changeCallback={this.setSearchFilter} />
              <SortingBar
                portal={this.props.portal}
                devices={this.getFilteredDevices(this.state.sortedDevices)}
                selectedDevicesImeiList={selectedDevicesImeiList}
                selectedTags={this.state.selectedTags}
                selectedGeofences={this.state.selectedGeofences}
                directionList={this.state.directionList}
                sortBy={this.sortBy}
                showBulkSettings={
                  selectedDevicesImeiList.length > 1 ? true : false
                }
              />
              {this.state.devices && (
                <TrackerTable
                  loadDevices={this.loadDevices}
                  searchFilter={this.state.searchFilter}
                  devicesExisting={
                    this.state.sortedDevices.length > 0 ? true : false
                  }
                  devices={this.getFilteredDevices(this.state.sortedDevices)}
                  fetchTags={this.props.fetchTags}
                  setTagsAsSaving={this.setTagsAsSaving}
                  tagList={this.state.tagList}
                  tagListLoadingState={this.state.tagListLoadingState}
                  user={this.props.user}
                  /// add the noTagsPlaceholder to the tag list to show non tagged
                  /// devices
                  shownTags={this.state.selectedTags.map(tag => {
                    return tag.id;
                  })}
                  selectedDevicesImeiList={selectedDevicesImeiList}
                  updateSelectedDevicesImeiList={
                    this.updateSelectedDevicesImeiList
                  }
                  updateSelectedDeviceImei={this.updateSelectedDeviceImei}
                  isSelectedAllDevices={
                    this.state.sortedDevices
                      ? this.getFilteredDevices(
                          this.state.sortedDevices,
                        ).filter(
                          element =>
                            element.assignment_type !==
                            DeviceAssignmentType.Read,
                        ).length === selectedDevicesImeiList.length
                      : false
                  }
                  selectAllDevices={this.selectAllDevices}
                  selectedGeofences={this.state.selectedGeofences}
                  onlineFilter={this.state.onlineFilter}
                />
              )}

              <Route
                path="/:portal/device-list/settings"
                render={({ match }) => (
                  <SettingsBase
                    match={match}
                    fetchService={this.props.fetchService}
                    selectedDevicesImeiList={
                      this.state.selectedDeviceImei.length > 0
                        ? this.state.selectedDeviceImei
                        : selectedDevicesImeiList
                    }
                    selectedSettingsTab={selectedSettingsTab}
                    devices={this.state.devices}
                    user={this.props.user}
                    updateSelectedDevicesImeiList={
                      this.updateSelectedDevicesImeiList
                    }
                    fetchDeviceSettings={this.props.fetchDeviceSettings}
                    clearSingleImei={this.clearSingleImei}
                    loadTags={this.loadTags}
                    loadGeofencingList={this.loadGeofencingList}
                    carsRequest={this.props.carsRequest}
                    addNewCar={this.props.addNewCar}
                    generateWiresCsv={this.props.generateWiresCsv}
                    getGeofenceList={this.props.getGeofenceList}
                    getDeviceSafetyZones={this.props.getDeviceSafetyZones}
                    deviceSafetyZoneRequest={this.props.deviceSafetyZoneRequest}
                    deleteDeviceSafetyZone={this.props.deleteDeviceSafetyZone}
                    readOnly={this.checkIfReadOnlySettings()}
                  />
                )}
              />
            </div>
          )
        }
      </UserConsumer>
    );
  }
}
