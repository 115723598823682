import React from "react";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import Plot from "react-plotly.js";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  last24h: {
    id: "tracar.lastSeenPie.lessThan24h",
    defaultMessage: "< 24 h",
  },
  last7d: {
    id: "tracar.lastSeenPie.lessThan7d",
    defaultMessage: "< 7 d",
  },
  moreThan7d: {
    id: "tracar.lastSeenPie.moreThan7d",
    defaultMessage: "> 7 d",
  },
});

function LastSeenPieFn({
  last_seen: { day, week, older },
  config,
  plotLayoutCommons,
  intl: { formatMessage: t },
  portal,
  history,
}) {
  const hideEmpty = data => {
    const labels = [t(terms.last24h), t(terms.last7d), t(terms.moreThan7d)];

    return data.map((date, index) => {
      return date.length === 0 ? null : `${labels[index]}: ${date.length}`;
    });
  };

  return (
    <div className="graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          <i className="fa fa-feed rotate--45" />
          <T id="tracar.lastSeenPie.lastSeen" defaultMessage="zuletzt online" />
        </h5>
      </div>

      <div className="tiny-spacer" />
      <Plot
        config={config}
        data={[
          {
            values: [day.length, week.length, older.length],
            type: "pie",
            text: hideEmpty([day, week, older]),
            textinfo: "text",
            textfont: { size: 16, color: Color.WildSand },
            sort: false,
            direction: "clockwise",
            hoverinfo: "none",
            marker: {
              colors: [Color.Red, Color.SilverChalice1, Color.Silver],
            },
            labels: [t(terms.last24h), t(terms.last7d), t(terms.moreThan7d)],
            customdata: ["online", "offline", "offline"],
          },
        ]}
        layout={{ ...plotLayoutCommons }}
        onClick={data => {
          history.push({
            pathname: `/${portal}/device-list`,
            state: {
              onlineFilter:
                data.points[0].customdata[0] === "online" ? "true" : "false",
            },
          });
        }}
      />
      <ul style={{ marginLeft: "18px", marginTop: "-28px" }}>
        <li>
          <i className="fa fa-square" style={{ color: Color.Red }} />
          &nbsp;&nbsp;
          <T
            id="tracar.lastSeenPie.last24h"
            defaultMessage="letzte 24 h: "
          />{" "}
          {day.length}
          &nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.SilverChalice1 }} />
          &nbsp;&nbsp;
          <T
            id="tracar.lastSeenPie.last7d"
            defaultMessage="letzte 7 d: "
          />{" "}
          {week.length}
          &nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.Silver }} />
          &nbsp;
          <T
            id="tracar.lastSeenPie.moreThan7dColon"
            defaultMessage="> 7 d: "
          />{" "}
          {older.length}
        </li>
      </ul>
    </div>
  );
}

export const LastSeenPie = injectIntl(LastSeenPieFn);
