import React from "react";
import { TrackerTableHead } from "./TrackerTableHead";
import { TrackerTableLine } from "./TrackerTableLine";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingCircle } from "../LoadingCircle";
import { noTagsPlaceholder } from "./TrackerListBase";
import { AssignTagsOverlay } from "../Tags/AssignTagsOverlay";

const terms = defineMessages({
  registeredDevices: {
    id: "tracar.device-list.registeredDevices",
    defaultMessage: "Registrierte Geräte:",
  },
});

class TrackerTableClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceToAssignTagsTo: null,
      showAssignTagsOverlay: false,
    };
  }

  toggleShowAssignTagsOverlay = deviceToAssignTagsTo => {
    this.setState({
      deviceToAssignTagsTo,
      showAssignTagsOverlay: !this.state.showAssignTagsOverlay,
    });
  };

  render() {
    const { devices, user } = this.props;
    const t = this.props.intl.formatMessage;
    return (
      <div className="table-frame">
        <TrackerTableHead
          headline={t(terms.registeredDevices)}
          iconClasses="fa fa-list"
          isSelectedAllDevices={this.props.isSelectedAllDevices}
          selectAllDevices={this.props.selectAllDevices}
        />
        {(devices == null || this.props.tagListLoadingState !== "ready") && (
          <LoadingCircle />
        )}
        {devices != null &&
          devices.length === 0 &&
          (this.props.tagListLoadingState === "ready" &&
          this.props.devicesExisting ? (
            <div className="table-line">
              <T
                id="tracar.device-list.noDevicesForCurrentFilter"
                defaultMessage="Es gibt keine Tracker für aktuelle Filteroptionen."
              />
            </div>
          ) : (
            <div className="table-line">
              <T
                id="tracar.device-list.noDevices"
                defaultMessage="Sie haben noch keine Tracker registriert."
              />
            </div>
          ))}
        {devices != null &&
          devices.map(device => {
            return (
              <TrackerTableLine
                toggleShowAssignTagsOverlay={this.toggleShowAssignTagsOverlay}
                tagList={this.props.tagList}
                key={device.imei}
                device={device}
                user={user}
                selectedDevicesImeiList={this.props.selectedDevicesImeiList}
                updateSelectedDevicesImeiList={
                  this.props.updateSelectedDevicesImeiList
                }
                updateSelectedDeviceImei={this.props.updateSelectedDeviceImei}
                selectedGeofences={this.props.selectedGeofences}
              />
            );
          })}
        {this.state.showAssignTagsOverlay &&
          this.state.deviceToAssignTagsTo && (
            <AssignTagsOverlay
              loadDevices={this.props.loadDevices}
              closeFunction={this.toggleShowAssignTagsOverlay}
              device={this.state.deviceToAssignTagsTo}
              fetchTags={this.props.fetchTags}
              setTagsAsSaving={this.props.setTagsAsSaving}
              tagList={this.props.tagList}
              tagListLoadingState={this.props.tagListLoadingState}
            />
          )}
      </div>
    );
  }
}

export const TrackerTable = injectIntl(TrackerTableClass);
