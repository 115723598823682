import React from "react";
import { FormattedMessage as T } from "react-intl";
import { DropdownItems, DropdownMenu } from "../../tools/DropdownMenu";
export function RoleLine({
  currentRecord,
  toggleEditOverlay,
  toggleAssignUserOverlay,
  toggleDeleteOverlay,
}) {
  /*eslint-disable */
  const { role_name } = currentRecord;
  /*eslint-enable */
  return (
    <div className="table-line grid-x grid-padding-x align-middle">
      <div className={"cell auto first-cell"}>
        <span className="name"> {role_name} </span>
      </div>
      <div className="cell small-12 large-1 medium-1 table-line-right align-middle align-right">
        <div className="button-container">
          <div
            className="button"
            onClick={() => toggleAssignUserOverlay(currentRecord)}
          >
            <i className="fa fa-user" />
            <span className="badge red">
              {currentRecord.number_of_users
                ? currentRecord.number_of_users
                : 0}
            </span>
          </div>
        </div>
        {!currentRecord.is_default && (
          <div className="button-container">
            <DropdownMenu>
              <DropdownItems>
                <li onClick={() => toggleEditOverlay(currentRecord)}>
                  <i className="fa fa-pencil" />
                  <T
                    id="adminPanel.roles.submenu.label.edit"
                    defaultMessage="Bearbeiten"
                  />
                </li>
                <li onClick={() => toggleDeleteOverlay(currentRecord)}>
                  <i className="fa fa-trash" />
                  <T
                    id="adminPanel.roles.submenu.label.delete"
                    defaultMessage="Löschen"
                  />
                </li>
              </DropdownItems>
            </DropdownMenu>
          </div>
        )}
        {currentRecord.is_default && (
          <div className="button-container">
            <div className="button" style={{ cursor: "default" }}>
              <i className="fa fa-cog inactive" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
