import React from "react";
import Plot from "react-plotly.js";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import moment from "moment";
import { FormattedMessage as T } from "react-intl";

export function DeviceRangeGraph({
  config,
  noAxisValues,
  range_data: { x, y },
  plotLayoutCommons,
}) {
  const formatted_x = x.map(val => moment(val).format("DD.MM.YYYY"));
  return (
    <div className="graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          {" "}
          <i className="fa fa-truck" />
          <T
            id="tracar.vehicleUsage.usage30d"
            defaultMessage="Gerätenutzung, letzte 30 Tage"
          />
        </h5>
      </div>
      <Plot
        config={{ displayModeBar: false }}
        data={[
          {
            x: formatted_x,
            y,
            name: "km",
            type: "bar",
            hoverinfo: "x+y+name",
            marker: { color: Color.Red },
          },
        ]}
        layout={{
          ...plotLayoutCommons,
          margin: plotLayoutCommons.margin,
          yaxis: { title: "km", tickformat: ",.0f," },
          xaxis: {
            ...noAxisValues,
            type: "category",
          },
        }}
      />
      <ul style={{ marginLeft: "68px", marginTop: "-28px" }}>
        <li>
          <T
            id="tracar.vehicleUsage.usage30d"
            defaultMessage="Gerätenutzung, letzte 30 Tage"
          />
        </li>
      </ul>
    </div>
  );
}
