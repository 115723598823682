import { postJson, deleteJson, contentJsonHeader, body } from "./fetchService";
import { saveAs } from "file-saver";

const poolVehicleRoute = "/admin_user/poolfahrzeuge";

export default function fetchPoolVehicleAdmin(jsonFetch, freeFetch) {
  const getPoolVehicleListApi = async (
    limit,
    offset,
    search_key,
    poolfahrzeug_status,
    order_by,
  ) => {
    return jsonFetch(
      poolVehicleRoute,
      postJson({ limit, offset, search_key, poolfahrzeug_status, order_by }),
    );
  };

  const getPoolVehicleListLocal = (limit, offset) =>
    new Promise((resolve, reject) =>
      resolve({
        poolvehicles: [
          {
            tags: [1],
            imei: "1011",
            name: "Test11",
            description: "Nice",
            status: "Occupied",
            alowed_route_type: 1,
            current_use: {
              driver: {
                email: "test_masteruser@leoworx.com",
                first_name: "Master ",
                last_name: "User",
                id: 3,
              },
            },
          },
        ],
        tags: {
          tag_id_list: [2, 1],
          tag_objects: {
            "1": {
              tag_name: "BMW",
              tag_additional_info: "so much info",
              tag_color: "#E60000",
            },
            "2": {
              tag_name: "Audi",
              tag_additional_info: "",
              tag_color: "#E60000",
            },
          },
        },
        pagination: {
          total_amount: 1,
          offset: 0,
          limit: 5,
          new_startpoint: null,
          sent_amount: 1,
        },
      }),
    );

  const getPoolVehicleList = getPoolVehicleListApi;

  const getPoolVehicleDashboard = async (from, to) => {
    return jsonFetch(
      `${poolVehicleRoute}/statistics?format=json&from=${from}&to=${to}`,
    );
  };

  const getDriverOptionsForDevice = async imei => {
    return jsonFetch(`${poolVehicleRoute}/check_in/drivers/${imei}`);
  };

  const getOrphanRouteContext = async routeId => {
    return jsonFetch(`${poolVehicleRoute}/orphan_routes/${routeId}/context`);
  };

  const checkUserInToDevice = async (user_id, imei, route_is_private) => {
    return jsonFetch(
      `${poolVehicleRoute}/check_in/${imei}`,
      postJson({ user_id: parseInt(user_id), private: route_is_private }),
    );
  };

  const checkUserOutOfDevice = async (user_id, imei) => {
    return jsonFetch(
      `${poolVehicleRoute}/check_out/${imei}`,
      postJson({ user_id: parseInt(user_id) }),
    );
  };

  const getPoolVehiclesForMap = async () => {
    return jsonFetch(`${poolVehicleRoute}/map`);
  };

  const getPoolVehicleMapHistory = async imei => {
    return jsonFetch(`${poolVehicleRoute}/map/${imei}`);
  };

  const getPoolVehiclesMapHistory = async (type, date) => {
    if (type === 3) {
      return jsonFetch(
        `${poolVehicleRoute}/map`,
        postJson({ type, from: date.from, to: date.to }),
      );
    }

    return jsonFetch(`${poolVehicleRoute}/map`, postJson({ type }));
  };

  const getPoolVehicleFahrtenbuchAdmin = (imei, bodyData) =>
    jsonFetch(`/admin_user/poolfahrzeuge/fahrtenbuch/${imei}`, {
      method: "POST",
      ...contentJsonHeader(),
      ...body(bodyData),
    });

  const assignOrphanRoute = (fahrtenbuch_id, user_id) =>
    jsonFetch(
      `/admin_user/poolfahrzeuge/orphan-routes/${fahrtenbuch_id}/adoption`,
      {
        method: "POST",
        ...contentJsonHeader(),
        ...body({ user_id: parseInt(user_id) }),
      },
    );

  const getPoolVehicleFahrtenbuchForUser = (from, to, id) =>
    jsonFetch(
      `/admin_user/poolfahrzeuge/routes?user_id=${id}&from=${from}&to=${to}`,
      {
        method: "GET",
        ...contentJsonHeader(),
      },
    );

  const generatePoolVehicleFahrtenbuchPdfForImei = async (
    from,
    to,
    imei,
    carId,
  ) => {
    let filename = "fahrtenbuch-" + from.toString() + "-" + to.toString();

    return freeFetch(`/admin_user/poolfahrzeuge/fahrtenbuch/pdf/${imei}`, {
      ...postJson({ from: from, to: to, car_id: carId }),
      responseType: "blob",
    })
      .then(response => {
        for (let [header, content] of response.headers.entries()) {
          if (header === "content-disposition") {
            filename = content.match(/"(.*?)"/)[1];
          }
        }

        return response.blob();
      })
      .then(blob => saveAs(blob, filename));
  };

  return {
    getPoolVehiclesForMap,
    getPoolVehicleMapHistory,
    getPoolVehiclesMapHistory,
    getPoolVehicleList,
    getDriverOptionsForDevice,
    checkUserInToDevice,
    checkUserOutOfDevice,
    getPoolVehicleFahrtenbuchAdmin,
    assignOrphanRoute,
    getPoolVehicleFahrtenbuchForUser,
    generatePoolVehicleFahrtenbuchPdfForImei,
    getOrphanRouteContext,
    getPoolVehicleDashboard,
  };
}
