import { postJson, putJson } from "./fetchService";
import { GeofenceType } from "../Geofence/GeofenceType";
import polyline from "@mapbox/polyline";

const route = "/user/geofences";
const filterRoute = "/user/v2/devices/";

export const fetchGeofences = jsonFetch => ({
  getGeofencingList() {
    return jsonFetch(route).then(geofencingList => {
      geofencingList = geofencingList.map(geofenceObject => {
        if (geofenceObject.fence_type === GeofenceType.Polygon) {
          geofenceObject.data = polyline
            .decode(geofenceObject.data)
            .map(point => ({ lat: point[0], lng: point[1] }));
        }
        return geofenceObject;
      });

      return geofencingList;
    });
  },

  /**
   * @param {Boolean} hasGeofence true: gets all devices with active geofences | false: gets all devices without any geofences
   * @param {[]} numberOfGeofences [Integer]; if provided then the devices with assigned geofence with same or greater than the number is returned
   * @param {[]} geofences [<geofence_id>,<geofence_id>]; if provided then devices with these geofence_ids are responded .It performs an OR operation.
   * @param {string} category current cutegory(portal)
   */
  filterGeofencingList(hasGeofence, numberOfGeofences, geofences, category) {
    let payload = {};
    if (hasGeofence != null) {
      payload = { ...payload, has_geofence: hasGeofence };
    }
    if (numberOfGeofences != null) {
      payload = { ...payload, number_of_geofence: numberOfGeofences };
    }
    if (geofences != null) {
      payload = { ...payload, geofences: geofences };
    }

    return jsonFetch(filterRoute + category, postJson(payload));
  },

  saveGeofence(geofenceObject) {
    /// encode polygones to polyline
    /// TODO ponder mutating the argument
    if (geofenceObject.fence_type === GeofenceType.Polygon) {
      const data = JSON.parse(geofenceObject.data).map(
        point => new window.google.maps.LatLng(point.lat, point.lng),
      );
      geofenceObject.data = window.google.maps.geometry.encoding.encodePath(
        data,
      );
    }
    if (geofenceObject.geofence_id) {
      /// geofenceObjects with an id have to be saved via PUT
      return jsonFetch(
        `${route}/${geofenceObject.geofence_id}`,
        putJson(geofenceObject),
      );
    } else {
      /// geofenceObjects without an id have to be created via POST
      return jsonFetch(route, postJson(geofenceObject));
    }
  },

  deleteGeofence(geofenceId) {
    return jsonFetch(`${route}/${geofenceId}`, {
      method: "DELETE",
    });
  },
});
