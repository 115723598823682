import React from "react";
import { withRouter } from "react-router-dom";
import { PoolVehicleFahrtenbuchEntry } from "./FahrtenbuchEntry";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CustomWeekPickerInput } from "../../../CustomWeekPickerInput";
import { FormattedMessage as T } from "react-intl";
import { LoadingSmallCircle } from "../../../LoadingCircle";

import { defaultLocale } from "../../../Language";

import { Portal } from "../../../Portal";
import { AccClientConfig } from "../../../ClientSpecificContent/js/vodafone-client-config";

import { FahrtenbuchOnDemanStatus } from "./FahrtenbuchBase";

class PoolVehicleFahrtenbuchClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.location.state,
    };
  }

  render() {
    const user = this.props.username ? this.props.username.user : undefined;
    const usernameFirstLastConcat = user
      ? `${user.first_name} ${user.last_name}`
      : "";
    const username = user
      ? usernameFirstLastConcat.length <= 1
        ? user.email
        : usernameFirstLastConcat
      : "";
    return (
      <div className="outer-table-frame">
        <div className="button-frame">
          <div className="button-block">
            <div className="flex">
              <DatePicker
                calendarClassName="week-picker"
                customInput={
                  <CustomWeekPickerInput
                    selectedDate={this.props.selectedDate}
                  />
                }
                dateFormat={this.props.dateFormat}
                dayClassName={date => {
                  if (
                    date.isAfter(this.props.selectedDate.from) &&
                    date.isBefore(this.props.selectedDate.to)
                  ) {
                    return "react-datepicker__day--selected";
                  }
                }}
                locale={moment().locale() || defaultLocale}
                maxDate={moment().endOf("isoWeek")}
                onChange={this.props.handleDateChange}
                selected={this.props.selectedDate.from}
                showMonthDropdown
                withPortal
              />
              <div
                className={`button ${
                  this.props.onDemandFinishStatus ? "blue" : "disabled-full"
                }`}
                onClick={
                  this.props.onDemandFinishStatus
                    ? () => this.props.fetchFahrtenbuch(this.props.userId)
                    : () => {}
                }
              >
                {!this.props.onDemandFinishStatus ? (
                  <div className="button-icon">
                    <LoadingSmallCircle />
                    &nbsp;
                  </div>
                ) : (
                  <i />
                )}
                <T
                  id="tracar.fahrtenbuchondemand.updateData"
                  defaultMessage="Routen aktualisieren"
                />
              </div>
            </div>
          </div>
          <div className="h5 bold flex-container align-middle">
            {username.length > 0 && <div>{username}</div>}
            {this.props.onDemandFinishState !== "" && (
              <div>
                {this.props.onDemandFinishState ===
                  FahrtenbuchOnDemanStatus.GENERATING ||
                this.props.onDemandFinishState ===
                  FahrtenbuchOnDemanStatus.PENDING ? (
                  <b className="info-text">
                    <T
                      id="tracar.fahrtenbuchondemand.generating"
                      defaultMessage="Die Fahrtenbuchdaten werden aktualisiert. Dies kann mehrere Minuten dauern."
                    />
                  </b>
                ) : this.props.onDemandFinishState ===
                  FahrtenbuchOnDemanStatus.SUCCESS ? (
                  <div className="info-text">
                    <i className="icon-ok" style={{ color: "green" }} />
                    &nbsp;
                    <b>
                      <T
                        id="tracar.fahrtenbuchondemand.success"
                        defaultMessage="Fahrtenbuch erfolgreich aktualisiert."
                      />
                    </b>
                  </div>
                ) : this.props.onDemandFinishState ===
                  FahrtenbuchOnDemanStatus.FAILURE ? (
                  <div className="info-text">
                    <i className="icon-remove" style={{ color: "red" }} />
                    &nbsp;
                    <b>
                      <T
                        id="tracar.fahrtenbuchondemand.failure"
                        defaultMessage="Die Fahrtenbuchdaten konnten nicht aktualisiertwerden."
                      />
                    </b>
                  </div>
                ) : (
                  <div className="info-text">
                    status: {this.props.onDemandFinishState}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="table-frame">
          {/*<div className="tracker-table">*/}
          {/* TODO consolidate post-demo*/}
          <div className="table-header">
            <i className="fa fa-bars" />
            <strong>
              {this.props.trackerDetail && this.props.trackerDetail.name}
            </strong>{" "}
            <small>
              {this.props.trackerDetail && this.props.trackerDetail.description}
            </small>
          </div>
          <div className="table-line fahrtenbuch table-header">
            <div className="fahrtenbuch-data top">
              <div className="date strong">
                <T id="tracar.fahrtenbuch.date" defaultMessage="Datum" />
              </div>
              <div className="vehicle strong">
                <T
                  id="portal.poolVehicles.list.column.header.vehicle"
                  defaultMessage="Fahrzeug"
                />
              </div>
              <div className="time strong">
                <T id="tracar.fahrtenbuch.time" defaultMessage="Uhrzeit" />
              </div>
              <div className="km-delta strong">
                <T id="tracar.fahrtenbuch.driven" defaultMessage="gefahren" />
              </div>
              <div className="km-total strong">
                {this.props.portal === Portal.Vehicle &&
                AccClientConfig.vehicleIsBoat ? (
                  <T id="base.nauticalMiles" defaultMessage="Seemeilen" />
                ) : (
                  <T
                    id="tracar.fahrtenbuch.kmTotal"
                    defaultMessage="km gesamt"
                  />
                )}
              </div>
              <div className="type strong">
                <T id="tracar.fahrtenbuch.type" defaultMessage="Typ" />
              </div>
              <div className="strong">
                <T id="tracar.fahrtenbuch.route" defaultMessage="Route" />
              </div>
            </div>
          </div>
          {this.props.fahrtenbuchData.map((entry, index) => {
            return (
              <PoolVehicleFahrtenbuchEntry
                editFahrtenbuchEntry={this.props.editFahrtenbuchEntry}
                entry={entry}
                successor={this.props.fahrtenbuchData[index + 1]}
                isLast={index === this.props.fahrtenbuchData.length - 1}
                key={entry.fahrtenbuch_id}
                reload={this.props.reload}
                suggestCustomer={this.props.suggestCustomer}
                originatedFromSplit={this.originatedFromSplit(entry)}
              />
            );
          })}
          {this.props.fahrtenbuchData.length === 0 && (
            <div className="fahrtenbuch-line">
              <div className="fahrtenbuch-data">
                <strong>
                  &nbsp;&nbsp;&nbsp;
                  <T
                    id="tracar.fahrtenbuch.noData"
                    defaultMessage="Für die ausgewählte Woche liegen keine Fahrtenbucheinträge vor."
                  />
                </strong>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  originatedFromSplit = entry => {
    if (!this.state.splitRoutes) {
      return false;
    }

    return (
      this.state.splitRoutes.fahrtenbuch_id_left === entry.fahrtenbuch_id ||
      this.state.splitRoutes.fahrtenbuch_id_right === entry.fahrtenbuch_id
    );
  };
}

export const PoolVehicleFahrtenbuch = withRouter(PoolVehicleFahrtenbuchClass);
