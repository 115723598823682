import React from "react";
import Link from "react-router-dom/es/Link";
import { FormattedMessage as T } from "react-intl";

export function SafetyZonesList({ safety_zones, portal, imei }) {
  return (
    <div className="graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          <i className="fa fa-globe" />
          <T
            id="tracar.map.overlay.safetyZones"
            defaultMessage="Sicherheitszonen"
          />
        </h5>
      </div>
      <div className="scrollable-list">
        <div className="tiny-spacer" />
        <div className="dashboard-message-list">
          {safety_zones &&
            safety_zones.map((safetyZoneName, index) => {
              return (
                <Link
                  key={index}
                  className="dashboard-message link"
                  to={{
                    pathname: `/${portal}/device-list/settings/${imei}`,
                    state: {
                      selectedDeviceImei: imei,
                      selectedSettingsTab: "geofence_settings",
                    },
                  }}
                >
                  {safetyZoneName}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}
