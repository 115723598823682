import React from "react";
import { Fahrtenbuch } from "./Fahrtenbuch";
import moment from "moment";
import { FahrtenbuchExport } from "./FahrtenbuchExport";

import { getWeekFromDate } from "../utility";

export const FahrtenbuchOnDemanStatus = {
  GENERATING: "GENERATING",
  STARTED: "STARTED",
  PENDING: "PENDING",
  RETRY: "RETRY",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export class FahrtenbuchBase extends React.Component {
  handleDateChange;
  reload;
  toggleExportOverlay;

  constructor(props) {
    super(props);

    let onDemandUuid;
    let onDemandImei;

    /** make sure that a misformed save does not ruin the app by causing a crash
     * in the constructor */
    try {
      if (localStorage.getItem("onDemandUuid")) {
        onDemandUuid = JSON.parse(localStorage.getItem("onDemandUuid"));
      } else {
        onDemandUuid = "";
      }
    } catch (e) {
      console.error(e);
      onDemandUuid = "";
    }

    /** make sure that a misformed save does not ruin the app by causing a crash
     * in the constructor */
    try {
      if (localStorage.getItem("onDemandImei")) {
        onDemandImei = JSON.parse(localStorage.getItem("onDemandImei"));
      } else {
        onDemandImei = "";
      }
    } catch (e) {
      console.error(e);
      onDemandImei = "";
    }

    if (onDemandImei === "") {
      onDemandImei = this.props.match.params.imei;
    }
    this.state = {
      fahrtenbuchData: [],
      selectedDate: getWeekFromDate(moment()),
      trackerDetail: null,
      showExportOverlay: false,
      car: {},
      cars: [],
      onDemandFinishStatus: onDemandUuid === "" ? true : false,
      onDemandFinishState: "",
      onDemandUuid,
      onDemandImei,
    };
    this.reload = this.reload.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleExportOverlay = this.toggleExportOverlay.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.generateFahrtenbuch = this.generateFahrtenbuch.bind(this);
    this.getGenerateFahrtenbuchStatus = this.fetchGenerateFahrtenbuchStatus.bind(
      this,
    );
  }

  handleDateChange(date) {
    this.setState({ selectedDate: getWeekFromDate(date) }, () => this.load());
  }

  handleSelect = event => {
    const id = parseInt(event.target.value);
    this.setCar(id);
  };

  reload() {
    this.load();
  }

  load() {
    this.fetchGenerateFahrtenbuchStatus();

    this.getData();
  }

  getData = () => {
    this.props.carsRequest(this.props.match.params.imei).then(res => {
      this.setState({ cars: res });
      if (res.length > 0) {
        if (this.state.car && this.state.car.id) this.setCar(this.state.car.id);
        else this.setCar();
      }
    });

    this.props.getTrackerDetail(this.props.match.params.imei).then(res => {
      this.setState({ trackerDetail: res });
    });
  };

  setCar = async (id, callback) => {
    if (id) {
      await this.setState(prevState => {
        let car = { ...prevState.car };
        car.id = id;
        car.car_number = this.state.cars.filter(
          fcar => fcar.fahrtenbuch_cardetails_id === car.id,
        )[0].car_number;
        car.model = this.state.cars.filter(
          fcar => fcar.fahrtenbuch_cardetails_id === car.id,
        )[0].model;
        return { car };
      });
    } else if (this.props.location.state && this.props.location.state.car_id) {
      await this.setState(prevState => {
        let car = { ...prevState.car };
        car.id = this.props.location.state.car_id;
        car.car_number = this.state.cars.filter(
          fcar => fcar.fahrtenbuch_cardetails_id === car.id,
        )[0].car_number;
        car.model = this.state.cars.filter(
          fcar => fcar.fahrtenbuch_cardetails_id === car.id,
        )[0].model;
        return { car };
      });
    } else {
      await this.setState(prevState => {
        let car = { ...prevState.car };
        car.id = this.state.cars.filter(
          fcar => fcar.current_car === true,
        )[0].fahrtenbuch_cardetails_id;
        car.car_number = this.state.cars.filter(
          fcar => fcar.current_car === true,
        )[0].car_number;
        car.model = this.state.cars.filter(
          fcar => fcar.current_car === true,
        )[0].model;
        return { car };
      });
    }
    this.fetchFahrtenbuch();
  };

  fetchFahrtenbuch() {
    let body = {
      from: this.state.selectedDate.from
        .clone()
        .utc()
        .format("YYYY-MM-DD\\THH:mm:ss"),
      to: this.state.selectedDate.to
        .clone()
        .utc()
        .format("YYYY-MM-DD\\THH:mm:ss"),
    };

    //only add car_id to the ajax body if available
    if (Object.keys(this.state.car).length > 0) body.car_id = this.state.car.id;

    this.props
      .getFahrtenbuch(this.props.match.params.imei, body)
      .then(res => {
        this.setState({ fahrtenbuchData: res });

        return res;
      })
      .catch(err => console.warn("Error caught ", err));
  }

  toggleExportOverlay() {
    this.setState({ showExportOverlay: !this.state.showExportOverlay });
  }

  generateFahrtenbuch() {
    this.props.fetchFahrtenbuch
      .onDemand(this.props.match.params.imei)
      .then(res => {
        localStorage.setItem("onDemandUuid", JSON.stringify(res.task_id));
        localStorage.setItem(
          "onDemandImei",
          JSON.stringify(this.props.match.params.imei),
        );
        this.setState(
          {
            onDemandUuid: res.task_id,
            onDemandFinishStatus: false,
            onDemandFinishState: FahrtenbuchOnDemanStatus.GENERATING,
          },
          this.fetchGenerateFahrtenbuchStatus,
        );
      });
  }

  fetchGenerateFahrtenbuchStatus() {
    if (this.state.onDemandUuid !== "") {
      this.props.fetchFahrtenbuch
        .onDemandStatus(this.state.onDemandUuid)
        .then(res => {
          if (res.finish) {
            localStorage.setItem("onDemandUuid", JSON.stringify(""));
            this.setState(
              {
                onDemandFinishStatus: res.finish,
                onDemandFinishState: res.state,
              },
              this.getData,
            );
          } else {
            setTimeout(() => {
              this.fetchGenerateFahrtenbuchStatus();
            }, 5000);
          }
        });
    }
  }

  componentWillMount() {
    this.load();
  }

  render() {
    const portal = this.props.match.params.portal || "";
    return (
      <div>
        <Fahrtenbuch
          editFahrtenbuchEntry={this.props.editFahrtenbuchEntry}
          fahrtenbuchData={this.state.fahrtenbuchData}
          handleDateChange={this.handleDateChange}
          mergeRoutes={this.props.mergeRoutes}
          reload={this.reload}
          selectedDate={this.state.selectedDate}
          suggestCustomer={this.props.suggestCustomer}
          toggleExportOverlay={this.toggleExportOverlay}
          trackerDetail={this.state.trackerDetail}
          portal={portal}
          car={this.state.car}
          cars={this.state.cars}
          handleSelect={this.handleSelect}
          generateFahrtenbuch={this.generateFahrtenbuch}
          onDemandFinishStatus={this.state.onDemandFinishStatus}
          onDemandFinishState={this.state.onDemandFinishState}
          onDemandImei={this.state.onDemandImei}
        />
        {this.state.showExportOverlay && (
          <FahrtenbuchExport
            closeFunction={this.toggleExportOverlay}
            generateFahrtenbuchPdf={this.props.generateFahrtenbuchPdf}
            generateFahrtenbuchCsv={this.props.generateFahrtenbuchCsv}
            generateCsv={this.props.generateCsv}
            imei={this.props.match.params.imei}
            car={this.state.car}
          />
        )}
      </div>
    );
  }
}
