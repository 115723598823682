import React from "react";
import { DeviceSafetyZoneDetail } from "./DeviceSafetyZoneDetail";
import { exchangeLetters } from "../utility";
import { getDeviceSafetyZoneDefault } from "./deviceSafetyZoneDefault";
import moment from "moment";
import { deviceSafetyZoneIsWholeDay } from "./deviceSafetyZoneIsWholeDay";
import { LoadingCircle } from "../LoadingCircle";
import { getWeekdayList } from "./weekdays";
import { injectIntl } from "react-intl";

function parseTimeFromHHMMSSString(hhmmss) {
  const timeStrings = hhmmss.split(":");
  return moment().set({
    hour: parseInt(timeStrings[0], 10),
    minute: parseInt(timeStrings[1], 10),
    second: parseInt(timeStrings[2], 10),
  });
}

class DeviceSafetyZoneDetailBaseClass extends React.Component {
  saveData;
  handleSelect;

  weekdayList;

  imei;
  safetyZoneId;

  constructor(props) {
    super(props);
    this.weekdayList = getWeekdayList(props.intl.formatMessage);
    this.state = {
      deviceSafetyZone: null,
      geofencingList: [],
      loadingState: "loading",
      trackerDetail: null,
      portal: props.match.params.portal || "",
    };
    this.safetyZoneId =
      props.match.params.safetyZoneId === "new"
        ? "new"
        : parseInt(props.match.params.safetyZoneId, 10);
    this.imei = props.match.params.imei || "";
    this.saveData = this.saveData.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillMount() {
    const deviceSafetyZonePromise =
      this.safetyZoneId === "new"
        ? getDeviceSafetyZoneDefault()
        : this.props.deviceSafetyZoneRequest(this.safetyZoneId);

    Promise.all([
      deviceSafetyZonePromise,
      this.props.getGeofencingList(),
      this.props.getTrackerDetail(this.imei),
    ]).then(([loadedDeviceSafetyZone, geofencingList, trackerDetail]) => {
      /// TODO refactor away from string | Moment | null types
      const deviceSafetyZone = loadedDeviceSafetyZone;
      /// data saved as 011100 was returned by the API as 11100
      /// thus leading 0's may have to be added:
      deviceSafetyZone.day_frequency = (
        "0000000" + deviceSafetyZone.day_frequency
      ).slice(-7);
      if (this.safetyZoneId === "new") {
        const firstSafetyZone =
          geofencingList.length && geofencingList[0].geofence_id;
        deviceSafetyZone.geofence_id = firstSafetyZone;
      }
      /// whole day safety zone
      if (deviceSafetyZoneIsWholeDay(deviceSafetyZone)) {
        delete deviceSafetyZone.start_time;
        delete deviceSafetyZone.end_time;
      } else {
        /// time-dependent time zone
        /// TODO oO rewriting a variable with type change? Not happy. Unhappy.
        deviceSafetyZone.start_time = parseTimeFromHHMMSSString(
          deviceSafetyZone.start_time,
        );
        deviceSafetyZone.end_time = parseTimeFromHHMMSSString(
          deviceSafetyZone.end_time,
        );

        // const endStrings = deviceSafetyZone.end_time.split(":");
        // deviceSafetyZone.end_time = moment().set({
        //   hour: endStrings[0],
        //   minute: endStrings[1],
        //   second: startStrings[2],
        // });
      }

      this.setState({
        deviceSafetyZone,
        geofencingList,
        loadingState: "loaded",
        trackerDetail,
      });
    });
  }

  setAsLeaveAlarm = leave_alert => () => {
    const deviceSafetyZone = { ...this.state.deviceSafetyZone, leave_alert };
    this.setState({ deviceSafetyZone });
  };

  setWeekday = weekdayIndex => () => {
    const weekdayState =
      this.state.deviceSafetyZone &&
      this.state.deviceSafetyZone.day_frequency[weekdayIndex] === "1"
        ? "0"
        : "1";
    const day_frequency =
      (this.state.deviceSafetyZone &&
        exchangeLetters(
          this.state.deviceSafetyZone.day_frequency,
          weekdayState,
          weekdayIndex,
        )) ||
      "0000000";
    const deviceSafetyZone = { ...this.state.deviceSafetyZone, day_frequency };
    this.setState({ deviceSafetyZone });
  };

  handleSelect(e) {
    const geofence_id = parseInt(e.target.value, 10);
    const deviceSafetyZone = { ...this.state.deviceSafetyZone, geofence_id };
    this.setState({ deviceSafetyZone });
  }

  /**
   *
   * @param e
   * | { start_time: Moment }
   * | { end_time: Moment }
   * | "whole day"
   * | "time-dependent"
   */
  changeHours = e => {
    if (e === "whole day") {
      // noinspection JSUnusedLocalSymbols
      const {
        start_time,
        end_time,
        ...deviceSafetyZone
      } = this.state.deviceSafetyZone;
      this.setState({ deviceSafetyZone });
      return;
    }
    /// change only if it is not time-dependent already
    if (
      e === "time-dependent" &&
      this.state.deviceSafetyZone &&
      this.state.deviceSafetyZone.start_time == null
    ) {
      const start_time = moment().set({
        hour: 8,
        minute: 0,
        second: 0,
      });
      const end_time = moment().set({
        hour: 18,
        minute: 0,
        second: 0,
      });

      const deviceSafetyZone = {
        ...this.state.deviceSafetyZone,
        start_time,
        end_time,
      };
      this.setState({ deviceSafetyZone });
      return;
    }
    if (typeof e === "object") {
      const deviceSafetyZone = { ...this.state.deviceSafetyZone, ...e };
      this.setState({ deviceSafetyZone }, () => {
        if (this.state.deviceSafetyZone != null) {
          let { start_time, end_time } = this.state.deviceSafetyZone;
          if (end_time && start_time && end_time.isBefore(start_time)) {
            const time = e.end_time || e.start_time;
            end_time = time.clone();
            start_time = time.clone();

            const deviceSafetyZone = {
              ...this.state.deviceSafetyZone,
              start_time,
              end_time,
            };
            this.setState({ deviceSafetyZone });
          }
        }
      });
    }
  };

  saveData() {
    this.setState({ loadingState: "saving" });
    if (this.state.deviceSafetyZone != null) {
      const deviceSafetyZone = stringifyHourIntervalsInDeviceSafetyZone(
        this.state.deviceSafetyZone,
      );
      if (this.safetyZoneId === "new") {
        this.props
          .getDeviceSafetyZones(this.imei, deviceSafetyZone)
          .then(res => this.setState({ loadingState: "saved" }));
      } else {
        this.props
          .deviceSafetyZoneRequest(this.safetyZoneId, deviceSafetyZone)
          .then(res => this.setState({ loadingState: "saved" }));
      }
    }
  }

  render() {
    if (this.state.deviceSafetyZone != null) {
      return (
        <DeviceSafetyZoneDetail
          {...this.state}
          weekdayList={this.weekdayList}
          changeHours={this.changeHours}
          handleSelect={this.handleSelect}
          imei={this.imei}
          saveData={this.saveData}
          setAsLeaveAlarm={this.setAsLeaveAlarm}
          setWeekday={this.setWeekday}
        />
      );
    }
    return (
      <div className="form">
        <LoadingCircle />
      </div>
    );
  }
}

function stringifyHourIntervalsInDeviceSafetyZone(zone) {
  const start_time = zone.start_time && zone.start_time.format("HH:mm:ss");
  const end_time = zone.end_time && zone.end_time.format("HH:mm:ss");
  return { ...zone, end_time, start_time };
}

export const DeviceSafetyZoneDetailBase = injectIntl(
  DeviceSafetyZoneDetailBaseClass,
);
