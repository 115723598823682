import React from "react";
import { Route, Switch } from "react-router";
import { FahrtenbuchBase } from "./Fahrtenbuch/FahrtenbuchBase.jsx";
import { TrackerListBase } from "./TrackerList/TrackerListBase";
import { MapBase } from "./Map/MapBase";
import { RouteMap } from "./Map/RouteMap";
import { GeofencingBase } from "./Geofence/GeofencingBase";
import { GeofencingListBase } from "./Geofence/GeofencingListBase";
import { UserBase } from "./User/UserBase";
import { TrackerDetailBase } from "./TrackerDetail/TrackerDetailBase";
import { DeviceSafetyZoneListBase } from "./DeviceSafetyZone/DeviceSafetyZoneListBase";
import { DeviceSafetyZoneDetailBase } from "./DeviceSafetyZone/DeviceSafetyZoneDetailBase";
import { TrackerStatisticsBase } from "./TrackerStatistics/TrackerStatisticsBase";
import { Faq } from "./Faq";
import { WiresBase } from "./Wires/WiresBase";
import { MessagesBase } from "./Messages/MessagesBase";
import { DashboardBase } from "./Dashboard/DashboardBase";
import { Portal, PortalDefault } from "./Portal";
import { CustomerAddressesBase } from "./CustomerAddresses/CustomerAddressesBase";
import { CustomerAddressesEditAdd } from "./CustomerAddresses/CustomerAddressesEditAdd";
import { RfidChipListBase } from "./RfidChipList/RfidChipListBase";
import { RfidableListBase } from "./RfidableList/RfidableListBase";
import { ReportsBase } from "./Reports/ReportsBase";
import { AdminPanelListBase } from "./AdminPanel/ListBase";
import { AdminpanelSettingsBase } from "./AdminPanel/Device/Settings/AdminpanelSettingsBase";
import { ServiceIntervalBase } from "./ServiceInterval/ServiceIntervalBase";
import SmartInventoryTaskTemplates from "./AdminPanel/SmartInventory/TaskTemplates/SmartInventoryTaskTemplates";
import { UserConsumer } from "./Base";
import { hasPermission, hasPortalAccess, Permission } from "./Permission";
import { AdminPanelPoolVehicleFahrtenbuchBase } from "./AdminPanel/PoolVehicle/FahrtenbuchBase";
import { PoolVehicleUserFahrtenbuchBase } from "./AdminPanel/User/Fahrtenbuch/FahrtenbuchBase";
import { MessageBase } from "./GlobalMessages/MessageBase";
import { PoolVehicleBase } from "./PoolVehicle/PoolVehicleBase";
import { PoolVehicleFahrtenbuchBase } from "./PoolVehicle/FahrtenbuchBase";
import { AdminPanelPoolVehicleMapBase } from "./AdminPanel/PoolVehicle/MapBase";
import { AdminPanelPoolVehicleDashboardBase } from "./AdminPanel/PoolVehicle/PoolVehicleDashboardBase";
import { PoolVehicleMapBase } from "./PoolVehicle/MapBase";

export class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const portal =
      (this.props.match && this.props.match.params.portal) || "no portal given";
    const { fetchService } = this.props;
    return (
      <UserConsumer>
        {user => (
          <div>
            <Switch>
              {hasPermission(user, Permission.UserDeviceManagement) && (
                <Route
                  path="/admin-panel/pool-vehicle/fahrtenbuch/:imei"
                  render={({ match }) => (
                    <AdminPanelPoolVehicleFahrtenbuchBase
                      editFahrtenbuchEntry={fetchService.editFahrtenbuchEntry}
                      generateCsv={fetchService.generateCsv}
                      generatePoolVehicleFahrtenbuchPdfForImei={
                        fetchService.fetchAdminPanelList
                          .generatePoolVehicleFahrtenbuchPdfForImei
                      }
                      getFahrtenbuch={
                        fetchService.getPoolVehicleFahrtenbuchAdmin
                      }
                      fetchFahrtenbuch={fetchService.fetchFahrtenbuch}
                      mergeRoutes={fetchService.mergeRoutes}
                      getTrackerDetail={fetchService.getTrackerDetail}
                      suggestCustomer={fetchService.suggestCustomer}
                      match={match}
                      location={this.props.location}
                      history={this.props.history}
                      carsRequest={fetchService.getCars}
                      getDriverOptionsForDevice={
                        fetchService.fetchAdminPanelList
                          .getDriverOptionsForDevice
                      }
                      getOrphanRouteContext={
                        fetchService.fetchAdminPanelList.getOrphanRouteContext
                      }
                      assignOrphanRoute={
                        fetchService.fetchAdminPanelList.assignOrphanRoute
                      }
                    />
                  )}
                />
              )}
              {
                <Route
                  path="/admin-panel/poolVehicle/map"
                  render={({ match, location }) => (
                    <AdminPanelPoolVehicleMapBase
                      key={portal}
                      isEmpty={false}
                      match={match}
                      location={location}
                      getPoolVehicleMapHistory={
                        fetchService.getPoolVehicleMapHistory
                      }
                      getPoolVehiclesForMap={fetchService.getPoolVehiclesForMap}
                      getLocationForSingleTracker={
                        fetchService.getLocationForSingleTracker
                      }
                      getPoolVehiclesMapHistory={
                        fetchService.getPoolVehiclesMapHistory
                      }
                      fetchAddress={fetchService.fetchAddress}
                    />
                  )}
                />
              }
              {
                <Route
                  path="/admin-panel/poolVehicle/dashboard"
                  render={({ match, location }) => (
                    <AdminPanelPoolVehicleDashboardBase
                      key={portal}
                      isEmpty={false}
                      match={match}
                      location={location}
                      getPoolVehicleDashboard={
                        fetchService.fetchAdminPanelList.getPoolVehicleDashboard
                      }
                    />
                  )}
                />
              }
              {(hasPortalAccess(user, Portal.AdminPanelUser) ||
                hasPortalAccess(user, Portal.AdminPanelRole) ||
                hasPortalAccess(user, Portal.AdminPanelDevice) ||
                hasPortalAccess(user, Portal.AdminPanelSmartInventory) ||
                hasPortalAccess(user, Portal.AdminPanelUserGroup)) && (
                <Route
                  exact
                  path="/admin-panel/:portal/list"
                  render={({ match }) => (
                    <AdminPanelListBase
                      match={match}
                      fetchList={fetchService.fetchAdminPanelList}
                      fetchListExpenses={fetchService.expensesAdmin}
                      fetchTags={fetchService.fetchTags}
                      fetchService={fetchService}
                    />
                  )}
                />
              )}
              {hasPortalAccess(user, Portal.AdminPanelSmartInventory) && (
                <Route
                  exact
                  path="/admin-panel/:portal/task-templates"
                  render={({ match }) => (
                    <SmartInventoryTaskTemplates
                      match={match}
                      fetchList={fetchService.fetchAdminPanelList}
                      fetchTags={fetchService.fetchTags}
                      fetchService={fetchService}
                    />
                  )}
                />
              )}
              {/* TEST */}
              <Route
                exact
                path="/admin-panel/:portal/messages/all"
                render={({ match }) => (
                  <MessageBase
                    //TEST
                    messageTypeFilter={fetchService.messageTypeFilter}
                    messageListQueryNew={fetchService.messageListQueryNew}
                    markMessagesAsReadNew={fetchService.markMessagesAsReadNew}
                    deleteMessagesNew={fetchService.deleteMessagesNew}
                    //TEST
                    messageListQuery={fetchService.messageListQuery}
                    markMessagesAsRead={fetchService.markMessagesAsRead}
                    deleteMessages={fetchService.deleteMessages}
                    match={match}
                  />
                )}
              />
              <Route
                exact
                path="/admin-panel/:portal/messages/:imei"
                render={({ match }) => (
                  <MessagesBase
                    //TEST
                    messageTypeFilter={fetchService.messageTypeFilter}
                    messageListQueryNew={fetchService.messageListQueryNew}
                    markMessagesAsReadNew={fetchService.markMessagesAsReadNew}
                    deleteMessagesNew={fetchService.deleteMessagesNew}
                    //TEST
                    messageListQuery={fetchService.messageListQuery}
                    markMessagesAsRead={fetchService.markMessagesAsRead}
                    deleteMessages={fetchService.deleteMessages}
                    match={match}
                  />
                )}
              />
              {/* TEST */}
              {(hasPortalAccess(user, Portal.AdminPanelUser) ||
                hasPortalAccess(user, Portal.AdminPanelRole) ||
                hasPortalAccess(user, Portal.AdminPanelDevice) ||
                hasPortalAccess(user, Portal.AdminPanelUserGroup)) && (
                <Route
                  exact
                  path="/admin-panel/user/:id/fahrtenbuch"
                  render={({ match }) => (
                    <PoolVehicleUserFahrtenbuchBase
                      match={match}
                      getPoolVehicleFahrtenbuchForUser={
                        fetchService.fetchAdminPanelList
                          .getPoolVehicleFahrtenbuchForUser
                      }
                      getUserList={fetchService.getUserList}
                    />
                  )}
                />
              )}
              {/* If the user has no portal or devices he is redirected to default and shown an empty map */}
              <Route
                path={`/${PortalDefault}/map`}
                render={({ match, location }) => (
                  <MapBase
                    key={portal}
                    isEmpty={true}
                    match={match}
                    location={location}
                    getSingleTrackerLocationHistory={
                      fetchService.getSingleTrackerLocationHistory
                    }
                    getTrackerList={fetchService.getTrackerList}
                    getLocationForSingleTracker={
                      fetchService.getLocationForSingleTracker
                    }
                    getCompleteLocationHistory={
                      fetchService.getCompleteLocationHistory
                    }
                    fetchAddress={fetchService.fetchAddress}
                  />
                )}
              />
              {hasPermission(user, Permission.Dashboard) && (
                <Route
                  path="/:portal/dashboard"
                  render={({ match, history }) => (
                    <DashboardBase
                      key={match.params.portal}
                      match={match}
                      history={history}
                      fetchDashboard={fetchService.fetchDashboard}
                      getServiceIntervals={fetchService.getServiceIntervals}
                      setIntervalCompletion={fetchService.setIntervalCompletion}
                    />
                  )}
                />
              )}
              <Route
                path="/:portal/reports"
                render={({ match }) => (
                  <ReportsBase
                    key={match.params.portal}
                    match={match}
                    fetchReports={fetchService.fetchReports}
                  />
                )}
              />
              {hasPermission(user, Permission.Location) && (
                <Route
                  path="/poolVehicle/map/:imei?"
                  render={({ match, location }) => (
                    <PoolVehicleMapBase
                      key={portal}
                      match={match}
                      location={location}
                      getPoolVehicleMapHistory={
                        fetchService.getPoolVehiclesPortalMapHistory
                      }
                      getPoolVehiclesForMap={
                        fetchService.getPoolVehiclesForPortalMap
                      }
                      getLocationForSingleTracker={
                        fetchService.getLocationForSingleTracker
                      }
                      getPoolVehiclesMapHistory={
                        fetchService.getPoolVehiclesPortalMapHistory
                      }
                      fetchAddress={fetchService.fetchAddress}
                      getPaginatedCustomerAddresses={
                        fetchService.getPaginatedCustomerAddresses
                      }
                      availablePortals={this.props.user.availablePortals}
                      messageListQuery={fetchService.messageListQuery}
                    />
                  )}
                />
              )}
              <Route
                path="/:portal/map/:imei?"
                render={({ match, location }) => (
                  <MapBase
                    key={portal}
                    match={match}
                    location={location}
                    getSingleTrackerLocationHistory={
                      fetchService.getSingleTrackerLocationHistory
                    }
                    getTrackerList={fetchService.getTrackerList}
                    getLocationForSingleTracker={
                      fetchService.getLocationForSingleTracker
                    }
                    getCompleteLocationHistory={
                      fetchService.getCompleteLocationHistory
                    }
                    fetchAddress={fetchService.fetchAddress}
                    getPaginatedCustomerAddresses={
                      fetchService.getPaginatedCustomerAddresses
                    }
                    availablePortals={this.props.user.availablePortals}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/geofencing/"
                render={ContextRouter => (
                  <GeofencingListBase
                    {...ContextRouter}
                    getGeofencingList={fetchService.getGeofencingList}
                    deleteGeofence={fetchService.deleteGeofence}
                    getLocationForSingleTracker={
                      fetchService.getLocationForSingleTracker
                    }
                  />
                )}
              />
              <Route
                path="/:portal/geofencing/:geofenceId"
                render={({ match }) => (
                  <GeofencingBase
                    match={match}
                    getGeofencingList={fetchService.getGeofencingList}
                    saveGeofence={fetchService.saveGeofence}
                    userLocation={this.props.userLocation}
                    getLocationForSingleTracker={
                      fetchService.getLocationForSingleTracker
                    }
                    getPaginatedCustomerAddresses={
                      fetchService.getPaginatedCustomerAddresses
                    }
                    fetchAddress={fetchService.fetchAddress}
                  />
                )}
              />
              {hasPortalAccess(user, Portal.PoolVehicle) &&
                hasPermission(user, Permission.DeviceList) && (
                  <Route
                    path="/poolVehicle/device-list"
                    render={({ match, location }) => (
                      <PoolVehicleBase
                        key={portal}
                        match={match}
                        location={location}
                        portal={Portal.PoolVehicle}
                        fetchService={fetchService}
                        getPoolVehicles={fetchService.getPoolVehicles}
                        fetchTags={fetchService.fetchTags}
                        fetchGeofences={fetchService.fetchGeofences}
                        user={this.props.user}
                        fetchDeviceSettings={fetchService.fetchDeviceSettings}
                        carsRequest={fetchService.getCars}
                        addNewCar={fetchService.addNewCar}
                        generateWiresCsv={fetchService.generateWiresCsv}
                        getGeofenceList={fetchService.getGeofencingList}
                        getDeviceSafetyZones={fetchService.getDeviceSafetyZones}
                        deviceSafetyZoneRequest={
                          fetchService.deviceSafetyZoneRequest
                        }
                        deleteDeviceSafetyZone={
                          fetchService.deleteDeviceSafetyZone
                        }
                        checkInToDevice={fetchService.checkInToDevice}
                        checkOutOfDevice={fetchService.checkOutOfDevice}
                        getCurrentPoolVehicle={
                          fetchService.getCurrentPoolVehicle
                        }
                        fetchListExpenses={fetchService.expensesPortal}
                      />
                    )}
                    //exact
                  />
                )}
              {hasPermission(user, Permission.DeviceList) && (
                <Route
                  path="/poolVehicle/routes"
                  render={({ match }) => (
                    <PoolVehicleFahrtenbuchBase
                      editFahrtenbuchEntry={fetchService.editFahrtenbuchEntry}
                      generatePoolVehicleFahrtenbuchPdf={
                        fetchService.generatePoolVehicleFahrtenbuchPdf
                      }
                      getPoolVehiclesFahrtenbookAcrossVehicles={
                        fetchService.getPoolVehiclesFahrtenbookAcrossVehicles
                      }
                      suggestCustomer={fetchService.suggestCustomer}
                      match={match}
                      location={this.props.location}
                      history={this.props.history}
                    />
                  )}
                />
              )}
              {hasPermission(user, Permission.DeviceList) && (
                <Route
                  path="/:portal/device-list"
                  render={({ match, location }) => (
                    <TrackerListBase
                      key={portal}
                      match={match}
                      location={location}
                      portal={match.params.portal || "tracar"}
                      fetchService={fetchService}
                      getUserDevices={fetchService.getUserDevices}
                      fetchTags={fetchService.fetchTags}
                      fetchGeofences={fetchService.fetchGeofences}
                      user={this.props.user}
                      fetchDeviceSettings={fetchService.fetchDeviceSettings}
                      carsRequest={fetchService.getCars}
                      addNewCar={fetchService.addNewCar}
                      generateWiresCsv={fetchService.generateWiresCsv}
                      getGeofenceList={fetchService.getGeofencingList}
                      getDeviceSafetyZones={fetchService.getDeviceSafetyZones}
                      deviceSafetyZoneRequest={
                        fetchService.deviceSafetyZoneRequest
                      }
                      deleteDeviceSafetyZone={
                        fetchService.deleteDeviceSafetyZone
                      }
                    />
                  )}
                  //exact
                />
              )}
              <Route
                path="/:portal/serviceIntervals"
                render={({ match, location }) => (
                  <ServiceIntervalBase
                    getPaginatedCustomerAddresses={
                      fetchService.getPaginatedCustomerAddresses
                    }
                    deleteServiceInterval={fetchService.deleteServiceInterval}
                    uploadCustomerAddresses={
                      fetchService.uploadCustomerAddresses
                    }
                    getServiceIntervals={fetchService.getServiceIntervals}
                    createServiceInterval={fetchService.createServiceInterval}
                    createMultipleServiceIntervals={
                      fetchService.createMultipleServiceIntervals
                    }
                    getEnumTypes={fetchService.getEnumTypes}
                    fetchDevices={
                      fetchService.fetchAdminPanelList.getDeviceList
                    }
                    setIntervalCompletion={fetchService.setIntervalCompletion}
                    portal={portal}
                  />
                )}
                //exact
              />
              <Route
                path="/:portal/rfid-chip-list"
                render={({ match }) => (
                  <RfidChipListBase
                    key={portal}
                    match={match}
                    fetchRfid={fetchService.fetchRfid}
                    user={this.props.user}
                  />
                )}
                exact
              />
              <Route
                path="/:portal/rfid-chip-list/rfidable-list"
                render={({ match }) => (
                  <RfidableListBase
                    key={portal}
                    match={match}
                    fetchRfid={fetchService.fetchRfid}
                    user={this.props.user}
                  />
                )}
              />
              <Route
                path="/:portal/fahrtenbuch/:imei/map/:route"
                render={({ match }) => (
                  <RouteMap
                    key={portal}
                    match={match}
                    getRouteData={fetchService.getRouteData}
                    splitRoute={fetchService.splitRoute}
                    fetchAddress={fetchService.fetchAddress}
                  />
                )}
              />
              <Route
                path="/:portal/fahrtenbuch/:imei"
                render={({ match }) => (
                  <FahrtenbuchBase
                    editFahrtenbuchEntry={fetchService.editFahrtenbuchEntry}
                    generateCsv={fetchService.generateCsv}
                    generateFahrtenbuchPdf={fetchService.generateFahrtenbuchPdf}
                    generateFahrtenbuchCsv={fetchService.generateFahrtenbuchCsv}
                    getFahrtenbuch={fetchService.getFahrtenbuch}
                    fetchFahrtenbuch={fetchService.fetchFahrtenbuch}
                    mergeRoutes={fetchService.mergeRoutes}
                    getTrackerDetail={fetchService.getTrackerDetail}
                    suggestCustomer={fetchService.suggestCustomer}
                    match={match}
                    location={this.props.location}
                    history={this.props.history}
                    carsRequest={fetchService.getCars}
                  />
                )}
              />
              <Route
                path="/admin-panel/device/settings/:imei"
                render={({ match, location }) => (
                  <AdminpanelSettingsBase
                    match={match}
                    user={this.props.user}
                    getUserDevices={fetchService.getUserDevices}
                    fetchDeviceSettings={fetchService.fetchDeviceSettings}
                    fetchList={fetchService.fetchAdminPanelList}
                    getGeofenceList={fetchService.getGeofencingList}
                    location={location}
                    portal={match.params.portal || "tracar"}
                    fetchService={fetchService}
                    fetchTags={fetchService.fetchTags}
                    fetchGeofences={fetchService.fetchGeofences}
                    carsRequest={fetchService.getCars}
                    addNewCar={fetchService.addNewCar}
                    generateWiresCsv={fetchService.generateWiresCsv}
                    getDeviceSafetyZones={fetchService.getDeviceSafetyZones}
                    deviceSafetyZoneRequest={
                      fetchService.deviceSafetyZoneRequest
                    }
                    deleteDeviceSafetyZone={fetchService.deleteDeviceSafetyZone}
                  />
                )}
              />
              <Route
                path="/:portal/device/:imei"
                render={({ match }) => (
                  <TrackerDetailBase
                    assignUserToDevice={fetchService.assignUserToDevice}
                    changeOdometer={fetchService.changeOdometer}
                    deviceEmailListRequest={fetchService.deviceEmailListRequest}
                    getNonCompanyUserList={fetchService.getNonCompanyUserList}
                    getTrackerDetail={fetchService.getTrackerDetail}
                    match={match}
                    maxSpeedRequest={fetchService.maxSpeedRequest}
                    getSos={fetchService.getSos}
                    setSos={fetchService.setSos}
                    fetchTrackingInterval={fetchService.fetchTrackingInterval}
                    user={this.props.user}
                    carsRequest={fetchService.getCars}
                    addNewCar={fetchService.addNewCar}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/user"
                render={({ match }) => (
                  <UserBase
                    match={match}
                    changePassword={fetchService.changePassword}
                    driversLicenseCheck={fetchService.driversLicenseCheck}
                    getUser={fetchService.getUser}
                    saveUser={fetchService.saveUser}
                    setUserProfileData={this.props.setUserProfileData}
                    userLoginData={this.props.user}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/device-safety-zone/:imei"
                render={({ match }) => (
                  <DeviceSafetyZoneListBase
                    match={match}
                    deleteDeviceSafetyZone={fetchService.deleteDeviceSafetyZone}
                    getDeviceSafetyZones={fetchService.getDeviceSafetyZones}
                    getTrackerDetail={fetchService.getTrackerDetail}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/device-safety-zone-detail/:imei/:safetyZoneId"
                render={({ match }) => (
                  <DeviceSafetyZoneDetailBase
                    match={match}
                    getDeviceSafetyZones={fetchService.getDeviceSafetyZones}
                    deviceSafetyZoneRequest={
                      fetchService.deviceSafetyZoneRequest
                    }
                    getGeofencingList={fetchService.getGeofencingList}
                    getTrackerDetail={fetchService.getTrackerDetail}
                  />
                )}
              />
              {hasPermission(user, Permission.Dashboard) && (
                <Route
                  exact
                  path="/:portal/statistics/:imei"
                  render={({ match }) => (
                    <TrackerStatisticsBase
                      fetchDashboard={fetchService.fetchDashboard}
                      getTrackerDetail={fetchService.getTrackerDetail}
                      requestStatistics={fetchService.requestStatistics}
                      match={match}
                      fetchAddress={fetchService.fetchAddress}
                      getDeviceServiceIntervals={
                        fetchService.getDeviceServiceIntervals
                      }
                      setIntervalCompletion={fetchService.setIntervalCompletion}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/:portal/wires/:imei"
                render={({ match }) => (
                  <WiresBase
                    wiresQuery={fetchService.wiresQuery}
                    generateWiresCsv={fetchService.generateWiresCsv}
                    match={match}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/messages/all"
                render={({ match }) => (
                  <MessageBase
                    //TEST
                    messageTypeFilter={fetchService.messageTypeFilter}
                    messageListQueryNew={fetchService.messageListQueryNew}
                    markMessagesAsReadNew={fetchService.markMessagesAsReadNew}
                    deleteMessagesNew={fetchService.deleteMessagesNew}
                    //TEST
                    messageListQuery={fetchService.messageListQuery}
                    markMessagesAsRead={fetchService.markMessagesAsRead}
                    deleteMessages={fetchService.deleteMessages}
                    match={match}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/messages/:imei"
                render={({ match }) => (
                  <MessagesBase
                    //TEST
                    messageTypeFilter={fetchService.messageTypeFilter}
                    messageListQueryNew={fetchService.messageListQueryNew}
                    markMessagesAsReadNew={fetchService.markMessagesAsReadNew}
                    deleteMessagesNew={fetchService.deleteMessagesNew}
                    //TEST
                    messageListQuery={fetchService.messageListQuery}
                    markMessagesAsRead={fetchService.markMessagesAsRead}
                    deleteMessages={fetchService.deleteMessages}
                    match={match}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/customerAddresses/list"
                render={({ match }) => (
                  <CustomerAddressesBase
                    getPaginatedCustomerAddresses={
                      fetchService.getPaginatedCustomerAddresses
                    }
                    deleteAddress={fetchService.deleteCustomerAddress}
                    uploadCustomerAddresses={
                      fetchService.uploadCustomerAddresses
                    }
                    match={match}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/customerAddresses/:customerAddressId"
                render={({ match }) => (
                  <CustomerAddressesEditAdd
                    addNewCustomerAddress={fetchService.addNewCustomerAddress}
                    editCustomerAddress={fetchService.editCustomerAddress}
                    loadAddress={fetchService.getCustomerAddress}
                    match={match}
                  />
                )}
              />
              <Route
                exact
                path="/:portal/faq"
                render={() => <Faq loadFaq={fetchService.fetchFaq} />}
              />
            </Switch>
          </div>
        )}
      </UserConsumer>
    );
  }
}
