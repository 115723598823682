import React from "react";
import { RequestOverlay } from "./Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

class ConfirmDeleteOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="confirm-customer-addresses-delete"
        enableCloseOnOverlayClicked={true}
      >
        {this.renderConfirmation()}
      </RequestOverlay>
    );
  }

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderConfirmation = () => {
    return (
      <div>
        {this.renderHeader()}
        {this.renderButtons()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="customer.addresses.confirmDelete.headline"
            defaultMessage="Möchten Sie den Eintrag wirklich löschen?"
            /// TODO this was well meant but does not work with translations
            // defaultMessage={`Möchten Sie ${this.props.label} wirklich löschen?`}
          />
        </h5>
      </div>
    );
  };

  /**
   * Renders the buttons
   * @return JSX
   */
  renderButtons = () => {
    return (
      <div className="confirm-button-container">
        <div className="spacer" />
        <div
          className="button"
          onClick={this.props.closeFunction}
          style={{ marginRight: "15px" }}
        >
          <span>
            <T
              id="customer.addresses.confirmDelete.buttonCancel"
              defaultMessage="Abbrechen"
            />
          </span>
        </div>

        <div className="button save-button" onClick={this.props.confirmDelete}>
          <span>
            <T
              id="customer.addresses.confirmDelete.buttonDelete"
              defaultMessage="Löschen"
            />
          </span>
        </div>
      </div>
    );
  };
}

export const ConfirmDeleteOverlay = injectIntl(ConfirmDeleteOverlayClass);
