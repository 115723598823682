import React from "react";
import { FormattedMessage as T, injectIntl } from "react-intl";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { SelectInput } from "../../SelectInput";
import { PoolVehicleAllowedRouteType } from "../../ApiContract";

class PoolVehicleAssignDriverOverlayClass extends React.Component {
  handleChange;
  submit;

  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showMore: false,
      selectedDriverId:
        this.props.device.current_use && this.props.device.current_use.driver
          ? this.props.device.current_use.driver.id
          : null,
      routeIsPrivate:
        this.props.device.current_use &&
        this.props.device.current_use.route_type
          ? this.props.device.current_use.route_type === "private"
          : false,
      driverList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props
      .getDriverOptionsForDevice(this.props.device.imei)
      .then(driverList => {
        if (
          this.props.device.current_use &&
          this.props.device.current_use.driver.id
        ) {
          driverList = [
            {
              id: this.props.device.current_use.driver.id,
              email: this.props.device.current_use.driver.email,
            },
            ...driverList,
          ];
        }
        this.setState({
          driverList: [{ id: 0, email: "Kein Fahrer" }, ...driverList],
        });
      });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  setRouteType(routeIsPrivate) {
    this.setState({ routeIsPrivate });
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  submit() {
    if (
      this.props.device.current_use &&
      this.state.selectedDriverId === this.props.device.current_use.driver.id
    ) {
      // no change in drivers => do nothing
      this.props.closeFunction();
      return;
    }

    if (this.state.selectedDriverId === "0" && !this.props.device.current_use) {
      // device started with no driver and ended with no driver => do nothing
      this.props.closeFunction();
      return;
    }
    if (this.state.selectedDriverId === "0") {
      // "no driver" is selected => check out current driver
      this.props
        .checkUserOutOfDevice(
          this.props.device.current_use.driver.id,
          this.props.device.imei,
        )
        .then(() => this.props.closeFunction());
    } else {
      // a driver was assigned before assigning the new one
      if (this.props.device.current_use) {
        // a driver is selected => checkout current driver => check in driver
        this.props
          .checkUserOutOfDevice(
            this.props.device.current_use.driver.id,
            this.props.device.imei,
          )
          .then(
            this.props.checkUserInToDevice(
              this.state.selectedDriverId,
              this.props.device.imei,
              this.props.device.allowed_route_type ==
                PoolVehicleAllowedRouteType.Private,
            ),
          )
          .then(() => this.props.closeFunction());
      } else {
        // driver is selected => assign new driver
        this.props
          .checkUserInToDevice(
            this.state.selectedDriverId,
            this.props.device.imei,
            this.props.device.allowed_route_type ==
              PoolVehicleAllowedRouteType.Private,
          )
          .then(() => this.props.closeFunction());
      }
    }
  }

  validate() {
    return true;
  }

  render() {
    return (
      <RequestOverlay
        className={this.state.requestOverlayClassName}
        closeFunction={this.props.closeFunction}
      >
        <div className="header">
          <h3>
            <T
              id="adminPanel.poolVehicles.assignDriver.headline"
              defaultMessage="Fahrer zuweisen"
            />{" "}
            <span className="margin-horizontal-1">></span>
            <span className="highlighted">{this.props.device.imei}</span>
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="grid-x grid-margin-x text-left">
          <h4 className="cell margin-left-1 margin-bottom-2">
            <T
              id="adminPanel.poolVehicles.assignDriver.label.selectDriver"
              defaultMessage="verfügbare Fahrer"
            />{" "}
            <span className="item-count">
              {/* Don't count the "no driver" option */}
              {this.state.driverList ? this.state.driverList.length - 1 : 0}
            </span>
          </h4>
          <div className="cell medium-3">
            <h4 className="margin-top-0 mb-7px">Aktuell gemeldeter Fahrer:</h4>
            <span>
              {this.props.device.current_use
                ? `${this.props.device.current_use.driver.first_name} ${this.props.device.current_use.driver.last_name}`
                : "-"}
            </span>
          </div>
          <div className="cell medium-4 margin-right-3">
            <h4 className="margin-bottom-0 margin-top-0">
              neuen Fahrer wählen:
            </h4>
            <React.Fragment>
              <div className="grid-container">
                <div className="grid-x grid-padding-x">
                  <div className="medium-auto cell relative">
                    <SelectInput
                      id="selectedDriverId"
                      // name={t(terms.user_role_label)}
                      value={this.state.selectedDriverId}
                      nameProp={"email"}
                      valueProp={"id"}
                      options={this.state.driverList}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="small-spacer" />
            </React.Fragment>
          </div>
          <div className="cell medium-3">
            <React.Fragment>
              <div className="chips">
                <h4 className="margin-top-0 mb-7px">
                  <T
                    id="adminPanel.poolVehicles.assignDriver.label.routeType"
                    defaultMessage="Art der Anmeldung"
                  />
                  <span className="margin-right-1">:</span>
                </h4>
                {this.props.device.allowed_route_type ==
                PoolVehicleAllowedRouteType.Private ? (
                  <T
                    id="adminPanel.poolVehicleList.label.routeType.private"
                    defaultMessage="Privat"
                  />
                ) : (
                  <T
                    id="adminPanel.poolVehicleList.label.routeType.public"
                    defaultMessage="Geschäftlich"
                  />
                )}
              </div>
            </React.Fragment>
          </div>
        </div>
        <React.Fragment>
          <div className="small-spacer" />
          <div className="footer text-right">
            <div
              className="button transparent"
              onClick={this.props.closeFunction}
            >
              <T
                id="adminPanel.shared.buttonLabel.cancel"
                defaultMessage="Abbrechen"
              />
            </div>
            <div className="button green primary" onClick={this.submit}>
              <T
                id="adminPanel.shared.buttonLabel.submit"
                defaultMessage="Zuweisung abschließen"
              />
            </div>
          </div>
        </React.Fragment>
      </RequestOverlay>
    );
  }
}

export const PoolVehicleAssignDriverOverlay = injectIntl(
  PoolVehicleAssignDriverOverlayClass,
);
