import React from "react";
import Link from "react-router-dom/es/Link";
import { FormattedMessage as T } from "react-intl";
import {Portal} from "../Portal";

export function DashboardMessageList({ messages, portal = Portal.Auto }) {
  return (
    <div className="graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          <i className="fa fa-envelope" />
          <T id="tracar.mostRecentMessages" defaultMessage="Letzte Meldungen" />
        </h5>
      </div>
      <div className="tiny-spacer" />
      <div className="dashboard-message-list">
        {messages.map(
          ({ message_subject, message_read, imei = "0000000" }, index) => {
            return (
              <Link
                key={index}
                className={`dashboard-message link`}
                style={message_read ? { fontWeight: "normal" } : {}}
                to={`/${portal}/messages/${imei}`}
              >
                {message_subject}
              </Link>
            );
          }
        )}
      </div>
    </div>
  );
}
