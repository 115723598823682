import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { getWeekFromDate } from "../../utility";
import moment from "moment";
import Plot from "react-plotly.js";
import { AccColor as Color } from "../../ClientSpecificContent/acc-index";
import { createRouteTypeRatiosPlotData } from "../../Dashboard/DashboardPoolvehicle";
import { uniq } from "lodash";
import { CustomWeekPickerInput } from "../../CustomWeekPickerInput";
import { defaultLocale } from "../../Language";
import DatePicker from "react-datepicker";
import { dateFormat } from "../../dateFormat";
//
import { CustomDatePickerInput } from "../../Map/Map";
//

const config = { displayModeBar: false };
const plotLayoutCommons = {
  separators: ",.",
  title: "",
  paper_bgcolor: "rgba(255,255,255,0)",
  plot_bgcolor: "rgba(255,255,255,0)",
  showlegend: false,
  width: 320,
  height: 210,
  margin: { t: 0, b: 25, l: 45, r: 15 },
};

const widePlotLayoutCommons = {
  ...plotLayoutCommons,
  width: 662,
};

const highWidePlotLayoutCommons = {
  ...widePlotLayoutCommons,
  height: 210 * 2,
};

const terms = defineMessages({
  quantity: {
    id: "tracar.Dashboard.quantity",
    defaultMessage: "Menge",
  },
});

const noAxisValues = {
  autorange: true,
  showgrid: false,
  zeroline: false,
  showline: false,
  autotick: true,
  ticks: "",
  showticklabels: false,
};

function createCostCategoryRatiosPerVehiclePlotData(data) {
  const costCategories = uniq(
    data.flatMap(x => Object.entries(x.ratios).map(([key, _]) => key)),
  );
  const vehicles = data.flatMap(x => x.name);
  const costCategoryForVehicle = [];
  for (const category of costCategories) {
    costCategoryForVehicle.push(
      data.map(x => (x.ratios[category] ? x.ratios[category] : "0")),
    );
  }
  return {
    costCategories,
    vehicles,
    costCategoryForVehicle,
  };
}

function createCostCategoryRatiosPlotData(data) {
  const costValues = [];
  const costLabels = [];
  const costColors = [];
  Object.entries(data).forEach(([key, value]) => {
    if (value != 0) {
      costValues.push(value);
      costLabels.push({
        id: `expense.category.${key}`,
        defaultValue: "Unbekannt",
      });
      costColors.push(Color.SilverChalice1);
    }
  });
  return { costValues, costLabels, costColors };
}

const colorList = [
  Color.RedBerry,
  Color.Limeade,
  //Color.Red,
  //Color.Olive,
  //Color.Supernova,
  //Color.WebOrange,
  //Color.White,
  //Color.WildSand,
  //Color.Gallery,
  //Color.Silver,
  //Color.DoveGrey,
  //Color.OuterSpace,
  //Color.SilverChalice1,
  Color.MineShaft,
  "#335145",
  "#48cae4",
  "#480ca8",
  "#ffd000",
  "#8E65EE",
  "#B2997B",
  //"#73CE55",
];

class AdminPanelPoolVehicleDashboardClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //
      endDate: moment(),
      startDate: moment(),
      //
      loading: true,
      stats: undefined,
      selectedDate: getWeekFromDate(moment()),
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    //
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    //
  }
  //
  lastWeek = [
    moment()
      .subtract(1, "weeks")
      .startOf("isoWeek"),
    moment()
      .subtract(1, "weeks")
      .endOf("isoWeek"),
  ];
  lastMonth = [
    moment()
      .subtract(1, "months")
      .startOf("month"),
    moment()
      .subtract(1, "months")
      .endOf("month"),
  ];
  lastQuarter = [
    moment()
      .subtract(1, "quarters")
      .startOf("quarter"),
    moment()
      .subtract(1, "quarters")
      .endOf("quarter"),
  ];

  //
  async componentDidMount() {
    this.load();
  }

  async load() {
    // const from = this.state.selectedDate.from
    const from = this.state.startDate
      .clone()
      .utc()
      .format("YYYY-MM-DD\\THH:mm:ss");
    // const to = this.state.selectedDate.to
    const to = this.state.endDate
      .clone()
      .utc()
      .format("YYYY-MM-DD\\THH:mm:ss");

    this.props.getPoolVehicleDashboard(from, to).then(stats => {
      this.setState({
        stats: stats,
        loading: false,
      });
    });
  }
  //
  handleChangeEnd = endDate => {
    if (endDate.isBefore(this.state.startDate)) {
      this.setState({ endDate, startDate: endDate.clone() });
      return;
    }
    this.setState({ endDate }, () => this.load());
  };

  handleChangeStart = startDate => {
    if (startDate.isAfter(this.state.endDate)) {
      this.setState({ startDate, endDate: startDate.clone() });
      return;
    }
    this.setState({ startDate }, () => this.load());
  };

  handleTimeSpan = (startDate, endDate) => () =>
    this.setState({ startDate, endDate }, () => this.load());

  compareTimeSpans = (startDate1, endDate1, startDate2, endDate2) => {
    return startDate1.isSame(startDate2) && endDate1.isSame(endDate2);
  };
  //
  handleDateChange(date) {
    this.setState({ selectedDate: getWeekFromDate(date) }, () => this.load());
  }

  renderGlobals() {
    return (
      <React.Fragment>
        <div className="graph-frame autoHeight">
          <div className="graph-headline">
            <h5>
              <T
                id="tracar.Dashboard.cost_per_km"
                defaultMessage="Allgemeine Daten"
              />
            </h5>
          </div>
          {this.state.stats && (
            <ul>
              <li>
                <T
                  id="tracar.Dashboard.fleet_size"
                  defaultMessage="Flottengröße"
                />{" "}
                {this.state.stats.fleet_size}
              </li>
              <li>
                <T
                  id="tracar.Dashboard.route_count"
                  defaultMessage="Anzahl Fahrten"
                />{" "}
                {this.state.stats.fuel_consumption.route_count}
              </li>
              <li>
                <T
                  id="tracar.Dashboard.total_cost"
                  defaultMessage="Gesamtkosten"
                />{" "}
                {this.state.stats.fuel_consumption.total_cost}
              </li>
              <li>
                <T
                  id="tracar.Dashboard.km_driven"
                  defaultMessage="Gefahrene km"
                />{" "}
                {this.state.stats.fuel_consumption.km_driven}
              </li>
            </ul>
          )}
        </div>
        <div className="wide-graph-frame autoHeight">
          <div className="graph-headline">
            <h5>
              <T
                id="tracar.Dashboard.cost_per_km"
                defaultMessage="Kosten pro km"
              />
            </h5>
          </div>
          {this.state.stats && (
            <ul>
              <li>
                <T id="tracar.Dashboard.total" defaultMessage="Gesamt" />{" "}
                {this.state.stats.cost_per_km.total}
              </li>
              <li>
                <T
                  id="tracar.Dashboard.fleet_average"
                  defaultMessage="Flottendurchschnitt"
                />{" "}
                {this.state.stats.cost_per_km.fleet_average}
              </li>
              <li>
                <T id="tracar.Dashboard.fuel" defaultMessage="Kraftstoff" />{" "}
                {this.state.stats.cost_per_km.fuel}
              </li>
            </ul>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderFuelConsumption() {
    const t = this.props.intl.formatMessage;

    return (
      <React.Fragment>
        <div className="graph-frame autoHeight">
          <div className="graph-headline">
            <h5>
              <T
                id="tracar.Dashboard.fuel_consumption"
                defaultMessage="Kraftstoffverbrauch"
              />
            </h5>
          </div>
          {this.state.stats && (
            <ul>
              <li>
                <T id="tracar.Dashboard.total" defaultMessage="Gesamt" />{" "}
                {this.state.stats.fuel_consumption.total}
              </li>
              <li>
                <T
                  id="tracar.Dashboard.fleet_average"
                  defaultMessage="Flottendurchschnitt"
                />{" "}
                {this.state.stats.fuel_consumption.fleet_average}
              </li>
            </ul>
          )}
        </div>
        <div className="wide-graph-frame autoHeight">
          <div className="graph-headline">
            <h5>
              <T
                id="tracar.Dashboard.fuel_consumption_per_vehicle"
                defaultMessage="Kraftstoffverbrauch pro Fahrzeug"
              />
            </h5>
          </div>
          <div className="tiny-spacer" />
          {this.state.stats &&
          this.state.stats.fuel_consumption_per_vehicle.length > 0 ? (
            <Plot
              config={config}
              data={[
                {
                  x: this.state.stats.fuel_consumption_per_vehicle.map(
                    x => x.name,
                  ),
                  y: this.state.stats.fuel_consumption_per_vehicle.map(
                    x => x.quantity,
                  ),
                  type: "bar",
                  text: "Liter",
                  textformat: "x+y+text",
                  textfont: { size: 16, color: Color.WildSand },
                  marker: {
                    colors: Color.Red,
                  },
                  outsidetextfont: { color: "transparent" },
                },
              ]}
              layout={{
                ...widePlotLayoutCommons,
                margin: widePlotLayoutCommons.margin,
                yaxis: { title: t(terms.quantity), tickformat: ",.1f," },
                xaxis: {
                  ...noAxisValues,
                  type: "category",
                },
              }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <T id="tracar.Dashboard.noData" defaultMessage="Keine Daten" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderRouteTypes() {
    const t = this.props.intl.formatMessage;
    const {
      routeValues,
      routeColors,
      routeLabels,
    } = createRouteTypeRatiosPlotData(
      this.state.stats && this.state.stats.route_type_ratios
        ? this.state.stats.route_type_ratios
        : {},
    );

    return (
      <React.Fragment>
        <div className="graph-frame pie-chart autoHeight">
          <div className="graph-headline pie-chart-headline">
            <h5>
              <T id="tracar.Dashboard.routeTypes" defaultMessage="Fahrttypen" />
            </h5>
          </div>
          <div className="tiny-spacer" />
          {routeValues.length > 0 ? (
            <Plot
              config={config}
              data={[
                {
                  values: routeValues,
                  type: "pie",
                  textinfo: "percent",
                  textfont: { size: 16, color: Color.WildSand },
                  sort: false,
                  direction: "clockwise",
                  hoverinfo: "none",
                  marker: {
                    colors: routeColors,
                  },
                  outsidetextfont: { color: "transparent" },
                },
              ]}
              layout={{ ...plotLayoutCommons, barmode: "stack" }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <T id="tracar.Dashboard.noData" defaultMessage="Keine Daten" />
            </div>
          )}
          {routeValues.length > 0 && (
            <ul className="pie-chart-legend legend-grid">
              {routeLabels.map((item, index) => (
                <li key={index}>
                  <span>
                    <i
                      className="fa fa-square"
                      style={{ color: routeColors[index] }}
                    />
                    &nbsp;&nbsp;
                    {routeValues[index] * 100}%{" "}
                  </span>

                  <T id={item.id} defaultMessage={item.defaultValue} />
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="wide-graph-frame autoHeight">
          <div className="graph-headline">
            <h5>
              <T
                id="tracar.Dashboard.route_type_ratios_per_vehicle"
                defaultMessage="Fahrttypen pro Fahrzeug"
              />
            </h5>
          </div>
          <div className="tiny-spacer" />
          {this.state.stats &&
          this.state.stats.route_type_ratios_per_vehicle.length > 0 &&
          routeValues.length > 0 ? (
            <Plot
              config={config}
              data={[
                {
                  x: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.name,
                  ),
                  y: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.ratios.private,
                  ),
                  type: "bar",
                  text: "Liter",
                  textformat: "x+y+text",
                  textfont: { size: 16, color: Color.WildSand },
                  marker: {
                    color: Color.Red,
                  },
                  outsidetextfont: { color: "transparent" },
                  name: t({
                    id: "tracar.dashboard.private",
                    defaultMessage: "Privat",
                  }),
                },
                {
                  x: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.name,
                  ),
                  y: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.ratios.commute,
                  ),
                  type: "bar",
                  text: "Liter",
                  textformat: "x+y+text",
                  textfont: { size: 16, color: Color.WildSand },
                  marker: {
                    color: Color.OuterSpace,
                  },
                  outsidetextfont: { color: "transparent" },
                  name: t({
                    id: "tracar.dashboard.commute",
                    defaultMessage: "Arbeitsweg",
                  }),
                },
                {
                  x: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.name,
                  ),
                  y: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.ratios.business,
                  ),
                  type: "bar",
                  text: "Liter",
                  textformat: "x+y+text",
                  textfont: { size: 16, color: Color.Silver },
                  marker: {
                    color: Color.Red,
                  },
                  outsidetextfont: { color: "transparent" },
                  name: t({
                    id: "tracar.dashboard.business",
                    defaultMessage: "Geschäftlich",
                  }),
                },
                {
                  x: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.name,
                  ),
                  y: this.state.stats.route_type_ratios_per_vehicle.map(
                    x => x.ratios.open,
                  ),
                  type: "bar",
                  text: "Liter",
                  textformat: "x+y+text",
                  textfont: { size: 16, color: Color.WildSand },
                  marker: {
                    color: Color.SilverChalice1,
                  },
                  outsidetextfont: { color: "transparent" },
                  name: t({
                    id: "tracar.dashboard.open",
                    defaultMessage: "Offen",
                  }),
                },
              ]}
              layout={{
                ...widePlotLayoutCommons,
                margin: widePlotLayoutCommons.margin,
                barmode: "stack",
              }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <T id="tracar.Dashboard.noData" defaultMessage="Keine Daten" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderCostCategories() {
    const {
      costCategories,
      vehicles,
      costCategoryForVehicle,
    } = createCostCategoryRatiosPerVehiclePlotData(
      this.state.stats && this.state.stats.cost_category_ratios_per_vehicle
        ? this.state.stats.cost_category_ratios_per_vehicle
        : [],
    );
    const {
      costValues,
      costLabels,
      costColors,
    } = createCostCategoryRatiosPlotData(
      this.state.stats && this.state.stats.cost_category_ratios
        ? this.state.stats.cost_category_ratios
        : {},
    );
    const t = this.props.intl.formatMessage;

    return (
      <React.Fragment>
        <div className="graph-frame pie-chart autoHeight">
          <div className="graph-headline pie-chart-headline">
            <h5>
              <T
                id="tracar.Dashboard.cost_category_ratios"
                defaultMessage="Kostenarten"
              />
            </h5>
          </div>
          <div className="tiny-spacer" />
          {costValues.length > 0 ? (
            <Plot
              config={config}
              data={[
                {
                  values: costValues,
                  type: "pie",
                  textinfo: "percent",
                  textfont: { size: 16, color: Color.WildSand },
                  sort: false,
                  direction: "clockwise",
                  hoverinfo: "none",
                  marker: {
                    colors: colorList,
                  },
                  outsidetextfont: { color: "transparent" },
                },
              ]}
              layout={{ ...plotLayoutCommons }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <T id="tracar.Dashboard.noData" defaultMessage="Keine Daten" />
            </div>
          )}
          {costValues.length > 0 && (
            <ul className="pie-chart-legend legend-grid">
              {costLabels.map((item, index) => (
                <li key={index}>
                  <span>
                    <i
                      className="fa fa-square"
                      style={{ color: colorList[index] }}
                    />
                    &nbsp;&nbsp;
                    {(costValues[index] * 100).toFixed(1)}%{" "}
                  </span>
                  <T id={item.id} defaultMessage={item.defaultValue} />
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="wide-graph-frame autoHeight">
          <div className="graph-headline">
            <h5>
              <T
                id="tracar.Dashboard.cost_category_ratios_per_vehicle"
                defaultMessage="Kostenarten pro Fahrzeug"
              />
            </h5>
          </div>
          <div className="tiny-spacer" />
          {this.state.stats &&
          this.state.stats.cost_category_ratios_per_vehicle.length > 0 &&
          costValues.length > 0 ? (
            <Plot
              config={config}
              data={costCategoryForVehicle.map((x, i) => {
                return {
                  x: vehicles,
                  y: x,
                  text: x.map(x =>
                    x == 0
                      ? null
                      : `${x * 100}% ` +
                        t({
                          id: `expense.category.${costCategories[i]}`,
                          defaultMessage: costCategories[i],
                        }),
                  ),
                  textformat: "y+text",
                  textfont: { size: 8, color: Color.WildSand },
                  hoverinfo: "text",
                  outsidetextfont: { color: "transparent" },
                  type: "bar",
                  name: t({
                    id: `expense.category.${costCategories[i]}`,
                    defaultMessage: costCategories[i],
                  }),
                  hoverlabel: {
                    namelength: 32,
                  },
                  //marker: {
                  //  color: costColors,
                  //},
                };
              })}
              layout={{
                ...highWidePlotLayoutCommons,
                margin: highWidePlotLayoutCommons.margin,
                barmode: "stack",
              }}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <T id="tracar.Dashboard.noData" defaultMessage="Keine Daten" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderStatisticContent() {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }
    return (
      <React.Fragment>
        {this.renderGlobals()}
        {this.renderFuelConsumption()}
        {this.renderRouteTypes()}
        {this.renderCostCategories()}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="outer-table-frame dashboard pool-vehicle reports">
        {/* <div className="outer-table-frame dashboard pool-vehicle"> */}
        {/* <DatePicker
          calendarClassName="week-picker"
          customInput={
            <CustomWeekPickerInput selectedDate={this.state.selectedDate} />
          }
          dateFormat={dateFormat}
          dayClassName={date => {
            if (
              date.isAfter(this.state.selectedDate.from) &&
              date.isBefore(this.state.selectedDate.to)
            ) {
              return "react-datepicker__day--selected";
            }
          }}
          locale={moment().locale() || defaultLocale}
          maxDate={moment().endOf("isoWeek")}
          onChange={this.handleDateChange}
          selected={this.state.selectedDate.from}
          showMonthDropdown
          withPortal
        /> */}
        {/* TEST */}
        <div className="button-frame">
          <div className="block">
            <div className="date-container">
              <div>
                <strong>
                  <T id="base.timeSpan" defaultMessage="Zeitraum:" />
                </strong>
              </div>
              <div>
                <T id="tracar.fahrtenbuch.from" defaultMessage="von:" />
              </div>
              <DatePicker
                className="button"
                customInput={<CustomDatePickerInput />}
                endDate={this.state.endDate}
                locale={moment().locale() || defaultLocale}
                onChange={this.handleChangeStart}
                popperPlacement="top-end"
                selected={this.state.startDate}
                selectsStart
                showMonthDropdown
                startDate={this.state.startDate}
                withPortal
              />
              <div>
                <T id="tracar.fahrtenbuch.to" defaultMessage="bis:" />
              </div>
              <DatePicker
                className=" button"
                customInput={<CustomDatePickerInput />}
                endDate={this.state.endDate}
                locale={moment().locale() || defaultLocale}
                onChange={this.handleChangeEnd}
                selected={this.state.endDate}
                selectsEnd
                showMonthDropdown
                startDate={this.state.startDate}
                withPortal
              />
            </div>
            <div
              className={
                "button" +
                (this.compareTimeSpans(
                  ...this.lastWeek,
                  this.state.startDate,
                  this.state.endDate,
                )
                  ? " active "
                  : "")
              }
              onClick={this.handleTimeSpan(...this.lastWeek)}
            >
              <T id="reports.lastWeek" defaultMessage="Letzte Woche" />
            </div>
            <div
              className={
                "button " +
                (this.compareTimeSpans(
                  ...this.lastMonth,
                  this.state.startDate,
                  this.state.endDate,
                )
                  ? " active "
                  : "")
              }
              onClick={this.handleTimeSpan(...this.lastMonth)}
            >
              <T id="reports.lastMonth" defaultMessage="Letzter Monat" />
            </div>
            <div
              className={
                "button " +
                (this.compareTimeSpans(
                  ...this.lastQuarter,
                  this.state.startDate,
                  this.state.endDate,
                )
                  ? " active "
                  : "")
              }
              onClick={this.handleTimeSpan(...this.lastQuarter)}
            >
              <T id="reports.lastQuarter" defaultMessage="Letztes Quartal" />
            </div>
          </div>
        </div>
        {/* TEST */}
        <div className="button-frame" />
        <div className="table-frame default-list">
          <div className="table-header">
            <i className="fa fa-tachometer" />
            <T id="base.dashboard" defaultMessage="Dashboard" />
          </div>
          <div className="all-graphs-outer-frame">
            <div className="all-graphs-frame">
              {this.renderStatisticContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };
}

export const AdminPanelPoolVehicleDashboard = injectIntl(
  AdminPanelPoolVehicleDashboardClass,
);
