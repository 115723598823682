import React from "react";
import Plotly from "../plotlyBundle";
import moment from "moment";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from "../Map/Map";
import { dateFormat } from "../dateFormat";
import { getAWholeDaysPeriod } from "../utility";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { defaultLocale } from "../Language";
import { AccClientConfig } from "../ClientSpecificContent/acc-index";

import { Portal } from "../Portal";

const terms = defineMessages({
  speed: {
    id: "tracar.statistics.speed",
    defaultMessage: "Geschwindigkeit",
  },
  pedal: {
    id: "tracar.statistics.pedal",
    defaultMessage: "Gaspedal-Stellung",
  },
  engine: {
    id: "tracar.statistics.engine",
    defaultMessage: "Motorlast",
  },
  rpm: {
    id: "tracar.statistics.rpm",
    defaultMessage: "Umdrehungszahl",
  },
  rpmUnit: {
    id: "tracar.statistics.rpmUnit",
    defaultMessage: "U / min",
  },
  battery: {
    id: "tracar.statistics.battery",
    defaultMessage: "Batterie",
  },
  voltage: {
    id: "tracar.statistics.voltage",
    defaultMessage: "Volt",
  },
  kmPerH: {
    id: "tracar.statistics.kmPerH",
    defaultMessage: "km / h",
  },
  knots: {
    id: "base.knots",
    defaultMessage: "Knoten",
  },
  kn: {
    id: "base.kn",
    defaultMessage: "kn",
  },
  temperature: {
    id: "tracar.statistics.temperature",
    defaultMessage: "Temperatur",
  },
  tilt: {
    id: "tracar.statistics.tilt",
    defaultMessage: "Neigung",
  },
  pressure: {
    id: "tracar.statistics.pressure",
    defaultMessage: "Luftdruck",
  },
  humidity: {
    id: "tracar.statistics.humidity",
    defaultMessage: "Feuchtigkeit",
  },
});

/*
displayedPlot =
  | "accelerator_pedal_position"
  | "engine_load"
  | "engine_rpm"
  | "main_power"
  | "module_voltage"
  | "speed"
  | "temperature"
  | "tilt"
  | "backup_power"
  | "empty";
*/

const getPlotInfo = (t, useNauticalMiles) => ({
  accelerator_pedal_position: {
    name: t(terms.pedal),
    unit: "%",
    hoverformat: ".0f",
  },
  engine_load: {
    name: t(terms.engine),
    unit: "%",
    hoverformat: ".0f",
  },
  engine_rpm: {
    name: t(terms.rpm),
    unit: t(terms.rpmUnit),
    hoverformat: ".0f",
  },
  main_power: {
    name: t(terms.battery),
    unit: t(terms.voltage),
    hoverformat: ".2f",
  },
  backup_power: {
    name: t(terms.battery),
    unit: t(terms.voltage),
    hoverformat: ".2f",
  },
  module_voltage: {
    name: t(terms.battery),
    unit: t(terms.voltage),
    hoverformat: ".2f",
  },
  speed: (() => {
    return useNauticalMiles
      ? {
          // name: t(terms.speed),
          // unit: t(terms.kmPerH),
          // hoverformat: ".0f",
          name: t(terms.knots),
          unit: t(terms.kn),
          hoverformat: ".0f",
        }
      : {
          name: t(terms.speed),
          unit: t(terms.kmPerH),
          hoverformat: ".0f",
        };
  })(),
  temperature: {
    name: t(terms.temperature),
    unit: "° C",
    hoverformat: ".0f",
  },
  tilt: {
    name: t(terms.tilt),
    unit: "°",
    hoverformat: ".0f",
  },
  pressure: {
    name: t(terms.pressure),
    unit: "",
    hoverformat: ".0f",
  },
  humidity: {
    name: t(terms.humidity),
    unit: "",
    hoverformat: ".0f",
  },
  empty: {
    name: "-",
    unit: "",
    hoverformat: "",
  },
});

class TrackerStatisticsClass extends React.Component {
  /// should be displayedPlot
  plotButtonList;
  resetZoom;
  setPlotType;
  /// should be { [displayedPlot]: plotInfoDetail }
  plotInfo;

  constructor(props) {
    super(props);

    const useNauticalMiles =
      this.props.portal === Portal.Vehicle && AccClientConfig.vehicleIsBoat;

    this.plotInfo = getPlotInfo(props.intl.formatMessage, useNauticalMiles);

    // get the keys of the traces to be displayed
    this.plotButtonList = ["empty"];
    for (const traceName in props.statistics.y) {
      // Check if the traceName is known, otherwise it might be a new value
      if (props.statistics.y.hasOwnProperty(traceName)) {
        this.plotButtonList.push(traceName);

        if (this.plotInfo[traceName] == null) {
          this.plotInfo[traceName] = {
            name: traceName,
            unit: "",
            color: "rgb(175, 170, 180)",
            hoverformat: ".2f",
          };

          console.error(
            `No statistics data for "${traceName} found.` +
              `Please define ${traceName} in the getPlotInfo function`,
          );
        }
      }
    }

    this.state = {
      displayedPlot1: this.plotButtonList[1] || "empty",
      displayedPlot2: this.plotButtonList[2] || "empty",
      plotIsShown: false,
      range: null,
      selectedDate: moment(),
    };

    this.resetZoom = this.resetZoom.bind(this);
    this.setPlotType = this.setPlotType.bind(this);
  }

  componentDidMount() {
    this.setPlot();
  }

  resetZoom() {
    this.setState({ range: null }, () => this.setPlot());
  }

  handleDatePickerChange = date => {
    this.setState(
      {
        selectedDate: date,
      },
      () =>
        this.props.load(getAWholeDaysPeriod(date)).then(() => {
          this.resetZoom();
          this.setPlot();
          this.forceUpdate();
        }),
    );
  };

  setPlotType(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value }, () => this.setPlot());
  }

  setPlot() {
    const setPlotConfiguration = traceName => {
      if (traceName === "empty") {
        return {
          x: [],
          y: [],
          info: this.plotInfo[this.state.displayedPlot1],
        };
      }
      const plotX = this.props.statistics.y[traceName].x_values;
      return {
        x: this.props.statistics.x[plotX],
        y: this.props.statistics.y[traceName].y_values,
        info: this.plotInfo[traceName],
      };
    };

    const plotOneConf = setPlotConfiguration(this.state.displayedPlot1);
    const plotTwoConf = setPlotConfiguration(this.state.displayedPlot2);

    const { plotIsShown, plotDiv } = plot(
      this.state.selectedDate,
      plotOneConf,
      plotTwoConf,
      "plot",
      this.state.range,
      this.props.portal,
    );

    this.setState({ plotIsShown });

    plotDiv &&
      plotDiv.on("plotly_relayout", eventdata => {
        this.setState({
          range: [eventdata["xaxis.range[0]"], eventdata["xaxis.range[1]"]],
        });
      });
  }

  render() {
    const { trackerDetail } = this.props;
    return (
      <div className="outer-table-frame">
        <div className="table-frame">
          <div className="table-header">
            <i className="fa fa-area-chart" />
            <T id="tracar.statistics.dataFor" defaultMessage="Daten für" />{" "}
            <strong>{trackerDetail.name}</strong>{" "}
            <small>{trackerDetail.description}</small>
          </div>
          <div className="grid-x">
            <div style={{ paddingLeft: "12px" }}>
              <DatePicker
                customInput={<CustomDatePickerInput />}
                dateFormat={dateFormat}
                locale={moment().locale() || defaultLocale}
                maxDate={moment()}
                onChange={this.handleDatePickerChange}
                popperPlacement="top"
                selected={this.state.selectedDate}
              />
            </div>
            {["displayedPlot1", "displayedPlot2"].map((plot, index) => (
              <div className="small-12 medium-12 large-12 cell" key={plot}>
                <div className="plot-select-frame">
                  <select
                    className="select"
                    id={plot}
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor:
                        index === 0 ? Color.Red : Color.SilverChalice,
                      paddingLeft: "15px",
                    }}
                    value={this.state[plot]}
                    onChange={this.setPlotType}
                  >
                    {this.plotButtonList.map(buttonName => (
                      <option value={buttonName} key={buttonName}>
                        {this.plotInfo[buttonName].name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
          <div className="plot-frame">
            {" "}
            {this.state.plotIsShown && (
              <div
                className={
                  "button unzoom" +
                  (this.state.range == null ? " disabled" : "")
                }
                onClick={this.resetZoom}
              >
                <i className="fa fa-arrows-alt" />
              </div>
            )}
            {this.state.range != null && this.state.plotIsShown && (
              <div className="arrow-frame">
                <i className="fa fa-arrows-h" />
              </div>
            )}
            {!this.state.plotIsShown && (
              <div className="table-header">
                <h5>
                  <T
                    id="tracar.statistics.noData"
                    defaultMessage={
                      "Für den ausgewählten Zeitraum und die" +
                      " ausgewählten Kategorien liegen keine Daten vor."
                    }
                  />
                </h5>
              </div>
            )}
            <div id="plot" />
          </div>
        </div>
      </div>
    );
  }
}

function plot(date, plotOneConf, plotTwoConf, divId, range, portal) {
  const plotDiv = document.getElementById(divId);

  /// plotly does not draw an appropriate time scale for missing data
  /// set a default for trace1
  const trace1XIsEmpty = plotOneConf.x.length === 0;
  const xTrace1 = trace1XIsEmpty
    ? [
        moment(date)
          .startOf("day")
          .format("YYYY-MM-DD\\THH:mm:ss"),
      ]
    : plotOneConf.x.map(tS => new Date(tS * 1000));
  let yTrace1 = trace1XIsEmpty ? [0] : plotOneConf.y || [];

  const useNauticalMiles =
    portal === Portal.Vehicle && AccClientConfig.vehicleIsBoat;

  yTrace1 = useNauticalMiles ? yTrace1.map(val => val / 1.85) : yTrace1;

  const trace1 = {
    x: xTrace1,
    y: yTrace1,
    // hoverinfo: "none",
    mode: "lines",
    line: {
      color: Color.Red,
      width: 2,
    },
    name: plotOneConf.info.unit,
  };

  const yTrace2 =
    useNauticalMiles && plotTwoConf.y
      ? plotTwoConf.y.map(val => val / 1.85)
      : plotTwoConf.y;
  const trace2 = {
    x: plotTwoConf.x.map(tS => new Date(tS * 1000)),
    y: yTrace2 || [],
    // hoverinfo: "none",
    mode: "lines",
    line: {
      color: Color.SilverChalice,
      width: 2,
    },
    name: plotTwoConf.info.unit,
    yaxis: "y2",
  };

  const hideAxis = {
    autotick: true,
    showgrid: false,
    showline: false,
    showticklabels: false,
    ticks: "",
    title: "",
    zeroline: false,
  };

  const hideYAxis1 =
    trace1.x.length < 2 || trace1.y.length == null || trace1.y.length === 0
      ? hideAxis
      : null;

  const hideYAxis2 =
    trace2.x.length === 0 || trace2.y.length == null || trace2.y.length === 0
      ? hideAxis
      : null;

  const hideXAxis = hideYAxis1 != null && hideYAxis2 != null ? hideAxis : null;
  const plotIsShown = hideYAxis1 == null || hideYAxis2 == null;

  const layout = {
    // exemplary implementation for the max_speed line
    //shapes,
    paper_bgcolor: "rgba(255,255,255,0)",
    plot_bgcolor: "rgba(255,255,255,0)",
    showlegend: false,
    title: "",
    margin: { t: 0, b: 40 },
    width: Math.min(window.innerWidth - 35, 1165),
    xaxis: {
      tickformat: "%H:%M",
      range: range || [
        moment(date)
          .startOf("day")
          .format("YYYY-MM-DD\\THH:mm:ss"),
        moment(date)
          .endOf("day")
          .format("YYYY-MM-DD\\THH:mm:ss"),
      ],
      ...hideXAxis,
    },
    yaxis: {
      title: plotOneConf.info.unit,
      fixedrange: true,
      titlefont: {
        color: Color.Red,
      },
      tickfont: {
        color: Color.Red,
      },
      hoverformat: plotOneConf.info.hoverformat,
      ...hideYAxis1,
    },
    yaxis2: {
      fixedrange: true,
      title: plotTwoConf.info.unit,
      titlefont: {
        color: Color.SilverChalice1,
      },
      tickfont: {
        color: Color.SilverChalice1,
      },
      overlaying: "y",
      side: "right",
      hoverformat: plotTwoConf.info.hoverformat,
      ...hideYAxis2,
    },
  };

  const config = { displayModeBar: false };

  let data = [trace1, trace2 /*, trace3*/];

  Plotly.newPlot(divId, data, layout, config);

  /* eslint-disable */
  /// Example for a max speed line
  // noinspection JSUnusedLocalSymbols
  const __trace3 = {
    x: [
      moment(date)
        .startOf("day")
        .format("YYYY-MM-DD\\THH:mm:ss"),
      moment(date)
        .endOf("day")
        .format("YYYY-MM-DD\\THH:mm:ss"),
    ],
    y: [90, 90],
    opacity: 0.5,
    yaxis: "y",
    line: {
      opacity: 0.5,
      color: "rgb(240, 134, 27)",
      width: 2,
    },
  };
  // noinspection JSUnusedLocalSymbols
  const __shapes = plotIsShown
    ? [
        {
          type: "line",
          yaxis: "y2",
          x0: 1000000000000,
          y0: 90,
          x1: 2000000000000,
          y1: 90,
          opacity: 0.5,
          line: {
            yaxis: "y2",
            color: "rgb(240, 134, 27)",
            width: 2,
            opacity: 0.5,
          },
        },
      ]
    : [];
  /* eslint-enable*/

  return { plotIsShown, plotDiv };
}

export const TrackerStatistics = injectIntl(TrackerStatisticsClass);
