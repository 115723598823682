import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingCircle } from "../LoadingCircle";
import { RequestOverlay } from "../Request/RequestOverlay";
import { TextInput } from "../TextInput";

const terms = defineMessages({
  ph1: {
    id: "person.device.sosPhoneNumber1",
    defaultMessage: "Notfall-Telefonnummer 1",
  },
  ph2: {
    id: "person.device.sosPhoneNumber2",
    defaultMessage: "Notfall-Telefonnummer 2",
  },
  ph3: {
    id: "person.device.sosPhoneNumber3",
    defaultMessage: "Notfall-Telefonnummer 3",
  },
  ph4: {
    id: "person.device.sosPhoneNumber4",
    defaultMessage: "Notfall-Telefonnummer 4",
  },
});

class SosOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      invalidNumbers: [],
      ...props.sosObj,
    };
  }

  /// TODO this function is used several times refactor to import
  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  };

  validatePhoneNumbers() {
    const invalidNumbers = [];

    Object.entries(this.state).forEach(([key, value]) => {
      if (!key.startsWith("ph") || !value) {
        return;
      }

      if (!this.validPhoneNumber(value)) {
        invalidNumbers.push(key);
      }
    });

    return invalidNumbers;
  }

  validPhoneNumber(number) {
    const regEx = RegExp("[+](?:[0-9]?){6,14}$");

    return regEx.test(number);
  }

  save = () => {
    // Check if phone numbers are valid
    const invalidPhoneNumbers = this.validatePhoneNumbers();

    this.setState({
      invalidNumbers: invalidPhoneNumbers,
    });

    if (invalidPhoneNumbers.length) {
      return;
    }

    this.setState({ saving: true });
    // noinspection JSUnusedLocalSymbols
    const { saving, status, invalidNumbers, ...sosObj } = this.state;

    this.props
      .setSos(this.props.imei, sosObj)
      .then(() => {
        this.props.closeFunction(true);
        this.setState({ saving: false });
      })
      .catch(() => {
        this.setState({ saving: false });
      });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="change-sos-numbers"
        enableCloseOnOverlayClicked={false}
      >
        {this.state.saving ? (
          <LoadingCircle />
        ) : (
          <div>
            <h5>
              <T
                id="person.device.changeSosPhoneNumbers"
                defaultMessage="Notfall-Telefonnummern ändern"
              />
            </h5>
            <p>
              <T
                id="person.device.changeSosPhoneNumbersDescription"
                defaultMessage="Die Telefonnummern müssen mit einem + beginnen und dürfen zwischen 6 und 14 Zeichen lang sein. Leerzeichen sind nicht erlaubt."
              />
              <br />
              <strong>
                <T
                  id="person.device.changeSosPhoneNumbersExample"
                  defaultMessage="Beispiel: +498007242640"
                />
              </strong>
            </p>
            <TextInput
              type="text"
              id="ph1"
              className={
                this.state.invalidNumbers.includes("ph1") ? "error" : ""
              }
              name={t(terms.ph1)}
              value={this.state.ph1}
              onChange={this.handleChange}
            />
            <TextInput
              type="text"
              id="ph2"
              className={
                this.state.invalidNumbers.includes("ph2") ? "error" : ""
              }
              name={t(terms.ph2)}
              value={this.state.ph2}
              onChange={this.handleChange}
            />
            <TextInput
              type="text"
              id="ph3"
              className={
                this.state.invalidNumbers.includes("ph3") ? "error" : ""
              }
              name={t(terms.ph3)}
              value={this.state.ph3}
              onChange={this.handleChange}
            />
            <TextInput
              type="text"
              id="ph4"
              className={
                this.state.invalidNumbers.includes("ph4") ? "error" : ""
              }
              name={t(terms.ph4)}
              value={this.state.ph4}
              onChange={this.handleChange}
            />
            <div className="button save-button" onClick={this.save}>
              <T
                id="tracar.device.accept"
                defaultMessage="Änderungen speichern"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const SosOverlay = injectIntl(SosOverlayClass);
