
import { putJson } from "./fetchService";

export const fetchDlc = jsonFetch => ({
  driversLicenseCheck(email, dlc_barcode = "") {
    const route = `/user/drivers_license_check`;
    if (email != null) {
      return jsonFetch(
        route,
        putJson({ user: { email }, dlc: { dlc_barcode } }),
      );
    } else {
      return jsonFetch(route);
    }
  },
  confirmWarning(dlc_barcodes) {
    return jsonFetch(`/user/dlc_warning_ack`, putJson({ dlc_barcodes }));
  },
});
