
export const fetchDashboard = (jsonFetch) => ({
  commonDashboardQuery(portal) {
    const route = `/user/common_dashboard/${portal}`;
    return jsonFetch(route);
  },
  deviceDashboard(imei) {
    const route = `/user/device_dashboard/${imei}`;
    return jsonFetch(route);
  },
  fetchPoolVehicleDashboard() {
    return jsonFetch(`/user/poolfahrzeuge/statistics`)
  }
});
