import React from "react";
import { Base } from "./Base";
import deLocale from "react-intl/locale-data/de";
import enLocale from "react-intl/locale-data/en";
import { addLocaleData, IntlProvider } from "react-intl";
import de_dynamic from "./i18n/locales/de_dynamic.json";
import { accDeDynamic, accEnDynamic } from "./ClientSpecificContent/acc-index";
import en_static from "./i18n/locales/en_static.json";
import en_dynamic from "./i18n/locales/en_dynamic.json";
import moment from "moment";
import numeral from "numeral";

const de = { ...de_dynamic, ...accDeDynamic };
const en = { ...en_static, ...en_dynamic, ...accEnDynamic };

export const defaultLocale = "en";

addLocaleData([...enLocale, ...deLocale]);

numeral.register("locale", "de", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function(number) {
    return ".";
  },
  currency: {
    symbol: "€",
  },
});
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});
numeral.locale(defaultLocale);
moment.locale(defaultLocale);

//const languages: languageTypeDef = {
const languages = {
  /// de does not need messages,
  /// it is the default locale, takes the default messages
  de: { locale: "de", messages: de },
  en: { locale: "en", messages: en },
};

export class Intl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localizationData: languages[getLanguage()],
    };
  }

  setLanguage = locale => {
    numeral.locale(locale);
    moment.locale(locale);
    this.setState({ localizationData: languages[locale] });
  };

  render() {
    return (
      <IntlProvider {...this.state.localizationData} defaultLocale={"de"}>
        <Base setLanguage={this.setLanguage} />
      </IntlProvider>
    );
  }
}

function getLanguage() {
  /// TODO get language from cookie
  const language =
    (navigator.languages && navigator.languages[0]) || navigator.language;
  let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
  /// let the week always start on monday, no matter the locale
  moment.updateLocale(languageWithoutRegionCode, { week: { dow: 1 } });

  if (
    Object.keys(languages).find(key => key === languageWithoutRegionCode) ==
    null
  ) {
    return "en";
  }
  return languageWithoutRegionCode;
}
