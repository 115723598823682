import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { LoadingCircle } from "../LoadingCircle";
import { RequestOverlay } from "../Request/RequestOverlay";
import { TextInput } from "../TextInput";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  ph1: {
    id: "person.device.sosPhoneNumber1",
    defaultMessage: "Notfall-Telefonnummer 1",
  },
  ph2: {
    id: "person.device.sosPhoneNumber2",
    defaultMessage: "Notfall-Telefonnummer 2",
  },
  ph3: {
    id: "person.device.sosPhoneNumber3",
    defaultMessage: "Notfall-Telefonnummer 3",
  },
  ph4: {
    id: "person.device.sosPhoneNumber4",
    defaultMessage: "Notfall-Telefonnummer 4",
  },
});

class SosTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.sosSettings,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.sosSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null) {
        settings[setting[0]] = this.state[setting[0]];
      }
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();

    let sosNumbers = {};
    Object.entries(this.state).forEach(property => {
      if (
        property[0] === "ph1" ||
        property[0] === "ph2" ||
        property[0] === "ph3" ||
        property[0] === "ph4"
      ) {
        Object.assign(sosNumbers, {
          [property[0]]: property[1],
        });
      }
    });

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: sosNumbers,
    });

    this.props.saveDeviceSettings(settingsContainer);
  };

  toggleOverlay = () => {
    this.setState({ showSosOverlay: !this.state.showSosOverlay });
  };

  isAnyPhoneNumberSet() {
    return Object.entries(this.state).find(property => {
      return property[0] !== "status" && property[1] !== "";
    })
      ? true
      : false;
  }

  updateState = updatedState => {
    this.props.setSettingsSaving("false");
    this.setState({ ...updatedState });
  };

  render() {
    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            <h5>
              <T
                id="tracar.device.headline.emergencyNumbers"
                defaultMessage="Notfallnummern"
              />
            </h5>
            <p>
              <T
                id="inanni.sos.description"
                defaultMessage="Hier können Sie bis zu vier Notfallnummern eingeben. Diese werden im Notfall nacheinander - beginnend von der Ersten - angerufen."
              />
            </p>

            {this.isAnyPhoneNumberSet() ? (
              <div>
                <div className="grid-x grid-margin-x">
                  <div className="medium-6 large-6 cell">
                    1:{" "}
                    {this.state.ph1 || (
                      <T
                        id="inanni.sos.nonumber"
                        defaultMessage="Keine Notfallnummer eingetragen"
                      />
                    )}
                  </div>
                  <div className="medium-6 large-6 cell">
                    2:{" "}
                    {this.state.ph2 || (
                      <T
                        id="inanni.sos.nonumber"
                        defaultMessage="Keine Notfallnummer eingetragen"
                      />
                    )}
                  </div>
                </div>
                <div className="grid-x grid-margin-x">
                  <div className="medium-6 large-6 cell">
                    3:{" "}
                    {this.state.ph3 || (
                      <T
                        id="inanni.sos.nonumber"
                        defaultMessage="Keine Notfallnummer eingetragen"
                      />
                    )}
                  </div>
                  <div className="medium-6 large-6 cell">
                    4:{" "}
                    {this.state.ph4 || (
                      <T
                        id="inanni.sos.nonumber"
                        defaultMessage="Keine Notfallnummer eingetragen"
                      />
                    )}
                  </div>
                </div>
                <div className="spacer" />
                {!this.state.status ? (
                  <p className="error">
                    <T
                      id="inanni.sos.statusFalse"
                      defaultMessage="Hinweis: Die angegebenen Telefonnummern wurden noch nicht auf das Gerät übertragen."
                    />
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="grid-x grid-margin-x">
                <div className="small-12 cell">
                  <strong>
                    <T
                      id="inanni.sos.nonumbers"
                      defaultMessage="Sie haben noch keine Notfallnummern eingetragen."
                    />
                  </strong>
                </div>
              </div>
            )}

            {!this.props.readOnly && (
              <div className="button border" onClick={this.toggleOverlay}>
                <T
                  id="inanni.sos.editNumbers"
                  defaultMessage="Telefonnummern bearbeiten"
                />
              </div>
            )}
            {this.state.showSosOverlay ? (
              <SosOverlay
                closeFunction={this.toggleOverlay}
                imei={this.props.imei}
                sosObj={this.state}
                updateState={this.updateState}
              />
            ) : (
              ""
            )}
            <div className="spacer" />
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/${
              this.props.portal === "admin-panel"
                ? "device/list"
                : "device-list"
            }`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          {!this.props.readOnly && (
            <div
              className={`button save-button ${this.props.settingsSaving ===
                "true" && "saving"} ${this.props.settingsSaving === "done" &&
                "saved"}`}
              onClick={() =>
                this.props.settingsSaving === "false" &&
                this.saveDeviceSettings()
              }
            >
              {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
              <span>
                {this.props.settingsSaving === "true" && (
                  <T
                    id="tracar.settings.sos.saving"
                    defaultMessage="SOS-Nummern werden aktualisiert..."
                  />
                )}
                {this.props.settingsSaving === "false" && (
                  <T
                    id="tracar.settings.sos.save"
                    defaultMessage="SOS-Nummern aktualisieren"
                  />
                )}
                {this.props.settingsSaving === "done" && (
                  <T
                    id="tracar.settings.sos.saved"
                    defaultMessage="SOS-Nummern aktualisiert"
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const SosTab = injectIntl(SosTabClass);

class SosOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      invalidNumbers: [],
      ...props.sosObj,
    };
  }

  /// TODO this function is used several times refactor to import
  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  };

  validatePhoneNumbers() {
    const invalidNumbers = [];

    Object.entries(this.state).forEach(([key, value]) => {
      if (!key.startsWith("ph") || !value) {
        return;
      }

      if (!this.validPhoneNumber(value)) {
        invalidNumbers.push(key);
      }
    });

    return invalidNumbers;
  }

  validPhoneNumber(number) {
    const regEx = RegExp("[+](?:[0-9]?){6,14}$");

    return regEx.test(number);
  }

  save = () => {
    // Check if phone numbers are valid
    const invalidPhoneNumbers = this.validatePhoneNumbers();

    this.setState({
      invalidNumbers: invalidPhoneNumbers,
    });

    if (invalidPhoneNumbers.length) {
      return;
    }

    this.setState({ saving: true });
    // noinspection JSUnusedLocalSymbols
    const { saving, status, invalidNumbers, ...sosObj } = this.state;

    this.props.updateState(sosObj);
    this.props.closeFunction(true);
    this.setState({ saving: false });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="change-sos-numbers"
        enableCloseOnOverlayClicked={false}
      >
        {this.state.saving ? (
          <LoadingCircle />
        ) : (
          <div>
            <h5>
              <T
                id="person.device.changeSosPhoneNumbers"
                defaultMessage="Notfall-Telefonnummern ändern"
              />
            </h5>
            <p>
              <T
                id="person.device.changeSosPhoneNumbersDescription"
                defaultMessage="Die Telefonnummern müssen mit einem + beginnen und dürfen zwischen 6 und 14 Zeichen lang sein. Leerzeichen sind nicht erlaubt."
              />
              <br />
              <strong>
                <T
                  id="person.device.changeSosPhoneNumbersExample"
                  defaultMessage="Beispiel: +498007242640"
                />
              </strong>
            </p>
            <TextInput
              type="text"
              id="ph1"
              className={
                this.state.invalidNumbers.includes("ph1") ? "error" : ""
              }
              name={t(terms.ph1)}
              value={this.state.ph1}
              onChange={this.handleChange}
            />
            <TextInput
              type="text"
              id="ph2"
              className={
                this.state.invalidNumbers.includes("ph2") ? "error" : ""
              }
              name={t(terms.ph2)}
              value={this.state.ph2}
              onChange={this.handleChange}
            />
            <TextInput
              type="text"
              id="ph3"
              className={
                this.state.invalidNumbers.includes("ph3") ? "error" : ""
              }
              name={t(terms.ph3)}
              value={this.state.ph3}
              onChange={this.handleChange}
            />
            <TextInput
              type="text"
              id="ph4"
              className={
                this.state.invalidNumbers.includes("ph4") ? "error" : ""
              }
              name={t(terms.ph4)}
              value={this.state.ph4}
              onChange={this.handleChange}
            />
            <div className="button save-button" onClick={this.save}>
              <T
                id="tracar.settings.accept"
                defaultMessage="Änderungen speichern"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const SosOverlay = injectIntl(SosOverlayClass);
