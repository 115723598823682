import React from "react";
import { FormattedMessage as T } from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment";
import { CustomDatePickerInput } from "../Map/Map";
import { defaultLocale } from "../Language";
import { Redirect } from "react-router";
import { Portal } from "../Portal";
import { ReportFileFormat } from "../ApiContract";

const ReportType = {
  Usage: "usage",
  DistanceDriven: "distance_driven",
  WiredReports: "wired_reports",
  Overspeeding: "rfid_speeding",
  DrivingHours: "rfid_driving_hours",
  Utilization: "utilization",
};

const reportAvailabilityByPortal = {
  auto: [
    ReportType.DistanceDriven,
    ReportType.Overspeeding,
    ReportType.DrivingHours,
    ReportType.Utilization,
  ],
  vehicle: [
    ReportType.Usage,
    ReportType.DistanceDriven,
    ReportType.WiredReports,
    ReportType.Utilization,
  ],
  asset: [ReportType.Usage, ReportType.DistanceDriven, ReportType.WiredReports],
  person: [
    // ReportType.WiredReports
  ],
};

function isXLSOnly(reportType) {
  return (
    reportType === ReportType.DrivingHours ||
    reportType === ReportType.Overspeeding ||
    reportType === ReportType.Utilization
  );
}

export class ReportsBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { endDate: moment(), startDate: moment() };
    this.portal = props.match.params.portal;
  }

  lastWeek = [
    moment()
      .subtract(1, "weeks")
      .startOf("isoWeek"),
    moment()
      .subtract(1, "weeks")
      .endOf("isoWeek"),
  ];
  lastMonth = [
    moment()
      .subtract(1, "months")
      .startOf("month"),
    moment()
      .subtract(1, "months")
      .endOf("month"),
  ];
  lastQuarter = [
    moment()
      .subtract(1, "quarters")
      .startOf("quarter"),
    moment()
      .subtract(1, "quarters")
      .endOf("quarter"),
  ];

  /**
   *
   * @param reportType ReportType
   * @returns {Function}
   */
  generateReport = (reportType, fileFormat) => () => {
    if (
      reportType === ReportType.DrivingHours ||
      reportType === ReportType.Overspeeding ||
      reportType === ReportType.Utilization
    ) {
      const bodyData = {
        start_time: this.state.startDate
          .clone()
          //.utc()
          .format("YYYY-MM-DD\\THH:mm:ss"),
        end_time: this.state.endDate
          .clone()
          //.utc()
          .format("YYYY-MM-DD\\THH:mm:ss"),
      };
      this.props.fetchReports.generateReportCsv(reportType, bodyData);
    } else {
      const bodyData = {
        category: this.portal,
        file_fmt: fileFormat,
        from: this.state.startDate
          .clone()
          //.utc()
          .format("YYYY-MM-DD\\THH:mm:ss"),
        to: this.state.endDate
          .clone()
          //.utc()
          .format("YYYY-MM-DD\\THH:mm:ss"),
      };
      this.props.fetchReports.generateReportCsv(reportType, bodyData);
    }
  };

  handleChangeEnd = endDate => {
    if (endDate.isBefore(this.state.startDate)) {
      this.setState({ endDate, startDate: endDate.clone() });
      return;
    }
    this.setState({ endDate });
  };

  handleChangeStart = startDate => {
    if (startDate.isAfter(this.state.endDate)) {
      this.setState({ startDate, endDate: startDate.clone() });
      return;
    }
    this.setState({ startDate });
  };

  handleTimeSpan = (startDate, endDate) => () =>
    this.setState({ startDate, endDate });

  compareTimeSpans = (startDate1, endDate1, startDate2, endDate2) => {
    return startDate1.isSame(startDate2) && endDate1.isSame(endDate2);
  };

  render() {
    if (
      this.portal !== Portal.Auto &&
      this.portal !== Portal.Vehicle &&
      this.portal !== Portal.Asset
    ) {
      return <Redirect to={`/${this.portal}/dashboard`} />;
    }

    return (
      <div className="outer-table-frame reports">
        <div className="button-frame">
          <div className="block">
            <div className="date-container">
              <div>
                <strong>
                  <T id="base.timeSpan" defaultMessage="Zeitraum:" />
                </strong>
              </div>
              <div>
                <T id="tracar.fahrtenbuch.from" defaultMessage="von:" />
              </div>
              <DatePicker
                className="button"
                customInput={<CustomDatePickerInput />}
                endDate={this.state.endDate}
                locale={moment().locale() || defaultLocale}
                onChange={this.handleChangeStart}
                popperPlacement="top-end"
                selected={this.state.startDate}
                selectsStart
                showMonthDropdown
                startDate={this.state.startDate}
                withPortal
              />
              <div>
                <T id="tracar.fahrtenbuch.to" defaultMessage="bis:" />
              </div>
              <DatePicker
                className=" button"
                customInput={<CustomDatePickerInput />}
                endDate={this.state.endDate}
                locale={moment().locale() || defaultLocale}
                onChange={this.handleChangeEnd}
                selected={this.state.endDate}
                selectsEnd
                showMonthDropdown
                startDate={this.state.startDate}
                withPortal
              />
            </div>
            <div
              className={
                "button" +
                (this.compareTimeSpans(
                  ...this.lastWeek,
                  this.state.startDate,
                  this.state.endDate,
                )
                  ? " active "
                  : "")
              }
              onClick={this.handleTimeSpan(...this.lastWeek)}
            >
              <T id="reports.lastWeek" defaultMessage="Letzte Woche" />
            </div>
            <div
              className={
                "button " +
                (this.compareTimeSpans(
                  ...this.lastMonth,
                  this.state.startDate,
                  this.state.endDate,
                )
                  ? " active "
                  : "")
              }
              onClick={this.handleTimeSpan(...this.lastMonth)}
            >
              <T id="reports.lastMonth" defaultMessage="Letzter Monat" />
            </div>
            <div
              className={
                "button " +
                (this.compareTimeSpans(
                  ...this.lastQuarter,
                  this.state.startDate,
                  this.state.endDate,
                )
                  ? " active "
                  : "")
              }
              onClick={this.handleTimeSpan(...this.lastQuarter)}
            >
              <T id="reports.lastQuarter" defaultMessage="Letztes Quartal" />
            </div>
          </div>
        </div>
        <div className="table-frame">
          <div className="table-header">
            <strong>
              <i className="fa fa-bar-chart" />
              <T id="base.reportType" defaultMessage="Report-Typ:" />
            </strong>
          </div>

          {reportAvailabilityByPortal[this.portal].map(reportType => (
            <div className="table-line grid-x">
              <div className="cell small-11 medium-1 large-1 table-line-left">
                <T id={`reports.${reportType}`} />
              </div>

              <div className="cell small-12 medium-12 large-auto table-line-middle flexible" />

              <div className="cell small-12 medium-1 large-1 table-line-right fixed">
                <div className="button-container">
                  <a
                    className="button"
                    key={reportType}
                    onClick={this.generateReport(
                      reportType,
                      ReportFileFormat.Excel,
                    )}
                  >
                    <i className="icon-xls" />
                  </a>
                  {!isXLSOnly(reportType) && (
                    <a
                      className="button"
                      key={reportType}
                      onClick={this.generateReport(
                        reportType,
                        ReportFileFormat.Csv,
                      )}
                    >
                      <i className="icon-csv" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
