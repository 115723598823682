import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { injectIntl } from "react-intl";
import { UserList } from "./UserList";
import { UserCreateOverlay } from "./UserCreateOverlay";
import { UserEditOverlay } from "./UserEditOverlay";
import { UserAssignRoleOverlay } from "./UserAssignRoleOverlay";
import { UserAssignRoleMultipleOverlay } from "./UserAssignRoleMultipleOverlay";
import { UserDeleteOverlay } from "./UserDeleteOverlay";
import { UserUploadOverlay } from "./UserUploadOverlay";
import { UserConsumer } from "../../Base";
import { hasPortalAccess } from "../../Permission";
import { Portal } from "../../Portal";

/**
 * Number of entries per pagination page
 * @type {number}
 */
const userListLimit = 10;

// search types to be sent via API to the backend
export const searchTypeRole = "role";
export const searchTypeUserData = "user_data";
export const searchTypeUserGroup = "user_groups";

/**
 * Base class for the user management portal
 */
class AdminPanelUserBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentPage: 0,
      showAssignRoleMultipleOverlay: false,
      selectedRecordList: [],
      recordListAll: [],
      userListOffset: 0,
      userListSearchKey: "",
      userListSearchType: searchTypeUserData,
      userListFilter: false,
      userListIsAutogenerated: null,
      userListIsActive: null,
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props
      .getUserListFiltered(
        this.state.userListOffset,
        userListLimit,
        this.state.userListSearchKey,
        this.state.userListSearchType,
        this.state.userListFilter,
        this.state.userListFilterIsAutogenerated,
        this.state.userListFilterIsActive,
      )
      .then(recordListData => {
        this.setState(
          {
            // unwrap user record list
            recordList: recordListData.user_data,
            // total number of users across all pagination pages
            numberOfUsers: recordListData.total_amount,
            // total number of pagination pages (used to generate pagination navigation)
            numberOfPages: Math.ceil(
              recordListData.total_amount / userListLimit,
            ),
          },
          () => {
            // retrieve all filtered users across all pagination pages to enable the "select all" button functionality
            this.props
              .getUserListFiltered(
                0,
                -1,
                this.state.userListSearchKey,
                this.state.userListSearchType,
                this.state.userListFilter,
                this.state.userListFilterIsAutogenerated,
                this.state.userListFilterIsActive,
              )
              .then(recordListAllData => {
                this.setState({
                  loading: false,
                  // unwrap user record list
                  recordListAll: recordListAllData.user_data,
                });
              });
          },
        );
      });
  }

  render() {
    if (this.state.recordList != null) {
      /// loaded
      return (
        <UserConsumer>
          {user =>
            hasPortalAccess(user, Portal.AdminPanelUser) && (
              <div>
                <UserList
                  toggleCreateOverlay={this.toggleCreateOverlay}
                  toggleCsvUploadOverlay={this.toggleCsvUploadOverlay}
                  toggleAssignFleetIdOverlay={this.toggleAssignFleetIdOverlay}
                  recordList={this.state.recordList}
                  recordListAll={this.state.recordListAll}
                  selectedRecordList={this.state.selectedRecordList}
                  inviteMessage={this.state.inviteMessage}
                  userLoginData={this.props.userLoginData}
                  toggleEditOverlay={this.toggleEditOverlay}
                  toggleDeleteOverlay={this.toggleDeleteOverlay}
                  toggleAssignUserGroupOverlay={
                    this.toggleAssignUserGroupOverlay
                  }
                  toggleAssignRoleOverlay={this.toggleAssignRoleOverlay}
                  toggleAssignRoleMultipleOverlay={
                    this.toggleAssignRoleMultipleOverlay
                  }
                  toggleAssignDeviceOverlay={this.toggleAssignDeviceOverlay}
                  selectRecord={this.selectRecord}
                  selectAllRecords={this.selectAllRecords}
                  deselectRecord={this.deselectRecord}
                  deselectAllRecords={this.deselectAllRecords}
                  updateListOffset={this.updateListOffset}
                  updateListSearchKey={this.updateListSearchKey}
                  updateListSearchType={this.updateListSearchType}
                  updateListFilterAll={this.updateListFilterAll}
                  updateListFilterIsActive={this.updateListFilterIsActive}
                  updateListFilterIsAutogenerated={
                    this.updateListFilterIsAutogenerated
                  }
                  updatePagination={this.updatePagination}
                  currentPage={this.state.currentPage}
                  numberOfUsers={this.state.numberOfUsers}
                  numberOfPages={this.state.numberOfPages}
                  currentListSearchType={this.state.userListSearchType}
                />
                {this.state.showCreateOverlay && (
                  <UserCreateOverlay
                    closeFunction={this.toggleCreateOverlay}
                    createUser={this.props.createUser}
                    generateEmailAddress={this.props.generateEmailAddress}
                    getRoleListAll={this.props.getRoleListAll}
                  />
                )}
                {this.state.showEditOverlay && (
                  <UserEditOverlay
                    closeFunction={this.toggleEditOverlay}
                    editUserRecord={this.state.editUserRecord}
                    editUser={this.props.editUser}
                    getRoleListAll={this.props.getRoleListAll}
                  />
                )}
                {this.state.showDeleteOverlay && (
                  <UserDeleteOverlay
                    closeFunction={this.toggleDeleteOverlay}
                    deleteUserRecords={[this.state.deleteUserRecord]}
                    deleteUser={this.props.deleteUser}
                  />
                )}
                {this.state.showAssignRoleOverlay && (
                  <UserAssignRoleOverlay
                    closeFunction={this.toggleAssignRoleOverlay}
                    assignRoleUserRecord={this.state.assignRoleUserRecord}
                    assignUsersToRole={this.props.assignUsersToRole}
                    getRoleListAll={this.props.getRoleListAll}
                    createRole={this.props.createRole}
                    getPermissionList={this.props.getPermissionList}
                  />
                )}
                {this.state.showAssignRoleMultipleOverlay && (
                  <UserAssignRoleMultipleOverlay
                    closeFunction={this.toggleAssignRoleMultipleOverlay}
                    assignRoleUserRecords={this.state.selectedRecordList}
                    assignUsersToRole={this.props.assignUsersToRole}
                    getRoleListAll={this.props.getRoleListAll}
                    createRole={this.props.createRole}
                    getPermissionList={this.props.getPermissionList}
                  />
                )}
                {this.state.showCsvUploadOverlay && (
                  <UserUploadOverlay
                    closeFunction={this.toggleCsvUploadOverlay}
                    uploadUsers={this.props.uploadUsers}
                  />
                )}
              </div>
            )
          }
        </UserConsumer>
      );
    } else {
      /// loading
      return <LoadingCircle />;
    }
  }

  /**
   * Updates state field "selectedRecordList" on selecting a user record
   * @param record
   */
  selectRecord = record => {
    if (!this.state.selectedRecordList.includes(record)) {
      let selectedRecordList = [...this.state.selectedRecordList, record];
      this.setState({ selectedRecordList });
    }
  };

  /**
   * Updates state field "selectedRecordList" on deselecting a user record
   * @param record
   */
  deselectRecord = record => {
    this.setState({
      selectedRecordList: this.state.selectedRecordList.filter(
        currentRecord => currentRecord.user.id !== record.user.id,
      ),
    });
  };

  /**
   * Updates state field "selectedRecordList" on selecting all user record
   */
  selectAllRecords = () => {
    this.setState(
      {
        selectedRecordList: this.state.recordListAll,
      },
      () => this.load(),
    );
  };

  /**
   * Updates state field "selectedRecordList" on deselecting all user record
   */
  deselectAllRecords = () => {
    this.setState(
      {
        selectedRecordList: [],
      },
      () => this.load(),
    );
  };

  /**
   * Updates the pagination offset to facilitate page switching
   * @param offset
   */
  updateListOffset = offset => {
    this.setState({ userListOffset: offset }, this.load);
  };

  /**
   * Updates the search term the list is filtered by
   * @param searchKey
   */
  updateListSearchKey = searchKey => {
    this.setState({ userListSearchKey: searchKey }, this.load);
  };

  /**
   * Updates the search type of the search bar
   * @param searchType as defined in constants above
   */
  updateListSearchType = searchType => {
    this.setState({ userListSearchType: searchType }, this.load);
  };

  /**
   * Updates the list to show all entries
   * Used to handle events of the dropdown filter control
   */
  updateListFilterAll = () => {
    this.setState(
      {
        userListFilter: false,
        userListFilterIsActive: null,
        userListFilterIsAutogenerated: null,
      },
      this.load,
    );
  };

  /**
   * Updates the list when filtering by active state
   * Used to handle events of the dropdown filter control
   */
  updateListFilterIsActive = isActive => {
    this.setState(
      {
        userListFilter: true,
        userListFilterIsActive: isActive,
        userListFilterIsAutogenerated: null,
      },
      this.load,
    );
  };

  /**
   * Updates the list when filtering if users are autogenerated
   * Used to handle events of the dropdown filter control
   */
  updateListFilterIsAutogenerated = isAutogenerated => {
    this.setState(
      {
        userListFilter: true,
        userListFilterIsActive: null,
        userListFilterIsAutogenerated: isAutogenerated,
      },
      this.load,
    );
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * userListLimit);
  };

  /**
   * Shows / hides the overlay containing the form to create users
   */
  toggleCreateOverlay = () => {
    this.setState({ showCreateOverlay: !this.state.showCreateOverlay }, () => {
      // reload list only when closing the overlay
      if (!this.state.showCreateOverlay) {
        this.load();
      }
    });
  };

  /**
   * Shows / hides the overlay containing the form to import users via CSV upload
   */
  toggleCsvUploadOverlay = () => {
    this.setState(
      { showCsvUploadOverlay: !this.state.showCsvUploadOverlay },
      () => {
        // reload list only when closing the overlay
        if (!this.state.showCsvUploadOverlay) {
          this.load();
        }
      },
    );
  };

  /**
   * Shows / hides the overlay containing the form to edit users
   */
  toggleEditOverlay = userRecord => {
    this.setState(
      {
        editUserRecord: userRecord,
        showEditOverlay: !this.state.showEditOverlay,
      },
      () => {
        // reload list only when closing the edit overlay
        if (!this.state.showEditOverlay) {
          this.load();
        }
      },
    );
  };

  /**
   * Shows / hides the overlay containing the confirmation to delete a single user
   */
  toggleDeleteOverlay = userRecord => {
    this.setState(
      {
        deleteUserRecord: userRecord,
        showDeleteOverlay: !this.state.showDeleteOverlay,
      },
      () => {
        // reload list only when closing the edit overlay
        if (!this.state.showDeleteOverlay) {
          this.load();
        }
      },
    );
  };

  /**
   * Shows / hides the overlay containing the form to assign a role to a single user
   */
  toggleAssignRoleOverlay = userRecord => {
    this.setState(
      {
        assignRoleUserRecord: userRecord,
        showAssignRoleOverlay: !this.state.showAssignRoleOverlay,
      },
      () => {
        // reload list only when closing the edit overlay
        if (!this.state.showAssignRoleOverlay) {
          this.deselectAllRecords();
        }
      },
    );
  };

  /**
   * Shows / hides the overlay containing the form to assign a role to multiple users
   */
  toggleAssignRoleMultipleOverlay = () => {
    this.setState(
      {
        showAssignRoleMultipleOverlay: !this.state
          .showAssignRoleMultipleOverlay,
      },
      () => {
        // reload list only when closing the edit overlay
        if (!this.state.showAssignRoleMultipleOverlay) {
          this.deselectAllRecords();
        }
      },
    );
  };

  toggleAssignDeviceOverlay = () => {
    // this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
    alert("Toggle device assignment overlay");
  };

  toggleAssignUserGroupOverlay = () => {
    // this.setState({ showInviteOverlay: !this.state.showInviteOverlay });
    alert("Toggle user group assignem overlay");
  };

  inviteUser = email => {
    this.setState({ recordList: null, showInviteOverlay: false });
    this.props
      .signUpNonCompanyUser(email)
      .then(() => {
        this.load();
        this.setState({
          inviteMessage: {
            text: `Der Nutzer mit der E-Mailadresse ${email} wurde eingeladen.`,
            className: "",
          },
        });
      })
      .catch(err => {
        if (err.status === 400 || err.status === 409) {
          this.load();
          this.setState({
            inviteMessage: {
              text: `Ein Nutzer mit der E-Mailadresse ${email} existiert bereits.`,
              className: "error",
            },
          });
        }
      });
  };
}

export const AdminPanelUserBase = injectIntl(AdminPanelUserBaseClass);
