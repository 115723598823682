import React from "react";

export class QueryLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }

  timer = null;

  handleChange = event => {
    const value = event.target.value;
    const name = event.target.id;
    this.setState({ [name]: value });
    if (this.props.changeDelay) {
      clearTimeout(this.timer);
      this.timer = setTimeout(
        () => this.props.changeCallback(value),
        this.props.changeDelay,
      );
    } else {
      this.props.changeCallback(value);
    }
  };

  resetQuery = () => {
    const query = "";
    this.setState({ query });
    this.props.changeCallback(query);
  };

  render() {
    const containerClass =
      typeof this.props.class !== "undefined" ? this.props.class : "";
    return (
      <div className={"query-line " + containerClass}>
        <input
          type="text"
          onChange={this.handleChange}
          id="query"
          placeholder={this.props.placeholder}
          value={this.state.query}
        />
        <label htmlFor="query">
          {this.state.query.length === 0 ? (
            <i className="fa fa-search" />
          ) : (
            <i className="fa fa-times delete" onClick={this.resetQuery} />
          )}
        </label>
      </div>
    );
  }
}
