import * as React from "react";
import "./Request.css";

export const Request = props => {
  return (
    <div className={"request-component"} onClick={props.reject.callback}>
      <div className="box">
        <div>{props.text}</div>
        <div className="buttons">
          <div className="button" onClick={props.reject.callback}>
            {props.reject.label}
          </div>
          <div className="button" onClick={props.accept.callback}>
            {props.accept.label}
          </div>
        </div>
      </div>
    </div>
  );
};
