
import React from "react";
import { CustomerAddressTableLine } from "./CustomerAddressTableLine";
import { FormattedMessage as T } from "react-intl";


export class CustomerAddressList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.customerAddresses.map(customerAddress => (
      <CustomerAddressTableLine
        portal={this.props.portal}
        deleteAddress={this.props.deleteAddress}
        customerAddress={customerAddress}
        showDeleteConfirmation={this.props.showDeleteConfirmation}
      />
    ));
  }
}
