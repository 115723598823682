import { deleteJson, postJson, putJson } from "./fetchService";
import { apiBaseUrl } from "./middleware";

const serverbaseUrl = apiBaseUrl;

/// Collection of all fetch functions regarding the non-company-user management
export default function fetchRoles(wrappedFetch) {
  const getRoleListFilteredApi = (search_key, offset, limit) => {
    return wrappedFetch(
      "/admin_user/roles",
      postJson({ search_key: search_key, offset: offset, limit: limit }),
    );
  };

  const getRoleListAllApi = () => {
    return wrappedFetch("/admin_user/roles");
  };

  const getRoleListLocal = () =>
    new Promise((resolve, reject) =>
      resolve([
        {
          id: 1,
          role_name: "Rolle 1",
          functionalities: [1],
          assignedUsers: [3],
        },
        {
          id: 2,
          role_name: "Rolle 2",
          functionalities: [2],
          assignedUsers: [10],
        },
        {
          id: 3,
          role_name: "Rolle 3",
          functionalities: [3],
          assignedUsers: [89],
        },
        {
          id: 4,
          role_name: "Rolle 4",
          functionalities: [4],
          assignedUsers: [93],
        },
      ]),
    );
  const getRoleListAll = getRoleListAllApi;
  const getRoleListFiltered = getRoleListFilteredApi;

  const getPermissionListApi = () =>
    wrappedFetch("/admin_user/get_all_permissions");

  const getPermissionList = getPermissionListApi;

  const createRoleLocal = roleRecord =>
    new Promise((resolve, reject) => {
      resolve({ role_id: 4 });
    });

  const createRoleApi = (role_name, permissions) =>
    wrappedFetch(
      "/admin_user/role",
      postJson({ role_name: role_name, mapped_permissions: permissions }),
    );

  const createRole = createRoleApi;

  const editRoleApi = (role_id, role_name, permissions) =>
    wrappedFetch(
      "/admin_user/role/" + role_id,
      putJson({ role_name: role_name, mapped_permissions: permissions }),
    );

  const editRole = editRoleApi;

  const deleteRole = role_id =>
    wrappedFetch("/admin_user/role/" + role_id, deleteJson({}));
  const assignUserToDevice = (imei, user_id) =>
    wrappedFetch("/user/assigndevice", postJson({ imei, user_id }));
  const assignUsersToRole = (role_id, users) =>
    wrappedFetch(
      "/admin_user/assign_role",
      postJson({ role_id: role_id, users: users }),
    );

  return {
    assignUserToDevice,
    assignUsersToRole,
    getRoleListAll,
    getRoleListFiltered,
    getPermissionList,
    createRole,
    editRole,
    deleteRole,
  };
}
