import React from "react";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import Plot from "react-plotly.js";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  online: {
    id: "tracar.general.online",
    defaultMessage: "online",
  },
  offline: {
    id: "tracar.general.offline",
    defaultMessage: "offline",
  },
});

function OnlinePieFn({
  online_status: { online, offline },
  config,
  plotLayoutCommons,
  portal,
  history,
  intl: { formatMessage: t },
}) {
  return (
    <div className="graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          <i className="fa fa-feed rotate--45" />
          <T
            id="tracar.onlinePie.devicesCurrentlyOnline"
            defaultMessage="Geräte aktuell online"
          />
        </h5>
      </div>

      <div className="tiny-spacer" />
      <Plot
        config={config}
        data={[
          {
            values: [online, offline],
            type: "pie",
            textinfo: "label+value",
            textfont: { size: 16, color: Color.WildSand },
            sort: false,
            direction: "clockwise",
            hoverinfo: "none",
            marker: {
              colors: [Color.Red, Color.SilverChalice],
            },
            labels: [t(terms.online), t(terms.offline)],
            customdata: ["online", "offline"],
          },
        ]}
        layout={{ ...plotLayoutCommons }}
        onClick={data => {
          history.push({
            pathname: `/${portal}/device-list`,
            state: {
              onlineFilter:
                data.points[0].customdata[0] === "online" ? "true" : "false",
            },
          });
        }}
      />

      <ul style={{ marginLeft: "94px", marginTop: "-28px" }}>
        <li>
          <i className="fa fa-square" style={{ color: Color.Red }} />
          &nbsp;
          {online} <T id="tracar.general.online" defaultMessage="online" />
          &nbsp;&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.SilverChalice1 }} />
          &nbsp;
          {offline} <T id="tracar.general.offline" defaultMessage="offline" />
        </li>
      </ul>
    </div>
  );
}

export const OnlinePie = injectIntl(OnlinePieFn);
