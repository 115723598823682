
import { putJson } from "./fetchService";


export const fetchLocale = (jsonFetch) => ({
  localeQuery(locale) {
    const route = `/user/language`;
    if (locale != null) {
      return jsonFetch(route, putJson({ language: locale }));
    } else {
      return jsonFetch(route);
    }
  },
});
