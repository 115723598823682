import React from "react";
import {FormattedMessage as T} from "react-intl";
import SmartInventoryTableLine from "./SmartInventoryTableLine";

export class SmartInventoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                {this.renderTableHeader()}
                { this.props.items.map(item => (
                      <SmartInventoryTableLine
                          item={item}
                          key={item.item_uuid}
                          setItemToEdit={this.props.setItemToEdit}
                          showDetails={this.props.showDetails}
                          deleteItem={this.props.deleteItem}
                          generateReport={this.props.generateReport}
                          downloadQRCode={this.props.downloadQRCode}
                      />
                ))}
            </div>
        );
    }

    renderTableHeader() {
        return (
            <div className="table-line smart-inventory-table-header grid-x grid-padding-x">
                <div className="cell small-2">
                    <T id="adminPanel.smartInventory.lineItem.name" defaultMessage="Name"/>
                </div>
                <div className="cell small-2">
                    <T id="adminPanel.smartInventory.lineItem.location" defaultMessage="Standort"/>
                </div>
                <div className="cell small-4">
                    <T id="adminPanel.smartInventory.lineItem.reservationDetails" defaultMessage="Reservierungsdetails"/>
                </div>
                <div className="cell text-right open-tasks">
                    <T id="adminPanel.smartInventory.lineItem.tasks" defaultMessage="Offene Aufgaben"/>
                </div>
                <div className={'cell auto table-line-right'}><span></span></div>
            </div>
        );
    }
}
