import React from "react";
import { SettingsBase } from "./SettingsBase";
import { DeviceAssignmentType } from "../../../ApiContract";
import { hasPermission, Permission } from "../../../Permission";
import {
  noGeofencesPlaceholder,
  noTagsPlaceholder,
} from "../../../TrackerList/TrackerListBase";

export class AdminpanelSettingsBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: [],
      loadingState: "loading",
      sortedDevices: [],
      selectedDeviceImei: "",
      selectedTags: [],
      selectedAllTags: true,
      geofencingList: [],
      selectedGeofences: [],
      selectedAllGeofences: true,
      isFiltered: false,
      initialTagLoad: true,
      initialGeofenceLoad: true,
      isInitialSorted: false,
      tagList: {
        tag_objects: {},
        tag_id_list: [],
      },
      tagListLoadingState: "loading",
      sortedDevices: [],
      onlineFilter:
        this.props.location.state && this.props.location.state.onlineFilter
          ? this.props.location.state.onlineFilter
          : "",
    };
  }

  loadDevices = () => {
    this.setState({ loadingState: "loading", devices: [] });
    this.props.fetchList
      .getDeviceListFiltered("", "ALL", "ALL", 0, 1000, [])
      .then(({ devices }) => {
        this.setState({
          devices: devices,
          sortedDevices: devices,
          loadingState: "ready",
        });
      });
  };

  loadTags = () => {
    this.setState({ tagListLoadingState: "loading" });
    this.loadGeofencingList();
    this.props.fetchTags.getTagList().then(tagList => {
      this.setState({
        tagList,
        tagListLoadingState: "ready",
        selectedTags:
          this.props.location.state && this.props.location.state.selectedTags
            ? this.props.location.state.selectedTags
            : this.state.initialTagLoad
            ? [
                noTagsPlaceholder,
                ...Object.entries(tagList.tag_objects).map(tag => {
                  return { id: parseInt(tag[0]), name: tag[1].tag_name };
                }),
              ]
            : this.state.selectedTags,
        selectedAllTags: this.props.location.state ? false : true,
        initialTagLoad: false,
      });
    });
  };

  componentDidMount() {
    this.loadDevices();
    this.loadTags();
  }

  clearSingleImei = () => {
    this.setState({ selectedDeviceImei: "" });
  };

  updateSelectedDevicesImeiList = (imei, status) => {
    if (imei === null) {
      this.setState({ selectedDeviceImei: "" });
    } else {
      this.setState({ selectedDeviceImei: imei });
    }
  };

  checkIfReadOnlySettings = () => {
    let readOnlyDeviceSelectedSingle = this.state.sortedDevices.some(
      device =>
        device.imei === this.state.selectedDeviceImei &&
        device.assignment_type === DeviceAssignmentType.Read,
    );
    let readOnlyDeviceSelectedList = this.state.sortedDevices.some(
      device =>
        device.imei === this.state.selectedDeviceImei &&
        device.assignment_type === DeviceAssignmentType.Read,
    );

    return readOnlyDeviceSelectedSingle || readOnlyDeviceSelectedList;
  };

  loadGeofencingList() {
    if (!hasPermission(this.props.user, Permission.SafetyZone)) {
      this.setState({
        selectedGeofences: [noGeofencesPlaceholder],
      });
      return;
    }
    this.props.fetchGeofences.getGeofencingList().then(res => {
      this.setState({
        geofencingList: res,
        selectedGeofences:
          this.props.location.state &&
          this.props.location.state.selectedGeofences
            ? this.props.location.state.selectedGeofences
            : this.state.initialGeofenceLoad
            ? [
                noGeofencesPlaceholder,
                ...res.map(geofence => {
                  return { id: geofence.geofence_id, name: geofence.name };
                }),
              ]
            : this.state.selectedGeofences,
        selectedAllGeofences: this.props.location.state ? false : true,
        isFiltered: false,
        initialGeofenceLoad: false,
      });
    });
  }

  render() {
    const selectedSettingsTab =
      this.props.location.state && this.props.location.state.selectedSettingsTab
        ? this.props.location.state.selectedSettingsTab
        : "";

    return (
      <div className="outer-table-frame tracker">
        <SettingsBase
          selectedDeviceImei={this.props.match.params.imei}
          devices={this.state.devices}
          user={this.props.user}
          fetchDeviceSettings={this.props.fetchDeviceSettings}
          selectedSettingsTab={selectedSettingsTab}
          getGeofenceList={this.props.getGeofenceList}
          clearSingleImei={this.clearSingleImei}
          match={this.props.match}
          fetchService={this.props.fetchService}
          selectedSettingsTab={selectedSettingsTab}
          updateSelectedDevicesImeiList={this.updateSelectedDevicesImeiList}
          fetchDeviceSettings={this.props.fetchDeviceSettings}
          clearSingleImei={this.clearSingleImei}
          loadTags={this.loadTags}
          loadGeofencingList={this.loadGeofencingList}
          carsRequest={this.props.carsRequest}
          addNewCar={this.props.addNewCar}
          generateWiresCsv={this.props.generateWiresCsv}
          getGeofenceList={this.props.getGeofenceList}
          getDeviceSafetyZones={this.props.getDeviceSafetyZones}
          deviceSafetyZoneRequest={this.props.deviceSafetyZoneRequest}
          deleteDeviceSafetyZone={this.props.deleteDeviceSafetyZone}
          readOnly={this.checkIfReadOnlySettings()}
          getCategoryListForDevice={
            this.props.fetchList.getCategoryListForDevice
          }
        />
      </div>
    );
  }
}
