import React from "react";
import { DashboardMessageList } from "./DashboardMessageList";
import { DashboardServiceIntervalList } from "./DashboardServiceIntervalList";
import { OnlinePie } from "./OnlinePie";
import { BatteryGraph } from "./BatteryGraph";
import { OnlineHeatmap } from "./OnlineHeatmap";
import { VehicleUsageLast30D } from "./VehicleUsageLast30D";
import { FormattedMessage as T } from "react-intl";
import { Portal } from "../Portal";
import Link from "react-router-dom/es/Link";

export function DashboardCommonVehicle({
  online_status,
  portal,
  usage_heatmap,
  usage_fleet,
  usage_per_device,
  messages,
  battery_yesterday,
  serviceIntervals,
  setIntervalCompletion,
  history,
}) {
  const config = { displayModeBar: false };
  const plotLayoutCommons = {
    separators: ",.",
    title: "",
    paper_bgcolor: "rgba(255,255,255,0)",
    plot_bgcolor: "rgba(255,255,255,0)",
    showlegend: false,
    width: 320,
    height: 210,
    margin: { t: 0, b: 25, l: 45, r: 15 },
  };

  const widePlotLayoutCommons = {
    ...plotLayoutCommons,
    width: 662,
  };

  const noAxisValues = {
    autorange: true,
    showgrid: false,
    zeroline: false,
    showline: false,
    autotick: true,
    ticks: "",
    showticklabels: false,
  };
  return (
    <div className="outer-table-frame dashboard">
      <div className="button-frame">
        <div className="button-block">
          <Link to={`/${portal}/reports`} className="button">
            <T id="reports.reports" defaultMessage="Reports" />
          </Link>
        </div>
      </div>
      <div className="table-frame">
        <div className="table-header">
          <i className="fa fa-tachometer" />
          <T id="base.dashboard" defaultMessage="Dashboard" />
        </div>
        <div className="all-graphs-outer-frame">
          <div className="all-graphs-frame">
            <OnlineHeatmap
              usage_heatmap={usage_heatmap}
              widePlotLayoutCommons={widePlotLayoutCommons}
              config={config}
            />

            <DashboardMessageList messages={messages} portal={Portal.Vehicle} />

            <DashboardServiceIntervalList
              serviceIntervals={serviceIntervals}
              setIntervalCompletion={setIntervalCompletion}
              portal={Portal.Vehicle}
            />

            <OnlinePie
              online_status={online_status}
              config={config}
              plotLayoutCommons={plotLayoutCommons}
              portal={portal}
              history={history}
            />

            <VehicleUsageLast30D
              widePlotLayoutCommons={widePlotLayoutCommons}
              noAxisValues={noAxisValues}
              config={config}
              plotLayoutCommons={plotLayoutCommons}
              usage_per_device={usage_per_device}
              portal={Portal.Vehicle}
            />

            <BatteryGraph
              battery_yesterday={battery_yesterday}
              config={config}
              noAxisValues={noAxisValues}
              widePlotLayoutCommons={widePlotLayoutCommons}
              history={history}
              portal={portal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
