import React from "react";
import { RfidableList } from "./RfidableList";
import { RfidableAssignChips } from "./RfidableAssignChips";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { Link } from "react-router-dom";
import { QueryLine } from "../tools/QueryLine";
import { RfidableAccessHistory } from "./RfidableAccessHistory";

const terms = defineMessages({
  confirmChipDeleteLabel: {
    id: "tracar.rfid-chip-list.confirmDelete.label",
    defaultMessage: "den Chip",
  },
});

export class RfidableListBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rfidableList: [],
      rfidChipLoadingState: "loading",
      rfidableLoadingState: "loading",
      devicesToAssign: [],
      deviceToAssign: [],
      showRfidableAssignChips: false,
      searchFilter: "",
      showRfidableAccessHistory: false,
      deviceWithAccessHistory: {},
    };
  }

  componentDidMount() {
    this.loadRfidable();
    this.loadRfidChips();
  }

  loadRfidChips = () => {
    this.setState({ rfidChips: [] });
    this.props.fetchRfid
      .getChipList()
      .then(rfidChipList =>
        this.setState({ rfidChipList, rfidChipLoadingState: "ready" }),
      );
  };

  loadRfidable = () => {
    this.props.fetchRfid.listRfidableDevices().then(rfidableList => {
      this.setState({
        rfidableList: rfidableList,
        rfidableLoadingState: "ready",
      });
    });
  };

  updateArray(arr, obj, status) {
    if (status) {
      arr.push(obj);
    } else {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].device_id === obj.device_id) {
          arr.splice(i, 1);
        }
      }
    }
    this.setState({});
  }

  updateDevicesToAssign = (device, status) => {
    this.updateArray(this.state.devicesToAssign, device, status);
  };

  updateDeviceToAssign = (device, status) => {
    this.toggleShowRfidableAssignChips();
    this.updateArray(this.state.deviceToAssign, device, status);
  };

  toggleShowRfidableAssignChips = () => {
    if (!this.state.showRfidableAssignChips) {
      this.setState({
        showRfidableAssignChips: !this.state.showRfidableAssignChips,
      });
    } else {
      this.setState({
        showRfidableAssignChips: !this.state.showRfidableAssignChips,
        deviceToAssign: [],
      });
      this.loadRfidable();
      this.loadRfidChips();
    }
  };

  toggleShowRfidableAccessHistory = () => {
    this.setState({
      showRfidableAccessHistory: !this.state.showRfidableAccessHistory,
    });
  };

  setSearchFilter = searchFilter => {
    this.setState({ searchFilter });
  };

  updateDeviceWithAccessHistory = device => {
    this.setState({ deviceWithAccessHistory: device });
  };

  render() {
    const portal = (this.props.match && this.props.match.params.portal) || "";
    return (
      <div className="outer-table-frame tracker">
        <div className="button-frame">
          <div className="block">
            <Link
              className="button icon-rfid-chip"
              to={`/${portal}/rfid-chip-list`}
            />
            <div className="button icon-rfid-chip-device active" />
            <div
              className="button"
              onClick={
                this.state.devicesToAssign.length > 0
                  ? this.toggleShowRfidableAssignChips
                  : () => {}
              }
              disabled={this.state.devicesToAssign.length === 0}
            >
              RFID-Chips zuweisen
            </div>
          </div>
          <div className="small-full-btn" />
        </div>
        <QueryLine changeCallback={this.setSearchFilter} />
        {this.state.showRfidableAssignChips && (
          <RfidableAssignChips
            closeFunction={this.toggleShowRfidableAssignChips}
            devices={
              this.state.deviceToAssign.length > 0
                ? this.state.deviceToAssign
                : this.state.devicesToAssign
            }
            rfidChipList={this.state.rfidChipList}
            fetchRfid={this.props.fetchRfid}
            updateDevicesToAssign={this.updateDevicesToAssign}
            loadRfidable={this.loadRfidable}
            loadRfidChips={this.loadRfidChips}
          />
        )}

        <RfidableList
          rfidableList={this.state.rfidableList}
          rfidChipLoadingState={this.state.rfidChipLoadingState}
          rfidableLoadingState={this.state.rfidableLoadingState}
          updateDevicesToAssign={this.updateDevicesToAssign}
          updateDeviceToAssign={this.updateDeviceToAssign}
          toggleShowRfidableAssignChips={this.toggleShowRfidableAssignChips}
          devicesToAssign={this.state.devicesToAssign}
          searchFilter={this.state.searchFilter}
          toggleShowRfidableAccessHistory={this.toggleShowRfidableAccessHistory}
          updateDeviceWithAccessHistory={this.updateDeviceWithAccessHistory}
        />

        {this.state.showRfidableAccessHistory && (
          <RfidableAccessHistory
            closeFunction={this.toggleShowRfidableAccessHistory}
            fetchRfid={this.props.fetchRfid}
            deviceWithAccessHistory={this.state.deviceWithAccessHistory}
          />
        )}
      </div>
    );
  }
}
