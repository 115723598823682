import React from "react";
import { Map } from "immutable";

import moment from "moment";
import Link from "react-router-dom/es/Link";

export class Message extends React.Component {
  imei;

  constructor(props) {
    super(props);
    this.state = {
      displayMessage: false,
    };
  }

  delete = () => {
    this.props.deleteEntry(this.props.message.message_id);
  };

  markMessage = () => {
    this.props.toggleMessageMark(this.props.message.message_id);
  };

  read = () => {
    if (this.props.message.read === false) {
      this.props.markCurrentMessageAsRead();
    }
    this.setState({
      displayMessage: !this.state.displayMessage,
    });
  };

  render() {
    const { markedMessages } = this.props;
    /*eslint-disable no-unused-vars */
    // noinspection JSUnusedLocalSymbols

    const {
      creation_time,
      device,
      link,
      message_id,
      read,
      subject,
      text,
      type,
    } = this.props.message;
    /*eslint-enable */
    return (
      <div className="table-line geofence">
        <div className="grid-x">
          <div
            className="cell small-12 medium-1 large-1 table-line-left"
            onClick={this.markMessage}
          >
            {markedMessages.has(message_id) ? (
              <i className="fa fa-check-square-o" />
            ) : (
              <i className="fa fa-square-o" />
            )}
          </div>
          <div
            className={
              "cell small-12 medium-auto large-auto table-line-middle" +
              (read ? " unstrong" : "")
            }
            onClick={this.read}
          >
            <span className={"link xyz " + (read ? " unstrong" : "")}>
              {subject}
            </span>
            <small className="message-date">
              {moment(creation_time).format("HH:mm:ss   DD.MM.YYYY")}
            </small>{" "}
          </div>
          <div
            className={
              `cell small-12 medium-1 large-1 small-table-line-right ` +
              ` two-icons`
            }
          >
            {link && (
              <Link className="button" to={link}>
                <i className="fa fa-book" />
              </Link>
            )}
            {this.props.message.lat && this.props.message.lon && (
              <div
                className="button"
                onClick={() => this.props.toggleMapOverlay(this.props.message)}
              >
                <i className="fa fa-map-marker" />
              </div>
            )}
            <div className="button" onClick={this.delete}>
              <i className="fa fa-trash" />
            </div>
          </div>
        </div>
        {this.state.displayMessage && (
          <div className="message-text" onClick={this.read}>
            {text.map((e, index) => (
              <div key={index}>{e}</div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
