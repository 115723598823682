import React from "react";
import { TextInput } from "../TextInput";
import { LoadingCircle } from "../LoadingCircle";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  mileage: {
    id: "tracar.mileage",
    defaultMessage: "Kilometerstand",
  },
  model: {
    id: "tracar.model",
    defaultMessage: "Marke und Modell",
  },
  driver: {
    id: "tracar.driver",
    defaultMessage: "Fahrer",
  },
  car_number: {
    id: "tracar.nar_number",
    defaultMessage: "Nummernschild",
  },
});

class NewCarClass extends React.Component {
  handleChange;
  save;

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      car_number: "",
      model: "",
      driver: "",
      mileage: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  // TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  save() {
    this.setState({ saving: true });

    this.props
      .addNewCar(
        this.props.imei,
        this.state.car_number,
        this.state.model,
        this.state.driver,
        this.state.mileage,
      )
      .then(res => {
        this.props.setCarFormData(
          this.state.car_number,
          this.state.model,
          this.state.driver,
          this.state.mileage,
        );
        this.props.closeFunction();
      });
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="new-car"
      >
        {this.state.saving ? (
          <LoadingCircle />
        ) : (
          <div>
            <h5>
              <T
                id="tracar.device.addNewCar"
                defaultMessage="Neues Fahrzeug hinzufügen"
              />
            </h5>
            <div className="grid-x grid-margin-x">
              <TextInput
                className="medium-6 large-6 cell"
                type="text"
                id="car_number"
                name={t(terms.car_number)}
                value={this.state.car_number}
                onChange={this.handleChange}
              />
              <TextInput
                className="medium-6 large-6 cell"
                type="text"
                id="model"
                name={t(terms.model)}
                value={this.state.model}
                onChange={this.handleChange}
              />
              <TextInput
                className="medium-6 large-6 cell"
                type="text"
                id="driver"
                name={t(terms.driver)}
                value={this.state.driver}
                onChange={this.handleChange}
              />
              <TextInput
                className="medium-6 large-6 cell"
                type="number"
                id="mileage"
                name={t(terms.mileage)}
                value={this.state.mileage}
                onChange={this.handleChange}
              />
            </div>
            <div className="button save-button" onClick={this.save}>
              <T
                id="tracar.device.accept"
                defaultMessage="Fahrzeug speichern"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const NewCar = injectIntl(NewCarClass);
