import React from "react";
import { Map } from "./Map";
import { hasPermission, hasPortalAccess, Permission } from "../Permission";
import { portals as possiblePortals } from "../Portal";
import { UserConsumer } from "../Base";
import Redirect from "react-router-dom/es/Redirect";

function accessParam(match, paramName) {
  return match && match.params[paramName];
}

export class MapBase extends React.Component {
  /// the promise number is used to enforce that only the latest request
  /// actually gets rendered
  promiseNumber;

  constructor(props) {
    super(props);
    this.promiseNumber = +new Date();
    this.state = { devices: [], timeShown: "live", date: {} };
  }

  findDefaultFrontendPortal = user => {
    // find the first frontend portal the user has access to
    return possiblePortals.find(portal => hasPortalAccess(user, portal));
  };

  findDefaultFrontendAction = user => {
    if (hasPermission(user, Permission.Dashboard)) {
      return "dashboard";
    }

    if (hasPermission(user, Permission.Location)) {
      return "map";
    }

    if (hasPermission(user, Permission.DeviceList)) {
      return "device-list";
    }

    return "map";
  };

  render() {
    return (
      <UserConsumer>
        {user => {
          if (hasPermission(user, Permission.Location)) {
            return (
              <Map
                isEmpty={this.props.isEmpty}
                date={this.state.date}
                match={this.props.match}
                fetchAddress={this.props.fetchAddress}
                getPaginatedCustomerAddresses={
                  this.props.getPaginatedCustomerAddresses
                }
                customerAddressList={this.state.customerAddressList}
                availablePortals={this.props.availablePortals}
                portal={accessParam(this.props.match, "portal") || ""}
                imei={accessParam(this.props.match, "imei") || ""}
                selectedTags={
                  this.props.location && this.props.location.state
                    ? this.props.location.state.selectedTags
                    : []
                }
                selectedGeofences={
                  this.props.location && this.props.location.state
                    ? this.props.location.state.selectedGeofences
                    : []
                }
                getTrackerList={this.props.getTrackerList}
                getCompleteLocationHistory={
                  this.props.getCompleteLocationHistory
                }
                getSingleTrackerLocationHistory={
                  this.props.getSingleTrackerLocationHistory
                }
                getLocationForSingleTracker={
                  this.props.getLocationForSingleTracker
                }
                location={this.props.location}
              />
            );
          } else {
            let defaultFrontendPortal = this.findDefaultFrontendPortal(user);
            let defaultFrontendAction = this.findDefaultFrontendAction(user);
            return (
              <Redirect
                to={{
                  pathname: `/${defaultFrontendPortal}/${defaultFrontendAction}`,
                }}
              />
            );
          }
        }}
      </UserConsumer>
    );
  }
}
