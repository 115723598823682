import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import {withGoogleMap, GoogleMap} from "react-google-maps";

class SimpleMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zoom: this.props.zoom || 12,
            center: this.props.center,
        };
    }

    render() {
        return (
            <div>
                <GoogleMap
                    bounds={{
                        east: 13.916343,
                        north: 53.930902,
                        south: 48.166079,
                        west: 6.012385,
                    }}
                    ref={map => (this.map = map)}
                    defaultZoom={19}
                    zoom={this.state.zoom}
                    defaultOptions={{
                        styles: {},
                        scaleControl: true,
                        disableDefaultUI: false,
                        zoomControl: true,
                    }}
                    defaultCenter={{ lat: this.state.center.lat, lng: this.state.center.lng }}
                >
                    {this.props.children}
                </GoogleMap>
            </div>
        );
    }
}

export default withGoogleMap(SimpleMap);
