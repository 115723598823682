import React from "react";
import { TextInput } from "../TextInput";
import { exchangeArrayElement, removeArrayElement } from "../utility";
import { trackerColors } from "./trackerColors";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { UserConsumer } from "../Base";
import Link from "react-router-dom/es/Link";
import { Portal } from "../Portal";
import { SosDisplay } from "../Person/SosDisplay";

const terms = defineMessages({
  deviceName: {
    id: "tracar.device.deviceName",
    defaultMessage: "Geräte-Name",
  },
  comment: {
    id: "tracar.device.comment",
    defaultMessage: "Anmerkung",
  },
  maxSpeed: {
    id: "tracar.device.maxSpeed",
    defaultMessage: "Geschwindigkeitsgrenze (km / h)",
  },
  email: {
    id: "tracar.device.email",
    defaultMessage: "E-Mail",
  },
  licensePlate: {
    id: "tracar.device.licensePlate",
    defaultMessage: "Nummernschild",
  },
  model: {
    id: "tracar.device.model",
    defaultMessage: "Marke und Modell",
  },
  driver: {
    id: "tracar.device.driver",
    defaultMessage: "Fahrer",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
  trackingInterval1: {
    id: "tracar.device.trackingInterval.one",
    defaultMessage: "1 mal pro Tag",
  },
  trackingInterval2: {
    id: "tracar.device.trackingInterval.two",
    defaultMessage: "3 mal pro Tag",
  },
  trackingInterval3: {
    id: "tracar.device.trackingInterval.three",
    defaultMessage: "1 mal pro Stunde",
  },
});

class TrackerDetailClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingMailDelete: null,
      ...props.formData,
    };
  }

  saveData = () => {
    // noinspection JSUnusedLocalSymbols
    const { pendingMailDelete, ...data } = this.state;
    // car_number, driver and model are lifted to TrackerDetailBase state hence need to be extracted from props.
    data.car_number = this.props.car_number;
    data.driver = this.props.driver;
    data.model = this.props.model;
    this.props.saveData(data);
  };

  setProfilePrivate = () => this.setState({ public: false });
  setProfilePublic = () => this.setState({ public: true });

  setClassifyRoutes = classify_routes => () =>
    this.setState({ classify_routes });

  cancelDelete = () => this.setState({ pendingMailDelete: null });

  addMailAddress = () => {
    this.setState({ deviceEmailList: [...this.state.deviceEmailList, ""] });
  };

  deleteMailAddress = index => {
    if (index === this.state.pendingMailDelete) {
      const deviceEmailList = removeArrayElement(
        this.state.deviceEmailList,
        index,
      );
      this.setState({ deviceEmailList, pendingMailDelete: null });
    } else {
      this.setState({ pendingMailDelete: index });
    }
  };

  handleChange = this.props.handleFormChange;

  handleFormChange = event => {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  handleMailChange = event => {
    const index = parseInt(event.target.id, 10);
    const deviceEmailList = exchangeArrayElement(
      this.state.deviceEmailList,
      event.target.value,
      index,
    );
    this.setState({ deviceEmailList });
  };

  handleTrackingIntervalChange = interval => {
    this.setState({ trackingInterval: interval });
  };

  render() {
    const user_owns_car =
      parseInt(this.props.user.user_id, 10) ===
      parseInt(this.state.user_id, 10);

    const t = this.props.intl.formatMessage;

    return (
      <UserConsumer>
        {user => (
          <div>
            <div className="form">
              <div className="tracker-data-head">
                <h1>{this.renderHeadline()}</h1>
                <small>
                  &nbsp;&nbsp;&nbsp;
                  <T id="tracar.device.imei" defaultMessage="IMEI:" />
                  &nbsp;{this.props.imei}
                </small>
              </div>
              <div className="grid-x grid-margin-x">
                <TextInput
                  className="medium-6 large-6 cell"
                  id="name"
                  name={t(terms.deviceName)}
                  onChange={this.handleFormChange}
                  type="text"
                  value={this.state.name}
                />
                <TextInput
                  className="medium-6 large-6 cell"
                  id="description"
                  name={t(terms.comment)}
                  onChange={this.handleFormChange}
                  type="text"
                  value={this.state.description}
                />
              </div>
              <div className="grid-x grid-margin-x">
                <div className="cell">
                  <div className="weekday-input">
                    <div className="label">
                      <T
                        id="tracar.device.pin"
                        defaultMessage="Farbe des Pins auf der Karte:"
                      />
                    </div>
                    <div className="weekday-buttons">
                      {trackerColors.map(color => (
                        <div
                          key={color}
                          className={
                            "color-button-old" +
                            (this.state.color === color ? " active" : "")
                          }
                          style={{ backgroundColor: color, borderColor: color }}
                          // data-weekday={Weekday.Mon}
                          // onClick={setWeekday}
                          onClick={() => this.setState({ color })}
                        >
                          {this.state.color === color && (
                            <i className="fa fa-check-circle" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="small-spacer" />
              <div className="grid-x grid-margin-x">
                {this.props.user.is_company && (
                  <div className="medium-6 large-6 cell">
                    <div className="select-frame">
                      <i className="fa fa-angle-down angle" />
                      <select
                        className="select"
                        id="user_id"
                        value={this.state.user_id}
                        onChange={this.handleFormChange}
                      >
                        {this.props.nonCompanyUserList.map(listedUser => {
                          return listedUser.email === this.props.user.email ? (
                            <option value={listedUser.id} key={listedUser.id}>
                              {t(terms.noUser)}
                            </option>
                          ) : (
                            <option value={listedUser.id} key={listedUser.id}>
                              {listedUser.email}
                              &nbsp;&nbsp;&nbsp; {listedUser.first_name}{" "}
                              {listedUser.last_name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="user_id">
                        <T
                          id="tracar.device.assignedUser"
                          defaultMessage="Zugeordneter Nutzer"
                        />
                      </label>
                    </div>
                  </div>
                )}
                <TextInput
                  className="medium-6 large-6 cell"
                  id="max_speed"
                  name={t(terms.maxSpeed)}
                  onChange={this.handleFormChange}
                  type="number"
                  value={this.state.max_speed}
                />
              </div>
              {this.renderTrackingInterval()}
              {// TODO: restrict with correct permission
              true && (
                <div className="grid-x grid-margin-x">
                  <div className="medium-6 large-6 cell">
                    <div className="weekday-input">
                      <div className="weekday-buttons">
                        <div
                          className={
                            "button" +
                            (this.state.public ? "" : " active") +
                            (user_owns_car ? "" : " disabled")
                          }
                          onClick={user_owns_car && this.setProfilePrivate}
                        >
                          <T
                            id="tracar.device.privateInvisible"
                            defaultMessage="private Fahrten sind nicht sichtbar"
                          />
                        </div>
                        <div
                          className={
                            "button" +
                            (this.state.public ? " active" : "") +
                            (user_owns_car ? "" : " disabled")
                          }
                          onClick={user_owns_car && this.setProfilePublic}
                        >
                          <T
                            id="tracar.device.allVisible"
                            defaultMessage="alle Fahrten sind sichtbar"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      {this.state.public ? (
                        <T
                          id="tracar.device.allVisibleMessage"
                          defaultMessage="Alle Fahrten sind für den Fuhrparkleiter sichtbar."
                        />
                      ) : (
                        <T
                          id="tracar.device.privateInvisibleMessage"
                          defaultMessage="Nur Dienstfahrten sind für den Fuhrparkleiter sichtbar."
                        />
                      )}
                    </div>
                  </div>
                  <div className="medium-6 large-6 cell">
                    <div className="weekday-input">
                      <div className="weekday-buttons">
                        <div
                          className={
                            "button" +
                            (this.state.classify_routes ? " active" : "") +
                            (user_owns_car ? "" : " disabled")
                          }
                          onClick={
                            user_owns_car && this.setClassifyRoutes(true)
                          }
                        >
                          <T
                            id="tracar.device.keepFahrtenbuch"
                            defaultMessage="Fahrtenbuch führen"
                          />
                        </div>
                        <div
                          className={
                            "button" +
                            (this.state.classify_routes ? "" : " active") +
                            (user_owns_car ? "" : " disabled")
                          }
                          onClick={
                            user_owns_car && this.setClassifyRoutes(false)
                          }
                        >
                          <T
                            id="tracar.device.routList"
                            defaultMessage="Routenliste anzeigen"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      {this.state.classify_routes ? (
                        <T
                          id="tracar.device.fahrtenbuchExplanation"
                          defaultMessage="Für das Fahrzeug sollen alle Fahrten im Fahrtenbuch klassifiziert werden."
                        />
                      ) : (
                        <T
                          id="tracar.device.routListExplanation"
                          defaultMessage="Sämtliche Fahrten werden unklassifiziert in der Routenliste angezeigt."
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="spacer" />
              <h5>
                <T id="tracar.device.emails" defaultMessage="Kontakt E-Mails" />
              </h5>
              <div className="grid-x grid-margin-x">
                {this.state.deviceEmailList.map((email, index) => (
                  <div className="medium-6 large-6 cell relative" key={index}>
                    <TextInput
                      className=""
                      id={index.toString()}
                      name={t(terms.email)}
                      onChange={this.handleMailChange}
                      type="text"
                      value={email}
                    />
                    <div className="delete-input-button">
                      {this.state.pendingMailDelete === index ? (
                        <div>
                          <div className="button" onClick={this.cancelDelete}>
                            <T
                              id="tracar.device.cancel"
                              defaultMessage="Abbrechen"
                            />
                          </div>
                          <div
                            className="button"
                            onClick={() => this.deleteMailAddress(index)}
                          >
                            <T
                              id="tracar.device.delete"
                              defaultMessage="Adresse löschen"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="button"
                          onClick={() => this.deleteMailAddress(index)}
                        >
                          -
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="medium-6 large-6 cell">
                  <div className="text-input-component">
                    <div className="delete-input-button">
                      <div className="button" onClick={this.addMailAddress}>
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer" />
              {//  TODO: restrict with correct permission
              true && (
                <div>
                  <h5>
                    <T
                      id="tracar.device.fahrtenbuchData"
                      defaultMessage="Fahrtenbuch-Angaben"
                    />
                  </h5>
                  <div className="grid-x grid-margin-x">
                    <TextInput
                      className="medium-6 large-6 cell"
                      id="car_number"
                      name={t(terms.licensePlate)}
                      onChange={this.handleChange}
                      type="text"
                      value={this.props.car_number}
                    />
                    <TextInput
                      className="medium-6 large-6 cell"
                      id="model"
                      name={t(terms.model)}
                      onChange={this.handleChange}
                      type="text"
                      value={this.props.model}
                    />
                    <TextInput
                      className="medium-6 large-6 cell"
                      id="driver"
                      name={t(terms.driver)}
                      onChange={this.handleChange}
                      type="text"
                      value={this.props.driver}
                    />
                    <div className="medium-6 large-6 cell">
                      <div className="weekday-input">
                        <div className="weekday-buttons">
                          <div
                            className={"button"}
                            onClick={this.props.toggleOdometerOverlay}
                          >
                            <T
                              id="tracar.device.setOdometer"
                              defaultMessage="Kilometerzähler einstellen"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <T
                          id="tracar.device.currentOdometer"
                          defaultMessage="Aktueller Kilometerstand nach Fahrtenbuch: {mileage} km"
                          values={{
                            mileage: parseInt(this.props.odometer, 10),
                          }}
                        />
                        <br />
                        <small>
                          <T
                            id="tracar.device.deviationsArePrivate"
                            defaultMessage="Abweichungen bis 10% müssen nicht korrigiert werden. Korrekturen werden als Privatfahrten verbucht."
                          />
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="spacer" />
              <div className="button save-button" onClick={this.saveData}>
                <T id="tracar.device.save" defaultMessage="Daten speichern" />
              </div>
              <div className="spacer" />
              {this.renderAdditionalCars(t)}
              {this.renderCarSwitch()}
              {this.props.portal === Portal.Person &&
              this.props.sosObj != null ? (
                <SosDisplay
                  sosObj={this.props.sosObj}
                  setSos={this.props.setSos}
                  imei={this.props.imei}
                  reloadDashboard={this.props.reloadDashboard}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }

  /**
   * Renders the section were all not-current cars are listet
   * @return JSX
   */
  renderAdditionalCars = t => {
    if (this.props.portal !== Portal.Auto) {
      return null;
    }

    return (
      <React.Fragment>
        <h5>
          <T
            id="tracar.device.additionalCars"
            defaultMessage="Weitere Fahrzeuge"
          />
        </h5>
        <div className="table-frame">
          {this.props.cars
            .filter(car => !car.current_car)
            .map((car, index) => (
              <div key={index} className="table-line grid-x">
                <div className="cell small-12 medium-12 large-auto table-line-left flexible">
                  {car.model} - {car.car_number}
                </div>
                <div className="cell small-12 medium-1 large-1 table-line-right fixed">
                  <Link
                    to={{
                      pathname: `/${this.props.portal}/fahrtenbuch/${
                        this.props.imei
                      }`,
                      state: {
                        car_id: car.fahrtenbuch_cardetails_id,
                        car_number: car.car_number,
                        model: car.model,
                      },
                    }}
                    className="button"
                    data-for={`tracker-table-line-${this.props.imei}`}
                    data-tip={t(terms.fahrtenbuch)}
                  >
                    <i className="fa fa-book" />
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  };

  renderHeadline = () => {
    if (this.props.portal === Portal.Person) {
      return (
        <T id="tracar.device.headlinePerson" defaultMessage="iNanny Daten" />
      );
    }

    return <T id="tracar.device.headline" defaultMessage="Trackerdaten" />;
  };

  /**
   * Renders the section to switch a car
   * @return JSX
   */
  renderCarSwitch() {
    if (this.props.portal !== Portal.Auto) {
      return null;
    }

    return (
      <div>
        <div className="spacer" />

        <h5>
          <T
            id="tracar.device.switchCarHeadline"
            defaultMessage="Fahrzeugwechsel"
          />
        </h5>

        <div className="switch-car-description">
          <T
            id="tracar.device.switchCarDescription"
            defaultMessage="Wenn Sie den Tracker in einem neuen Fahrzeug einsetzen möchten, beachten Sie bitte folgende Hinweise: Sobald Sie die Eintragungen vorgenommen haben und speichern, wird das bisherige Fahrtenbuch geschlossen und archiviert.
"
          />
        </div>

        <div className="spacer" />

        <div className={"button"} onClick={this.props.toggleNewCarOverlay}>
          <T
            id="tracar.device.switchCarButton"
            defaultMessage="Fahrzeug wechseln"
          />
        </div>
      </div>
    );
  }

  renderTrackingInterval() {
    if (this.props.portal !== Portal.Asset) {
      return null;
    }

    const t = this.props.intl.formatMessage;
    const trackingIntervalArray = [1, 2, 3];

    return (
      <div>
        <div className="spacer" />

        <h5>
          <T
            id="tracar.device.trackingInterval.headline"
            defaultMessage="Abfragefrequenz"
          />
        </h5>

        <div className="switch-car-description">
          <T
            id="tracar.device.trackingInterval.tip"
            defaultMessage="Die Einstellungen werden an das Gerät gesendet sobald es online ist."
          />
        </div>

        <div className="small-spacer" />

        <div className="">
          <div className="button-frame">
            <div className="block">
              {trackingIntervalArray.map(intervalIndex => {
                return (
                  <div
                    key={intervalIndex}
                    className={`button sorting ${this.props.trackingInterval
                      .interval_setting_type === intervalIndex && "active"}`}
                    // style={
                    //   this.props.trackingInterval
                    //     .actual_interval_in_hardware === intervalIndex
                    //     ? { border: "solid 3px rgb(230, 0, 0)" }
                    //     : {}
                    // }
                    onClick={() =>
                      this.props.updateTrackingInterval(intervalIndex)
                    }
                  >
                    {t(terms["trackingInterval" + intervalIndex])}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <T
            id="tracar.device.trackingInterval.current"
            defaultMessage="Aktuelle Einstellung im Gerät: "
          />{" "}
          {t(
            terms[
              "trackingInterval" +
                this.props.trackingInterval.actual_interval_in_hardware
            ],
          )}
        </div>
      </div>
    );
  }
}

export const TrackerDetail = injectIntl(TrackerDetailClass);
