import React from "react";
import { Map } from "./Map";
import { hasPermission, Permission } from "../../Permission";
import { UserConsumer } from "../../Base";

function accessParam(match, paramName) {
  return match && match.params[paramName];
}

export class AdminPanelPoolVehicleMapBase extends React.Component {
  /// the promise number is used to enforce that only the latest request
  /// actually gets rendered
  promiseNumber;

  constructor(props) {
    super(props);

    this.promiseNumber = +new Date();
    this.state = { devices: [], timeShown: "live", date: {} };
  }

  render() {
    return (
      <UserConsumer>
        {user => {
          if (hasPermission(user, Permission.PoolVehicleAdmin)) {
            return (
              <Map
                isEmpty={this.props.isEmpty}
                date={this.state.date}
                match={this.props.match}
                fetchAddress={this.props.fetchAddress}
                getPaginatedCustomerAddresses={
                  this.props.getPaginatedCustomerAddresses
                }
                customerAddressList={this.state.customerAddressList}
                availablePortals={this.props.availablePortals}
                portal={accessParam(this.props.match, "portal") || ""}
                imei={accessParam(this.props.match, "imei") || ""}
                selectedTags={
                  this.props.location && this.props.location.state
                    ? this.props.location.state.selectedTags
                    : []
                }
                selectedGeofences={
                  this.props.location && this.props.location.state
                    ? this.props.location.state.selectedGeofences
                    : []
                }
                getPoolVehiclesForMap={this.props.getPoolVehiclesForMap}
                getPoolVehiclesMapHistory={this.props.getPoolVehiclesMapHistory}
                getPoolVehicleMapHistory={this.props.getPoolVehicleMapHistory}
                getLocationForSingleTracker={
                  this.props.getLocationForSingleTracker
                }
                location={this.props.location}
              />
            );
          }
        }}
      </UserConsumer>
    );
  }
}
