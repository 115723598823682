import React from "react";
import { injectIntl, FormattedMessage as T } from "react-intl";
import CreateAndEditItemsForm from "../Forms/CreateAndEditItemsForm";
import { RequestOverlay } from "../../../../Request/RequestOverlay";
import AssignTemplateForm from "../Forms/AssignTemplateForm";
import {assignTemplateToTask, createNewInventoryItem, updateItem} from "../../../../fetch/fetchSmartInventory";

const noTemplateId = 0;

class CreateAndEditItemOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            templates: [],
            showEdit: true,
            showAssignTemplate: false,
            data: {
                createEditForm: {
                    name: this.props.itemToEdit ? this.props.itemToEdit.name : null,
                    color: this.props.itemToEdit ? this.props.itemToEdit.color : null,
                    description: this.props.itemToEdit ? this.props.itemToEdit.description : null,
                    serialNumber: this.props.itemToEdit ? this.props.itemToEdit.serial_number : null,
                },
                assignTaskTemplate: {
                    selectedBeforeTemplateId: null,
                    selectedAfterTemplateId: null,
                },
            }
        };
    }

    render() {
        return (
            <div className="new-inventory-item-modal">
                <RequestOverlay
                    closeFunction={this.props.closeFunction}
                    className="create-edit-smart-inventory-item"
                    enableCloseOnOverlayClicked={true}
                >
                    {this.renderInterface()}
                </RequestOverlay>
            </div>
        );
    }

    renderInterface = () => {
        return (
            <div>
                {this.renderHeader()}
                {this.renderTabs()}
                {this.renderBody()}
                <div className="small-spacer" />
                {this.renderFooter()}
            </div>
        );
    };

    renderHeader() {
        if (this.props.itemToEdit) {
            return (
                <div className="header">
                    <h3>
                        <T
                            id="adminPanel.smartInventory.editItem.headline"
                            defaultMessage="Objekt"
                        />
                        <span> -> </span>
                        <span className={`highlighted`}>{this.props.itemToEdit.name}</span>
                    </h3>
                </div>
            );
        }

        return (
            <div className="header">
                <h3>
                    <T id="adminPanel.smartInventory.createItem.headline" defaultMessage="Neues Item erstellen"/>
                </h3>
            </div>
        );
    };

    renderTabs() {
        return (
            <div className="tabs">
                <div className="tabs-inner-wrapper">
                    <div className={`tab-element ${this.state.showEdit ? 'active' : ''}`}
                         onClick={() => this.setState({showEdit: true, showAssignTemplate: false})}
                    >
                        <span className={'fa fa-pencil'}></span>
                        <T id="adminPanel.smartInventory.createItem.labels.edit" defaultMessage="Editieren"/>
                    </div>

                    <div className={`tab-element ${this.state.showAssignTemplate ? 'active' : ''}`}
                         onClick={() => this.setState({showEdit: false, showAssignTemplate: true})}
                    >
                        <span className={'fa fa-check-circle'}></span>
                        <T id="adminPanel.smartInventory.createItem.labels.assignTemplates" defaultMessage="Zuweisen"/>
                    </div>
                </div>
            </div>
        )
    }

    renderBody() {
        return (
            <div className={`tab-content`}>
                { this.renderEditForm() }
                { this.renderAssignForm() }
            </div>
        );
    };

    renderEditForm() {
        if (!this.state.showEdit) {
            return;
        }

        return <CreateAndEditItemsForm
            fetchService={this.props.fetchService}
            updateData={(formName, data) => this.updateFormData(formName, data)}
            itemToEdit={this.props.itemToEdit}
            data={this.state.data.createEditForm}
            closeFunction={this.props.closeFunction}
        />;
    }

    renderAssignForm() {
        if (!this.state.showAssignTemplate) {
            return;
        }

        return <AssignTemplateForm
            fetchService={this.props.fetchService}
            updateData={(formName, data) => this.updateFormData(formName, data)}
            itemToEdit={this.props.itemToEdit}
            templates={this.state.templates}
            setTemplates={(templates) => this.setTemplates(templates)}
            data={this.state.data.assignTaskTemplate}
            closeFunction={this.props.closeFunction}
        />;
    }

    setTemplates(templates) {
        this.setState({templates});
    }

    updateFormData(formName, data) {
        this.setState({
            data: {
                ...this.state.data,
                [formName]: data,
            }
        });
    }

    renderFooter() {
        return (
            <div className="footer text-right">
                <div className="button transparent" onClick={this.props.closeFunction}>
                    <T id="adminPanel.smartInventory.createInventoryItem.buttonLabel.cancel" defaultMessage="Abbrechen"/>
                </div>
                <div className="button green primary" onClick={() => this.submit()}>
                    <T id="adminPanel.smartInventory.createInventoryItem.buttonLabel.submit" defaultMessage="Speichern"/>
                </div>
            </div>
        );
    }

    submit() {
        this.saveOrUpdateItem().then((response) => {
            this.saveTaskTemplates(response.item_uuid).then(this.props.closeFunction);
        });
    }

    saveOrUpdateItem() {
        const data = {
            name: this.state.data.createEditForm.name,
            description: this.state.data.createEditForm.description,
            serial_number: this.state.data.createEditForm.serialNumber,
        };

        if (this.state.data.createEditForm.color) {
            data.color = this.state.data.createEditForm.color;
        }

        if (!this.props.itemToEdit) {
            return createNewInventoryItem(this.props.fetchService.jsonFetch, data);
        }

        return updateItem(this.props.fetchService.jsonFetch, this.props.itemToEdit.item_uuid, data);
    }

    saveTaskTemplates(itemId) {
        let afterTemplateId = this.state.data.assignTaskTemplate.selectedAfterTemplateId;
        let beforeTemplateId = this.state.data.assignTaskTemplate.selectedBeforeTemplateId;

        // Only send request if we actually got something to save
        // otherwise we would override existing templates.
        if (!afterTemplateId && !beforeTemplateId) {
            return new Promise((resolve) => resolve());
        }

        afterTemplateId = afterTemplateId == noTemplateId ? null : afterTemplateId;
        beforeTemplateId = beforeTemplateId == noTemplateId ? null : beforeTemplateId;

        return assignTemplateToTask(this.props.fetchService.jsonFetch, {
            item_uuid: [itemId],
            template_after: parseInt(afterTemplateId),
            template_before: parseInt(beforeTemplateId),
        });
    }
}

export default injectIntl(CreateAndEditItemOverlay);
