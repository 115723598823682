import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../TextInput";
import Link from "react-router-dom/es/Link";
import { Portal } from "../Portal";
import { NewCar } from "../TrackerDetail/NewCar";
import { LoadingSmallCircle } from "../LoadingCircle";
import { ServiceIntervalBase } from "../ServiceInterval/ServiceIntervalBase";

const terms = defineMessages({
  licensePlate: {
    id: "tracar.device.licensePlate",
    defaultMessage: "Nummernschild",
  },
  model: {
    id: "tracar.device.model",
    defaultMessage: "Marke und Modell",
  },
  driver: {
    id: "tracar.device.driver",
    defaultMessage: "Fahrer",
  },
  setOdometer: {
    id: "tracar.device.setOdometer",
    defaultMessage: "Kilometerzähler einstellen",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
});

class ServiceIntervalTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.fahrtenbuchSettings.fahrtenbuch_current_car_details,
      ...this.props.fahrtenbuchSettings,
      ...this.props.userSettings,
      showNewCarOverlay: false,
    };

    this.toggleNewCarOverlay = this.toggleNewCarOverlay.bind(this);
  }

  componentWillUnmount() {
    this.rememberSettings();
    this.rememberUserSettings();
  }

  rememberSettings = () => {
    let settings = this.props.fahrtenbuchSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null) {
        settings[setting[0]] = this.state[setting[0]];
      }
    });

    Object.entries(settings.fahrtenbuch_current_car_details).forEach(
      setting => {
        if (this.state[setting[0]] !== null) {
          settings.fahrtenbuch_current_car_details[setting[0]] = this.state[
            setting[0]
          ];
        }
      },
    );

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  rememberUserSettings = () => {
    let settings = this.props.userSettings;
    if (!settings) {
      return;
    }
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]])
        settings[setting[0]] = isNaN(parseInt(this.state[setting[0]]))
          ? this.state[setting[0]]
          : parseInt(this.state[setting[0]]);
    });

    this.props.updateDeviceSettings("user_settings", settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();
    this.rememberUserSettings();

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: this.props.fahrtenbuchSettings,
    });

    Object.assign(settingsContainer, {
      user_settings: this.props.userSettings,
    });
    this.props.saveDeviceSettings(settingsContainer);
  };

  toggleNewCarOverlay() {
    this.setState({ showNewCarOverlay: !this.state.showNewCarOverlay });
  }

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "set_speed_limit" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            <div className="grid-x grid-margin-x">
              <ServiceIntervalBase
                getPaginatedCustomerAddresses={
                  this.props.fetchService.getPaginatedCustomerAddresses
                }
                deleteServiceInterval={
                  this.props.fetchService.deleteServiceInterval
                }
                uploadCustomerAddresses={
                  this.props.fetchService.uploadCustomerAddresses
                }
                getServiceIntervals={
                  this.props.fetchService.getServiceIntervals
                }
                createServiceInterval={
                  this.props.fetchService.createServiceInterval
                }
                createMultipleServiceIntervals={
                  this.props.fetchService.createMultipleServiceIntervals
                }
                setIntervalCompletion={
                  this.props.fetchService.setIntervalCompletion
                }
                getEnumTypes={this.props.fetchService.getEnumTypes}
                portal={Portal.AdminPanel}
              />
            </div>

            <div className="spacer" />
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/${
              this.props.portal === "admin-panel"
                ? "device/list"
                : "device-list"
            }`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          <div
            className={`button save-button ${this.props.settingsSaving ===
              "true" && "saving"} ${this.props.settingsSaving === "done" &&
              "saved"}`}
            onClick={() =>
              this.props.settingsSaving === "false" && this.saveDeviceSettings()
            }
          >
            {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
            <span>
              {this.props.settingsSaving === "true" && (
                <T
                  id="tracar.settings.fahrtenbuch.saving"
                  defaultMessage="Fahrtenbuch wird aktualisiert..."
                />
              )}
              {this.props.settingsSaving === "false" && (
                <T
                  id="tracar.settings.fahrtenbuch.save"
                  defaultMessage="Fahrtenbuch aktualisieren"
                />
              )}
              {this.props.settingsSaving === "done" && (
                <T
                  id="tracar.settings.fahrtenbuch.saved"
                  defaultMessage="Fahrtenbuch aktualisiert"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export const ServiceIntervalTab = injectIntl(ServiceIntervalTabClass);
