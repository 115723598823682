import { postCSV, postJson, putJson } from "./fetchService";

const chipRoute = "/admin_user/chipemp";
const chipDelete = "/admin_user/chip_del";
const rfidableRoute = "/admin_user/assign_desassign_chip_to_device";
const rfidAccessRoute = "/admin_user/device_access_status/";
const rfidUploadRoute = "/admin_user/upload_chips_employees";

export const fetchRfid = jsonFetch => ({
  getChipList() {
    return jsonFetch(chipRoute);
  },

  /*
    {
        "chip_code": "112347",
        "chip_name": " Test 3",
        "fname" : "Sandesh",
        "lname" : "Nayak",
    }[]
   */
  createNewChip(chip) {
    return jsonFetch(chipRoute, postJson([chip]));
  },

  updateChip(chip) {
    return jsonFetch(chipRoute + "/" + chip.rfid_chip_id, putJson(chip));
  },

  /*
   {
        "device_id": 2,
        "imei": "1002",
        "device_name": "TEST 2",
        "assigned_user": "test_masteruser@leoworx.com",
        "assigned_chip_count": 0,
        "assigned_chips": [],
        "available_slots": 50
    }[]
   */
  listRfidableDevices() {
    return jsonFetch(rfidableRoute);
  },

  /*
  Can be used to assign multiple devices to multiple chips:
  {
    device_ids : deviceids[],
    chip_ids: chipids[]
  }
  */
  assignChipsToDevices(
    chipsToAssign,
    chipsToDeassign,
    devicesToAssign,
    devicesToDeassign,
  ) {
    return jsonFetch(
      rfidableRoute,
      postJson({
        assign: { chip_ids: chipsToAssign, device_ids: devicesToAssign },
        deassign: {
          chip_ids: chipsToDeassign,
          device_ids: devicesToDeassign,
        },
      }),
    );
  },

  uploadChipList(file) {
    return jsonFetch(rfidUploadRoute, postCSV(file.file));
  },

  deleteChips(chipIds) {
    return jsonFetch(chipDelete, postJson(chipIds));
  },

  getRfidableAccessList(accessData) {
    return jsonFetch(
      rfidAccessRoute + accessData.imei,
      postJson({
        from: accessData.from,
        to: accessData.to,
      }),
    );
  },
});
