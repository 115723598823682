import Cookies from "js-cookie";
import { Cookie } from "../Cookie";
import { initMiddleware } from "./middleware";
import { saveAs } from "file-saver";
import { GeofenceType } from "../Geofence/GeofenceType";
import polyline from "@mapbox/polyline";
import fetchNonCompanyUsers from "./fetchNonCompanyUsers";
import { fetchWires } from "./fetchWires";
import { fetchMessages } from "./fetchMessages";
import { fetchLocale } from "./fetchLocale";
import { fetchDlc } from "./fetchDlc";
import { fetchTags } from "./fetchTags";
import { fetchGeofences } from "./fetchGeofences";
import { fetchDashboard } from "./fetchDashboard";
import { fetchPerson } from "./fetchPerson";
import { fetchCustomerAddresses } from "./fetchCustomerAddresses";
import fetchUsers from "./fetchUsers";
import { fetchRfid } from "./fetchRfid";
import { fetchAddress } from "./fetchAddress";
import { fetchFaq } from "./fetchFaq";
import { fetchTrackingInterval } from "./fetchTrackingInterval";
import { Portal } from "../Portal";
import { fetchDeviceSettings } from "./fetchDeviceSettings";
import { fetchReports } from "./fetchReports";
import { fetchAdminPanelList } from "./fetchAdminPanelList";
import { fetchFahrtenbuch } from "./fetchFahrtenbuch";
import fetchServiceInterval from "./fetchServiceInterval";
import fetchPoolVehicle from "./fetchPoolVehicle";
import fetchPoolVehicleAdmin from "./fetchPoolVehicleAdmin";
import fetchExpenses from "./fetchExpensesAdmin";
import fetchExpensesAdmin from "./fetchExpensesAdmin";
import fetchExpensesPortal from "./fetchExpensesPortal";

////////////////////////////////////////////////////////////////////////////////
// FUNCTIONS

export const contentJsonHeader = (additionalHeaders = {}) => ({
  headers: {
    "Content-Type": "application/json",
    ...additionalHeaders,
  },
});

export const contentCSVHeader = (additionalHeaders = {}) => ({
  headers: {
    "Content-Type": "text/csv",
    ...additionalHeaders,
  },
});

export const contentMultipartFormdataHeader = (additionalHeaders = {}) => ({
  headers: {
    ...additionalHeaders,
  },
});

export const body = body => ({ body: JSON.stringify(body) });
//TEST
export const getJson = content => ({
  method: "GET",
  ...body(content),
  ...contentJsonHeader(),
});
//TEST
export const postJson = content => ({
  method: "POST",
  ...body(content),
  ...contentJsonHeader(),
});

export const putJson = content => ({
  method: "PUT",
  ...body(content),
  ...contentJsonHeader(),
});

export const deleteJson = content => ({
  method: "DELETE",
  ...body(content),
  ...contentJsonHeader(),
});

export const postCSV = file => {
  const request = {
    method: "POST",
    body: file,
    ...contentCSVHeader({
      "Content-Disposition": `attachment; filename=${file.name}`,
      "Content-Type": "text/csv",
    }),
  };

  return request;
};

export const postMultipartFormdata = content => {
  let body = new FormData();

  Object.keys(content).forEach(key => {
    body.append(key, content[key]);
  });
  const request = {
    method: "POST",
    body,
    ...contentMultipartFormdataHeader(),
  };

  return request;
};

export const putMultipartFormdata = content => {
  let body = new FormData();

  Object.keys(content).forEach(key => {
    body.append(key, content[key]);
  });
  const request = {
    method: "PUT",
    body,
    ...contentMultipartFormdataHeader(),
  };

  return request;
};

////////////////////////////////////////////////////////////////////////////////
// The fetch service itself

export const initFetchService = (refreshJWT, logOut) => {
  const { freeFetch, jsonFetch } = initMiddleware(refreshJWT, logOut);

  function login(email, password) {
    return jsonFetch(`/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json; version =2.0",
      },
      body: JSON.stringify({ email, password }),
    }).then(res => {
      Cookies.set(Cookie.jwt, res.token, { secure: true });
      Cookies.set(Cookie.jwtSigned, `${Date.now() / 1000}`, { secure: true });
      return res;
    });
  }

  //////////////////////////////////////////////////////////////////////////////
  // /user/refresh

  //  Promise<LoginResponse>
  const refresh = () => {
    const token = Cookies.get(Cookie.jwt);
    /// TODO this might become a problem if the call fails
    /// ponder using a local storage lock
    /// may Safari request a thousand refreshs if it wants...

    /// set the jwtSigned value early to prevent multiple refresh calls
    const oldJwtSigned = Cookie.jwtSigned;
    const newJwtSigned = `${Date.now() / 1000}`;
    /// optimistically labelling the jwt signed
    Cookies.set(Cookie.jwtSigned, newJwtSigned, { secure: true });
    return jsonFetch(`/user/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json; version =2.0",
      },
      body: JSON.stringify({ token }),
    })
      .then(res => {
        Cookies.set(Cookie.jwt, res.token, { secure: true });
        return res;
      })
      .catch(err => {
        /// the refresh failed for whatever reasons, reset to the old jwtSigned
        /// unless another refresh went through already
        Cookies.get(Cookie.jwtSigned) === newJwtSigned &&
          Cookies.set(Cookie.jwtSigned, oldJwtSigned, { secure: true });
        throw err;
      });
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/geofences

  const saveGeofence = geofenceObject => {
    /// encode polygones to polyline
    /// TODO ponder mutating the argument
    if (geofenceObject.fence_type === GeofenceType.Polygon) {
      const data = JSON.parse(geofenceObject.data).map(
        point => new window.google.maps.LatLng(point.lat, point.lng),
      );
      geofenceObject.data = window.google.maps.geometry.encoding.encodePath(
        data,
      );
    }
    if (geofenceObject.geofence_id) {
      /// geofenceObjects with an id have to be saved via PUT
      return jsonFetch(`/user/geofences/${geofenceObject.geofence_id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(geofenceObject),
      });
    } else {
      /// geofenceObjects without an id have to be created via POST
      return jsonFetch(`/user/geofences`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(geofenceObject),
      });
    }
  };

  const getGeofencingList = () =>
    jsonFetch(`/user/geofences`).then(geofencingList => {
      geofencingList = geofencingList.map(geofenceObject => {
        if (geofenceObject.fence_type === GeofenceType.Polygon) {
          geofenceObject.data = polyline
            .decode(geofenceObject.data)
            .map(point => ({ lat: point[0], lng: point[1] }));
        }
        return geofenceObject;
      });

      return geofencingList;
    });

  //////////////////////////////////////////////////////////////////////////////
  // /user/location

  const getTrackerList = (portal, automated = false) => {
    switch (portal) {
      case Portal.All: {
        return jsonFetch(`/user/location/live`, { automated });
      }
      default: {
        return jsonFetch(`/user/location/live/${portal}`, { automated });
      }
    }
  };

  const deleteGeofence = geofenceId => {
    return jsonFetch(`/user/geofences/${geofenceId}`, {
      method: "DELETE",
    });
  };

  // TODO add portal
  const getCompleteLocationHistory = (portal, timeCode, duration = {}) => {
    const body = JSON.stringify({
      type: timeCode,
      ...duration,
    });
    return jsonFetch(`/user/location/live/${portal}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/device

  const getSingleTrackerLocationHistory = (timeCode, imei, duration = {}) => {
    const body = JSON.stringify({
      type: timeCode,
      ...duration,
    });
    return jsonFetch(`/user/device/location/${imei}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
  };

  const getLocationForSingleTracker = (imei, automated = false) =>
    jsonFetch(`/user/device/location/${imei}`, { automated });

  //////////////////////////////////////////////////////////////////////////////
  // /user/fahrtenbuch

  const getFahrtenbuch = (imei, bodyData) =>
    jsonFetch(`/user/fahrtenbuch/${imei}`, {
      method: "POST",
      ...contentJsonHeader(),
      ...body(bodyData),
    });

  const getRouteData = fahrtenbuchId =>
    jsonFetch(`/user/location/route`, {
      method: "POST",
      ...contentJsonHeader(),
      ...body({
        fahrtenbuch_id: fahrtenbuchId,
      }),
    });

  const mergeRoutes = (imei, start, end) =>
    jsonFetch(`/user/fahrtenbuch/routemerge/${imei}`, {
      method: "POST",
      ...contentJsonHeader(),
      ...body({
        fahrtenbuch_id_start: start,
        fahrtenbuch_id_end: end,
      }),
    });

  const splitRoute = (imei, fahrtenbuchID, tracklogId) =>
    jsonFetch(`/user/fahrtenbuch/routesplit/${imei}`, {
      method: "POST",
      ...contentJsonHeader(),
      ...body({
        fahrtenbuch_id: fahrtenbuchID,
        tracklog_id: tracklogId,
      }),
    });

  //////////////////////////////////////////////////////////////////////////////
  // /user/devicesetting

  // eslint-disable-next-line
  const getTrackerDetailV2 = (imei, deviceData) => {
    const url = `/user/v2/devicesetting/${imei}`;
    return deviceData ? jsonFetch(url, putJson(deviceData)) : jsonFetch(url);
  };

  const getTrackerDetail = (imei, deviceData) =>
    deviceData
      ? jsonFetch(`/user/devicesetting/${imei}`, {
          method: "PUT",
          ...contentJsonHeader(),
          ...body(deviceData),
        })
      : jsonFetch(`/user/devicesetting/${imei}`);

  //////////////////////////////////////////////////////////////////////////////
  // /user/devices

  const getUserDevices = portal => jsonFetch(`/user/v2/devices/${portal}`);

  //////////////////////////////////////////////////////////////////////////////
  // /user/

  const getUser = () => jsonFetch(`/user`);

  const saveUser = user => jsonFetch(`/user`, putJson(user));

  //////////////////////////////////////////////////////////////////////////////
  // /user/fahrtenbuchedit

  const editFahrtenbuchEntry = fahrtenbuchEntry => {
    return jsonFetch(
      `/user/fahrtenbuchedit/${fahrtenbuchEntry.fahrtenbuch_id}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(fahrtenbuchEntry),
      },
    );
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/speedlimit

  const maxSpeedRequest = (imei, max_speed) => {
    const url = `/user/speedlimit/${imei}`;
    if (max_speed == null) {
      return jsonFetch(url).catch(({ status }) => {
        if (status === 404) {
          return { max_speed: -1 };
        }
      });
    } else {
      return jsonFetch(url, putJson({ max_speed }));
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/emailnotification

  const deviceEmailListRequest = (imei, emailList = null) => {
    const url = `/user/emailnotification/${imei}`;
    return emailList ? jsonFetch(url, postJson(emailList)) : jsonFetch(url);
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/devicesafetyzone

  /// TODO rename
  const getDeviceSafetyZones = (imei, deviceSafetyZone) =>
    deviceSafetyZone
      ? jsonFetch(`/user/devicesafetyzone/${imei}`, {
          method: "POST",
          ...contentJsonHeader(),
          ...body(deviceSafetyZone),
        })
      : jsonFetch(`/user/devicesafetyzone/${imei}`);
  const deviceSafetyZoneRequest = (deviceSafetyZoneId, deviceSafetyZone) => {
    const url = `/user/editdevicesafetyzone/${deviceSafetyZoneId}`;
    if (deviceSafetyZone) {
      return jsonFetch(url, {
        method: "PUT",
        ...contentJsonHeader(),
        ...body(deviceSafetyZone),
      });
    } else {
      return jsonFetch(`/user/editdevicesafetyzone/${deviceSafetyZoneId}`);
    }
  };

  const deleteDeviceSafetyZone = deviceSafetyZoneId =>
    jsonFetch(`/user/editdevicesafetyzone/${deviceSafetyZoneId}`, {
      method: "DELETE",
    });

  //////////////////////////////////////////////////////////////////////////////
  // /user/generateFahrtenbuchPdf

  const generateFahrtenbuchPdf = (imei, bodyData) => {
    let filename = "Fahrtenbuch.pdf";

    return freeFetch(`/user/generatepdf/${imei}`, {
      ...postJson(bodyData),
      responseType: "blob",
    })
      .then(response => {
        for (let [header, content] of response.headers.entries()) {
          if (header === "content-disposition") {
            filename = content.match(/"(.*?)"/)[1];
          }
        }

        return response.blob();
      })
      .then(blob => saveAs(blob, filename));
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/generateFahrtenbuchCsv

  const generateFahrtenbuchCsv = (imei, bodyData) => {
    let filename = "Fahrtenbuch.csv";

    return freeFetch(`/user/generatecsv/${imei}`, {
      ...postJson(bodyData),
      responseType: "blob",
    })
      .then(response => {
        for (let [header, content] of response.headers.entries()) {
          if (header === "content-disposition") {
            filename = content.match(/"(.*?)"/)[1];
          }
        }

        return response.blob();
      })
      .then(blob => saveAs(blob, filename));
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/routelist

  /// TODO types other than any...
  const generateCsv = (imei, range) => {
    let filename = "Routenliste.csv";
    return freeFetch(`/user/routelist/${imei}`, {
      ...postJson(range),
      responseType: "blob",
    })
      .then(response => {
        for (let [header, content] of response.headers.entries()) {
          if (header === "content-disposition") {
            filename = content.match(/"(.*?)"/)[1];
          }
        }

        return response.blob();
      })
      .then(blob => saveAs(blob, filename));
  };

  //////////////////////////////////////////////////////////////////////////////
  // /user/updatepassword

  const changePassword = passwordChangeDefinition =>
    jsonFetch("/user/updatepassword", {
      method: "PUT",
      ...contentJsonHeader(),
      ...body(passwordChangeDefinition),
    });

  /// TODO add imei once available
  const requestStatistics = (imei, period) =>
    jsonFetch(
      `/user/v2/statistics`,
      postJson({
        ...period,
        imei,
      }),
    );

  //////////////////////////////////////////////////////////////////////////////
  // /user/resetpassword

  const resetPassword = email =>
    jsonFetch(`/user/resetpassword`, postJson({ email }));

  //////////////////////////////////////////////////////////////////////////////
  // /user/password-reset-confirm

  const validateResetToken = (userId, token) =>
    freeFetch(`/password-reset-confirm/${userId}/${token}/`);

  const createNewPassword = (userId, token, password) =>
    freeFetch(
      `/password-reset-confirm/${userId}/${token}/`,
      postJson({ password }),
    );

  //////////////////////////////////////////////////////////////////////////////
  // /user/changeodometer

  const changeOdometer = (imei, device_odom_curr) =>
    jsonFetch(`/user/changeodometer/${imei}`, putJson({ device_odom_curr }));

  //////////////////////////////////////////////////////////////////////////////
  // /user/get_cars

  const getCars = imei => jsonFetch(`/user/get_cars/${imei}`);

  // user/add_new_car

  const addNewCar = (
    imei,
    car_number,
    car_model,
    driver,
    current_odometer_reading,
  ) => {
    const body = JSON.stringify({
      car_number,
      car_model,
      driver,
      current_odometer_reading,
    });

    return jsonFetch(`/user/add_new_car/${imei}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
  };

  const logout = () => {
    return jsonFetch(`/user/logout`);
  };

  const fetchService = {
    jsonFetch,
    freeFetch,
    logout,
    changePassword,
    changeOdometer,
    deleteDeviceSafetyZone,
    deleteGeofence,
    deviceEmailListRequest,
    deviceSafetyZoneRequest,
    editFahrtenbuchEntry,
    ...fetchDlc(jsonFetch),
    ...fetchLocale(jsonFetch),
    ...fetchMessages(jsonFetch),
    ...fetchNonCompanyUsers(jsonFetch),
    ...fetchUsers(jsonFetch),
    ...fetchPerson(jsonFetch),
    fetchDashboard: fetchDashboard(jsonFetch),
    fetchAdminPanelList: fetchAdminPanelList(jsonFetch, freeFetch),
    fetchTags: fetchTags(jsonFetch),
    fetchGeofences: fetchGeofences(jsonFetch),
    fetchRfid: fetchRfid(jsonFetch),
    fetchReports: fetchReports(freeFetch),
    ...fetchWires(jsonFetch, freeFetch),
    ...fetchCustomerAddresses(jsonFetch),
    generateCsv,
    generateFahrtenbuchPdf,
    generateFahrtenbuchCsv,
    getCompleteLocationHistory,
    getDeviceSafetyZones,
    getFahrtenbuch,
    getRouteData,
    mergeRoutes,
    splitRoute,
    getGeofencingList,
    getLocationForSingleTracker,
    getSingleTrackerLocationHistory,
    getTrackerDetail,
    getTrackerList,
    getUser,
    getUserDevices,
    login,
    maxSpeedRequest,
    refresh,
    requestStatistics,
    resetPassword,
    createNewPassword,
    validateResetToken,
    saveGeofence,
    saveUser,
    getCars,
    addNewCar,
    fetchAddress: fetchAddress(jsonFetch),
    fetchFaq: fetchFaq(jsonFetch),
    fetchDeviceSettings: fetchDeviceSettings(jsonFetch),
    fetchTrackingInterval: fetchTrackingInterval(jsonFetch),
    fetchFahrtenbuch: fetchFahrtenbuch(jsonFetch),
    ...fetchServiceInterval(jsonFetch),
    ...fetchPoolVehicle(jsonFetch, freeFetch),
    ...fetchPoolVehicleAdmin(jsonFetch, freeFetch),
    expensesAdmin: { ...fetchExpensesAdmin(jsonFetch, freeFetch) },
    expensesPortal: { ...fetchExpensesPortal(jsonFetch, freeFetch) },
  };
  return fetchService;
};
