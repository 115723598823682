import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import Link from "react-router-dom/es/Link";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  deviceName: {
    id: "tracar.device.deviceName",
    defaultMessage: "Geräte-Name",
  },
  comment: {
    id: "tracar.device.comment",
    defaultMessage: "Anmerkung",
  },
  maxSpeed: {
    id: "tracar.device.maxSpeed",
    defaultMessage: "Geschwindigkeitsgrenze (km / h)",
  },
  email: {
    id: "tracar.device.email",
    defaultMessage: "E-Mail",
  },
  licensePlate: {
    id: "tracar.device.licensePlate",
    defaultMessage: "Nummernschild",
  },
  model: {
    id: "tracar.device.model",
    defaultMessage: "Marke und Modell",
  },
  driver: {
    id: "tracar.device.driver",
    defaultMessage: "Fahrer",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
});

class UserTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.userSettings,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.userSettings;

    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]]) settings[setting[0]] = this.state[setting[0]];
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();
    this.props.saveDeviceSettings(this.props.tabType);
  };

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  setProfilePrivate = user_owns_car => {
    user_owns_car && this.setState({ public: false });
  };
  setProfilePublic = user_owns_car => {
    user_owns_car && this.setState({ public: true });
  };
  setClassifyRoutes = classify_routes => user_owns_car => {
    user_owns_car && this.setState({ classify_routes });
  };

  render() {
    return <div> deleted_settings </div>;
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/device-list`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T
                id="tracar.rfid-chip-list.settings.cancel"
                defaultMessage="Abbrechen"
              />
            </span>
          </Link>

          <div
            className={`button save-button ${this.props.settingsSaving ===
              "true" && "saving"} ${this.props.settingsSaving === "done" &&
              "saved"}`}
            onClick={() =>
              this.props.settingsSaving === "false" && this.saveDeviceSettings()
            }
          >
            {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
            <span>
              {this.props.settingsSaving === "true" && (
                <T
                  id="tracar.settings.user.saving"
                  defaultMessage="Benutzer wird aktualisiert..."
                />
              )}
              {this.props.settingsSaving === "false" && (
                <T
                  id="tracar.settings.user.save"
                  defaultMessage="Benutzer aktualisieren"
                />
              )}
              {this.props.settingsSaving === "done" && (
                <T
                  id="tracar.settings.user.saved"
                  defaultMessage="Benutzer aktualisiert"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export const UserTab = injectIntl(UserTabClass);
