import React from "react";
import { defineMessages, FormattedMessage as T } from "react-intl";
import { PaginationBar } from "../PaginationBar";
import { ExpenseLine } from "./ExpenseLine";

export class ExpenseList extends React.Component {
  handleChange;

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      warnings: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({
      loading: false,
      // unwrap device records
      devices: this.props.recordList,
      warnings: !this.props.recordList.find(({ dlc }) => dlc && dlc.warned),
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div>
        <div className="table-frame filter-table admin-list">
          <div className="table-header">
            <i className="icon-device" />
            <T
              id="adminPanel.devices.list.headline"
              defaultMessage="Kostenübersicht"
            />
            <span className="item-count">{this.props.numberOfDevices}</span>
          </div>
          <div className="grid-x grid-padding-x table-line filter margin-bottom-0">
            <div className="cell small-12 medium-6 large-auto">
              <a className="" href="#">
                <T
                  id="adminPanel.expense.list.line.label.poolVehicle"
                  defaultMessage="Poolfahrzeug"
                />
              </a>
            </div>

            <div className="cell small-12 medium-6 large-2 minus-margin-right-20 text-right">
              <a className="" href="#">
                <T
                  id="adminPanel.expense.list.line.label.totalCost"
                  defaultMessage="Gesamtkosten"
                />
              </a>
            </div>
            <div className="cell small-2" />
          </div>
          {this.props.recordList.map(device => (
            <ExpenseLine
              key={device.imei}
              device={device}
              toggleExpenseDetailOverlay={this.props.toggleExpenseDetailOverlay}
              getExpenseImage={this.props.getExpenseImage}
              acknowledgeExpense={this.props.acknowledgeExpense}
              deleteExpense={this.props.deleteExpense}
            />
          ))}
        </div>

        <PaginationBar
          currentPage={this.props.currentPage}
          numberOfPages={this.props.numberOfPages}
          updateCurrentPage={this.props.updatePagination}
        />
      </div>
    );
  }
}
