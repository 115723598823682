import React from "react";
import Link from "react-router-dom/es/Link";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { DeviceType } from "../Wires/deviceTypes";
import { defineMessages, injectIntl } from "react-intl";
import { Portal } from "../Portal";

const terms = defineMessages({
  privateCar: {
    id: "tracar.device-list.privateCar",
    defaultMessage: "privat genutztes Firmenfahrzeug",
  },
  companyCar: {
    id: "tracar.device-list.companyCar",
    defaultMessage: "Firmenfahrzeug",
  },
  tags: {
    id: "tracar.device-list.tags",
    defaultMessage: "Tags",
  },
  statistics: {
    id: "tracar.device-list.statistics",
    defaultMessage: "Statistiken",
  },
  mapView: {
    id: "tracar.device-list.mapView",
    defaultMessage: "Kartenansicht",
  },
  fahrtenbuch: {
    id: "tracar.device-list.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  geofences: {
    id: "tracar.device-list.geofences",
    defaultMessage: "Sicherheitszonen",
  },
  settings: {
    id: "tracar.device-list.settings",
    defaultMessage: "Einstellungen",
  },
  contacts: {
    id: "tracar.device-list.contacts",
    defaultMessage: "Kontakte",
  },
  messages: {
    id: "tracar.device-list.messages",
    defaultMessage: "Nachrichten",
  },
  fence_down: {
    id: "tracar.device-list.fenceDown",
    defaultMessage: "Bauzaun ist umgestürzt!",
  },
  batteryStatus: {
    id: "tracar.device-list.batteryStatus",
    defaultMessage: "Batteriestatus",
  },
  ok: {
    id: "ok",
    defaultMessage: "O.K.",
  },
  assigned: {
    id: "tracar.device-list.assigned",
    defaultMessage: "Zugewiesen",
  },
});

export const RfidableTableLine = withRouter(
  injectIntl(props => {
    const t = props.intl.formatMessage;

    const {
      device_id,
      imei,
      device_name,
      assigned_user,
      assigned_chip_count,
      assigned_chips,
      available_slots,
    } = props.rfidable;

    const totalSlots = assigned_chip_count + available_slots;

    const updateDevicesToAssign = (event, device) => {
      props.updateDevicesToAssign(device, event.target.checked);
    };

    const updateCheckBox = device => {
      const checkBox = document.getElementById("checkBox_" + device.device_id);
      checkBox.checked = !checkBox.checked;
      props.updateDevicesToAssign(device, checkBox.checked);
    };

    const assignDevice = device => {
      props.updateDeviceToAssign(device, true);
    };

    return (
      <div
        className="table-line tracker grid-x"
        onClick={() => updateCheckBox(props.rfidable)}
      >
        <div className="cell small-11 medium-1 large-1 table-line-left ">
          <input
            type="checkbox"
            id={"checkBox_" + device_id}
            onClick={event => {
              event.stopPropagation();
              updateDevicesToAssign(event, props.rfidable);
            }}
            defaultChecked={
              typeof props.devicesToAssign.find(
                device => device.device_id === props.rfidable.device_id,
              ) !== "undefined"
            }
          />
          {false && <input type="checkbox" />}
          {/*{icon}*/}
          <div style={{ display: "block" }}>
            {device_name}
            <div style={{ fontWeight: "normal" }}>
              <small>{assigned_user}</small>
            </div>
          </div>
        </div>

        <div className="cell small-12 medium-12 large-auto table-line-middle flexible">
          {/* {assigned_chips.map(chip => chip)}  */}
          {/* needs to be fiixed cause of type object */}
        </div>

        <div className="cell small-12 medium-1 large-1 table-line-right fixed">
          <div className="button-container">
            <div
              className="button"
              style={{
                pointerEvents: "none",
                fontWeight: "normal",
              }}
            >
              {t(terms.assigned)}: {assigned_chip_count} / {totalSlots}
            </div>
          </div>
          <div
            className="button-container"
            onClick={event => {
              event.stopPropagation();
              assignDevice(props.rfidable);
            }}
          >
            <i className="button icon-rfid-chip" />
          </div>
          <div
            className="button-container"
            onClick={event => {
              event.stopPropagation();
              props.updateDeviceWithAccessHistory(props.rfidable);
              props.toggleShowRfidableAccessHistory();
            }}
          >
            <i className="button icon-_445" />
          </div>
          {/*<div*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.tags)}*/}
          {/*  onClick={() => props.toggleShowAssignTagsOverlay(props.device)}*/}
          {/*>*/}
          {/*  <i className="fa fa-tag " />*/}
          {/*</div>{" "}*/}
          {/*<Link*/}
          {/*  to={"/" + portal + "/messages/" + imei}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.messages)}*/}
          {/*>*/}
          {/*  <i className="fa fa-envelope" />*/}
          {/*  {unread_messages > 0 && unread_messages < 100 && (*/}
          {/*    <div className="indicator">{unread_messages}</div>*/}
          {/*  )}*/}
          {/*  {unread_messages > 100 && <div className="indicator">99+</div>}*/}
          {/*</Link>*/}
          {/*<Link*/}
          {/*  to={"/" + portal + "/map/" + imei}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.mapView)}*/}
          {/*>*/}
          {/*  <i className="fa fa-map-marker" />*/}
          {/*</Link>*/}
          {/*<Link*/}
          {/*  to={`/${portal}/device-safety-zone/${imei}`}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.geofences)}*/}
          {/*>*/}
          {/*  <i className="fa fa-globe" />*/}
          {/*</Link>*/}
          {/*<Link*/}
          {/*  to={`/${portal}/device/${imei}`}*/}
          {/*  className="button"*/}
          {/*  data-for={`tracker-table-line-${imei}`}*/}
          {/*  data-tip={t(terms.settings)}*/}
          {/*>*/}
          {/*  <i className="fa fa-cog" />*/}
          {/*</Link>*/}
        </div>
        {/*<ReactTooltip*/}
        {/*  delayShow={500}*/}
        {/*  class="tooltip"*/}
        {/*  border={true}*/}
        {/*  id={`tracker-table-line-${imei}`}*/}
        {/*/>*/}
      </div>
    );
  }),
);
