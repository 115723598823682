import { ColorName } from "../TrackerDetail/trackerColors";

export function convertDeviceToPosition(device) {
  return {
    lat: parseFloat(device.lat || device.cust_lat), // cust_lat - adjustment to the backend key for customer addresses
    lng: parseFloat(device.lng || device.cust_lon), // cust_lon - adjustment to the backend key for customer addresses
    course: device.course,
    device: device,
  };
}

export function convertCustomerAddressToPosition(address) {
  return {
    lat: parseFloat(address.cust_lat),
    lng: parseFloat(address.cust_lon),
    ...address,
  };
}

/**
 * Returns pinUrl from Marker/Device
 * @param marker
 * @returns {pinUrl}
 */

export function getPinUrl(marker) {
  if (marker.device) {
    const color = marker.device.device
      ? marker.device.device.color
      : marker.device.color;

    const colorName = ColorName[color] ? ColorName[color] : "red";

    const description = marker.device.device
      ? marker.device.device.device_category.description
      : marker.device.device_category.description;

    switch (description) {
      case "auto": {
        return `/pins/auto/pins-${colorName}/Element ${getPinNumber(
          marker.course,
        )}.png`;
      }
      case "vehicle": {
        return `/pins/vehicle/pins-${colorName}/Element ${getPinNumber(
          marker.course,
        )}.png`;
      }
      case "asset": {
        return `/pins/asset/pins-${colorName}/Element ${getPinNumber(
          marker.course,
        )}.png`;
      }
      case "person": {
        return `/pins/person/pins-${colorName}/1x/Element ${getPinNumber(
          marker.course,
        )}.png`;
      }
      case "poolvehicle": {
        return `/pins/poolvehicle/pins-${colorName}/Element ${getPinNumber(
          marker.course,
        )}.png`;
      }
      default: {
        return `/pins/auto/pins-${colorName}/Element ${getPinNumber(
          marker.course,
        )}.png`;
      }
    }
  } else {
    return `/pins/home/Element 61.png`;
  }
}

/**
 * Returns pinNumber
 * @param angle
 * @returns {pinNumber}
 */

/// angles are given in degree x 10
/// pins are given in 6° steps successively numbered, i.e. `pin 12.png` is 36°
/// 0° is pin 1
function getPinNumber(angle) {
  let pinNumber = parseInt(angle / 60, 10) + 1;
  if (pinNumber <= 0) {
    pinNumber += 60;
  }
  if (pinNumber > 60) {
    pinNumber -= 60;
  }
  return pinNumber;
}
