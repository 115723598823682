import React from "react";
import moment from "moment";
import { defineMessages, injectIntl, FormattedMessage as T } from "react-intl";
import { DropdownItems, DropdownMenu } from "../../../tools/DropdownMenu";

const messages = defineMessages({
    edit: {
        id: "adminPanel.smartInventory.lineItem.editTitle",
        defaultMessage: "Bearbeiten",
    },
    unavailable: {
        id: "adminPanel.smartInventory.lineItem.unavailable",
        defaultMessage: "Nicht verfügbar",
    },
    available: {
        id: "adminPanel.smartInventory.lineItem.available",
        defaultMessage: "Verfügbar",
    },
    delete: {
        id: "adminPanel.smartInventory.lineItem.deleteTitle",
        defaultMessage: "Löschen",
    },
    details: {
        id: "adminPanel.smartInventory.lineItem.detailTitle",
        defaultMessage: "Details",
    },
    qrCode: {
        id: "adminPanel.smartInventory.lineItem.qrCodeTitle",
        defaultMessage: "QR-Code generieren",
    },
    report: {
        id: "adminPanel.smartInventory.lineItem.reportTitle",
        defaultMessage: "Report erstellen",
    },
});

class SmartInventoryTableLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="table-line smart-inventory-item grid-x grid-padding-x">
                <div className={`cell small-2 font-black item-name`}>
                    <div className="font-bold">
                        {this.props.item.name}
                    </div>
                </div>
                { this.renderLocation() }
                { this.renderReservationDetails() }
                { this.renderOpenTasks() }

                <div className={'cell auto table-line-right align-middle align-right'}>
                    { this.renderButtons() }
                    { this.renderDropdown() }
                </div>
            </div>
        );
    }

    renderLocation() {
        if (!this.props.item.location) {
            return <div className="cell small-2">-</div>;
        }

        return (
            <div className="cell small-2 font-black">
                {this.props.item.location}
            </div>
        );
    }

    renderReservationDetails() {
        if (!this.props.item.reservation) {
            return (
                <div className="cell small-4 font-black reservation-details">
                    { this.renderAvailabilityInformation() }
                    <T
                        id="adminPanel.smartInventory.lineItem.available"
                        defaultMessage="Verfügbar"
                    />
                </div>
            );
        }

        return (
            <div className="cell small-4 font-black reservation-details">
                { this.renderAvailabilityInformation() }
                <span className="reservation-date">
                    {
                        moment(this.props.item.reservation.start_date).format('DD.MM.YY') + ' - ' +
                        moment(this.props.item.reservation.end_date).format('DD.MM.YY')
                    }
                </span>

                <span className="customer">
                    {'-'} {this.props.item.customer.first_name} {this.props.item.customer.last_name}
                </span>
            </div>
        );
    }

    renderOpenTasks() {
        return (
            <div className="cell open-tasks">
                <div className={"text-right"}>{this.props.item.item_tasks}</div>
            </div>
        )
    }

    renderAvailabilityInformation() {
        const statusClass = this.props.item.reservation ? 'unavailable' : 'available';
        return <span
            className={`availability ${statusClass}`}
            title={this.props.intl.formatMessage(messages[statusClass])}
        ></span>
    }

    renderButtons() {
        return (
            <div className={'button-container'}>
                <div className={"button detail-button"}>
                    <span
                        className={'fa fa-info'}
                        title={this.props.intl.formatMessage(messages['details'])}
                        onClick={() => this.props.showDetails(this.props.item)}
                    ></span>
                </div>

                <div className={"button qrcode-button"}>
                    <span
                        className={'fa fa-qrcode'}
                        title={this.props.intl.formatMessage(messages['qrCode'])}
                        onClick={() => this.props.downloadQRCode(this.props.item)}
                    ></span>
                </div>

                <div className={"button report-button"}>
                    <span
                        className={'fa fa-bar-chart'}
                        title={this.props.intl.formatMessage(messages['report'])}
                        onClick={() => this.props.generateReport(this.props.item)}
                    ></span>
                </div>
            </div>
        );
    }

    renderDropdown() {
        return (
            <div className={'button-container'}>
                <DropdownMenu>
                    <DropdownItems>
                        <li onClick={() => this.props.setItemToEdit(this.props.item)}>
                            <i className="fa fa-pencil" />
                            <T id="adminPanel.smartInventory.label.edit" defaultMessage="Bearbeiten"/>
                        </li>
                        <li
                            className={`${this.props.item.reservation ? 'disabled': ''}`}
                            onClick={() => this.handleDeleteClick()}
                        >
                            <i className="fa fa-trash" />
                            <T id="adminPanel.smartInventory.label.delete" defaultMessage="Löschen"/>
                        </li>
                    </DropdownItems>
                </DropdownMenu>
            </div>
        );
    }

    handleDeleteClick() {
        // We don't support deletion of items with active reservations
        if (this.props.item.reservation) {
            return;
        }

        return this.props.deleteItem(this.props.item);
    }
}

export default injectIntl(SmartInventoryTableLine);
