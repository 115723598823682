import React from "react";
import { Wires } from "./Wires";

import { Redirect } from "react-router-dom";
import { LoadingCircle } from "../LoadingCircle";

export class WiresBase extends React.Component {
  portal;

  constructor(props) {
    super(props);
    this.portal = this.props.match.params.portal || "tracar";
    this.state = {
      loadingState: "loading",
      wires: null,
      imei: this.props.match.params.imei || "",
    };
  }

  componentDidMount() {
    this.props
      .wiresQuery(this.state.imei)
      .then(wires => this.setState({ wires, loadingState: "loaded" }));
  }

  save = editedWires => {
    this.setState({ loadingState: "saving" });
    this.props
      .wiresQuery(this.state.imei, editedWires)
      .then(() => this.setState({ loadingState: "saved" }));
  };

  render() {
    if (
      this.state.loadingState === "loading" ||
      this.state.loadingState === "saving"
    ) {
      return (
        <div className="outer-table-frame tracker">
          <div className="table-frame">
            <div className="spacer" />
            <LoadingCircle />
            <div className="spacer" />
          </div>
        </div>
      );
    } else if (
      this.state.loadingState === "loaded" &&
      this.state.wires != null
    ) {
      return (
        <div>
          <Wires
            wires={this.state.wires}
            save={this.save}
            generateWiresCsv={this.props.generateWiresCsv}
            imei={this.state.imei}
          />
        </div>
      );
    }
    return <Redirect to={`/${this.portal}/device-list`} />;
  }
}
