/// see for plotly control elements:
/// https://community.plot.ly/t/remove-options-from-the-hover-toolbar/130/11

import React from "react";

import { LoadingCircle } from "../LoadingCircle";
import { DashboardCommonAuto } from "./DashboardCommonAuto";
import { DashboardCommonVehicle } from "./DashboardCommonVehicle";
import { DashboardCommonAsset } from "./DashboardCommonAsset";
import { DashboardPerson } from "./DashboardPerson";
import { Portal } from "../Portal";
import { hasPermission, Permission } from "../Permission";
import { UserConsumer } from "../Base";
import { DashboardPoolvehicle } from "./DashboardPoolvehicle";

export class DashboardBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: "loading",
      dashboardData: null,
      serviceIntervals: [],
    };
  }

  componentDidMount() {
    const { portal } = this.props.match.params;
    portal && this.load(portal);
  }

  load = portal => {
    if (portal === Portal.PoolVehicle) {
      this.props.fetchDashboard.fetchPoolVehicleDashboard().then((dashboardData) => {
        this.setState({ dashboardData, loadingState: "loaded" });
      })
      return;
    }
    Promise.all([
      this.props.fetchDashboard.commonDashboardQuery(portal),
      this.props.getServiceIntervals(),
    ]).then(([dashboardData, serviceIntervals]) => {
      this.setState({
        dashboardData,
        serviceIntervals,
        loadingState: "loaded",
      });
    });
  };

  filterByType = (type, items) => {
    return items.length > 0
      ? items.filter(item => {
          return item.device_category === type;
        })
      : [];
  };

  sortByDate = items => {
    const sortParam = "planned_service_date";
    const direction = -1;

    return items.length > 0
      ? [].concat(items).sort((itemA, itemB) => {
          let paramFirst,
            paramSecond = "";
          paramFirst = new Date(itemA[sortParam]).getTime();
          paramSecond = new Date(itemB[sortParam]).getTime();
          if (paramFirst > paramSecond) {
            return -1 * direction;
          } else if (paramFirst < paramSecond) {
            return 1 * direction;
          } else {
            return 0;
          }
        })
      : [];
  };

  setIntervalCompletion = async interval => {
    this.props
      .setIntervalCompletion(!interval.is_done, interval.service_interval_id)
      .then(() => {
        this.props.getServiceIntervals().then(serviceIntervals => {
          this.setState({ serviceIntervals });
        });
      });
  };

  render() {
    let dashboard;
    const { portal } = this.props.match.params;
    switch (portal) {
      case Portal.Auto:
        dashboard = (
          <DashboardCommonAuto
            {...this.state.dashboardData}
            serviceIntervals={this.sortByDate(
              this.filterByType(Portal.Auto, this.state.serviceIntervals),
            ).splice(0, 4)}
            setIntervalCompletion={this.setIntervalCompletion}
            portal={portal}
            history={this.props.history}
          />
        );
        break;
      case Portal.Vehicle:
        dashboard = (
          <DashboardCommonVehicle
            {...this.state.dashboardData}
            serviceIntervals={this.sortByDate(
              this.filterByType(Portal.Vehicle, this.state.serviceIntervals),
            ).splice(0, 4)}
            setIntervalCompletion={this.setIntervalCompletion}
            portal={portal}
            history={this.props.history}
          />
        );
        break;
      case Portal.Asset:
        dashboard = (
          <DashboardCommonAsset
            {...this.state.dashboardData}
            serviceIntervals={this.sortByDate(
              this.filterByType(Portal.Asset, this.state.serviceIntervals),
            ).splice(0, 4)}
            setIntervalCompletion={this.setIntervalCompletion}
            portal={portal}
            history={this.props.history}
          />
        );
        break;
      case Portal.Person:
        dashboard = (
          <DashboardPerson
            {...this.state.dashboardData}
            serviceIntervals={this.sortByDate(
              this.filterByType(Portal.Person, this.state.serviceIntervals),
            ).splice(0, 4)}
            setIntervalCompletion={this.setIntervalCompletion}
            portal={portal}
            history={this.props.history}
          />
        );
        break;
      case Portal.PoolVehicle:
        dashboard = <DashboardPoolvehicle 
            {...this.state.dashboardData} 
            portal={portal}
            history={this.props.history}
          />;
        break;
      default:
      /// TODO add a default case, handle "default" portal
      // throw new TypeError(
      //   `Unexpected value for Portal given: ${this.props.match.params.portal}`
      // );
    }

    const { loadingState } = this.state;
    if (loadingState === "loading") {
      return (
        <div className="outer-table-frame tracker">
          <div className="table-frame">
            <div className="spacer" />
            <LoadingCircle />
            <div className="spacer" />
          </div>
        </div>
      );
    } else if (loadingState === "loaded") {
      return (
        <UserConsumer>
          {user => {
            if (!hasPermission(user, Permission.Dashboard)) {
              return null;
            }
            return dashboard;
          }}
        </UserConsumer>
      );
    }
  }
}
