import React from "react";
import { AccLogo } from "./ClientSpecificContent/acc-index";
import Link from "react-router-dom/es/Link";
import { LoadingCircle } from "./LoadingCircle";
import { Redirect } from "react-router-dom";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  password: {
    id: "resetPassword.password",
    defaultMessage: "Passwort",
  },
  confirmPassword: {
    id: "resetPassword.confirmPassword",
    defaultMessage: "Passwort bestätigen",
  },
});

const tokenValidationStatus = {
  valid: "valid",
  invalid: "invalid",
  undetermined: "undetermined",
};

class CreateNewPasswordClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      loadingState: "none",
      validationError: false,
      tokenError: false,
      tokenValidationStatus: tokenValidationStatus.undetermined,
    };
  }

  componentDidMount() {
    this.setState({ loadingState: "saving" });
    this.props
      .validateResetToken(
        this.props.match.params.userId,
        this.props.match.params.token,
      )
      .then(response => {
        if (response.ok) {
          this.setState({ tokenValidationStatus: tokenValidationStatus.valid });
        } else {
          this.setState({
            tokenValidationStatus: tokenValidationStatus.invalid,
          });
        }
        this.setState({ loadingState: "none" });
      });
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  resetPassword = () => {
    this.setState({ validationError: false, loadingState: "saving" }, () =>
      this.props
        .resetPassword(this.state.email)
        .then(() => this.setState({ loadingState: "finished" }))
        .catch(err => {
          if (err.status === 400 || err.status === 422) {
            this.setState({ validationError: true, loadingState: "none" });
          }
        }),
    );
  };

  validate = () => {
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ validationError: true, loadingState: "none" });
      return false;
    }
    this.setState({ validationError: false, loadingState: "none" });
    return true;
  };

  submit = () => {
    if (this.validate()) {
      this.setState({ loadingState: "saving" });
      this.props
        .createNewPassword(
          this.props.match.params.userId,
          this.props.match.params.token,
          this.state.password,
        )
        .then(response => {
          if (response.ok) {
            this.setState({ loadingState: "finished" });
            return;
          }
          this.setState({ validationError: true, loadingState: "none" });
        })
        .catch(err => {
          if (err.status === 400 || err.status === 422) {
            this.setState({ tokenError: true, loadingState: "none" });
          }
        });
    }
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div className="login-overlay reset-password">
        <div className="login-form" onSubmit={this.resetPassword}>
          <div className="logo">
            <Link to="">
              <img className="login-logo" src={AccLogo} alt="Logo" />
            </Link>
          </div>
          <div className="spacer" />
          {this.state.tokenValidationStatus === tokenValidationStatus.valid && (
            <React.Fragment>
              <div>
                <p>
                  <T
                    id="resetPassword.enterNewPassword"
                    defaultMessage="Bitte geben Sie Ihr neues Passwort ein."
                  />
                </p>
                <div className="small-spacer" />
                <T
                  id="resetPassword.passwordRequirementsIntro"
                  defaultMessage="Das Passwort muss folgende Bedingungen erfüllen:"
                />
                <ul>
                  <li>
                    <T
                      id="resetPassword.passwordRequirementsLength"
                      defaultMessage="Es muss mindestens 8 Zeichen lang sein"
                    />
                  </li>
                  <li>
                    <T
                      id="resetPassword.passwordRequirementsCharacters"
                      defaultMessage="Es muss mindestens einen Buchstabe, eine Zahl und ein Sonderzeichen enthalten"
                    />
                  </li>
                  <li>
                    <T
                      id="resetPassword.passwordRequirementsCredentials"
                      defaultMessage="Es darf nicht Ihrem Vornamen, Nachnamen oder E-Mail Adresse ähneln"
                    />
                  </li>
                  <li>
                    <T
                      id="resetPassword.passwordRequirementsCommon"
                      defaultMessage="Es darf kein häufig verwendetes Passwort sein"
                    />
                  </li>
                </ul>
              </div>
              <div className="text-input-component">
                <input
                  type="password"
                  value={this.state.password}
                  name="password"
                  id="password"
                  onChange={this.handleInputChange}
                  placeholder={t(terms.password)}
                />
                <label htmlFor="password">{t(terms.password)}</label>
              </div>
              <div className="text-input-component margin-bottom-1">
                <input
                  type="password"
                  value={this.state.confirmPassword}
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={this.handleInputChange}
                  placeholder={t(terms.confirmPassword)}
                />
                <label htmlFor="confirmPassword">
                  {t(terms.confirmPassword)}
                </label>
              </div>
              {this.state.validationError && (
                <div className="error">
                  <T
                    id="resetPassword.passwordError"
                    defaultMessage="Bitte geben Sie ein gültiges Passwort ein."
                  />
                  <div className="small-spacer" />
                </div>
              )}
              {this.state.tokenError && (
                <div className="error">
                  <T
                    id="resetPassword.tokenError"
                    defaultMessage="Diese Seite ist nicht mehr gültig. Bitte fordern Sie einen neuen Link"
                  />
                  <div className="small-spacer" />
                </div>
              )}
              <div className="flex align-justify">
                <Link className="back-link align-self-middle" to="">
                  <T id="resetPassword.back" defaultMessage="zurück" />
                </Link>
                <div className="button primary" onClick={this.submit}>
                  <T
                    id="resetPassword.resetPassword"
                    defaultMessage="Passwort zurücksetzen"
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {this.state.tokenValidationStatus ===
            tokenValidationStatus.invalid && (
            <div>
              <T
                id="resetPassword.tokenError"
                defaultMessage="Diese Seite ist nicht mehr gültig. Bitte fordern Sie einen neuen Link an."
              />
              <div className="smalls-spacer" />
              <Link to="/reset-password">
                <T
                  id="resetPassword.backToTokenRequest"
                  defaultMessage="Zurück zum Anfrageformular"
                />
              </Link>
            </div>
          )}
          {this.state.loadingState === "saving" && (
            <div>
              <LoadingCircle />
            </div>
          )}
          {this.state.loadingState === "finished" && (
            <div>
              <Redirect
                to={{
                  pathname: "/",
                  state: { passwordHasBeenReset: true },
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const CreateNewPassword = injectIntl(CreateNewPasswordClass);
