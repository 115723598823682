import React from "react";
import { Settings } from "./Settings";
import { LoadingCircle } from "../LoadingCircle";
import { Redirect } from "react-router";
import { removeArrayElement } from "../utility";

export class SettingsBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSettings: null,
      editedDeviceSettings: null,
      loadingState: "loading",
      settingsSaving: "false",
    };
  }

  componentDidMount() {
    this.loadDeviceSettings();
  }

  loadDeviceSettings = () => {
    this.setState({
      deviceSettings: null,
      loadingState: "loading",
    });
    // fetch the device settings tab to be displayed
    this.props.selectedDevicesImeiList.length > 1
      ? this.props.fetchDeviceSettings
          .bulk(this.props.selectedDevicesImeiList)
          .then(deviceSettings => {
            let tempTabs =
              deviceSettings != null &&
              Object.entries(deviceSettings).map(deviceSetting => {
                return deviceSetting[0];
              });
            tempTabs = removeArrayElement(
              tempTabs,
              tempTabs.indexOf("user_settings"),
            );

            this.setState({
              deviceSettings: deviceSettings,
              editedDeviceSettings: deviceSettings,
              isBulk: true,
              loadingState: "loaded",
              tabs: tempTabs,
            });
          })
          .catch(error => {
            this.setState({
              loadingState: "loaded",
            });
          })
      : this.props.fetchDeviceSettings
          .device(this.props.selectedDevicesImeiList)
          .then(deviceSettings => {
            let tempTabs =
              deviceSettings != null &&
              Object.entries(deviceSettings).map(deviceSetting => {
                return deviceSetting[0];
              });
            tempTabs = removeArrayElement(
              tempTabs,
              tempTabs.indexOf("user_settings"),
            );

            this.setState({
              deviceSettings: deviceSettings,
              editedDeviceSettings: deviceSettings,
              isBulk: false,
              loadingState: "loaded",
              tabs: tempTabs,
            });
          })
          .catch(error => {
            this.setState({
              loadingState: "loaded",
            });
          });
  };

  updateDeviceSettings = (type, settings) => {
    let editedDeviceSettings = this.state.editedDeviceSettings;
    editedDeviceSettings[type] = settings;
    this.setState({ editedDeviceSettings, settingsSaving: "false" });
  };

  saveDeviceSettings = settings => {
    this.setState({ settingsSaving: "true" });
    this.props.fetchDeviceSettings
      .update(this.props.selectedDevicesImeiList, settings)
      .then(res => {
        this.setState({ settingsSaving: "done" });
        this.props.loadTags();
      })
      .catch(err => {
        this.setState({ settingsSaving: "done" });
        this.props.loadTags();
      });
  };

  setSettingsSaving = status => {
    this.setState({ settingsSaving: status });
  };

  resetDeviceSettings = async settings => {
    this.setState({ settingsSaving: "true" });
    return await this.props.fetchDeviceSettings
      .reset(this.props.selectedDevicesImeiList, settings)
      .then(res => {
        this.setState({ settingsSaving: "done" });
        this.props.loadTags();
        return res;
      })
      .catch(() => {
        this.setState({ settingsSaving: "done" });
        return false;
      });
  };

  handleChange = event => {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  render() {
    const portal = (this.props.match && this.props.match.params.portal) || "";

    let deviceList = [];
    this.props.selectedDevicesImeiList &&
      this.props.devices &&
      this.props.selectedDevicesImeiList.forEach(imei => {
        this.props.devices.forEach(device => {
          device.imei === imei &&
            deviceList.push({
              imei: imei,
              name: device.name,
              assignment_type: device.assignment_type,
            });
        });
      });

    if (
      this.state.loadingState === "loading" ||
      this.state.loadingState === "saving"
    ) {
      return (
        <div className="loading-circle-container">
          <LoadingCircle />
        </div>
      );
    }

    if (this.state.editedDeviceSettings != null) {
      return (
        <Settings
          tabs={this.state.tabs}
          portal={portal}
          user={this.props.user}
          clearSingleImei={this.props.clearSingleImei}
          selectedDevicesImeiList={this.props.selectedDevicesImeiList}
          updateSelectedDevicesImeiList={
            this.props.updateSelectedDevicesImeiList
          }
          selectedSettingsTab={this.props.selectedSettingsTab}
          deviceList={deviceList}
          deviceSettings={this.state.editedDeviceSettings}
          updateDeviceSettings={this.updateDeviceSettings}
          isBulk={this.state.isBulk}
          handleChange={this.handleChange}
          saveDeviceSettings={this.saveDeviceSettings}
          carsRequest={this.props.carsRequest}
          addNewCar={this.props.addNewCar}
          settingsSaving={this.state.settingsSaving}
          setSettingsSaving={this.setSettingsSaving}
          resetDeviceSettings={this.resetDeviceSettings}
          generateWiresCsv={this.props.generateWiresCsv}
          getGeofenceList={this.props.getGeofenceList}
          getDeviceSafetyZones={this.props.getDeviceSafetyZones}
          deviceSafetyZoneRequest={this.props.deviceSafetyZoneRequest}
          deleteDeviceSafetyZone={this.props.deleteDeviceSafetyZone}
          loadTags={this.props.loadTags}
          loadDeviceSettings={this.loadDeviceSettings}
          readOnly={this.props.readOnly}
          fetchService={this.props.fetchService}
        />
      );
    } else return <Redirect to={`/${portal}/device-list`} />;
  }
}
