import React from "react";
import { ServiceIntervalTableLine } from "./ServiceIntervalTableLine";

export class ServiceIntervalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.serviceIntervals.map(serviceInterval => (
      <div
        className={`table-line service-interval status ${
          serviceInterval.status_type_choices === "fällig" ||
          serviceInterval.status_type_choices === "due"
            ? "soon"
            : serviceInterval.status_type_choices === "überfällig" ||
              serviceInterval.status_type_choices === "overdue"
            ? "overdue"
            : ""
        }`}
        key={serviceInterval.service_interval_id}
      >
        <ServiceIntervalTableLine
          portal={this.props.portal}
          deleteServiceInterval={this.props.deleteServiceInterval}
          serviceInterval={serviceInterval}
          updateServiceIntervals={this.props.updateServiceIntervals}
          setIntervalCompletion={this.props.setIntervalCompletion}
          showDeleteConfirmation={this.props.showDeleteConfirmation}
          fetchDevices={this.props.fetchDevices}
          createServiceInterval={this.props.createServiceInterval}
          getEnumTypes={this.props.getEnumTypes}
          userEmail={this.props.userEmail}
        />
      </div>
    ));
  }
}
