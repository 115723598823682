import React from "react";
import Link from "react-router-dom/Link";
import {
  defineMessages,
  FormattedMessage as T,
  FormattedMessage,
  injectIntl,
} from "react-intl";
import moment from "moment";
import { PoolVehicleAllowedRouteType } from "../../ApiContract";
import FormattedMessageFixed from "../../tools/FormattedMessageFixed";
import { hasPermission, Permission } from "../../Permission";
import { UserConsumer } from "../../Base";
import ReactTooltip from "react-tooltip";

const terms = defineMessages({
  messages: {
    id: "tracar.device-list.messages",
    defaultMessage: "Nachrichten",
  },
});
export class PoolVehicleLineClass extends React.Component {
  imei;
  constructor(props) {
    super(props);
    this.imei = this.props.device.imei || "1001";
    this.state = {
      amountUnread: 0,
    };
  }
  componentDidMount() {
    this.load({ offset: 0, limit: 0 });
  }

  load(pagination) {
    this.props.messageListQuery(this.imei, pagination).then(messageList => {
      this.setState({ amountUnread: messageList.amount_unread });
    });
  }
  // all portals have the map marker
  getIcon = color => {
    return (
      <div className={`message-pin`}>
        <i className="fa fa-map-marker" style={{ color }} />
      </div>
    );
  };
  render() {
    const t = this.props.intl.formatMessage;
    const { amountUnread } = this.state;
    const { imei } = this.props.device;
    return (
      <UserConsumer>
        {user => (
          <div
            className="table-line grid-x grid-margin-x pool-vehicle"
            style={{ width: "100%" }}
          >
            <div className="cell small-12 medium-6 large-2 first-cell">
              <div className="cell small-12 medium-6 large-3 first-cell">
                <div className="name"> {this.props.device.name}</div>
              </div>
              <div className="cell small-12 medium-12 large-3">
                <div className="grey-text small">
                  IMEI: {this.props.device.imei}
                </div>
              </div>
            </div>
            <div className="cell small-12 medium-auto">
              {this.props.device.current_use
                ? `${this.props.device.current_use.driver.first_name} ${this.props.device.current_use.driver.last_name}`
                : "-"}
            </div>
            <div className="cell small-12 medium-auto">
              {this.props.device.last_check_in_time
                ? moment(this.props.device.last_check_in_time).format(
                    "D.MM.YYYY HH:mm:ss",
                  )
                : "-"}
              <br />
              {this.props.device.current_use ? (
                this.props.device.current_use.route_type === "public" ? (
                  <T
                    id="adminPanel.poolVehicleList.label.currentRouteType.public"
                    defaultMessage="Geschäftlich"
                  />
                ) : (
                  <T
                    id="adminPanel.poolVehicleList.label.currentRouteType.private"
                    defaultMessage="Privat"
                  />
                )
              ) : (
                ""
              )}
            </div>
            <div className="cell small-12 medium-auto">
              {this.props.device.allowed_route_type ==
                PoolVehicleAllowedRouteType.Private && (
                <T
                  id="adminPanel.poolVehicleList.label.routeType.private"
                  defaultMessage="Geschäftlich, Privat"
                />
              )}
              {this.props.device.allowed_route_type ==
                PoolVehicleAllowedRouteType.Public && (
                <T
                  id="adminPanel.poolVehicleList.label.routeType.public"
                  defaultMessage="Nur geschäftlich"
                />
              )}
            </div>
            <div className="cell small-12 medium-auto">
              {this.props.device.last_public_position
                ? this.props.device.last_public_position
                : "-"}
            </div>
            <div className="cell small-12 medium-12 large-2 table-line-right">
              <div className="button-container">
                <Link
                  to={"/admin-panel/poolvehicle/messages/" + imei}
                  className="button"
                  data-for={`tracker-table-line-${imei}`}
                  data-tip={t(terms.messages)}
                >
                  <i className="fa fa-envelope" />
                  {amountUnread > 0 && amountUnread < 100 && (
                    <div className="indicator">{amountUnread}</div>
                  )}
                  {amountUnread >= 100 && <div className="indicator">99+</div>}
                </Link>
              </div>
              <div className="button-container all-buttons-margin-b-0">
                <div className="button-container">
                  <div
                    className="button margin-0"
                    onClick={() => {
                      this.props.togglePoolVehicleAssignDriverOverlay(
                        this.props.device,
                      );
                    }}
                  >
                    <i className="icon icon-steering_wheel" />
                  </div>
                  {hasPermission(user, Permission.UserDeviceManagement) && (
                    <div className="button margin-0">
                      <Link
                        to={
                          "/admin-panel/pool-vehicle/fahrtenbuch/" +
                          this.props.device.imei
                        }
                        className="button pool-vehicle-detail-list"
                        data-tip="Fahrtenbuch"
                      >
                        <i className={`fa fa-book`} />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ReactTooltip
              delayShow={500}
              class="tooltip"
              border={true}
              id={`tracker-table-line-${imei}`}
            />
          </div>
        )}
      </UserConsumer>
    );
  }
}

export const PoolVehicleLine = injectIntl(PoolVehicleLineClass);
