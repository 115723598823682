import React, { createRef } from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingCircle } from "./LoadingCircle";

/**
 * currently not in use, this is the solution for language dependent downloads
 */
const terms = defineMessages({
  link: {
    id: "tracar.faq.link",
    defaultMessage: "/Handbuch_AutoTrackingBusiness.pdf",
  },
});

const ref = createRef();

class FaqClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      faqLoadingState: "loading",
    };
  }

  componentDidMount() {
    this.loadFaq();
  }

  loadFaq = () => {
    this.props.loadFaq().then(res => {
      this.setState({ faq: res, faqLoadingState: "ready" });
      this.scrollTo();
    });
  };

  scrollTo = () =>
    ref.current &&
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  render() {
    const t = this.props.intl.formatMessage;
    const f = <T id="tracar.faq.f" defaultMessage="F:" />;
    const a = <T id="tracar.faq.a" defaultMessage="A:" />;
    return (
      <div className="outer-table-frame">
        {this.state.faqLoadingState === "loading" && <LoadingCircle />}
        <div className="button-frame" />
        {this.state.faqLoadingState === "ready" && (
          <div className="form">
            <div>
              {this.state.faq.payload.map((item, index) => {
                if (item.type === "h2") {
                  return (
                    <div key={item.type + index}>
                      <div className="spacer" />
                      <h2>{item.text}</h2>
                    </div>
                  );
                }
                if (item.type === "a") {
                  return (
                    <div className="faq-download" key={item.type + index}>
                      <a href={item.href} target="_blank">
                        <strong>
                          <i className={`fa ${item.icon}`} /> &nbsp; {item.text}
                        </strong>
                      </a>
                    </div>
                  );
                }
                if (item.type === "h3") {
                  return (
                    <div ref={item.scrollTo && ref} key={item.type + index}>
                      <div className="spacer" />
                      <h3>{item.text}</h3>
                    </div>
                  );
                }
                if (item.type === "FAQ") {
                  return (
                    <div className="faq" key={item.type + index}>
                      <div className="tiny-spacer" />
                      <div>
                        <strong>{f} </strong>
                        {item.questionText}
                      </div>
                      <div>
                        <strong>{a} </strong>
                        {item.answerText}
                      </div>
                    </div>
                  );
                }
                if (item.type === "changelog") {
                  return (
                    <div key={item.type + index}>
                      <div className="tiny-spacer" />
                      {item.version_number} ({item.release_date})
                      {item.content.map((content, index) => {
                        return (
                          <div
                            className="faq"
                            key={item.version_number + index}
                          >
                            - {content}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const Faq = injectIntl(FaqClass);
