import React from "react";
import { RequestOverlay } from "./Request/RequestOverlay";
import "moment/locale/de";
import moment from "moment";
import { defaultLocale } from "./Language";
import { dateFormat, timeFormat, longTimeFormat } from "./dateFormat";
import DatePicker from "react-datepicker";

moment.locale("de");

export class TimePeriodOverlay extends React.Component {
  constructor(props) {
    super(props);
    const from = this.props.period
      ? this.props.period.from
      : moment()
          .add(-1, "days")
          .set({ second: 0 });
    const to = this.props.period
      ? this.props.period.to
      : moment().set({ second: 0 });
    this.state = { from, to };
  }

  setFrom = (from) => {
    this.setState({ from });

    /// avoid from being after to
    if (from.isAfter(this.state.to)) {
      this.setState({ to: from });
      return;
    }

    /// avoid the time span being longer than the given max time in ms
    if (
      this.props.maxTimeInMs != null &&
      this.props.maxTimeInMs < this.state.to.diff(from)
    ) {
      this.setState({ to: from.clone().add(1, "days") });
    }
  };

  setTo = (to) => {
    this.setState({ to });

    /// avoid to being before from
    if (to.isBefore(this.state.from)) {
      this.setState({ from: to });
      return;
    }

    /// avoid the time span being longer than the given max time in ms
    if (
      this.props.maxTimeInMs != null &&
      this.props.maxTimeInMs < to.diff(this.state.from)
    ) {
      this.setState({ from: to.clone().add(-1, "days") });
    }
  };

  render() {
    const { from, to } = this.state;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction} className="">
        {this.props.children}

        <div className="overlay-content time-period-overlay">
          <div className="selection-line">
            <div className="intro">von</div>
            <DatePicker
              allowSameDay={true}
              dateFormat={dateFormat + " " + longTimeFormat}
              timeFormat={timeFormat}
              locale={moment().locale() || defaultLocale}
              maxDate={moment()}
              onChange={this.setFrom}
              popperPlacement="top"
              selected={from}
              showTimeSelect
              timeIntervals={5}
            />
          </div>
          <div className="selection-line">
            <div className="intro">bis</div>
            <DatePicker
              allowSameDay={true}
              dateFormat={dateFormat + " " + longTimeFormat}
              timeFormat={timeFormat}
              locale={moment().locale() || defaultLocale}
              maxDate={moment()}
              onChange={this.setTo}
              popperPlacement="top"
              selected={to}
              showTimeSelect
              timeIntervals={5}
            />
          </div>
        </div>
        <div className="tiny-spacer" />
        <div
          className="button time-period-overlay-button"
          onClick={() => {
            this.props.setTimePeriodCallback({ from, to });
            this.props.closeFunction();
          }}
        >
          Zeitraum anzeigen
        </div>
      </RequestOverlay>
    );
  }
}
