/**
 * Device filter type name constants. These are the values as expected by the API
 * @type {{All: string, Assigned: string, AssignedFahrtenbuch: string, Unassigned: string}}
 */
export const DeviceFilterType = {
  All: "ALL",
  Assigned: "ASSIGNED",
  Unassigned: "UNASSIGNED",
  AssignedFahrtenbuch: "ASSIGNED_FAHRTENBUCH",
};

export const DeviceFilterStatus = {
  All: "ALL",
  Free: "FREE",
  Occupied: "OCCUPIED",
};

export const PoolVehicleAllowedRouteType = {
  Private: 0,
  Public: 1,
};

export const DeviceAssignmentType = {
  NotAssigned: "NOT_ASSIGNED",
  Read: "READ",
  Write: "WRITE",
  Manage: "MANAGE",
  Fahrtenbuch: "FAHRTENBUCH",
  PoolVehicle: "POOLVEHICLE"
};

export const ComparisonType = {
  None: 0,
  LessThan: 1,
  GreaterThan: 2,
  EqualTo: 3,
};

/**
 * Endpoint: /api/user/reports/usage
 * Possible value for parameter "file_fmt"
 *
 * @type {{Excel: string, Csv: string}}
 */
export const ReportFileFormat = {
  Excel: "excel",
  Csv: "csv",
};

export const CostCategoryType = {
  User: "POOLVEHICLE_USER",
  Admin: "POOLVEHICLE_ADMINISTRATOR",
  All: "ALL",
};
