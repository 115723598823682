import React from "react";
import { saveAs } from "file-saver";
import { Portal } from "../../../Portal";
import { UserConsumer } from "../../../Base";
import { LoadingCircle } from "../../../LoadingCircle";
import { injectIntl, FormattedMessage as T, defineMessages } from "react-intl";
import { hasPortalAccess } from "../../../Permission";
import { SmartInventoryHeadline } from "./SmartInventoryHeadline";
import { SmartInventoryList } from "./SmartInventoryList";
import {
    deleteItem,
    fetchInventoryItems,
    generateItemQRCode,
    generateItemReport
} from "../../../fetch/fetchSmartInventory";
import { PaginationBar } from "../../PaginationBar";
import { SmartInventoryListFilter } from "./SmartInventoryListFilter";
import { QueryLine } from "../../../tools/QueryLine";
import CreateAndEditItemOverlay from "./Overlays/CreateAndEditItemOverlay";
import UploadCSVOverlay from "./Overlays/UploadCSVOverlay";
import ItemDetailsOverlay from "./Overlays/ItemDetailsOverlay";
import DeleteItemOverlay from "./Overlays/DeleteItemOverlay";

const messages = defineMessages({
  createItem: {
    id: "adminPanel.smartInventory.base.createItem",
    defaultMessage: "Neues Item anlegen",
  },
});

class SmartInventoryListBase extends React.Component {
  constructor(props) {
    super(props);

        this.state = {
            items: [],
            error: false,
            loading: true,
            filter: false,
            listOffset: 0,
            totalItems: 0,
            currentPage: 0,
            itemsPerPage: 15,
            itemToEdit: null,
            itemToShow: null,
            searchQuery: false,
            itemToDelete: null,
            showCSVOverlay: false,
            showDetailOverlay: false,
            showDeleteOverlay: false,
            showCreateEditOverlay: false,
            filterByReservation: false,
        };
    }

  async componentDidMount() {
    this.loadInventoryItems();
  }

  loadInventoryItems() {
    fetchInventoryItems(this.props.fetchService.jsonFetch, {
      filter: this.state.filter,
      offset: this.state.listOffset,
      limit: this.state.itemsPerPage,
      search: this.state.searchQuery,
      reservation: this.state.filterByReservation,
    })
      .then(response => {
        this.setState({
          loading: false,
          items: response.items,
          totalItems: response.total_amount,
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  render() {
    if (this.state.loading) {
      return <LoadingCircle />;
    }

    return (
      <UserConsumer>
        {user => {
          if (!hasPortalAccess(user, Portal.AdminPanelSmartInventory)) {
            return;
          }

          return this.renderSmartInventoryPage();
        }}
      </UserConsumer>
    );
  }

  renderSmartInventoryPage() {
    return (
      <div className="admin-list outer-table-frame smart-inventory-list">
        <div className="grid-x grid-margin-x align-middle">
          {this.renderSearch()}
          {this.renderFilter()}
          {this.renderCSVButton()}
          {this.renderCreateButton()}
        </div>

        <div className="smart-inventory-table table-frame">
          {this.renderItemsList()}
        </div>

        {this.renderOverlays()}

        {this.renderPagination()}
        {this.renderError()}
      </div>
    );
  }

  renderSearch() {
    return (
      <div className="auto cell">
        <QueryLine changeCallback={this.handleSearch} changeDelay={500} />
      </div>
    );
  }

  handleSearch = query => {
    return this.setState(
      {
        loading: false,
        searchQuery: query,
      },
      this.loadInventoryItems,
    );
  };

  renderFilter() {
    return (
      <SmartInventoryListFilter changeFunction={this.handleFilterChange} />
    );
  }

  handleFilterChange = event => {
    let value = false;

    if (event.target.value === "available") {
      value = 1;
    }

    if (event.target.value === "unavailable") {
      value = 0;
    }

    this.setState(
      {
        listOffset: 0,
        filterByReservation: value,
      },
      this.loadInventoryItems,
    );
  };

    renderItemsList() {
        return (
            <div className="smart-inventory-table">
                <SmartInventoryHeadline numberItems={this.state.totalItems} />
                <SmartInventoryList
                    items={this.state.items}
                    setItemToEdit={this.setItemToEdit}
                    showDetails={this.setItemToShow}
                    deleteItem={this.confirmDeleteItem}
                    downloadQRCode={this.downloadQRCode}
                    generateReport={this.generateReport}
                />
            </div>
        );
    }

  setItemToEdit = item => {
    this.setState({
      itemToEdit: item,
      showCreateEditOverlay: true,
    });
  };

  setItemToShow = item => {
    this.setState({
      itemToShow: item,
      showItemDetailOverlay: true,
    });
  };

    confirmDeleteItem = item => {
        // Only available items can be deleted
        if (item.reservation) {
            return;
        }

        this.setState({
            itemToDelete: item,
            showDeleteItemOverlay: true,
        })
    };

    deleteItem() {
        return deleteItem(this.props.fetchService.jsonFetch, this.state.itemToDelete.item_uuid)
            .then(() => this.loadInventoryItems())
            .catch(error => console.log(error));
    }

  downloadQRCode = item => {
    generateItemQRCode(this.props.fetchService.freeFetch, item.item_uuid).then(
      response => {
        response.blob().then(blob => saveAs(blob, "qrcode.pdf"));
      },
    );
  };

  generateReport = item => {
    generateItemReport(this.props.fetchService.freeFetch, item.item_uuid).then(
      response => {
        response.blob().then(data => {
          saveAs(data, `report-${item.name}.csv`);
        });
      },
    );
  };

  renderCSVButton() {
    return (
      <div className="shrink cell">
        <div className="button-frame">
          <div
            className="button green primary"
            onClick={() => this.setState({ showCSVOverlay: true })}
          >
            <T
              id="adminPanel.smartInventory.labels.csvUpload"
              defaultMessage="CSV Upload"
            />
          </div>
        </div>
      </div>
    );
  }

  renderCreateButton() {
    return (
      <div className="shrink cell">
        <div className="button-frame">
          <div
            className="button green primary"
            onClick={() =>
              this.setState({
                itemToEdit: null,
                showCreateEditOverlay: true,
              })
            }
          >
            <T
              id="adminPanel.smartInventory.labels.createInventoryItem"
              defaultMessage="Neues Item anlegen"
            />
          </div>
        </div>
      </div>
    );
  }

    renderOverlays() {
        return (
            <div>
                {this.renderCreateEditInventoryItemOverlay()}
                {this.renderCSVUploadOverlay()}
                {this.renderItemDetailsOverlay()}
                {this.renderDeleteItemOverlay()}
            </div>
        );
    }

  renderCreateEditInventoryItemOverlay() {
    if (!this.state.showCreateEditOverlay) {
      return;
    }

    return (
      <CreateAndEditItemOverlay
        itemToEdit={this.state.itemToEdit}
        closeFunction={() => {
          this.setState({ showCreateEditOverlay: false });
          this.loadInventoryItems();
        }}
        fetchService={this.props.fetchService}
        label={messages.createItem.defaultMessage}
      />
    );
  }

  renderCSVUploadOverlay() {
    if (!this.state.showCSVOverlay) {
      return;
    }

    return (
      <UploadCSVOverlay
        closeFunction={() => {
          this.setState({ showCSVOverlay: false }, this.loadInventoryItems);
        }}
        fetchService={this.props.fetchService}
        label={messages.createItem.defaultMessage}
      />
    );
  }

  renderItemDetailsOverlay() {
    if (!this.state.showItemDetailOverlay) {
      return;
    }

    return (
      <ItemDetailsOverlay
        closeFunction={() => {
          this.setState({ showItemDetailOverlay: false });
        }}
        fetchService={this.props.fetchService}
        item={this.state.itemToShow}
        label={messages.createItem.defaultMessage}
      />
    );
  }

     renderDeleteItemOverlay() {
        if (!this.state.showDeleteItemOverlay) {
            return;
        }

        return (
            <DeleteItemOverlay
                closeFunction={() => {
                    this.setState({ showDeleteItemOverlay: false });
                }}
                item={this.state.itemToDelete}
                confirm={() => {
                    this.deleteItem().then(() => this.setState({ showDeleteItemOverlay: false }))
                }}
            />
        );
    }

    renderPagination() {
        const numberMaxPages = this.getMaxPages();

    if (numberMaxPages <= 1) {
      if (this.state.currentPage > 0) {
        this.setState({ currentPage: 0 });
      }

      return;
    }

    return (
      <PaginationBar
        currentPage={this.state.currentPage}
        numberOfPages={numberMaxPages}
        updateCurrentPage={this.updateCurrentPage}
      />
    );
  }

  getMaxPages() {
    return Math.ceil(this.state.totalItems / this.state.itemsPerPage);
  }

  updateCurrentPage = pageNumber => {
    if (pageNumber === this.state.currentPage) {
      return;
    }

    this.setState(
      {
        currentPage: pageNumber,
        listOffset: pageNumber * this.state.itemsPerPage,
      },
      this.loadInventoryItems,
    );
  };

  renderError = () => {
    if (!this.state.error) {
      return;
    }
  };
}

export const SmartInventory = injectIntl(SmartInventoryListBase);
