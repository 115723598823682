
import { postJson, putJson } from "./fetchService";

const route = "/user/tags";


export const fetchTags = (jsonFetch) => ({
  assignTagsToDevice(imei, tag_ids) {
    return jsonFetch(`/user/device_tags/${imei}`, putJson({ tag_ids }));
  },
  deleteTag(tagId) {
    return jsonFetch(route + `/${tagId}`, {
      method: "DELETE",
    });
  },
  getTagList() {
    return jsonFetch(route);
  },
  setTag(tagDefinition, tagId) {
    return tagId
      ? jsonFetch(route + `/${tagId}`, putJson(tagDefinition))
      : jsonFetch(route, postJson(tagDefinition));
  },
});
