import { postJson } from "./fetchService";
import { saveAs } from "file-saver";

export const fetchReports = freeFetch => ({
  async generateReportCsv(reportType, bodyData) {
    let filename;

    return freeFetch(`/user/reports/${reportType}`, {
      ...postJson(bodyData),
      responseType: "blob",
    })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          return;
        }
        // 204: no content
        if (response.status === 204) {
          return;
        }
        for (let [header, content] of response.headers.entries()) {
          console.log("header", header, content);
          if (header === "content-disposition") {
            filename = content.match(/"(.*?)"/)[1];
          }
        }

        return response.blob();
      })
      .then(blob => {
        if (blob) {
          return saveAs(blob, filename);
        }
      });
  },
});
