import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage as T } from "react-intl";

export const GeofencingEntry = ({ portal, geofence, requestDelete }) => {
  let connectedDevicesLabel;
  if (geofence.connected_devices > 1) {
    connectedDevicesLabel = (
      <T
        id="tracar.geofencing.nDevices"
        defaultMessage="{number} Geräte zugewiesen"
        values={{ number: geofence.connected_devices }}
      />
    );
  } else if (geofence.connected_devices === 1) {
    connectedDevicesLabel = (
      <T
        id="tracar.geofencing.oneDevice"
        defaultMessage="ein Gerät zugewiesen"
      />
    );
  } else {
    connectedDevicesLabel = (
      <T
        id="tracar.geofencing.noDevices"
        defaultMessage="keine Geräte zugewiesen"
      />
    );
  }
  return (
    <div className="table-line geofence grid-x">
      <div className="cell small-12 medium-1 large-1 table-line-left">
        <i className="fa fa-globe" />
      </div>
      <div className="cell small-12 medium-auto large-auto table-line-middle">
        {geofence.name}{" "}
        <small className="note"> - {connectedDevicesLabel}</small>
      </div>{" "}
      <div className="cell small-12 medium-1 large-1 table-line-right">
        <Link
          className="button-container"
          to={`/${portal}/geofencing/${geofence.geofence_id}`}
        >
          {" "}
          <i className="fa fa-pencil button" />{" "}
        </Link>
        <div
          className="button-container"
          onClick={() => requestDelete(geofence.geofence_id)}
        >
          <i className="fa fa-trash button" />
        </div>
      </div>
    </div>
  );
};
