import React from "react";
import DatePicker from "react-datepicker";
import { RequestOverlay } from "../../Request/RequestOverlay";
import moment from "moment";
import { CustomDatePickerInput } from "../../Map/Map";
import { FormattedMessage as T } from "react-intl";

import { defaultLocale } from "../../Language";

export class PoolVehicleFahrtenbuchExport extends React.Component {
  generateCsvExport;
  generateFahrtenbuchExport;
  handleChangeEnd;
  handleChangeStart;

  constructor(props) {
    super(props);
    this.state = {
      endDate: moment(),
      startDate: moment(),
    };
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
  }

  handleChangeEnd(endDate) {
    this.setState({ endDate });
  }
  handleChangeStart(startDate) {
    this.setState({ startDate });
  }

  generateFahrtenbuchPDFExport = () => {
    const from = this.state.startDate
        .clone()
        .utc()
        .format("YYYY-MM-DDThh:mm:ss");
    const to = this.state.endDate
        .clone()
        .utc()
        .format("YYYY-MM-DDThh:mm:ss");
    //only add car_id to the ajax body if available
    let carId = undefined;
    if (Object.keys(this.props.car).length > 0) carId = this.props.car.id;

    this.props.generatePoolVehicleFahrtenbuchPdfForImei(from, to, this.props.imei, carId);
    this.props.closeFunction();
  };

  render() {
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <h5>
          <strong>
            <T
              id="tracar.fahrtenbuch.exportData2"
              defaultMessage="Daten exportieren"
            />
          </strong>
        </h5>
        <div className="overlay-content">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "3rem" }}>
              <T id="tracar.fahrtenbuch.from" defaultMessage="von:" />
            </div>
            <DatePicker
              customInput={<CustomDatePickerInput />}
              endDate={this.state.endDate}
              locale={moment().locale() || defaultLocale}
              onChange={this.handleChangeStart}
              popperPlacement="top-end"
              selected={this.state.startDate}
              selectsStart
              showMonthDropdown
              startDate={this.state.startDate}
              withPortal
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "3rem" }}>
              <T id="tracar.fahrtenbuch.to" defaultMessage="bis:" />
            </div>
            <DatePicker
              customInput={<CustomDatePickerInput />}
              endDate={this.state.endDate}
              locale={moment().locale() || defaultLocale}
              onChange={this.handleChangeEnd}
              selected={this.state.endDate}
              selectsEnd
              showMonthDropdown
              startDate={this.state.startDate}
              withPortal
            />
          </div>
          <div
            className="button button-100 save-button"
            onClick={this.generateFahrtenbuchPDFExport}
          >
            <i className="fa fa-file-pdf-o" /> &nbsp;{" "}
            <T
              id="tracar.fahrtenbuch.exportFahrtenbuchPdf"
              defaultMessage="Routenliste/Fahrtenbuch Export als PDF"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}
