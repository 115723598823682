import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { SelectInput } from "../../SelectInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  user_firstname_label: {
    id: "adminPanel.users.create.inputLabel.first_name",
    defaultMessage: "Vorname",
  },
  user_lastname_label: {
    id: "adminPanel.users.create.inputLabel.last_name",
    defaultMessage: "Nachname",
  },
  user_email_label: {
    id: "adminPanel.users.create.inputLabel.email",
    defaultMessage: "E-Mail*",
  },
  user_employeeid_label: {
    id: "adminPanel.users.create.inputLabel.employee_id",
    defaultMessage: "Employee ID*",
  },
  user_role_label: {
    id: "adminPanel.users.create.inputLabel.role",
    defaultMessage: "Rolle",
  },
  user_group_label: {
    id: "adminPanel.users.create.inputLabel.group",
    defaultMessage: "Gruppe",
  },
});

class UserCreateOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      createUserIsAutogeneratedEmail: false,
      createUserFirstname: "",
      createUserLastname: "",
      createUserEmployeeid: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    // fetch role options
    this.props.getRoleListAll().then(roleRecordList => {
      this.setState({
        loading: false,
        roleRecordList,
        // preselect first role if no role has been selected yet
        createUserRole: this.state.createUserRole
          ? this.state.createUserRole
          : roleRecordList[0].role_id,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  /**
   * Sends an API request to generate an email address.
   * Users with such email addresses are flagged as "autogenerated".
   */
  handleGenerateEmailClick() {
    this.props
      .generateEmailAddress(
        this.state.createUserFirstname,
        this.state.createUserLastname,
        this.state.createUserEmployeeid,
      )
      .then(response => {
        this.setState({
          createUserIsAutogeneratedEmail: true,
          createUserEmail: response.generated_email_id,
        });
      });
  }

  submit() {
    if (this.validate()) {
      // format user data for API
      let newUserRecord = {
        email: this.state.createUserEmail,
        unique_employee_id: this.state.createUserEmployeeid,
        first_name: this.state.createUserFirstname,
        last_name: this.state.createUserLastname,
        is_autogenerated_email: this.state.createUserIsAutogeneratedEmail,
        role_id: this.state.createUserRole,
      };
      // send user record to API
      this.props.createUser(newUserRecord).then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return (
      !isEmpty(this.state.createUserEmail) ||
      !isEmpty(this.state.createUserEmployeeid)
    );
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className="header">
          <h3>
            <T
              id="adminPanel.users.create.headline"
              defaultMessage="Neuen Benutzer erstellen"
            />
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="small-spacer" />
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell large-6">
              <TextInput
                id="createUserFirstname"
                name={t(terms.user_firstname_label)}
                className={
                  this.state.showErrorMessage &&
                  isEmpty(this.state.createUserFirstname)
                    ? "error"
                    : ""
                }
                onChange={this.handleChange}
                value={this.state.createUserFirstname}
              />
            </div>
            <div className="cell large-6">
              <TextInput
                id="createUserLastname"
                name={t(terms.user_lastname_label)}
                className={
                  this.state.showErrorMessage &&
                  isEmpty(this.state.createUserLastname)
                    ? "error"
                    : ""
                }
                onChange={this.handleChange}
                value={this.state.createUserLastname}
              />
            </div>

            <div className="cell large-6">
              <TextInput
                id="createUserEmployeeid"
                name={t(terms.user_employeeid_label)}
                className={
                  this.state.showErrorMessage &&
                  isEmpty(this.state.createUserEmployeeid)
                    ? "error"
                    : ""
                }
                onChange={this.handleChange}
                value={this.state.createUserEmployeeid}
              />
            </div>
            <div className="cell large-6">
              <div className="grid-x align-middle">
                <div className="auto cell">
                  <TextInput
                    id="createUserEmail"
                    name={t(terms.user_email_label)}
                    className={
                      this.state.showErrorMessage &&
                      isEmpty(this.state.createUserEmail)
                        ? "error"
                        : ""
                    }
                    onChange={this.handleChange}
                    value={this.state.createUserEmail}
                  />
                </div>
                <div className="shrink cell">
                  <button
                    className="transparent button small margin-0"
                    onClick={() => this.handleGenerateEmailClick()}
                  >
                    <T
                      id="adminPanel.users.create.buttonLabel.generateEmailAddress"
                      defaultMessage="E-Mail generieren"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="cell large-6">
              <SelectInput
                id="createUserRole"
                name={t(terms.user_role_label)}
                nameProp={"role_name"}
                valueProp={"role_id"}
                options={this.state.roleRecordList}
                onChange={this.handleChange}
              />
            </div>

            <div className="cell large-6">
              {/* @todo change options to group list */}
              {
                // temporarily disabled
                /*<SelectInput
                id="createUserGroup"
                name={"Gruppe"}
                nameProp={"group_name"}
                valueProp={"group_id"}
                options={[{ group_id: 1, group_name: "Not implemented yet" }]}
                onChange={this.handleChange}
              />*/
              }
            </div>
          </div>
        </div>
        <div className="small-spacer" />
        {this.state.showErrorMessage && (
          <div className="error">
            <T
              id="adminPanel.users.create.errorMessage.requiredFieldEmpty"
              defaultMessage="Bitte füllen Sie alle Pflichtfelder aus."
            />
          </div>
        )}
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.users.create.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.users.create.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const UserCreateOverlay = injectIntl(UserCreateOverlayClass);
