import React from "react";
import SmartInventoryTaskTemplatesTableLine from "./SmartInventoryTaskTemplatesTableLine";

export class SmartInventoryTaskTemplatesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return this.props.templates.map(template => (
            <SmartInventoryTaskTemplatesTableLine
                fetchService={this.props.fetchService}
                template={template}
                key={template.id}
                editTemplate={this.props.editTemplate}
                deleteTemplate={this.props.deleteTemplate}
            />
        ));
  }
}
