import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  device_name_label: {
    id: "adminPanel.devices.create.inputLabel.name",
    defaultMessage: "Name*",
  },
  device_type_label: {
    id: "adminPanel.devices.create.inputLabel.type",
    defaultMessage: "Type",
  },
});

class DeviceCreateOverlayClass extends React.Component {
  handleChange;
  submit;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showErrorMessage: false,
      selectedTypeId: "",
      selectedTagId: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getDeviceTypeList().then(typeRecordList => {
      // TODO: remove dummy data
      typeRecordList = [
        {
          id: 1,
          type_name: "Type 1",
        },
        {
          id: 2,
          type_name: "Type 2",
        },
        {
          id: 3,
          type_name: "Type 3",
        },
        {
          id: 4,
          type_name: "Type 4",
        },
      ];
      this.setState({
        loading: false,
        typeRecordList,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    if (this.validate()) {
      // this.props
      //   .createRole(this.state.createRoleName, this.state.selectedFunctions)
      //   .then(this.props.closeFunction);
      alert("Dummy confirmation of creation.");
      this.props.closeFunction();
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return !isEmpty(this.state.createDeviceName);
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className={"header"}>
          <h3>
            <T
              id="adminPanel.devices.create.headline"
              defaultMessage="Neues Gerät erstellen"
            />
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="small-spacer" />
        <TextInput
          id="createDeviceName"
          name={t(terms.device_name_label)}
          className={
            this.state.showErrorMessage && isEmpty(this.state.createDeviceName)
              ? "error"
              : ""
          }
          onChange={this.handleChange}
          value={this.state.createDeviceName}
        />
        <div className="small-spacer" />
        <div className="grid-x grid-margin-x">
          <div className="medium-6 large-6 cell relative">
            <select
              name="selectedTypeId"
              value={this.state.selectedTypeId}
              onChange={this.handleChange}
            >
              {this.state.typeRecordList &&
                this.state.typeRecordList.map((record, index) => (
                  <option value={record.id} key={index}>
                    {record.type_name}
                  </option>
                ))}
            </select>
          </div>
          <div className="medium-6 large-6 cell relative">
            <select
              name="selectedTag"
              value={this.state.selectedTagId}
              onChange={this.handleChange}
            >
              {this.props.tagList &&
                this.props.tagList.tag_id_list.map(tagId => {
                  const tagObject = this.props.tagList.tag_objects[
                    tagId.toString()
                  ];
                  return (
                    <option value={tagId} key={tagId}>
                      {tagObject.tag_name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="small-spacer" />
        {this.state.showErrorMessage && (
          <div className="error">
            <T
              id="adminPanel.devices.create.errorMessage.requiredFieldEmpty"
              defaultMessage="Bitte füllen Sie alle Pflichtfelder aus."
            />
          </div>
        )}
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.devices.create.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.devices.create.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const DeviceCreateOverlay = injectIntl(DeviceCreateOverlayClass);
