import React                                     from "react";
import { defineMessages, FormattedMessage as T } from "react-intl";
import { DeviceLine }                            from "./DeviceLine";
import { PaginationBar }                         from "../PaginationBar";
import { TrackerTableLine }                      from "../../TrackerList/TrackerTableLine";

const filterType = ["Alle Geräte", "Geräte ohne Nutzer"];

export class DeviceList extends React.Component {
  handleChange;

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      warnings: false,
      type_filter: "Alle Geräte",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({
      loading: false,
      // unwrap device records
      devices: this.props.recordList,
      warnings: !this.props.recordList.find(({ dlc }) => dlc && dlc.warned),
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    name === "filterType" && this.props.updateCurrentPage(1);
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div>
        {/*<div className="grid-x grid-margin-x align-right align-middle margin-bottom-1">
          <div className="shrink cell" htmlFor="filterType">
            <T
              id="adminPanel.devices.list.filter-type.label"
              defaultMessage="Liste filtern"
            />
          </div>
          <div className="cell shrink">
            <select
              name="selectDeviceType"
              id="filterType"
              className="margin-0"
              value={this.state.filterType}
              onChange={this.handleChange}
            >
              {filterType.map(type => {
                return (
                  <option value={type} key={type}>
                    {type}
                  </option>
                );
              })}
            </select>
          </div>
        </div>*/}
        <div className="table-frame filter-table admin-list">
          <div className="table-header">
            <i className="icon-device" />
            <T
              id="adminPanel.devices.list.headline"
              defaultMessage="Registrierte Geräte"
            />
            <span className="item-count">{this.props.numberOfDevices}</span>
          </div>
          <div className="grid-x table-line filter">
            <div className="cell small-12 medium-6 large-3">
              <a className="" href="#">
                Gerät
                <i className="fa fa-sort relative" />
              </a>
            </div>
            <div className="cell small-12 medium-6 large-2">
              <a className="" href="#">
                Kategorie
                <i className="fa fa-sort relative" />
              </a>
            </div>
            <div className="cell small-12 medium-12 large-3">IMEI</div>
            <div className="cell small-12 medium-12 large-3">TAGS</div>
            <div className="cell small-12 medium-1 large-auto" />
          </div>
          {this.props.recordList.map(device => (
            <DeviceLine
              key={device.imei}
              device={device}
              tagList={this.props.tagList}
              updateSelectedDeviceImei={this.props.updateSelectedDeviceImei}
              toggleDeviceAssignUserOverlay={
                this.props.toggleDeviceAssignUserOverlay
              }
              toggleDeviceAssignCategoryOverlay={
                this.props.toggleDeviceAssignCategoryOverlay
              }
              toggleDeviceEditOverlay={this.props.toggleDeviceEditOverlay}
            />
          ))}
        </div>

        <PaginationBar
          currentPage={this.props.currentPage}
          numberOfPages={this.props.numberOfPages}
          updateCurrentPage={this.props.updatePagination}
        />
      </div>
    );
  }
}
