import React from "react";
import moment from "moment";
import { SketchPicker } from 'react-color';
import {TextInput} from "../../../../TextInput";
import {defineMessages, injectIntl, FormattedMessage as T} from "react-intl";
import {createNewInventoryItem, getItemLocation, updateItem} from "../../../../fetch/fetchSmartInventory";
import SimpleMap from "../../../../Map/SimpleMap";
import {Marker} from "react-google-maps";

const messages = defineMessages({
    name: {
        id: "adminPanel.smartInventory.createEditItem.name",
        defaultMessage: "Name",
    },
    description: {
        id: "adminPanel.smartInventory.createEditItem.description",
        defaultMessage: "Beschreibung",
    },
    serialNumber: {
        id: "adminPanel.smartInventory.createEditItem.serialNumber",
        defaultMessage: "Seriennummer",
    },
    color: {
        id: "adminPanel.smartInventory.createEditItem.color",
        defaultMessage: "Farbe (Hexadezimal)",
    },
});

const formName = 'createEditForm';

class CreateAndEditItemsForm extends React.Component {
    constructor(props) {
        super(props);

        const defaultData = {
            name: null,
            color: null,
            description: null,
            serialNumber: null,
        };

        this.state = {
            data: this.props.data ? this.props.data : defaultData,
            location: null,
            showColorPicker: false,
        };
    }

    componentDidMount() {
        if (!this.props.itemToEdit) {
            return;
        }

        getItemLocation(this.props.fetchService.jsonFetch, this.props.itemToEdit.item_uuid)
            .then((response) => this.setState({
                location: response
            }));
    }

    render() {
        return (
            <div>
                <div className="grid-container" style={{paddingTop: '1.5rem'}}>
                    { this.renderInputs() }
                    { this.renderReservationDetails() }
                     <div className={'location-container'}>
                        { this.renderLocationInformation() }
                     </div>
                </div>
            </div>
        );
    }

    renderInputs() {
        return (
            <div className="grid-x grid-padding-x">
                <div className="cell large-4 small-6">
                    <TextInput
                        id="name"
                        onChange={(event) => {
                            this.setState({ data: {
                                ...this.state.data,
                                name: event.target.value
                            }, }, () => this.props.updateData(formName, this.state.data));
                        }}
                        value={this.state.data.name}
                        name={this.props.intl.formatMessage(messages.name) + '*'}
                    />
                </div>
                <div className="cell large-4 small-6">
                    <TextInput
                        id="description"
                        onChange={(event) => {
                            this.setState({ data: {
                                ...this.state.data,
                                description: event.target.value
                            }, }, () => this.props.updateData(formName, this.state.data));
                        }}
                        value={this.state.data.description}
                        name={this.props.intl.formatMessage(messages.description)  + '*'}
                    />
                </div>
                <div className="cell large-2 small-6">
                    <TextInput
                        id="serial-number"
                        onChange={(event) => {
                            this.setState({ data: {
                                ...this.state.data,
                                serialNumber: event.target.value
                            }, }, () => this.props.updateData(formName, this.state.data));
                        }}
                        value={this.state.data.serialNumber}
                        name={this.props.intl.formatMessage(messages.serialNumber)  + '*'}
                    />
                </div>
                <div className="cell large-2 small-6 color-input-container">
                    <TextInput
                        id="color"
                        onChange={(event) => {
                            this.setState({ data: {
                                ...this.state.data,
                                color: event.target.value
                            }, }, () => this.props.updateData(formName, this.state.data));
                        }}
                        onClick={() => this.setState({showColorPicker: true})}
                        value={this.state.data.color}
                        name={this.props.intl.formatMessage(messages.color)}
                    />
                    <div className={'color-display'} style={{backgroundColor: this.state.data.color}}></div>
                    <div
                        className={`color-picker-container`}
                        onMouseLeave={() => this.setState({showColorPicker: false})}
                    >
                        {this.renderColorPicker()}
                    </div>
                </div>
            </div>
        );
    }

    renderColorPicker() {
        if (!this.state.showColorPicker) {
            return;
        }

        return <SketchPicker
            color={ this.state.data.color || '#e60000' }
            onChangeComplete={ this.selectColor }
        />
    }

    selectColor = (color) => {
        if (!color) {
            return;
        }

        this.setState({data: {
            ...this.state.data,
            color: color.hex
        }}, () => this.props.updateData(formName, this.state.data))
    }

    renderReservationDetails() {
        if (!this.props.itemToEdit) {
            return;
        }

        const available = !this.props.itemToEdit.reservation;

        return (
            <div className="cell reservation-details bold text-left">
                <T id="adminPanel.smartInventory.createEditItem.availability" defaultMessage="Verfügbarkeit">
                    {txt => <span className={'grey-text'}>{txt}</span>}
                </T>
                <span className={'availability-indicator ' + (available ? 'available' : 'unavailable')}></span>
                { (!available && (
                    <span>
                        <T id="adminPanel.smartInventory.createEditItem.reservedBy" defaultMessage="Reserviert von"/>
                        <span className="customer">
                            {this.props.itemToEdit.customer.first_name} {this.props.itemToEdit.customer.last_name}
                        </span>
                        <T id="adminPanel.smartInventory.createEditItem.since" defaultMessage="seit"/>
                        <span className="reservation-date">
                            {
                                moment(this.props.itemToEdit.reservation.start_date)
                                    .format('DD.MM.YYYY HH:MM')
                            }
                        </span>
                        <T id="adminPanel.smartInventory.createEditItem.oClock" defaultMessage="Uhr"/>
                    </span>
                ))}
            </div>
        );
    }

    renderLocationInformation() {
        if (!this.props.itemToEdit) {
            return;
        }

        if (!this.state.location || !this.state.location.lat || !this.state.location.lon) {
            return this.renderLocationString();
        }

        return this.renderLocationMap();
    }

    renderLocationString() {
        const locationString = this.props.itemToEdit.location ?
            this.props.itemToEdit.location :
            <T
                id="adminPanel.smartInventory.createEditItem.locationNoFound"
                defaultMessage="Für dieses Objekt liegen keine Standortdaten vor"
            >
                {txt => <span className={'grey-text'}>{txt}</span>}
            </T>;

        return (
            <div className={'bold text-left location-information cell large-2 small-6 text-left'}>
                <T id="adminPanel.smartInventory.createEditItem.location" defaultMessage="Standort">
                    {txt => <span className={'grey-text'}>{txt}</span>}
                </T>
                <div className={'location-string'}>{locationString}</div>
            </div>
        );
    }

    renderLocationMap() {
        const position = {lat: this.state.location.lat, lng: this.state.location.lon};

        return (
            <div className={'text-left'}>
                <T id="adminPanel.smartInventory.itemDetail.locationMap" defaultMessage="Karte des Objekts">
                    {message => <div className={'grey-text'}>{message}</div>}
                </T>
                <SimpleMap
                    containerElement={<div className="simple-map-container"/>}
                    mapElement={<div className="simple-map"/>}
                    center={position}
                >
                    <Marker position={position}/>
                </SimpleMap>
            </div>
        );
    }
}

export default injectIntl(CreateAndEditItemsForm);
