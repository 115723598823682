import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../TextInput";

const terms = defineMessages({
  fname: {
    id: "tracar.rfid-chip-add.fname",
    defaultMessage: "Vorname:",
    label: "Vorname",
  },
  lname: {
    id: "tracar.rfid-chip-add.lname",
    defaultMessage: "Nachname:",
    label: "Nachname",
  },
});

class SettingsOverlayClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fname: this.props.chipToUpdate.fname,
      lname: this.props.chipToUpdate.lname,
    };
  }

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="confirm-customer-addresses-delete"
        enableCloseOnOverlayClicked={true}
      >
        {this.renderConfirmation()}
      </RequestOverlay>
    );
  }

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderConfirmation = () => {
    return (
      <div>
        {this.renderHeader()}
        {this.renderButtons()}
      </div>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="tracar.rfid-chip-list.settings.headline"
            defaultMessage="RFID-Chip Einstellungen"
          />
        </h5>
      </div>
    );
  };

  handleChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  /**
   * Renders the buttons
   * @return JSX
   */
  renderButtons = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="confirm-button-container">
        <div className="grid-x grid-margin-x">
          <TextInput
            className="medium-6 large-6 cell"
            id="fname"
            name={t(terms.fname)}
            onChange={this.handleChange}
            type="text"
            value={this.state.fname}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="lname"
            name={t(terms.lname)}
            onChange={this.handleChange}
            type="text"
            value={this.state.lname}
          />
        </div>

        <div className="spacer" />
        <div
          className="button"
          onClick={this.props.closeFunction}
          style={{ marginRight: "15px" }}
        >
          <span>
            <T
              id="tracar.rfid-chip-list.settings.cancel"
              defaultMessage="Abbrechen"
            />
          </span>
        </div>

        <div
          className="button save-button"
          onClick={() =>
            this.props.updateChip(this.state.fname, this.state.lname)
          }
        >
          <span>
            <T
              id="tracar.rfid-chip-list.settings.save"
              defaultMessage="Änderungen speichern"
            />
          </span>
        </div>
      </div>
    );
  };
}

export const SettingsOverlay = injectIntl(SettingsOverlayClass);
