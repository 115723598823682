export const Portal = {
  All: "all",
  Auto: "auto",
  Vehicle: "vehicle",
  Asset: "asset",
  Person: "person",
  PoolVehicle: "poolvehicle",
  AdminPanel: "admin-panel",
  AdminPanelUser: "user",
  AdminPanelDevice: "device",
  AdminPanelRole: "role",
  AdminPanelUserGroup: "user-group",
  AdminPanelRfidChipList: "rfid-chip-list",
  AdminPanelCustomerAddress: "customerAddresses",
  AdminPanelServiceInterval: "serviceIntervals",
  AdminPanelPoolVehicle: "poolVehicle",
  AdminPanelExpense: "expense",
  AdminPanelSmartInventory: 'smartInventory',
};

/// `default` is a fallback option for users with no devices and no portals
/// attached
export const PortalDefault = "default";

/// also defines the order of the portals in the menu
export const portals = [
  Portal.All,
  Portal.Auto,
  Portal.Vehicle,
  Portal.Asset,
  Portal.Person,
  Portal.PoolVehicle,
];

export const adminPortals = [
  Portal.AdminPanelUser,
  Portal.AdminPanelDevice,
  Portal.AdminPanelRole,
  Portal.AdminPanelRfidChipList,
  Portal.AdminPanelCustomerAddress,
  Portal.AdminPanelServiceInterval,
  Portal.AdminPanelPoolVehicle,
  Portal.AdminPanelExpense,
  Portal.AdminPanelSmartInventory,
];
