import React from "react";
import { defineMessages, injectIntl, FormattedMessage as T } from "react-intl";
import SmartInventoryTaskTemplatesTableLineTasks from "./SmartInventoryTaskTemplatesTableLineTasks";
import {DropdownItems, DropdownMenu} from "../../../tools/DropdownMenu";

const messages = defineMessages({
    delete: {
        id: "adminPanel.smartInventoryTaskTemplates.lineItem.deleteTitle",
        defaultMessage: "Löschen",
    },

    info: {
        id: "adminPanel.smartInventoryTaskTemplates.lineItem.infoTitle",
        defaultMessage: "Ausklappen",
    },

    edit: {
        id: "adminPanel.smartInventoryTaskTemplates.lineItem.editTitle",
        defaultMessage: "Editieren",
    },
});

class SmartInventoryTaskTemplatesTableLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isExtended: false,
        };
    }

    render() {
        return (
            <div className={`table-line ` + (this.state.isExtended ? 'extended' : '')}>
                <div className={"grid-x grid-margin-x relative"}>
                    <div className="cell small-3 font-black template-name">
                        <div className="font-bold" onClick={() => this.setState({isExtended: !this.state.isExtended})}>
                            <span
                                className={`fa ${this.state.isExtended ? 'fa-angle-down' : 'fa-angle-right'}`}
                            ></span>
                            <span className={'task-template-name'}>{this.props.template.name}</span>
                        </div>
                    </div>

                    <div className={'cell auto table-line-right'}>
                        <div className={`button-container`}>
                            <div className={"button detail-button"}>
                                <span
                                    className={'fa fa-info'}
                                    title={this.props.intl.formatMessage(messages['info'])}
                                    onClick={() => this.setState({isExtended: !this.state.isExtended})}
                                ></span>
                            </div>
                        </div>
                        { this.renderDropdown() }
                    </div>
                </div>
                <SmartInventoryTaskTemplatesTableLineTasks
                    isVisible={this.state.isExtended}
                    template={this.props.template}
                    fetchService={this.props.fetchService}
                />
            </div>
        );
    }

    renderDropdown() {
        return (
            <div className={'button-container'}>
                <DropdownMenu>
                    <DropdownItems>
                        <li onClick={() => this.props.editTemplate(this.props.template)}>
                            <i className="fa fa-pencil" />
                            <T
                                id="adminPanel.roles.submenu.label.edit"
                                defaultMessage="Bearbeiten"
                            />
                        </li>
                        <li onClick={() => this.props.deleteTemplate(this.props.template)}>
                            <i className="fa fa-trash" />
                            <T
                                id="adminPanel.roles.submenu.label.delete"
                                defaultMessage="Löschen"
                            />
                        </li>
                    </DropdownItems>
                </DropdownMenu>
            </div>
        );
    }
}

export default injectIntl(SmartInventoryTaskTemplatesTableLine);
