import React from "react";
import { dateFormat } from "./dateFormat";

export function CustomWeekPickerInput(props) {
  return (
    <button
      className={"button" + (props.active ? " active" : "")}
      onClick={props.onClick}
    >
      {props.selectedDate.from.format(dateFormat)}
      {" - "}
      {props.selectedDate.to.format(dateFormat)}
    </button>
  );
}
