import { postJson, deleteJson } from "./fetchService";

const serviceIntervalRoute = "/user/service/service_interval";
const enumTypesRoute = "/user/service/enums";
const isCompleteRoute = "/user/service/intervaliscomplete";
const multipleServiceIntervalRoute =
  "/user/service/service_interval_createinterval";

export default function fetchServiceInterval(jsonFetch) {
  const getServiceIntervals = async () => {
    return jsonFetch(serviceIntervalRoute);
  };

  const getDeviceServiceIntervals = async imei => {
    return jsonFetch(serviceIntervalRoute + "/" + imei);
  };

  const createServiceInterval = async serviceInterval => {
    return jsonFetch(
      serviceIntervalRoute,
      postJson({
        ...serviceInterval,
      }),
    );
  };

  const createMultipleServiceIntervals = async serviceInterval => {
    return jsonFetch(
      multipleServiceIntervalRoute,
      postJson({
        ...serviceInterval,
      }),
    );
  };

  const deleteServiceInterval = async (id, user) => {
    return jsonFetch(
      serviceIntervalRoute,
      deleteJson({
        service_interval_id: id,
        user: user,
      }),
    );
  };

  const getEnumTypes = async () => {
    return jsonFetch(enumTypesRoute);
  };

  const setIntervalCompletion = async (status, id, imei) => {
    return jsonFetch(
      isCompleteRoute,
      postJson({ completed: status, service_interval_id: id, imei: imei }),
    );
  };

  return {
    getServiceIntervals,
    getDeviceServiceIntervals,
    createServiceInterval,
    createMultipleServiceIntervals,
    deleteServiceInterval,
    getEnumTypes,
    setIntervalCompletion,
  };
}
