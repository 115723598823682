import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import moment from "moment";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";
import { ServiceIntervalForm, FormMode } from "./ServiceIntervalForm";

// Translations
const terms = defineMessages({
  header_new_title: {
    id: "service.interval.new.header",
    defaultMessage: "Serviceintervall anlegen",
  },
  header_edit_title: {
    id: "service.interval.edit.header",
    defaultMessage: "Serviceintervall bearbeiten",
  },
  device_label: {
    id: "service.interval.new.device.label",
    defaultMessage: "Gerät",
  },
  type_name_label: {
    id: "service.interval.new.type.label",
    defaultMessage: "Service Typ angeben",
  },
  type_description_label: {
    id: "service.interval.new.description.label",
    defaultMessage: "Service Beschreibung",
  },
  interval_date: {
    id: "service.interval.new.date.label",
    defaultMessage: "Datum",
  },
  interval_range: {
    id: "service.interval.new.range.label",
    defaultMessage: "Kilometer",
  },
  interval_threshold: {
    id: "service.interval.new.distance.label",
    defaultMessage: " ",
  },
  errors_upload_failed: {
    id: "service.interval.new",
    defaultMessage:
      "Fehler beim Erstellen des Intervalls. Bitte versuchen Sie es erneut.",
  },
  turnus_type_label: {
    id: "service.interval.turnus",
    defaultMessage: "Periode",
  },
  start_date: {
    id: "service.interval.startDate",
    defaultMessage: "Startdatum",
  },
  start_value: {
    id: "service.interval.startValue",
    defaultMessage: "Startwert",
  },
  set_service_interval: {
    id: "service.interval.label.set",
    defaultMessage: "Serviceintervall konfigurieren",
  },
  cancel: {
    id: "service.interval.label.cancel",
    defaultMessage: "Abbrechen",
  },
  save: {
    id: "service.interval.label.save",
    defaultMessage: "Speichern",
  },
  timePeriodType: {
    day: {
      id: "service.interval.label.timePeriodType.day",
      defaultMessage: "Tage",
    },
    week: {
      id: "service.interval.label.timePeriodType.week",
      defaultMessage: "Wochen",
    },
    month: {
      id: "service.interval.label.timePeriodType.month",
      defaultMessage: "Monate",
    },
    year: {
      id: "service.interval.label.timePeriodType.year",
      defaultMessage: "Jahre",
    },
  },
  dateFormMode: {
    fixedDate: {
      id: "service.interval.label.dateFormMode.fixedDate",
      defaultMessage: "nach festem Datum",
    },
    timePeriod: {
      id: "service.interval.label.dateFormMode.timePeriod",
      defaultMessage: "nach Zeitspanne",
    },
  },
  thresholdFormMode: {
    distance: {
      id: "service.interval.label.thresholdFormMode.distance",
      defaultMessage: "Kilometer",
    },
    time: {
      id: "service.interval.label.thresholdFormMode.time",
      defaultMessage: "Laufzeit",
    },
  },
  thresholdDistanceFormMode: {
    fixedDistance: {
      id: "service.interval.label.thresholdDistanceFormMode.fixedDistance",
      defaultMessage: "Nach Kilometerstand",
    },
    runningDistance: {
      id: "service.interval.label.thresholdDistanceFormMode.runningDistance",
      defaultMessage: "Nach Laufleistung",
    },
  },
  thresholdOperatingTimeFormMode: {
    fixedOperatingTime: {
      id:
        "service.interval.label.thresholdOperatingTimeFormMode.fixedOperatingTime",
      defaultMessage: "Nach Endstundenstand",
    },
    runningOperatingTime: {
      id:
        "service.interval.label.thresholdOperatingTimeFormMode.runningOperatingTime",
      defaultMessage: "Nach Betriebsstunden",
    },
  },
});

const TimePeriodType = {
  Day: "day",
  Week: "week",
  Month: "month",
  Year: "year",
};

const DateFormMode = {
  fixedDate: "fixedDate",
  timePeriod: "timePeriod",
};

const ThresholdFormMode = {
  distance: "distance",
  operatingTime: "operatingTime",
};

const ThresholdDistanceFormMode = {
  fixedDistance: "fixedDistance",
  runningDistance: "runningDistance",
};

const ThresholdOperatingTimeFormMode = {
  fixedOperatingTime: "fixedOperatingTime",
  runningOperatingTime: "runningOperatingTime",
};

class CreateServiceIntervalOverlayClass extends React.Component {
  handleChange;

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: true,
      devices: [],
      deviceImei: this.props.serviceInterval
        ? this.props.serviceInterval.imei
        : "",
      enumTypes: [],
      userEmail: "",
      serviceIntervalId: this.props.serviceInterval
        ? this.props.serviceInterval.service_interval_id
          ? this.props.serviceInterval.service_interval_id
          : ""
        : "",
      serviceType: this.props.serviceInterval
        ? this.props.serviceInterval.service_type_choices
          ? this.props.serviceInterval.service_type_choices
          : ""
        : "",
      typeDescription: this.props.serviceInterval
        ? this.props.serviceInterval.service
          ? this.props.serviceInterval.service
          : ""
        : "",
      thresholdType: this.props.serviceInterval
        ? this.props.serviceInterval.threshold_type_choices
          ? this.props.serviceInterval.threshold_type_choices
          : ""
        : "",
      turnusType: "once",
      turnusTypeValue: 1,
      showDateOption: true,
      showThresholdOption: this.props.serviceInterval
        ? this.props.serviceInterval.threshold
          ? true
          : false
        : "",
      startDate: moment(),
      fixedDate: moment(),
      timePeriodType: TimePeriodType.Day,
      timePeriodCount: 0,
      dateFormMode: DateFormMode.fixedDate,
      thresholdFormMode: ThresholdFormMode.distance,
      thresholdDistanceFormMode: ThresholdDistanceFormMode.fixedDistance,
      thresholdOperatingTimeFormMode:
        ThresholdOperatingTimeFormMode.fixedOperatingTime,
      plannedDates: [
        this.props.serviceInterval
          ? this.props.serviceInterval.planned_service_date
            ? moment(this.props.serviceInterval.planned_service_date)
            : moment()
          : moment(),
      ],
      thresholds: [
        this.props.serviceInterval
          ? this.props.serviceInterval.threshold
            ? this.props.serviceInterval.threshold
            : 1000
          : 1000,
      ],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  createServiceInterval = (user, intervalData) => {
    this.setState({ uploading: true });

    this.props
      .createServiceInterval(intervalData)
      .then(res => {
        this.setState({ uploading: false });
        this.props.updateServiceIntervals();
        this.props.closeFunction();
      })
      .catch(error => {
        this.setState({ error, uploading: false });
      });
  };

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return (
      <div className="new-interval-modal">
        <RequestOverlay
          closeFunction={this.props.closeFunction}
          className="import-customer-addresses"
          enableCloseOnOverlayClicked={true}
        >
          {this.state.uploading
            ? this.renderLoadingCircle()
            : this.renderInterface()}

          {this.renderError()}
        </RequestOverlay>
      </div>
    );
  }

  /**
   * Renders the loading circle
   * @return LoadingCircle
   */
  renderLoadingCircle = () => {
    return <LoadingCircle />;
  };

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderInterface = () => {
    return (
      <UserConsumer>
        {user =>
          hasPortalAccess(user, Portal.AdminPanelServiceInterval) && (
            <div>
              {this.renderHeader()}
              {this.renderBody()}
            </div>
          )
        }
      </UserConsumer>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="headline">
        <i className="icon-wrench" />
        <div>{t(terms.header_new_title)}</div>
      </div>
    );
  };

  /**
   * Render the body
   * @return JSX
   */
  renderBody = () => {
    const t = this.props.intl.formatMessage;

    return (
      <ServiceIntervalForm
        formMode={FormMode.Create}
        closeFunction={this.props.closeFunction}
        fetchDevices={this.props.fetchDevices}
        onFormSubmit={this.createServiceInterval}
        userEmail={this.props.userEmail}
        getEnumTypes={this.props.getEnumTypes}
        updateServiceIntervals={this.props.load}
      />
    );
  };

  renderError = () => {
    if (!this.state.error) {
      return null;
    }

    return (
      <div className="error">
        <T
          id="customer.addresses.errors.uploadFailed"
          defaultMessage="Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut."
        />
      </div>
    );
  };
}

export const CreateServiceIntervalOverlay = injectIntl(
  CreateServiceIntervalOverlayClass,
);
