import React from "react";
import moment from "moment";

import { getWeekFromDate } from "../../../utility";
import { PoolVehicleFahrtenbuch } from "./Fahrtenbuch";

export const FahrtenbuchOnDemanStatus = {
  GENERATING: "GENERATING",
  STARTED: "STARTED",
  PENDING: "PENDING",
  RETRY: "RETRY",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export class PoolVehicleUserFahrtenbuchBase extends React.Component {
  handleDateChange;
  reload;
  toggleExportOverlay;

  constructor(props) {
    super(props);

    let onDemandUuid;

    /** make sure that a misformed save does not ruin the app by causing a crash
     * in the constructor */
    try {
      if (localStorage.getItem("onDemandUuid")) {
        onDemandUuid = JSON.parse(localStorage.getItem("onDemandUuid"));
      } else {
        onDemandUuid = "";
      }
    } catch (e) {
      console.error(e);
      onDemandUuid = "";
    }

    this.state = {
      fahrtenbuchData: [],
      selectedDate: getWeekFromDate(moment()),
      trackerDetail: null,
      onDemandFinishStatus: onDemandUuid === "" ? true : false,
      onDemandFinishState: "",
      username: undefined,
    };
    this.reload = this.reload.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.fetchFahrtenbuch = this.fetchFahrtenbuch.bind(
      this,
    );
  }

  handleDateChange(date) {
    this.setState({ selectedDate: getWeekFromDate(date) }, () => this.load());
  }

  reload() {
    this.load();
  }

  load() {
    this.fetchFahrtenbuch(this.props.match.params.id);
    this.props.getUserList().then((result) => {
      this.setState({username: result.user_data.find((u) => u.user.id == this.props.match.params.id)})
    })
  }

  fetchFahrtenbuch(id) {
    const from = this.state.selectedDate.from
      .clone()
      .utc()
      .format("YYYY-MM-DD\\THH:mm:ss");
    const to =  this.state.selectedDate.to
      .clone()
      .utc()
      .format("YYYY-MM-DD\\THH:mm:ss");

    return this.props
      .getPoolVehicleFahrtenbuchForUser(from, to, id)
      .then(res => {
        // filter out private routes
        this.setState({ fahrtenbuchData: res.filter((e) => e.type !== 2) });
        return res;
      })
      .catch(err => console.warn("Error caught ", err));
  }

  componentWillMount() {
    this.load();
  }

  render() {
    const portal = this.props.match.params.portal || "";
    return (
      <div>
        <PoolVehicleFahrtenbuch
          editFahrtenbuchEntry={this.props.editFahrtenbuchEntry}
          fahrtenbuchData={this.state.fahrtenbuchData}
          handleDateChange={this.handleDateChange}
          reload={this.reload}
          selectedDate={this.state.selectedDate}
          suggestCustomer={this.props.suggestCustomer}
          trackerDetail={this.state.trackerDetail}
          portal={portal}
          fetchFahrtenbuch={this.fetchFahrtenbuch}
          onDemandFinishStatus={this.state.onDemandFinishStatus}
          onDemandFinishState={this.state.onDemandFinishState}
          userId={this.props.match.params.id}
          username={this.state.username}
        />
      </div>
    );
  }
}
