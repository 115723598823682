import React from "react";
import { Map } from "immutable";

import { Message } from "./Message";

export class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      deleteEntry,
      markedMessages,
      messageList,
      toggleMessageMark,
      toggleMapOverlay,
    } = this.props;
    return (
      <div>
        {messageList.messages.map(message => (
          <Message
            key={message.message_id}
            deleteEntry={deleteEntry}
            message={message}
            markCurrentMessageAsRead={this.props.markAsRead(message.message_id)}
            markedMessages={markedMessages}
            toggleMessageMark={toggleMessageMark}
            toggleMapOverlay={toggleMapOverlay}
          />
        ))}
        {messageList.total_amount === 0 && (
          <div className="table-line geofence">
            {/* TODO translate */}
            <strong>Es liegen keine Nachrichten vor.</strong>
          </div>
        )}
      </div>
    );
  }
}
