import { postJson, putJson, postCSV } from "./fetchService";

const url = "/admin_user";

/**
 * if user_input is "" the suggestions will be based on distance to the route
 * if user_input is given it will be an alphabetically sorted search result
 */

export const fetchCustomerAddresses = jsonFetch => ({
  suggestCustomer: (fahrtenbuch_id, user_input) => {
    return jsonFetch(
      `/user/suggest_customer`,
      postJson({ fahrtenbuch_id, user_input }),
    );
  },

  addNewCustomerAddress: customerAddress => {
    return jsonFetch(`${url}/cust_addr`, postJson(customerAddress));
  },

  editCustomerAddress: customerAddress => {
    return jsonFetch(
      `${url}/cust_addr/${customerAddress.customer_address_id}`,
      putJson(customerAddress),
    );
  },

  getPaginatedCustomerAddresses: (offset, limit, search_key = "") => {
    return jsonFetch(
      `${url}/paginated_cust_addr`,
      postJson({ offset, limit, search_key }),
    );
  },

  getCustomerAddresses: () => {
    return jsonFetch(`${url}/cust_addr`);
  },

  /**
   * Load single address
   * @param  integer id
   * @return Promise
   */
  getCustomerAddress: id => {
    return jsonFetch(`${url}/cust_addr/${id}`);
  },

  deleteCustomerAddress: id => {
    return jsonFetch(`${url}/cust_addr/${id}`, {
      method: "DELETE",
    });
  },

  uploadCustomerAddresses: file => {
    return jsonFetch(`${url}/upload_cust_addr`, postCSV(file));
  },

  bulkDeleteCustomerAddresses: () => {},
});
