import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import { RoleCreateOverlay } from "../Role/RoleCreateOverlay";
import { RoleCreateInline } from "../Role/RoleCreateInline";
import { SelectInput } from "../../SelectInput";
import { AdminPanelRoleBase } from "../Role/RoleBase";

const terms = defineMessages({
  text_new_role_button: {
    id: "adminPanel.users.assignRole.buttonLabel.createRole",
    defaultMessage: "Neue Rolle anlegen",
  },
});

class UserAssignRoleInlineClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getRoleListAll().then(roleRecordList => {
      // fallback to first role of the list if all else fails
      let selectedRole = roleRecordList[0].role_id;

      // preselect already assigned role if there is only one user selected
      if (
        this.props.assignRoleUserRecords.length === 1 &&
        this.props.assignRoleUserRecords[0].user.role.role_id
      ) {
        selectedRole = this.props.assignRoleUserRecords[0].user.role.role_id;
      }

      // retain already selected role
      if (this.state.selectedRole) {
        selectedRole = this.state.selectedRole;
      }

      this.setState({
        loading: false,
        roleRecordList,
        selectedRole,
      });
    });
  }

  submit() {
    // assume a list of users since this component is used by UserAssignRoleOverlay and UserAssignRoleMultipleOverlay
    this.props
      .assignUsersToRole(
        parseInt(this.state.selectedRole),
        this.props.assignRoleUserRecords.map(user => user.user.id),
      )
      .then(this.props.closeFunction);
  }

  toggleInnerCreateOverlay = new_role_id => {
    this.props.getRoleListAll().then(roleRecordList => {
      this.setState(
        {
          loading: false,
          roleRecordList,
          selectedRole: new_role_id,
        },
        () => this.props.toggleInnerCreateOverlay(),
      );
    });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div>
        {!this.props.showInnerCreateOverlay && (
          <React.Fragment>
            <h4 className="text-left">
              <T
                id="adminPanel.users.assignRole.active_roles"
                defaultMessage="Aktive Nutzerrollen"
              />{" "}
              <span className="item-count">
                {this.state.roleRecordList
                  ? this.state.roleRecordList.length
                  : 0}
              </span>
            </h4>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="medium-auto cell relative">
                  <SelectInput
                    id="selectedRole"
                    // name={t(terms.user_role_label)}
                    value={this.state.selectedRole}
                    nameProp={"role_name"}
                    valueProp={"role_id"}
                    options={this.state.roleRecordList}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="medium-shrink cell relative">
                  <div
                    className="button blue primary width-100"
                    onClick={this.props.toggleInnerCreateOverlay}
                  >
                    {t(terms.text_new_role_button)}
                  </div>
                </div>
              </div>
            </div>
            <div className="small-spacer" />
          </React.Fragment>
        )}

        {this.props.showInnerCreateOverlay && (
          <RoleCreateInline
            closeFunction={this.toggleInnerCreateOverlay}
            createRole={this.props.createRole}
            getPermissionList={this.props.getPermissionList}
            showInnerSaveButtons={true}
          />
        )}
        {!this.props.showInnerCreateOverlay && (
          <React.Fragment>
            <div className="small-spacer" />
            <div className="footer text-right">
              <div
                className="button transparent"
                onClick={this.props.closeFunction}
              >
                <T
                  id="adminPanel.users.assignRole.buttonLabel.cancel"
                  defaultMessage="Abbrechen"
                />
              </div>
              <div className="button green primary" onClick={this.submit}>
                <T
                  id="adminPanel.users.assignRole.buttonLabel.submit"
                  defaultMessage="Speichern"
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export const UserAssignRoleInline = injectIntl(UserAssignRoleInlineClass);
