
import React from "react";



export class ProfileDriversLicenseRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fleetId: null,
      loading: "loading",
    };
  }

  componentDidMount() {
    this.props.driversLicenseCheck().then(res => {
      const userInformation = res.user_information.find(
        ({ user }) => user.email === this.props.userLoginData.email
      );
      const fleetId = userInformation && userInformation.dlc.dlc_barcode;
      this.setState({ fleetId });
    });
  }

  render() {
    return (
      <div className="form">
        <h2>fleet iD Führerscheinprüfung</h2>
        {this.state.fleetId == null && (
          <div>
            <div>
              Sie erhalten für die Elektronische Führerscheinkontrolle einen
              fleet iD Hologramm-Barcode, welcher auf dem Führerschein
              aufgebracht wird. Sie müssen diesen Barcode im Portal registrieren
              um die Führerscheinkontrolle durchführen zu können.{" "}
            </div>
            <div className="small-spacer" />
            <div className="button" onClick={this.props.toggleRegisterFleetId}>
              Fleet-ID registrieren
            </div>
          </div>
        )}
        {this.state.fleetId != null && (
          <div>
            Ihr Führerschein ist auf die fleet iD {this.state.fleetId}{" "}
            registriert. Sollte diese Id nicht (mehr) stimmen kontaktieren Sie
            bitte Ihren Fuhrparkleiter.
          </div>
        )}
      </div>
    );
  }
}
