import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../TextInput";
import Link from "react-router-dom/es/Link";
import { Portal } from "../Portal";
import { NewCar } from "../TrackerDetail/NewCar";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  identificationNumber: {
    id: "tracar.device.identificationNumber",
    defaultMessage: "Identifikationsnummer",
  },
  description: {
    id: "tracar.device.description",
    defaultMessage: "Beschreibung",
  },
  driver: {
    id: "tracar.driver",
    defaultMessage: "Fahrer",
  },
  setIgnitionTime: {
    id: "tracar.device.setOperatingHours",
    defaultMessage: "Betriebsstunden einstellen",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
});

class UtilityTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.utilitySettings,
      ...this.props.userSettings,
      showNewCarOverlay: false,
    };

    this.toggleNewCarOverlay = this.toggleNewCarOverlay.bind(this);
  }

  componentWillUnmount() {
    this.rememberSettings();
    this.rememberUserSettings();
  }

  rememberSettings = () => {
    let settings = this.props.utilitySettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null) {
        settings[setting[0]] = this.state[setting[0]];
      }
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  rememberUserSettings = () => {
    let settings = this.props.userSettings;
    if (!settings) {
      return;
    }
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]])
        settings[setting[0]] = isNaN(parseInt(this.state[setting[0]]))
          ? this.state[setting[0]]
          : parseInt(this.state[setting[0]]);
    });

    this.props.updateDeviceSettings("user_settings", settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();
    this.rememberUserSettings();

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: this.props.utilitySettings,
    });

    Object.assign(settingsContainer, {
      user_settings: this.props.userSettings,
    });
    this.props.saveDeviceSettings(settingsContainer);
  };

  toggleNewCarOverlay() {
    this.setState({ showNewCarOverlay: !this.state.showNewCarOverlay });
  }

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "set_speed_limit" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  setProfilePrivate = user_owns_car => {
    this.props.setSettingsSaving("false");
    user_owns_car && this.setState({ is_fahrtenbuch_public: false });
  };

  setProfilePublic = user_owns_car => {
    this.props.setSettingsSaving("false");
    user_owns_car && this.setState({ is_fahrtenbuch_public: true });
  };

  setClassifyRoutes = (classify_routes, user_owns_car) => {
    this.props.setSettingsSaving("false");
    user_owns_car &&
      this.setState({ classify_routes, classify_fahrtenbuch: classify_routes });
  };

  render() {
    const t = this.props.intl.formatMessage;

    const user_owns_car =
      parseInt(this.props.user.user_id, 10) ===
      parseInt(this.state.assigned_user || this.props.user.user_id, 10);

    return (
      <React.Fragment>
        <div className="content-container utility-tab">
          <div className="button-wrap">
            <div
              className={
                "button white-bg" +
                (this.state.classify_routes ? " border" : " active") +
                (user_owns_car ? "" : " disabled")
              }
              onClick={() => {
                this.setClassifyRoutes(false, true);
              }}
            >
              <T
                id="tracar.device.routList"
                defaultMessage="Routenliste anzeigen"
              />
            </div>
          </div>

          <div className="content utility-content">
            <div className="grid-x grid-margin-x">
              <TextInput
                className="medium-6 large-6 cell"
                id="car_number"
                name={t(terms.identificationNumber)}
                onChange={event => this.handleChange(event)}
                type="text"
                value={this.state.car_number}
                disabled={this.props.readOnly}
              />
              <TextInput
                className="medium-6 large-6 cell"
                id="model"
                name={t(terms.description)}
                onChange={event => this.handleChange(event)}
                type="text"
                value={this.state.model}
                disabled={this.props.readOnly}
              />
              <TextInput
                className="medium-6 large-6 cell"
                id="driver"
                name={t(terms.driver)}
                onChange={event => this.handleChange(event)}
                type="text"
                value={this.state.driver}
                disabled={this.props.readOnly}
              />
              <div className="text-input-component medium-6 large-6 cell autoHeight">
                <TextInput
                  className="noMargin noPadding"
                  id="device_ignition_time_curr"
                  name={t(terms.setIgnitionTime)}
                  onChange={event => this.handleChange(event)}
                  type="text"
                  value={this.state.device_ignition_time_curr}
                  disabled={this.props.readOnly}
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/${
              this.props.portal === "admin-panel"
                ? "device/list"
                : "device-list"
            }`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          <div
            className={`button confirm-button ${this.props.settingsSaving ===
              "true" && "saving"} ${this.props.settingsSaving === "done" &&
              "saved"}`}
            onClick={() =>
              this.props.settingsSaving === "false" && this.saveDeviceSettings()
            }
          >
            {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
            <span>
              {this.props.settingsSaving === "true" && (
                <T
                  id="tracar.settings.fahrtenbuch.saving"
                  defaultMessage="Wird gespeichert..."
                />
              )}
              {this.props.settingsSaving === "false" && (
                <T
                  id="tracar.settings.fahrtenbuch.save"
                  defaultMessage="Speichern"
                />
              )}
              {this.props.settingsSaving === "done" && (
                <T
                  id="tracar.settings.fahrtenbuch.saved"
                  defaultMessage="Gespeichert"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export const UtilityTab = injectIntl(UtilityTabClass);
