import React from "react";
import { DeviceSafetyZoneList } from "./DeviceSafetyZoneList";

export class DeviceSafetyZoneListBase extends React.Component {
  deleteDeviceSafetyZone;

  imei;

  constructor(props) {
    super(props);
    this.imei = props.match.params.imei || "";
    this.state = {
      deviceSafetyZones: [],
      trackerDetail: null,
      loadingState: "loading",
    };
    this.deleteDeviceSafetyZone = this.deleteDeviceSafetyZone.bind(this);
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loadingState: "loading" });
    Promise.all([
      this.props.getDeviceSafetyZones(this.imei),
      this.props.getTrackerDetail(this.imei),
    ]).then(([deviceSafetyZones, trackerDetail]) => {
      this.setState({
        deviceSafetyZones,
        loadingState: "loaded",
        trackerDetail,
      });
    });
  }

  deleteDeviceSafetyZone(deviceSafetyZoneId) {
    this.setState({ loadingState: "saving" });
    this.props
      .deleteDeviceSafetyZone(deviceSafetyZoneId)
      .then(res => this.load());
  }

  render() {
    return (
      <DeviceSafetyZoneList
        deleteDeviceSafetyZone={this.deleteDeviceSafetyZone}
        deviceSafetyZones={this.state.deviceSafetyZones}
        imei={this.imei}
        match={this.props.match}
        portal={this.props.match.params.portal || "tracar"}
        loadingState={this.state.loadingState}
        trackerDetail={this.state.trackerDetail}
      />
    );
  }
}
