import React from "react";
import Route from "react-router-dom/es/Route";
import Link from "react-router-dom/es/Link";

const LinkButton = ({
  iconClass,
  to,
  activeOnlyWhenExact = false,
  tip = "",
  dataFor,
}) => (
  <Route
    path={to}
    exact={activeOnlyWhenExact}
    children={({ match }) => (
      <Link
        className={(match ? "active" : "") + " button"}
        to={to}
        data-tip={tip}
        data-for={dataFor || "nav"}
      >
        <i className={iconClass} />
      </Link>
    )}
  />
);

export default LinkButton;
