import React from "react";
import { withRouter } from "react-router-dom";
import { LoadingCircle } from "../../../LoadingCircle";
import numeral from "numeral";
import Link from "react-router-dom/es/Link";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { AccClientConfig } from "../../../ClientSpecificContent/acc-index";
import { Portal } from "../../../Portal";
import { PoolVehicleBusinessOverlay } from "./BusinessOverlay";

export const TravelType = {
  Commute: 0,
  Business: 1,
  Private: 2,
  Open: 4,
};

export const travelTypesNames = {
  "0": <T id="tracar.fahrtenbuch.commute" defaultMessage="Arbeitsweg" />,
  "1": <T id="tracar.fahrtenbuch.business" defaultMessage="Betriebsfahrt" />,
  "2": <T id="tracar.fahrtenbuch.private" defaultMessage="Privat" />,
  "4": <T id="tracar.fahrtenbuch.open" defaultMessage="offen" />,
};

const terms = defineMessages({
  private: {
    id: "tracar.fahrtenbuch.private",
    defaultMessage: "Privat",
  },
  km: {
    id: "base.km",
    defaultMessage: "km",
  },
  nm: {
    id: "base.nm",
    defaultMessage: "sm",
  },
  showOnMap: {
    id: "tracar.fahrtenbuch.showOnMap",
    defaultMessage: "Auf Karte anzeigen",
  },
});

/// TODO change reason comment and partner to new values
/// TODO upon save other than business

class PoolVehicleFahrtenbuchEntryClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showBusinessOverlay: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.loading === "awaiting data") {
      this.setState({ loading: false });
    }
  }

  toggleBusinessOverlay = () => {
    this.setState({ showBusinessOverlay: !this.state.showBusinessOverlay });
  };

  render() {
    const t = this.props.intl.formatMessage;

    const {
      jrny_st_ts,
      jrny_end_ts,
      jrny_st_addr,
      jrny_end_addr,
      odom_begin,
      odom_end,
      type,
      distance,
    } = this.props.entry;
    const startingDate = jrny_st_ts.toLocaleDateString();
    const endDate = jrny_end_ts.toLocaleDateString();
    /// the second date is only shown if it differs
    /// i.e. if the journey ended on a different day than it started
    const secondDate = startingDate === endDate ? "\u00a0" : endDate;
    const startingTime = jrny_st_ts.toLocaleTimeString();
    const endTime = jrny_end_ts.toLocaleTimeString();

    const useNauticalMiles =
      this.props.portal === Portal.Vehicle && AccClientConfig.vehicleIsBoat;
    const conversionFactor = useNauticalMiles ? 1.85 : 1;

    return (
      <div
        className={
          "fahrtenbuch table-line " + (this.state.loading ? "loading" : "")
        }
      >
        {this.state.loading && (
          <LoadingCircle className="loading-circle-overlay" />
        )}
        <div className={`fahrtenbuch-data`}>
          <div className="date">
            {startingDate}
            <br />
            {secondDate}
          </div>
          <div className="vehicle">
            {`${this.props.entry.fb_car.model} ${this.props.entry.fb_car.car_number}`}
          </div>
          <div className="time">
            {startingTime} - {endTime}
          </div>

          <div className="hide-for-large date-time">
            <span className="mobile-date">
              {startingDate} {secondDate}
            </span>
            <span className="mobile-time">
              {startingTime} - {endTime}
            </span>
          </div>

          <div className="km-delta">
            {numeral(distance / conversionFactor).format("0,0.00")}{" "}
            {useNauticalMiles ? (
              <T id="base.nauticalMiles" defaultMessage="Seemeilen" />
            ) : (
              <T id="tracar.fahrtenbuch.kmTotal" defaultMessage="km gesamt" />
            )}
          </div>
          <div className="km-total">
            {numeral(odom_begin / conversionFactor).format("0,0")}&nbsp;
            {useNauticalMiles ? (
              <T id="base.nm" defaultMessage="sm" />
            ) : (
              <T id="base.km" defaultMessage="km" />
            )}{" "}
            <span className="arrow">&#8594;</span>{" "}
            {numeral(odom_end / conversionFactor).format("0,0")}&nbsp;
            {useNauticalMiles ? (
              <T id="base.nm" defaultMessage="sm" />
            ) : (
              <T id="base.km" defaultMessage="km" />
            )}
          </div>
          <div className={"type" + (type === 4 ? " alert" : "")}>
            <strong>{travelTypesNames[type]}</strong>
          </div>
          <div className="addresses">
            <div className="address from sfont">
              {jrny_st_addr === "Privat" ? t(terms.private) : jrny_st_addr}
            </div>
            <div className="address arrow">&#8594;</div>
            <div className="address to sfont">
              {jrny_end_addr === "Privat" ? t(terms.private) : jrny_end_addr}
            </div>
          </div>
          <div className={" type-m" + (type === 4 ? " alert" : "")}>
            <strong>{travelTypesNames[type]}</strong>
          </div>
        </div>

        {this.renderBusinessInformation()}

        {this.renderOriginatedFromSplitHint()}

        {this.renderBusinessOverlay()}
      </div>
    );
  }

  /**
   * If a journey/route is of type 'business' we display
   * certain information about the partner, reason and
   * comment regarding the journey.
   *
   * @return Null|JSX
   */
  renderBusinessInformation() {
    if (this.props.type !== TravelType.Business) {
      return null;
    }

    return (
      <div className="grid-x grid-margin-x grey-box">
        <div className="medium-12 large-4 cell">
          <strong>
            <T
              id="tracar.fahrtenbuch.businessPartner"
              defaultMessage="Geschäftspartner"
            />
            :
          </strong>
          <br /> {this.props.entry.partner}
        </div>
        <div className="medium-12 large-4 cell">
          <strong>
            <T
              id="tracar.fahrtenbuch.reason"
              defaultMessage="Grund der Betriebsfahrt:"
            />
          </strong>
          <br /> {this.props.entry.reason}
        </div>
        <div className="medium-12 large-4 cell">
          {this.props.entry.comments && (
            <div>
              <strong>
                <T
                  id="tracar.fahrtenbuch.comment"
                  defaultMessage="Kommentar:"
                />
              </strong>
              <br /> {this.props.entry.comments}
            </div>
          )}
        </div>
        <div className="tiny-spacer" />
      </div>
    );
  }

  /**
   * Renders the buttons to set the type of the route.
   * Returns null if freeze_flag is set - usally seven
   * days after the drive took place.
   *
   * @return JSX|null
   */
  renderTypeButtons() {
    if (this.props.entry.freeze_flag) {
      return null;
    }

    return (
      <div className="fahrtenbuch-type-buttons">
        <div className="border button" onClick={this.setPrivate}>
          <T id="tracar.fahrtenbuch.privateButton" defaultMessage="Privat" />
        </div>
        <div className="border button" onClick={this.setCommute}>
          <T
            id="tracar.fahrtenbuch.commuteButton"
            defaultMessage="Arbeitsweg"
          />
        </div>
        <div className="border button" onClick={this.toggleBusinessOverlay}>
          <T
            id="tracar.fahrtenbuch.businessButton"
            defaultMessage="Betriebsfahrt"
          />
        </div>

        {/* this.renderShowOnMapButton() */}
      </div>
    );
  }

  /**
   * Sets type of route to private
   * @return void
   */
  setPrivate = () => {
    this.setState({ loading: true });

    this.props
      .editFahrtenbuchEntry({
        ...this.props.entry,
        comments: null,
        partner: null,
        reason: null,
        type: TravelType.Private,
      })
      .then(res => this.props.reload())
      .then(res => this.setState({ loading: "awaiting data" }));
  };

  /**
   * Sets type of route to commute
   * @return void
   */
  setCommute = () => {
    this.setState({ loading: true });

    this.props
      .editFahrtenbuchEntry({
        ...this.props.entry,
        comments: null,
        partner: null,
        reason: null,
        type: TravelType.Commute,
      })
      .then(res => this.props.reload())
      .then(res => this.setState({ loading: "awaiting data" }));
  };

  /**
   * Sets the route/journey as business-type.
   * @param  string partner
   * @param  string reason
   * @param  string comments
   * @return void
   */
  setBusiness = (partner, reason, comments) => {
    this.setState({
      loading: true,
      showBusinessOverlay: false,
    });

    this.props
      .editFahrtenbuchEntry({
        ...this.props.entry,
        comments,
        partner,
        reason,
        type: TravelType.Business,
      })
      .then(res => this.props.reload())
      .then(res => this.setState({ loading: "awaiting data" }));
  };

  /**
   * Renders a map containing all the tracklogs belonging to this route.
   * @return JSX
   */
  renderShowOnMapButton() {
    const portal = this.props.match.params.portal;

    // TODO: get imei from entry
    return (
      <Link
        className="border button split-button"
        to={`/${portal}/fahrtenbuch/${this.props.match.params.imei}/map/${this.props.entry.fahrtenbuch_id}`}
      >
        <i className="fa fa-scissors" />
      </Link>
    );
  }

  /**
   * Overlay to input all required information for business-journeys
   * @return Null|JSX
   */
  renderBusinessOverlay = () => {
    if (!this.state.showBusinessOverlay) {
      return null;
    }

    return (
      <PoolVehicleBusinessOverlay
        suggestCustomer={this.props.suggestCustomer}
        entry={this.props.entry}
        callbackBusiness={this.setBusiness}
        closeFunction={this.toggleBusinessOverlay}
      />
    );
  };

  renderOriginatedFromSplitHint = () => {
    if (!this.props.originatedFromSplit) {
      return null;
    }

    return (
      <div className="originated-from-split">
        <small>
          <T
            id="tracar.fahrtenbuch.originatedFromSplit"
            defaultMessage="Durch Routenaufteilung entstanden"
          />
        </small>
      </div>
    );
  };
}

export const PoolVehicleFahrtenbuchEntry = withRouter(
  injectIntl(PoolVehicleFahrtenbuchEntryClass),
);
