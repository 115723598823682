import React from "react";
import { Redirect } from "react-router-dom";
import { LoadingCircle } from "../LoadingCircle";
import { ChangePassword } from "./ChangePassword";
import { User } from "./User";
import { ProfileDriversLicenseRegistration } from "../DriversLicenseCheck/ProfileDriversLicenseRegistration";
import { DriversLicenseRegistrationOverlay } from "../DriversLicenseCheck/DriversLicenseRegistrationOverlay";

export class UserBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadState: "loading",
      showPasswordOverlay: false,
      showRegisterFleetIdOverlay: false,
      user: null,
    };
  }

  componentWillMount() {
    this.props.getUser().then(user =>
      this.setState({
        user,
        loadState: "loaded",
      }),
    );
  }

  save = user => {
    this.props.saveUser(user).then(res => {
      this.setState({ loadState: "saved" });
      this.props.setUserProfileData(res);
    });
    this.setState({ loadState: "saving" });
  };

  toggleChangePassword = () => {
    this.setState({ showPasswordOverlay: !this.state.showPasswordOverlay });
  };

  toggleRegisterFleetId = () => {
    this.setState({
      showRegisterFleetIdOverlay: !this.state.showRegisterFleetIdOverlay,
    });
  };

  render() {
    const userHasProfileDriversLicenseRegistrationOption = false;
    //   this.props.userLoginData.functionalities.find(
    //   f => f === Functionality.F5_DriversLicenseCheck
    // );
    return (
      <div>
        {(this.state.loadState === "loading" ||
          this.state.loadState === "saving") && (
          <div className="form">
            <LoadingCircle />
          </div>
        )}
        {this.state.loadState === "saved" && <Redirect to="/all/map" />}
        {this.state.loadState === "loaded" && this.state.user && (
          <div>
            {userHasProfileDriversLicenseRegistrationOption && (
              <ProfileDriversLicenseRegistration
                toggleRegisterFleetId={this.toggleRegisterFleetId}
                driversLicenseCheck={this.props.driversLicenseCheck}
                userLoginData={this.props.userLoginData}
              />
            )}
            <User
              save={this.save}
              toggleChangePassword={this.toggleChangePassword}
              user={this.state.user}
            />
          </div>
        )}
        {this.state.showPasswordOverlay && (
          <ChangePassword
            closeFunction={this.toggleChangePassword}
            changePassword={this.props.changePassword}
          />
        )}
        {this.state.showRegisterFleetIdOverlay && (
          <DriversLicenseRegistrationOverlay
            closeFunction={this.toggleRegisterFleetId}
            driversLicenseCheck={this.props.driversLicenseCheck}
            userLoginData={this.props.userLoginData}
          />
        )}
      </div>
    );
  }
}
