import React from "react";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import Plot from "react-plotly.js";
import { FormattedMessage as T, injectIntl } from "react-intl";

export function createRouteTypeRatiosPlotData(route_type_ratios) {
  const routeValues = [];
  const routeLabels = [];
  const routeColors = [];
  Object.entries(route_type_ratios).forEach(([key, value]) => {
    if (value != 0) {
      switch (key) {
        case "private":
          routeValues.push(value);
          routeLabels.push({
            id: "tracar.Dashboard.private",
            defaultValue: "Privat",
          });
          routeColors.push(Color.Red);
          break;
        case "commute":
          routeValues.push(value);
          routeLabels.push({
            id: "tracar.Dashboard.commute",
            defaultValue: "Arbeitsweg",
          });
          routeColors.push(Color.OuterSpace);
          break;
        case "business":
          routeValues.push(value);
          routeLabels.push({
            id: "tracar.Dashboard.business",
            defaultValue: "Geschäftlich",
          });
          routeColors.push(Color.Silver);
          break;
        case "open":
          routeValues.push(value);
          routeLabels.push({ id: "open", defaultValue: "Offen" });
          routeColors.push(Color.SilverChalice1);
          break;
      }
    }
  });
  return { routeValues, routeLabels, routeColors };
}

function DashboardPoolvehicleFn({
  current_monetary_advantage,
  yearly_monetary_advantage_linear_estimation,
  route_type_ratios,
  history,
  portal,
  intl: { formatMessage: t },
}) {
  const config = { displayModeBar: false };
  const plotLayoutCommons = {
    separators: ",.",
    title: "",
    paper_bgcolor: "rgba(255,255,255,0)",
    plot_bgcolor: "rgba(255,255,255,0)",
    showlegend: false,
    width: 320,
    height: 210,
    margin: { t: 0, b: 25, l: 45, r: 15 },
  };

  //route_type_ratios.commute = 0.5;
  //route_type_ratios.open = 0.5;
  //current_monetary_advantage= 2000;
  //yearly_monetary_advantage_linear_estimation= 2500;

  const {
    routeValues,
    routeColors,
    routeLabels,
  } = createRouteTypeRatiosPlotData(route_type_ratios);

  return (
    <div className="outer-table-frame dashboard pool-vehicle">
      <div className="button-frame" />
      <div className="table-frame default-list">
        <div className="table-header">
          <i className="fa fa-tachometer" />
          <T id="base.dashboard" defaultMessage="Dashboard" />
        </div>
        <div className="all-graphs-outer-frame">
          <div className="all-graphs-frame">
            <div className="wide-graph-frame">
              <div className="graph-headline">
                <h5 className="headline-for-icon">
                  {" "}
                  <i className="icon icon-eur" />
                  <div>
                    <T
                      id="tracar.Dashboard.monetaryAdvantage"
                      defaultMessage="Geldwerter Vorteil (basierend auf bestätigten Kosten & Fahrverhalten)"
                    />
                  </div>
                </h5>
              </div>
              <ul>
                <li>
                  <T
                    id="tracar.Dashboard.currentMonetaryAdvantage"
                    defaultMessage="Aktuell"
                  />{" "}
                  {current_monetary_advantage}
                </li>
                <li>
                  <T
                    id="tracar.Dashboard.yearlyMonetaryAdvantageLinearEstimation"
                    defaultMessage="Am Ende des Jahres (geschätzt)"
                  />{" "}
                  {yearly_monetary_advantage_linear_estimation}
                </li>
              </ul>
            </div>
            <div className="graph-frame pie-chart">
              <div className="graph-headline pie-chart-headline">
                <h5 className="headline-for-icon">
                  <i className="icon icon-steering_wheel" />
                  <T
                    id="tracar.Dashboard.routeTypes"
                    defaultMessage="Fahrttypen"
                  />
                </h5>
              </div>
              <div className="tiny-spacer" />
              {routeValues.length > 0 ? (
                <Plot
                  config={config}
                  data={[
                    {
                      values: routeValues,
                      type: "pie",
                      textinfo: "percent",
                      textfont: { size: 16, color: Color.WildSand },
                      sort: false,
                      direction: "clockwise",
                      hoverinfo: "none",
                      marker: {
                        colors: routeColors,
                      },
                      outsidetextfont: { color: "transparent" },
                    },
                  ]}
                  layout={{ ...plotLayoutCommons }}
                />
              ) : (
                <div style={{ textAlign: "center" }}>
                  <T
                    id="tracar.Dashboard.noData"
                    defaultMessage="Keine Daten"
                  />
                </div>
              )}

              {routeValues.length > 0 && (
                <ul className="pie-chart-legend">
                  {routeLabels.map((item, index) => (
                    <li key={index}>
                      <i
                        className="fa fa-square"
                        style={{ color: routeColors[index] }}
                      />
                      &nbsp;&nbsp;
                      {routeValues[index] * 100}%{" "}
                      <T id={item.id} defaultMessage={item.defaultValue} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const DashboardPoolvehicle = injectIntl(DashboardPoolvehicleFn);
