import React from "react";
import {RequestOverlay} from "../../../../Request/RequestOverlay";
import { injectIntl, FormattedMessage as T, defineMessages } from "react-intl";

class DeleteItemOverlay extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <RequestOverlay closeFunction={this.props.closeFunction}>
            <div className="header">
                <h3>
                    <T id="adminPanel.smartInventory.deleteItem.headline" defaultMessage="Item löschen"/>
                </h3>
            </div>
            <div className="small-spacer" />
            <div>
                <T
                    id="adminPanel.smartInventory.deleteItem.confirmationText"
                    defaultMessage="Sind Sie sicher, dass Sie {itemName} löschen möchten?"
                    values={{ itemName: this.props.item.name }}
                />
            </div>
            <div className="small-spacer" />
            <div className="footer text-right">
                <div className="button transparent" onClick={this.props.closeFunction}>
                    <T id="adminPanel.smartInventory.deleteItem.buttonLabel.cancel" defaultMessage="Abbrechen"/>
                </div>
                <div className="button alert" onClick={this.props.confirm}>
                    <T id="adminPanel.smartInventory.deleteItem.buttonLabel.submit" defaultMessage="Löschen"/>
                </div>
            </div>
        </RequestOverlay>;
    }
}

export default injectIntl(DeleteItemOverlay);
