import React from "react";
import Plot from "react-plotly.js";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import { FormattedMessage, FormattedMessage as T } from "react-intl";
import moment from "moment";

export function DeviceSpeedGraph({
  config,
  noAxisValues,
  speed_data,
  widePlotLayoutCommons,
}) {
  const x = speed_data.map(({ date }) => moment(date).format("DD.MM.YYYY"));

  const groupedBarChart = {
    trace1: {
      x,
      y: speed_data.map(({ max_speed }) => max_speed),
      name: "km/h",
      type: "bar",
      marker: { color: Color.SilverChalice1 },
    },
    trace2: {
      x,
      y: speed_data.map(({ avg_speed }) => avg_speed),
      marker: { color: Color.Red },
      name: "km/h",
      type: "bar",
    },
  };

  return (
    <div className="wide-graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          {" "}
          <i className="fa fa-tachometer" />
          <T id="tracar.statistics.speed" defaultMessage="Geschwindigkeit" />
        </h5>
      </div>
      <Plot
        config={{ displayModeBar: false }}
        data={[groupedBarChart.trace1, groupedBarChart.trace2]}
        layout={{
          ...widePlotLayoutCommons,
          margin: widePlotLayoutCommons.margin,
          yaxis: { title: "km/h", tickformat: ",.0f," },
          xaxis: {
            ...noAxisValues,
            type: "category",
          },
        }}
      />
      <ul style={{ marginLeft: "64px", marginTop: "-28px" }}>
        <li>
          <T
            id="tracar.Dashboard.speedLast30Days"
            defaultMessage="Geschwindigkeit für die letzten 30 Tage"
          />&nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.SilverChalice1 }} />
          &nbsp; <T id="tracar.dashboard.maximun" defaultMessage="maximal" />
          &nbsp;&nbsp;
        </li>
        <li>
          <i className="fa fa-square" style={{ color: Color.Red }} />
          &nbsp;{" "}
          <FormattedMessage
            id="tracar.dasboard.average"
            defaultMessage="Durchschnitt"
          />
          &nbsp;&nbsp;
        </li>
      </ul>
    </div>
  );
}
