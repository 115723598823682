import React from "react";
import {injectIntl, FormattedMessage as T} from "react-intl";
import SmartInventoryItemLine from "./SmartInventoryItemLine";
import {LoadingCircle} from "../../../../LoadingCircle";
import {fetchInventoryItems} from "../../../../fetch/fetchSmartInventory";
import {PaginationBar} from "../../../PaginationBar";

const formName = 'assignItemsForm';
const noTemplateType = 'noTemplate';
const afterTemplateType = 'afterTemplate';
const beforeTemplateType = 'beforeTemplate';

class AssignTaskTemplateForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            assignedItems: Object.keys(this.props.data).length !== 0 ? this.props.data : {
                [beforeTemplateType]: [],
                [afterTemplateType]: [],
            },
            loading: true,
            listOffset: 0,
            totalItems: 0,
            currentPage: 0,
            itemsPerPage: 10,
            selectedItems: [],
            selectedAction: null,
            tasks: this.props.tasks || [],
        };
    }

    componentDidMount() {
        this.loadInventoryItems();
    }

    loadInventoryItems() {
        fetchInventoryItems(this.props.fetchService.jsonFetch, {
            offset: this.state.listOffset,
            limit: this.state.itemsPerPage,
        }).then(response => {
            this.setState({
                loading: false,
                items: response.items,
                totalItems: response.total_amount,
            }, () => this.reflectAssignedTemplates());
        })
    }

    reflectAssignedTemplates() {
        if (!this.props.template) {
            return;
        }

        const itemsWithCurrentTemplateAsBeforeTemplate = [];
        const itemsWithCurrentTemplateAsAfterTemplate = [];

        this.state.items.forEach(item => {
            if (item.template_before === this.props.template.id) {
                itemsWithCurrentTemplateAsBeforeTemplate.push(item);
                return;
            }

            if (item.template_after === this.props.template.id) {
                itemsWithCurrentTemplateAsAfterTemplate.push(item);
            }
        });

        this.setState({
            assignedItems: {
                [beforeTemplateType]: itemsWithCurrentTemplateAsBeforeTemplate,
                [afterTemplateType]: itemsWithCurrentTemplateAsAfterTemplate,
            }
        }, () => this.props.updateFormData(formName, this.state.assignedItems))
    }

    render() {
        if (this.state.loading) {
            return <LoadingCircle />;
        }

        return (
            <div className={`assign-task-template-form`}>
                <div className="small-spacer" />
                {this.renderTasks()}
                {this.renderItemList()}
            </div>
        );
    }

    renderTasks() {
        return <ul className={`assign-task-template-task-list grid-x text-left`}>
            {
                this.state.tasks.map(task => {
                    return <li className={`cell small-6 large-3 grey-text bold`} key={task.id}>{task.task_name}</li>
                })
            }
        </ul>
    }

    renderItemList() {
        return <div className="outer-table-frame table-scroll">
            {this.renderContent()}
            <div className="small-spacer" />
            {this.renderPagination()}
        </div>
    }

    renderContent() {
        return (
            <div className="table-frame">
                <div className="padding-1">
                    {this.renderActions()}
                    <div className="small-spacer" />
                    {this.renderList()}
                </div>
            </div>
        );
    }

    renderActions() {
        return (
            <div className="action-list">
                <div className="button transparent" onClick={() => this.toggleAllItems()}>
                    {this.renderToggleString()}
                </div>
                <T id="adminPanel.smartInventoryTaskTemplates.assignTasks.headline.action" defaultMessage="Aktion:"/>
                {this.renderCheckboxes()}
                {this.renderSelectCounter()}
            </div>
        );
    }

    toggleAllItems() {
        if (this.areAllItemsSelected()) {
            return this.setState({selectedItems: []});
        }

        this.setState({
            selectedItems: this.state.items
        });
    }

    areAllItemsSelected() {
        return this.state.items.length === this.state.selectedItems.length
    }

    renderToggleString() {
        if (this.areAllItemsSelected()) {
            return <T
                id="adminPanel.smartInventoryTaskTemplates.assignTasks.labels.select-none"
                defaultMessage="Keine Auswählen"
            />;
        }

        return <T
            id="adminPanel.smartInventoryTaskTemplates.assignTasks.labels.select-all"
            defaultMessage="Alle Auswählen"
        />;
    }

    renderCheckboxes() {
        return (
            <div className="checkbox-container">
                <div
                    className="checkbox action"
                    id={'before-task-checkbox'}
                    onClick={() => this.selectAction(beforeTemplateType)}
                >
                    {
                        this.state.selectedAction === beforeTemplateType ?
                            <i className="icon-check" /> :
                            <i className="icon-check_empty" />
                    }
                    <T
                        id="adminPanel.smartInventoryTaskTemplates.assignTasks.labels.beforeTemplate"
                        defaultMessage="Vor-Aufgabe"
                    />
                </div>
                <div
                    className="checkbox action"
                    id={'after-task-checkbox'}
                    onClick={() => this.selectAction(afterTemplateType)}
                >
                    {
                        this.state.selectedAction === afterTemplateType ?
                            <i className="icon-check" /> :
                            <i className="icon-check_empty" />
                    }
                    <T
                        id="adminPanel.smartInventoryTaskTemplates.assignTasks.labels.afterTemplate"
                        defaultMessage="Nach-Aufgabe"
                    />
                </div>
                <div
                    className="checkbox action"
                    id={'no-template-checkbox'}
                    onClick={() => this.removeTemplate()}
                >
                    {
                        this.state.selectedAction === noTemplateType ?
                            <i className="icon-check" /> :
                            <i className="icon-check_empty" />
                    }
                    <T
                        id="adminPanel.smartInventoryTaskTemplates.assignTasks.labels.noTemplate"
                        defaultMessage="Keine Aufgabe"
                    />
                </div>
            </div>
        );
    }

    selectAction(type) {
        if (!this.state.selectedItems.length && this.state.selectedAction === type) {
            return this.setState({
                selectedAction: null,
            });
        }

        const oppositeType = type === beforeTemplateType ? afterTemplateType : beforeTemplateType;
        const oppositeTypeItems = this.state.assignedItems[oppositeType];

        this.state.selectedItems.forEach(item => {
            const index = oppositeTypeItems.indexOf(item);

            if (index !== -1) {
                oppositeTypeItems.splice(index, 1);
            }
        });

        this.setState({
            selectedAction: type,
            assignedItems: {
                [oppositeType]: oppositeTypeItems,
                [type]: [
                    ...this.state.assignedItems[type],
                    ...this.state.selectedItems
                ],
            }
        }, () => this.props.updateFormData(formName, this.state.assignedItems));
    }

    removeTemplate() {
        const newBeforeTemplateItems = this.state.assignedItems[beforeTemplateType];
        const newAfterTemplateItems = this.state.assignedItems[afterTemplateType];

        this.state.selectedItems.forEach(item => {
            const indexBeforeTemplate = this.state.assignedItems[beforeTemplateType].indexOf(item);
            const indexAfterTemplate = this.state.assignedItems[afterTemplateType].indexOf(item);

            if (indexBeforeTemplate !== -1) {
                newBeforeTemplateItems.splice(indexBeforeTemplate, 1);
            }

            if (indexAfterTemplate !== -1) {
                newAfterTemplateItems.splice(indexAfterTemplate, 1);
            }
        });

        this.setState({
            selectedAction: noTemplateType,
            assignedItems: {
                [beforeTemplateType]: newBeforeTemplateItems,
                [afterTemplateType]: newAfterTemplateItems,
            }
        }, () => this.props.updateFormData(formName, this.state.assignedItems))
    }

    renderSelectCounter() {
        return <div className="counter">{ this.state.selectedItems.length }</div>;
    }

    renderList() {
        return (
            <div>
                {
                    this.state.items.map(item => {
                        return <SmartInventoryItemLine
                            item={item}
                            key={item.item_uuid}
                            isSelected={this.isSelected(item)}
                            toggleItem={(item) => this.toggleItem(item)}
                            assignedAsBeforeTemplate={this.hasAssignedTemplate(item, 'beforeTemplate')}
                            assignedAsAfterTemplate={this.hasAssignedTemplate(item, 'afterTemplate')}
                            assignAsBeforeTemplate={(item) => this.assignAsTemplate(item, 'beforeTemplate')}
                            assignAsAfterTemplate={(item) => this.assignAsTemplate(item, 'afterTemplate')}
                        />
                    })
                }
            </div>
        );
    }

    isSelected(item) {
        return this.state.selectedItems.some((selectedItem) => selectedItem.item_uuid === item.item_uuid);
    }

    toggleItem(item) {
        if (this.isSelected(item)) {
            return this.deselectItem(item);
        }

        const items = [...this.state.selectedItems];
        items.push(item);

        this.setState({selectedItems: items});
    }

    deselectItem(item) {
        const index = this.state.selectedItems.indexOf(item);
        const items = [...this.state.selectedItems];
        items.splice(index, 1);

        this.setState({selectedItems: items});
    }

    assignAsTemplate(item, type) {
        const newItems = [...this.state.assignedItems[type]];
        newItems.push(item);

        this.setState({
            assignedItems: {
                ...this.state.assignedItems,
                [type]: newItems,
            }
        }, () => this.props.updateFormData(formName, this.state.assignedItems))
    }

    hasAssignedTemplate(item, type) {
        const items = this.state.assignedItems[type];

        console.log(items, JSON.stringify(this.state));

        return items.some((assignedItem) => assignedItem.item_uuid === item.item_uuid);
    }

    renderPagination() {
        const numberMaxPages = this.getMaxPages();

        if (numberMaxPages <= 1) {
            if (this.state.currentPage > 0) {
                this.setState({ currentPage: 0 });
            }

            return;
        }

        return (
            <PaginationBar
                currentPage={this.state.currentPage}
                numberOfPages={numberMaxPages}
                updateCurrentPage={this.updateCurrentPage}
            />
        );
    }

    getMaxPages() {
        return Math.ceil(this.state.totalItems / this.state.itemsPerPage);
    }

    updateCurrentPage = pageNumber => {
        if (pageNumber === this.state.currentPage) {
            return;
        }

        this.setState(
            {
                currentPage: pageNumber,
                listOffset: pageNumber * this.state.itemsPerPage,
            },
            this.loadInventoryItems,
        );
    };

}

export default injectIntl(AssignTaskTemplateForm);
