import { postJson } from "./fetchService";

const onDemandRoute = "/user/fahrtenbuchondemand/";
const onDemandStatusRoute = "/user/fahrtenbuchondemand_status/";

export const fetchFahrtenbuch = jsonFetch => ({
  //TODO: include into code (instead of old implementation in fetchService) for consistency
  getFahrtenbuch(imei, bodyData) {
    jsonFetch(`/user/fahrtenbuch/${imei}`, postJson({ bodyData }));
  },

  //TODO: include into code (instead of old implementation in fetchService) for consistency
  getRouteData(fahrtenbuchId) {
    jsonFetch(
      `/user/location/route`,
      postJson({
        fahrtenbuch_id: fahrtenbuchId,
      }),
    );
  },

  //TODO: include into code (instead of old implementation in fetchService) for consistency
  mergeRoutes(imei, start, end) {
    jsonFetch(
      `/user/fahrtenbuch/routemerge/${imei}`,
      postJson({
        fahrtenbuch_id_start: start,
        fahrtenbuch_id_end: end,
      }),
    );
  },

  //TODO: include into code (instead of old implementation in fetchService) for consistency
  splitRoute(imei, fahrtenbuchID, tracklogId) {
    jsonFetch(
      `/user/fahrtenbuch/routesplit/${imei}`,
      postJson({
        fahrtenbuch_id: fahrtenbuchID,
        tracklog_id: tracklogId,
      }),
    );
  },

  onDemand(deviceUuid) {
    return jsonFetch(onDemandRoute + deviceUuid, postJson());
  },

  onDemandStatus(taskUuid) {
    return jsonFetch(onDemandStatusRoute + taskUuid);
  },
});
