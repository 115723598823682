import React from "react";
import { TrackerTableHead } from "../TrackerList/TrackerTableHead";
import { DeviceSafetyZoneTableLine } from "./DeviceSafetyZoneTableLine";
import { LoadingCircle } from "../LoadingCircle";
import { Link } from "react-router-dom";
import { FormattedMessage as T } from "react-intl";

export class DeviceSafetyZoneList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const portal = this.props.match.params.portal || "tracar";

    const newLink = `/${portal}/device-safety-zone-detail/${
      this.props.imei
    }/new`;
    if (
      this.props.loadingState === "loading" ||
      this.props.loadingState === "saving"
    ) {
      return (
        <div className="outer-table-frame tracker">
          <div className="button-frame">
            <div className="block" />
          </div>
          <div className="table-frame">
            <div className="spacer" />
            <LoadingCircle />
            <div className="spacer" />
          </div>
        </div>
      );
    } else {
      const {
        deviceSafetyZones,
        trackerDetail,
        deleteDeviceSafetyZone,
      } = this.props;
      return (
        <div className="outer-table-frame">
          {/* button line ******************************************************/}
          <div className="button-frame">
            <div className="block">
              <Link to={newLink} className="button">
                <T
                  id="tracar.device-safety-zone.new"
                  defaultMessage="Weitere Sicherheitszone zuordnen"
                />
              </Link>
              <Link
                to={{
                  pathname: `/${this.props.portal}/geofencing/new`,
                  state: { from: this.props.match.url },
                }}
                className="button"
              >
                <T
                  id="tracar.device-safety-zone.newGeofence"
                  defaultMessage="Neue Sicherheitszone erstellen"
                />
              </Link>
            </div>
            <div className="small-full-btn" />
          </div>
          {/* table ************************************************************/}
          <div className="table-frame">
            {
              <TrackerTableHead
                headline={
                  trackerDetail ? (
                    <span>
                      <T
                        id="tracar.device-safety-zone.safetyZoneHeadline"
                        defaultMessage="Sicherheitszonen für"
                      />{" "}
                      {trackerDetail.name}
                    </span>
                  ) : (
                    ""
                  )
                }
                iconClasses="fa fa-globe"
              />
            }
            {deviceSafetyZones.length < 1 && (
              <div className="table-line">
                <T
                  id="tracar.device-safety-zones.noZones"
                  defaultMessage="Sie haben noch keine Sicherheitszonen zugeordnet."
                />
              </div>
            )}
            {deviceSafetyZones.map((deviceSafetyZone, index) => (
              <DeviceSafetyZoneTableLine
                key={index}
                deviceSafetyZone={deviceSafetyZone}
                deleteDeviceSafetyZone={deleteDeviceSafetyZone}
              />
            ))}
          </div>
        </div>
      );
    }
  }
}
