import React from "react";

export function RequestOverlay({
  className = "",
  children,
  closeFunction,
  enableCloseOnOverlayClicked = true,
}) {
  return (
    <div
      className={"request-component " + className}
      onClick={() => enableCloseOnOverlayClicked && closeFunction()}
    >
      <div
        className={"box"}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <i
          className="fa fa-times-circle close-x"
          onClick={() => closeFunction()}
        />
        {children}
      </div>
    </div>
  );
}
