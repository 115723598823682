import {injectIntl, FormattedMessage as T} from "react-intl";
import React from "react";

class SmartInventoryItemLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSelected: this.props.isSelected || false,
            isBeforeTemplate: this.props.assignedAsBeforeTemplate || false,
            isAfterTemplate: this.props.assignedAsAfterTemplate || false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isSelected !== this.props.isSelected) {
            this.setState({isSelected: this.props.isSelected});
        }

        if (prevProps.assignedAsBeforeTemplate !== this.props.assignedAsBeforeTemplate) {
            this.setState({isBeforeTemplate: this.props.assignedAsBeforeTemplate});
        }

        if (prevProps.assignedAsAfterTemplate !== this.props.assignedAsAfterTemplate) {
            this.setState({isAfterTemplate: this.props.assignedAsAfterTemplate});
        }
    }


    render() {
        return (
            <div className="table-line smart-inventory-item grid-x grid-padding-x">
                <div className={`cell small-1`}>
                    {this.renderCheckbox()}
                </div>
                <div className={`cell small-3 font-black bold item-name text-left`}>
                    {this.props.item.name}
                </div>
                {this.renderTemplateTypeSelects()}
            </div>
        )
    }

    renderCheckbox() {
        return (
            <div className="checkbox" onClick={() => {
                this.setState({isSelected: !this.state.isSelected});
                this.props.toggleItem(this.props.item);
            }}>
                {this.state.isSelected ? <i className="icon-check" /> : <i className="icon-check_empty" />}
            </div>
        );
    }

    renderTemplateTypeSelects() {
        return (
            <div className={`cell auto table-line-right template-type-buttons`}>
                <div
                    className={`button transparent outline primary ${this.state.isBeforeTemplate ? 'active' : ''}`}
                    onClick={() => {
                        this.setState({isBeforeTemplate: true, isAfterTemplate: false});
                        this.props.assignAsBeforeTemplate(this.props.item);
                    }}
                >
                    <T
                        id="adminPanel.smartInventoryTaskTemplates.assignTasks.labels.beforeTask"
                        defaultMessage="Vor-Aufgabe"
                    />
                </div>
                <div
                    className={`button transparent outline primary ${this.state.isAfterTemplate ? 'active' : ''}`}
                    onClick={() => {
                        this.setState({isBeforeTemplate: false, isAfterTemplate: true});
                        this.props.assignAsAfterTemplate(this.props.item);
                    }}
                >
                    <T
                        id="adminPanel.smartInventoryTaskTemplates.assignTasks.labels.afterTask"
                        defaultMessage="Nach-Aufgabe"
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(SmartInventoryItemLine);
