import React from "react";
import { injectIntl } from "react-intl";
import {FormattedMessage as T} from "react-intl";
import { RequestOverlay } from "../../../../Request/RequestOverlay";
import moment from "moment";
import {Marker} from "react-google-maps";
import {getItemLocation} from "../../../../fetch/fetchSmartInventory";
import SimpleMap from "../../../../Map/SimpleMap";

class ItemDetailsOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.bounds = new window.google.maps.LatLngBounds();

        this.state = {
            location: null,
        };
    }

    render() {
        return (
            <div className="inventory-item-modal">
                <RequestOverlay
                    closeFunction={this.props.closeFunction}
                    className="inventory-item-detail"
                    enableCloseOnOverlayClicked={true}
                >
                    {this.renderInterface()}
                </RequestOverlay>
            </div>
        );
    }

    componentDidMount() {
        getItemLocation(this.props.fetchService.jsonFetch, this.props.item.item_uuid)
            .then((response) => this.setState({
                location: response
            }));
    }

    renderInterface = () => {
        return (
            <div>
                {this.renderHeader()}
                {this.renderBody()}
                <div className="small-spacer" />
                { this.renderFooter() }
            </div>
        );
    };

    renderHeader() {
        return (
            <div className="header">
                <h3>{this.props.item.name}</h3>
            </div>
        );
    };

    renderBody() {
        return (
            <div className="grid-container" style={{paddingTop: '1.5rem'}}>
                { this.renderBaseInformation() }
                <div className="grid-x grid-padding-x bold text-left">
                    { this.renderReservationDetails() }
                </div>

                <div className="small-spacer" />

                <div className={'location-container'}>
                    { this.renderLocationInformation() }
                </div>

                <div className="small-spacer" />

            </div>
        );
    };

    renderBaseInformation() {
        return (
            <div className="grid-x grid-padding-x bold text-left">
                <div className="cell large-4 small-6">
                    <T id="adminPanel.smartInventory.itemDetail.name" defaultMessage="Name">
                        {message => <div className={'grey-text'}>{message}</div>}
                    </T>
                    <span>{this.props.item.name}</span>
                </div>
                <div className="cell large-4 small-6">
                    <T id="adminPanel.smartInventory.itemDetail.description" defaultMessage="Beschreibung">
                        {message => <div className={'grey-text'}>{message}</div>}
                    </T>
                    <span>{this.props.item.description}</span>
                </div>
                <div className="cell large-2 small-6">
                    <T id="adminPanel.smartInventory.itemDetail.serialNumber" defaultMessage="Seriennummer">
                        {message => <div className={'grey-text'}>{message}</div>}
                    </T>
                    <span>{this.props.item.serial_number}</span>
                </div>
                <div className="cell large-2 small-6 color-input-container text-left">
                    <T id="adminPanel.smartInventory.itemDetail.color" defaultMessage="Farbe">
                        {message => <div className={'grey-text'}>{message}</div>}
                    </T>
                    <span className={'color-display'} style={{backgroundColor: this.props.item.color}}></span>
                    <span className={'color-string'}>{this.props.item.color}</span>
                </div>
            </div>
        );
    }

    renderReservationDetails() {
        if (!this.props.item) {
            return;
        }

        const available = !this.props.item.reservation;

        if (available) {
            return (
                <div className={'cell reservation-details bold text-left small-6 large-4'}>
                    <T id="adminPanel.smartInventory.itemDetail.availability" defaultMessage="Verfügbarkeit">
                        {txt => <span className={'grey-text'}>{txt}</span>}
                    </T>
                    <div>
                        <span className={'availability-indicator available'}></span>
                        <T id="adminPanel.smartInventory.itemDetail.available" defaultMessage="Verfügbar"/>
                    </div>
                </div>
            );
        }

        return (
            <div className={'cell reservation-details bold text-left small-12'}>
                <div>
                    <T id="adminPanel.smartInventory.itemDetail.availability" defaultMessage="Verfügbarkeit">
                        {txt => <span className={'grey-text'}>{txt}</span>}
                    </T>
                    <div></div>
                    <span className={'availability-indicator unavailable'}></span>
                    <T id="adminPanel.smartInventory.itemDetail.reservedBy" defaultMessage="Reserviert von"/>
                    <span className="customer">
                        {this.props.item.customer.first_name} {this.props.item.customer.last_name}
                    </span>
                    <T id="adminPanel.smartInventory.itemDetail.since" defaultMessage="seit"/>
                    <span className="reservation-date">
                        {
                            moment(this.props.item.reservation.start_date)
                                .format('DD.MM.YYYY HH:MM')

                        }
                    </span>
                     <T id="adminPanel.smartInventory.itemDetail.oClock" defaultMessage="Uhr"/>
                </div>
            </div>
        );
    }

    renderLocationInformation() {

        if (!this.props.item) {
            return;
        }

        if (!this.state.location || (!this.state.location.lat || !this.state.location.lon)) {
            return this.renderLocationString();
        }

        return this.renderLocationMap();
    }

    renderLocationString() {
        const locationString = this.props.item.location ?
            this.props.item.location :
            <T
                id="adminPanel.smartInventory.createEditItem.locationNoFound"
                defaultMessage="Für dieses Objekt liegen keine Standortdaten vor"
            >
                {txt => <span className={'grey-text'}>{txt}</span>}
            </T>;

        return (
            <div className={'bold text-left location-information cell large-2 small-6 text-left'}>
                <T id="adminPanel.smartInventory.createEditItem.location" defaultMessage="Standort">
                    {txt => <span className={'grey-text'}>{txt}</span>}
                </T>
                <div className={'location-string'}>{locationString}</div>
            </div>
        );
    }

    renderLocationMap() {
        const position = {lat: this.state.location.lat, lng: this.state.location.lon};

        return (
            <div className={'text-left'}>
                <T id="adminPanel.smartInventory.itemDetail.locationMap" defaultMessage="Karte des Objekts">
                    {message => <div className={'grey-text'}>{message}</div>}
                </T>
                <SimpleMap
                    containerElement={<div className="simple-map-container"/>}
                    mapElement={<div className="simple-map"/>}
                    center={position}
                >
                    <Marker position={position}/>
                </SimpleMap>
            </div>
        );
    }

    renderFooter() {
        return <div className="footer"></div>;
    }
}

export default injectIntl(ItemDetailsOverlay);
