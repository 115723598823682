import React from "react";
import Link from "react-router-dom/es/Link";
import { withRouter } from "react-router-dom";
import { GeofencingEntry } from "./GeofencingEntry";
import { LoadingCircle } from "../LoadingCircle";
import { FormattedMessage as T } from "react-intl";

const fn = ({
  deletedGeofence,
  geofences = [],
  loadingState,
  match,
  requestDelete,
  savedGeofenceName,
}) => {
  const portal = match.params.portal || "tracar";
  return (
    <div className="outer-table-frame">
      {savedGeofenceName && (
        <div className="notification">
          <T
            id="tracar.geofencing.saved"
            defaultMessage="Die Sicherheitszone {name} wurde gespeichert."
            values={{ name: <strong>{savedGeofenceName}</strong> }}
          />
        </div>
      )}
      {deletedGeofence && (
        <div className="notification">
          <T
            id="tracar.geofencing.deleted"
            defaultMessage="Die Sicherheitszone wurde gelöscht."
          />
        </div>
      )}
      <div className="button-frame">
        <div className="button-block">
          <Link to={`/${portal}/geofencing/new`} className="button expanded">
            <T
              id="tracar.geofencing.new"
              defaultMessage="Neue Sicherheitszone erstellen"
            />
          </Link>
        </div>
      </div>
      <div className="table-frame">
        <div className="table-header">
          <div className="fa fa-globe" />
          &nbsp;&nbsp;&nbsp;
          <T id="tracar.geofencing.zones" defaultMessage="Sicherheitszonen" />
        </div>
        {loadingState === "loading" && <LoadingCircle />}
        {loadingState !== "loading" && geofences && geofences.length === 0 && (
          <div className="table-line">
            <T
              id="tracar.geofencing.noZones"
              defaultMessage="Sie haben noch keine Sicherheitszonen erstellt."
            />
          </div>
        )}
        {loadingState !== "loading" &&
          geofences &&
          geofences.map(geofence => (
            <GeofencingEntry
              key={geofence.geofence_id}
              portal={portal}
              geofence={geofence}
              requestDelete={requestDelete}
            />
          ))}
      </div>
    </div>
  );
};

export const GeofencingList = withRouter(fn);
