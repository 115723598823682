import React from "react";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import Plot from "react-plotly.js";
import { FormattedMessage as T } from "react-intl";

export function CarsLastRange({
  config,
  plotLayoutCommons,
  distance,
  noAxisValues,
  timeSpan = "1d",
}) {
  const x = distance.map(({ device_name }) => device_name);

  return (
    <div className="graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          <i className="fa fa-truck" />
          {timeSpan === "1d" ? (
            <T
              id="tracar.dashboard.vehiclePerformanceYesterday"
              defaultMessage="Fahrzeugleistung, gestern"
            />
          ) : (
            <T
              id="tracar.dashboard.vehiclePerformanceLast30Days"
              defaultMessage="Fahrzeugleistung, letzte 30 Tage"
            />
          )}
        </h5>
      </div>
      <div className="tiny-spacer" />
      <Plot
        config={config}
        data={[
          {
            x,
            y: distance.map(({ device_distance }) => device_distance),
            type: "bar",
            fill: "grey",
            mode: "lines+points",
            text: "km",
            hoverinfo: "x+y+text",
            marker: { color: Color.Silver },
          },
        ]}
        layout={{
          ...plotLayoutCommons,
          yaxis: {
            tickformat: ",.0f,",
          },
          xaxis: {
            ...noAxisValues,
            type: "category",
          },
          margin: { ...plotLayoutCommons.margin, b: 40, l: 60 },
        }}
      />
      <div
        style={{
          marginLeft: timeSpan === "1d" ? "52px" : "30px",
          marginTop: "-22px",
        }}
      >
        {timeSpan === "1d" ? (
          <T
            id="tracar.dashboard.kmDrivenYesterdayPerVehicle"
            defaultMessage="gestern gefahrene km, pro Fahrzeug"
          />
        ) : (
          <T id="tracar.dashboard.kmDrivenLast30DaysPerVehicle" defaultMessage="letzte 30 Tage gefahrene km, pro Fahrzeug" />
        )}
      </div>
    </div>
  );
}
