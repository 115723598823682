import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import { removeArrayElement } from "../utility";
import { LoadingCircle } from "../LoadingCircle";

export class AssignTagsOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedTags: props.device.tags,
    };
  }

  changeAssignedTags = tagId => {
    const index = this.state.assignedTags.indexOf(tagId);
    if (index === -1) {
      this.setState({ assignedTags: [...this.state.assignedTags, tagId] });
    } else {
      this.setState({
        assignedTags: removeArrayElement(this.state.assignedTags, index),
      });
    }
  };

  saveTagAssignment = () => {
    this.props.setTagsAsSaving();
    this.props.fetchTags
      .assignTagsToDevice(this.props.device.imei, this.state.assignedTags)
      .then(_ => {
        this.props.closeFunction();
        this.props.loadDevices();
        this.props.setTagsAsSaving(false);
      });
  };

  render() {
    if (this.props.tagListLoadingState !== "ready") {
      return (
        <RequestOverlay closeFunction={this.props.closeFunction}>
          <LoadingCircle />
        </RequestOverlay>
      );
    }
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <h3>Tags zuordnen</h3>
        <div>
          <strong>{this.props.device.name}</strong>
        </div>
        <div>{this.props.device.description}</div>
        <div className="small-spacer" />
        <ul className="tag-buttons assign-list">
          {this.props.tagList.tag_id_list.map(tagId => {
            const tagObject = this.props.tagList.tag_objects[tagId.toString()];
            return (
              <li
                className={
                  "button button-100 " +
                  (this.state.assignedTags.find(id => id === tagId)
                    ? ""
                    : " inactive ")
                }
                onClick={() => this.changeAssignedTags(tagId)}
                key={tagId}
              >
                <i
                  className="fa fa-tag"
                  style={{ color: tagObject.tag_color }}
                />
                &nbsp;&nbsp;&nbsp;{tagObject.tag_name}
              </li>
            );
          })}
        </ul>
        <div className="flex-just-between">
          <div className="link" onClick={this.props.closeFunction}>
            abbrechen
          </div>
          <div
            className="button save-button save-button"
            onClick={this.saveTagAssignment}
          >
            Zuordnung speichern
          </div>
        </div>
      </RequestOverlay>
    );
  }
}
