import React from "react";
import { AccLogo } from "../ClientSpecificContent/acc-index";

import { Link } from "react-router-dom";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

import { LoadingCircle } from "../LoadingCircle";

const terms = defineMessages({
  email: {
    id: "login.email",
    defaultMessage: "E-Mail",
  },
  password: {
    id: "login.password",
    defaultMessage: "Passwort",
  },
  login: {
    id: "login.login",
    defaultMessage: "Login",
  },
});

class LoginClass extends React.Component {
  login;
  handleInputChange;

  constructor(props) {
    super(props);
    this.state = {
      email: process.env.REACT_APP_USER || "",
      password: process.env.REACT_APP_PASS || "",
      showErrorMessage: false,
      showRateLimitingMessage: false,
      // contains an error emssage sent by the API to be displayed in the login form (i.e. rate limiting error)
      nonFieldErrorMessage: null,
      loading: false,
    };
    this.login = this.login.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  login(event) {
    event.preventDefault();
    this.setState({ loading: true });
    this.props
      .loginCall(this.state.email, this.state.password)
      .then(res => {
        this.setState({ loading: false });
        this.props.setLoginData(res.token, res.user);
        this.props.setLoggedIn(true);
      })
      .catch(err => {
        this.setState({ loading: false });
        /// Überschreiben der Fehlermeldung
        if (err.status === 401 || err.status === 400) {
          this.setState({ showErrorMessage: false }, () =>
            this.setState({ showErrorMessage: true }),
          );
          //this.forceUpdate();
        }
        // error 403 means the API started limiting the rate of requests -> show message sent in response body
        if (err.status === 403) {
          this.setState({
            showRateLimitingMessage: true,
            nonFieldErrorMessage: err.body.detail,
          });
        }
        return err;
      });
  }

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <div className="login-overlay">
        <form className="login-form" onSubmit={this.login}>
          <div className="logo">
            <img className="login-logo" src={AccLogo} alt="Logo" />
          </div>
          <h1>
            <T id="login.title" />
          </h1>
          {this.state.loading || this.props.loading ? (
            <LoadingCircle />
          ) : (
            <div>
              {this.props.passwordResetHasBeenRequested && (
                <div>
                  <T
                    id="login.newPasswordRequested"
                    defaultMessage="Sie erhalten einen Link, mit dem Sie Ihr Passwort zurücksetzen können, per E-Mail."
                  />
                </div>
              )}
              {this.props.passwordHasBeenReset && (
                <div>
                  <T
                    id="login.newPassword"
                    defaultMessage="Ihr Passwort wurde zurückgesetzt."
                  />
                </div>
              )}
              {this.props.userActivation === "success" && (
                <div>
                  <T
                    id="login.accountActivation"
                    defaultMessage="Ihr Account wurde aktiviert. Sie können sich mit Ihrer E-Mailadresse und dem zugesandten Passwort einloggen."
                  />
                </div>
              )}
              {this.props.userActivation === "failure" && (
                <div>
                  <T
                    id="login.accountActivationFailure"
                    defaultMessage="Die Aktivierung ist fehlgeschlagen. Entweder Ihr Konto wurde bereits aktiviert und Sie können sich einloggen oder der Aktivierungslink ist ungültig."
                  />
                </div>
              )}
              <div className="small-spacer" />
              <div className="text-input-component noPadding">
                <input
                  value={this.state.email}
                  type="text"
                  name="email"
                  id="email"
                  onChange={this.handleInputChange}
                  placeholder={t(terms.email)}
                />
                <label htmlFor="email">{t(terms.email)}</label>
              </div>
              <div className="text-input-component noPadding">
                <input
                  type="password"
                  value={this.state.password}
                  name="password"
                  id="password"
                  onChange={this.handleInputChange}
                  placeholder={t(terms.password)}
                />
                <label htmlFor="password">{t(terms.password)}</label>
              </div>
              {this.state.showErrorMessage && (
                <div className="error dist-below">
                  <T
                    id="login.wrongEmail"
                    defaultMessage="Falsche E-Mailadresse"
                  />
                  <br />
                  <T
                    id="login.orWrongPassword"
                    defaultMessage="oder falsches Passwort"
                  />
                </div>
              )}
              {this.state.showRateLimitingMessage && (
                <div className="error dist-below">
                  <span>{this.state.nonFieldErrorMessage}</span>
                </div>
              )}
              <input
                className="button border"
                type="submit"
                value={t(terms.login)}
              />
              <div className="spacer-small" />
              <div>
                <Link to="/reset-password">
                  <small>
                    <T
                      id="login.forgottenPassword"
                      defaultMessage="Passwort vergessen?"
                    />
                  </small>
                </Link>
              </div>
            </div>
          )}
        </form>
        <div className="login-version-number">
          {process.env.REACT_APP_VERSION_NUMBER}{" "}
        </div>
      </div>
    );
  }
}

export default injectIntl(LoginClass);
