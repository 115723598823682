import { vsprintf } from 'sprintf-js';
import {postJson, putJson} from "./fetchService";

const deleteItemEndpoint = '/user/smart_inventory/%s';
const generateQRCodeEndpoint = '/user/smart_inventory/qrcode';
const createInventoryItemEndpoint = '/user/smart_inventory/item';
const updateItemEndpoint = '/user/smart_inventory/item_update/%s';
const uploadCsvEndpoint = '/admin_user/smart_inventory/upload_item';
const getItemReportEndpoint = '/admin_user/smart_inventory/item_report/%s';
const getItemLocationEndpoint = '/admin_user/smart_inventory/item_location/%s'
const getItemTemplatesEndpoint = '/admin_user/smart_inventory/item/template/%s';
const assignTemplateToItemEndpoint = '/admin_user/smart_inventory/item/template';
const getInventoryItemsEndpoint = '/admin_user/smart_inventory/global_items?offset=%d&limit=%d';

const createTemplateEndpoint = '/admin_user/smart_inventory/template';
const getTaskTemplatesEndpoint = '/admin_user/smart_inventory/template';
const deleteTaskEndpoint = '/admin_user/smart_inventory/task/template/%d';
const deleteTaskTemplateEndpoint = '/admin_user/smart_inventory/template/%d';
const updateTemplateNameEndpoint = '/admin_user/smart_inventory/template/%d';
const createTemplateTaskEndpoint = '/admin_user/smart_inventory/template/multiple_tasks/%d';
const assignTemplatesToItemsEndpoint = '/admin_user/smart_inventory/item/template';
const getTaskTemplateTasksEndpoint = '/admin_user/smart_inventory/template/task/%d';

export async function fetchInventoryItems(jsonFetch, options) {
    let endpoint = vsprintf(getInventoryItemsEndpoint, [options.offset, options.limit]);

    if (options.search) {
        endpoint += `&search=${options.search}`
    }

    if (options.reservation === 1 || options.reservation === 0) {
        endpoint += `&reservation=${options.reservation}`
    }

    return jsonFetch(endpoint);
}

export async function createNewInventoryItem(jsonFetch, data) {
    return jsonFetch(createInventoryItemEndpoint, postJson(data));
}

export function uploadItems(jsonFetch, file) {
    // This endpoint seems to differ from the other CSV upload endpoints.
    // That's why we can't use the usual postCSV(file) request.

    const formData = new FormData();
    formData.append("file", file, file.name);

    return jsonFetch(uploadCsvEndpoint, {
        method: "POST",
        body: formData
    });
};

export function updateItem(jsonFetch, itemId, data) {
    const endpoint = vsprintf(updateItemEndpoint, [itemId]);

    return jsonFetch(endpoint, putJson(data));
}

export function deleteItem(jsonFetch, itemId) {
    const endpoint = vsprintf(deleteItemEndpoint, [itemId]);

    return jsonFetch(endpoint, {
        method: 'DELETE'
    });
}

export function getItemLocation(jsonFetch, itemId) {
    const endpoint = vsprintf(getItemLocationEndpoint, [itemId]);

    return jsonFetch(endpoint);
}

export function getItemTemplates(jsonFetch, itemId) {
    const endpoint = vsprintf(getItemTemplatesEndpoint, [itemId]);

    return jsonFetch(endpoint);
}

export function generateItemQRCode(freeFetch, itemId) {
    return freeFetch(generateQRCodeEndpoint, {
        ...postJson({item_uuid: [itemId]}),
        responseType: "blob"
    });
}

export function generateItemReport(freeFetch, itemId) {
    const endpoint = vsprintf(getItemReportEndpoint, [itemId]);

    return freeFetch(endpoint, {responseType: "blob"});
}

export function assignTemplateToItem(jsonFetch, data) {
    return jsonFetch(assignTemplateToItemEndpoint, putJson(data));
}

export function fetchTaskTemplates(jsonFetch) {
    return jsonFetch(getTaskTemplatesEndpoint);
}

export function fetchTaskTemplateTasks(jsonFetch, templateId) {
    const endpoint = vsprintf(getTaskTemplateTasksEndpoint, [templateId]);
    return jsonFetch(endpoint);
}

export function deleteTemplate(jsonFetch, templateId) {
    const endpoint = vsprintf(deleteTaskTemplateEndpoint, [templateId]);

    return jsonFetch(endpoint, {
        method: 'DELETE',
    });
}

export function deleteTemplateTask(jsonFetch, taskId) {
    const endpoint = vsprintf(deleteTaskEndpoint, [taskId]);

    return jsonFetch(endpoint, {
        method: 'DELETE',
    });
}

export function updateTemplateName(jsonFetch, templateId, data) {
    const endpoint = vsprintf(updateTemplateNameEndpoint, [templateId]);

    return jsonFetch(endpoint, putJson(data));
}

export function createTemplate(jsonFetch, name) {
    return jsonFetch(createTemplateEndpoint, postJson({name}));
}

export function createTemplateTasks(jsonFetch, templateId, data) {
    const endpoint = vsprintf(createTemplateTaskEndpoint, [templateId]);

    return jsonFetch(endpoint, postJson(data));
}

export function assignTemplateToTask(jsonFetch, data) {
    return jsonFetch(assignTemplatesToItemsEndpoint, putJson(data));
}

