import React from "react";

export class PaginationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPages = () => {
    let pages = [];
    for (let i = 0; i < this.props.numberOfPages; i++) {
      pages.push(
        <li
          className={`${this.props.currentPage == i && "active"}`}
          onClick={() => this.props.updateCurrentPage(i)}
          key={i}
        >
          <span className="page-number">{i + 1}</span>
        </li>,
      );
    }

    return pages;
  };

  toggleCurrentPage = direction => {
    this.props.updateCurrentPage(this.props.currentPage + direction);
  };

  arrowLeft = () => {
    return (
      <div
        className="prev-btn"
        onClick={() => this.props.currentPage > 0 && this.toggleCurrentPage(-1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
        >
          <g
            id="Gruppe_69"
            data-name="Gruppe 69"
            transform="translate(-783 -1060)"
          >
            <circle
              id="Ellipse_32"
              data-name="Ellipse 32"
              cx="13"
              cy="13"
              r="13"
              transform="translate(783 1060)"
              fill="#efefef"
            />
            <g
              id="Gruppe_68"
              data-name="Gruppe 68"
              transform="translate(458.561 798.692)"
            >
              <line
                id="Linie_21"
                data-name="Linie 21"
                x2="10.912"
                transform="translate(331.827 274.057)"
                fill="none"
                stroke="#5a5a5a"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Pfad_439"
                data-name="Pfad 439"
                d="M337.577,279.806l-5.749-5.749,5.749-5.749"
                fill="none"
                stroke="#5a5a5a"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  };

  arrowRight = () => {
    return (
      <div
        className="next-btn"
        onClick={() =>
          this.props.currentPage < this.props.numberOfPages - 1 &&
          this.toggleCurrentPage(1)
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
        >
          <g
            id="Gruppe_70"
            data-name="Gruppe 70"
            transform="translate(809 1086) rotate(180)"
          >
            <circle
              id="Ellipse_32"
              data-name="Ellipse 32"
              cx="13"
              cy="13"
              r="13"
              transform="translate(783 1060)"
              fill="#efefef"
            />
            <g
              id="Gruppe_68"
              data-name="Gruppe 68"
              transform="translate(458.561 798.692)"
            >
              <line
                id="Linie_21"
                data-name="Linie 21"
                x2="10.912"
                transform="translate(331.827 274.057)"
                fill="none"
                stroke="#5a5a5a"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Pfad_439"
                data-name="Pfad 439"
                d="M337.577,279.806l-5.749-5.749,5.749-5.749"
                fill="none"
                stroke="#5a5a5a"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  };

  render() {
    return (
      <div className="flex-container align-center-middle">
        <div className="pagination">
          {this.arrowLeft()}
          <ul className="menu">{this.getPages()}</ul>
          {this.arrowRight()}
        </div>
      </div>
    );
  }
}
