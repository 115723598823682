import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { LoadingCircle } from "../LoadingCircle";
import { RfidChipTableLine } from "./RfidChipTableLine";
import { TrackerTableHead } from "../TrackerList/TrackerTableHead";

const terms = defineMessages({
  registeredDevices: {
    id: "tracar.rfid-chip-list.rfidChips",
    defaultMessage: "RFID-Chips:",
  },
});

class RfidChipListClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { rfidChipList } = this.props;
    const t = this.props.intl.formatMessage;
    return (
      <div className="table-frame">
        <TrackerTableHead
          headline={t(terms.registeredDevices)}
          iconClasses="icon-rfid-chip"
        />
        {this.props.rfidableLoadingState !== "ready" &&
          this.props.rfidChipLoadingState !== "ready" && <LoadingCircle />}
        {rfidChipList.length === 0 &&
          this.props.rfidChipLoadingState === "ready" && (
            <div className="table-line">
              <T
                id="tracar.rfid-chip-list.noDevices"
                defaultMessage="Sie haben noch keine RFID-Chips registriert."
              />
            </div>
          )}
        {this.props.rfidChipLoadingState === "ready" &&
          rfidChipList.reduce((acc, rfidChip) => {
            const stringFiltering =
              this.props.searchFilter.length === 0 ||
              rfidChip.chip_name
                .toLowerCase()
                .search(this.props.searchFilter.toLowerCase()) > -1 ||
              String(rfidChip.rfid_chip_id).search(this.props.searchFilter) >
                -1 ||
              rfidChip.chip_code
                .toLowerCase()
                .search(this.props.searchFilter.toLowerCase()) > -1 ||
              rfidChip.fname
                .toLowerCase()
                .search(this.props.searchFilter.toLowerCase()) > -1 ||
              rfidChip.lname
                .toLowerCase()
                .search(this.props.searchFilter.toLowerCase()) > -1;

            if (!stringFiltering) {
              return acc;
            }

            return [
              ...acc,
              <RfidChipTableLine
                key={rfidChip.rfid_chip_id}
                rfidChip={rfidChip}
                toggleShowAssignDevicesOverlay={
                  this.props.toggleShowAssignDevicesOverlay
                }
                toggleDeleteConfirmationOverlay={
                  this.props.toggleDeleteConfirmationOverlay
                }
                toggleShowSettingsOverlay={this.props.toggleShowSettingsOverlay}
                updateRfidChipsToAssign={this.props.updateRfidChipsToAssign}
                updateRfidChipToAssign={this.props.updateRfidChipToAssign}
                rfidChipsToAssign={this.props.rfidChipsToAssign}
              />,
            ];
          }, [])}
      </div>
    );
  }
}

export const RfidChipList = injectIntl(RfidChipListClass);
