import { Map } from "immutable";
import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { MessageList } from "./MessageList";
import { removeSingleElementByCondition } from "../utility";
import { MessageHeadline } from "./MessageHeadline";
import ReactPaginate from "react-paginate";
import { MessageMapOverlay } from "./MessageMapOverlay";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";

const defaultPaginationLimit = 25;

// Translations
const terms = defineMessages({
  map_overlay_title: {
    id: "message.map.header.title",
    defaultMessage: "Kartenansicht",
  },
  selectMessagesType: {
    id: "messagesType.all",
    defaultMessage: "Nachrichtentyp auswählen",
  },
});

class MessagesBaseClass extends React.Component {
  imei;

  constructor(props) {
    super(props);
    // TODO: remove default when endpoints are ready
    this.imei = props.match.params.imei || "1001";
    this.state = {
      messageList: null,
      markedMessages: Map(),
      loading: true,
      offset: 0,
      paginationLimit: 25,
      selectedPage: 0,
      showMapOverlay: false,
      // message to display in map overlay
      mapMessageRecord: null,
      messageType: [],
      messageListNew: null,
      limit: 25,
      deviceListTypeFilter: 0,
    };
  }

  componentDidMount() {
    this.loadType();
    this.loadMessages(
      this.state.offset,
      defaultPaginationLimit,
      this.state.deviceListTypeFilter,
    );
  }

  deleteEntry = id => {
    const { offset, paginationLimit, deviceListTypeFilter } = this.state;
    this.setState({ loading: true });
    if (this.state.messageListNew == null) {
      return;
    }
    const [messages] = removeSingleElementByCondition(
      this.state.messageListNew.messages,
      ({ message_id }) => id === message_id,
    );
    const messageListNew = { ...this.state.messageListNew, messages };
    this.setState({ messageListNew });
    this.props
      .deleteMessagesNew([id])
      .then(() =>
        this.loadMessages(offset, paginationLimit, deviceListTypeFilter),
      );
  };

  deleteMarkedEntries = () => {
    const { offset, paginationLimit, deviceListTypeFilter } = this.state;
    if (this.state.messageListNew == null) {
      return;
    }
    this.setState({ loading: true });
    const toDelete = [...this.state.markedMessages.keys()];
    this.props
      .deleteMessagesNew(toDelete)
      .then(() =>
        this.loadMessages(offset, paginationLimit, deviceListTypeFilter),
      );
    /// nasty boilerplate...
    if (this.state.messageListNew == null) {
      return;
    }
    const messages = this.state.messageListNew.messages.filter(
      ({ message_id }) => !this.state.markedMessages.get(message_id),
    );
    const messageListNew = { ...this.state.messageListNew, messages };
    this.setState({ messageListNew });
  };

  loadType() {
    this.setState({ loading: true });
    this.props.messageTypeFilter().then(messageType => {
      this.setState({ messageType, loading: false });
    });
  }

  loadMessages(offset, limit, deviceListTypeFilter) {
    this.setState({ loading: true });
    this.props
      .messageListQueryNew(offset, limit, deviceListTypeFilter)
      .then(messageListNew => {
        this.setState({ messageListNew, loading: false });
      });
  }

  markAll = () => {
    if (this.state.messageListNew == null) {
      return;
    }
    const markedMessages = Map(
      this.state.messageListNew.messages.map(({ message_id }) => [
        message_id,
        true,
      ]),
    );
    this.setState({ markedMessages });
  };

  markAsRead = id => () => {
    this.changeMessageItemsToRead([id]);
    this.props.markMessagesAsReadNew([id]);
  };

  changeMessageItemsToRead = ids => {
    if (this.state.messageListNew == null) {
      return;
    }

    const messages = this.state.messageListNew.messages.map(message => {
      if (ids.indexOf(message.message_id) === -1) {
        return message;
      }
      return { ...message, read: true };
    });

    const messageListNew = { ...this.state.messageListNew, messages };

    this.setState({ messageListNew });
  };

  handlePagination = data => {
    /// pixel offset from the windows upper edge
    const paginationYOffset = 155;
    const selected = data ? data.selected : this.state.selectedPage;
    const { paginationLimit, deviceListTypeFilter } = this.state;
    let offset = Math.ceil(selected * paginationLimit);
    this.setState({
      offset,
      selectedPage: selected,
      markedMessages: Map(),
      loading: true,
      messageList: { ...this.state.messageList, messages: [] },
      messageListNew: { ...this.state.messageListNew, messages: [] },
    });

    this.loadMessages(offset, paginationLimit, deviceListTypeFilter);

    if (window.pageYOffset > paginationYOffset) {
      window.scrollTo(0, paginationYOffset);
    }
  };

  setPaginationLimit = paginationLimit => {
    this.setState({ paginationLimit }, this.handlePagination);
    this.setState({ selectedPage: 0 });
  };

  markSelectedAsRead = () => {
    const selected = [...this.state.markedMessages.keys()];
    this.changeMessageItemsToRead(selected);
    this.props.markMessagesAsReadNew(selected);
  };

  toggleMessageMark = id => {
    const markedMessages = this.state.markedMessages.has(id)
      ? this.state.markedMessages.delete(id)
      : this.state.markedMessages.set(id);
    this.setState({ markedMessages });
  };

  toggleMapOverlay = messageRecord => {
    this.setState({
      mapMessageRecord: messageRecord,
      showMapOverlay: !this.state.showMapOverlay,
    });
  };

  setMessagesFilter = event => {
    this.setState(
      {
        deviceListTypeFilter: +event.target.value,
      },
      this.loadMessages(
        this.state.offset,
        this.state.limit,
        +event.target.value,
      ),
    );
  };

  render() {
    let messageTypeList = Object.entries(this.state.messageType);

    const t = this.props.intl.formatMessage;
    const { markedMessages, messageListNew } = this.state;
    const pageCount =
      messageListNew != null
        ? Math.ceil(messageListNew.total_amount / this.state.paginationLimit)
        : 0;

    const paginationLimitDefinition = setLimit => {
      const className =
        "link" + (setLimit === this.state.paginationLimit ? " active" : "");
      return {
        key: setLimit,
        onClick: () => this.setPaginationLimit(setLimit),
        className,
      };
    };

    const selectType = (
      <select
        className="message-category"
        onChange={this.setMessagesFilter}
        value={this.state.deviceListTypeFilter}
      >
        <option value="0">
          {t(terms.selectMessagesType)}
          {/* <FormattedMessage id="messagesType.all" /> */}
        </option>
        {messageTypeList.length > 0 &&
          messageTypeList.map(itemType => (
            <option value={itemType[1].id} key={itemType[1].id}>
              <FormattedMessage
                id={`messagesType.${itemType[1].display_name}`}
                // defaultMessage={`${itemType[1].display_name}`}
              >
                {message => `${message}`}
              </FormattedMessage>
            </option>
          ))}
      </select>
    );

    const pagination =
      messageListNew != null && pageCount > 1 ? (
        <div className="pagination-wrapper">
          <div className="global-message-pagination">
            <ReactPaginate
              activeClassName={"active"}
              breakClassName={"break-me"}
              breakLabel={<a>...</a>}
              containerClassName={"pagination"}
              forcePage={this.state.selectedPage}
              marginPagesDisplayed={2}
              nextLabel={<i className="fa fa-chevron-circle-right" />}
              onPageChange={this.handlePagination}
              pageCount={pageCount}
              pageRangeDisplayed={5}
              previousLabel={<i className="fa fa-chevron-circle-left" />}
              subContainerClassName={"pages pagination"}
            />
            {selectType}
          </div>
          <ul className="pagination-limiter">
            {[25, 100, 500].map(limit => (
              <li {...paginationLimitDefinition(limit)}>{limit}</li>
            ))}
            &nbsp;&nbsp; Nachrichten anzeigen
          </ul>
        </div>
      ) : (
        <div className="pagination-wrapper">
          <div className="global-message-pagination">{selectType}</div>
        </div>
      );

    return (
      <div className="outer-table-frame tracker">
        {pagination}
        <div className="table-frame">
          {messageListNew != null && (
            <div>
              <MessageHeadline
                deleteMarkedEntries={this.deleteMarkedEntries}
                markAll={this.markAll}
                markedMessages={markedMessages}
                markSelectedAsRead={this.markSelectedAsRead}
                messageList={messageListNew}
              />
              <MessageList
                selectType={selectType}
                deleteEntry={this.deleteEntry}
                markedMessages={markedMessages}
                markAsRead={this.markAsRead}
                messageList={messageListNew}
                toggleMessageMark={this.toggleMessageMark}
                toggleMapOverlay={this.toggleMapOverlay}
              />
              {this.state.showMapOverlay && (
                <div className="new-interval-modal">
                  <RequestOverlay
                    closeFunction={() => this.toggleMapOverlay(null)}
                    className="message-map-container"
                    enableCloseOnOverlayClicked={true}
                  >
                    <div className="headline">
                      <div>{t(terms.map_overlay_title)}</div>
                    </div>
                    <MessageMapOverlay
                      portal={this.props.match.params.portal}
                      containerElement={<div className="map-container" />}
                      mapElement={
                        <div
                          style={{
                            height: `70vh`,
                            "margin-top": "25px",
                          }}
                        />
                      }
                      closeFunction={() => this.toggleMapOverlay(null)}
                      message={this.state.mapMessageRecord}
                    />
                  </RequestOverlay>
                </div>
              )}
            </div>
          )}
          {(messageListNew == null || this.state.loading) && (
            <div className="message-loading-circle">
              <LoadingCircle />
            </div>
          )}
        </div>
        {messageListNew == null || this.state.loading ? (
          <div className="message-loading-circle">
            <LoadingCircle />
          </div>
        ) : messageListNew.total_amount > 24 ? (
          <div>{pagination}</div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export const MessageBase = injectIntl(MessagesBaseClass);
