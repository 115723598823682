import { postJson, putJson, getJson, deleteJson } from "./fetchService";

export const fetchMessages = jsonFetch => ({
  messageListQueryNew(offset, limit, notificationId) {
    console.log(notificationId);
    if (!notificationId) {
      const route = `/user/messages?offset=${offset}&limit=${limit}`;
      return jsonFetch(route, getJson());
    } else {
      const route = `/user/messages?offset=${offset}&limit=${limit}&notification_type_id=${notificationId}`;
      return jsonFetch(route, getJson());
    }
  },
  messageTypeFilter() {
    const route = `/user/messages/types`;
    return jsonFetch(route, getJson());
  },
  markMessagesAsReadNew(messageIds) {
    const route = `/user/messages`;
    const postData = {
      message_ids_read: messageIds,
    };
    return jsonFetch(route, putJson(postData));
  },
  deleteMessagesNew(messageIds) {
    const route = `/user/messages`;
    const postData = {
      message_ids: messageIds,
    };
    return jsonFetch(route, deleteJson(postData));
  },

  messageListQuery(imei, pagination) {
    const route = `/user/messagepagination/${imei}`;
    return jsonFetch(route, postJson(pagination));
  },
  markMessagesAsRead(imei, messageIds) {
    const route = `/user/messages/${imei}`;
    const postData = messageIds.map(message_id => ({
      message_id,
      read: true,
    }));
    return jsonFetch(route, putJson(postData));
  },
  deleteMessages(imei, messageIds) {
    const route = `/user/messages/${imei}`;
    const postData = messageIds.map(message_id => ({
      message_id,
      delete: true,
    }));
    return jsonFetch(route, putJson(postData));
  },
});
