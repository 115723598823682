/// transform loaded data to form data

export function generateTrackerDetailFormData(
  data,
  deviceEmailListArg,
  max_speedArg,
) {
  let deviceEmailList = deviceEmailListArg.map(({ mail }) => mail);
  deviceEmailList = deviceEmailList.length === 0 ? [""] : deviceEmailList;
  const max_speed = max_speedArg.max_speed === -1 ? "" : max_speedArg.max_speed;
  deviceEmailList = deviceEmailList.length === 0 ? [] : deviceEmailList;
  return {
    classify_routes: data.cardetails.classify_routes,
    color: data.color,
    description: data.description,
    deviceEmailList,
    max_speed,
    name: data.name,
    public: data.public,
    user_id: data.user_id,
  };
}
