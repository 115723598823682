import React from "react";
import Link from "react-router-dom/es/Link";
import { defineMessages, injectIntl } from "react-intl";
import { Portal, PortalDefault } from "./Portal";
import { UserConsumer } from "./Base.jsx";
import FormattedMessageFixed from "./tools/FormattedMessageFixed";
import { hasPermission, hasPortalAccess, Permission } from "./Permission";
import ReactTooltip from "react-tooltip";
import LinkButton from "./LinkButton";

const messages = defineMessages({
    map: {
        id: "base.map",
        defaultMessage: "Kartenansicht",
    },
    deviceList: {
        id: "base.deviceList",
        defaultMessage: "Geräteliste",
    },
    geofences: {
        id: "base.geofences",
        defaultMessage: "Sicherheitszonen",
    },
    userList: {
        id: "base.userList",
        defaultMessage: "Nutzerliste",
    },
    dashboard: {
        id: "base.dashboard",
        defaultMessage: "Dashboard",
    },
    customerAddresses: {
        id: "base.customerAddresses",
        defaultMessage: "Kundenadressen",
    },
    rfid: {
        id: "base.rfid",
        defaultMessage: "RFID",
    },
    taskTemplates: {
        id: 'base.taskTemplates',
        defaultMessage: 'Aufgabenvorlagen',
    },
    expense: {
      id: "adminPanel.expense.portal.name",
      defaultMessage: "Kostenerfassung",
    },
    smartInventory: {
        id: 'base.smartInventory',
        defaultMessage: 'Inventory Monitoring Items',
    }
});

class AdminPortalSelectionClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const activePortal = (this.props.match && this.props.match.params.portal) || "";

        return (
            <UserConsumer>
                {user => {
                    const availablePortals = user.availableAdminPortals;

                    return (
                        <div className="tracker-type-tabs">
                            <div className="tabs-inner-wrapper">
                                <div style={{ display: "inline-block" }}>
                                    {
                                        availablePortals.map(portalToRender => {
                                            return this.renderPortalLink(
                                                portalToRender,
                                                user,
                                                activePortal == portalToRender
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    );
                }}
            </UserConsumer>
        );
    }

    renderPortalLink(portalToRender, user, isActive) {
        let destination = portalToRender === Portal.All ? 'map' : 'list';
        if (portalToRender === Portal.AdminPanelPoolVehicle) {
          destination = "dashboard";
        }
        if (portalToRender === PortalDefault) {
            return;
        }

        if (!hasPortalAccess(user, portalToRender)) {
            return;
        }

        return (
            <div className={`tab ${isActive ? "active" : ""}`} key={portalToRender}>
                <Link to={`/admin-panel/${portalToRender}/${destination}`} className="tab-label">
                    <FormattedMessageFixed
                        id={`adminPanel.portal.${portalToRender}`}
                        defaultMessage={`Portal ${portalToRender}`}
                    />
                </Link>
                { this.renderSubmenu(portalToRender, isActive) }
            </div>
        );
    }

    renderSubmenu(portalShown, isActive) {
        if (!isActive) {
            return;
        }

        if (portalShown === Portal.AdminPanelSmartInventory) {
            return this.renderSmartInventorySubmenu(portalShown);
        }

      if (portalShown === Portal.AdminPanelPoolVehicle) {
        return this.renderPoolVehicleSubmenu(portalShown);
      }
    }

    renderSmartInventorySubmenu(portalShown) {
        return (
            <div className="button-group">
                <ReactTooltip
                    delayShow={500}
                    class="tooltip"
                    border={true}
                    id="nav"
                />

                <LinkButton
                    iconClass="fa fa fa-wrench"
                    tip={this.props.intl.formatMessage(messages.smartInventory)}
                    to={`/admin-panel/${portalShown}/list`}
                />

                <LinkButton
                    iconClass="fa fa-list"
                    tip={this.props.intl.formatMessage(messages.taskTemplates)}
                    to={`/admin-panel/${portalShown}/task-templates`}
                />
            </div>
        );
    }

  renderPoolVehicleSubmenu(portalShown) {
    return (
      <div className="button-group">
        <ReactTooltip
          delayShow={500}
          class="tooltip"
          border={true}
          id="nav"
        />

          <LinkButton
            iconClass="fa fa-tachometer"
            tip={this.props.intl.formatMessage(messages.deviceList)}
            to={`/admin-panel/${portalShown}/dashboard`}
          />
          <LinkButton
            iconClass="fa fa-list"
            tip={this.props.intl.formatMessage(messages.deviceList)}
            to={`/admin-panel/${portalShown}/list`}
          />
          <LinkButton
            iconClass="fa fa-map-marker"
            tip={this.props.intl.formatMessage(messages.map)}
            to={`/admin-panel/${portalShown}/map`}
          />
      </div>
    );
  }
}

export const AdminPortalSelection = injectIntl(AdminPortalSelectionClass);
