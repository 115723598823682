import React from "react";
import { TextInput } from "../TextInput";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

/// TODO ponder extract function to separate file
function empty(string) {
  return string == null || string.trim() === "";
}

const terms = defineMessages({
  partnerLabel: {
    id: "tracar.fahrtenbuch.partnerLabel",
    defaultMessage: "Geschäftspartner (Firmenname)*",
  },
  reasonLabel: {
    id: "tracar.fahrtenbuch.reasonLabel",
    defaultMessage: "Anlass der Betriebsfahrt*",
  },
  commentLabel: {
    id: "tracar.fahrtenbuch.commentLabel",
    defaultMessage: "Anmerkungen",
  },
  save: {
    id: "tracar.fahrtenbuch.save",
    defaultMessage: "Änderungen speichern",
  },
  private: {
    id: "tracar.fahrtenbuch.private",
    defaultMessage: "Privat",
  },
});

class BusinessOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: props.entry.reason || "",
      partner: props.entry.partner || "",
      comments: props.entry.comments || "",
      validationErrorReason: false,
      validationErrorComments: false,
      validationErrorPartner: false,
      suggestCustomerList: null,
      selectedCustomersReasons: null,
    };
  }

  /// load proposed things here
  componentDidMount() {
    this.getCustomerSuggestion(this.state.partner);
  }

  handlePartnerChange = event => {
    const value = this.handleStringChange(event);
    this.getCustomerSuggestion(value);
  };

  async getCustomerSuggestion(query = "") {
    const { payload: suggestCustomerResult } = await this.props
      .suggestCustomer(this.props.entry.fahrtenbuch_id, query)
      .catch(() => {
        this.setState({ suggestCustomerList: null });
      });

    this.setState({ suggestCustomerList: suggestCustomerResult });
  }

  /// TODO this function is used several times refactor to import
  handleStringChange = event => {
    const value = event.target.value;
    const name = event.target.id;
    this.setState({ [name]: value });
    return value;
  };

  /**
   * Select a reason.
   * @param  suggestion
   * @return void
   */
  handleReasonSelection = suggestion => {
    this.setState({
      reason: suggestion,
      suggestReasonList: null, // hide suggestions
    });
  };

  /**
   * Select a customer. Prefill reason if only one is present.
   * Hide customer selection afterwards.
   * @param  suggestion
   * @return void
   */
  handleCustomerSelection = suggestion => {
    const dataToUpdate = {
      partner: suggestion.suggested_addr,
      suggestCustomerList: null, // hide suggestions
    };

    if (suggestion.suggested_reason.length === 1) {
      dataToUpdate.reason = suggestion.suggested_reason[0];
    } else {
      dataToUpdate.suggestReasonList = suggestion.suggested_reason;
    }

    this.setState(dataToUpdate);
  };

  validate = () => {
    const validationErrorReason = empty(this.state.reason);
    const validationErrorPartner = empty(this.state.partner);
    this.setState({ validationErrorReason });
    this.setState({ validationErrorPartner });
    return !validationErrorReason && !validationErrorPartner;
  };

  save = () => {
    console.debug(this.props.callbackBusiness);
    if (!this.validate()) {
      return;
    }
    this.props.callbackBusiness(
      this.state.partner,
      this.state.reason,
      this.state.comments,
    );
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="fahrtenbuch-entry-data"
      >
        <h5>
          <T
            id="tracar.fahrtenbuch.additionalData"
            defaultMessage="Zusätzliche Daten Betriebsfahrt"
          />
        </h5>
        <div className="spacer" />
        <form>
          <TextInput
            className={this.state.validationErrorPartner ? "error" : ""}
            id="partner"
            name={t(terms.partnerLabel)}
            value={this.state.partner}
            onChange={this.handlePartnerChange}
          />

          {this.state.suggestCustomerList != null ? (
            <div className="customer-suggestions">
              <div className="hint">
                <T
                  id="tracar.fahrtenbuch.suggestedCustomers"
                  defaultMessage="Vorgeschlagene Geschäftspartner"
                />
              </div>
              <div className="suggestions-container">
                {this.state.suggestCustomerList.map((suggestion, index) => (
                  <div
                    className="suggestion"
                    onClick={this.handleCustomerSelection.bind(
                      this,
                      suggestion,
                    )}
                    key={index}
                  >
                    {suggestion.suggested_addr}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          <TextInput
            className={this.state.validationErrorReason ? "error" : ""}
            id="reason"
            name={t(terms.reasonLabel)}
            value={this.state.reason}
            onChange={this.handleStringChange}
          />

          {this.state.suggestReasonList != null ? (
            <div className="customer-suggestions">
              <div className="hint">
                <T
                  id="tracar.fahrtenbuch.suggestedReasons"
                  defaultMessage="Bitte Anlass auswählen oder Neuen eintragen"
                />
              </div>
              <div className="suggestions-container">
                {this.state.suggestReasonList.map((suggestion, index) => (
                  <div
                    className="suggestion"
                    onClick={this.handleReasonSelection.bind(this, suggestion)}
                    key={index}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          <TextInput
            id="comments"
            name={t(terms.commentLabel)}
            value={this.state.comments}
            onChange={this.handleStringChange}
          />
          <div className="comment">
            <small
              className={
                this.state.validationErrorPartner ||
                this.state.validationErrorReason
                  ? "error"
                  : ""
              }
            >
              <T
                id="tracar.fahrtenbuch.required"
                defaultMessage="*)Pflichtfelder"
              />
            </small>
          </div>
          <div className="spacer" />
          <div className="button save-button" onClick={this.save}>
            {t(terms.save)}
          </div>
        </form>
      </RequestOverlay>
    );
  }
}

export const BusinessOverlay = injectIntl(BusinessOverlayClass);
