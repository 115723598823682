import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { RequestOverlay } from "../Request/RequestOverlay";
import { ExpenseCreateInline } from "./ExpenseCreateInline";
import moment from "moment/moment";
import { PaginationBar } from "../PaginationBar";
import { ExpenseEditInline } from "./ExpenseEditInline";

const terms = defineMessages({
  label_new_expense_button: {
    id: "adminPanel.expense.detailOverlay.buttonLabel.createExpense",
    defaultMessage: "Neue Kosten erfassen",
  },
});
const listLimit = 10;

class ExpenseOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showMore: false,
      showInnerCreateOverlay: false,
      showInnerEditOverlay: false,
      requestOverlayClassName: "",
      recordList: [],
      selectedExpense: null,
      totalCost: 0,
      // total number of devices across all pagination pages
      numberOfDevices: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // used by pagination to fetch the devices displayed on the current pagination page
      listOffset: 0,
    };
  }

  async componentDidMount() {
    if (this.props.expense) {
      this.toggleInnerEditOverlay(this.props.expense);
    } else {
      this.load();
    }
  }

  async load() {
    this.props
      .getExpenseListForDevice(
        this.props.device.imei,
        this.state.listOffset,
        listLimit,
      )
      .then(recordListData =>
        this.setState({
          loading: false,
          recordList: recordListData.receipts,
          totalCost: recordListData.total_cost,
          numberOfDevices: recordListData.total_amount,
          numberOfPages: Math.ceil(recordListData.total_amount / listLimit),
        }),
      );
  }

  async acknowledgeExpense(expenseId) {
    this.props.acknowledgeExpense(expenseId).then(() => this.load());
  }

  async deleteExpense(expenseId) {
    this.props.deleteExpense(expenseId).then(() => this.load());
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        className={this.state.requestOverlayClassName + " pool-vehicle"}
        closeFunction={this.props.closeFunction}
      >
        <div className="header text-left margin-bottom-1">
          <h4>
            <T
              id="adminPanel.expense.expenseDetail.headline"
              defaultMessage="Poolfahrzeug"
            />{" "}
            -> <span className="highlighted">{this.props.device.imei}</span>
          </h4>
        </div>
        {!this.state.showInnerCreateOverlay &&
          !this.state.showInnerEditOverlay && (
            <React.Fragment>
              <div className="grid-container">
                <div className="grid-x grid-padding-x">
                  <div className="cell text-right">
                    <div
                      className="button primary"
                      onClick={this.toggleInnerCreateOverlay}
                    >
                      {t(terms.label_new_expense_button)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-frame filter-table admin-list">
                <div className="grid-x table-line filter text-left">
                  <div className="cell small-12 medium-6 large-2">
                    <a className="" href="#">
                      <T
                        id="portal.expense.overlay.label.category"
                        defaultMessage="Kostenart"
                      />
                    </a>
                  </div>
                  <div className="cell small-12 medium-6 large-1">
                    <a className="" href="#">
                      <T
                        id="portal.expense.overlay.label.date"
                        defaultMessage="Datum"
                      />
                    </a>
                  </div>
                  <div className="cell small-12 medium-6 large-4">
                    <T
                      id="portal.expense.overlay.label.description"
                      defaultMessage="Beschreibung"
                    />
                  </div>
                  <div className="cell small-12 medium-6 large-3 text-right">
                    <a className="" href="#">
                      <strong>
                        <T
                          id="portal.expense.overlay.label.amount"
                          defaultMessage="Kosten"
                        />{" "}
                        (
                        <T
                          id="portal.expense.overlay.label.total"
                          defaultMessage="Bestätigte Belege"
                        />
                        : {this.state.totalCost}€)
                      </strong>
                    </a>
                  </div>
                  <div className="cell small-12 medium-1 large-auto" />
                </div>

                {this.state.recordList.map(expense => (
                  <div
                    key={expense.id}
                    className="table-line grid-x gird-padding-x align-center-middle pool-vehicle detail-price-list text-left"
                  >
                    <div className="cell small-12 medium-6 large-2 first-cell">
                      <strong>
                        <T
                          id={`expense.category.${expense.category_name}`}
                          defaultMessage={expense.category_name}
                        />
                      </strong>
                    </div>
                    <div className="cell small-12 medium-6 large-1">
                      {moment(expense.start_date).format("DD.MM.YYYY")}
                    </div>
                    <div className="cell small-12 medium-12 large-4">
                      {expense.description}
                    </div>
                    <div className="cell small-12 medium-12 large-3 text-right">
                      <strong>{expense.amount}€</strong>
                    </div>
                    <div className="cell small-12 large-auto flex align-right">
                      <i
                        className={`fa fa-check-circle ${
                          expense.is_acknowledged ? "" : "disabled"
                        }`}
                      />
                    </div>
                    <div className="cell small-12 large-auto flex align-right icon-wrap pipe-left-14">
                      <a
                        href="#"
                        onClick={() => this.props.getExpenseImage(expense.id)}
                      >
                        <i
                          className={`fa fa-arrow-circle-o-down margin-horizontal-0 ${
                            expense.image ? "" : "disabled"
                          }`}
                        />
                      </a>
                      <a
                        href="#"
                        onClick={() =>
                          !expense.is_acknowledged &&
                          this.toggleInnerEditOverlay(expense)
                        }
                      >
                        <i
                          className={`fa fa-pencil margin-horizontal-1 ${
                            expense.is_acknowledged ? "disabled" : ""
                          }`}
                        />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <PaginationBar
                currentPage={this.state.currentPage}
                numberOfPages={this.state.numberOfPages}
                updateCurrentPage={this.updatePagination}
              />
            </React.Fragment>
          )}

        {this.state.showInnerCreateOverlay && (
          <ExpenseCreateInline
            closeFunction={this.toggleInnerCreateOverlay}
            createExpense={this.props.createExpense}
            showInnerSaveButtons={true}
            getExpenseCategoryList={this.props.getExpenseCategoryList}
            showInnerCreateOverlay={this.state.showInnerCreateOverlay}
            imei={this.props.device.imei}
          />
        )}

        {this.state.showInnerEditOverlay && (
          <ExpenseEditInline
            closeFunction={this.toggleInnerEditOverlay}
            expense={this.state.selectedExpense}
            editExpense={this.props.editExpense}
            showInnerSaveButtons={true}
            getExpenseCategoryList={this.props.getExpenseCategoryList}
            showInnerEditOverlay={this.state.showInnerEditOverlay}
          />
        )}

        {!this.state.showInnerCreateOverlay &&
          !this.state.showInnerEditOverlay && (
            <div>
              <React.Fragment>
                <div className="small-spacer" />
                <div className="footer text-right">
                  <div
                    className="button transparent"
                    onClick={this.props.closeFunction}
                  >
                    <T
                      id="adminPanel.expense.detailOverlay.buttonLabel.cancel"
                      defaultMessage="Schließen"
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
      </RequestOverlay>
    );
  }

  toggleInnerCreateOverlay = () => {
    this.setState(
      {
        showInnerCreateOverlay: !this.state.showInnerCreateOverlay,
        requestOverlayClassName:
          this.state.requestOverlayClassName === "" ? "" : "",
      },
      () => {
        this.load();
      },
    );
  };

  toggleInnerEditOverlay = expense => {
    this.setState(
      {
        showInnerEditOverlay: !this.state.showInnerEditOverlay,
        selectedExpense: expense,
        requestOverlayClassName:
          this.state.requestOverlayClassName === "" ? "" : "",
      },
      () => {
        this.load();
      },
    );
  };

  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * listLimit);
  };

  updateListOffset = offset => {
    this.setState({ listOffset: offset }, this.load);
  };
}

export const ExpenseOverlay = injectIntl(ExpenseOverlayClass);
