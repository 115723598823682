import "react-datepicker/dist/react-datepicker.css";
import "./styles/__screen.css";
import "moment/locale/de";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Cookies from "js-cookie";
import { Cookie } from "./Cookie";
import { Main } from "./Main.jsx";
import { initFetchService } from "./fetch/fetchService";
import { LoginBase } from "./Login/LoginBase";
import { Footer } from "./Footer";
import { ResetPassword } from "./ResetPassword";

import {
  Portal,
  PortalDefault,
  portals as possiblePortals,
  adminPortals,
} from "./Portal";
import { AdminMain } from "./AdminMain";
import { Redirect } from "react-router";
import { CreateNewPassword } from "./CreateNewPassword";

const { Consumer, Provider } = React.createContext("user");
export const UserConsumer = Consumer;

export const Functionality = {
  F5_DriversLicenseCheck: "F5",
};

export class Base extends React.Component {
  fetchService;

  constructor(props) {
    super(props);

    let userLocation = null;
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(position => {
        const { latitude: lat, longitude: lng } = position.coords;
        userLocation = { lat, lng };
      });
    }
    this.state = {
      jwt: null,
      user: null,
      devices: [],
      userLocation,
      loggedIn: false,
    };

    this.refreshJWT = this.refreshJWT.bind(this);
    this.fetchService = initFetchService(this.refreshJWT, this.logOut);
    this.checkLoginState();
  }

  checkLoginState = () => {
    const jwtSigned =
      Cookies.get(Cookie.jwtSigned) &&
      parseInt(Cookies.get(Cookie.jwtSigned), 10);

    if (!jwtSigned) {
      setTimeout(this.checkLoginState, 5000);
      return;
    }

    // Check if the user has a cookie that is no longer valid and if so log out
    if (
      !(
        Cookies.get(Cookie.jwt) &&
        Cookies.get(Cookie.jwt) !== "undefined" &&
        parseInt(jwtSigned, 10) +
          parseInt(process.env.REACT_APP_JWT_EXPIRATION_S, 10) >
          Date.now() / 1000
      )
    ) {
      this.logOut();
    }
    setTimeout(this.checkLoginState, 5000);
  };

  refreshJWT = (resetUser = false) => {
    this.fetchService &&
      this.fetchService.refresh().then(res => {
        Cookies.set(Cookie.jwt, res.token, { secure: true });
        Cookies.set(Cookie.jwtSigned, `${Date.now() / 1000}`, { secure: true });
        if (resetUser) {
          this.setLoginData(res.token, res.user);
        }
      });
  };

  setUserProfileData = user => {
    this.setState({
      user: {
        ...this.state.user,
        ...user,
      },
    });
  };

  setLoginData = (jwt, user) => {
    /// permissions and portals are set during login, but not when the user
    /// data is saved, thus we set it only if it is given
    this.setState(
      {
        jwt,
        user: {
          ...user,
          ...this.normalizePortals(user.categories),
          permissions: [...user.permissions],
        },
      },
      () => {
        this.fetchService.localeQuery().then(({ language }) => {
          this.props.setLanguage(language);
        });
      },
    );
  };

  normalizePortals = categories => {
    // TODO: disable this to disable category workaround
    // this leads to the duplicated All portal in the navigation
    //categories = possiblePortals;

    const availablePortals = possiblePortals.filter(portal =>
      categories.includes(portal),
    );

    // if the user has no portal at all add default to show the map and his user
    // data
    if (availablePortals.length === 0) {
      availablePortals.push(PortalDefault);
    }

    if (possiblePortals.length === 0) {
      console.warn(
        "No portal or no known portal was given in functionality definition.",
      );
    }

    if (availablePortals.length > 1) {
      availablePortals.unshift(Portal.All);
    }

    // TODO: get available portals form backend
    const availableAdminPortals = adminPortals;
    return {
      availablePortals,
      availableAdminPortals,
    };
  };

  logOut = () => {
    if (this.state.jwt) {
      this.fetchService.logout().then(() => {
        Cookies.remove(Cookie.jwt);
        Cookies.remove(Cookie.jwtSigned);
        this.setState({ jwt: null });
      }).catch((e) => {
        // A 401 error can occur if the user was logged in two browsers but logged out in the other browser, invalidating the jwt token
        if(e.toString().indexOf("401") > -1) {
          Cookies.remove(Cookie.jwt);
          Cookies.remove(Cookie.jwtSigned);
          this.setState({ jwt: null });
        }
      });
    }
  };

  setLoggedIn = state => {
    this.setState({ loggedIn: state });
  };

  render() {
    return (
      <Provider value={this.state.user}>
        <Router>
          <div>
            {this.state.jwt == null && (
              <div>
                <Switch>
                  <Route
                    path="/reset-password"
                    render={({ match }) => (
                      <ResetPassword
                        resetPassword={this.fetchService.resetPassword}
                      />
                    )}
                  />
                  <Route
                    path="/password-reset-confirm/:userId/:token"
                    render={({ match }) => (
                      <CreateNewPassword
                        match={match}
                        createNewPassword={this.fetchService.createNewPassword}
                        validateResetToken={
                          this.fetchService.validateResetToken
                        }
                      />
                    )}
                  />
                  <Route
                    path="/:activate?/:hash?"
                    render={({ location, match }) => (
                      <LoginBase
                        activateUser={this.fetchService.activateUser}
                        location={location}
                        loginCall={this.fetchService.login}
                        match={match}
                        refreshCall={this.fetchService.refresh}
                        setLoginData={this.setLoginData}
                        setLoggedIn={this.setLoggedIn}
                      />
                    )}
                  />
                </Switch>
              </div>
            )}
            {this.state.jwt != null && this.state.user != null && (
              <div className="">
                <Switch>
                  <Route
                    path="/admin-panel/:portal"
                    children={({ match, location }) => (
                      <AdminMain
                        fetchService={this.fetchService}
                        jwt={this.state.jwt}
                        location={location}
                        logOut={this.logOut}
                        match={match}
                        setLoginData={this.setLoginData}
                        setUserProfileData={this.setUserProfileData}
                        user={this.state.user}
                        userLocation={this.state.userLocation}
                        loggedIn={this.state.loggedIn}
                        setLoggedIn={this.setLoggedIn}
                      />
                    )}
                  />
                  <Route
                    path="/:portal"
                    children={({ match, location }) => (
                      <Main
                        fetchService={this.fetchService}
                        jwt={this.state.jwt}
                        location={location}
                        logOut={this.logOut}
                        match={match}
                        setLoginData={this.setLoginData}
                        setUserProfileData={this.setUserProfileData}
                        user={this.state.user}
                        userLocation={this.state.userLocation}
                        loggedIn={this.state.loggedIn}
                        setLoggedIn={this.setLoggedIn}
                      />
                    )}
                  />
                  <Redirect from={"/"} to={"all/map"} />
                </Switch>
                <div className="small-spacer background" />
                <Footer
                  setLanguage={this.props.setLanguage}
                  localeQuery={this.fetchService.localeQuery}
                />
              </div>
            )}
          </div>
        </Router>
      </Provider>
    );
  }
}
