import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { TextInput } from "../../TextInput";
import { isEmpty } from "../../utility";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  user_firstname_label: {
    id: "adminPanel.users.edit.inputLabel.first_name",
    defaultMessage: "Vorname",
  },
  user_lastname_label: {
    id: "adminPanel.users.edit.inputLabel.last_name",
    defaultMessage: "Nachname",
  },
  user_email_label: {
    id: "adminPanel.users.edit.inputLabel.email",
    defaultMessage: "E-Mail (optional)",
  },
  user_employeeid_label: {
    id: "adminPanel.users.edit.inputLabel.employee_id",
    defaultMessage: "Employee ID*",
  },
  user_role_label: {
    id: "adminPanel.users.edit.inputLabel.role",
    defaultMessage: "Rolle",
  },
});

class UserEditOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      editUserFirstname: this.props.editUserRecord.user.first_name,
      editUserLastname: this.props.editUserRecord.user.last_name,
      editUserEmail: this.props.editUserRecord.user.email,
      editUserEmployeeid: this.props.editUserRecord.user.unique_employee_id,
      editUserRole: this.props.editUserRecord.user.role_id,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props.getRoleListAll().then(roleRecordList => {
      this.setState({
        loading: false,
        roleRecordList,
      });
    });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  submit() {
    if (this.validate()) {
      // format user record for API
      let updatedUserRecord = this.props.editUserRecord;
      updatedUserRecord.user.first_name = this.state.editUserFirstname;
      updatedUserRecord.user.last_name = this.state.editUserLastname;
      updatedUserRecord.user.email = this.state.editUserEmail;
      updatedUserRecord.user.unique_employee_id = this.state.editUserEmployeeid;
      // send user data to API
      this.props.editUser(updatedUserRecord).then(this.props.closeFunction);
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  validate() {
    return (
      !isEmpty(this.state.editUserFirstname) ||
      !isEmpty(this.state.editUserLastname) ||
      !isEmpty(this.state.editUserEmployeeid)
    );
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className="header">
          <h3>
            <T
              id="adminPanel.users.edit.headline"
              defaultMessage="Benutzer bearbeiten"
            />{" "}
            ->{" "}
            <span className="highlighted">
              {this.state.editUserFirstname} {this.state.editUserLastname}
            </span>
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="small-spacer" />
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="cell large-6">
              <TextInput
                id="editUserFirstname"
                name={t(terms.user_firstname_label)}
                className={
                  this.state.showErrorMessage &&
                  isEmpty(this.state.editUserFirstname)
                    ? "error"
                    : ""
                }
                onChange={this.handleChange}
                value={this.state.editUserFirstname}
              />
            </div>
            <div className="cell large-6">
              <TextInput
                id="editUserLastname"
                name={t(terms.user_lastname_label)}
                className={
                  this.state.showErrorMessage &&
                  isEmpty(this.state.editUserLastname)
                    ? "error"
                    : ""
                }
                onChange={this.handleChange}
                value={this.state.editUserLastname}
              />
            </div>
            <div className="cell large-6">
              <TextInput
                id="editUserEmployeeid"
                name={t(terms.user_employeeid_label)}
                className={
                  this.state.showErrorMessage &&
                  isEmpty(this.state.editUserEmployeeid)
                    ? "error"
                    : ""
                }
                onChange={this.handleChange}
                value={this.state.editUserEmployeeid}
              />
            </div>
            <div className="cell large-6">
              <TextInput
                id="editUserEmail"
                name={t(terms.user_email_label)}
                onChange={this.handleChange}
                value={this.state.editUserEmail}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="small-spacer" />
        {this.state.showErrorMessage && (
          <div className="error">
            <T
              id="adminPanel.users.edit.errorMessage.requiredFieldEmpty"
              defaultMessage="Bitte füllen Sie alle Pflichtfelder aus."
            />
          </div>
        )}
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.users.edit.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button green primary" onClick={this.submit}>
            <T
              id="adminPanel.users.edit.buttonLabel.submit"
              defaultMessage="Speichern"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const UserEditOverlay = injectIntl(UserEditOverlayClass);
