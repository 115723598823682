import fetchWrap from "fetch-wrap";

import middleware from "fetch-wrap/middleware";
import Cookies from "js-cookie";
import { Cookie } from "../Cookie";

/**
 * for more details
 * @see https://github.com/benjamine/fetch-wrap.git
 */

export const apiBaseUrl = window.apiUrl + "/api";

/**
 * Checks if the given url is one of the login relevant urls
 * refresh and login are login relevant
 */
function urlIsLogin(url) {
  return (
    url.indexOf("/api/user/login") > -1 ||
    url.indexOf("/api/user/refresh") > -1 ||
    url.indexOf("/api/password-reset-confirm") > -1 ||
    url.indexOf("/api/user/resetpassword") > -1
  );
}

function urlIsLogout(url) {
  return url.indexOf("/api/user/logout") > -1
}

/// checks if the jwt has to be refreshed and if so does it
const refreshJWT = refreshJWTCallback => (url, options, innerFetch) => {
  const jwtSigned = parseInt(Cookies.get(Cookie.jwtSigned), 10);
  const refreshTime = parseInt(process.env.REACT_APP_JWT_REFRESH_S, 10);
  // refresh is neither desireable on login or refresh calls (!) nor on
  // automated calls since they do not necessarily indicate user activity
  if (
    !urlIsLogin(url) &&
    !options.automated &&
    jwtSigned + refreshTime < Date.now() / 1000
  ) {
    refreshJWTCallback();
  }
  return innerFetch(url, options);
};

/// add Auth token to all calls except login and refresh
const addJWT = (url, options, innerFetch) => {
  if (urlIsLogin(url)) {
    return innerFetch(url, options);
  }
  const token = Cookies.get(Cookie.jwt) || "";
  return innerFetch(
    url,
    fetchWrap.merge({}, options, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    }),
  );
};

const addBaseUrl = (url, options, innerFetch) =>
  innerFetch(`${apiBaseUrl}${url}`, options);

const errorMessaging = logOut => (url, options, innerFetch) => {
  return innerFetch(url, options).catch(err => {
    /// add default error messaging right here
    console.warn("Error caught on middleware errorMessaging");
    console.warn(err);
    console.warn(err.status);
    if (err.status === 401) {
      console.warn("log out due to ", err.status);
      // Do not call logout if logout failed because it leads to a recursive call
      if(!urlIsLogout(url)) {
        logOut();
      }
    }
    throw err;
  });
};

export const initMiddleware = (refreshJWTCallback, logOut) => {
  return {
    /// expects json as response type
      jsonFetch: fetchWrap(fetch, [
      errorMessaging(logOut),
      addBaseUrl,
      refreshJWT(refreshJWTCallback),
      middleware.receiveJSON(),
      addJWT,
    ]),
    /// does not expect a certain response type
    freeFetch: fetchWrap(fetch, [
      errorMessaging(logOut),
      addBaseUrl,
      refreshJWT(refreshJWTCallback),
      addJWT,
    ]),
  };
};
