
import * as React from "react";

export const handleInputChange = (self) => (
  event
) => {
  const target = event.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;
  return self.setState({ [name]: value });
};
