import { postJson } from "./fetchService";
import {apiBaseUrl} from "./middleware";

const serverbaseUrl = apiBaseUrl;

/// Collection of all fetch functions regarding the non-company-user management
export default function fetchNonCompanyUsers(wrappedFetch) {
  const getNonCompanyUserList = () => wrappedFetch("/user/listusers");
  const signUpNonCompanyUser = email =>
    wrappedFetch("/user/signup", postJson({ email }));
  const assignUserToDevice = (imei, user_id) =>
    wrappedFetch("/user/assigndevice", postJson({ imei, user_id }));
  const activateUser = hash =>
    fetch(`${serverbaseUrl}/activate/${hash}/`).then(res => {
      if (!res.ok) {
        throw new Error("http error " + res.status + ": " + res.statusText);
      }
      return res.json();
    });

  return {
    activateUser,
    assignUserToDevice,
    getNonCompanyUserList,
    signUpNonCompanyUser,
  };
}
