import React from "react";
import { TextInput } from "../TextInput";
import { RequestOverlay } from "../Request/RequestOverlay";
import { LoadingCircle } from "../LoadingCircle";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  enterFleetId: {
    id: "tracar.user.enterFleetId",
    defaultMessage: "fleet iD",
  },
});

class DriversLicenseRegistrationOverlayClass extends React.Component {
  changePassword;
  handleChange;

  constructor(props) {
    super(props);
    this.state = {
      dlc_barcodes: [],
      fleetId: "",
      loadingState: "loading",
      validationError: "",
    };
  }

  componentDidMount() {
    this.props
      .driversLicenseCheck()
      .then(({ dlc_barcodes }) =>
        this.setState({ dlc_barcodes, loadingState: "ready" }),
      );
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  };

  validate() {
    const valid = this.state.dlc_barcodes.find(
      barcode => barcode === this.state.fleetId,
    );
    const validationError = valid
      ? ""
      : "Die eingegebene fleet iD ist im System nicht verfügbar. Bitte " +
        "prüfen Sie Ihre Eingabe oder wenden Sie Sich an Ihren " +
        "Fuhrparkleiter.";
    this.setState({ validationError });
    return valid;
  }

  enterFleetId = () => {
    if (!this.validate()) {
      return;
    }
    this.setState({ loadingState: "saving" });
    this.props
      .driversLicenseCheck(this.props.userLoginData.email, this.state.fleetId)
      .then(() => this.props.closeFunction())
      .catch(err => {
        if (err.status === 401 || err.status === 403) {
          this.setState({
            loadingState: "ready",
            validationError:
              "Die eingegebene fleet iD ist im System nicht verfügbar. Bitte " +
              "prüfen Sie Ihre Eingabe oder wenden Sie Sich an Ihren " +
              "Fuhrparkleiter.",
          });
        }
      });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="change-password"
      >
        {this.state.loadingState === "loading" ||
        this.state.loadingState === "saving" ? (
          <LoadingCircle />
        ) : (
          <div>
            <h5>
              <T
                id="tracar.user.pleaseEnterFleetId"
                defaultMessage="fleet iD eintragen"
              />
            </h5>
            <div>
              Bitte geben Sie die fleet iD von Ihrem Hologramm-Barcode ein.
            </div>
            <TextInput
              type="text"
              id="fleetId"
              name={t(terms.enterFleetId)}
              value={this.state.fleetId}
              onChange={this.handleChange}
            />
            {<div className="error">{this.state.validationError}</div>}
            <div className="spacer" />
            <div className="button save-button" onClick={this.enterFleetId}>
              <T
                id="tracar.user.saveChanges"
                defaultMessage="Änderungen speichern"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const DriversLicenseRegistrationOverlay = injectIntl(
  DriversLicenseRegistrationOverlayClass,
);
