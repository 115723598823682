import React from "react";
import { TextInput } from "../../TextInput";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { SelectInput } from "../../SelectInput";
import FileUploader from "../../FileUploader";
import { CostCategoryType } from "../../ApiContract";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from "../../Map/Map";
import moment from "moment";
import { defaultLocale } from "../../Language";

const terms = defineMessages({
  amountLabel: {
    id: "adminPanel.expense.edit.label.amount",
    defaultMessage: "Kosten",
  },
  descriptionLabel: {
    id: "adminPanel.expense.edit.label.description",
    defaultMessage: "Beschreibung",
  },
});
const InternalDateFormat = "YYYY-MM-DD";
/**
 * Displays the form to create new roles.
 */
class ExpenseEditAdminExpenseInlineClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      query: "",
      loading: false,
      expenseCategoryList: [],
      amount: this.props.expense.amount,
      tax: this.props.expense.tax,
      startDate: moment(this.props.expense.start_date).format(
        InternalDateFormat,
      ),
      endDate: moment(this.props.expense.end_date).format(InternalDateFormat),
      description: this.props.expense.description,
      image: this.props.expense.image,
      isAcknowledged: this.props.expense.is_acknowledged,
      category: this.props.expense.category_name,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    const t = this.props.intl.formatMessage;

    this.props
      .getExpenseCategoryList(CostCategoryType.Admin)
      .then(recordListData => {
        recordListData.map(
          record =>
            (record.translatedName = t({
              id: `expense.category.${record.category_name}`,
              defaultMessage: record.category_name,
            })),
        );

        this.setState({
          loading: false,
          expenseCategoryList: recordListData,
        });
      });
  }

  validate() {
    return /^\d{1,7}(\.\d{2})?$/.test(this.state.amount);
  }

  submit() {
    if (this.validate()) {
      this.props
        .editExpense(
          this.props.expense.id,
          this.state.amount,
          this.state.tax,
          this.state.category,
          this.state.description,
          this.state.startDate,
          this.state.endDate,
          this.state.isAcknowledged,
          this.state.image,
        )
        .then(response => {
          if (response && response.detail) {
            this.setState({ showErrorMessage: true });
          } else {
            this.props.closeFunction();
          }
        });
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  handleSelectedFile = file => {
    this.setState({
      uploading: false,
      image: file,
    });
  };

  handleChangeStartDate = date => {
    this.setState({ startDate: date.format(InternalDateFormat) });
  };

  handleChangeEndDate = date => {
    this.setState({ endDate: date.format(InternalDateFormat) });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div>
        {this.props.showInnerSaveButtons && (
          <h4 className="text-left">
            <T
              id="adminPanel.expense.edit.headline"
              defaultMessage="Kosten bearbeiten"
            />
          </h4>
        )}

        <div className="expense-add-costs">
          <div className="type-of-costs">
            <label>
              <T
                id="adminPanel.expense.edit.label.category"
                defaultMessage="Kostenart"
              />
            </label>
            <T 
              id={`expense.category.${this.state.category}`}
              defaultMessage={this.state.category}/>
          </div>
          <div>
            <label>
              <T
                id="adminPanel.expense.create.label.startDate"
                defaultMessage="Startdatum"
              />
            </label>
            <DatePicker
              customInput={<CustomDatePickerInput />}
              locale={moment().locale() || defaultLocale}
              onChange={date => this.handleChangeStartDate(date)}
              popperPlacement="top-end"
              selected={moment(this.state.startDate)}
              selectsStart
              showMonthDropdown
              startDate={moment(this.state.startDate)}
              withPortal
            />
          </div>

          <div>
            <label>
              <T
                id="adminPanel.expense.create.label.endDate"
                defaultMessage="Enddatum"
              />
            </label>
            <DatePicker
              customInput={<CustomDatePickerInput />}
              locale={moment().locale() || defaultLocale}
              onChange={date => this.handleChangeEndDate(date)}
              popperPlacement="top-end"
              selected={moment(this.state.endDate)}
              selectsStart
              showMonthDropdown
              startDate={moment(this.state.endDate)}
              withPortal
            />
          </div>
          <div>
            <TextInput
              id="description"
              name={t(terms.descriptionLabel)}
              className={""}
              onChange={this.handleChange}
              value={this.state.description}
            />
          </div>
          <div className="text-right">
            <TextInput
              id="amount"
              name={t(terms.amountLabel)}
              className={"text-right"}
              onChange={this.handleChange}
              value={this.state.amount}
            />
          </div>
        </div>

        <div className="small-spacer" />
        {this.state.showErrorMessage && (
          <div className="expense-error-message">
            <T
              id="adminPanel.expense.edit.error.text.amount"
              defaultMessage="Bitte geben Sie einen gültigen Betrag ein."
            />
          </div>
        )}
        <div className="small-spacer" />
        {this.props.showInnerSaveButtons === true && (
          <div className="text-right">
            <div
              className="button transparent"
              onClick={this.props.closeFunction}
            >
              <T
                id="adminPanel.roles.create.buttonLabel.cancel"
                defaultMessage="Abbrechen"
              />
            </div>
            <div className="button green" onClick={this.submit}>
              <T
                id="adminPanel.roles.create.buttonLabel.submit"
                defaultMessage="Speichern"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ExpenseEditAdminExpenseInline = injectIntl(
  ExpenseEditAdminExpenseInlineClass,
);
