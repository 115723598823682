import React from "react";
import { LoadingCircle } from "../../LoadingCircle";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { QueryLine } from "../../tools/QueryLine";
import { hasPortalAccess } from "../../Permission";
import { UserConsumer } from "../../Base";
import { Portal } from "../../Portal";
import { ExpenseList } from "./ExpenseList";
import { ExpenseDetailOverlay } from "./ExpenseDetailOverlay";

const listLimit = 10;

class AdminPanelExpenseBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      recordList: [],
      showExpenseDetailOverlay: false,
      query: "",
      // total number of devices across all pagination pages
      numberOfDevices: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // used by pagination to fetch the devices displayed on the current pagination page
      listOffset: 0,
      // search term to filter role list
      deviceListSearchKey: "",
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load(paginationInfo = {}) {
    // TODO: fetch paginated records @see CustomerAddresses
    this.props
      .getExpenseList(
        this.state.listOffset,
        listLimit,
        this.state.deviceListSearchKey,
      )
      .then(recordListData =>
        this.setState({
          loading: false,
          recordList: recordListData.receipts,
          numberOfDevices: recordListData.total_amount,
          numberOfPages: Math.ceil(recordListData.total_amount / listLimit),
        }),
      );
  }

  setSearchFilter = deviceListSearchKey => {
    this.setState({ deviceListSearchKey }, this.load);
  };

  updateCurrentPage = number => {
    this.setState({ currentPage: number });
  };

  getDeviceTypeList = async () => {
    return ["car", "utility", "asset", "person"];
  };

  setDeviceFilter = event => {
    this.setState({ deviceListFilterType: event.target.value }, this.load);
  };

  // called in expenseList. ExpenseDetailOverlay has its own version to reload the correct list.
  acknowledgeExpense = expenseId => {
    this.props.acknowledgeExpense(expenseId).then(() => this.load());
  };

  // called in expenseList. ExpenseDetailOverlay has its own version to reload the correct list.
  deleteExpense = expenseId => {
    this.props.deleteExpense(expenseId).then(() => this.load());
  };

  render() {
    return (
      <UserConsumer>
        {user =>
          hasPortalAccess(user, Portal.AdminPanelDevice) && (
            <div className="outer-table-frame">
              <div className="grid-x grid-margin-x align-middle search-area">
                <div className="auto cell">
                  <QueryLine
                    changeCallback={this.setSearchFilter}
                    changeDelay={500}
                  />
                </div>
              </div>
              {this.renderDeviceList()}
            </div>
          )
        }
      </UserConsumer>
    );
  }

  /**
   * Returns the device-list-table
   * @return JSX
   */
  renderDeviceList = () => {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }

    return (
      <div>
        <ExpenseList
          recordList={this.state.recordList}
          toggleExpenseDetailOverlay={this.toggleExpenseDetailOverlay}
          numberOfDevices={this.state.numberOfDevices}
          updateListOffset={this.updateListOffset}
          updateListSearchKey={this.updateListSearchKey}
          updatePagination={this.updatePagination}
          currentPage={this.state.currentPage}
          numberOfPages={this.state.numberOfPages}
          getExpenseImage={this.props.getExpenseImage}
          deleteExpense={this.deleteExpense}
          acknowledgeExpense={this.acknowledgeExpense}
        />
        {this.state.showExpenseDetailOverlay && (
          <ExpenseDetailOverlay
            closeFunction={this.toggleExpenseDetailOverlay}
            getExpenseListForDevice={this.props.getExpenseListForDevice}
            editAdminExpense={this.props.editAdminExpense}
            createExpense={this.props.createExpense}
            getExpenseCategoryList={this.props.getExpenseCategoryList}
            device={this.state.selectedDevice}
            expense={this.state.selectedExpense}
            getExpenseImage={this.props.getExpenseImage}
            acknowledgeExpense={this.props.acknowledgeExpense}
            deleteExpense={this.props.deleteExpense}
          />
        )}
      </div>
    );
  };

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };

  toggleExpenseDetailOverlay = (device, expense = null) => {
    this.setState(
      {
        showExpenseDetailOverlay: !this.state.showExpenseDetailOverlay,
        selectedDevice: device,
        selectedExpense: expense,
      },
      () => this.load(),
    );
  };

  /**
   * Updates the pagination offset to facilitate page switching
   * @param offset
   */
  updateListOffset = offset => {
    this.setState({ listOffset: offset }, this.load);
  };

  /**
   * Updates the search term the list is filtered by
   * @param searchKey
   */
  updateListSearchKey = searchKey => {
    this.setState({ deviceListSearchKey: searchKey }, this.load);
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * listLimit);
  };
}

export const AdminPanelExpenseBase = injectIntl(AdminPanelExpenseBaseClass);
