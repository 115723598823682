/// Box Of Tools

/// exchanges the element at the given position with the given element
/// thereby removing the element stored at the given index
/// returns a new array

import moment from "moment";

export function exchangeArrayElement(arr, element, index) {
  if (index > arr.length) {
    throw Error("Element exchange requested at invalid position");
  }
  return [...arr.slice(0, index), element, ...arr.slice(index + 1)];
}

/// removes the element at the given position
/// returns a new array
/// a[] -> int -> a[]
export function removeArrayElement(arr, index) {
  if (index > arr.length) {
    throw Error("Element exchange requested at invalid position");
  }
  if (index === -1) {
    return arr;
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

/// a[] -> (a -> bool) -> a[]
export function removeSingleElementByCondition<T>(arr, fn) {
  const element = arr.find(fn);
  if (typeof element === "undefined") {
    return [arr, false];
  }
  const index = arr.indexOf(element);
  if (index === -1) {
    return [arr, false];
  }
  return [removeArrayElement(arr, index), true];
}

export function exchangeLetters(string, replacement, index) {
  return (
    string.substr(0, index) +
    replacement +
    string.substr(index + replacement.length)
  );
}

export function getWeekFromDate(date) {
  return {
    from: moment(date.startOf("isoWeek")),
    to: moment(date.endOf("isoWeek")),
  };
}

export function validateEmail(email) {
  if (email == null) {
    return false;
  }
  const emailRegexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailRegexp.test(email);
}

export function formatMomentPeriodToStringPeriod({ from, to }) {
  return { from: stringFromMoment(from), to: stringFromMoment(to) };
}

export function stringFromMoment(t) {
  return t
    .clone()
    .utc()
    .format("YYYY-MM-DD\\THH:mm:ss");
}

export function getAWholeDaysPeriod(date) {
  const from = date.clone().startOf("day");
  const to = date.clone().endOf("day");
  return formatMomentPeriodToStringPeriod({ from, to });
}

export function isANumber(num) {
  return typeof num === "number" && !isNaN(num) && num + 1 !== num;
}

export function isEmpty(str) {
  if (str == null) {
    return true;
  }

  return str.trim() == "";
}
