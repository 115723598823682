import React from "react";
import Link from "react-router-dom/es/Link";
import { withRouter } from "react-router-dom";
import { getWeekdayList } from "./weekdays";
import { deviceSafetyZoneIsWholeDay } from "./deviceSafetyZoneIsWholeDay";
import { FormattedMessage as T, injectIntl } from "react-intl";

class DeviceSafetyZoneTableLineClass extends React.Component {
  deleteLine;
  cancelDelete;
  weekdayList;

  constructor(props) {
    super(props);

    this.weekdayList = getWeekdayList(props.intl.formatMessage);
    this.state = { pendingDelete: false };
    this.deleteLine = this.deleteLine.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }

  deleteLine(e) {
    this.state.pendingDelete
      ? this.props.deleteDeviceSafetyZone(
          this.props.deviceSafetyZone.geofence_device_id,
        )
      : this.setState({ pendingDelete: true });
  }

  cancelDelete() {
    this.setState({ pendingDelete: false });
  }

  render() {
    const { deviceSafetyZone, match } = this.props;
    const portal = match.params.portal || "tracar";
    const imei = match.params.imei || "";

    const editButtons = (
      <div className="cell small-12 medium-1 large-1 table-line-right fixed s-absolute">
        <Link
          to={`/${portal}/device-safety-zone-detail/${imei}/${
            deviceSafetyZone.geofence_device_id
          }`}
          className="button-container"
        >
          <i className="fa fa-pencil button" />
        </Link>

        <div onClick={this.deleteLine} className="button-container">
          <i className="fa fa-trash button" />
        </div>
      </div>
    );

    const confirmDeleteButtons = (
      <div className="cell small-12 medium-1 large-1 table-line-right fixed s-absolute">
        <div className="button-container">
          <div
            onClick={this.cancelDelete}
            className="button confirm-delete-button border"
          >
            <T
              id="tracar.device-safety-zone-detail.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div
            onClick={this.deleteLine}
            className="button confirm-delete-button"
          >
            <T
              id="tracar.device-safety-zone-detail.delete"
              defaultMessage="Löschen"
            />
          </div>
        </div>
      </div>
    );

    return (
      <div className="table-line device-safety-zones grid-x">
        <div className="cell small-12 medium-1 large-1 table-line-left ">
          {deviceSafetyZone.geofence_name}
        </div>
        <div className="cell small-12 medium-auto large-auto table-line-middle flexible">
          {this.weekdayList.map((weekday, index) => (
            <div
              key={index}
              className={
                "weekday-display" +
                (deviceSafetyZone.day_frequency[index] === "1"
                  ? " active"
                  : " inactive")
              }
            >
              {weekday}
            </div>
          ))}
          &nbsp;&nbsp;&nbsp;
          {deviceSafetyZone.leave_alert ? (
            <div className="ib">
              <i className="fa fa-sign-out" /> &nbsp;{" "}
              <T
                id="tracar.device-safety-zone.alarmOut"
                defaultMessage="Alarm wegen Ausfahrt"
              />{" "}
              &nbsp;
            </div>
          ) : (
            <div className="ib">
              <i className="fa fa-sign-in" /> &nbsp;{" "}
              <T
                id="tracar.device-safety-zone.alarmIn"
                defaultMessage="Alarm wegen Einfahrt"
              />{" "}
              &nbsp;
            </div>
          )}
          {!deviceSafetyZoneIsWholeDay(deviceSafetyZone) && (
            <div>
              {" "}
              <T
                id="tracar.device-safety-zone.fromTo"
                defaultMessage="von {start} bis {end} Uhr"
                values={{
                  start: deviceSafetyZone.start_time.slice(0, 8),
                  end: deviceSafetyZone.end_time.slice(0, 8),
                }}
              />
            </div>
          )}
        </div>
        {this.state.pendingDelete ? confirmDeleteButtons : editButtons}
      </div>
    );
  }
}

export let DeviceSafetyZoneTableLine = withRouter(
  injectIntl(DeviceSafetyZoneTableLineClass),
);
