import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";

import { trackerColors } from "../TrackerDetail/trackerColors";
import { handleInputChange } from "../tools/handleInputChange";

export class EditTagOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag_color: (this.props.tag && this.props.tag.tag_color) || "#2D862D",
      tag_name: (this.props.tag && this.props.tag.tag_name) || "",
      validationError: "",
    };
  }

  handleInputChange = handleInputChange(this);

  saveTag = () => {
    if (this.state.tag_name.length === 0) {
      this.setState({ validationError: "Bitte geben Sie einen Tagnamen ein" });
      return;
    }
    this.props.setTagsAsSaving();
    this.props.closeFunction();
    this.props.fetchTags.setTag(this.state, this.props.editedTagId).then(_ => {
      this.props.loadTags();
    });
  };

  render() {
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <h3>Tag bearbeiten</h3>
        <div className="overlay-content">
          <div className="default-label">Farbe des Tags</div>
          <div className="tiny-spacer" />
          <div className="tag-color-button-area">
            {trackerColors.map(color => (
              <div
                key={color}
                className={
                  "color-button-old" +
                  (this.state.tag_color === color ? " active" : "")
                }
                style={{ backgroundColor: color, borderColor: color }}
                onClick={() => this.setState({ tag_color: color })}
              >
                {this.state.tag_color === color ? (
                  <i className="fa fa-check-circle" />
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
          <div className="small-spacer" />
          <div className="text-input-component">
            <input
              type="text"
              value={this.state.tag_name}
              name="tag_name"
              id="tag_name"
              onChange={this.handleInputChange}
              placeholder="Tag-Name"
            />
            <label htmlFor="email">Tag-Name</label>
          </div>
          <div className="error">{this.state.validationError}</div>
        </div>
        <div className="small-spacer" />
        <div className="flex-just-between">
          <div className="link" onClick={this.props.closeFunction}>
            abbrechen
          </div>
          <div className="button save-button" onClick={this.saveTag}>
            Tag speichern
          </div>
        </div>
      </RequestOverlay>
    );
  }
}
