import React from "react";
import { PoolVehicleTable } from "./PoolVehicleTable";
import { LoadingCircle } from "../LoadingCircle";
import { UserConsumer } from "../Base";
import { hasPermission, Permission } from "../Permission";
import { DeviceFilterStatus } from "../ApiContract";
import { QueryLine } from "../tools/QueryLine";
import { FormattedMessage as T } from "react-intl";
import { ExpenseOverlay } from "./ExpenseOverlay";

export const noTagsPlaceholder = {
  id: Number.MAX_SAFE_INTEGER - 1,
  name: "no_tags_placeholder",
};
export const noGeofencesPlaceholder = {
  id: Number.MAX_SAFE_INTEGER - 2,
  name: "no_geofences_placeholder",
};

const deviceListLimit = 10;

export class PoolVehicleBase extends React.Component {
  /// the promise number is used to enforce that only the latest request
  /// actually gets rendered
  promiseNumber;
  imei;
  static contextType = UserConsumer;
  constructor(props) {
    super(props);
    this.imei1 = props.match.params.imei || "1001";
    this.state = {
      isLoaded: false,
      devices: null,
      // vehicle the user is currently checked in as driver
      currentPoolVehicle: {},
      // total number of devices across all pagination pages
      numberOfDevices: 0,
      // number of the current pagination page
      currentPage: 0,
      // total number of pagination pages
      numberOfPages: 0,
      // used by pagination to fetch the devices displayed on the current pagination page
      deviceListOffset: 0,
      // search term to filter role list
      deviceListSearchKey: "",
      // filter devices by assignment type
      deviceListFilterStatus: DeviceFilterStatus.All,
      // toggle expense overlay
      showExpenseOverlay: false,
      selectedDevice: null,
      amountUnread: 0,
    };
  }
  componentDidMount() {
    this.loadDevices();
    this.load({ offset: 0, limit: 0 });
  }

  loadDevices = () => {
    this.props
      .getPoolVehicles(
        deviceListLimit,
        this.state.deviceListOffset,
        this.state.deviceListSearchKey,
        "",
      )
      .then(recordListData => {
        this.setState({
          devices: recordListData.poolvehicles,
          isLoaded: true,
          numberOfDevices: recordListData.pagination.total_amount,
          numberOfPages: Math.ceil(
            recordListData.pagination.total_amount / deviceListLimit,
          ),
        });
      });
    this.props.getCurrentPoolVehicle().then(currentPoolVehicle => {
      this.setState({ currentPoolVehicle });
    });
  };

  load(pagination) {
    console.log(this.state.devices);
    this.props.fetchService
      .messageListQuery(this.imei1, pagination)
      .then(messageList => {
        this.setState({ amountUnread: messageList.amount_unread });
      });
  }

  /**
   * Updates the search term the list is filtered by
   * @param searchKey
   */
  updateListSearchKey = searchKey => {
    this.setState({ deviceListSearchKey: searchKey }, this.load);
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
    this.updateListOffset(pageNumber * deviceListLimit);
  };

  setSearchFilter = deviceListSearchKey => {
    this.setState({ deviceListSearchKey }, this.load);
  };

  setDeviceFilter = event => {
    this.setState({ deviceListFilterStatus: event.target.value }, this.load);
  };

  checkInToDevice = (imei, is_private) => {
    this.props.checkInToDevice(imei, is_private).then(() => this.loadDevices());
  };

  checkOutOfDevice = imei => {
    this.props.checkOutOfDevice(imei).then(() => this.loadDevices());
  };

  toggleExpenseOverlay = device => {
    this.setState(
      {
        showExpenseOverlay: !this.state.showExpenseOverlay,
        selectedDevice: device,
      },
      () => this.loadDevices,
    );
  };

  render() {
    const { fetchService } = this.props;
    return (
      <UserConsumer>
        {user =>
          hasPermission(user, Permission.DeviceList) && (
            <div className="outer-table-frame tracker">
              {!this.state.isLoaded && (
                <div className="loading-circle-container">
                  <LoadingCircle />
                </div>
              )}
              <div className="grid-x grid-margin-x align-middle search-area">
                <div className="auto cell">
                  <QueryLine
                    changeCallback={this.setSearchFilter}
                    changeDelay={500}
                  />
                </div>
                <div className="medium-3 cell">
                  <select
                    className="margin-bottom-1"
                    onChange={this.setDeviceFilter}
                  >
                    <option value={DeviceFilterStatus.All}>
                      <T
                        id="portal.poolvehicles.list.deviceFilterStatus.optionLabel.all"
                        defaultMessage="Alle"
                      />
                    </option>
                    <option value={DeviceFilterStatus.Free}>
                      <T
                        id="portal.poolvehicles.list.deviceFilterStatus.optionLabel.free"
                        defaultMessage="Nicht zugewiesen"
                      />
                    </option>
                    <option value={DeviceFilterStatus.Occupied}>
                      <T
                        id="portal.poolvehicles.list.deviceFilterStatus.optionLabel.occupied"
                        defaultMessage="Zugewiesen"
                      />
                    </option>
                  </select>
                </div>
              </div>
              {this.state.devices && (
                <PoolVehicleTable
                  devices={this.state.devices}
                  user={this.props.user}
                  checkInToDevice={this.checkInToDevice}
                  currentPoolVehicle={this.state.currentPoolVehicle}
                  checkOutOfDevice={this.checkOutOfDevice}
                  numberOfDevices={this.state.numberOfDevices}
                  updateListOffset={this.updateListOffset}
                  updateListSearchKey={this.updateListSearchKey}
                  updatePagination={this.updatePagination}
                  currentPage={this.state.currentPage}
                  numberOfPages={this.state.numberOfPages}
                  toggleExpenseOverlay={this.toggleExpenseOverlay}
                  messageListQuery={fetchService.messageListQuery}
                />
              )}
              {this.state.showExpenseOverlay && (
                <ExpenseOverlay
                  closeFunction={this.toggleExpenseOverlay}
                  getExpenseListForDevice={
                    this.props.fetchListExpenses.getExpenseListForDevice
                  }
                  editExpense={this.props.fetchListExpenses.editExpense}
                  createExpense={this.props.fetchListExpenses.createExpense}
                  getExpenseCategoryList={
                    this.props.fetchListExpenses.getExpenseCategoryList
                  }
                  device={this.state.selectedDevice}
                  expense={this.state.selectedExpense}
                  getExpenseImage={this.props.fetchListExpenses.getExpenseImage}
                  deleteExpense={this.props.fetchListExpenses.deleteExpense}
                />
              )}
            </div>
          )
        }
      </UserConsumer>
    );
  }
}
