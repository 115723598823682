import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { TextInput } from "../TextInput";
import { trackerColors } from "../TrackerDetail/trackerColors";
import { Portal } from "../Portal";
import { Link } from "react-router-dom";
import { isDev } from "../tools/Environment";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  deviceName: {
    id: "tracar.device.deviceName",
    defaultMessage: "Geräte-Name",
  },
  comment: {
    id: "tracar.device.comment",
    defaultMessage: "Anmerkung",
  },
});

class GeneralTabClass extends React.Component {
  constructor(props) {
    super(props);

    let settingsToUpdate = [];
    Object.entries(this.props.generalSettings).forEach(setting => {
      Object.assign(settingsToUpdate, {
        [setting[0]]: this.props.isBulk ? false : true,
      });
    });

    this.state = {
      ...this.props.generalSettings,
      settingsToUpdate: settingsToUpdate,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.generalSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null)
        settings[setting[0]] = this.state[setting[0]];
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();

    let settingsToUpdate = {};
    Object.entries(this.state.settingsToUpdate).forEach(setting => {
      if (setting[1]) {
        Object.assign(settingsToUpdate, {
          [setting[0]]: this.state[setting[0]],
        });
      }
    });

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: settingsToUpdate,
    });

    this.props.saveDeviceSettings(settingsContainer);
  };

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  updateSettingsToUpdate = name => {
    this.state.settingsToUpdate[name] = !this.state.settingsToUpdate[name];
    this.setState({});
  };

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            <div className="grid-x grid-margin-x">
              <div className="medium-6 large-6 cell">
                <div
                  className={
                    this.props.isBulk
                      ? "input-container-half medium-6 large-6 cell"
                      : "medium-6 large-6 cell"
                  }
                >
                  {this.props.isBulk && (
                    <div
                      className="checkbox"
                      onClick={() => {
                        !this.props.readOnly &&
                          this.updateSettingsToUpdate("device_name");
                      }}
                    >
                      {this.state.settingsToUpdate.device_name ? (
                        <i className="icon-check" />
                      ) : (
                        <i className="icon-check_empty" />
                      )}
                    </div>
                  )}
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="device_name"
                    name={t(terms.deviceName)}
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.device_name}
                    disabled={
                      this.props.readOnly ||
                      !this.state.settingsToUpdate.device_name
                    }
                  />
                </div>

                <div
                  className={
                    this.props.isBulk
                      ? "input-container-half medium-6 large-6 cell"
                      : "medium-6 large-6 cell"
                  }
                >
                  {this.props.isBulk && (
                    <div
                      className="checkbox"
                      onClick={() => {
                        !this.props.readOnly &&
                          this.updateSettingsToUpdate("device_description");
                      }}
                    >
                      {this.state.settingsToUpdate.device_description ? (
                        <i className="icon-check" />
                      ) : (
                        <i className="icon-check_empty" />
                      )}
                    </div>
                  )}
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="device_description"
                    name={t(terms.comment)}
                    onChange={this.handleChange}
                    type="text"
                    value={this.state.device_description}
                    disabled={
                      this.props.readOnly ||
                      !this.state.settingsToUpdate.device_description
                    }
                  />
                </div>
              </div>
              <div className="medium-6 large-6 cell">
                <div
                  className={
                    this.props.isBulk
                      ? "input-container-full medium-6 large-6 cell"
                      : "color-buttons-container"
                  }
                >
                  {this.props.isBulk && (
                    <div
                      className="checkbox"
                      onClick={() => {
                        this.updateSettingsToUpdate("pin_color_for_map");
                      }}
                    >
                      {this.state.settingsToUpdate.pin_color_for_map ? (
                        <i className="icon-check" />
                      ) : (
                        <i className="icon-check_empty" />
                      )}
                    </div>
                  )}
                  <div className="color-buttons-container">
                    <div className="label">
                      <T
                        id="tracar.device.pin"
                        defaultMessage="Farbe des Pins auf der Karte:"
                      />
                    </div>
                    <div
                      className="color-buttons"
                      disabled={
                        this.props.readOnly ||
                        !this.state.settingsToUpdate.pin_color_for_map
                      }
                    >
                      {trackerColors.map(color => (
                        <div
                          key={color}
                          className={
                            "color-button-container" +
                            (this.state.pin_color_for_map === color
                              ? " active"
                              : "")
                          }
                        >
                          <div
                            className={"color-button"}
                            style={{ backgroundColor: color }}
                            onClick={() =>
                              !this.props.readOnly &&
                              this.handleChange({
                                target: {
                                  type: "button",
                                  id: "pin_color_for_map",
                                  value: color,
                                },
                              })
                            }
                          >
                            {this.state.pin_color_for_map === color && (
                              <i className="fa fa-check-circle" />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderHeadline = () => {
    if (this.props.portal === Portal.Person) {
      return (
        <T id="tracar.device.headlinePerson" defaultMessage="iNanny Daten" />
      );
    }

    return <T id="tracar.device.headline" defaultMessage="Trackerdaten" />;
  };

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/${
              this.props.portal === "admin-panel"
                ? "device/list"
                : "device-list"
            }`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          {!this.props.readOnly && (
            <div
              className={`button save-button ${this.props.settingsSaving ===
                "true" && "saving"} ${this.props.settingsSaving === "done" &&
                "saved"}`}
              onClick={() =>
                this.props.settingsSaving === "false" &&
                this.saveDeviceSettings()
              }
            >
              {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
              <span>
                {this.props.settingsSaving === "true" && (
                  <T
                    id="tracar.settings.general.saving"
                    defaultMessage="Einstellungen werden aktualisiert..."
                  />
                )}
                {this.props.settingsSaving === "false" && (
                  <T
                    id="tracar.settings.general.save"
                    defaultMessage="Einstellungen aktualisieren"
                  />
                )}
                {this.props.settingsSaving === "done" && (
                  <T
                    id="tracar.settings.general.saved"
                    defaultMessage="Einstellungen aktualisiert"
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const GeneralTab = injectIntl(GeneralTabClass);
