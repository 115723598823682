import React from "react";
import { FormattedMessage as T } from "react-intl";

export class SmartInventoryTaskTemplatesHeadline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="table-header long-headline">
                <div className="">
                    <i className="fa fa-list" />
                    <T
                        id="adminPanel.smartInventory.taskTemplates.list.headline"
                        defaultMessage="Inventory Monitoring Aufgabenlisten"
                    />
                    <small className="font-weight-normal" style={{marginLeft: '10px'}}>
                        <T
                            id="adminPanel.smartInventory.taskTemplates.list.headline.amount"
                            defaultMessage="{total_amount} Listen"
                            values={{ total_amount: this.props.numberTasks }}
                        />
                    </small>
                </div>
            </div>
        );
    }
}
