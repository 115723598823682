import * as React from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { isDev } from "../tools/Environment";

export const TrackerTableHead = withRouter(
  injectIntl(props => {
    const {
      headline,
      iconClasses,
      children,
      isSelectedAllDevices,
      selectAllDevices,
    } = props;
    const className = "";

    return (
      <div className={"table-header" + className}>
        {props.match.path === "/:portal/device-list" && (
          <div
            className="checkbox"
            onClick={() => {
              selectAllDevices(!isSelectedAllDevices);
            }}
          >
            {isSelectedAllDevices ? (
              <i className="icon-check" />
            ) : (
              <i className="icon-check_empty" />
            )}
          </div>
        )}
        <div className="header unstrong">
          <i className={iconClasses} />
          {headline}
          {children}
        </div>
      </div>
    );
  }),
);
