import React from "react";
import { AdminPanelPoolVehicleDashboard } from "./PoolVehicleDashboard";
import { hasPermission, Permission } from "../../Permission";
import { UserConsumer } from "../../Base";

export class AdminPanelPoolVehicleDashboardBase extends React.Component {
  render() {
    return (
      <UserConsumer>
        {user => {
          if (hasPermission(user, Permission.PoolVehicleAdmin)) {
            return (
              <AdminPanelPoolVehicleDashboard
                getPoolVehicleDashboard={this.props.getPoolVehicleDashboard}
              />
            );
          }
        }}
      </UserConsumer>
    );
  }
}
