import React from "react";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import { format } from "date-fns";
import Plot from "react-plotly.js";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  timeOfDay: {
    id: "tracar.general.timeOfDay",
    defaultMessage: "Uhrzeit",
  },
});

function OnlineHeatmapFn({
  config,
  usage_heatmap,
  widePlotLayoutCommons,
  intl: { formatMessage: t },
}) {
  const maximumOnlineDevices = Math.max(
    ...usage_heatmap.map(({ val }) => Math.max(...val)),
  );

  if (usage_heatmap.length === 0) {
    return null;
  }

  const z = usage_heatmap[0].val.map((_, hourIndex) => {
    return usage_heatmap.reduce((acc, day) => {
      acc.push(day.val[hourIndex]);
      return acc;
    }, []);
  });

  const x = usage_heatmap.map(({ date }) => format(Date.parse(date), "DD.MM."));

  const textLabel = z.map((row, i) =>
    row.map((item, j) => {
      return (
        ` ${x[j]} <br />` +
        ` Uhrzeit: ${i}:00 - ${i + 1}:00 Uhr <br />` +
        ` Geräte Online: ${item.toFixed(0)} `
      );
    }),
  );

  return (
    <div className="wide-graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          <i className="fa fa-feed rotate--45" />
          <T
            id="tracar.heatmap.online7d"
            defaultMessage="Geräte online, letzte sieben Tage"
          />
        </h5>
      </div>
      <div className="tiny-spacer" />
      {maximumOnlineDevices > 0 && (
        <Plot
          // https://codepen.io/etpinard/pen/WxLGoo
          config={config}
          data={[
            {
              x,
              z,
              y: [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
              ],
              text: textLabel,
              hoverinfo: "text",
              type: "heatmap",
              colorscale: [
                ["0.0", Color.White],
                ["0.4", Color.SilverChalice1],
                ["1.0", Color.Red],
              ],
              colorbar: {
                tickformat: ",.0f,",
                tickvals: [0, maximumOnlineDevices],
              },
            },
          ]}
          layout={{
            ...widePlotLayoutCommons,
            yaxis: {
              title: t(terms.timeOfDay),
            },
            margin: { ...widePlotLayoutCommons.margin, l: 70 },
          }}
        />
      )}
    </div>
  );
}

export const OnlineHeatmap = injectIntl(OnlineHeatmapFn);
