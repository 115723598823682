import React from "react";
import { withRouter } from "react-router-dom";
import Link from "react-router-dom/es/Link";
import ReactTooltip from "react-tooltip";

// noinspection JSUnresolvedVariable
import {
  defineMessages,
  FormattedMessage,
  FormattedMessage as T,
  injectIntl,
} from "react-intl";
import { DropdownItems, DropdownMenu } from "../tools/DropdownMenu";
import { PoolVehicleAllowedRouteType } from "../ApiContract";

const terms = defineMessages({
  privateCar: {
    id: "tracar.device-list.privateCar",
    defaultMessage: "privat genutztes Firmenfahrzeug",
  },
  companyCar: {
    id: "tracar.device-list.companyCar",
    defaultMessage: "Firmenfahrzeug",
  },
  tags: {
    id: "tracar.device-list.tags",
    defaultMessage: "Tags",
  },
  statistics: {
    id: "tracar.device-list.statistics",
    defaultMessage: "Statistiken",
  },
  mapView: {
    id: "tracar.device-list.mapView",
    defaultMessage: "Kartenansicht",
  },
  fahrtenbuch: {
    id: "tracar.device-list.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  routeList: {
    id: "tracar.device-list.routeList",
    defaultMessage: "Routenliste",
  },
  geofences: {
    id: "tracar.device-list.geofences",
    defaultMessage: "Sicherheitszonen",
  },
  settings: {
    id: "tracar.device-list.settings",
    defaultMessage: "Einstellungen",
  },
  contacts: {
    id: "tracar.device-list.contacts",
    defaultMessage: "Kontakte",
  },
  messages: {
    id: "tracar.device-list.messages",
    defaultMessage: "Nachrichten",
  },
  fence_down: {
    id: "tracar.device-list.fenceDown",
    defaultMessage: "Bauzaun ist umgestürzt!",
  },
  batteryStatus: {
    id: "tracar.device-list.batteryStatus",
    defaultMessage: "Batteriestatus",
  },
  ok: {
    id: "ok",
    defaultMessage: "O.K.",
  },
});

class PoolVehicleTableLineClass extends React.Component {
  imei;
  constructor(props) {
    super(props);
    this.imei = this.props.device.imei || "1001";
    this.state = {
      amountUnread: 0,
    };
  }
  componentDidMount() {
    this.load({ offset: 0, limit: 0 });
  }

  load(pagination) {
    this.props.messageListQuery(this.imei, pagination).then(messageList => {
      this.setState({ amountUnread: messageList.amount_unread });
    });
  }
  render() {
    const { amountUnread } = this.state;
    const t = this.props.intl.formatMessage;
    const { description, imei, name, unread_messages } = this.props.device;
    const portal = this.props.match.params.portal || "tracar";
    const portalVehicle = this.props.match.params.portal || "poolvehicle";
    return (
      <div className="table-line grid-x grid-margin-x pool-vehicle">
        <div className="cell small-12 medium-6 large-2 first-cell">
          <div className="cell small-12 medium-6 large-3 first-cell">
            <div className="name"> {this.props.device.name}</div>
          </div>
          <div className="cell small-12 medium-12 large-3">
            <div className="grey-text">IMEI: {this.props.device.imei}</div>
          </div>
        </div>
        <div className="cell small-12 medium-6 large-2">
          {this.props.device.allowed_route_type ==
            PoolVehicleAllowedRouteType.Private && (
            <FormattedMessage
              id={`adminPanel.poolVehicleList.label.routeType.private`}
              defaultMessage={`Privat`}
            >
              {message => `${message}`}
            </FormattedMessage>
          )}
          {this.props.device.allowed_route_type ==
            PoolVehicleAllowedRouteType.Public && (
            <FormattedMessage
              id={`adminPanel.poolVehicleList.label.routeType.public`}
              defaultMessage={`Geschäftlich`}
            >
              {message => `${message}`}
            </FormattedMessage>
          )}
        </div>
        <div className="cell small-12 medium-6 large-2">
          <FormattedMessage
            id={`portal.poolVehicle.list.label.status.${this.props.device.status}`}
            defaultMessage={`${this.props.device.status}`}
          >
            {message => `${message}`}
          </FormattedMessage>
        </div>
        <div className="cell small-12 medium-12 large-4">
          {this.props.device.last_public_position
            ? this.props.device.last_public_position
            : "-"}
        </div>
        {/* <div className="cell small-12 medium-1 large-1  "></div> */}
        <div className="cell small-12 medium-12 large-2 table-line-right">
          <div className="button-container">
            <Link
              to={"/" + portalVehicle + "/messages/" + imei}
              className="button"
              data-for={`tracker-table-line-${imei}`}
              data-tip={t(terms.messages)}
            >
              <i className="fa fa-envelope" />
              {amountUnread > 0 && amountUnread < 100 && (
                <div className="indicator">{amountUnread}</div>
              )}
              {amountUnread >= 100 && <div className="indicator">99+</div>}
            </Link>
          </div>
          <div className="button-container all-buttons-margin-b-0">
            <div
              className=""
              onClick={() => this.props.toggleExpenseOverlay(this.props.device)}
            >
              <i className="icon icon-eur padding-1" />
            </div>
            <DropdownMenu
              anchor={
                <div className="margin-0 disabled">
                  <i className="icon icon-steering_wheel" />
                </div>
              }
              disabled={this.props.disableCheckIn}
            >
              <DropdownItems>
                <li
                  onClick={() =>
                    this.props.checkInToDevice(this.props.device.imei, false)
                  }
                >
                  <T
                    id="portal.poolVehicles.list.label.routeType.public"
                    defaultMessage="Später Klassifizieren"
                  />
                </li>
                <li
                  className={
                    this.props.device.allowed_route_type ==
                    PoolVehicleAllowedRouteType.Public
                      ? "disabled"
                      : ""
                  }
                  onClick={() =>
                    this.props.device.allowed_route_type ==
                      PoolVehicleAllowedRouteType.Private &&
                    this.props.checkInToDevice(this.props.device.imei, true)
                  }
                >
                  <T
                    id="portal.poolVehicles.list.label.routeType.private"
                    defaultMessage="Alles Privatfahrten"
                  />
                </li>
              </DropdownItems>
            </DropdownMenu>
          </div>
        </div>
        <ReactTooltip
          delayShow={500}
          class="tooltip"
          border={true}
          id={`tracker-table-line-${imei}`}
        />
      </div>
    );
  }
}

export const PoolVehicleTableLine = withRouter(
  injectIntl(PoolVehicleTableLineClass),
);
