import React from "react";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { FormattedMessage as T, injectIntl } from "react-intl";

class UserDeleteOverlayClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
    };
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  submit() {
    this.props
      .deleteUser(this.props.deleteUserRecords)
      .then(this.props.closeFunction);
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay closeFunction={this.props.closeFunction}>
        <div className="header">
          <h3>
            <T
              id="adminPanel.users.delete.headline"
              defaultMessage="Nutzer löschen"
            />
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="small-spacer" />
        <div>
          {this.props.deleteUserRecords.length > 1 ? (
            <T
              id="adminPanel.users.delete.confirmationTextBulk"
              defaultMessage="Sind Sie sicher, dass Sie die folgende Nutzer löschen möchten?"
            />
          ) : (
            <T
              id="adminPanel.users.delete.confirmationTextSingle"
              defaultMessage="Sind Sie sicher, dass Sie den folgenden Nutzer löschen möchten?"
            />
          )}
        </div>
        <div className="small-spacer" />
        <div>
          {this.props.deleteUserRecords.map((record, index) => (
            <span key={index}>{record.user.email}</span>
          ))}
        </div>
        <div className="small-spacer" />
        <div className="footer text-right">
          <div
            className="button transparent"
            onClick={this.props.closeFunction}
          >
            <T
              id="adminPanel.users.delete.buttonLabel.cancel"
              defaultMessage="Abbrechen"
            />
          </div>
          <div className="button alert" onClick={this.submit}>
            <T
              id="adminPanel.users.delete.buttonLabel.submit"
              defaultMessage="Löschen"
            />
          </div>
        </div>
      </RequestOverlay>
    );
  }
}

export const UserDeleteOverlay = injectIntl(UserDeleteOverlayClass);
