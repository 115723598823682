import React from "react";
import { TextInput } from "../TextInput";


import { WireExportBase } from "./WireExportBase";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";


const terms = defineMessages({
  brownCable: {
    id: "tracar.wires.brownCable",
    defaultMessage: "Braunes Kabel; Input 1",
  },
  purpleCable: {
    id: "tracar.wires.purpleCable",
    defaultMessage: "Lila Kabel; Input 2",
  },
  greenCable: {
    id: "tracar.wires.greenCable",
    defaultMessage: "Grünes Kabel; Input 3",
  },
  whiteCable: {
    id: "tracar.wires.whiteCable",
    defaultMessage: "Weißes Kabel; Analog Input 1",
  },
});

class WiresClass extends React.Component {
  constructor(props) {
    super(props);
    const [first, second, third, fourth] = this.props.wires.map(
      ({ description }) => description
    );
    this.state = { first, second, third, fourth };
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  };

  save = () => {
    this.props.save([
      { description: this.state.first },
      { description: this.state.second },
      { description: this.state.third },
      { description: this.state.fourth },
    ]);
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div className="outer-table-frame">
        <div className="button-frame">
          <WireExportBase
            generateWiresCsv={this.props.generateWiresCsv}
            imei={this.props.imei}
          />
        </div>
        <div className="form">
          <h1>
            <T id="tracar.wires.headline" defaultMessage="Tracar-Kontakte" />
          </h1>
          <div className="grid-x grid-margin-x">
            <TextInput
              className="medium-6 large-6 cell"
              id="first"
              name={t(terms.brownCable)}
              value={this.state.first}
              onChange={this.handleChange}
            />
            <TextInput
              className="medium-6 large-6 cell"
              id="second"
              name={t(terms.purpleCable)}
              value={this.state.second}
              onChange={this.handleChange}
            />
            <TextInput
              className="medium-6 large-6 cell"
              id="third"
              name={t(terms.greenCable)}
              value={this.state.third}
              onChange={this.handleChange}
            />
            <TextInput
              className="medium-6 large-6 cell"
              id="fourth"
              name={t(terms.whiteCable)}
              value={this.state.fourth}
              onChange={this.handleChange}
            />
          </div>
          <div className="spacer" />
          <div className="button save-button" onClick={this.save}>
            <T id="tracar.wires.save" defaultMessage="Daten speichern" />
          </div>
        </div>
      </div>
    );
  }
}

export const Wires = injectIntl(WiresClass);
