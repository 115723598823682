import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";

import { EditTagOverlay } from "./EditTagOverlay";

import { LoadingCircle } from "../LoadingCircle";

export class EditTagListOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditTagOverlay: false,
      editedTagId: null,
      tagIdToBeDeleted: null,
    };
  }

  toggleEditTagOverlay = editedTagId => {
    this.setState({
      editedTagId,
      showEditTagOverlay: !this.state.showEditTagOverlay,
    });
  };

  deleteTag(tagIdToBeDeleted) {
    if (this.state.tagIdToBeDeleted !== tagIdToBeDeleted) {
      this.setState({ tagIdToBeDeleted });
    } else {
      this.props.setTagsAsSaving();
      this.props.fetchTags
        .deleteTag(tagIdToBeDeleted)
        .then(() => this.props.loadTags());
    }
  }

  render() {
    const content =
      this.props.tagListLoadingState !== "ready" ? (
        <LoadingCircle />
      ) : (
        <div>
          <ul>
            {this.props.tagList.tag_id_list.map(id => {
              const tagObject = this.props.tagList.tag_objects[id.toString()];
              return (
                <li key={id} className="edit-tag-list-element">
                  <div className="tag-name">
                    <i
                      className="fa fa-tag"
                      style={{ color: tagObject.tag_color }}
                    />
                    &nbsp;&nbsp;&nbsp;{tagObject.tag_name}
                  </div>
                  <div>
                    {id === this.state.tagIdToBeDeleted
                      ? [
                          <div
                            className="link"
                            onClick={() => this.deleteTag(null)}
                            key={1}
                          >
                            abbrechen
                          </div>,
                          <div
                            className="button"
                            onClick={() => this.deleteTag(id)}
                            key={2}
                          >
                            Tag löschen
                          </div>,
                        ]
                      : [
                          <div
                            className="button"
                            onClick={() => this.toggleEditTagOverlay(id)}
                            key={1}
                          >
                            <i className="fa fa-pencil" />
                          </div>,
                          <div
                            className="button"
                            onClick={() => this.deleteTag(id)}
                            key={2}
                          >
                            <i className="fa fa-trash" />
                          </div>,
                        ]}
                  </div>
                </li>
              );
            })}
          </ul>
          <div
            className="button button-100"
            onClick={() => this.toggleEditTagOverlay()}
          >
            <i className="fa fa-tags" />
            &nbsp;&nbsp;&nbsp;Neues Tag
          </div>
          <div className="small-spacer" />
          <div className="link" onClick={this.props.closeFunction}>
            schließen
          </div>
        </div>
      );

    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="edit-tags"
      >
        <h3>Tags</h3>
        {content}
        {this.state.showEditTagOverlay && (
          <EditTagOverlay
            closeFunction={this.toggleEditTagOverlay}
            fetchTags={this.props.fetchTags}
            editedTagId={this.state.editedTagId}
            loadTags={this.props.loadTags}
            setTagsAsSaving={this.props.setTagsAsSaving}
            tag={
              this.state.editedTagId
                ? this.props.tagList.tag_objects[
                    this.state.editedTagId.toString()
                  ]
                : null
            }
          />
        )}
      </RequestOverlay>
    );
  }
}
