import React from "react";
import { FormattedMessage as T } from "react-intl";

export class SmartInventoryHeadline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div className="table-header long-headline">
        <div className="">
          <i className="fa fa-wrench" />
          <T id="adminPanel.smartInventory.list.headline" defaultMessage="Inventory Monitoring"/>
          <small className="font-weight-normal" style={{marginLeft: '10px'}}>
            <T
              id="adminPanel.smartInventory.list.headline.amount"
              defaultMessage="{total_amount} Items"
              values={{ total_amount: this.props.numberItems }}
            />
          </small>
        </div>
      </div>
    );
  }
}
