import React from "react";
import { TextInput } from "../TextInput";
import { LoadingCircle } from "../LoadingCircle";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  mileage: {
    id: "tracar.mileage",
    defaultMessage: "Kilometerstand",
  },
});

class OdometerClass extends React.Component {
  handleChange;
  save;

  constructor(props) {
    super(props);
    this.state = {
      mileage: this.props.odometer || 0,
      saving: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  save() {
    this.setState({ saving: true });
    this.props
      .changeOdometer(this.props.imei, parseInt(this.state.mileage, 10))
      .then(() => {
        this.props.setOdometer(this.state.mileage);
        this.props.closeFunction();
      });
  }

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <RequestOverlay
        closeFunction={this.props.closeFunction}
        className="change-password"
      >
        {this.state.saving ? (
          <LoadingCircle />
        ) : (
          <div>
            <h5>
              <T
                id="tracar.device.changeOdometer"
                defaultMessage="Kilometerstand ändern"
              />
            </h5>
            <TextInput
              type="number"
              id="mileage"
              name={t(terms.mileage)}
              value={this.state.mileage}
              onChange={this.handleChange}
            />
            <div className="button save-button" onClick={this.save}>
              <T
                id="tracar.device.accept"
                defaultMessage="Änderungen speichern"
              />
            </div>
          </div>
        )}
      </RequestOverlay>
    );
  }
}

export const Odometer = injectIntl(OdometerClass);
