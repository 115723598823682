import React from "react";
import { RequestOverlay } from "../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { UserConsumer } from "../Base";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";

// Translations
const terms = defineMessages({
  header_title: {
    id: "message.map.header.title",
    defaultMessage: "Kartenansicht",
  },
  close: {
    id: "message.map.close",
    defaultMessage: "Schließen",
  },
});

class MessageMapOverlayClass extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Renders the loading circle if a file was just uploaded
   * the headline, description and fileupload otherwise.
   * @return RequestOverlay
   */
  render() {
    return this.renderInterface();
  }

  /**
   * Interface to upload a CSV-file
   * @return JSX
   */
  renderInterface = () => {
    return (
      <UserConsumer>{user => <div>{this.renderBody()}</div>}</UserConsumer>
    );
  };

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="headline">
        <i className="icon-wrench" />
        <div>{t(terms.header_title)}</div>
      </div>
    );
  };

  /**
   * Render the body
   * @return JSX
   */
  renderBody = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <GoogleMap
          bounds={{
            east: 13.916343,
            north: 53.930902,
            south: 48.166079,
            west: 6.012385,
          }}
          ref={map => (this.map = map)}
          defaultZoom={19}
          zoom={10}
          defaultOptions={{
            styles: [],
            scaleControl: true,
            disableDefaultUI: false,
            zoomControl: true,
          }}
          defaultCenter={{
            lat: this.props.message.lat,
            lng: this.props.message.lon,
          }}
          onClick={() => {}}
        >
          <Marker
            key={-1}
            position={{
              lat: this.props.message.lat,
              lng: this.props.message.lon,
            }}
            icon={{
              url: `/pins/${this.props.portal}/pins-red/Element 1.png`,
              anchor: new window.google.maps.Point(8, 8),
            }}
          />
        </GoogleMap>
      </div>
    );
  };

  /**
   * Render the footer
   * @return JSX
   */
  renderFooter = user => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="upload-container footer-container">
        <div className="button-container align-right align-middle">
          <div className="cancel" onClick={this.props.closeFunction}>
            {t(terms.close)}
          </div>
        </div>
      </div>
    );
  };
}

export const MessageMapOverlay = withGoogleMap(
  injectIntl(MessageMapOverlayClass),
);
