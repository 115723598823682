import React from "react";
import { Geofencing } from "./Geofencing";

export class GeofencingBase extends React.Component {
  updateObject;

  constructor(props) {
    super(props);
    this.state = { fence: null };
    this.updateObject = this.updateObject.bind(this);
  }

  updateObject(currentObject, circle, polygon) {
    this.setState({
      currentObject,
      circle,
      polygon,
      showCustomerAddresses: false,
      customerAddressList: [],
      fitToBounds: true,
    });
  }

  loadCustomerAddressList = () => {
    if (this.state.showCustomerAddresses) {
      this.setState({
        showCustomerAddresses: false,
      });
    } else {
      this.setState({ customerAddressListLoading: true });
      this.setState({
        showCustomerAddresses: true,
      });
      this.props.getPaginatedCustomerAddresses(0, 1000).then(res => {
        this.setState({
          customerAddressList: res.cust_addr,
          customerAddressListLoading: false,
          fitToBounds: true,
        });
      });
    }
  };

  updateFitToBounds = status => {
    this.setState({ fitToBounds: status });
  };

  render() {
    return (
      <div>
        <div className="displayed-time">
          <div
            className={`button customer ${this.state.showCustomerAddresses &&
              "active"}`}
            onClick={this.loadCustomerAddressList}
          >
            <i className="fa fa-home" aria-hidden="true" />
          </div>
        </div>

        <Geofencing
          userLocation={this.props.userLocation}
          fence={this.state.fence}
          containerElement={
            <div className="map-container" style={{ height: `100vh` }} />
          }
          saveGeofence={this.props.saveGeofence}
          getGeofencingList={this.props.getGeofencingList}
          getLocationForSingleTracker={this.props.getLocationForSingleTracker}
          match={this.props.match}
          mapElement={<div style={{ height: `calc(50vh)` }} />}
          customerAddressList={{
            addresses: this.state.customerAddressList,
            show: this.state.showCustomerAddresses,
            isLoading: this.state.customerAddressListLoading,
            fitToBounds: this.state.fitToBounds,
          }}
          updateFitToBounds={this.updateFitToBounds}
          fetchAddress={this.props.fetchAddress}
        />
      </div>
    );
  }
}
