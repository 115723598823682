import React from "react";
import { FormattedMessage as T } from "react-intl";

/* TODO ponder: own component!*/
/* TODO TRANSLATE*/

export class CustomerAddressHeadline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      deleteMarkedEntries,
      markAll,
      // markedAddresses,
      markSelectedAsRead,
      customerAddressList,
    } = this.props;

    // const disabled = markedAddresses.size === 0 ? " disabled" : "";
    return (
      <div className="table-header long-headline">
        <div className="">
          <i className="fa fa-address-book-o" />
          <T
            id="customer.addresses.list.headline"
            defaultMessage="Kundenadressen"
          />
          &nbsp;&nbsp;&nbsp;{" "}
          <small className="font-weight-normal">
            <T
              id="customer.addresses.list.headline.amount"
              defaultMessage="{total_amount} Adressen"
              values={{ total_amount: customerAddressList.total_amount }}
            />
          </small>
        </div>
        {/*<div className="headline-link-list">
          <div className="link" onClick={markAll}>
            <T
              id="customer.addresses.list.markAll"
              defaultMessage="alle markieren"
            />
          </div>
          |
          <div className={"link " + disabled} onClick={deleteMarkedEntries}>
            <T
              id="customer.addresses.list.deleteMarked"
              defaultMessage="markierte Löschen"
            />
          </div>
        </div>*/}
      </div>
    );
  }
}
