
import React from "react";
import { WireExport } from "./WireExport";
import { FormattedMessage as T } from "react-intl";


export class WireExportBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExportOverlay: false,
    };
  }

  toggleExportOverlay = () =>
    this.setState({ showExportOverlay: !this.state.showExportOverlay });

  render() {
    return (
      <div>
        <div className="button save-button" onClick={this.toggleExportOverlay}>
          <i className="fa fa-download" /> &nbsp;{" "}
          <T id="tracar.wires.exportData" defaultMessage="Daten exportieren" />
        </div>
        {this.state.showExportOverlay && (
          <WireExport
            closeFunction={this.toggleExportOverlay}
            imei={this.props.imei}
            generateWiresCsv={this.props.generateWiresCsv}
          />
        )}
      </div>
    );
  }
}
