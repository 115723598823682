import React from "react";
import ReactTooltip from "react-tooltip";
import Link from "react-router-dom/es/Link";
import { Portal } from "../Portal";
import { RequestOverlay } from "../Request/RequestOverlay";

import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import FormattedMessageFixed from "../tools/FormattedMessageFixed";

const terms = defineMessages({
  del: {
    id: "customer.addresses.tableLine.delete",
    defaultMessage: "Löschen",
  },
  edit: {
    id: "customer.addresses.tableLine.edit",
    defaultMessage: "Bearbeiten",
  },
  mapView: {
    id: "tracar.device-list.mapView",
    defaultMessage: "Kartenansicht",
  },
});

class ServiceIntervalTableLineClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showEditOverlay: false };
  }

  toggleShowEditOverlay = () => {
    this.setState({ showEditOverlay: !this.state.showEditOverlay });
  };

  editInterval = () => {
    this.setState({
      showEditOverlay: !this.state.showEditOverlay,
    });
  };

  toggleShowConfirmCompletionOverlay = () => {
    this.setState({
      showConfirmCompletionOverlay: !this.state.showConfirmCompletionOverlay,
    });
  };

  setIntervalCompletion = () => {
    this.props
      .setIntervalCompletion(
        !this.props.serviceInterval.is_done,
        this.props.serviceInterval.service_interval_id,
        this.props.serviceInterval.imei,
      )
      .then(() => {
        this.toggleShowConfirmCompletionOverlay();
        this.props.updateServiceIntervals();
      });
  };

  render() {
    const t = this.props.intl.formatMessage;
    const serviceIntervalId = this.props.serviceInterval.service_interval_id;

    return (
      <div className="grid-x grid-margin-x relative">
        <div className="cell auto font-black">
          <span className="font-bold">
            {this.props.serviceInterval.service_type_choices || "-"}
          </span>
        </div>

        <div className="cell auto font-black">
          {this.props.serviceInterval.service || "-"}
        </div>

        <div className="cell auto font-black">
          {this.props.serviceInterval.vehicle_model || "-"}
          {/*<div className="subline">Subline</div>*/}
        </div>

        <div className="cell auto font-black">
          {(
            <FormattedMessageFixed
              id={`tracar.portal.${this.props.serviceInterval.device_category}`}
              defaultMessage={this.props.serviceInterval.device_category}
            />
          ) || "-"}
        </div>

        <div className="cell medium-2 font-black">
          {this.props.serviceInterval.planned_service_date || "-"}
        </div>

        <div className="cell medium-2 font-black">
          {this.props.serviceInterval.threshold
            ? `${this.props.serviceInterval.threshold} ${this.props
                .serviceInterval.threshold_type_choices || ""}`
            : "-"}
        </div>
        <div className="cell auto font-black">
          {this.props.serviceInterval.device_odom_or_ignition_curr
            ? `${this.props.serviceInterval.device_odom_or_ignition_curr} ${this
                .props.serviceInterval.threshold_type_choices || ""}`
            : "-"}
        </div>

        <div className="cell medium-1 relative flex-just-between icon-wrap">
          <div className="cell auto table-line-right">
            <div className="button-container">
              <div className="button cursor-default">
                <i
                  className={`fa fa-check-circle ${
                    this.props.serviceInterval.is_done
                      ? "complete"
                      : "uncomplete"
                  } ${this.props.portal === Portal.AdminPanel &&
                    "cursor-pointer"}`}
                  onClick={
                    this.props.portal === Portal.AdminPanel
                      ? () => {
                          this.toggleShowConfirmCompletionOverlay();
                        }
                      : () => {}
                  }
                />
              </div>
              <div className="button-wrap">
                <Link
                  to={"/all/map/" + this.props.serviceInterval.device_id}
                  className="button"
                  data-for={`service-interval-table-line-${
                    this.props.serviceInterval.device_id
                  }`}
                  data-tip={t(terms.mapView)}
                >
                  <i className="icon-map_marker padding-left-5px" />
                </Link>

                {this.props.portal === Portal.AdminPanel && (
                  <div
                    className="button"
                    data-for={`service-interval-table-line-${serviceIntervalId}`}
                    data-tip={t(terms.edit)}
                    onClick={() => this.toggleShowEditOverlay()}
                  >
                    <i className="icon-cog padding-left-5px" />
                  </div>
                )}

                {this.props.portal === Portal.AdminPanel && (
                  <div
                    className="button"
                    data-for={`service-interval-table-line-${serviceIntervalId}`}
                    data-tip={t(terms.del)}
                    onClick={() =>
                      this.props.showDeleteConfirmation(
                        serviceIntervalId,
                        this.props.serviceInterval.user,
                      )
                    }
                  >
                    <i className="icon-trash padding-left-5px" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip
          delayShow={500}
          class="tooltip"
          border={true}
          id={`service-interval-table-line-${serviceIntervalId}`}
        />
        {this.state.showConfirmCompletionOverlay && (
          <RequestOverlay
            closeFunction={this.toggleShowConfirmCompletionOverlay}
            className="import-customer-addresses"
            enableCloseOnOverlayClicked={true}
          >
            {this.renderHeader()}
            {this.renderButtons()}
          </RequestOverlay>
        )}
      </div>
    );
  }

  /**
   * The header with a description
   * @return JSX
   */
  renderHeader = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div>
        <h5>
          <T
            id="tracar.service.intervals.completion.headline"
            defaultMessage="Möchten Sie das Intervall aktualisieren?"
          />
        </h5>
      </div>
    );
  };

  /**
   * Renders the buttons
   * @return JSX
   */
  renderButtons = () => {
    return (
      <div className="confirm-button-container">
        <div className="spacer" />
        <div
          className="button"
          onClick={() => this.toggleShowConfirmCompletionOverlay()}
          style={{ marginRight: "15px" }}
        >
          <span>
            <T
              id="tracar.service.intervals.completion.cancel"
              defaultMessage="Abbrechen"
            />
          </span>
        </div>

        <div
          className="button save-button"
          onClick={() => this.setIntervalCompletion()}
        >
          <span>
            <T
              id="tracar.service.intervals.completion.confirm"
              defaultMessage="Bestätigen"
            />
          </span>
        </div>
      </div>
    );
  };
}

export const ServiceIntervalTableLine = injectIntl(
  ServiceIntervalTableLineClass,
);
