import React from "react";
import {injectIntl} from "react-intl";
import {LoadingCircle} from "../../../LoadingCircle";
import {fetchTaskTemplateTasks} from "../../../fetch/fetchSmartInventory";

class SmartInventoryTaskTemplatesTableLineTasks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tasks: [],
            isLoading: true,
        };
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }

        if (this.state.isLoading) {
            return <LoadingCircle />
        }

        return (
            <div className="grid-x grid-margin-x relative task-template-tasks-container">
                {this.state.tasks.map((task) => {
                    return (
                        <div className="cell small-12 bold grey-text">
                            <span className={`right-angle`}> </span>
                            {task.task_name}
                        </div>
                    );
                })}
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isVisible || this.state.tasks.length !== 0 || !this.state.isLoading) {
            return;
        }

        fetchTaskTemplateTasks(this.props.fetchService.jsonFetch, this.props.template.id)
            .then(response => this.setState({
                tasks: response.tasks_template,
                isLoading: false,
            }));
    }
}

export default injectIntl(SmartInventoryTaskTemplatesTableLineTasks);
