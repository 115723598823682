import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TextInput } from "../TextInput";
import { WireExportBase } from "../Wires/WireExportBase";
import { LoadingSmallCircle } from "../LoadingCircle";

const terms = defineMessages({
  brownCable: {
    id: "tracar.wires.brownCable",
    defaultMessage: "Braunes Kabel; Input 1",
  },
  purpleCable: {
    id: "tracar.wires.purpleCable",
    defaultMessage: "Lila Kabel; Input 2",
  },
  greenCable: {
    id: "tracar.wires.greenCable",
    defaultMessage: "Grünes Kabel; Input 3",
  },
  whiteCable: {
    id: "tracar.wires.whiteCable",
    defaultMessage: "Weißes Kabel; Analog Input 1",
  },
});

class WiresTabClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.wiresSettings,
    };
  }

  componentWillUnmount() {
    this.rememberSettings();
  }

  rememberSettings = () => {
    let settings = this.props.wiresSettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null) {
        settings[setting[0]] = this.state[setting[0]];
      }
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  saveDeviceSettings = () => {
    this.rememberSettings();

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: Object.entries(this.state).map(wire => {
        return wire[1];
      }),
    });

    this.props.saveDeviceSettings(settingsContainer);
  };

  handleChange = event => {
    const target = event.target;
    const name = target.id;
    const value = target.value;
    let wire = this.state[name];
    wire.description = value;
    this.setState({ [name]: wire });

    this.props.setSettingsSaving("false");
  };

  updateState = updatedState => {
    this.props.setSettingsSaving("false");
    this.setState({ ...updatedState });
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <React.Fragment>
        <div className="content-container">
          <div className="content">
            <div>
              <div className="button-frame">
                <WireExportBase
                  generateWiresCsv={this.props.generateWiresCsv}
                  imei={
                    Array.isArray(this.props.selectedDevicesImeiList)
                      ? this.props.selectedDevicesImeiList[0]
                      : this.props.selectedDevicesImeiList
                  }
                />
              </div>
              <div>
                <div className="grid-x grid-margin-x">
                  {this.props.wiresSettings.map((element, index) => (
                    <TextInput
                      className="medium-6 large-6 cell"
                      id={index}
                      name={element.wire.base_description}
                      onChange={this.handleChange}
                      value={this.state[index].description}
                      disabled={this.props.readOnly}
                    />
                  ))}
                  {/*<TextInput
                    className="medium-6 large-6 cell"
                    id="0"
                    name={t(terms.brownCable)}
                    onChange={this.handleChange}
                    value={this.state["0"].description}
                    disabled={this.props.readOnly}
                  />
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="1"
                    name={t(terms.purpleCable)}
                    onChange={this.handleChange}
                    value={this.state["1"].description}
                    disabled={this.props.readOnly}
                  />
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="2"
                    name={t(terms.greenCable)}
                    onChange={this.handleChange}
                    value={this.state["2"].description}
                    disabled={this.props.readOnly}
                  />
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="3"
                    name={t(terms.whiteCable)}
                    onChange={this.handleChange}
                    value={this.state["3"].description}
                    disabled={this.props.readOnly}
                  />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/${
              this.props.portal === "admin-panel"
                ? "device/list"
                : "device-list"
            }`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          {!this.props.readOnly && (
            <div
              className={`button save-button ${this.props.settingsSaving ===
                "true" && "saving"} ${this.props.settingsSaving === "done" &&
                "saved"}`}
              onClick={() =>
                this.props.settingsSaving === "false" &&
                this.saveDeviceSettings()
              }
            >
              {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
              <span>
                {this.props.settingsSaving === "true" && (
                  <T
                    id="tracar.settings.wires.saving"
                    defaultMessage="Kabel werden aktualisiert..."
                  />
                )}
                {this.props.settingsSaving === "false" && (
                  <T
                    id="tracar.settings.wires.save"
                    defaultMessage="Kabel aktualisieren"
                  />
                )}
                {this.props.settingsSaving === "done" && (
                  <T
                    id="tracar.settings.wires.saved"
                    defaultMessage="Kabel aktualisiert"
                  />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export const WiresTab = injectIntl(WiresTabClass);
