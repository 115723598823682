import React from "react";

/**
 * displays the red loading circle
 */
export function LoadingCircle({ className = "" }) {
  return (
    <div className={`lds-css ${className}`}>
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "auto",
        }}
        className="lds-eclipse"
      >
        <div />
      </div>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "@keyframes lds-eclipse {0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}50% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}}@-webkit-keyframes lds-eclipse {0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}50% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}}.lds-eclipse {position: relative;}.lds-eclipse div {position: absolute;-webkit-animation: lds-eclipse 1s linear infinite;animation: lds-eclipse 1s linear infinite;width: 160px;height: 160px;top: 20px;left: 20px;border-radius: 50%;box-shadow: 0 4px 0 0 #e60000;-webkit-transform-origin: 80px 82px;transform-origin: 80px 82px;}.lds-eclipse {width: 125px !important;height: 125px !important;-webkit-transform: translate(-62.5px, -62.5px) scale(0.625) translate(62.5px, 62.5px);transform: translate(-62.5px, -62.5px) scale(0.625) translate(62.5px, 62.5px);}",
        }}
      />
    </div>
  );
}

export function LoadingSmallCircle() {
  return (
    <React.Fragment>
      <div className="small-loading-circle">
        <div className="lds-eclipse">
          <div />
        </div>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "@keyframes lds-eclipse {0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}50% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}}@-webkit-keyframes lds-eclipse {0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}50% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}}.lds-eclipse {position: relative;}.lds-eclipse div {position: absolute;-webkit-animation: lds-eclipse 1s linear infinite;animation: lds-eclipse 1s linear infinite;width: 20px;height: 20px;top: 2,5px;left: 2,5px;border-radius: 50%;box-shadow: 0 3px 0 0 #e60000;-webkit-transform-origin: 10px 10px;transform-origin: 10px 10px;}.lds-eclipse {width: 16px !important;height: 16px !important;-webkit-transform: translate(-8px, -8px) scale(0.625) translate(8px, 8px);transform: translate(-8px, -8px) scale(0.625) translate(8px, 8px);}",
          }}
        />
      </div>
    </React.Fragment>
  );
}
