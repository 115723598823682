import React from "react";
import { TextInput } from "../TextInput";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";

const terms = defineMessages({
  selectGender: {
    id: "tracar.user.selectGender",
    defaultMessage: "Bitte wählen Sie",
  },
  male: {
    id: "tracar.user.male",
    defaultMessage: "Herr",
  },
  female: {
    id: "tracar.user.female",
    defaultMessage: "Frau",
  },
  title: {
    id: "tracar.user.title",
    defaultMessage: "Titel",
  },
  first_name: {
    id: "tracar.user.first_name",
    defaultMessage: "Vorname",
  },
  last_name: {
    id: "tracar.user.last_name",
    defaultMessage: "Nachname",
  },
  email: {
    id: "tracar.user.email",
    defaultMessage: "E-Mail",
  },
  phone: {
    id: "tracar.user.phone",
    defaultMessage: "Telefonnummer",
  },
  company_name: {
    id: "tracar.user.company_name",
    defaultMessage: "Firmenname",
  },
  street: {
    id: "tracar.user.street",
    defaultMessage: "Straße",
  },
  city: {
    id: "tracar.user.city",
    defaultMessage: "Stadt",
  },
  state: {
    id: "tracar.user.state",
    defaultMessage: "Bundesland",
  },
  postal_code: {
    id: "tracar.user.postal_code",
    defaultMessage: "Postleitzahl",
  },
  country: {
    id: "tracar.user.country",
    defaultMessage: "Land",
  },
});

class UserClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.user,
      showPasswordOverlay: false,
      validationError: "",
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  };

  validate() {
    if (this.state.company_name === "" && this.state.is_company) {
      this.setState({
        validationError: "Bitte geben Sie einen Firmennamen an",
      });
      return false;
    }
    return true;
  }

  save = () => {
    if (this.validate()) {
      // noinspection JSUnusedLocalSymbols
      const { validationError, ...userData } = this.state;
      this.props.save(userData);
    }
  };

  render() {
    const t = this.props.intl.formatMessage;
    return (
      <div className="form">
        <h2>
          <T id="tracar.user.headline" defaultMessage="Nutzerdaten" />
        </h2>
        <div className="error">{this.state.validationError}</div>
        <div className="grid-x grid-margin-x">
          <div className="medium-6 large-6 cell">
            <div className="select-frame">
              <i className="fa fa-angle-down angle" />
              <select
                className="select"
                id="gender"
                value={this.state.gender}
                onChange={this.handleChange}
              >
                <option value="4">{t(terms.selectGender)}</option>
                <option value="0">{t(terms.male)}</option>
                <option value="1">{t(terms.female)}</option>
              </select>
              <label htmlFor="gender">
                <T id="tracar.user.salutation" defaultMessage="Anrede" />
              </label>
            </div>
          </div>
          <TextInput
            className="medium-6 large-6 cell"
            id="title"
            name={t(terms.title)}
            value={this.state.title}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="first_name"
            name={t(terms.first_name)}
            value={this.state.first_name}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="last_name"
            name={t(terms.last_name)}
            value={this.state.last_name}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            disabled={true}
            id="email"
            name={t(terms.email)}
            value={this.state.email}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="phone"
            name={t(terms.phone)}
            value={this.state.phone}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="company_name"
            disabled={!this.state.is_company}
            name={t(terms.company_name)}
            value={this.state.company_name}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="street"
            name={t(terms.street)}
            value={this.state.street}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="city"
            name={t(terms.city)}
            value={this.state.city}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="state"
            name={t(terms.state)}
            value={this.state.state}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="postal_code"
            name={t(terms.postal_code)}
            value={this.state.postal_code}
            onChange={this.handleChange}
          />
          <TextInput
            className="medium-6 large-6 cell"
            id="country"
            name={t(terms.country)}
            value={this.state.country}
            onChange={this.handleChange}
          />
          <div className="medium-6 large-6 cell">
            <div className="spacer" />
            <div
              className={"button border"}
              onClick={this.props.toggleChangePassword}
            >
              <T
                id="tracar.user.changePassword"
                defaultMessage="Passwort ändern"
              />
            </div>
          </div>
        </div>
        {this.state.validationError && <div className="small-spacer" />}
        <div className="error">{this.state.validationError}</div>
        {this.state.validationError && <div className="small-spacer" />}
        <div className="button important save-button" onClick={this.save}>
          <T id="tracar.user.save" defaultMessage="Profildaten speichern" />
        </div>
      </div>
    );
  }
}

export let User = injectIntl(UserClass);
