import React from "react";
import ReactPaginate from "react-paginate";
import Link from "react-router-dom/es/Link";
import { LoadingCircle } from "../LoadingCircle";
import { CustomerAddressList } from "./CustomerAddressList";
import { ConfirmDeleteOverlay } from "../ConfirmDeleteOverlay";
import { ImportAddressesOverlay } from "./ImportAddressesOverlay";
import { CustomerAddressHeadline } from "./CustomerAddressHeadline";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";

const terms = defineMessages({
  noCustomers: {
    id: "customer.addresses.base.noCustomers",
    defaultMessage: "Keine Kundenadressen gefunden",
  },
  confirmAddressDeleteLabel: {
    id: "customer.addresses.confirmDelete.label",
    defaultMessage: "die Adresse",
  },
});

class CustomerAddressesBaseClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentPage: 0,
      addressesPerPage: 25,
      addressIdToDelete: null,
      showImportOverlay: false,
      customerAddressList: null,
      showDeleteConfirmationOverlay: false,
    };
  }

  async componentDidMount() {
    this.load();
  }

  async load(paginationInfo = {}) {
    const customerAddressList = await this.props.getPaginatedCustomerAddresses(
      paginationInfo.offset || 0,
      paginationInfo.limit || this.state.addressesPerPage,
    );

    this.setState({ customerAddressList, loading: false });
  }

  /**
   * Deletes a single address
   * @param  integer id
   * @return void
   */
  deleteAddress = () => {
    if (!this.state.addressIdToDelete) {
      return;
    }

    this.props.deleteAddress(this.state.addressIdToDelete).then(response => {
      this.setState(
        {
          showDeleteConfirmationOverlay: false,
        },
        () => {
          this.load();
        },
      );
    });
  };

  toggleImportOverlay = (reload = false) => {
    this.setState({
      showImportOverlay: !this.state.showImportOverlay,
    });

    if (reload) {
      this.load();
    }
  };

  /**
   * Shows or hides the delete confirmation
   * @return void
   */
  toggleDeleteConfirmationOverlay = id => {
    this.setState({
      addressIdToDelete: id,
      showDeleteConfirmationOverlay: !this.state.showDeleteConfirmationOverlay,
    });
  };

  render() {
    const pagination = this.getPagination();

    return (
      <div className="outer-table-frame tracker">
        {this.getTopButtons()}

        {pagination}

        <div className="table-frame">{this.renderList()}</div>

        {pagination}

        {this.renderImportOverlay()}
        {this.renderDeleteConfirmationOverlay()}
      </div>
    );
  }

  /**
   * Return the add-address and import-address buttons.
   * @return JSX
   */
  getTopButtons = () => {
    return (
      <div className="button-frame">
        <div className="button-block">
          <Link to={`/admin-panel/customerAddresses/new`} className="button">
            <T
              id="customer.addresses.new"
              defaultMessage="Neue Kundenadresse erstellen"
            />
          </Link>
          <div className="button pull-right" onClick={this.toggleImportOverlay}>
            <i className="fa fa-upload" style={{ marginRight: "10px" }} />
            <T
              id="customer.addresses.import"
              defaultMessage="Adressen importieren"
            />
          </div>
        </div>
      </div>
    );
  };

  /**
   * Return the pagination if needed
   * @return JSX||null
   */
  getPagination = () => {
    const pageCount = this.getPageCount();

    if (!this.state.customerAddressList || pageCount <= 1) {
      return null;
    }

    return (
      <div className="pagination-wrapper">
        <ReactPaginate
          activeClassName={"active"}
          breakClassName={"break-me"}
          breakLabel={<a>...</a>}
          containerClassName={"pagination"}
          forcePage={this.state.currentPage}
          marginPagesDisplayed={2}
          nextLabel={<i className="fa fa-chevron-circle-right" />}
          onPageChange={this.handlePagination}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          previousLabel={<i className="fa fa-chevron-circle-left" />}
          subContainerClassName={"pages pagination"}
        />
        <ul className="pagination-limiter">
          {[25, 100, 500].map(limit => (
            <li {...this.getPaginationItemProps(limit)}>{limit}</li>
          ))}
          <T
            id="customer.addresses.base.pagination.label"
            defaultMessage="Kundenadressen"
          />
        </ul>
      </div>
    );
  };

  /**
   * Return the number of pages for the Pagination
   * @return integer
   */
  getPageCount = () => {
    if (!this.state.customerAddressList) {
      return 0;
    }

    return Math.ceil(
      this.state.customerAddressList.total_amount / this.state.addressesPerPage,
    );
  };

  /**
   * Returns the props for the pagination items
   * @param  ineteger limit
   * @return Object
   */
  getPaginationItemProps = limit => {
    const className = `link ${
      limit === this.state.addressesPerPage ? " active" : ""
    }`;

    return {
      key: limit,
      onClick: () => this.setPaginationLimit(limit),
      className,
    };
  };

  /**
   * Sets the pagination-limit and reloads the list
   * @param  integer limit
   * @return void
   */
  setPaginationLimit = limit => {
    this.setState(
      {
        addressesPerPage: limit,
      },
      this.handlePagination,
    );
  };

  /**
   * handles the click on the pagination
   * @param  Object data
   * @return void
   */
  handlePagination = data => {
    // Height of header
    const paginationYOffset = 155;
    const selected = data ? data.selected : this.state.currentPage;
    const { addressesPerPage } = this.state;
    const offset = Math.ceil(selected * addressesPerPage);

    this.setState({
      loading: true,
      currentPage: selected,
    });

    this.load({ offset, limit: addressesPerPage });

    if (window.pageYOffset > paginationYOffset) {
      window.scrollTo(0, paginationYOffset);
    }
  };

  /**
   * Returns the address-list-table
   * @return JSX
   */
  renderList = () => {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }

    const portal = this.props.portal || "tracar";

    return (
      <div>
        <CustomerAddressHeadline
          customerAddressList={this.state.customerAddressList}
        />
        <CustomerAddressList
          customerAddresses={this.state.customerAddressList.cust_addr}
          deleteAddress={this.deleteAddress}
          showDeleteConfirmation={this.toggleDeleteConfirmationOverlay}
          portal={portal}
        />
      </div>
    );
  };

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };

  /**
   * Render the import overlay
   * @return null|JSX
   */
  renderImportOverlay = () => {
    if (!this.state.showImportOverlay) {
      return null;
    }

    return (
      <ImportAddressesOverlay
        closeFunction={this.toggleImportOverlay}
        uploadCustomerAddresses={this.props.uploadCustomerAddresses}
      />
    );
  };

  /**
   * Render the delete confirmation
   * @return null|JSX
   */
  renderDeleteConfirmationOverlay = () => {
    if (!this.state.showDeleteConfirmationOverlay) {
      return null;
    }

    return (
      <ConfirmDeleteOverlay
        closeFunction={this.toggleDeleteConfirmationOverlay}
        confirmDelete={this.deleteAddress}
        label={terms.confirmAddressDeleteLabel.defaultMessage}
      />
    );
  };
}

export const CustomerAddressesBase = injectIntl(CustomerAddressesBaseClass);
