import React from "react";

import { NewUpdatesCheckOverlay } from "./NewUpdatesCheckOverlay";

export class NewUpdatesCheckBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warningClosed: !this.props.userLoginData.showUpdatePopup,
    };
  }

  toggleOverlay = () => {
    this.props.setLoggedIn(false);
    this.setState({ warningClosed: !this.state.warningClosed });
  };

  render() {
    return (
      !this.state.warningClosed && (
        <NewUpdatesCheckOverlay closeFunction={this.toggleOverlay} />
      )
    );
  }
}
