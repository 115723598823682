import React from "react";
import Plot from "react-plotly.js";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import moment from "moment";
import { FormattedMessage as T } from "react-intl";
import { sToh } from "../tools/functions";
import numeral from "numeral";

export function DeviceUsageGraph({
  config,
  noAxisValues,
  usage_data,
  widePlotLayoutCommons,
}) {
  /// same code is found in VehicleUsageLast30d
  const x = usage_data.map(({ date }) => moment(date).format("DD.MM.YYYY"));

  const graphKeys = Object.keys(usage_data[0]);

  // parse y-axis data
  let graphData = [];
  graphKeys.forEach(value => {
    switch(value) {
       // skip date since it is the x value
      case 'date': break;
      case 'usage_ignition': graphData[value] = usage_data.map(({ usage_ignition }) =>
         sToh(parseFloat(usage_ignition))
      );
      break;
      case 'usage_engine': graphData[value] = usage_data.map(({ usage_engine }) =>
         sToh(parseFloat(usage_engine))
      );
      break;
      // parse varying amount of cables
      default: graphData[value] = usage_data.map(({ [value]: { data } }) =>
         sToh(parseFloat(data))
      );
    }
  });

  const nf = (n: number) => numeral(n).format("0,0.0");

  const textLabel = x.map((device_name, index) => {
    let returnValue = '';
    graphKeys.forEach(value => {
      switch(value) {
         // skip date since it is the x value
        case 'date': break;
        case 'usage_ignition':
          returnValue += `Zündung:  ${nf(graphData['usage_ignition'][index])} h<br />`
          break;
        case 'usage_engine':
          returnValue += `Motor:  ${nf(graphData['usage_engine'][index])} h<br />`
          break;
         // parse varying amount of cables
        default:
          returnValue += `${usage_data[index][value].name}:  ${nf(graphData[value][index])} h<br />`
      }
    });

    return returnValue;
  });

  // a device can have a maximum of four cables, so we need up to four colors
  const wireColors = [
    Color.OuterSpace,
    Color.Silver,
    Color.SilverChalice,
    Color.SilverChalice1
  ]

  let wireColorsTemp = [...wireColors];

  let groupedBarChart = [
  ];

  graphKeys.forEach(value => {
    switch(value) {
       // skip date since it is the x value
      case 'date': break;
      case 'usage_ignition':
        groupedBarChart.push({
          x,
          y: graphData[value],
          marker: { color: Color.Red },
          type: "bar",
          hoverinfo: "x+text",

          text: textLabel,
        })
        break;
      case 'usage_engine':
        groupedBarChart.push({
          x,
          y: graphData[value],
          marker: { color: Color.DoveGrey },
          type: "bar",
          hoverinfo: "none",
        })
        break;
       // parse varying amount of cables
      default:
        groupedBarChart.push({
          x,
          y: graphData[value],
          marker: { color: wireColorsTemp.pop() },
          type: "bar",
          hoverinfo: "none",
        })
    }
  })
  // restore wire colors
  wireColorsTemp = [...wireColors];

  return (
    <div className="wide-graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          {" "}
          <i className="fa fa-truck" />
          <T
            id="tracar.vehicleUsage.usage30d"
            defaultMessage="Gerätenutzung, letzte 30 Tage"
          />
        </h5>
      </div>
      <Plot
        config={config}
        data={groupedBarChart}
        layout={{
          ...widePlotLayoutCommons,
          margin: widePlotLayoutCommons.margin,
          yaxis: { title: "h", tickformat: ",.0f," },
          xaxis: {
            ...noAxisValues,
            type: "category",
          },
        }}
      />
      <ul style={{ marginLeft: "16px", marginTop: "-28px" }}>
        {graphKeys.map(value => {
          switch(value) {
             // skip date since it is the x value
            case 'date': break;
            case 'usage_ignition':
              return <li>
                <i className="fa fa-square" style={{ color: Color.Red }} />
                &nbsp;&nbsp;
                <T id="tracar.vehicleUsage.ignition" defaultMessage="Zündung" />
                &nbsp;&nbsp;&nbsp;
              </li>
            case 'usage_engine':
              return <li>
                <i className="fa fa-square" style={{ color: Color.DoveGrey }} />
                &nbsp;&nbsp;
                <T id="tracar.vehicleUsage.engine" defaultMessage="Motor" />{" "}
                &nbsp;&nbsp;&nbsp;
              </li>
             // parse varying amount of cables
            default:
              return <li>
                <i className="fa fa-square" style={{ color: wireColorsTemp.pop() }} />
                &nbsp;&nbsp;
                {usage_data[0][value].name}
                &nbsp;&nbsp;&nbsp;
              </li>
          }
        })}
      </ul>
    </div>
  );
}
