import React from "react";
import Plot from "react-plotly.js";
import { format } from "date-fns";
import { AccColor as Color } from "../ClientSpecificContent/acc-index";
import { FormattedMessage as T } from "react-intl";
import numeral from "numeral";

export function FleetRangeLast30D({
  config,
  plotLayoutCommons,
  distance_total,
}) {
  const x = distance_total.x.map(t => format(t, "DD.MM.YYYY"));
  const { y } = distance_total;

  const textLabel = x.map((date, index) => {
    return `${numeral(y[index]).format("0,0.")} km<br />${date}`;
  });

  return (
    <div className="graph-frame">
      <div className="graph-headline">
        <h5 className="headline-for-icon">
          <i className="fa fa-truck" />
          <T
            id="tracar.Dashboard.fleetPerformanceLast30Days"
            defaultMessage="Flottenleistung, letzte 30 Tage"
          />
        </h5>
      </div>
      <div className="tiny-spacer" />
      <Plot
        config={config}
        data={[
          {
            x,
            y,
            name: "Foo",
            type: "scatter",
            fill: "tozeroy",
            line: { shape: "spline" },
            fillcolor: Color.TransparentDoveGrey,
            mode: "lines+points",
            marker: { color: Color.Red },
            text: textLabel,
            hoverinfo: "text",
          },
        ]}
        layout={{
          ...plotLayoutCommons,
          yaxis: {
            tickformat: ",.0f",
            hoverformat: "none",
            hoverinfo: "none",
          },
          xaxis: {
            tickangle: 0,
            tickvals: x,
            /// take only the first, middle and last date
            /// to avoid overlapping ticks
            ticktext: x.map((date, index, array) => {
              if (index === 0) {
                return date;
              }
              if (index === array.length - 1) {
                return date;
              }
              if (index === Math.floor(array.length / 2)) {
                return date;
              }
              return "";
            }),
          },
          margin: { ...plotLayoutCommons.margin, b: 40, l: 50 },
        }}
      />
      <div style={{ marginLeft: "22px", marginTop: "-22px" }}>
        <T
          id="tracar.dashboard.drivenKmAllVehiclesLast30Days"
          defaultMessage="gefahrene km, alle Fahrzeuge, letzte 30 Tage"
        />
      </div>
    </div>
  );
}
