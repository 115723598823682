import React from "react";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import Link from "react-router-dom/es/Link";
import { LoadingSmallCircle } from "../../../LoadingCircle";
import { SelectInput } from "../../../SelectInput";
import { TextInput } from "../../../TextInput";
import { ConfirmCategoryChangeOverlay } from "./ConfirmCategoryChangeOverlay";

const terms = defineMessages({
  identificationNumber: {
    id: "tracar.device.identificationNumber",
    defaultMessage: "Identifikationsnummer",
  },
  description: {
    id: "tracar.device.description",
    defaultMessage: "Beschreibung",
  },
  device_odom_curr: {
    id: "tracar.device_odometer",
    defaultMessage: "Kilometerstand",
  },
  fahrtenbuch: {
    id: "tracar.device.fahrtenbuch",
    defaultMessage: "Fahrtenbuch",
  },
  noUser: {
    id: "tracar.device.noUser",
    defaultMessage: "keinem Nutzer zugeordnet",
  },
  driver: {
    id: "tracar.device.driver",
    defaultMessage: "Fahrer",
  },
  model: {
    id: "tracar.device.model",
    defaultMessage: "Modell",
  },
  device_ignition_time_curr: {
    id: "tracar.device.device_ignition_time_curr",
    defaultMessage: "Betriebsstunden",
  },
});

class CategoryTabClass extends React.Component {
  constructor(props) {
    super(props);

    const startCategory = this.props.categorySettings.category_options.find(
      x => x.id === this.props.categorySettings.current,
    );
    console.log("categorySettings", this.props.categorySettings);
    console.log("startCategory", startCategory);
    this.state = {
      ...this.props.categorySettings,
      ...this.props.userSettings,
      initialCategoryId: this.props.categorySettings.current,
      showErrorMessage: false,
      showMore: false,
      selectedCategory: startCategory.name,
      selectedCategoryId: startCategory.id,
      car_number: startCategory.car_details.car_number,
      model: startCategory.car_details.model,
      driver: startCategory.car_details.driver,
      device_odom_curr: startCategory.car_details.device_odom_curr,
      device_ignition_time_curr:
        startCategory.car_details.device_ignition_time_curr,
      showCategoryChangeConfirmation: false,
    };
    console.log("state after init", this.state);

    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    this.rememberSettings();
    this.rememberUserSettings();
  }

  rememberSettings = () => {
    let settings = this.props.categorySettings;
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]] !== null) {
        settings[setting[0]] = this.state[setting[0]];
      }
    });

    this.props.updateDeviceSettings(this.props.tabType, settings);
  };

  rememberUserSettings = () => {
    let settings = this.props.userSettings;
    if (!settings) {
      return;
    }
    Object.entries(settings).forEach(setting => {
      if (this.state[setting[0]])
        settings[setting[0]] = isNaN(parseInt(this.state[setting[0]]))
          ? this.state[setting[0]]
          : parseInt(this.state[setting[0]]);
    });

    this.props.updateDeviceSettings("user_settings", settings);
  };

  updateCarDetails(name) {
    if (name === "AUTO") {
      return {
        name: this.state.selectedCategory,
        id: this.state.category_options.find(
          x => x.name === this.state.selectedCategory,
        ).id,
        car_details: {
          car_number: this.state.car_number,
          model: this.state.model,
          driver: this.state.driver,
          device_odom_curr: parseFloat(this.state.device_odom_curr),
        },
      };
    } else if (name === "VEHICLE") {
      return {
        name: this.state.selectedCategory,
        id: this.state.category_options.find(
          x => x.name === this.state.selectedCategory,
        ).id,
        car_details: {
          car_number: this.state.car_number,
          model: this.state.model,
          driver: this.state.driver,
          device_ignition_time_curr: parseFloat(
            this.state.device_ignition_time_curr,
          ),
        },
      };
    } else if (name === "POOLVEHICLE") {
      return {
        name: this.state.selectedCategory,
        id: this.state.category_options.find(
          x => x.name === this.state.selectedCategory,
        ).id,
        car_details: {
          car_number: this.state.car_number,
          model: this.state.model,
          device_odom_curr: parseFloat(this.state.device_odom_curr),
        },
      };
    }
    return undefined;
  }

  saveDeviceSettings = () => {
    this.rememberSettings();
    this.rememberUserSettings();

    let settingsContainer = {};
    Object.assign(settingsContainer, {
      [this.props.tabType]: this.updateCarDetails(this.state.selectedCategory),
    });

    Object.assign(settingsContainer, {
      user_settings: this.props.userSettings,
    });
    this.props.saveDeviceSettings(settingsContainer);
    this.setState({
      showCategoryChangeConfirmation: false,
      initialCategoryId: this.state.selectedCategoryId,
    });
  };

  handleChange = event => {
    this.props.setSettingsSaving("false");
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "set_speed_limit" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    if (name === "selectedCategory") {
      const x = this.props.categorySettings.category_options.find(
        x => x.name === value,
      );
      if (x) {
        this.setState(
          {
            selectedCategoryId: x.id,
            car_number: x.car_details.car_number,
            model: x.car_details.model,
            driver: x.car_details.driver,
            device_odom_curr: x.car_details.device_odom_curr,
            device_ignition_time_curr: x.car_details.device_ignition_time_curr,
          },
          () => {
            console.log("state after change", this.state);
          },
        );
      }
    }
    this.setState({ [name]: value });
  };

  /**
   * Toggle category change confirmation overlay
   * @return void
   */
  toggleCategoryChangeConfirmation = () => {
    this.setState({
      showCategoryChangeConfirmation: !this.state
        .showCategoryChangeConfirmation,
    });
  };

  isFloat(value) {
    return (
      typeof value === "number" ||
      value.match("^([0-9]+([.][0-9]*)?|[.][0-9]+)$")
    );
  }

  canSubmit() {
    const currData = this.state;
    switch (this.state.selectedCategory) {
      case "AUTO":
        return (
          currData.car_number !== undefined &&
          currData.car_number !== null &&
          currData.car_number.length > 0 &&
          currData.model !== undefined &&
          currData.model !== null &&
          currData.model.length > 0 &&
          currData.driver !== undefined &&
          currData.driver !== null &&
          currData.driver.length > 0 &&
          currData.device_odom_curr !== undefined &&
          currData.device_odom_curr !== null &&
          this.isFloat(currData.device_odom_curr) &&
          currData.device_odom_curr >= 0
        );
      case "POOLVEHICLE":
        return (
          currData.car_number !== undefined &&
          currData.car_number !== null &&
          currData.car_number.length > 0 &&
          currData.model !== undefined &&
          currData.model !== null &&
          currData.model.length > 0 &&
          currData.device_odom_curr !== undefined &&
          currData.device_odom_curr !== null &&
          this.isFloat(currData.device_odom_curr) &&
          currData.device_odom_curr >= 0
        );

      case "VEHICLE":
        return (
          currData.car_number !== undefined &&
          currData.car_number !== null &&
          currData.car_number.length > 0 &&
          currData.model !== undefined &&
          currData.model !== null &&
          currData.model.length > 0 &&
          currData.driver !== undefined &&
          currData.driver !== null &&
          currData.driver.length > 0 &&
          currData.device_ignition_time_curr !== undefined &&
          currData.device_ignition_time_curr !== null &&
          this.isFloat(currData.device_ignition_time_curr) &&
          currData.device_ignition_time_curr >= 0
        );
      default:
        return false;
    }
  }

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <React.Fragment>
        <div className="content-container utility-tab">
          <div>
            <div className="content margin-bottom-0 padding-horizontal-0 padding-vertical-0 no-bg">
              <SelectInput
                id="selectedCategory"
                // name={t(terms.user_role_label)}
                value={this.state.selectedCategory}
                nameProp={"name"}
                valueProp={"id"}
                options={this.state.category_options.map(x => {
                  {
                    return {
                      id: x.name,
                      name: this.props.intl.formatMessage({
                        id: "tracar.portal." + x.name.toLowerCase(),
                        defaultMessage: x.name,
                      }),
                    };
                  }
                })}
                onChange={this.handleChange}
              />
            </div>
            <div className="content utility-content bold-labels-wrap">
              <div className="grid-x grid-margin-x">
                <TextInput
                  className="medium-6 large-6 cell"
                  id="car_number"
                  name={t(terms.identificationNumber)}
                  onChange={event => this.handleChange(event)}
                  type="text"
                  value={this.state.car_number}
                  disabled={this.props.readOnly}
                />
                <TextInput
                  className="medium-6 large-6 cell"
                  id="model"
                  name={t(terms.model)}
                  onChange={event => this.handleChange(event)}
                  type="text"
                  value={this.state.model}
                  disabled={this.props.readOnly}
                />
                {(this.state.selectedCategory === "POOLVEHICLE" ||
                  this.state.selectedCategory === "AUTO") && (
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="device_odom_curr"
                    name={t(terms.device_odom_curr)}
                    onChange={event => this.handleChange(event)}
                    type="text"
                    value={this.state.device_odom_curr}
                    disabled={this.props.readOnly}
                  />
                )}
                {this.state.selectedCategory !== "POOLVEHICLE" && (
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="driver"
                    name={t(terms.driver)}
                    onChange={event => this.handleChange(event)}
                    type="text"
                    value={this.state.driver}
                    disabled={this.props.readOnly}
                  />
                )}
                {this.state.selectedCategory === "VEHICLE" && (
                  <TextInput
                    className="medium-6 large-6 cell"
                    id="device_ignition_time_curr"
                    name={t(terms.device_ignition_time_curr)}
                    onChange={event => this.handleChange(event)}
                    type="text"
                    value={this.state.device_ignition_time_curr}
                    disabled={this.props.readOnly}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {this.renderCategoryChangeConfirmation()}
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderCategoryChangeConfirmation = () => {
    if (!this.state.showCategoryChangeConfirmation) {
      return null;
    }

    return (
      <ConfirmCategoryChangeOverlay
        closeFunction={this.toggleCategoryChangeConfirmation}
        confirmCategoryChange={this.saveDeviceSettings}
      />
    );
  };

  renderFooter = () => {
    return (
      <div className="footer">
        <div className="buttons">
          <Link
            to={`/${this.props.portal}/${
              this.props.portal === "admin-panel"
                ? "device/list"
                : "device-list"
            }`}
            className="button"
            onClick={this.props.clearSingleImei}
          >
            <span>
              <T id="tracar.settings.close" defaultMessage="Schließen" />
            </span>
          </Link>

          <div
            className={`button confirm-button ${this.props.settingsSaving ===
              "true" && "saving"} ${this.props.settingsSaving === "done" &&
              "saved"} ${!this.canSubmit() ? "disabled" : ""}`}
            onClick={() =>
              this.props.settingsSaving === "false" &&
              this.canSubmit() &&
              (this.state.selectedCategoryId !== this.state.initialCategoryId
                ? this.toggleCategoryChangeConfirmation()
                : this.saveDeviceSettings())
            }
          >
            {this.props.settingsSaving === "true" && <LoadingSmallCircle />}
            <span>
              {this.props.settingsSaving === "true" && (
                <T
                  id="tracar.settings.general.saving"
                  defaultMessage="Einstellungen werden aktualisiert..."
                />
              )}
              {this.props.settingsSaving === "false" && (
                <T
                  id="tracar.settings.general.save"
                  defaultMessage="Einstellungen aktualisieren"
                />
              )}
              {this.props.settingsSaving === "done" && (
                <T
                  id="tracar.settings.general.saved"
                  defaultMessage="Einstellungen aktualisiert"
                />
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export const CategoryTab = injectIntl(CategoryTabClass);
