import React from "react";
import { FormattedMessage as T, injectIntl } from "react-intl";
import { RequestOverlay } from "../../Request/RequestOverlay";
import { SelectInput } from "../../SelectInput";
import { ConfirmAssignDriverFahrtenbuchOverlay } from "./ConfirmAssignDriverDahrtenbuchOverlay";

class PoolVehicleAssignDriverOverlayFahrtenbuchClass extends React.Component {
  handleChange;
  submit;

  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
      showMore: false,
      selectedDriverId: null,
      routeIsPrivate: false,
      driverList: [],
      showAssignDriverConfirmation: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    this.props
      .getOrphanRouteContext(this.props.entry.fahrtenbuch_id)
      .then(context => {
        const driverList = [...context.drivers];
        this.setState({
          driverList: [{ id: 0, email: "Kein Fahrer" }, ...driverList],
        });
      });
  }

  /// TODO this function is used several times refactor to import
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    this.setState({ [name]: value });
  }

  setSearchFilter = query => {
    this.setState({ query });
  };

  submit() {
    if (!this.state.selectedDriverId || this.state.selectedDriverId === "0") {
      // device started with no driver and ended with no driver => do nothing
      this.props.closeFunction();
      return;
    }

    // assign new driver
    this.props
      .assignOrphanRoute(
        this.props.entry.fahrtenbuch_id,
        this.state.selectedDriverId,
      )
      .then(() => this.props.closeFunction());
  }

  validate() {
    return true;
  }

  render() {
    return (
      <RequestOverlay
        className={this.state.requestOverlayClassName}
        closeFunction={this.props.closeFunction}
      >
        <div className="header">
          <h3>
            <T
              id="adminPanel.poolVehicles.assignDriver.headline"
              defaultMessage="Fahrer zuweisen"
            />{" "}
            <span className="margin-horizontal-1">></span>
            <span className="highlighted">{this.props.imei}</span>
          </h3>
        </div>
        <div className="small-spacer" />
        <div className="grid-x grid-margin-x text-left">
          <h4 className="cell margin-left-1 margin-bottom-2">
            <T
              id="adminPanel.poolVehicles.assignDriver.label.selectDriver"
              defaultMessage="Verfügbare Fahrer"
            />{" "}
            <span className="item-count">
              {/* Don't count the "no driver" option */}
              {this.state.driverList ? this.state.driverList.length - 1 : 0}
            </span>
          </h4>
          <div className="cell medium-4 margin-right-3">
            <h4 className="margin-bottom-0 margin-top-0">
              Neuen Fahrer wählen:
            </h4>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="medium-auto cell relative">
                  <SelectInput
                    id="selectedDriverId"
                    // name={t(terms.user_role_label)}
                    value={this.state.selectedDriverId}
                    nameProp={"email"}
                    valueProp={"id"}
                    options={this.state.driverList}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="small-spacer" />
          </div>
          <div className="cell medium-4 margin-right-3">
            <h4 className="margin-bottom-0 margin-top-0">
              <T
                id="adminPanel.poolVehicles.export.assignirreversible"
                defaultMessage="Diese Zuweisung kann nicht rückgängig gemacht werden"
              />
            </h4>
          </div>
        </div>
        <React.Fragment>
          <div className="small-spacer" />
          <div className="footer text-right">
            <div
              className="button transparent"
              onClick={this.props.closeFunction}
            >
              <T
                id="adminPanel.shared.buttonLabel.cancel"
                defaultMessage="Abbrechen"
              />
            </div>
            <div
              className="button green primary"
              onClick={this.toggleAssignDriverConfirmation}
            >
              <T
                id="adminPanel.shared.buttonLabel.submit"
                defaultMessage="Zuweisung abschliessen"
              />
            </div>
          </div>
          {this.renderAssignDriverConfirmation()}
        </React.Fragment>
      </RequestOverlay>
    );
  }
  renderAssignDriverConfirmation = () => {
    if (!this.state.showAssignDriverConfirmation) {
      return null;
    }

    return (
      <ConfirmAssignDriverFahrtenbuchOverlay
        closeFunction={this.toggleAssignDriverConfirmation}
        confirm={this.submit}
      />
    );
  };

  /**
   * Toggle assign driver confirmation overlay
   * @return void
   */
  toggleAssignDriverConfirmation = () => {
    this.setState({
      showAssignDriverConfirmation: !this.state.showAssignDriverConfirmation,
    });
  };
}

export const PoolVehicleAssignDriverFahrtenbuchOverlay = injectIntl(
  PoolVehicleAssignDriverOverlayFahrtenbuchClass,
);
