import { defineMessages } from "react-intl";

const terms = defineMessages({
  mon: {
    id: "weekdays.mon",
    defaultMessage: "Mo",
  },
  tue: {
    id: "weekdays.tue",
    defaultMessage: "Di",
  },
  wed: {
    id: "weekdays.wed",
    defaultMessage: "Mi",
  },
  thu: {
    id: "weekdays.thu",
    defaultMessage: "Do",
  },
  fri: {
    id: "weekdays.fri",
    defaultMessage: "Fr",
  },
  sat: {
    id: "weekdays.sat",
    defaultMessage: "Sa",
  },
  sun: {
    id: "weekdays.sun",
    defaultMessage: "So",
  },
});

export const getWeekdayList = t => [
  t(terms.mon),
  t(terms.tue),
  t(terms.wed),
  t(terms.thu),
  t(terms.fri),
  t(terms.sat),
  t(terms.sun),
];
