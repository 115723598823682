import React from "react";
import { DashboardMessageList } from "./DashboardMessageList";
import { DashboardServiceIntervalList } from "./DashboardServiceIntervalList";
import { DeviceInfo } from "./DeviceInfo";
import { SafetyZonesList } from "./SafetyZonesList";
import { Minimap } from "../Map/Minimap";
import { DeviceSpeedGraph } from "./DeviceSpeedGraph";
import { DeviceRangeGraph } from "./DeviceRangeGraph";
import { FormattedMessage as T } from "react-intl";
import { Portal } from "../Portal";

export function DeviceDashboardAuto({
  deviceDashboard,
  portal,
  imei,
  fetchAddress,
  serviceIntervals,
  setIntervalCompletion,
}) {
  const config = { displayModeBar: false };
  const plotLayoutCommons = {
    separators: ",.",
    title: "",
    paper_bgcolor: "rgba(255,255,255,0)",
    plot_bgcolor: "rgba(255,255,255,0)",
    showlegend: false,
    width: 320,
    height: 210,
    margin: { t: 0, b: 25, l: 45, r: 15 },
  };

  const widePlotLayoutCommons = {
    ...plotLayoutCommons,
    width: 662,
  };

  const noAxisValues = {
    autorange: true,
    showgrid: false,
    zeroline: false,
    showline: false,
    autotick: true,
    ticks: "",
    showticklabels: false,
  };

  return (
    <div className="outer-table-frame dashboard">
      <div className="table-frame">
        <div className="table-header">
          <i className="fa fa-tachometer" />
          <T id="base.dashboard" defaultMessage="Dashboard" />
        </div>
        <div className="all-graphs-outer-frame">
          <div className="all-graphs-frame">
            <DeviceSpeedGraph
              config={{ config }}
              noAxisValues={noAxisValues}
              widePlotLayoutCommons={widePlotLayoutCommons}
              speed_data={deviceDashboard.speed}
            />

            <DeviceRangeGraph
              config={{ config }}
              noAxisValues={noAxisValues}
              plotLayoutCommons={plotLayoutCommons}
              range_data={deviceDashboard.distance}
            />

            <DeviceInfo {...deviceDashboard} />

            <DashboardMessageList
              messages={deviceDashboard.messages}
              portal={Portal.Auto}
            />

            <DashboardServiceIntervalList
              serviceIntervals={serviceIntervals}
              setIntervalCompletion={setIntervalCompletion}
              portal={Portal.Auto}
            />

            <SafetyZonesList
              safety_zones={deviceDashboard.device_info.device_geofences}
              portal={portal}
              imei={imei}
            />

            <Minimap
              portal={portal}
              imei={imei}
              latest_gps_info={deviceDashboard.latest_gps_info}
              fetchAddress={fetchAddress}
            />

            <div className="small-spacer" />
          </div>
        </div>
      </div>
    </div>
  );
}
