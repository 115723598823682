import React from "react";
import FileUploader from "../../../../FileUploader";
import {LoadingCircle} from "../../../../LoadingCircle";
import {RequestOverlay} from "../../../../Request/RequestOverlay";
import { defineMessages, FormattedMessage as T, injectIntl } from "react-intl";
import {uploadItems} from "../../../../fetch/fetchSmartInventory";

const messages = defineMessages({
    linkExampleFile: {
        id: "adminPanel.smartInventory.upload.linkExampleFile",
        defaultMessage: "/smartInventoryItemCsvExample/csv_beispiel_DE.csv",
    },
    errorsUploadFailed: {
        id: "adminPanel.smartInventory.upload.uploadFailed",
        defaultMessage: "Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut.",
    },
});

class UploadCSVOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            uploading: false,
            selectedFile: null,
        };
    }

    render() {
        return (
            <RequestOverlay
                closeFunction={this.props.closeFunction}
                className="import-smart-inventory-items"
                enableCloseOnOverlayClicked={true}
            >
                {this.state.uploading ? <LoadingCircle /> : this.renderImportInterface()}
                {this.renderError()}
            </RequestOverlay>
        );
    }

    renderImportInterface() {
        return (
            <div>
                {this.renderHeader()}
                {this.renderFileupload()}
            </div>
        );
    };

    renderHeader() {
        return (
            <div>
                <h5>
                    <T id="adminPanel.smartInventory.upload.headline" defaultMessage="Items importieren"/>
                </h5>
                <div className="description">
                    <T
                        id="adminPanel.smartInventory.upload.descriptionOne"
                        defaultMessage="
                            Bitte laden Sie die Benutzerdaten im CSV-Format hoch. Die Datei muss folgende Header haben:
                            Name, Beschreibung, Seriennummer, Farbe."
                    />
                    <br/>
                    <T
                        id="adminPanel.smartInventory.upload.descriptionTwo"
                        defaultMessage="
                            Das Trennzeichen ist ein Komma."
                    />
                    &nbsp;
                    <a href={this.props.intl.formatMessage(messages.linkExampleFile)} target="_blank" rel="noopener noreferrer">
                        <strong>
                            <T
                                id="adminPanel.smartInventory.downloadExampleFile"
                                defaultMessage="Beispieldatei herunterladen"
                            />
                        </strong>
                    </a>
                </div>
            </div>
        );
    };

    renderFileupload() {
        const buttonClasses = ["button", "save-button"];

        if (!this.state.selectedFile) {
            buttonClasses.push("disabled");
        }

        return (
            <div className="upload-container">
                <div className="spacer" />
                <FileUploader handleSelectedFile={this.handleSelectedFile} validExtensions={[".csv"]}/>
                <div className="spacer" />
                <div className={buttonClasses.join(" ")} onClick={this.state.selectedFile ? this.handleUpload : null}>
                    <span>
                        <T id="adminPanel.smartInventory.upload.uploadLabel" defaultMessage="Daten importieren"/>
                    </span>
                </div>
            </div>
        );
    };

    handleSelectedFile = file => {
        this.setState({
            uploading: false,
            selectedFile: file,
        });
    };

    handleUpload = () => {
        this.setState({ uploading: true }, () => {
            this.uploadItems().then(() => {
                this.props.closeFunction();
            }).catch(() => {
                this.setState({
                    uploading: false,
                    error: "uploadFailed",
                });
            });
        });
    };

    uploadItems() {
        return uploadItems(this.props.fetchService.jsonFetch, this.state.selectedFile);
    }

    renderError() {
        if (!this.state.error) {
            return;
        }

        return (
            <div className="error">
                <T
                    id="adminPanel.smartInventory.errors.uploadFailed"
                    defaultMessage="Fehler beim hochladen der Datei. Bitte versuchen Sie es erneut."
                />
            </div>
        );
    };
}

export default injectIntl(UploadCSVOverlay);
