import React from "react";
import {defineMessages, injectIntl, FormattedMessage as T} from "react-intl";
import {
    fetchTaskTemplates,
    fetchTaskTemplateTasks,
    getItemTemplates
} from "../../../../fetch/fetchSmartInventory";

const formName = 'assignTaskTemplate';
const noTemplateId = 0;

const messages = defineMessages({
    noTaskTemplate: {
        id: "adminPanel.smartInventory.assignTemplateForm.noTaskTemplate",
        defaultMessage: "Keine Aufgabenliste",
    },
});

class AssignTemplateForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            templates: this.props.templates ? this.props.templates : [],
            beforeTemplateTasks: [],
            afterTemplateTasks: [],
            data: this.props.data ? this.props.data : {
                selectedBeforeTemplateId: null,
                selectedAfterTemplateId: null,
            }
        };
    }

    componentDidMount() {
        if (this.state.templates.length !== 0) {
            return this.handleAssignedTemplates();
        }

        const noTemplateOption = [{
            id: noTemplateId,
            name: this.props.intl.formatMessage(messages.noTaskTemplate)
        }];

        fetchTaskTemplates(this.props.fetchService.jsonFetch).then(response => {
            const templates = noTemplateOption.concat(response);

            this.setState({templates}, () => {
                this.props.setTemplates(templates);
                this.handleAssignedTemplates();
            });
        });
    }

    handleAssignedTemplates() {
        if (this.state.data.selectedBeforeTemplateId || this.state.data.selectedAfterTemplateId) {
            return this.fetchTemplateTasks();
        }

        return this.fetchAssignedTemplates();
    }

    fetchAssignedTemplates() {
        if (!this.props.itemToEdit) {
            return;
        }

        getItemTemplates(this.props.fetchService.jsonFetch, this.props.itemToEdit.item_uuid).then(response => {
            this.setState({data: {
                selectedBeforeTemplateId: response.template_before ? response.template_before.id : null,
                selectedAfterTemplateId: response.template_after ? response.template_after.id : null,
            }}, () => this.fetchTemplateTasks());
        });
    }

    fetchTemplateTasks() {
        if (this.state.data.selectedBeforeTemplateId) {
            this.fetchBeforeTemplateTasks();
        }

        if (this.state.data.selectedAfterTemplateId) {
            this.fetchAfterTemplateTasks();
        }
    }

    fetchBeforeTemplateTasks() {
        if (this.state.data.selectedBeforeTemplateId == noTemplateId) {
            return this.setState({beforeTemplateTasks: []});
        }

        fetchTaskTemplateTasks(this.props.fetchService.jsonFetch, this.state.data.selectedBeforeTemplateId)
            .then(response => {
               this.setState({beforeTemplateTasks: response.tasks_template || []});
            });
    }

    fetchAfterTemplateTasks() {
        if (this.state.data.selectedAfterTemplateId == noTemplateId) {
            return this.setState({afterTemplateTasks: []});
        }

        fetchTaskTemplateTasks(this.props.fetchService.jsonFetch, this.state.data.selectedAfterTemplateId)
            .then(response => {
               this.setState({afterTemplateTasks: response.tasks_template || []});
            });
    }

    render() {
        return (
            <div>
                <div className="grid-container grid-x grid-padding-x" style={{paddingTop: '1.5rem'}}>
                    { this.renderTaskSelect('before') }
                    <div className={`cell small-0 large-1`}>
                        <div className={`devider`}></div>
                    </div>
                    { this.renderTaskSelect('after') }
                </div>
            </div>
        );
    }

    renderTaskSelect(type) {
        return (
            <div className={`cell small-12 large-5 text-left`}>
                <div>
                    {this.renderSelectHeadline(type)}
                </div>
                { this.renderTemplateSelect(type) }
                { this.renderSelectedTemplateTasks(type) }
            </div>
        );
    }

    renderSelectHeadline(type) {
        if (type === 'before') {
            return <T
                id="adminPanel.smartInventory.assignTemplateForm.headline.beforeTemplate"
                defaultMessage={'Vor-Aufgabe'}
            >
                {msg => <span className={`grey-text`}>{msg}</span> }
            </T>;
        }

        return <T
            id="adminPanel.smartInventory.assignTemplateForm.headline.afterTemplate"
            defaultMessage={'Nach-Aufgabe'}
        >
            {msg => <span className={`grey-text`}>{msg}</span> }
        </T>;
    }

    renderTemplateSelect(type) {
        const selectedTaskId = type === 'before' ?
            parseInt(this.state.data.selectedBeforeTemplateId) :
            parseInt(this.state.data.selectedAfterTemplateId);

        return <select name={type + 'List'} onChange={event => this.changeSelectedTemplate(event, type)}>
            { this.state.templates.map(template => {
                return (
                    <option key={template.id} value={template.id} selected={selectedTaskId === template.id}>
                        {template.name}
                    </option>
                )
            })}
        </select>
    }

    changeSelectedTemplate(event, type) {
        const selectedTaskId = type === 'before' ? 'selectedBeforeTemplateId' : 'selectedAfterTemplateId';
        const templateId = event.target.value;

        this.setState({
            data: {
                ...this.state.data,
                [selectedTaskId]: templateId
            }
        }, () => {
            this.fetchTemplateTasks();
            this.props.updateData(formName, this.state.data);
        });
    }

    renderSelectedTemplateTasks(type) {
        const tasks = type === 'before' ? this.state.beforeTemplateTasks : this.state.afterTemplateTasks;

        return (
            <ul className={`template-task-container`}>
                { tasks.map(task => {
                    return <li key={task.id}>{task.task_name}</li>;
                })}
            </ul>
        );
    }
}

export default injectIntl(AssignTemplateForm);
