import React from "react";
import { LoadingCircle } from "../LoadingCircle";
import { ServiceIntervalList } from "./ServiceIntervalList";
import { ConfirmDeleteOverlay } from "../ConfirmDeleteOverlay";
import { ServiceIntervalHeadline } from "./ServiceIntervalHeadline";
import { FormattedMessage as T, injectIntl, defineMessages } from "react-intl";
import { PaginationBar } from "../AdminPanel/PaginationBar";
import { QueryLine } from "../tools/QueryLine";
import { Data } from "../ServiceInterval";
import { hasPortalAccess } from "../Permission";
import { UserConsumer } from "../Base";
import { Portal } from "../Portal";
import { RequestOverlay } from "../Request/RequestOverlay";

const terms = defineMessages({
  noCustomers: {
    id: "customer.addresses.base.noCustomers",
    defaultMessage: "Keine Kundenadressen gefunden",
  },
  confirmAddressDeleteLabel: {
    id: "customer.addresses.confirmDelete.label",
    defaultMessage: "die Adresse",
  },
  service_type_choices: {
    id: "service.interval.base.sorting.service",
    defaultMessage: "Service Typ",
  },
  service: {
    id: "service.interval.base.sorting.description",
    defaultMessage: "Beschreibung",
  },
  vehicle_model: {
    id: "service.interval.base.sorting.vehicle_model",
    defaultMessage: "Gerät",
  },
  device_category: {
    id: "service.interval.base.sorting.device_category",
    defaultMessage: "Kategorie",
  },
  planned_service_date: {
    id: "service.interval.base.sorting.planned_service_date",
    defaultMessage: "Nächste Wartung am",
  },
  threshold: {
    id: "service.interval.base.sorting.threshold",
    defaultMessage: "Nächste Wartung bei",
  },
  expected_service_date: {
    id: "service.interval.base.sorting.avarage_usage_date",
    defaultMessage: "Datum bei ø Nutzung",
  },
  device_odom_or_ignition_curr: {
    id: "service.interval.base.sorting.device_odom_or_ignition_curr",
    defaultMessage: "Aktuell",
  },
  is_done: {
    id: "service.interval.base.sorting.is_done",
    defaultMessage: "Durchgeführt",
  },
});

class ServiceIntervalBaseClass extends React.Component {
  load;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentPage: 0,
      serviceIntervalsPerPage: 10,
      serviceIntervalToDelete: null,
      serviceIntervalToDeleteUser: "",
      showCreateOverlay: false,
      serviceIntervalList: null,
      showDeleteConfirmationOverlay: false,
      searchFilter: "",
    };

    this.load = this.load.bind(this);
  }

  async componentDidMount() {
    this.load();
  }

  async load() {
    const serviceIntervals = await this.props.getServiceIntervals();
    this.setState({ serviceIntervals, loading: false });
  }

  /**
   * Deletes a single interval
   * @param  integer id
   * @return void
   */
  deleteServiceInterval = () => {
    if (!this.state.serviceIntervalToDelete) {
      return;
    }

    this.setState({ loading: true });

    this.props
      .deleteServiceInterval(
        this.state.serviceIntervalToDelete,
        this.state.serviceIntervalToDeleteUser,
      )
      .then(response => {
        this.setState(
          {
            loading: false,
            showDeleteConfirmationOverlay: false,
          },
          () => {
            this.load();
          },
        );
      })
      .catch(error => {
        this.setState({ loading: false, error });
      });
  };

  createNewInterval = (reload = false) => {
    this.setState({
      showCreateOverlay: !this.state.showCreateOverlay,
    });

    if (reload) {
      this.load();
    }
  };

  /**
   * Shows or hides the delete confirmation
   * @return void
   */
  toggleDeleteConfirmationOverlay = (id, user) => {
    this.setState({
      serviceIntervalToDelete: id,
      serviceIntervalToDeleteUser: user,
      showDeleteConfirmationOverlay: !this.state.showDeleteConfirmationOverlay,
    });
  };

  setSearchFilter = searchFilter => {
    this.setState({ searchFilter });
  };

  getFilteredItems = items => {
    if (!this.state.loading) {
      return items.reduce((acc, item) => {
        const stringFiltering =
          this.state.searchFilter.length === 0 ||
          (item.vehicle_model &&
            item.vehicle_model
              .toLowerCase()
              .search(this.state.searchFilter.toLowerCase()) > -1) ||
          (item.imei && item.imei.search(this.state.searchFilter) > -1) ||
          (item.description &&
            item.description
              .toLowerCase()
              .search(this.state.searchFilter.toLowerCase()) > -1);

        if (!stringFiltering) {
          return acc;
        }

        return [...acc, item];
      }, []);
    } else {
      return [];
    }
  };

  customSort = sortParam => {
    const sortDirectionToggler =
      sortParam === this.state.sortParam
        ? !this.state.sortDirectionToggler
        : true;
    const direction = sortDirectionToggler ? 1 : -1;

    this.setState({
      sortDirectionToggler: sortDirectionToggler,
      sortParam: sortParam,
      serviceIntervals: this.sortByParam(
        sortParam,
        direction,
        this.getFilteredItems(this.state.serviceIntervals),
      ),
    });
  };

  sortByParam = (sortParam, direction, items) => {
    return [].concat(items).sort((itemA, itemB) => {
      let paramFirst,
        paramSecond = "";
      if (sortParam === "planned_service_date") {
        paramFirst = new Date(itemA[sortParam]).getTime();
        paramSecond = new Date(itemB[sortParam]).getTime();
      } else if (sortParam === "is_done") {
        paramFirst = itemA[sortParam] ? "b" : "a";
        paramSecond = itemB[sortParam] ? "b" : "a";
      } else if (sortParam === "threshold") {
        paramFirst = itemA[sortParam];
        paramSecond = itemB[sortParam];
      } else {
        paramFirst =
          itemA[sortParam] === null
            ? "-"
            : itemA[sortParam].toString().toLowerCase();
        paramSecond =
          itemB[sortParam] === null
            ? "-"
            : itemB[sortParam].toString().toLowerCase();
      }
      if (paramFirst > paramSecond) {
        return -1 * direction;
      } else if (paramFirst < paramSecond) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  };

  filterByType = (type, items) => {
    return items.length > 0
      ? items.filter(item => {
          return item.device_category === type;
        })
      : [];
  };

  render() {
    const portal = this.props.portal || "tracar";

    return (
      <UserConsumer>
        {user => {
          return portal === Portal.AdminPanel ? (
            hasPortalAccess(user, Portal.AdminPanelServiceInterval) && (
              <div className="outer-table-frame modal-interval-table tracker">
                {this.getTopButtons()}

                <div className="table-frame">{this.renderList(user.email)}</div>
                {this.getPagination()}

                {this.renderCreateOverlay(user.email)}
                {this.renderDeleteConfirmationOverlay()}

                {this.renderError()}
              </div>
            )
          ) : (
            <div className="outer-table-frame modal-interval-table tracker">
              {this.getTopButtons()}

              <div className="table-frame">{this.renderList(user.email)}</div>
              {this.getPagination()}

              {this.renderDeleteConfirmationOverlay()}

              {this.renderError()}
            </div>
          );
        }}
      </UserConsumer>
    );
  }

  /**
   * Return the add-interval button and search bar.
   * @return JSX
   */
  getTopButtons = () => {
    const portal = this.props.portal || "tracar";

    return (
      <div className="grid-x grid-margin-x align-middle search-area">
        <div className="auto cell">
          <QueryLine changeCallback={this.setSearchFilter} changeDelay={500} />
        </div>
      </div>
    );
  };

  /**
   * Return the sorting bar.
   * @return JSX
   */
  getSortBar = () => {
    const t = this.props.intl.formatMessage;

    return (
      <div className="grid-x grid-margin-x table-line filter">
        <div
          className="cell auto"
          onClick={() => this.customSort(Data.Service)}
        >
          <a
            className={`${this.state.sortParam === Data.Service && "active"}`}
            href="#"
          >
            {t(terms[Data.Service])}
            <i
              className={`fa ${
                this.state.sortParam === Data.Service
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
        <div
          className="cell auto"
          onClick={() => this.customSort(Data.Description)}
        >
          <a
            className={`${this.state.sortParam === Data.Description &&
              "active"}`}
            href="#"
          >
            {t(terms[Data.Description])}
            <i
              className={`fa ${
                this.state.sortParam === Data.Description
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
        <div
          className="cell auto"
          onClick={() => this.customSort(Data.Vehicle)}
        >
          <a
            className={`${this.state.sortParam === Data.Vehicle && "active"}`}
            href="#"
          >
            {t(terms[Data.Vehicle])}
            <i
              className={`fa ${
                this.state.sortParam === Data.Vehicle
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
        <div
          className="cell auto"
          onClick={() => this.customSort(Data.Category)}
        >
          <a
            className={`${this.state.sortParam === Data.Category && "active"}`}
            href="#"
          >
            {t(terms[Data.Category])}
            <i
              className={`fa ${
                this.state.sortParam === Data.Category
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
        <div
          className="cell medium-2"
          onClick={() => this.customSort(Data.PlanedDate)}
        >
          <a
            className={`${this.state.sortParam === Data.PlanedDate &&
              "active"}`}
            href="#"
          >
            {t(terms[Data.PlanedDate])}
            <i
              className={`fa ${
                this.state.sortParam === Data.PlanedDate
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
        <div
          className="cell medium-2"
          onClick={() => this.customSort(Data.Threshold)}
        >
          <a
            className={`${this.state.sortParam === Data.Threshold && "active"}`}
            href="#"
          >
            {t(terms[Data.Threshold])}
            <i
              className={`fa ${
                this.state.sortParam === Data.Threshold
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
        <div
          className="cell auto"
          onClick={() => this.customSort(Data.DeviceOdomOrIgnitionCurr)}
        >
          <a
            className={`${this.state.sortParam ===
              Data.DeviceOdomOrIgnitionCurr && "active"}`}
            href="#"
          >
            {t(terms[Data.DeviceOdomOrIgnitionCurr])}
            <i
              className={`fa ${
                this.state.sortParam === Data.DeviceOdomOrIgnitionCurr
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
        <div
          className="cell medium-1"
          onClick={() => this.customSort(Data.Done)}
        >
          <a
            className={`icon-wrap ${this.state.sortParam === Data.Done &&
              "active"}`}
            href="#"
          >
            <i className="fa fa-check-circle" />
            <i
              className={`fa ${
                this.state.sortParam === Data.Done
                  ? this.state.sortDirectionToggler
                    ? "fa-sort-up"
                    : "fa-sort-down"
                  : "fa-sort"
              } relative`}
            />
          </a>
        </div>
      </div>
    );
  };

  /**
   * Return the pagination if needed
   * @return JSX||null
   */
  getPagination = () => {
    const pageCount = this.getPageCount();

    if (!this.state.serviceIntervals || pageCount <= 1) {
      return null;
    }

    return (
      <PaginationBar
        currentPage={this.state.currentPage}
        numberOfPages={pageCount}
        updateCurrentPage={this.updatePagination}
      />
    );
  };

  /**
   * Return the number of pages for the Pagination
   * @return integer
   */
  getPageCount = () => {
    if (!this.state.serviceIntervals) {
      return 0;
    }

    const portal = this.props.portal || "tracar";

    const serviceIntervals = this.state.serviceIntervals
      ? portal === Portal.AdminPanel
        ? this.getFilteredItems(this.state.serviceIntervals)
        : this.getFilteredItems(
            this.filterByType(portal, this.state.serviceIntervals),
          )
      : [];

    return Math.ceil(
      this.getFilteredItems(serviceIntervals).length /
        this.state.serviceIntervalsPerPage,
    );
  };

  /**
   * Updates pagination information on page switch
   * @param pageNumber
   */
  updatePagination = pageNumber => {
    this.setState({ currentPage: pageNumber });
  };

  /**
   * Returns the props for the pagination items
   * @param  ineteger limit
   * @return Object
   */
  getPaginationItemProps = limit => {
    const className = `link ${
      limit === this.state.serviceIntervalsPerPage ? " active" : ""
    }`;

    return {
      key: limit,
      onClick: () => this.setPaginationLimit(limit),
      className,
    };
  };

  /**
   * Sets the pagination-limit and reloads the list
   * @param  integer limit
   * @return void
   */
  setPaginationLimit = limit => {
    this.setState(
      {
        addressesPerPage: limit,
      },
      this.handlePagination,
    );
  };

  /**
   * handles the click on the pagination
   * @param  Object data
   * @return void
   */
  handlePagination = data => {
    // Height of header
    const paginationYOffset = 155;
    const selected = data ? data.selected : this.state.currentPage;
    const { serviceIntervalsPerPage: addressesPerPage } = this.state;
    const offset = Math.ceil(selected * addressesPerPage);

    this.setState({
      loading: true,
      currentPage: selected,
    });

    this.load({ offset, limit: addressesPerPage });

    if (window.pageYOffset > paginationYOffset) {
      window.scrollTo(0, paginationYOffset);
    }
  };

  /**
   * Returns the address-list-table
   * @return JSX
   */
  renderList = userEmail => {
    if (this.state.loading) {
      return this.renderLoadingCircle();
    }

    const portal = this.props.portal || "tracar";

    const serviceIntervals = this.state.serviceIntervals
      ? portal === Portal.AdminPanel
        ? this.getFilteredItems(this.state.serviceIntervals)
        : this.getFilteredItems(
            this.filterByType(portal, this.state.serviceIntervals),
          )
      : [];

    return (
      <div className="filter-table">
        <ServiceIntervalHeadline
          serviceIntervalListCount={serviceIntervals.length || 0}
        />
        {this.getSortBar()}
        <ServiceIntervalList
          serviceIntervals={serviceIntervals.slice(
            this.state.currentPage * this.state.serviceIntervalsPerPage,
            this.state.currentPage * this.state.serviceIntervalsPerPage +
              this.state.serviceIntervalsPerPage,
          )}
          updateServiceIntervals={this.load}
          setIntervalCompletion={this.props.setIntervalCompletion}
          deleteServiceInterval={this.deleteServiceInterval}
          showDeleteConfirmation={this.toggleDeleteConfirmationOverlay}
          fetchDevices={this.props.fetchDevices}
          createServiceInterval={this.props.createServiceInterval}
          getEnumTypes={this.props.getEnumTypes}
          userEmail={userEmail}
          portal={portal}
        />
      </div>
    );
  };

  /**
   * Renders the loading circle
   * @return JSX
   */
  renderLoadingCircle = () => {
    return (
      <div className="message-loading-circle">
        <LoadingCircle />
      </div>
    );
  };

  /**
   * Render the create overlay
   * @return null|JSX
   */
  renderCreateOverlay = userEmail => {
    if (!this.state.showCreateOverlay) {
      return null;
    }
  };

  /**
   * Render the delete confirmation
   * @return null|JSX
   */
  renderDeleteConfirmationOverlay = () => {
    if (!this.state.showDeleteConfirmationOverlay) {
      return null;
    }

    return (
      <ConfirmDeleteOverlay
        closeFunction={this.toggleDeleteConfirmationOverlay}
        confirmDelete={this.deleteServiceInterval}
        label={terms.confirmAddressDeleteLabel.defaultMessage}
      />
    );
  };

  renderError = () => {
    if (!this.state.error) {
      return null;
    }

    return (
      <RequestOverlay
        closeFunction={this.setState({ error: null })}
        className="import-customer-addresses"
        enableCloseOnOverlayClicked={true}
      >
        <div className="error">
          <T
            id="customer.addresses.errors.uploadFailed"
            defaultMessage="Fehler beim Löschen des Intervalls. Bitte versuchen Sie es erneut."
          />
        </div>
      </RequestOverlay>
    );
  };
}

export const ServiceIntervalBase = injectIntl(ServiceIntervalBaseClass);
