import { postJson } from "./fetchService";

const intervalRoute = "/user/tracking_interval_setting/";

export const fetchTrackingInterval = jsonFetch => ({
  getTrackingInterval(imei) {
    return jsonFetch(intervalRoute + imei);
  },

  setTrackingInterval(imei, trackingInterval) {
    return jsonFetch(
      intervalRoute + imei,
      postJson({ tracking_interval_type: trackingInterval }),
    );
  },
});
