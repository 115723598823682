import React from "react";
import { Settings } from "./Settings";
import { LoadingCircle } from "../../../LoadingCircle";
import { Redirect } from "react-router";
import { removeArrayElement } from "../../../utility";

export class SettingsBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSettings: null,
      editedDeviceSettings: null,
      loadingState: "loading",
      settingsSaving: "false",
    };
  }

  componentDidMount() {
    this.loadDeviceSettings();
  }

  loadDeviceSettings = () => {
    this.setState({
      deviceSettings: null,
      loadingState: "loading",
    });
    this.props.fetchDeviceSettings
      .deviceAdmin(this.props.selectedDeviceImei)
      .then(deviceSettings => {
        let tempTabs =
          deviceSettings != null &&
          Object.entries(deviceSettings).map(deviceSetting => {
            return deviceSetting[0];
          });
        tempTabs = removeArrayElement(
          tempTabs,
          tempTabs.indexOf("user_settings"),
        );

        this.setState({
          deviceSettings: deviceSettings,
          editedDeviceSettings: deviceSettings,
          loadingState: "loaded",
          tabs: tempTabs,
        });
      })
      .catch(error => {
        this.setState({
          loadingState: "loaded",
        });
      });
  };

  updateDeviceSettings = (type, settings) => {
    let editedDeviceSettings = this.state.editedDeviceSettings;
    editedDeviceSettings[type] = settings;
    this.setState({ editedDeviceSettings, settingsSaving: "false" });
  };

  saveDeviceSettings = settings => {
    this.setState({ settingsSaving: "true" });
    this.props.fetchDeviceSettings
      .updateAdmin(this.props.selectedDeviceImei, settings)
      .then(res => {
        this.setState({ settingsSaving: "done" });
        this.props.loadTags();
      })
      .catch(err => {
        this.setState({ settingsSaving: "done" });
        this.props.loadTags();
      });
  };

  setSettingsSaving = status => {
    this.setState({ settingsSaving: status });
  };

  resetDeviceSettings = async settings => {
    this.setState({ settingsSaving: "true" });
    return await this.props.fetchDeviceSettings
      .reset([this.props.selectedDeviceImei], settings)
      .then(res => {
        this.setState({ settingsSaving: "done" });
        this.props.loadTags();
        return res;
      })
      .catch(() => {
        this.setState({ settingsSaving: "done" });
        return false;
      });
  };

  handleChange = event => {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.id;
    if (name === "max_speed" && value !== "") {
      value = parseInt(value, 10);
      value > 400 && (value = 400);
      value < 0 && (value = 0);
    }
    this.setState({ [name]: value });
  };

  render() {
    const portal = (this.props.match && this.props.match.params.portal) || "";

    let deviceList = [];
    this.props.selectedDeviceImei &&
      this.props.devices &&
      this.props.devices.forEach(device => {
        device.imei === this.props.selectedDeviceImei &&
          deviceList.push({
            imei: this.props.selectedDeviceImei,
            name: device.name,
            assignment_type: device.assignment_type,
          });
      });

    if (
      this.state.loadingState === "loading" ||
      this.state.loadingState === "saving"
    ) {
      return (
        <div className="loading-circle-container">
          <LoadingCircle />
        </div>
      );
    }

    if (this.state.editedDeviceSettings != null) {
      return (
        <Settings
          tabs={this.state.tabs}
          portal={portal}
          user={this.props.user}
          clearSingleImei={this.props.clearSingleImei}
          selectedDeviceImei={this.props.selectedDeviceImei}
          updateselectedDeviceImei={this.props.updateselectedDeviceImei}
          selectedSettingsTab={this.props.selectedSettingsTab}
          deviceList={deviceList}
          deviceSettings={this.state.editedDeviceSettings}
          updateDeviceSettings={this.updateDeviceSettings}
          isBulk={false}
          handleChange={this.handleChange}
          saveDeviceSettings={this.saveDeviceSettings}
          carsRequest={this.props.carsRequest}
          addNewCar={this.props.addNewCar}
          settingsSaving={this.state.settingsSaving}
          setSettingsSaving={this.setSettingsSaving}
          resetDeviceSettings={this.resetDeviceSettings}
          generateWiresCsv={this.props.generateWiresCsv}
          getGeofenceList={this.props.getGeofenceList}
          getDeviceSafetyZones={this.props.getDeviceSafetyZones}
          deviceSafetyZoneRequest={this.props.deviceSafetyZoneRequest}
          deleteDeviceSafetyZone={this.props.deleteDeviceSafetyZone}
          loadTags={this.props.loadTags}
          loadDeviceSettings={this.loadDeviceSettings}
          readOnly={this.props.readOnly}
          fetchService={this.props.fetchService}
          device={this.props.devices.find(
            x => x.imei === this.props.selectedDeviceImei,
          )}
          getCategoryListForDevice={this.props.getCategoryListForDevice}
        />
      );
    } else return <Redirect to="/admin-panel/device/list" />;
  }
}
